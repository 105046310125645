import { generateDataTypeIconBackground, generateDataTypeIconColor } from "./styleFunctions";

const DetailedSpeedDataTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="4" fill={generateDataTypeIconBackground(props)} />
      <path
        d="M10.8245 14.8184C11.1235 15.1174 11.5086 15.2594 11.9798 15.2443C12.4511 15.2292 12.7968 15.0591 13.017 14.7342L16.4132 9.81495C16.5338 9.63348 16.5201 9.46879 16.3722 9.32089C16.2243 9.173 16.0595 9.1592 15.8777 9.2795L10.9508 12.668C10.6205 12.8882 10.4421 13.2269 10.4156 13.6841C10.3892 14.1413 10.5255 14.5194 10.8245 14.8184ZM12.0001 5.68555C12.5539 5.68555 13.0831 5.73575 13.5878 5.83615C14.0925 5.93655 14.5845 6.09147 15.0638 6.30091C15.2344 6.36892 15.4042 6.49036 15.5731 6.66524C15.742 6.84012 15.7852 7.00582 15.7026 7.16236C15.6201 7.31888 15.4417 7.42278 15.1675 7.47406C14.8933 7.52534 14.6666 7.51698 14.4874 7.44897C14.0933 7.27948 13.6918 7.15371 13.2826 7.07165C12.8735 6.98961 12.446 6.94859 12.0001 6.94859C10.1336 6.94859 8.54422 7.60468 7.23204 8.91685C5.91986 10.229 5.26377 11.8184 5.26377 13.6849C5.26377 14.2743 5.34447 14.8567 5.50586 15.4321C5.66725 16.0075 5.8953 16.5478 6.19002 17.0531H17.8102C18.133 16.5198 18.368 15.9654 18.5154 15.39C18.6627 14.8146 18.7364 14.2182 18.7364 13.6007C18.7364 13.2034 18.6967 12.7956 18.6174 12.3773C18.538 11.959 18.4136 11.5571 18.2441 11.1717C18.1761 10.9925 18.1621 10.8176 18.202 10.6471C18.242 10.4765 18.3364 10.3352 18.4853 10.2233C18.6235 10.1215 18.7744 10.0933 18.938 10.1386C19.1015 10.184 19.2173 10.2833 19.2853 10.4366C19.5282 10.9656 19.7058 11.4862 19.8181 11.9984C19.9303 12.5106 19.9908 13.0361 19.9995 13.5748C20.0081 14.3369 19.9223 15.0573 19.742 15.7358C19.5617 16.4142 19.2902 17.0779 18.9275 17.7267C18.8163 17.9091 18.6598 18.053 18.4579 18.1582C18.256 18.2635 18.0401 18.3161 17.8102 18.3161H6.19002C5.96547 18.3161 5.75361 18.2621 5.55444 18.1542C5.35527 18.0462 5.1947 17.8929 5.07271 17.6943C4.75101 17.1329 4.49192 16.5208 4.29545 15.858C4.09897 15.1952 4.00073 14.4708 4.00073 13.6849C4.00073 12.5849 4.20941 11.5508 4.62677 10.5826C5.04413 9.61444 5.61385 8.76646 6.33594 8.03867C7.05803 7.31088 7.90703 6.73675 8.88294 6.31628C9.85884 5.89579 10.8979 5.68555 12.0001 5.68555Z"
        fill={generateDataTypeIconColor(props)}
      />
    </svg>
  );
};

export default DetailedSpeedDataTypeIcon;
