import Checkbox from "../../../CheckBox/Checkbox";
import { Filter } from "../../../../shared";

interface IFilterOptionListProps {
  props: any;
  option: Filter;
  optionName: string;
  isTableLoading: boolean;
  selected: boolean;
  selectedItem: Filter | null;
}

const FilterOptionList = ({
  option,
  optionName,
  isTableLoading,
  selected,
  selectedItem,
  props,
}: IFilterOptionListProps) => {
  const { key, ...restProps } = props; // Exclude 'key' from props

  return (
    <li
      key={key}
      {...restProps} // fix warning: A props object containing a "key" prop is being spread into JSX #247
      style={{
        display: "flex",
        justifyContent: "space-between",
        opacity: isTableLoading && selectedItem?.id !== option.id ? 0.4 : 1,
      }}
    >
      {optionName}

      <Checkbox checked={selected} />
    </li>
  );
};

export default FilterOptionList;
