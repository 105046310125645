import Dialog from "../../../../../Dialog/Dialog";
import {
  GeneralDialogProps,
  VEHICLE_FILES_ACTIONS,
} from "../../../../../../shared";
import { capitalizeStringFirstLetter } from "../../../../../../shared/functions/functions";

interface IFileSettingsDialogProps extends GeneralDialogProps {
  filesTableName: string;
}

const FileSettingsDialog = ({
  isOpen,
  onClose,
  filesTableName,
}: IFileSettingsDialogProps) => {
  const handleCloseVehicleFileSettingsDialog = () => {
    onClose(VEHICLE_FILES_ACTIONS.FileSettings);
  };

  return (
    <Dialog
      data-testid={`${filesTableName.toLowerCase()}-file-settings-dialog`}
      title={`${filesTableName} files settings`}
      isTitleSeparator
      open={isOpen}
      close={handleCloseVehicleFileSettingsDialog}
      actions={<></>}
    >
      {`${capitalizeStringFirstLetter(filesTableName)} file settings dialog`}
    </Dialog>
  );
};

export default FileSettingsDialog;
