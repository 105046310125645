/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

import { opacityTransition, useStyles } from "../styles";

import Button from "../../../../../components/Button/Button";

interface ArrowButtonProps {
  scroll: (scrollOffset: number) => void;
  customStyles?: any;
  endOfScroll: boolean;
  icon: React.ReactNode;
  scrollOffset: number;
  testId?: string;
}

const ArrowButton = ({
  scroll,
  customStyles,
  endOfScroll,
  icon,
  scrollOffset,
  testId,
}: ArrowButtonProps) => {
  const { repeatedArrowButtonStyles } = useStyles({});

  return (
    <Button
      variant="iconOnly"
      size="small"
      color="secondary"
      data-testid={testId}
      disableRipple
      onClick={() => scroll(scrollOffset)}
      css={css([
        customStyles,
        repeatedArrowButtonStyles,
        {
          opacity: endOfScroll ? 0 : 1,
          transition: opacityTransition,
        },
      ])}
      icon={icon}
    />
  );
};

export default ArrowButton;
