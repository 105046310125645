import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    fieldAndButtonCSS: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    copyButtonCSS: { marginTop: "24px" },

    credentialsMainContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    credentialsTextAndButtonCSS: {
      display: "flex",
      flexDirection: "column",
    },
    credentialsTextCSS: {
      display: "flex",
      marginTop: "4px",
      gap: "4px",
    },
    mainLoaderCSS: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "92px",
    },
    noCredentialsCSS: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "1",

      svg: {
        marginBottom: "16px",
      },

      "span:last-of-type": {
        marginTop: "8px",
      },
    },
  } as CSSObject);
