import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { getEnvironment } from "../functions/functions";
import { useAuth } from "../../context/Auth/AuthProvider";
import useTableData from "../../components/Table/hooks/useTableData";

export const useGoogleAnalytics = (user: any) => {
  const { cookieConsentStatus } = useAuth();

  const { href } = window.location;

  const location = useLocation();
  const { getApiQueryParams } = useTableData(undefined, true);
  const { isProductionEnv } = getEnvironment();

  const [initialized, setInitialized] = useState(false);

  const userExists = Boolean(user?.email);
  const fullPageUrl = getApiQueryParams();
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  const isAnalyticsCookieAccepted = (cookieConsentStatus: any) =>
    cookieConsentStatus.analytics === "true";

  useEffect(() => {
    if (
      isProductionEnv &&
      trackingId &&
      isAnalyticsCookieAccepted(cookieConsentStatus) &&
      !initialized &&
      userExists
    ) {
      ReactGA.initialize(trackingId);
      ReactGA.set({
        dimension1: user.email,
        dimension2: user.company.name,
      });

      setInitialized(true);
    }
  }, [
    isProductionEnv,
    trackingId,
    cookieConsentStatus,
    href,
    initialized,
    userExists,
    user,
  ]);

  useEffect(() => {
    if (initialized && userExists) {
      ReactGA.set({
        dimension3: fullPageUrl,
      });

      ReactGA.send({ hitType: "pageview", page: fullPageUrl });
    }
  }, [initialized, userExists, user, location, fullPageUrl]);
};
