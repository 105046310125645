import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DriversIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M15.2812 6.56982C15.2812 7.53232 14.975 8.31982 14.3625 8.93232C13.75 9.54482 12.9625 9.85107 12 9.85107C11.0375 9.85107 10.25 9.54482 9.6375 8.93232C9.025 8.31982 8.71875 7.53232 8.71875 6.56982C8.71875 5.60732 9.025 4.81982 9.6375 4.20732C10.25 3.59482 11.0375 3.28857 12 3.28857C12.9625 3.28857 13.75 3.59482 14.3625 4.20732C14.975 4.81982 15.2812 5.60732 15.2812 6.56982Z"
        fill="currentColor"
      />
      <path
        d="M19 14.8212V15.5649C19 15.9237 18.8688 16.2387 18.615 16.4924C18.3612 16.7462 18.055 16.8774 17.6875 16.8774H16.7337C16.6637 14.3224 14.5725 12.2749 12 12.2749C9.4275 12.2749 7.33625 14.3224 7.26625 16.8774H6.3125C5.945 16.8774 5.63875 16.7462 5.385 16.4924C5.13125 16.2387 5 15.9237 5 15.5649V14.8212C5 14.2612 5.14 13.7887 5.41125 13.3949C5.69125 13.0012 6.05 12.7037 6.4875 12.5024C7.4675 12.0649 8.40375 11.7324 9.29625 11.5137C10.1975 11.2949 11.0988 11.1899 12 11.1899C12.9012 11.1899 13.8025 11.2949 14.6862 11.5224C15.5787 11.7499 16.515 12.0737 17.4862 12.5024C17.9413 12.7037 18.3088 13.0012 18.58 13.3949C18.86 13.7887 19 14.2612 19 14.8212Z"
        fill="currentColor"
      />
      <path
        d="M16.2963 16.8774C16.2263 14.5674 14.3276 12.7124 12.0001 12.7124C9.67257 12.7124 7.77382 14.5674 7.70382 16.8774C7.69507 16.9212 7.69507 16.9737 7.69507 17.0174C7.69507 19.3974 9.62882 21.3312 12.0001 21.3312C14.3713 21.3312 16.3051 19.3974 16.3051 17.0174C16.3051 16.9737 16.3051 16.9212 16.2963 16.8774ZM11.1426 19.8349C11.0463 19.9137 10.9238 19.9574 10.8013 19.9574C10.7138 19.9574 10.6263 19.9312 10.5388 19.8874C9.70757 19.4674 9.09507 18.7149 8.86757 17.8224C8.81507 17.6124 8.87632 17.4024 9.02507 17.2624C9.16507 17.1312 9.37507 17.0962 9.55882 17.1574C10.3901 17.4724 11.0463 18.2424 11.3263 19.2399C11.3876 19.4674 11.3176 19.6949 11.1426 19.8349ZM10.1101 15.9412C9.84757 15.9412 9.61132 15.8187 9.48007 15.6087C9.34882 15.3987 9.36632 15.1537 9.53257 14.9524C10.1451 14.2087 11.0463 13.7887 12.0001 13.7887C12.9538 13.7887 13.8551 14.2087 14.4676 14.9524C14.6338 15.1449 14.6513 15.3899 14.5201 15.6087C14.3976 15.8187 14.1526 15.9412 13.8901 15.9412H10.1101ZM13.4613 19.8874C13.3738 19.9312 13.2863 19.9574 13.1988 19.9574C13.0763 19.9574 12.9538 19.9137 12.8576 19.8349C12.6826 19.6949 12.6126 19.4674 12.6738 19.2399C12.9538 18.2424 13.6101 17.4724 14.4413 17.1574C14.6251 17.0962 14.8263 17.1312 14.9751 17.2624C15.1238 17.4024 15.1851 17.6037 15.1326 17.8224C14.8963 18.7149 14.2926 19.4674 13.4613 19.8874Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DriversIcon;
