import { Dispatch, SetStateAction } from "react";
import {
  ActivityDates,
  SchedulePayloadDto,
  ScheduleRepeatPeriod,
} from "../types";
import {
  DATE_FORMATS,
  DriverDataTypes,
  TABLE_NAMES,
  VehicleDataTypes,
  useDateTime,
} from "../../../shared";

export const useScheduleResourceFunctions = (resourceName: TABLE_NAMES) => {
  const { subtractDaysFromDate } = useDateTime();

  const isDriversSchedule = resourceName === TABLE_NAMES.Drivers;

  const driverSchedulePayload = {
    name: "",
    startDate: "",
    customRepeat: 0,
    repeat: null,
    fileTypes: [DriverDataTypes.DriverCard],
    global: false,
  };

  const vehicleSchedulePayload = {
    name: "",
    startDate: "",
    customRepeat: 0,
    repeat: null,
    fileTypes: [VehicleDataTypes.Overview],
    global: false,
    activities: {},
  };

  const initialSchedulePayload = isDriversSchedule
    ? driverSchedulePayload
    : vehicleSchedulePayload;

  const activityDatesInitialState = {
    startDate: "",
    endDate: "",
  };

  const updateActivityDates = (
    schedulePayload: SchedulePayloadDto,
    repeatPeriod: ScheduleRepeatPeriod | null,
    inputError: {
      name: string;
      startDate: string;
    },
    setSchedulePayload: Dispatch<SetStateAction<SchedulePayloadDto>>,
    setActivityDates: (value: SetStateAction<ActivityDates>) => void
  ) => {
    const { fileTypes, startDate } = schedulePayload;

    if (!isDriversSchedule) {
      const activitiesReady =
        repeatPeriod && startDate && !inputError.startDate;

      const activitiesValue =
        activitiesReady && fileTypes.includes(VehicleDataTypes.Activities)
          ? {
              startDate: subtractDaysFromDate(repeatPeriod?.days, startDate),
              endDate: subtractDaysFromDate(1, startDate),
            }
          : {};

      setSchedulePayload({
        ...schedulePayload,
        activities: activitiesValue,
      });

      const customActivityDates = activitiesReady && {
        startDate: subtractDaysFromDate(
          repeatPeriod?.days,
          startDate,
          DATE_FORMATS.YearMonthDay
        ),
        endDate: subtractDaysFromDate(1, startDate, DATE_FORMATS.YearMonthDay),
      };

      setActivityDates(customActivityDates || activityDatesInitialState);
    }
  };
  return {
    isDriversSchedule,
    initialSchedulePayload,
    activityDatesInitialState,
    updateActivityDates,
  };
};
