import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../../../../Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import { useMediaQueries } from "../../../../../../shared";

interface DeleteApiCredentialsDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  closeDialog: () => void;
  handleDelete: () => void;
}

const DeleteApiCredentialsDialog = ({
  isOpen,
  isLoading,
  closeDialog,
  handleDelete,
}: DeleteApiCredentialsDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();

  return (
    <Dialog
      title={t("Dialog##api management##confirm delete dialog title")}
      description={t(
        "Dialog##api management##confirm delete dialog description"
      )}
      open={isOpen}
      close={closeDialog}
      extendToMaxWidth={toMd}
      isActionsSeparator
      submit={handleDelete}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={closeDialog}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##delete")}
          confirmationButtonTestId="delete-api-credentials-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="delete-api-credentials-cancel-button"
        />
      }
    />
  );
};

export default DeleteApiCredentialsDialog;
