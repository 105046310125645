import { useTranslation } from "react-i18next";

import InputField from "../../components/InputField/InputField";

interface OtpInputFieldProps {
  isLoading: boolean;
  error: string | null;
  onChange: (newValue: string) => void;
  isRequired?: boolean;
}

export const OtpInputField = ({
  isLoading,
  error,
  onChange,
  isRequired,
}: OtpInputFieldProps) => {
  const { t } = useTranslation();

  return (
    <InputField
      data-testid="otp-input-field"
      autoFocus
      autoComplete="one-time-code"
      fullWidth
      required={isRequired}
      disabled={isLoading}
      errorText={error}
      labelLeft={t("Password##six digit code")}
      placeholder={t("Dialog##enter")}
      size="medium"
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
