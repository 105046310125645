export const SECTION_OPEN_DELAY_ON_RESET = "1s";
export const SECTION_OPEN_DELAY_NOT_RESET = "0s";
export const MAX_DESCRIPTION_LENGTH = 100;
export const DESKTOP_DETAILS_MARGIN_TOP = "22px";
export const DESKTOP_DETAILS_MARGIN_BOTTOM = "24px";
export const MARGIN_LEFT_AND_RIGHT = "48px";


export const COMPANIES_GENERAL_DETAILS_ITEMS = [
    {
        title: "Details##parent company"
    }
]