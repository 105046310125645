/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import { useStyles } from "../../styles/styles";
import Button from "../../../Button/Button";
import { useDialog } from "../../../PageHeader/actions/hooks/useDialog";
import AddVehiclesDialog from "../../../PageHeader/actions/dialogs/vehicles/AddVehicleDialog/AddVehiclesDialog";
import AddDriverDialog from "../../../PageHeader/actions/dialogs/drivers/AddDriverDialog/AddDriverDialog";
import InviteUserDialog from "../../../PageHeader/actions/dialogs/users/InviteUserDialog/InviteUserDialog";
import TableIsEmptyIcon from "../../../../assets/customIcons/emptyTable/TableIsEmptyIcon";
import { tableName } from "../../../../atoms/atoms";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  DRIVERS_ACTIONS,
  ROUTES,
  TABLE_NAMES,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import { FILES_TABLES } from "../../../../Pages/Files";
import { USERS_TABLES } from "../../../../Pages/Users/types";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../roles/types/enums";
import { DashboardCardNames } from "../../../../Pages/Dashboard/types";

interface INoInitialResultsProps {
  dashboardCardName?: DashboardCardNames;
}

const NoInitialResults = ({ dashboardCardName }: INoInitialResultsProps) => {
  const { colors } = useContext(ThemeContext);

  const { getAccessStatus } = useAuth();

  const location = useLocation();
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const currentTableName = useRecoilValue(tableName);

  const { emptyTableTitle, emptyTableDescription } = useStyles();

  const isUserInvitationsTable = location.pathname === ROUTES.UsersInvitations;

  const userPermissionsForTables: { [key: string]: USER_PERMISSIONS } = {
    [TABLE_NAMES.Vehicles]: USER_PERMISSIONS.ManageVehicles,
    [TABLE_NAMES.Drivers]: USER_PERMISSIONS.ManageDrivers,
  };

  const isButtonDisplayed = () => {
    switch (currentTableName) {
      case TABLE_NAMES.Files:
      case TABLE_NAMES.Companies:
      case TABLE_NAMES.Dashboard:
        return false;

      case TABLE_NAMES.Users:
        return !isUserInvitationsTable;

      default:
        return getAccessStatus(userPermissionsForTables[currentTableName!]);
    }
  };

  const handleAddItemClick = () => {
    switch (currentTableName) {
      case TABLE_NAMES.Vehicles:
        return openDialog(VEHICLES_ACTIONS.AddVehicle);
      case TABLE_NAMES.Drivers:
        return openDialog(DRIVERS_ACTIONS.AddDriver);
      case TABLE_NAMES.Users:
        return openDialog(USERS_ACTIONS.InviteUser);
      default:
        return console.log("Add item click");
    }
  };

  const emptyTableName = () => {
    switch (currentTableName) {
      case TABLE_NAMES.Files:
        return location.pathname === ROUTES.VehicleFiles
          ? FILES_TABLES.VehicleFiles.toLowerCase()
          : FILES_TABLES.DriverFiles.toLowerCase();

      case TABLE_NAMES.Users:
        return location.pathname === ROUTES.UsersInvitations
          ? USERS_TABLES.UsersInvitations.toLowerCase()
          : currentTableName;

      case TABLE_NAMES.Dashboard:
        return dashboardCardName?.toLowerCase();

      default:
        return currentTableName;
    }
  };

  return (
    <Fragment>
      <div>
        <TableIsEmptyIcon
          css={css({
            fontSize: "120px",
          })}
        />
      </div>
      <Typography
        variant="body1"
        sx={{ color: colors.textDark }}
        css={css(emptyTableTitle)}
      >
        {t(
          `Table##empty table##no initial results##title##${emptyTableName()}`
        )}
      </Typography>
      <Typography
        variant="body2"
        css={css(emptyTableDescription)}
        sx={{ color: colors.textSecondary }}
      >
        {t(
          `Table##empty table##no initial results##description##${emptyTableName()}`
        )}
      </Typography>

      {isButtonDisplayed() && (
        <>
          <Button
            variant="textOnly"
            color="primary"
            size="small"
            onClick={handleAddItemClick}
          >
            {t(`Table##empty table##button##add##${emptyTableName()}`)}
          </Button>
          <AddVehiclesDialog
            isOpen={isDialogOpen[VEHICLES_ACTIONS.AddVehicle]}
            onClose={closeDialog}
          />
          <AddDriverDialog
            isOpen={isDialogOpen[DRIVERS_ACTIONS.AddDriver]}
            onClose={closeDialog}
          />
          <InviteUserDialog
            tableName={TABLE_NAMES.Users}
            isOpen={isDialogOpen[USERS_ACTIONS.InviteUser]}
            onClose={closeDialog}
          />
        </>
      )}
    </Fragment>
  );
};

export default NoInitialResults;
