export enum PaperHeights {
  xs = "260px",
  sm = "370px",
  medium = "422px",
  large = "458px",
  xlarge = "528px",
}

export enum PaperContentHeights {
  header = "60px",
  marginBottom = "24px",
}

export enum DashboardCardNames {
  CompanyCards = "Company cards",
}
