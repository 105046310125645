/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useStyles } from "../styles";
import { useContext } from "react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Typography } from "@mui/material";

import { CircleStepperStatusTypes } from "../types";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../shared";

interface CircleStepperFunctionsProps {
  showLeftHorizontalLine?: boolean;
  status: CircleStepperStatusTypes;
  showRightHorizontalLine?: boolean;
  lastElement?: boolean;
  stepTitle?: string;
}

const useCircleStepperFunctions = ({
  showLeftHorizontalLine,
  status,
  showRightHorizontalLine,
  lastElement,
  stepTitle,
}: CircleStepperFunctionsProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd } = useMediaQueries();

  const {
    pulsatingCircle,
    pulsatingDot,
    stagesFromMdContainer,
    horizontalStickStyle,
    verticalStickStyle,
    stagesToMdContainer,
    stageIconContainerToMd,
    downloadStepTextToMd,
  } = useStyles({
    colors,
    showLeftHorizontalLine,
    fromMd,
  });

  const generateRightHorizontalLineColor = (
    status: CircleStepperStatusTypes
  ) => {
    switch (status) {
      case CircleStepperStatusTypes.success:
        return colors.blue700;
      case CircleStepperStatusTypes.current:
        return colors.textDarkDisabled;
      case CircleStepperStatusTypes.notCompleted:
        return colors.textDarkDisabled;

      default:
        return colors.blue700;
    }
  };

  const generateLeftHorizontalLineColor = () =>
    status === CircleStepperStatusTypes.notCompleted
      ? colors.textDarkDisabled
      : colors.blue700;

  const generatetextColor = (status: CircleStepperStatusTypes) =>
    status === CircleStepperStatusTypes.notCompleted
      ? colors.textDarkDisabled
      : colors.textDark;

  const generateCurrentStepCircle = () => (
    <div className="pulsating-circle" css={css(pulsatingCircle)}>
      <div className="pulsating-dot" css={css(pulsatingDot)}></div>
    </div>
  );

  const generateViewFromMd = (
    generateCircleStepperIcon: (
      status: CircleStepperStatusTypes
    ) => EmotionJSX.Element | null
  ) => (
    <>
      <div css={css(stagesFromMdContainer)}>
        {showLeftHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: generateLeftHorizontalLineColor(),
              },
              horizontalStickStyle,
            ])}
          />
        )}
        {generateCircleStepperIcon(status)}
        {showRightHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: lastElement
                  ? "transparent"
                  : generateRightHorizontalLineColor(status),
              },
              horizontalStickStyle,
            ])}
          />
        )}
      </div>
      <Typography
        variant="body2"
        color={generatetextColor(status)}
        sx={{ marginTop: "16px" }}
      >
        {stepTitle}
      </Typography>
    </>
  );

  const generateViewToMd = (
    generateCircleStepperIcon: (
      status: CircleStepperStatusTypes
    ) => EmotionJSX.Element | null
  ) => (
    <div css={css(stagesToMdContainer)}>
      <div css={css(stageIconContainerToMd)}>
        {/* top vertical line */}
        {showLeftHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: generateLeftHorizontalLineColor(),
              },
              verticalStickStyle,
            ])}
          />
        )}
        {generateCircleStepperIcon(status)}

        {/* bottom vertical line */}
        {showRightHorizontalLine && (
          <div
            css={css([
              {
                backgroundColor: lastElement
                  ? "transparent"
                  : generateRightHorizontalLineColor(status),
              },
              verticalStickStyle,
            ])}
          />
        )}
      </div>
      <Typography
        variant="body2"
        color={generatetextColor(status)}
        sx={downloadStepTextToMd as CSSObject}
      >
        {stepTitle}
      </Typography>
    </div>
  );

  return { generateViewFromMd, generateViewToMd, generateCurrentStepCircle };
};

export default useCircleStepperFunctions;
