import { Dispatch } from "react";

import {
  afterLabelSpan,
  afterLabelSpanTitle,
  checkIfArrayHasAnyValues,
  generateAddedRowString,
  generateBoldTickElement,
  generateDeductedStringRow,
  generateReservedStringRow,
} from "./functions";

import { emptyDoughnutStyles } from "../../config";

import { ThemeColors } from "../../../../shared";

export const cutout = 48;
export const diameter = "142";
const fontFamily = "Open Sans, sans-serif";
const creditsBalanceTooltipWidth = "176px";
const chartFontSize = 10;

export const options: any = (
  colors: ThemeColors,
  _data: any,
  setHoveredElement: Dispatch<any>
) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    onHover: function(_event: any, activeElements: any) {
      if (activeElements.length > 0) {
        setHoveredElement(activeElements[0].index);
      }
    },
    plugins: {
      tooltip: {
        // disable default tooltip
        enabled: false,
      },
      legend: {
        display: false,
      },
      emptyDoughnut: emptyDoughnutStyles(colors, cutout),
    },
    cutout: cutout,
  };
};

export const lineChartOptions = (
  colors: ThemeColors,
  t: any,
  monthsArray?: string[],
  monthlyData?: number[]
) => {
  return {
    responsive: true,
    // line below enables possibility to change height of chart
    maintainAspectRatio: false,
    scales: {
      x: {
        afterUpdate: (axis: any) => {
          axis.marginRight = 0;
        },
        ticks: {
          stepSize: 1,
          font: {
            size: chartFontSize,
            weight: generateBoldTickElement(monthsArray),
            family: fontFamily,
          },
          color: colors.textSecondary,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grace: 0,
        // line below removes padding from top of ticks labels
        afterUpdate: (axis: any) => {
          axis.paddingTop = monthsArray && monthsArray?.length > 0 ? 0 : 5;
        },
        ticks: {
          font: {
            size: chartFontSize,
            weight: 400,
            family: fontFamily,
          },
          color: colors.textSecondary,
          // line below add padding around of ticks labels
          padding: 10,
        },
        grid: {
          drawTicks: false,
          drawBorder: false,
          color: colors.gray100,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        max: !checkIfArrayHasAnyValues(monthlyData) ? 300 : undefined,
      },
    },
    elements: {
      point: {
        backgroundColor: colors.white,
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // disable default tooltip
        enabled: false,
        external: customCreditsBalanceTooltip(colors, t),
        callbacks: {
          label: function(context: any) {
            const lastMonthInArray =
              monthsArray && context.dataIndex === monthsArray?.length - 1;

            const prevMonthsIncludedCurMonth =
              monthsArray && context.dataIndex !== monthsArray?.length - 1;

            const estimatedLabel =
              lastMonthInArray &&
              `${t("Dashboard##estimated")}: ${context.raw}`;

            const creditsLabel =
              prevMonthsIncludedCurMonth &&
              `${t("Dashboard##credits")}: ${context.raw}`;

            let label = estimatedLabel || creditsLabel;
            return label;
          },

          afterLabel: function(tooltipItem: any) {
            const afterLabel =
              tooltipItem.dataset.labels[tooltipItem.dataIndex];

            return afterLabel;
          },
        },
      },
      lineChartAreaBorder: {
        borderColor: colors.gray100,
        borderWidth: 1,
        borderline: 6,
      },
    },
  };
};

export const lineChartAreaBorder = {
  id: "lineChartAreaBorder",
  beforeDraw(chart: any, _args: any, options: any) {
    const {
      ctx,
      chartArea: { left, top, width, height },
    } = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  },
};

export const customCreditsBalanceTooltip = (colors: any, t: any) =>
  function(context: any) {
    let tooltipEl = document.getElementById("credits-line-tooltip");

    const statusElement = context.tooltip.body[0].lines[0];

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "credits-line-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    function getBody(bodyItem: any) {
      return bodyItem.lines;
    }

    if (tooltipEl) {
      const tooltipModel = context.tooltip;

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = "0";

        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      setCaretPosition(tooltipModel, tooltipEl);

      // Set Text
      setText(tooltipEl, tooltipModel, getBody, t);

      const tooltipBody = tooltipModel.body[0].after[0];

      // Display after labels
      const showDeducted =
        tooltipBody.deducted !== 0 && tooltipBody.reserved === undefined;

      const showAdded = tooltipBody.added !== 0;

      const showReserved =
        tooltipBody.reserved !== 0 && tooltipBody.reserved !== undefined;

      const showAllRequiredLabels =
        (showDeducted && showAdded) || (showAdded && showReserved);

      const noAfterLabels = !showDeducted && !showAdded && !showReserved;

      // Display, position, and set styles
      const position = context.chart.canvas.getBoundingClientRect();

      tooltipEl.style.opacity = "1";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.display =
        statusElement === " undefined" ? "none" : "block";

      const allLabelsYDistance = showAllRequiredLabels && 140;
      const noLabelsYDistance = noAfterLabels && 80;

      const yNumber = allLabelsYDistance || noLabelsYDistance || 110;

      tooltipEl.style.left =
        position.left + window.scrollX + tooltipModel.caretX - 50 + "px";

      tooltipEl.style.top =
        position.top + window.scrollY + tooltipModel.caretY - yNumber + "px";

      tooltipEl.style.width = creditsBalanceTooltipWidth;
      tooltipEl.style.padding = "8px";
      tooltipEl.style.background = colors.white;
      tooltipEl.style.color = colors.textDark;
      tooltipEl.style.boxShadow = "0px 2px 8px rgba(15, 23, 42, 0.16)";
      tooltipEl.style.borderRadius = "8px";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.transition = "opacity 0.3s";
    }
  };

const setCaretPosition = (tooltipModel: any, tooltipEl: HTMLElement) => {
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }
};

const setText = (
  tooltipEl: HTMLElement | null,
  tooltipModel: any,
  getBody: any,
  t: any
) => {
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = "<thead>";

    innerHtml +=
      '</thead><tbody style="display: flex; flex-direction: column; width: 100%">';

    bodyLines.forEach(function(body: any, i: number) {
      const afterLabel = tooltipModel.body[i].after[0];

      const afterLabelDateString = `${new Date(
        afterLabel.date.slice(0, 10)
      ).toLocaleString("default", {
        month: "long",
      })}, ${new Date(afterLabel.date).getFullYear()}`;

      const afterLabelDeducted = `${afterLabel.deducted}`;
      const afterLabelAdded = `${afterLabel.added}`;
      const afterLabelReserved = `${afterLabel.reserved}` || undefined;
      const hasReservedValue =
        afterLabelReserved !== "0" && afterLabelReserved !== "undefined";
      const shouldDisplayDeducted =
        afterLabelDeducted !== "0" && afterLabelReserved === "undefined";
      const shouldDisplayAdded = afterLabelAdded !== "0";

      const hasAnyAfterLabel =
        shouldDisplayDeducted || shouldDisplayAdded || hasReservedValue;

      const colors = tooltipModel.labelColors[i];

      let style = "background: white";
      let rowStyle = "border-spacing: 0";
      style += "; border-color:" + colors.borderColor;

      let dotStyle =
        "background:" +
        `${tooltipModel.labelColors[i].backgroundColor}` +
        "; " +
        "border:" +
        `2px solid ${tooltipModel.labelColors[i].borderColor} !important` +
        "; width: 5px; height: 5px; border-radius: 50%; display: inline-block; margin-right: 8px";

      const labelSpan =
        '<span style="font-weight: 600; font-size: 14px; line-height: 20px' +
        style +
        '">' +
        body +
        "</span>";

      const dot = '<span style="' + dotStyle + '"></span>';

      // colors are as close as possible, since the exact color is not provided
      const labelSeperator = hasAnyAfterLabel
        ? `<tr style="${rowStyle}; margin: 4px 0"><td style="height: 1px; background: rgb(232,232,232); width: ${creditsBalanceTooltipWidth}; padding: 0;" ></td></tr>`
        : "";

      // set deducted text:
      const deductedSpanLabel = afterLabelSpanTitle(t("Dashboard##deducted"));
      const deductedSpanValue = afterLabelSpan(
        afterLabelDeducted,
        "rgb(153, 27, 27)"
      );
      const deductedString = `${deductedSpanLabel}: ${deductedSpanValue}`;

      // set added text:
      const addedSpanLabel = afterLabelSpanTitle(t("Dashboard##added"));
      const addedSpanValue = afterLabelSpan(afterLabelAdded, "rgb(20, 82, 45)"); // colors are as close as possible, since the exact color is not provided
      const addedString = `${addedSpanLabel}: ${addedSpanValue}`;

      // set reserved text:
      const reservedSpanLabel = afterLabelSpanTitle(t("Dashboard##Reserved"));
      const reservedSpanValue = afterLabelSpan(
        afterLabelReserved as string,
        "unset"
      );
      const reservedString = `${reservedSpanLabel}: ${reservedSpanValue}`;

      innerHtml += `
          <tr style="${rowStyle}"><td>${dot}${labelSpan}</td></tr>
          <tr style="${rowStyle}"><td>${afterLabelSpan(
        afterLabelDateString,
        undefined,
        "400"
      )}</td></tr>
          ${labelSeperator}

          ${generateDeductedStringRow(
            afterLabelDeducted,
            afterLabelReserved,
            rowStyle,
            deductedString
          )}

          ${generateReservedStringRow(
            afterLabelReserved,
            rowStyle,
            reservedString
          )}
          ${generateAddedRowString(afterLabelAdded, rowStyle, addedString)}
        `;
    });

    innerHtml += "</tbody>";

    let tableRoot: any = tooltipEl?.querySelector("table");
    tableRoot.innerHTML = innerHtml;
  }
};
