import { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { INPUT_LENGTH } from "../../../shared";

const usePasswordValidation = () => {
  const { t } = useTranslation();

  const [passwordRequirements, setPasswordRequirements] = useState({
    uppercase: false,
    lowercase: false,
    length: false,
  });

  const [passwordError, setPasswordError] = useState<any>({
    password1: null,
    password2: null,
  });

  const validatePasswordCreation = (password: string) => {
    setPasswordRequirements({
      uppercase: /[A-Z]/.test(password) ? true : false,
      lowercase: /[a-z]/.test(password) ? true : false,
      length: password.length >= INPUT_LENGTH.MinPassword ? true : false,
    });
  };

  const validatePasswordMatching = (password1: string, password2: string) => {
    if (password1 !== password2) {
      setPasswordError({
        ...passwordError,
        password2: t("Auth##passwords do not match"),
      });
    }
  };

  const validatePassword = (passwordName: string) => {
    const requirementValues = Object.values(passwordRequirements);
    const isValid = requirementValues.indexOf(false) === -1;

    if (passwordName.length > 0 && !isValid) {
      setPasswordError({
        ...passwordError,
        password1: t("Auth##invalid password format"),
      });
    }
  };

  const clearPasswordError = (passwordName: string) => {
    setPasswordError({
      ...passwordError,
      [passwordName]: null,
    });
  };

  const validatePasswordLength = (
    e: any,
    setErrorMessage: Dispatch<string>
  ) => {
    const inputValue = e.target.value;
    if (inputValue.length > INPUT_LENGTH.MaxPassword) {
      setErrorMessage(
        t("Password##password too long", {
          maxNumber: INPUT_LENGTH.MaxPassword,
        })
      );
    }
  };

  return {
    passwordRequirements,
    passwordError,
    setPasswordError,
    validatePasswordCreation,
    validatePasswordMatching,
    validatePassword,
    clearPasswordError,
    validatePasswordLength,
  };
};

export default usePasswordValidation;
