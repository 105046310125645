import RectangleLoader from "../../../shared/components/loaders/RectangleLoader";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../Button/Button";

interface IClearAllButtonProps {
  onButtonClick: () => void;
  buttonText: string;
  isInitialLoading: boolean;
}

const ClearAllButton = ({
  onButtonClick,
  buttonText,
  isInitialLoading,
}: IClearAllButtonProps) => {
  return isInitialLoading ? (
    <RectangleLoader testId="clear-all-button-loader" width={77} height={20} />
  ) : (
    <Button
      color={BUTTON_COLORS.White}
      size={BUTTON_SIZES.Small}
      variant={BUTTON_VARIANTS.TextOnly}
      onClick={onButtonClick}
    >
      {buttonText}
    </Button>
  );
};

export default ClearAllButton;
