/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import {
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import TableCheckbox from "./TableCheckbox";

import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../../functions";

import TableSortLabel from "../../../../TableSortLabel/TableSortLabel";

import {
  Column,
  getDefaultSortedColumn,
  tableColumnDropShadow,
  tableColumnRightDropShadow,
  TableRowData,
} from "../../../../../shared";
import {
  tableInlineActions,
  selectedTableRows,
  tableColumns,
  rowsEnabledToSelect,
  tableSortedColumn,
  tableLoading,
  isTableCheckable,
} from "../../../../../atoms/atoms";

interface ITableHeadProps {
  scrollHorizontal: boolean;
  companiesPage?: boolean;
}

const TableHead = ({ scrollHorizontal, companiesPage }: ITableHeadProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedRows, setSelectedRows] = useRecoilState<any>(
    selectedTableRows
  );
  const [sortedColumn, setSortedColumn] = useRecoilState<any>(
    tableSortedColumn
  );

  const isCheckable = useRecoilValue(isTableCheckable);
  const isLoading = useRecoilValue(tableLoading);

  const columns = useRecoilValue<Column[]>(tableColumns);
  const inlineActions = useRecoilValue(tableInlineActions);
  const selectableRows = useRecoilValue(rowsEnabledToSelect);

  const sortedColumnValueFromLocalStorage = pageGlobalStateObjectResult(
    location.pathname,
    sortedColumn
  );

  const defaultSortedColumn = getDefaultSortedColumn(
    location.pathname,
    sortedColumnValueFromLocalStorage
  );

  const { orderBy, descending } = defaultSortedColumn || {};

  useEffect(() => {
    setSortedColumn((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,
        defaultSortedColumn
      )
    );

    // eslint-disable-next-line
  }, [location.pathname, setSortedColumn]);

  const sortColumn = (resource: string) => {
    if (!isLoading) {
      const getOrderBy = () => {
        if (!descending && orderBy === resource) {
          return "";
        }

        return resource;
      };

      const getDescendingStatus = () => {
        if (orderBy === resource) {
          return !descending;
        }

        return true;
      };

      const newOrderBy = getOrderBy();
      const descendingStatus = getDescendingStatus();

      const updatedColumnValue = {
        orderBy: newOrderBy === "" ? "" : newOrderBy,
        descending: newOrderBy === "" ? "" : descendingStatus,
      };

      setSortedColumn((prev: any) =>
        getPageGlobalStateObjectValue(
          location.pathname,
          prev,
          updatedColumnValue
        )
      );
    }
  };

  const setLeftPosition = (isSticky: boolean | undefined) =>
    isSticky ? "60px" : "unset";

  const handletableHeadCheckboxChange = () => {
    setSelectedRows((prev: any) =>
      prev.concat(selectableRows.map((row: TableRowData) => row.id))
    );
  };

  const isChecked = () =>
    selectedRows.length > 0 &&
    selectedRows.every((rowId: string) =>
      selectableRows.some((row: TableRowData) => row.id === rowId)
    );

  return (
    <MuiTableHead data-testid="table-head">
      <MuiTableRow>
        {isCheckable && (
          <TableCheckbox
            onTableHeadCheckboxChange={handletableHeadCheckboxChange}
            isChecked={isChecked()}
            disabled={selectableRows?.length === 0}
          />
        )}
        {columns.map(
          ({ name, isSticky, width, sortResource, isActive }: Column) => {
            const isActiveSorting = orderBy === sortResource;

            const isDescending = isActiveSorting ? descending : true;

            return (
              isActive && (
                <MuiTableCell
                  sx={{ minWidth: width }}
                  css={css([
                    {
                      left: !isCheckable ? "0" : setLeftPosition(isSticky),
                      zIndex: isSticky ? "3" : "2",
                    },
                    isSticky && tableColumnRightDropShadow(scrollHorizontal),
                  ])}
                  key={name}
                >
                  {!companiesPage && sortResource ? (
                    <TableSortLabel
                      isActive={isActiveSorting}
                      isDescending={isDescending}
                      onSortClick={() => sortColumn(sortResource)}
                    >
                      {t(`Table##${name.toLowerCase()}`)}
                    </TableSortLabel>
                  ) : (
                    !companiesPage && t(`Table##${name.toLowerCase()}`)
                  )}
                </MuiTableCell>
              )
            );
          }
        )}
        {/* Add drop shadow on horizontal scroll */}
        {inlineActions.length > 0 && (
          <MuiTableCell
            sx={[
              {
                position: "sticky",
                right: "0",
                width: "0 !important",
                padding: "0 !important",
              },
              tableColumnDropShadow(scrollHorizontal),
            ]}
          />
        )}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default React.memo(TableHead);
