import { useState } from "react";
import { useTranslation } from "react-i18next";

import EnableTwoFA from "../EnableTwoFA/EnableTwoFA";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../../../../Button/Button";
import DialogNewFeature from "../../../../../../../DialogNewFeature/DialogNewFeature";

import { ReactComponent as TwoFactorDisabled } from "../../../../../../../../assets/customIcons/infoPopUps/TwoFactorDisabled.svg";
import { useAuth } from "../../../../../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../../../shared/featureToggles/types";

const TwoFactorSuggestion = () => {
  const { user } = useAuth();
  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const isRoleAllowedToToggle2FA =
    user &&
    isReleaseFeatureEnabledForRole(FEATURE_NAMES.ShowToggle2FA, user?.role);

  const isTwoFactorEnabled = user && user.twoFactorEnabled;

  const showTwoFaSuggestionDialog =
    user && !isRoleAllowedToToggle2FA && !isTwoFactorEnabled;

  const [is2FaSuggestionOpen, setIs2FaSuggestionOpen] = useState<boolean>(
    showTwoFaSuggestionDialog || false
  );

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleSubmit = () => {
    setIs2FaSuggestionOpen(false);
    openDialog();
  };

  return (
    <>
      {showTwoFaSuggestionDialog && (
        <DialogNewFeature
          open={is2FaSuggestionOpen}
          title={t("Password##two-factor suggestion disabled title")}
          description={t(
            "Password##two-factor suggestion disabled description"
          )}
          image={<TwoFactorDisabled />}
          submit={handleSubmit}
          actions={
            <Button
              fullWidth
              color={BUTTON_COLORS.Primary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              type="submit"
            >
              {t("Button##continue")}
            </Button>
          }
        />
      )}

      <EnableTwoFA isOpen={isOpen} onCloseDialog={closeDialog} />
    </>
  );
};

export default TwoFactorSuggestion;
