/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import ListChoice from "../../../../components/ListChoice/ListChoice";

interface EditChoiceProps {
  testId?: string;
  onButtonClick: () => void;
  dialog?: React.ReactNode;
}

const EditChoice = ({ testId, onButtonClick, dialog }: EditChoiceProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ListChoice
        data-testid={testId || "edit-choice"}
        title={t("Details##edit")}
        iconLeft={
          <EditRoundedIcon
            css={css({ alignSelf: "center", width: "16px", height: "16px" })}
          />
        }
        onClick={onButtonClick}
        alignIconCenter
      />
      {dialog}
    </>
  );
};

export default EditChoice;
