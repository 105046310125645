import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { DownloadStatusLiveStatusesType } from "../components/DownloadStatusDialog/types";

import { ApiResources } from "../../../../../api/resources";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../shared/hooks/useResponse";

const useDownloadStatus = () => {
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { t } = useTranslation();

  const [
    downloadLiveStatuses,
    setDownloadLiveStatuses,
  ] = useState<DownloadStatusLiveStatusesType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      setIsLoading(true);
      const getVehiclesLiveStatuses = async () => {
        try {
          const response = await getData(ApiResources.VehiclesLiveStatuses);

          setDownloadLiveStatuses(response.data);
        } catch (error) {
          handleCommonError(error, t);
        } finally {
          setIsLoading(false);
        }
      };
      getVehiclesLiveStatuses();
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDownloadLiveStatuses]);

  return { downloadLiveStatuses, setDownloadLiveStatuses, isLoading };
};

export default useDownloadStatus;
