import {
  generateDataTypeIconBackground,
  generateDataTypeIconColor,
} from "./styleFunctions";

const ActivitiesDataTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={generateDataTypeIconBackground(props)}
      />
      <path
        d="M9.79373 7.15039V9.79414L8.13998 11.4366"
        stroke={generateDataTypeIconColor(props)}
        strokeWidth="1.4175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4637 8.62375C14.9237 5.98 12.5837 4 9.79375 4C6.59875 4 4 6.5875 4 9.79375C4 12.5275 5.89 14.8113 8.4325 15.4188"
        stroke={generateDataTypeIconColor(props)}
        strokeWidth="1.4175"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.618 21C11.4446 21 11.2982 20.9404 11.1789 20.8211C11.0596 20.7018 11 20.5554 11 20.382C11 20.2086 11.0596 20.0622 11.1789 19.9429C11.2982 19.8237 11.4446 19.764 11.618 19.764H19.2954C19.4689 19.764 19.6152 19.8237 19.7345 19.9429C19.8538 20.0622 19.9134 20.2086 19.9134 20.382C19.9134 20.5554 19.8538 20.7018 19.7345 20.8211C19.6152 20.9404 19.4689 21 19.2954 21H11.618ZM13.2608 18.7424C12.6372 18.7424 12.1045 18.5215 11.6627 18.0797C11.2209 17.6379 11 17.1052 11 16.4816V12.236C11 11.8939 11.1205 11.6023 11.3614 11.3614C11.6023 11.1205 11.8939 11 12.236 11H19.7673C20.1063 11 20.3965 11.121 20.6379 11.363C20.8793 11.6051 21 11.8949 21 12.2327V13.8626C21 14.2004 20.8793 14.4903 20.6379 14.7323C20.3965 14.9743 20.1063 15.0953 19.7673 15.0953H18.7424V16.4816C18.7424 17.1052 18.5207 17.6379 18.0773 18.0797C17.634 18.5215 17.1009 18.7424 16.4783 18.7424H13.2608ZM13.2641 17.5064H16.4751C16.7587 17.5064 17.0015 17.4058 17.2035 17.2044C17.4054 17.0031 17.5064 16.7611 17.5064 16.4783V12.236H12.236V16.4783C12.236 16.7611 12.3367 17.0031 12.538 17.2044C12.7393 17.4058 12.9814 17.5064 13.2641 17.5064ZM18.7424 13.8626H19.764V12.2327H18.7424V13.8626ZM13.2641 17.5064H12.236H17.5064H13.2641Z"
        fill={generateDataTypeIconColor(props)}
      />
    </svg>
  );
};

export default ActivitiesDataTypeIcon;
