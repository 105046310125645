import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = () =>
  ({
    label: {
      display: "flex",
      alignItems: "center",
      marginBottom: "4px",
      color: COLORS.Gray700,
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    titleLeft: {
      display: "flex",
      alignItems: "center",
    },
    titleTooltip: {
      display: "flex",
      alignItems: "center",
      marginLeft: "4px",

      "& > svg": {
        fontSize: "16px",
        color: COLORS.Blue700,
      },
    },
  } as CSSObject);
