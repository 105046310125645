export const containerWithDetailsStyle = (
  generateMarginLeft: () => "0" | "68px" | "236px",
  generateMarginRight: () => "0" | "unset" | "361px" | "472px",
  delay: "1s" | "0s"
) => {
  return {
    marginLeft: generateMarginLeft(),
    marginRight: generateMarginRight(),
    transition: `margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-right 0.3s cubic-bezier(0.4, 0, 0.2, 1) ${delay}`,
  };
};

export const containerWithoutDetailsStyle = (
  generateMarginLeft: () => "0" | "68px" | "236px"
) => {
  return {
    marginLeft: generateMarginLeft(),
    transition: `margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  };
};
