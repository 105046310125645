/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { diameter, options } from "../../config";
import {
  checkIfOnlyOneOptionHasValue,
  generateCreditsItemColor,
} from "../../functions";

import { CreditsDto, creditsObjectType, CreditsStatuses } from "../../../types";

import { useStyles as dashboardStyles } from "../../../../styles";
import { emptyDoughnut } from "../../../../config";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { useFormattedNumber } from "../../../../../../shared";

Chart.register(Tooltip, Legend, ArcElement);

interface CreditsDoughnutChartProps {
  creditsData: CreditsDto | null;
  setHovered: Dispatch<SetStateAction<boolean>>;
  setHoveredElement: Dispatch<any>;
  creditsObjectArray: creditsObjectType[] | null;
}

const CreditsDoughnutChart = ({
  creditsData,
  setHovered,
  setHoveredElement,
  creditsObjectArray,
}: CreditsDoughnutChartProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const [totalCount, setTotalCount] = useState<number>(0);

  const { formattedNumber } = useFormattedNumber(totalCount);

  const { canvasHolder, doughnutChartCenterInfo } = dashboardStyles({
    colors,
    diameter,
  });

  useEffect(() => {
    if (creditsData) {
      setTotalCount(creditsData.total);
    }
  }, [creditsData]);

  const dataArray =
    // Change negative value in a chart as positive number with Math.abs(i.count), otherwise the chart will look broken.
    // Changed value will not be reflected in the UI and the correct value will be shown in the list next to the doughnut chart.
    creditsObjectArray?.map((i: creditsObjectType) => Math.abs(i.count)) || [];

  const dataArrayBackgroundColors =
    creditsObjectArray?.map((i: creditsObjectType) =>
      generateCreditsItemColor(i.name, colors)
    ) || [];

  const noBorderColor = "transparent";
  const defaultBorderColor = colors.white;
  const shouldTriggerHover = totalCount > 0 || totalCount < 0;

  const data: any = {
    datasets: [
      {
        data: dataArray,
        labels: [CreditsStatuses.Reserved, CreditsStatuses.Available],
        backgroundColor: dataArrayBackgroundColors,
        hoverBackgroundColor: dataArrayBackgroundColors,

        // show full doughnut chart without borders if only one option has value
        borderRadius: checkIfOnlyOneOptionHasValue(dataArray) ? 0 : 2,
        borderWidth: checkIfOnlyOneOptionHasValue(dataArray) ? 0 : 2,
        borderColor: checkIfOnlyOneOptionHasValue(dataArray)
          ? noBorderColor
          : defaultBorderColor,
        hoverBorderColor: checkIfOnlyOneOptionHasValue(dataArray)
          ? noBorderColor
          : defaultBorderColor,
      },
    ],
  };

  const handleOnMouseOut = () => {
    setHovered(false);
    setHoveredElement(null);
  };

  const handleOnPointerEnter = (e: any) => {
    setHovered(true);
  };

  return (
    <div
      id="credits-doughnut-canvas-holder"
      style={canvasHolder as CSSProperties}
      data-testid={
        totalCount === 0
          ? "empty-credits-canvas-holder"
          : "credits-canvas-holder"
      }
    >
      <Doughnut
        id="credits-doughnut-chart"
        onPointerLeave={shouldTriggerHover ? handleOnMouseOut : undefined}
        onPointerEnter={shouldTriggerHover ? handleOnPointerEnter : undefined}
        data={data}
        options={options(colors, data, setHoveredElement)}
        width={+diameter}
        height={+diameter}
        plugins={[emptyDoughnut]}
        style={{ cursor: "pointer" }}
      />

      {/* centered total count of devices */}
      <div css={css(doughnutChartCenterInfo)}>
        {creditsData && (
          <>
            <Typography variant="body2">{formattedNumber}</Typography>
            <Typography variant="subtitle1semiBold">
              {t("Dashboard##credits")}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditsDoughnutChart;
