import { SetStateAction, useState } from "react";
import { MenuAnchorEl } from "../../../shared";

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

  const handleOpenMenu = (e: { currentTarget: SetStateAction<MenuAnchorEl> }) =>
    setAnchorEl(e.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  return { anchorEl, handleOpenMenu, handleCloseMenu };
};
