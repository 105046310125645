import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DoubleRightArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M13.2901 6.29376C12.9001 6.68376 12.9001 7.31376 13.2901 7.70376L17.1701 11.5838L13.2901 15.4638C12.9001 15.8538 12.9001 16.4838 13.2901 16.8738C13.6801 17.2638 14.3101 17.2638 14.7001 16.8738L19.2901 12.2838C19.6801 11.8938 19.6801 11.2638 19.2901 10.8738L14.7001 6.28376C14.3201 5.90376 13.6801 5.90376 13.2901 6.29376Z"
        fill="currentColor"
      />
      <path
        d="M6.2925 6.29376C5.9025 6.68376 5.9025 7.31376 6.2925 7.70376L10.1725 11.5838L6.2925 15.4638C5.9025 15.8538 5.9025 16.4838 6.2925 16.8738C6.6825 17.2638 7.3125 17.2638 7.7025 16.8738L12.2925 12.2838C12.6825 11.8938 12.6825 11.2638 12.2925 10.8738L7.7025 6.28376C7.3225 5.90376 6.6825 5.90376 6.2925 6.29376Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DoubleRightArrowIcon;
