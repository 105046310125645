import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import ScheduleDetailsDialog from "./ScheduleDetailsDialog";
import { DriverScheduleDto, ScheduleDto } from "../../../../../types";
import ListChoice from "../../../../../../ListChoice/ListChoice";
import DetailsIcon from "../../../../../../../assets/customIcons/actions/DetailsIcon";
import { MenuAnchorEl } from "../../../../../../../shared";

interface ScheduleDetailsProps {
  schedule: ScheduleDto | DriverScheduleDto;
  onActionsMenu: Dispatch<SetStateAction<MenuAnchorEl>>;
  isDriversPage?: boolean;
}

const ScheduleDetailsButton = ({
  schedule,
  onActionsMenu,
  isDriversPage,
}: ScheduleDetailsProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenScheduleDetailsDialog = () => {
    setIsOpen(true);
  };

  const handleCloseScheduleDetailsDialog = () => {
    setIsOpen(false);
    onActionsMenu(null);
  };

  return (
    <>
      <ListChoice
        title={t("Details##schedule details")}
        iconLeft={<DetailsIcon />}
        onClick={handleOpenScheduleDetailsDialog}
        alignIconCenter
      />

      <ScheduleDetailsDialog
        isDriversPage={isDriversPage}
        schedule={schedule}
        isOpen={isOpen}
        handleClose={handleCloseScheduleDetailsDialog}
      />
    </>
  );
};

export default ScheduleDetailsButton;
