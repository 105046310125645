import MultipleFiles from "./MultipleFiles/MultipleFiles";
import SingleFile from "./SingleFile/SingleFile";
import { DropzoneProps } from "./types";

const Dropzone = ({
  supportedFormats,
  isDisabled,
  countCsv,
  tooltipText,
  isMultiple,
  maxFileNameLength,
  maxFiles,
  isLoading,
  isUploaded,
}: DropzoneProps) => {
  return isMultiple ? (
    <MultipleFiles
      supportedFormats={supportedFormats}
      isDisabled={isDisabled}
      maxFileNameLength={maxFileNameLength}
      maxFiles={maxFiles}
      isLoading={isLoading}
      isUploaded={isUploaded}
    />
  ) : (
    <SingleFile
      supportedFormats={supportedFormats}
      isDisabled={isDisabled}
      maxFileNameLength={maxFileNameLength}
      countCsv={countCsv}
      tooltipText={tooltipText}
    />
  );
};

export default Dropzone;
