import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../../../../shared";

export const useStyles = (colors: ThemeColors) => {
  const borderStyle = `1px solid ${colors.gray200}`;
  const heightOfHalfXAxisEmptyBox = "22px";
  const heightOfXAxisEmptyBoxWithhorizontalBorder = "21px";

  return {
    boxWithRightBorder: {
      width: "calc(100% / 2)",
      height: heightOfHalfXAxisEmptyBox,
      backgroundColor: "transparent",
      borderRight: borderStyle,
      // marginTop and paddingBottom is used to make border longer then the box height,
      // since connected to boxWithLeftAndBottomBorders, which has borderRadius
      marginTop: "-6px",
      paddingBottom: "6px",
    },
    boxWithBottomBorder: {
      width: "calc(100% / 2)",
      height: heightOfXAxisEmptyBoxWithhorizontalBorder,
      backgroundColor: "transparent",
      borderBottom: borderStyle,
    },
    boxWithoutBorders: {
      width: "calc(100% / 2)",
      height: heightOfHalfXAxisEmptyBox,
      backgroundColor: "transparent",
    },
    boxWithLeftAndBottomBorders: {
      width: "calc(100% / 2 - 1px)",
      height: heightOfXAxisEmptyBoxWithhorizontalBorder,
      backgroundColor: "transparent",
      borderLeft: borderStyle,
      borderBottom: borderStyle,
      borderBottomLeftRadius: "6px 6px",
    },
  } as CSSObject;
};
