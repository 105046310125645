/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Paper } from "@mui/material";
import { useContext } from "react";

import CardTitle from "./components/CardTitle";

import { useStyles } from "../styles";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../shared";

interface CardWrapperProps {
  isTopSection: boolean;
  isCreditsCard?: boolean;
  isInnerCard?: boolean;
  isDataCard?: boolean;
  children?: React.ReactNode;
  cardTitle: string;
  titleInlineAction?: React.ReactNode;
  isCompanyCards?: boolean;
  isLoading?: boolean;
  reference?: any;
  titleLoaderTestId?: string;
}

const CardWrapper = ({
  isTopSection,
  isCreditsCard,
  isInnerCard,
  isDataCard,
  children,
  cardTitle,
  titleInlineAction,
  isCompanyCards,
  isLoading,
  reference,
  titleLoaderTestId,
}: CardWrapperProps) => {
  const { colors } = useContext(ThemeContext);

  const { to1099, from1099, fromLg } = useMediaQueries();

  const {
    dashboardPaper,
    topSection,
    creditsPaper,
    innerPaper,
    dataCard,
    cardContentBox,
    companyCardsPaper,
  } = useStyles({
    colors,
    to1099,
    from1099,
    fromLg,
  });

  const topSectionStyle = isTopSection && topSection;
  const creditsCardStyle = isCreditsCard && creditsPaper;
  const innerCardStyle = isInnerCard && innerPaper;
  const dataCardStyle = isDataCard && dataCard;
  const companyCardsStyle = isCompanyCards && companyCardsPaper;

  return (
    <Paper
      elevation={0}
      sx={[
        dashboardPaper as any,
        topSectionStyle,
        creditsCardStyle,
        innerCardStyle,
        dataCardStyle,
        companyCardsStyle,
      ]}
      ref={reference}
    >
      {/* icon should be added in front of title when needed */}
      <CardTitle
        title={cardTitle}
        titleInlineAction={titleInlineAction}
        isLoading={isLoading}
        testId={titleLoaderTestId}
      />
      <div css={css([cardContentBox])}>{children}</div>
    </Paper>
  );
};

export default CardWrapper;
