/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import ColumnsToShow from "./ColumnsToShow";

import Button from "../../../../Button/Button";
import Menu from "../../../../Menu/Menu";

import { MenuAnchorEl } from "../../../../../shared";
import useInitialLoading from "../../../../../shared/hooks/useInitialLoading";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";
import TableViewIcon from "../../../../../assets/customIcons/actions/TableViewIcon";

const TableView = () => {
  const { isInitialLoading } = useInitialLoading();
  const { t } = useTranslation();

  const {
    columnsToShowMenuCSS,
    tableViewButtonCSS,
    tableViewLoaderCSS,
  } = useStyles();

  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  return isInitialLoading ? (
    <RectangleLoader width={125} height={36} customStyle={tableViewLoaderCSS} />
  ) : (
    <>
      <Button
        color="white"
        size="small"
        variant="iconLeft"
        icon={<TableViewIcon />}
        onClick={handleOpenMenu}
        css={css(tableViewButtonCSS)}
      >
        {t("Button##table view")}
      </Button>

      <Menu
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        MenuListProps={{ disableListWrap: true }}
        css={css(columnsToShowMenuCSS)}
      >
        <ColumnsToShow />
      </Menu>
    </>
  );
};

export default TableView;
