import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../shared";

export const useStyles = (colors?: ThemeColors) =>
  ({
    statusIconCSS: {
      "& > svg": { fontSize: "24px", margin: "6px 6px 1px 6px" },
    },
    mainDivCSS: {
      padding: "7px 15px",
      border: `1px solid ${colors?.gray200}`,
      borderRadius: "8px",
      "& > div": {
        display: "flex",
        alignItems: "center",
      },
      "&.uploaded-file-multiple-enter": {
        opacity: "0",
      },

      "&.uploaded-file-multiple-enter-active": {
        opacity: "1",
        transition: "opacity 300ms ease-in",
      },

      "&.uploaded-file-multiple-exit": {
        opacity: "1",
      },

      "&.uploaded-file-multiple-exit-active": {
        opacity: "0",
        transition: "opacity 150ms ease-in",
      },
    },
    statusCSS: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div": {
        display: "flex",
        alignItems: "center",
      },
    },
    fileIconCSS: {
      alignSelf: "flex-start",
      marginRight: "8px",
      color: colors?.textPlaceholder,
    },
    uploadStatusCSS: {
      alignSelf: "flex-start",
      marginLeft: "8px",
    },

    loaderCSS: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "36px",
      height: "36px",
      marginRight: "4px",
    },
    failedUploadCSS: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      wordBreak: "break-word",
    },
  } as CSSObject);
