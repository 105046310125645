import { useEffect, Fragment } from "react";
import { useRecoilState } from "recoil";

import NavbarHeader from "./components/NavbarHeader/NavbarHeader";
import TwoFactorSuggestion from "./components/Settings/Dialogs/SecuritySettings/TwoFA/TwoFactorSuggestion/TwoFactorSuggestion";
import WelcomeDialog from "./components/WelcomeDialog/WelcomeDialog";

import DesktopDrawer from "../Drawer/DesktopDrawer/DesktopDrawer";
import MobileDrawer from "../Drawer/MobileDrawer/MobileDrawer";

import { useMediaQueries } from "../../shared";
import { mobileDrawerState } from "../../atoms/atoms";
import { useAuth } from "../../context/Auth/AuthProvider";
import CircularLoaderWithBackdrop from "../../shared/components/loaders/CircularLoaderWithBackdrop";

const Navbar = () => {
  const { isLoading } = useAuth();

  const { pathname } = window.location;
  const { toMd, fromMd } = useMediaQueries();

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useRecoilState(
    mobileDrawerState
  );

  useEffect(() => {
    setIsMobileDrawerOpen(false);
  }, [pathname, fromMd, setIsMobileDrawerOpen]);

  return (
    <Fragment>
      <NavbarHeader />

      {toMd ? (
        <MobileDrawer
          isOpen={isMobileDrawerOpen}
          setIsMobileDrawerOpen={setIsMobileDrawerOpen}
        />
      ) : (
        <DesktopDrawer />
      )}

      {isLoading && <CircularLoaderWithBackdrop isLoading={isLoading} />}

      <TwoFactorSuggestion />
      <WelcomeDialog />
    </Fragment>
  );
};

export default Navbar;
