/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Fragment, useContext, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { noop } from "lodash";

import ActionButton from "./ActionButton";
import ActionListChoice from "./ActionListChoice";

import ActionsForSelectionDialog from "../actionsForSelection/ActionsForSelectionDialog";
import { useStyles } from "../styles";
import { deselectAllRows } from "../../functions";

import Menu from "../../../Menu/Menu";
import ListChoice from "../../../ListChoice/ListChoice";
import { Actions } from "../../../PageHeader/actions/types/types";
import { useDialog } from "../../../PageHeader/actions/hooks/useDialog";

import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  COMPANIES_ACTIONS,
  DRIVERS_ACTIONS,
  MenuAnchorEl,
  TableRowDataType,
  useMediaQueries,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import {
  desktopDrawerState,
  rowsEnabledToSelect,
  selectedTableRows,
  tableDetailsSectionState,
} from "../../../../atoms/atoms";
import DeleteIcon from "../../../../assets/customIcons/actions/DeleteIcon";
import RemoveSchedulesIcon from "../../../../assets/customIcons/actions/RemoveSchedulesIcon";

interface ToolbarProps {
  resourceActions: Actions[] | null;
  toggleActiveHead: (value: boolean) => void;
}

const SharedToolbar = ({ resourceActions, toggleActiveHead }: ToolbarProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { toMd, fromMd, to1099, toLg } = useMediaQueries();

  const isDesktopDrawerOpen = useRecoilValue(desktopDrawerState);
  const isDesktopDetailsOpen = useRecoilValue(tableDetailsSectionState);
  const [, setSelectedRows] = useRecoilState<any>(selectedTableRows);
  const selectedRows = useRecoilValue(selectedTableRows);
  const selectableRows = useRecoilValue(rowsEnabledToSelect);
  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

  const { sharedToolbarActionMenu, sharedToolbarActionListChoice } = useStyles({
    colors,
  });

  const atLeastSingleSelectedHasChildCompanies = selectedRows.some(
    (rowId: string) =>
      selectableRows
        .map((row: TableRowDataType) => {
          return row.childCompanies?.length > 0 && row.id;
        })
        .includes(rowId)
  );

  const actionToBeDisabled = COMPANIES_ACTIONS.DeleteCompany;
  const disableActionCase = atLeastSingleSelectedHasChildCompanies;

  const disabledAction = (action: Actions) =>
    action === actionToBeDisabled && disableActionCase;

  const deleteCompanyButtonTooltip = t("Tooltip##delete company action");

  const shouldActionButtonsHaveOnlyIcon = () => {
    if (isDesktopDrawerOpen && isDesktopDetailsOpen) {
      if (toLg) {
        return true;
      }
    } else {
      if (fromMd && to1099 && isDesktopDetailsOpen) {
        return true;
      }
    }
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleOpenMenu = (e: MouseEvent<HTMLDivElement>) =>
    setAnchorEl(e.currentTarget);

  const resetView = () => {
    toggleActiveHead(false);
    deselectAllRows(setSelectedRows);
  };

  const handleActionButtonClick = (action: Actions) =>
    disabledAction(action) ? noop : openDialog(action);

  const getIconLeft = (action: Actions) => {
    switch (action) {
      case VEHICLES_ACTIONS.DeleteVehicle:
      case DRIVERS_ACTIONS.DeleteDriver:
      case USERS_ACTIONS.DeleteUser:
      case COMPANIES_ACTIONS.DeleteCompany:
        return <DeleteIcon key={action} />;

      case VEHICLES_ACTIONS.RemoveSchedules:
      case DRIVERS_ACTIONS.RemoveSchedules:
        return <RemoveSchedulesIcon key={action} />;
      default:
        return <Fragment key={action}></Fragment>;
    }
  };

  return (
    <Fragment>
      {fromMd &&
        !shouldActionButtonsHaveOnlyIcon() &&
        resourceActions?.map((action: Actions) => {
          return (
            <ActionButton
              key={action}
              action={action}
              disabledAction={disabledAction(action)}
              tooltipTitle={deleteCompanyButtonTooltip}
              onButtonClick={() => handleActionButtonClick(action)}
            />
          );
        })}
      {(toMd || shouldActionButtonsHaveOnlyIcon()) && (
        <Fragment>
          {resourceActions !== null ? (
            <Fragment>
              <div onClick={handleOpenMenu}>
                {t("Button##actions")}
                <ExpandMoreRoundedIcon
                  css={css({
                    marginLeft: "4px",
                  })}
                />
              </div>

              <Menu
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
                MenuListProps={{ disableListWrap: true }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                css={css(sharedToolbarActionMenu)}
              >
                {toMd && (
                  <ListChoice
                    title={t("Button##default view")}
                    iconLeft={<ArrowBackRoundedIcon />}
                    onClick={resetView}
                    css={css(sharedToolbarActionListChoice)}
                  />
                )}
                {resourceActions.map((action) => (
                  <ActionListChoice
                    key={action}
                    action={action}
                    disabledAction={disabledAction(action)}
                    tooltipTitle={deleteCompanyButtonTooltip}
                    onActionClick={() => handleActionButtonClick(action)}
                    getIconLeft={getIconLeft}
                  />
                ))}
              </Menu>
            </Fragment>
          ) : (
            <div onClick={resetView}>{t("Button##default view")}</div>
          )}
        </Fragment>
      )}
      {resourceActions?.map((action: Actions) => (
        <ActionsForSelectionDialog
          key={action}
          isOpen={isDialogOpen[action as keyof typeof isDialogOpen]}
          close={closeDialog}
          resourceAction={action}
          onDefaultView={resetView}
        />
      ))}
    </Fragment>
  );
};

export default SharedToolbar;
