/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import DetailsCardItemText from "../../../../DetailsCardItemText";
import DetailsCardItemTitle from "../../../../DetailsCardItemTitle";

import { useStyles } from "../../../../../../../styles";
import { CompanyCardDto } from "../../../../../../../types";

import { ThemeContext } from "../../../../../../../../../context/theme/ThemeContextProvider";
import { useDateTime } from "../../../../../../../../../shared";
import CardStatusBadge from "../../../../../../../../../Pages/Dashboard/RightSide/CompanyCards/components/CardStatusBadge/CardStatusBadge";

interface CompanyCardContentProps {
  cardData: CompanyCardDto;
}

const CompanyCardContent = ({ cardData }: CompanyCardContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { formattedDate } = useDateTime();

  const { paperCardItem, paperCardItemAlignStart } = useStyles(colors);

  return (
    <>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle
          title={t("Company cards##status")}
          customLineHeight="24px"
        />

        <CardStatusBadge card={cardData} tooltipSize="medium" />
      </div>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Company cards##valid until")} />
        <DetailsCardItemText
          textToRender={formattedDate(cardData.validUntil)}
        />
      </div>
      <div css={css([paperCardItem, paperCardItemAlignStart])}>
        <DetailsCardItemTitle title={t("Company cards##company card id")} />
        <DetailsCardItemText
          textToRender={cardData.companyCardId}
          hasCopyButton
          copiedValue={cardData.companyCardId}
        />
      </div>
    </>
  );
};

export default CompanyCardContent;
