import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import {
  SET_FILE,
  SET_FILES,
  SET_MESSAGE,
  REMOVE_FILE,
  REMOVE_ALL_FILES,
  SET_FILE_UPLOAD_STATUS,
} from "./dropzoneActions";

import { FILE_UPLOAD_STATUS } from "../../shared";
import { BANNER_MESSAGE_STATUSES } from "../../components/BannerMessage/types";

export interface Message {
  status: BANNER_MESSAGE_STATUSES;
  title: string;
  description?: string;
}

export interface DropzoneInitialState {
  file: File | null;
  lineCount: number;
  columnData: number[];
  multipleFiles: {
    file: File;
    id: string;
    uploadStatus: FILE_UPLOAD_STATUS;
    errorMessage?: string | null;
  }[];
  messages: Message[];
}

type Action =
  | {
      type: typeof SET_FILE;
      payload: {
        file: File | null;
        fileData: number[][];
      };
    }
  | {
      type: typeof SET_FILES;
      payload: File[];
    }
  | {
      type: typeof SET_MESSAGE;
      payload: { message: Message[]; override: boolean };
    }
  | {
      type: typeof SET_FILE_UPLOAD_STATUS;
      payload: {
        fileUploadStatus: FILE_UPLOAD_STATUS;
        id: string;
        errorMessage?: any;
      };
    }
  | {
      type: typeof REMOVE_FILE;
      payload: string;
    }
  | {
      type: typeof REMOVE_ALL_FILES;
    };

const DropzoneReducer = (
  state: DropzoneInitialState,
  action: Action
): DropzoneInitialState => {
  switch (action.type) {
    case SET_FILE:
      const { file, fileData } = action.payload;

      const newArray: number[] = [];
      fileData.forEach((element) => newArray.push(Number(element[0])));

      return {
        ...state,
        file,
        lineCount: fileData.length,
        columnData: newArray,
        messages: [],
      };
    case SET_FILES:
      const filesWithId = action.payload.map((uploadFile) => ({
        file: uploadFile,
        id: uuidv4(),
        uploadStatus: FILE_UPLOAD_STATUS.Ready,
      })) as any;

      return {
        ...state,
        multipleFiles: [...state.multipleFiles, ...filesWithId],
        messages: [],
      };
    case SET_MESSAGE:
      const { message, override } = action.payload;

      const getUpdatedMessages = () => {
        if (override) {
          return message;
        } else {
          const allMessages = [...state.messages, ...message];

          return _.uniqBy(allMessages, "title");
        }
      };

      const updatedMessages = getUpdatedMessages();

      return {
        ...state,
        file: null,
        lineCount: 0,
        messages: updatedMessages,
      };

    case SET_FILE_UPLOAD_STATUS: {
      const { fileUploadStatus, id: fileId, errorMessage } = action.payload;

      return {
        ...state,
        multipleFiles: state.multipleFiles.map((singleFile) =>
          singleFile.id === fileId
            ? {
                ...singleFile,
                uploadStatus: fileUploadStatus,
                errorMessage,
              }
            : singleFile
        ),
      };
    }

    case REMOVE_FILE:
      const id = action.payload;
      const updatedFiles = state.multipleFiles;
      const index = updatedFiles.findIndex(
        (updatedFile) => updatedFile.id === id
      );

      index !== -1 && updatedFiles.splice(index, 1);

      return {
        ...state,
        multipleFiles: updatedFiles,
        messages: [],
      };
    case REMOVE_ALL_FILES:
      return {
        ...state,
        file: null,
        lineCount: 0,
        multipleFiles: [],
        messages: [],
      };
    default:
      return state;
  }
};

export default DropzoneReducer;
