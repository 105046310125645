/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "./styles";
import useCircleStepperFunctions from "./hooks/useCircleStepperFunctions";
import { CircleStepperStatusTypes } from "./types";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import CircleStepperSuccessIcon from "../../assets/customIcons/steppers/CircleStepperSuccessIcon";
import CircleStepperCurrentIcon from "../../assets/customIcons/steppers/CircleStepperCurrentIcon";
import { useMediaQueries } from "../../shared";

interface CircleStepperProps {
  status: CircleStepperStatusTypes;
  showRightHorizontalLine?: boolean;
  stepTitle?: string;
  showLeftHorizontalLine?: boolean;
  lastElement?: boolean;
  pulseEffect?: boolean;
}

const CircleStepper = ({
  showRightHorizontalLine = true,
  stepTitle,
  showLeftHorizontalLine,
  status,
  lastElement,
  pulseEffect,
}: CircleStepperProps) => {
  const { colors } = useContext(ThemeContext);
  const { fromMd } = useMediaQueries();

  const {
    generateViewFromMd,
    generateViewToMd,
    generateCurrentStepCircle,
  } = useCircleStepperFunctions({
    showLeftHorizontalLine,
    status,
    showRightHorizontalLine,
    lastElement,
    stepTitle,
  });

  const { circleStepIconStyle, circleStepperContainer } = useStyles({
    colors,
    showLeftHorizontalLine,
    fromMd
  });

  const generateCircleStepperIcon = (status: CircleStepperStatusTypes) => {
    switch (status) {
      case CircleStepperStatusTypes.success:
        return (
          <CircleStepperSuccessIcon
            fillColour={colors.blue700}
            sx={circleStepIconStyle as CSSObject}
          />
        );
      case CircleStepperStatusTypes.current:
        // pulseEffect implies that stage is not finished
        return pulseEffect ? (
          generateCurrentStepCircle()
        ) : (
          <CircleStepperSuccessIcon
            fillColour={colors.blue700}
            sx={circleStepIconStyle as CSSObject}
          />
        );
      case CircleStepperStatusTypes.notCompleted:
        return (
          <CircleStepperCurrentIcon
            strokeColor={colors.textDarkDisabled}
            sx={circleStepIconStyle as CSSObject}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div css={css(circleStepperContainer)}>
      {fromMd
        ? generateViewFromMd(generateCircleStepperIcon)
        : generateViewToMd(generateCircleStepperIcon)}
    </div>
  );
};

export default CircleStepper;
