/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDropzone } from "react-dropzone";

import UploadedFileMultiple from "./UploadedFileMultiple";
import Frame from "../Frame";
import { MultipleFilesProps } from "../types";

import { BANNER_MESSAGE_STATUSES } from "../../BannerMessage/types";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import { FILE_UPLOAD_STATUS } from "../../../shared";

const MultipleFiles = ({
  supportedFormats,
  isDisabled,
  maxFileNameLength,
  maxFiles = 10,
  isLoading,
  isUploaded,
}: MultipleFilesProps) => {
  const { t } = useTranslation();

  const { multipleFiles, setFiles, setMessage } = useContext(DropzoneContext);

  const [isDragged, setIsDragged] = useState(false);

  const isMaxFileLimitReached = multipleFiles?.length >= maxFiles;

  const isDisabledDropzone = isDisabled || isMaxFileLimitReached;

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/html": supportedFormats },
    disabled: isDisabledDropzone,
    onDragEnter: () => setIsDragged(true),
    onDragLeave: () => setIsDragged(false),
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        const sliceEnd = maxFiles - multipleFiles.length;

        const filesWithCorrectName = acceptedFiles.filter(({ name }) =>
          maxFileNameLength ? name.length <= maxFileNameLength : true
        );

        const filesToUpload = filesWithCorrectName.slice(0, sliceEnd);

        const potentialAmountOfFiles =
          multipleFiles.length + filesWithCorrectName.length;

        const tooManyFiles = potentialAmountOfFiles > maxFiles;

        setFiles(filesToUpload);

        tooManyFiles &&
          setMessage({
            status: BANNER_MESSAGE_STATUSES.Warning,
            title: t("Dropzone##too many files title", {
              maxFiles: maxFiles,
            }),
            description: t("Dropzone##too many files description"),
          });

        filesWithCorrectName.length < acceptedFiles.length &&
          setMessage(
            {
              status: BANNER_MESSAGE_STATUSES.Critical,
              title: t("Dropzone##too long file name", {
                maxLength: maxFileNameLength,
              }),
            },
            true
          );
      }

      if (rejectedFiles.length > 0) {
        setMessage({
          status: BANNER_MESSAGE_STATUSES.Critical,
          title: t("Dropzone##file invalid type"),
        });
      }

      setIsDragged(false);
    },
  });

  const sortedByErrorsFirst = multipleFiles
    ?.map((file) => ({ ...file }))
    .sort((firstFile, secondFile) => {
      const firstFileError =
        firstFile.uploadStatus === FILE_UPLOAD_STATUS.Failed;

      const secondFileError =
        secondFile.uploadStatus === FILE_UPLOAD_STATUS.Failed;

      if (firstFileError && !secondFileError) {
        return -1;
      }

      if (secondFileError && !firstFileError) {
        return 1;
      }

      return 0;
    });

  return (
    <Fragment>
      <Frame
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        isDragged={isDragged}
        supportedFormats={supportedFormats}
        isMultiple
        isDisabled={isDisabledDropzone}
        isUploaded={isUploaded}
        tooltipText={
          isMaxFileLimitReached ? t("Dropzone##maximum file limit reached") : ""
        }
      />

      <TransitionGroup component={null}>
        {sortedByErrorsFirst?.map(
          ({ file, id, uploadStatus, errorMessage }) => (
            <CSSTransition
              key={id}
              timeout={300}
              classNames="uploaded-file-multiple"
            >
              <UploadedFileMultiple
                key={id}
                name={file.name}
                id={id}
                isLoading={isLoading}
                failedUpload={errorMessage}
                fileUploadStatus={uploadStatus}
                css={css({
                  marginTop: "8px",
                })}
              />
            </CSSTransition>
          )
        )}
      </TransitionGroup>
    </Fragment>
  );
};

export default MultipleFiles;
