import React from "react";
import { createTheme } from "@mui/material";

declare module "@mui/material" {
  interface TypographyPropsVariantOverrides {
    body3regular: true;
    body3semiBold: true;
    body4regular: true;
    body4semiBold: true;
    subtitle1semiBold: true;
    body1Regular: true;
    body1Bold: true;
    body2Bold: true;
    body2Regular: true;
  }

  interface TypographyClasses {
    body3regular: string;
    body3semiBold: string;
    body4regular: string;
    body4semiBold: string;
    subtitle1semiBold: string;
    body1Regular: string;
    body1Bold: string;
    body2Bold: string;
    body2Regular: string;
  }
}
declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3regular?: React.CSSProperties;
    body3semiBold?: React.CSSProperties;
    body4regular?: React.CSSProperties;
    body4semiBold?: React.CSSProperties;
    subtitle1semiBold?: React.CSSProperties;
    body1Regular?: React.CSSProperties;
    body1Bold?: React.CSSProperties;
    body2Bold?: React.CSSProperties;
    body2Regular?: React.CSSProperties;
  }

  interface TypographyVariants {
    body3regular: React.CSSProperties;
    body3semiBold: React.CSSProperties;
    body4regular: React.CSSProperties;
    body4semiBold: React.CSSProperties;
    subtitle1semiBold: React.CSSProperties;
    body1Regular: React.CSSProperties;
    body1Bold: React.CSSProperties;
    body2Bold: React.CSSProperties;
    body2Regular: React.CSSProperties;
  }
  interface BreakpointOverrides {
    xs0: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: false;
  }
}

export const muiThemeOverride = createTheme({
  breakpoints: {
    values: {
      xs0: 0,
      xs: 360,
      sm: 600,
      md: 840,
      lg: 1440,
    },
  },
  typography: {
    fontFamily: "'Open sans',  sans-serif",
    h1: {
      fontSize: "96px",
      fontWeight: 700,
      lineHeight: "128px",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: "64px",
      fontWeight: 700,
      lineHeight: "80px",
      letterSpacing: "-1.5px",
    },
    h3: {
      fontSize: "48px",
      fontWeight: 600,
      lineHeight: "64px",
    },
    h4: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "40px",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
    body1Regular: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    body1Bold: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    body2Regular: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    body2Bold: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
    subtitle1semiBold: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
    body3regular: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
    body3semiBold: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
    body4regular: {
      fontSize: "10px",
      fontWeight: 400,
      lineHeight: "12px",
      letterSpacing: "1px",
    },
    body4semiBold: {
      fontSize: "10px",
      fontWeight: 600,
      lineHeight: "12px",
      letterSpacing: "1px",
    },
  },
});
