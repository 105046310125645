/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";
import { StatusIconProps } from "../types";

import Tooltip from "../../Tooltip/Tooltip";

import WarningIcon from "../../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../../assets/customIcons/status/SuccessIcon";
import { FILE_UPLOAD_STATUS } from "../../../shared";

const StatusIcon = ({ uploadStatus }: StatusIconProps) => {
  const { t } = useTranslation();

  const { statusIconCSS } = useStyles();

  const isSuccess = uploadStatus === FILE_UPLOAD_STATUS.Success;

  return (
    <Tooltip
      placement="top"
      title={
        isSuccess
          ? t("Dropzone##file successfully uploaded")
          : t("Dropzone##error uploading the file")
      }
    >
      <span css={css(statusIconCSS)}>
        {isSuccess ? <SuccessIcon /> : <WarningIcon />}
      </span>
    </Tooltip>
  );
};

export default StatusIcon;
