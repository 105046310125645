import { NAVIGATION_ITEMS, ROUTES } from ".";

const hiddenNavItems: NAVIGATION_ITEMS[] = [
  NAVIGATION_ITEMS.FileSynchronization,
];

export const isItemVisible = (item: NAVIGATION_ITEMS) => {
  if (hiddenNavItems.includes(item)) {
    return false;
  }
  return true;
};

export type LayoutType = {
  key: NAVIGATION_ITEMS;
  title: NAVIGATION_ITEMS;
  pathname: ROUTES;
  shouldBeVisible: boolean;
};
