/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import useTwoFA from "../../../../hooks/useTwoFA";
import { DISABLE_AUTHENTICATION_STEPS } from "../../../../types";

import Dialog from "../../../../../../../Dialog/Dialog";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../../../../Button/Button";

import {
  maxDialogWidth,
  useMediaQueries,
} from "../../../../../../../../shared";

interface DisableTwoFAProps {
  onCloseDialog: () => void;
  isOpen: boolean;
}

const DisableTwoFA = ({ isOpen, onCloseDialog }: DisableTwoFAProps) => {
  const { t } = useTranslation();
  const { fromMd } = useMediaQueries();

  const {
    disableTwoFASteps: steps,
    setPassword,
    isLoading,
    setError,
    disableStep,
    setDisableStep,
  } = useTwoFA({ onCloseDialog });

  const hasActionSeparator = steps[disableStep].hasActionSeparator;
  const title = steps[disableStep].title;
  const description = steps[disableStep].description;
  const submitBtnName = steps[disableStep].submitBtn;
  const isDisabled = steps[disableStep].isSubmitBtnDisabled;
  const content = steps[disableStep].content;
  const onSubmit = steps[disableStep].onSubmit;

  return (
    <Dialog
      open={isOpen}
      close={onCloseDialog}
      isTitleSeparator
      isActionsSeparator={hasActionSeparator}
      extendToMaxWidth
      title={title}
      description={description}
      submit={onSubmit}
      TransitionProps={{
        onExited: () => {
          setDisableStep(DISABLE_AUTHENTICATION_STEPS.Confirmation);
          setPassword("");
          setError(null);
        },
      }}
      actions={
        <>
          <Button
            fullWidth
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Normal}
            variant={BUTTON_VARIANTS.TextOnly}
            onClick={onCloseDialog}
          >
            {t("Button##cancel")}
          </Button>

          {submitBtnName && (
            <Button
              fullWidth
              color={BUTTON_COLORS.Primary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              type="submit"
              disabled={isDisabled}
              isLoading={isLoading}
            >
              {submitBtnName}
            </Button>
          )}
        </>
      }
      css={css(fromMd && maxDialogWidth)}
    >
      {content}
    </Dialog>
  );
};

export default DisableTwoFA;
