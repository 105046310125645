import { useContext } from "react";

import {
  arrayWithOnlyOneSameValue,
  checkIfNextLevelHasLowerNumber,
  indexOfNextSameValueInALevelsArray,
} from "./functions";
import ParentAndFirstChildLine from "./components/ParentAndFirstChildLine";
import ParentWithMultipleChildsLine from "./components/ParentWithMultipleChildsLine";
import SingleVerticalLine from "./components/SingleVerticalLine";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";

interface ICompanyHierarchyTreeLinesProps {
  indexOfxAxisEmptyDiv: number;
  companyLevelsList: number[];
  tableRowIndex: any;
  xAxisEmptyDivsArray: any;
}

const CompanyHierarchyTreeLines = ({
  indexOfxAxisEmptyDiv,
  companyLevelsList,
  tableRowIndex,
  xAxisEmptyDivsArray,
}: ICompanyHierarchyTreeLinesProps) => {
  const { colors } = useContext(ThemeContext);

  const mapThroughCompanyChildrenLevels = (
    currentCompanyLevel: number,
    gapBetweenLevels: number,
    levelListWithoutCurrentLevel: number[],
    levelNumber: number
  ) => {
    const nextSameValueIndex = indexOfNextSameValueInALevelsArray(
      levelListWithoutCurrentLevel,
      levelNumber
    );

    const arrayWithOneSameValue = arrayWithOnlyOneSameValue(
      levelListWithoutCurrentLevel,
      nextSameValueIndex
    );

    const isNextLevelLowerNumber = checkIfNextLevelHasLowerNumber(
      levelListWithoutCurrentLevel,
      nextSameValueIndex,
      levelNumber
    );

    const shouldHaveVerticalLine =
      arrayWithOneSameValue.includes(levelNumber) && !isNextLevelLowerNumber;

    if (currentCompanyLevel === levelNumber) {
      if (
        (gapBetweenLevels === -1 || gapBetweenLevels === 0) &&
        levelListWithoutCurrentLevel.includes(levelNumber)
      ) {
        if (shouldHaveVerticalLine) {
          return <ParentWithMultipleChildsLine colors={colors} />;
        }
      }
      return <ParentAndFirstChildLine colors={colors} />;
    }
    if (
      currentCompanyLevel !== levelNumber &&
      levelListWithoutCurrentLevel.includes(levelNumber)
    ) {
      if (shouldHaveVerticalLine) {
        return <SingleVerticalLine colors={colors} />;
      }
    }
  };

  const GenerateHierarchyLines = () => {
    for (let i = 0; i < companyLevelsList.length; i++) {
      let gapBetweenLevels = companyLevelsList[i] - companyLevelsList[i + 1];

      const currentCompanyLevel = companyLevelsList[i];

      const currentTableRow = tableRowIndex === i;

      let levelListWithoutCurrentLevel = companyLevelsList.slice(i + 1);

      for (let k = 0; k < xAxisEmptyDivsArray.length; k++) {
        const currentXAxisEmptyDiv = indexOfxAxisEmptyDiv === k;

        if (currentXAxisEmptyDiv && currentTableRow) {
          return mapThroughCompanyChildrenLevels(
            currentCompanyLevel,
            gapBetweenLevels,
            levelListWithoutCurrentLevel,
            k + 1
          );
        }
      }
    }
  };

  return <>{GenerateHierarchyLines()}</>;
};

export default CompanyHierarchyTreeLines;
