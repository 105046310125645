import { CSSObject } from "@emotion/react";

interface UseStylesProps {
  toMd?: boolean;
}

export const useStyles = ({ toMd }: UseStylesProps) =>
  ({
    dialogPaper: {
      width: toMd ? "100%" : "448px",
      maxWidth: toMd ? "unset" : "684px",
      minWidth: toMd ? "unset" : "448px",
      borderRadius: toMd ? "0" : "12px",
    },
    closeButton: {
      position: "absolute",
      top: "4px",
      right: "4px",
    },
    contentContainer: {
      padding: "16px 24px",
      textAlign: "center",
    },
    dialogNewFeatureActions: {
      display: "grid",
      rowGap: "8px",
      marginTop: "16px",
    },
  } as CSSObject);
