import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import EmailRecipientDialogContent from "../../../EmailRecipientDialogContent";
import { EditRecipientPayload } from "../../../../types";
import useCompaniesTabDetails from "../../../../../../hooks/useCompaniesTabDetails";
import { EmailTransferObjectDto } from "../../../../../../../../types";
import Dialog from "../../../../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../../../../Dialog/types/enums";
import DialogActions from "../../../../../../../../../../shared/components/buttons/DialogActions";
import { tableDetailsLoading } from "../../../../../../../../../../atoms/atoms";
import useResponse from "../../../../../../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../../../../../../api/hooks/useAxiosPrivate";
import { useMediaQueries } from "../../../../../../../../../../shared";
import { ApiResources } from "../../../../../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../../../../../context/alert/types";

interface EditEmailRecipientDialogProps {
  companyId: string;
  cardData: EmailTransferObjectDto;
  open: boolean;
  onClose: () => void;
}

const EditEmailRecipientDialog = ({
  companyId,
  cardData,
  open,
  onClose,
}: EditEmailRecipientDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { putData } = useAxiosPrivate();
  const { handleErrorWithEntries, handleResponse } = useResponse();
  const { getTabDetailsData } = useCompaniesTabDetails();

  const [, setIsDetailsLoading] = useRecoilState(tableDetailsLoading);

  const [payload, setPayload] = useState<EditRecipientPayload>(cardData);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleSubmitEdit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);

        await putData(ApiResources.EmailRecipients, payload);
        handleResponse(
          ALERT_STATUS.Success,
          t("Alert##email transfer edit recipient success")
        );

        setIsDetailsLoading(true);
        await getTabDetailsData(companyId, ApiResources.EmailRecipients);
        setIsDetailsLoading(false);
      } catch (error) {
        handleErrorWithEntries(error, t);
      }
    }

    setIsLoading(false);
  };

  const resetAllValues = () => {
    setIsReady(false);
    setPayload(cardData);
  };

  return (
    <Dialog
      title={t("Details##edit user", { userEmail: `"${cardData.email}"` })}
      wordBreakTitle="break-all"
      open={open}
      close={onClose}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      submit={handleSubmitEdit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      TransitionProps={{
        onExited: () => resetAllValues(),
      }}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-recipient-dialog-save-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="edit-recipient-dialog-close-button"
        />
      }
    >
      <EmailRecipientDialogContent
        isLoading={isLoading}
        payload={payload}
        setIsReady={setIsReady}
        setPayload={setPayload}
      />
    </Dialog>
  );
};

export default EditEmailRecipientDialog;
