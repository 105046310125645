import { useTranslation } from "react-i18next";

import SingleCompanyCard from "./components/SingleCompanyCard/SingleCompanyCard";
import NoCompanyCards from "./components/NoCompanyCards/NoCompanyCards";
import AddCompanyCardButton from "./components/AddCompanyCardButton/AddCompanyCardButton";
import AccordionDetailsCard from "../AccordionDetailsCard/AccordionDetailsCard";
import { CompanyCardDto, DETAILS_TITLES } from "../../../../types";
import { IGeneralDetailsProps } from "../../../../../../shared";
import { COMPANY_CARDS_LIMITS } from "../../../../../../Pages/Companies/types";

const CompanyCardsDetails = ({
  detailsData,
  isLoading,
}: IGeneralDetailsProps) => {
  const { t } = useTranslation();

  const cardsCount: number = detailsData?.companyCards?.length || 0;
  const hasCompanyCards = detailsData && detailsData?.companyCards?.length > 0;

  const renderListOfCompanyCards = () => (
    <AccordionDetailsCard
      cardTitle={t(`Details##${DETAILS_TITLES.CompanyCards}`)}
      maxCount={COMPANY_CARDS_LIMITS.MaxCompanyCardsCount}
      currentCount={cardsCount}
      quickTitleAction={
        <AddCompanyCardButton
          detailsData={detailsData}
          cardsCount={cardsCount}
        />
      }
    >
      {detailsData?.companyCards?.map((card: CompanyCardDto, index: number) => (
        <SingleCompanyCard
          key={card.id}
          cardData={card}
          index={index}
          totalCount={cardsCount}
          companyData={detailsData}
        />
      ))}
    </AccordionDetailsCard>
  );
  return isLoading ? (
    <></>
  ) : (
    <>
      {!hasCompanyCards && (
        <NoCompanyCards currentCount={cardsCount} detailsData={detailsData} />
      )}
      {hasCompanyCards && renderListOfCompanyCards()}
    </>
  );
};

export default CompanyCardsDetails;
