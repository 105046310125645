/** @jsxImportSource @emotion/react */
import { CSSObject } from "@emotion/react";
import { Card } from "@mui/material";
import { SetStateAction, useContext, useEffect, useRef, useState } from "react";

import ListedNewContent from "../ListedNewContent";
import DraftAdditions from "../DraftAdditions";
import NewsImageContainer from "../NewsImageContainer";

import { NewsDto } from "../..";
import useSingleNew from "../../hooks/useSingleNews";
import { useStyles } from "../../styles";
import { NEWS_SECTION } from "../../types/enums";
import SingleNewDialog from "../../Dialogs/SingleNewsDialog/SingleNewsDialog";
import EditNewsDialog from "../../Dialogs/EditNewsDialog/EditNewsDialog";
import PublishNewsDialog from "../../Dialogs/PublishNewsDialog/PublishNewsDialog";
import DeleteNewsDialog from "../../Dialogs/DeleteNewsDialog/DeleteNewsDialog";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { MenuAnchorEl, NEWS_ACTIONS } from "../../../../../../shared";

interface SingleNewProps {
  newsData: NewsDto;
  noMargin?: any;
}

const SingleNew = ({ noMargin, newsData }: SingleNewProps) => {
  const { colors } = useContext(ThemeContext);

  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);
  const [isHeightOverflowing, setIsHeightOverflowing] = useState<boolean>(
    false
  );
  const [isNewDialogOpen, setIsNewDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState<boolean>(
    false
  );
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [fetchedSingleNew, setFetchedSingleNew] = useState<NewsDto | null>(
    null
  );

  const { getSingleNews, isLoading, handleCloseActionsMenu } = useSingleNew({
    id: newsData.id,
    setIsActionsOpen,
  });

  const titleRef: any = useRef(null);

  const { newsCard, cardNoMargin } = useStyles({
    colors,
  });

  const unpublishedNew = newsData.draft;

  useEffect(() => {
    setIsHeightOverflowing(
      titleRef.current.scrollHeight > titleRef.current.clientHeight
    );
  }, [newsData.title, titleRef]);

  const changeDialogStates = (
    setState: (value: SetStateAction<boolean>) => void,
    stateVal: boolean
  ) => {
    setState(stateVal);
    handleCloseActionsMenu();
  };

  const openDialog = (action: NEWS_ACTIONS) => {
    switch (action) {
      case NEWS_ACTIONS.EditNews:
        changeDialogStates(setIsEditDialogOpen, true);
        getSingleNews(setFetchedSingleNew);
        break;
      case NEWS_ACTIONS.Publish:
        changeDialogStates(setIsPublishDialogOpen, true);
        break;
      case NEWS_ACTIONS.Delete:
        changeDialogStates(setIsDeleteDialogOpen, true);
        break;
      default:
        return;
    }
  };

  const openSingleNewsDialog = () => {
    !isActionsOpen && setIsNewDialogOpen(true);
    getSingleNews(setFetchedSingleNew);
  };

  const handleCloseDialog = (action: NEWS_ACTIONS) => {
    switch (action) {
      case NEWS_ACTIONS.EditNews:
        changeDialogStates(setIsEditDialogOpen, false);
        break;
      case NEWS_ACTIONS.Publish:
        changeDialogStates(setIsPublishDialogOpen, false);
        break;
      case NEWS_ACTIONS.Delete:
        changeDialogStates(setIsDeleteDialogOpen, false);
        break;
      default:
        setIsNewDialogOpen(false);
    }
  };

  const handleOpenActionsMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
    stopPropagation: () => void;
  }) => {
    e.stopPropagation();
    setIsActionsOpen && setIsActionsOpen(e.currentTarget);
  };

  return (
    <>
      <Card
        data-testid="single-news-card"
        elevation={0}
        sx={
          [
            newsCard,
            noMargin && cardNoMargin,
            { position: "relative" },
          ] as CSSObject[]
        }
        onClick={openSingleNewsDialog}
      >
        {unpublishedNew && (
          <DraftAdditions
            onOpenActionsMenu={handleOpenActionsMenu}
            onCloseActionsMenu={handleCloseActionsMenu}
            isActionsOpen={isActionsOpen}
            openDialog={openDialog}
          />
        )}

        <NewsImageContainer
          image={
            newsData?.imageName?.length > 0 ? newsData.imageName : undefined
          }
          colors={colors}
          height={NEWS_SECTION.listImageHeight}
          width={NEWS_SECTION.listImageWidth}
          listItem
        />

        <ListedNewContent
          newsData={newsData}
          isHeightOverflowing={isHeightOverflowing}
          titleRef={titleRef}
        />
      </Card>

      {/* DIALOGS: */}
      <SingleNewDialog
        title={newsData.title}
        newsData={fetchedSingleNew}
        isOpen={isNewDialogOpen}
        onClose={() => handleCloseDialog(NEWS_ACTIONS.OpenNew)}
        isLoading={isLoading}
        colors={colors}
      />

      <EditNewsDialog
        isLoading={isLoading}
        newsData={fetchedSingleNew}
        isDialogOpen={isEditDialogOpen}
        onClose={() => handleCloseDialog(NEWS_ACTIONS.EditNews)}
      />
      <PublishNewsDialog
        isDialogOpen={isPublishDialogOpen}
        onClose={() => handleCloseDialog(NEWS_ACTIONS.Publish)}
        newsData={newsData}
      />

      <DeleteNewsDialog
        isDialogOpen={isDeleteDialogOpen}
        onClose={() => handleCloseDialog(NEWS_ACTIONS.Delete)}
        newsData={newsData}
      />
    </>
  );
};

export default SingleNew;
