import { useTranslation } from "react-i18next";

import TextLink from "../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../components/TextLink/types";

interface IExternalTextLinkProps {
  linkUrl: string;
  linkText: string;
  linkSize?: TEXT_LINK_SIZES;
}

const ExternalTextLink = ({
  linkUrl,
  linkText,
  linkSize,
}: IExternalTextLinkProps) => {
  const { t } = useTranslation();

  return (
    <TextLink
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      underline="none"
      size={linkSize || TEXT_LINK_SIZES.Small}
    >
      {t(linkText)}
    </TextLink>
  );
};

export default ExternalTextLink;
