/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Trans } from "react-i18next";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import WikiIcon from "@mui/icons-material/InfoOutlined";

import IconLink from "./IconLink";
import { useStyles } from "../styles";

import LogoIcon from "../../../assets/customIcons/contacts/LogoIcon";
import TextLink from "../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../components/TextLink/types";

const Contacts = () => {
  const { contactsOuterDiv, privacyPolicyDiv, contactIconsDiv } = useStyles();

  return (
    <div css={css(contactsOuterDiv)}>
      <div css={css(privacyPolicyDiv)}>
        <Trans
          i18nKey="Auth##privacy policy"
          components={{
            3: (
              <TextLink
                href="https://teltonika-gps.com/about-us/policies-certificates/privacy-policy"
                target="_blank"
                rel="noreferrer"
                underline="none"
                size={TEXT_LINK_SIZES.Small}
              >
                Privacy policy
              </TextLink>
            ),
          }}
        />
      </div>

      <div css={css(contactIconsDiv)}>
        <IconLink
          dataTestId="logo-icon"
          link="https://teltonika-gps.com/"
          tooltip="teltonika telematics"
          icon={LogoIcon}
        />

        <IconLink
          dataTestId="community-forum-icon"
          link="https://community.teltonika.lt/"
          tooltip="community forum"
          icon={ForumOutlinedIcon}
        />

        <IconLink
          dataTestId="wiki-icon"
          link="https://wiki.teltonika-gps.com/view/Main_Page"
          tooltip="wiki"
          icon={WikiIcon}
        />
      </div>
    </div>
  );
};

export default Contacts;
