import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

interface StyleProps {
  width?: string | number;
  colors?: ThemeColors;
}

export const useStyles = ({ width, colors }: StyleProps) =>
  ({
    filterSeparator: {
      alignSelf: "stretch",
      flexShrink: "0",
      width: width,
      background: colors?.gray300,
      margin: "0 16px",
    },
  } as CSSObject);
