import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import LayoutNoDetails from "./LayoutNoDetails";
import LayoutWithDetails from "./LayoutWithDetails";
import { tableName } from "../../atoms/atoms";

interface ILayoutWrapperProps {
  showDetailsSection: boolean;
  children: React.ReactNode;
}

const LayoutWrapper = ({
  showDetailsSection,
  children,
}: ILayoutWrapperProps) => {
  const { t } = useTranslation();

  const currentTableName = useRecoilValue(tableName);

  const renderLayout = () => {
    if (!showDetailsSection) {
      return (
        <LayoutNoDetails headerTitle={t(`General##${currentTableName}`)}>
          {children}
        </LayoutNoDetails>
      );
    } else {
      return (
        <LayoutWithDetails headerTitle={t(`General##${currentTableName}`)}>
          {children}
        </LayoutWithDetails>
      );
    }
  };

  return renderLayout();
};

export default LayoutWrapper;
