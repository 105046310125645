export const getArrowPosition = (props: any) => {
  switch (props.placement) {
    case "top-start":
    case "bottom-start":
      return {
        transform: "unset !important",
        left: "12px !important",
        right: "unset !important",
      };
    case "top-end":
    case "bottom-end":
      return {
        transform: "unset !important",
        left: "unset !important",
        right: "12px !important",
      };
    case "left-start":
    case "right-start":
      return {
        transform: "unset !important",
        top: "12px !important",
        bottom: "unset !important",
      };
    case "left-end":
    case "right-end":
      return {
        transform: "unset !important",
        top: "unset !important",
        bottom: "12px !important",
      };
    case "left":
    case "right":
      return {
        transform: "unset !important",
        top: "calc(50% - 6px) !important",
      };
    default:
      return {};
  }
};
