/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import { useStyles } from "../../styles";

import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";

interface CardTitleProps {
  title: string;
  titleInlineAction?: React.ReactNode;
  isLoading?: boolean;
  testId?: string;
}

const CardTitle = ({
  title,
  titleInlineAction,
  isLoading,
  testId,
}: CardTitleProps) => {
  const { cardTitle } = useStyles({});

  return (
    <div css={css([cardTitle])}>
      {isLoading ? (
        <RectangleLoader width={136} height={20} testId={testId} />
      ) : (
        <>
          <Typography variant="body1">{title}</Typography>
          {titleInlineAction && <div>{titleInlineAction}</div>}
        </>
      )}
    </div>
  );
};

export default CardTitle;
