export enum DialogWidth {
  Dialog920px = "920px",
  MaxWidth = "684px",
  MinWidth = "448px",
}

export enum FILE_NAME {
  SampleDriversImportList = "sample_drivers_import_list",
  SampleVehiclesImportList = "sample_vehicles_import_list",
}

export enum DialogHeight {
  MaxHeight = "754px",
  selectImageHeight = "510px", // should be 502px, but 8px gap impacts overflowY behavior, so 8px added
  selectImgaeHeigthFromMd = "594px", // should be 586px, but 8px gap impacts overflowY behavior, so 8px added
}
