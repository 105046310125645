import RectangleLoader from "../../../../../../../shared/components/loaders/RectangleLoader";

const CompanyCardAuthLoader = () => {
  return (
    <div>
      <RectangleLoader
        width="188px"
        height="24px"
        customStyle={{ margin: "16px 0 8px 0" }}
      />
      <RectangleLoader width="100%" height="288px" />
    </div>
  );
};

export default CompanyCardAuthLoader;
