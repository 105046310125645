/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";

import ContentLoader from "./components/ContentLoader";
import TabsArrowButton from "./components/TabsArrowButton";

import useCompaniesTabDetails from "../../hooks/useCompaniesTabDetails";

import DetailsContainer from "../../../DetailsContainer/DetailsContainer";

import { useStyles } from "../../../../styles";
import { COMPANY_DETAILS_TABS } from "../../../../types";

import TabsCustom from "../../../../../TabsCustom/TabsCustom";
import TabCustomPanel from "../../../../../TabsCustom/components/TabCustomPanel";

import { ApiResources } from "../../../../../../api/resources";
import RectangleLoader from "../../../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../../../shared";

interface CompaniesDetailsContainerProps {
  details: React.ReactNode;
  fileSyncContent: React.ReactNode;
  emailTransferContent: (companyId: string) => React.JSX.Element | null;
  contentHeight: string;
  companyId: string;
  displayInitialLoader: boolean;
}

const CompaniesDetailsContainer = ({
  details,
  fileSyncContent,
  emailTransferContent,
  contentHeight,
  companyId,
  displayInitialLoader,
}: CompaniesDetailsContainerProps) => {
  const { getTabDetailsData, isLoading } = useCompaniesTabDetails();
  const { toMd, toLg } = useMediaQueries();

  const [value, setValue] = useState(0);

  const { customTabsContainer } = useStyles();

  const desktopTabsCustomWidth = toLg ? "309px" : "419px"; // desktop details width: toLg ? "361px" : "472px" - 52px of left and right padding;

  useEffect(() => {
    value === 2 && getTabDetailsData(companyId, ApiResources.EmailRecipients);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, companyId]);

  const tabsArray: string[] = [
    COMPANY_DETAILS_TABS.Details,
    COMPANY_DETAILS_TABS.FileSync,
    COMPANY_DETAILS_TABS.EmailTransfer,
  ];

  const isDataFetchLoading = isLoading;

  const handleRightArrowClick = () => {
    if (value < tabsArray.length - 1) {
      setValue(value + 1);
    }
  };

  const handleLeftArrowClick = () => {
    if (value > 0) {
      setValue(value - 1);
    }
  };

  return (
    <>
      {/* Tab loader */}
      {displayInitialLoader ? (
        <RectangleLoader
          height={"32px"}
          customStyle={{ margin: "16px 28px 0 24px" }}
        />
      ) : (
        <div data-testid="custom-tabs-container" css={css(customTabsContainer)}>
          <TabsArrowButton
            onButtonClick={handleLeftArrowClick}
            isDisabled={value === 0}
            leftButton
          />

          <TabsCustom
            setValue={setValue}
            value={value}
            tabsArray={tabsArray}
            // in this case style here does not accept dynamic values and affects the tabs to move to the very last tab on first render
            customStyle={{
              width: toMd ? "calc(100% - 52px)" : desktopTabsCustomWidth,
            }}
          />
          <TabsArrowButton
            onButtonClick={handleRightArrowClick}
            isDisabled={value === tabsArray.length - 1}
          />
        </div>
      )}

      {isDataFetchLoading || displayInitialLoader ? (
        <ContentLoader contentHeight={contentHeight} isLoading={isLoading} />
      ) : (
        <>
          <TabCustomPanel value={value} index={0}>
            <DetailsContainer contentHeight={contentHeight}>
              {details}
            </DetailsContainer>
          </TabCustomPanel>

          <TabCustomPanel value={value} index={1}>
            <DetailsContainer contentHeight={contentHeight}>
              {fileSyncContent}
            </DetailsContainer>
          </TabCustomPanel>

          <TabCustomPanel value={value} index={2}>
            <DetailsContainer contentHeight={contentHeight}>
              {emailTransferContent(companyId)}
            </DetailsContainer>
          </TabCustomPanel>
        </>
      )}
    </>
  );
};

export default CompaniesDetailsContainer;
