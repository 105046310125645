import { Dispatch, SetStateAction, useState } from "react";
import { AxiosError } from "axios";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LogisticJournalDataProps } from "../../types";

import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../../api/resources";
import SuccessIcon from "../../../../../../../assets/customIcons/status/SuccessIcon";
import { numbersAndLettersOnly } from "../../../../../../../shared";

export const useNewHierarchyFormInputs = (
  logisticJournalData: LogisticJournalDataProps,
  setLogisticJournalData: Dispatch<SetStateAction<LogisticJournalDataProps>>,
  setName: Dispatch<SetStateAction<string>>
) => {
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();
  const [logisticJournalError, setLogisticJournalError] = useState<
    null | string
  >(null);
  const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);

  const { lzCode, lzCompanyName } = logisticJournalData;

  const handleVerify = async () => {
    setVerifyButtonLoading(true);
    try {
      if (lzCode) {
        setLogisticJournalData({
          ...logisticJournalData,
          lzCompanyName: "",
        });

        const { data } = await getData(
          `${ApiResources.CompaniesLzCode}/${lzCode}`
        );

        const nameFromLZ = data?.name;

        setLogisticJournalData({
          ...logisticJournalData,
          lzCompanyName: nameFromLZ,
        });

        setName(nameFromLZ);
      }
    } catch (error) {
      const err = error as AxiosError<any>;

      const errorString = err.response?.data.message || err.message;

      setLogisticJournalError(t(`Alert##${errorString}`));
    }
    setVerifyButtonLoading(false);
  };

  const handleLzCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const inputValue = e.target.value;

    if (inputValue.length <= 8) {
      setLogisticJournalData({
        ...logisticJournalData,
        lzCode: e.target.value,
      });

      if (!numbersAndLettersOnly.test(inputValue)) {
        setLogisticJournalError(t("Dialog##lz code invalid characters"));
      } else {
        setLogisticJournalError(null);
      }
    }
  };

  const getLzCodeFieldIcon = () => {
    if (!lzCompanyName || verifyButtonLoading) return undefined;

    return verifyButtonLoading ? (
      <CircularProgress data-testid="circular-progress" size={20} />
    ) : (
      <SuccessIcon fontSize="small" />
    );
  };

  return {
    verifyButtonLoading,
    logisticJournalError,
    handleVerify,
    handleLzCodeInput,
    getLzCodeFieldIcon,
  };
};
