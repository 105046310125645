import { useTranslation } from "react-i18next";

import Button from "../../../../../components/Button/Button";

interface CloseDialogButtonProps {
  onClose: () => void;
  isLoading: boolean;
}

const CloseDialogButton = ({ onClose, isLoading }: CloseDialogButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      data-testid="close_single_new_dialog-button"
      fullWidth
      variant="textOnly"
      size="normal"
      color="secondary"
      onClick={onClose}
      disabled={isLoading}
    >
      {t("Button##close")}
    </Button>
  );
};

export default CloseDialogButton;
