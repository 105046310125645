/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TableCell } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { useStyles } from "../../../styles/styles";
import InlineActionIconButton from "../../../../Button/InlineActionIconButton/InlineActionIconButton";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import {
  ROUTES,
  tableColumnDropShadow,
  TableRowData,
} from "../../../../../shared";
import { useAuth } from "../../../../../context/Auth/AuthProvider";

interface ITableInlineActionsProps {
  scrollHorizontal?: boolean;
  inlineActions: any[];
  row: TableRowData;
}

const TableInlineActions = ({
  scrollHorizontal,
  inlineActions,
  row,
}: ITableInlineActionsProps) => {
  const { user } = useAuth();
  const { colors } = useContext(ThemeContext);
  const location = useLocation();

  const [isInlineBtnLoading, setInlineBtnLoading] = useState(false);

  const { tableInlineActionsBlock, firstTableInlineAction } = useStyles();

  const companiesPage = location.pathname === ROUTES.Companies;
  const boxShadowBottom = `inset 0px -1px 0px ${colors.gray200}`;

  return (
    <TableCell
      onClick={(e) => e.stopPropagation()}
      sx={[
        {
          position: "sticky",
          right: "0",
          padding: "0 !important",
          overflowX: "unset !important",
          background: `${colors.white} !important`,
          boxShadow: boxShadowBottom,
          cursor: "auto",
          width: companiesPage ? "2%" : "unset",
        },
        tableColumnDropShadow(scrollHorizontal),
      ]}
    >
      <div css={css(tableInlineActionsBlock)} data-testid="inline-actions-cell">
        {inlineActions.map((action: any, index: number) => {
          // if inlineActions renderConditions property is equal to true
          // then return inline action button
          if (action.renderConditions({ data: row, user })) {
            return (
              <Fragment key={action.title}>
                {index === 0 && (
                  <div
                    css={css([
                      {
                        background: colors.gray200,
                      },
                      firstTableInlineAction,
                    ])}
                  />
                )}
                <InlineActionIconButton
                  title={action.title}
                  icon={action.icon}
                  onButtonClick={() =>
                    action.clickAction(row, setInlineBtnLoading)
                  }
                  isLoading={action.hasLoader && isInlineBtnLoading}
                />
              </Fragment>
            );
          }
          // otherwise no inline action is shown
          return null;
        })}
      </div>
    </TableCell>
  );
};

export default TableInlineActions;
