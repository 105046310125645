import { CreditsStatuses } from "../types";

import { ThemeColors } from "../../../../shared";

export const generateCreditsItemColor = (name: string, colors: ThemeColors) =>
  name === CreditsStatuses.Reserved ? colors.blue500 : colors.blue600;

export const generateCreditsItemBorderColor = (
  name: string,
  colors: ThemeColors
) => generateCreditsItemColor(name, colors);

export const checkIfOnlyOneOptionHasValue = (dataArray: number[]) => {
  return dataArray.filter((item: number) => item !== 0).length === 1;
};

export const checkIfPositiveNumber = (stringNumber: string | number) =>
  +stringNumber >= 0;

export const generateNumberStringWithPlusOrMinus = (
  number: string | number
) => {
  return +number >= 0 ? `+${number}` : `${number}`;
};

const today = new Date();
const currentMonthInShort = today.toLocaleString("default", {
  month: "short",
});

export const generateBoldTickElement = (monthsArray: string[] | undefined) =>
  monthsArray &&
  monthsArray.map((month: string) =>
    month === currentMonthInShort ? 700 : 400
  );

export const checkIfArrayHasAnyValues = (array: number[] | undefined) =>
  array?.some((item: number) => item !== 0);

export const afterLabelSpan = (
  afterLabelString: string,
  color?: string,
  fontWeight?: string
) =>
  `<span style="font-weight: ${fontWeight ||
    600}; font-size: 14px !important; line-height: 20px; color: ${color}">` +
  afterLabelString +
  "</span>";

export const afterLabelSpanTitle = (afterLabelString: string) =>
  `<span style="font-weight: 400; font-size: 14px !important; line-height: 20px; padding: 0">` +
  afterLabelString +
  "</span>";

export const generateDeductedStringRow = (
  afterLabelDeducted: string,
  afterLabelReserved: string | undefined,
  rowStyle: string,
  deductedString: string
) =>
  afterLabelDeducted === "0" || afterLabelReserved !== "undefined"
    ? ""
    : `<tr style="${rowStyle}"><td>${deductedString}</td></tr>`;

export const generateAddedRowString = (
  afterLabelAdded: string,
  rowStyle: string,
  addedString: string
) =>
  afterLabelAdded === "0"
    ? ""
    : `<tr style="${rowStyle}"><td>${addedString}</td></tr>`;

export const generateReservedStringRow = (
  afterLabelReserved: string | undefined,
  rowStyle: string,
  reservedString: string
) =>
  afterLabelReserved === "0" || afterLabelReserved === "undefined"
    ? ""
    : afterLabelReserved &&
      `<tr style="${rowStyle}"><td>${reservedString}</td></tr>`;
