/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment, ReactNode } from "react";
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/ErrorRounded";

import {
  largeTooltipTextFieldStyleOverrides,
  muiAutocompleteComponentPropsStyleOverrides,
  muiAutocompleteStyleOverrides,
} from "./styleFunctions";
import Label from "../Label/Label";

import { TooltipProps } from "../Tooltip/types";
import TooltipLarge from "../Tooltip/TooltipLarge";
import { muiOutlinedInputDefaultStylesOverrides } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

type AutocompleteSize = "tiny" | "small" | "medium";

export interface AutocompleteProps {
  size: AutocompleteSize;
  labelLeft?: string;
  labelIcon?: string;
  labelRight?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  errorText?: string | null;
  isRequired?: boolean;
  width?: string;
  tooltipProps?: TooltipProps;
  textFieldParams?: {};
  customStyle?: {};
  labelCustomStyle?: {};
}

const Autocomplete = ({
  size,
  labelLeft,
  labelIcon,
  labelRight,
  iconLeft,
  iconRight,
  errorText,
  isRequired,
  width,
  tooltipProps,
  textFieldParams = {},
  customStyle,
  labelCustomStyle,
  ...props
}: AutocompleteProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size"
  >) => {
  const { colors } = useContext(ThemeContext);

  const paddings = {
    tiny: "2px 12px",
    small: "8px 12px",
    medium: "12px",
  };

  const padding = paddings[size];

  const getWidth = () => {
    if (props.fullWidth) {
      return "100%";
    }

    return width || "unset";
  };

  const containerWidth = getWidth();

  return (
    <div
      css={css({
        display: "flex",
        flexDirection: "column",
        width: containerWidth,
        ...customStyle,
      })}
    >
      {(labelLeft || labelRight) && (
        <Label
          titleLeft={labelLeft}
          titleRight={labelRight}
          titleTooltip={labelIcon}
          isRequired={isRequired}
          labelCustomStyle={labelCustomStyle}
        />
      )}

      <MuiAutocomplete
        {...props}
        onOpen={props.onOpen}
        options={props.options}
        autoComplete
        autoHighlight
        popupIcon={
          <KeyboardArrowDownRoundedIcon
            sx={{
              color: colors.gray500,
            }}
          />
        }
        renderInput={(params: any) => (
          <TooltipLarge
            {...tooltipProps}
            title={tooltipProps?.title || ""}
            placement="top"
          >
            <TextField
              {...params}
              {...textFieldParams}
              error={Boolean(errorText)}
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  e.stopPropagation();
                }
              }}
              helperText={
                errorText ? (
                  <Fragment>
                    <ErrorIcon
                      css={css`
                        color: ${colors.red700};
                        font-size: 16px;
                        margin-right: 4px;
                      `}
                    />
                    {errorText}
                  </Fragment>
                ) : (
                  ""
                )
              }
              InputProps={{
                ...params.InputProps,
                spellCheck: "false",
                ...(iconLeft && {
                  startAdornment: (
                    <InputAdornment position="start">{iconLeft}</InputAdornment>
                  ),
                }),
                ...(iconRight && {
                  endAdornment: (
                    <InputAdornment position="start">
                      {iconRight}
                      {params.InputProps.endAdornment}
                    </InputAdornment>
                  ),
                }),
              }}
              sx={largeTooltipTextFieldStyleOverrides(colors)}
            />
          </TooltipLarge>
        )}
        componentsProps={{
          paper: {
            sx: muiAutocompleteComponentPropsStyleOverrides(colors, props),
          },
        }}
        sx={[
          muiAutocompleteStyleOverrides(colors, props, padding),
          muiOutlinedInputDefaultStylesOverrides(colors),
        ]}
      />
    </div>
  );
};

export default Autocomplete;
