import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

const checkIfDashboardAndrturnValue = (dashboardPage: boolean, value: string) =>
  dashboardPage ? "unset" : value;

export const useStyles = (
  colors: ThemeColors,
  showBorder: boolean,
  dashboardPage: boolean
) => {
  return {
    pageHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px 24px 15px 24px",
      height: "36px",
      width: "100%",
      backgroundColor: colors.gray50,
      borderBottom: showBorder ? `1px solid ${colors.gray200}` : "none",

      // xsm screen:
      "@media(max-width: 599px)": {
        // leave the default pageHeader height in dashboard page
        display: checkIfDashboardAndrturnValue(dashboardPage, "block"),
        height: checkIfDashboardAndrturnValue(dashboardPage, "80px"),
        padding: "16px 24px 16px 24px",
      },
    },
    pageHeaderTitle: {
      display: "flex",
      alignItems: "center",

      "@media(max-width: 599px)": {
        // do not add margin to the title on dashboard page
        marginBottom: checkIfDashboardAndrturnValue(dashboardPage, "8px"),
      },
    },
    pageHeaderActions: {
      display: "flex",
      gap: "8px",
      "@media(max-width: 599px)": {
        gap: "16px",
      },
    },
  } as CSSObject;
};
