import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import EditCompanyDialogContent from "./EditCompanyDialogContent";
import useEditItem from "../../../inlineActions/hooks/useEditItem";
import { EditCompanyPayloadType } from "../../../../types";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogHeight } from "../../../../../Dialog/types/enums";
import {
  tableDetails,
  tableDetailsResource,
} from "../../../../../../atoms/atoms";
import {
  CompaniesHierarchyDataDto,
  COMPANIES_ACTIONS,
  GeneralDialogProps,
  SINGULAR_ITEM_NAMES,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import DialogTitleWithTooltip from "../../../../../Dialog/components/DialogTitleWithTooltip/DialogTitleWithTooltip";
import useCompanies from "../../../../../Navbar/components/CompanySelector/hooks/useCompanies";

interface IEditCompanyDialogProps {
  rowData: CompaniesHierarchyDataDto;
}

const EditCompanyDialog = ({
  isOpen,
  onClose,
  rowData,
}: IEditCompanyDialogProps & GeneralDialogProps) => {
  const { t } = useTranslation();
  const { editItem } = useEditItem();

  const { getQueryData } = useCompanies(undefined, true);
  const { user, getUserData, tokenInfo } = useAuth();

  const resource = useRecoilValue(tableDetailsResource);
  const currentTableDetails = useRecoilValue(tableDetails);

  const [isReadyToConfirm, setIsReadyToConfirm] = useState<boolean | "" | null>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<EditCompanyPayloadType>({
    id: null,
    name: null,
    comment: null,
    automaticDriverRegistrationEnabled: true,
    autoAssignGlobalScheduleToDriver: true,
    autoAssignGlobalScheduleToVehicle: true
  });

  const editableItemHasOpenedDetails =
    currentTableDetails?.companiesPage &&
    currentTableDetails?.companiesPage.id === payload.id;

  const resourceString = resource && SINGULAR_ITEM_NAMES.Company;
  const isReadyToCallApi = isReadyToConfirm && resource;
  // companies page does not have search field
  const valueInSearchField = null;

  const handleCloseEditCompanyDialog = () => {
    onClose(COMPANIES_ACTIONS.EditCompany);
  };

  const handleSubmit = async () => {
    if (isReadyToCallApi) {
      await editItem(
        setIsLoading,
        resource,
        payload,
        valueInSearchField,
        editableItemHasOpenedDetails,
        resourceString,
        handleCloseEditCompanyDialog
      );

      if (user?.selectedCompany.id === payload.id) {
        tokenInfo && (await getUserData());
      }

      await getQueryData();
    }
  };

  return (
    <Dialog
      data-testid="edit-company-dialog"
      title={
        rowData &&
        ((
          <DialogTitleWithTooltip
            title={t("Dialog##edit company", {
              name: rowData.name,
            })}
            tooltipText={rowData.name}
          />
        ) as any)
      }
      extendToMaxWidth
      maxHeight={DialogHeight.MaxHeight}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseEditCompanyDialog}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={handleCloseEditCompanyDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-company-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-edit-company"
        />
      }
    >
      {rowData && (
        <EditCompanyDialogContent
          rowData={rowData}
          setPayload={setPayload}
          setIsReadyToConfirm={setIsReadyToConfirm}
        />
      )}
    </Dialog>
  );
};

export default EditCompanyDialog;
