/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";

import { SetStateAction, useContext } from "react";
import { Trans } from "react-i18next";

import Checkbox from "../../../../components/CheckBox/Checkbox";
import Separator from "../../../../components/Separator/Separator";
import { inputFieldMarginBottom } from "../../../../shared";
import TextLink from "../../../../components/TextLink/TextLink";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { TEXT_LINK_SIZES } from "../../../../components/TextLink/types";

interface TermsOfServiceCheckboxProps {
  acceptedTOS: boolean;
  termsOfServiceCSS: CSSObject;
  setAcceptedTOS: (value: SetStateAction<boolean>) => void;
}

const TermsOfServiceCheckbox = ({
  acceptedTOS,
  termsOfServiceCSS,
  setAcceptedTOS,
}: TermsOfServiceCheckboxProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <>
      <Separator color={colors.gray200} css={css(inputFieldMarginBottom)} />
      <Checkbox
        data-testid="tos-checkbox"
        checked={acceptedTOS}
        onChange={() => setAcceptedTOS(!acceptedTOS)}
        customLabel={
          <Trans
            i18nKey="Auth##terms and conditions"
            components={{
              1: (
                <TextLink
                  noFlex
                  href="https://teltonika-gps.com/about-us/policies-certificates/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  size={TEXT_LINK_SIZES.Normal}
                />
              ),
              2: (
                <TextLink
                  noFlex
                  href="https://teltonika-gps.com/about-us/policies-certificates/data-processing-agreement"
                  target="_blank"
                  rel="noreferrer"
                  underline="none"
                  size={TEXT_LINK_SIZES.Normal}
                />
              ),
            }}
          />
        }
        customStyle={css(termsOfServiceCSS)}
      />
    </>
  );
};

export default TermsOfServiceCheckbox;
