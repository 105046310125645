export enum SCHEDULES_NAMES {
  GlobalSchedule = "Global schedule",
}

export enum SCHEDULE_STATUS {
  NotSelected = "not selected",
  Selected = "selected",
}

export enum MAX_SCHEDULES_COUNT {
  GlobalConfigured = 3,
  NoGlobalConfigured = 2,
}

export enum SCHEDULE_PAYLOAD_NAMES {
  Name = "name",
  StartDate = "startDate",
  Repeat = "repeat",
  CustomRepeat = "customRepeat",
  FileTypes = "fileTypes",
  Global = "global",
}

export enum REPEAT_DAY_VALUES {
  Daily = 1,
  Hourly = -1,
  Weekly = 7,
  Biweekly = 14,
  Every28Days = 28,
  Every90Days = 90,
}

export enum REPEAT_TYPE_VALUES {
  Hourly = "hourly",
  Single = "single",
  Multiple = "multiple",
}

export enum REPEAT_NAME_VALUES {
  Custom = "Custom",
  Daily = "Daily",
  Hourly = "Hourly",
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Every28Days = "Every28Days",
  Every90Days = "Every90Days",
}

export enum CUSTOM_REPEAT_INPUT_REQUIREMENTS {
  Min = 1,
  Max = 366,
}
