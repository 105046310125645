/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import TollIcon from "@mui/icons-material/Toll";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../../styles";

import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

const Credits = () => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { navigationItem, additionalNavButton, navItemTypography } = useStyles(
    colors
  );

  return (
    <li>
      <div
        className="navigation-item"
        onClick={() => console.log("show credits")}
        css={css([navigationItem, additionalNavButton])}
      >
        <TollIcon />
        <Typography variant="body2" css={css(navItemTypography)}>
          {t("General##credits")}
        </Typography>
      </div>
    </li>
  );
};

export default Credits;
