/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import {
  deselectAllRows,
  deselectSelectedVisibleRows,
  selectAllVisibleRows,
} from "../../functions";
import {
  generateTableToolbarStyle,
  tableHeadToolbarDivStyle,
} from "../../styles/styleFunctions";
import Checkbox from "../../../CheckBox/Checkbox";
import TextLink from "../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../TextLink/types";
import {
  rowsEnabledToSelect,
  selectedTableRows,
} from "../../../../atoms/atoms";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { TableRowData, useMediaQueries } from "../../../../shared";

interface TableHeadToolbarProps {
  children: ReactNode;
}

const TableHeadToolbar = ({ children }: TableHeadToolbarProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { toMd, fromMd } = useMediaQueries();

  const [selectedRows, setSelectedRows] = useRecoilState<any>(
    selectedTableRows
  );
  const selectableRows = useRecoilValue(rowsEnabledToSelect);

  const rowsCount = selectedRows.length;
  const visibleTableItemsIdArray = selectableRows.map(
    (row: TableRowData) => row.id
  );

  let checkIfAllSelectedItemsIncludesVisibleTableItems = (
    arr: any[],
    target: any[]
  ) => target.every((v: any) => arr.includes(v));

  const allSelected = checkIfAllSelectedItemsIncludesVisibleTableItems(
    visibleTableItemsIdArray,
    selectedRows
  );

  const atLeastSingleSelected = selectedRows.some((rowId: string) =>
    selectableRows.map((row: TableRowData) => row.id).includes(rowId)
  );

  const handletableHeadCheckboxChange = () => {
    if (!atLeastSingleSelected) {
      deselectSelectedVisibleRows(setSelectedRows, selectableRows);
    } else {
      selectAllVisibleRows(setSelectedRows, visibleTableItemsIdArray);
    }
  };

  return (
    <div css={css(generateTableToolbarStyle(colors))}>
      <div css={css(tableHeadToolbarDivStyle(toMd))}>
        <Checkbox
          id={uuidv4()}
          label={t("Button##rows selected", {
            count: rowsCount,
          })}
          checked={allSelected}
          indeterminate={!allSelected && atLeastSingleSelected}
          onChange={handletableHeadCheckboxChange}
        />
      </div>

      {children}

      {fromMd && (
        <TextLink
          size={TEXT_LINK_SIZES.Normal}
          onClick={() => deselectAllRows(setSelectedRows)}
          css={css({
            marginLeft: "16px",
          })}
        >
          {t("Button##deselect all")}
        </TextLink>
      )}
    </div>
  );
};

export default TableHeadToolbar;
