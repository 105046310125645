import { noop } from "lodash";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import SharedToolbar from "./SharedToolbar";

import { Actions } from "../../../PageHeader/actions/types";

import { tableName } from "../../../../atoms/atoms";
import {
  COMPANIES_ACTIONS,
  DRIVERS_ACTIONS,
  ROUTES,
  TABLE_NAMES,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import { useReleaseFeatureToggle } from "../../../../shared/featureToggles/hooks/useReleaseFeatureToggle";
import { FEATURE_NAMES } from "../../../../shared/featureToggles/types";

interface TableHeadActionsProps {
  toggleActiveHead?: (value: boolean) => void;
}

const TableHeadActions = ({ toggleActiveHead }: TableHeadActionsProps) => {
  const location = useLocation();
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const currentTableName = useRecoilValue(tableName);

  const companyActions = isReleaseFeatureEnabled(
    FEATURE_NAMES.ShowDeleteCompanyButton
  )
    ? [COMPANIES_ACTIONS.DeleteCompany]
    : null;

  const resourceActions = (() => {
    switch (currentTableName) {
      case TABLE_NAMES.Vehicles:
        return [
          VEHICLES_ACTIONS.RemoveSchedules,
          VEHICLES_ACTIONS.DeleteVehicle,
        ];
      case TABLE_NAMES.Drivers:
        return [DRIVERS_ACTIONS.RemoveSchedules, DRIVERS_ACTIONS.DeleteDriver];
      case TABLE_NAMES.Companies:
        return companyActions;
      case TABLE_NAMES.Users:
        return location.pathname === ROUTES.Users
          ? [USERS_ACTIONS.DeleteUser]
          : null;
      default:
        return null;
    }
  })();

  return (
    <SharedToolbar
      resourceActions={resourceActions as Actions[]}
      toggleActiveHead={toggleActiveHead || noop}
    />
  );
};

export default TableHeadActions;
