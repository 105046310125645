import { useState } from "react";
import { useTranslation } from "react-i18next";

import ScheduleFormContent from "../ScheduleFormContent";
import { useScheduleFormInputs } from "../../hooks/useScheduleFormInputs";
import { ResourceParams } from "../../types";

import Dialog from "../../../Dialog/Dialog";
import { DialogWidth } from "../../../Dialog/types/enums";

import useAxiosPrivate from "../../../../api/hooks/useAxiosPrivate";
import { ALERT_STATUS } from "../../../../context/alert/types";
import { GeneralDialogProps, useMediaQueries } from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";
import useResponse from "../../../../shared/hooks/useResponse";

interface CreateScheduleDialogProps extends GeneralDialogProps {
  resourceParams: ResourceParams;
  getSchedulesData: () => Promise<void>;
}

const CreateScheduleDialog = ({
  resourceParams,
  isOpen,
  onClose,
  getSchedulesData,
}: CreateScheduleDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { postData } = useAxiosPrivate();
  const { handleCommonError, handleResponse } = useResponse();

  const { resourceName, actionCreate, apiResource } = resourceParams;

  const {
    schedulePayload,
    repeatPeriod,
    activityDates,
    inputError,
    readyToSubmit,
    inputValues,
    startDateTime,
    resetValueStates,
    validateScheduleName,
    validateStartDate,
    handleFormChange,
    handleTimeChange,
  } = useScheduleFormInputs({ resourceName });

  const [isLoading, setIsLoading] = useState(false);

  const { fileTypes } = schedulePayload;

  const handleCloseDialog = () => onClose(actionCreate);

  const handleCreate = async () => {
    try {
      setIsLoading(true);
      await postData(apiResource, schedulePayload);

      handleResponse(ALERT_STATUS.Success, t("Alert##create schedule success"));
      handleCloseDialog();
      getSchedulesData();
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      data-testid="create-schedule-dialog"
      title={t(`Dialog##create schedule`)}
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={handleCloseDialog}
      submit={handleCreate}
      TransitionProps={{
        onExited: () => resetValueStates(),
      }}
      actions={
        <DialogActions
          onClose={handleCloseDialog}
          isLoading={isLoading}
          isreadyToConfirm={readyToSubmit}
          confirmationButtonText={t("Button##create")}
          confirmationButtonTestId="create-schedule-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="create-schedule-cancel-button"
        />
      }
    >
      <ScheduleFormContent
        inputValues={inputValues}
        inputError={inputError}
        repeatValue={repeatPeriod}
        startDateTime={startDateTime}
        selectedDataTypes={fileTypes}
        activityDates={activityDates}
        handleFormChange={handleFormChange}
        handleTimeChange={handleTimeChange}
        validateScheduleName={validateScheduleName}
        validateStartDate={validateStartDate}
      />
    </Dialog>
  );
};

export default CreateScheduleDialog;
