import { ReactComponent as TachoSyncLogo } from "../../assets/logos/TachoSyncLogo.svg";
import { ReactComponent as TachoSyncLogoSmall } from "../../assets/logos/TachoSyncLogoSmall.svg";
import { useMediaQueries } from "../../shared";

const Logo = ({ ...props }) => {
  const { toSm } = useMediaQueries();

  return toSm ? (
    <TachoSyncLogoSmall {...props} data-testid="tacho-web-logo" />
  ) : (
    <TachoSyncLogo {...props} data-testid="tacho-web-logo" />
  );
};

export default Logo;
