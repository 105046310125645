/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useStyles } from "../../styles/styles";
import { lastActivityState } from "../../types";
import BreakOrRestIcon from "../../../../assets/customIcons/lastActivityStates/BreakOrRestIcon";
import TachographAvailableIcon from "../../../../assets/customIcons/lastActivityStates/TachographAvailableIcon";
import TachographDrivingCircleIcon from "../../../../assets/customIcons/lastActivityStates/TachographDrivingCircleIcon";
import TachographUnknownIcon from "../../../../assets/customIcons/lastActivityStates/TachographUnknownIcon";
import TachographWorkIcon from "../../../../assets/customIcons/lastActivityStates/TachographWorkIcon";

interface IDriverLastActivityState {
  lastActivity: string;
}

const DriverLastActivityState = ({
  lastActivity,
}: IDriverLastActivityState) => {
  const { t } = useTranslation();
  const { driverLastActivityDiv, driverLastActivityIconDiv } = useStyles();

  const isEmptyString = lastActivity === "";
  const iconSize = 16;

  const generateLastActivityIcon = () => {
    const lastActivityStateIcons: {
      [key: string]: (props: any) => React.JSX.Element;
    } = {
      [lastActivityState.BreakOrRest]: BreakOrRestIcon,
      [lastActivityState.Available]: TachographAvailableIcon,
      [lastActivityState.Work]: TachographWorkIcon,
      [lastActivityState.Drive]: TachographDrivingCircleIcon,
      [lastActivityState.Unknown4]: TachographUnknownIcon,
      [lastActivityState.Unknown5]: TachographUnknownIcon,
    };

    const IconComponent = lastActivityStateIcons[lastActivity];

    return IconComponent ? (
      <div
        css={css(driverLastActivityIconDiv)}
        data-testid="last-activity-icon"
      >
        <IconComponent width={iconSize} height={iconSize} />
      </div>
    ) : null;
  };

  return (
    <div css={css(driverLastActivityDiv)}>
      {generateLastActivityIcon()}
      <Typography variant="body2" sx={{ paddingLeft: "4px" }}>
        {isEmptyString ? "-" : t(`Drivers##last activity##${lastActivity}`)}
      </Typography>
    </div>
  );
};

export default DriverLastActivityState;
