/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import MobileTableHeadActive from "./MobileTableHeadActive";
import { mobileTableHeadContentStyle } from "../../../styles/styleFunctions";
import { useStyles } from "../../../styles/styles";
import useTutorialMode from "../../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../../TutorialMode/types";
import CheckboxDefaultCheckedIcon from "../../../../../assets/customIcons/checkbox/CheckboxDefaultCheckedIcon";
import {
  isTableCheckable,
  tableHeadActivity,
} from "../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

const MobileTableHead = () => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const {
    stepIndex,
    stepGroup,
    callTutorialWithTimeout,
    checkIfCorrectTutorialStep,
  } = useTutorialMode();

  const [isTableHeadActive, setIsTableheadActive] = useRecoilState<boolean>(
    tableHeadActivity
  );
  const isCheckable = useRecoilValue(isTableCheckable);
  const { mobileTableHead } = useStyles();

  const handelSelectRowsClick = () => {
    setIsTableheadActive(true);

    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialApplySchedule, 2)) {
      callTutorialWithTimeout(true, stepIndex + 1, stepGroup, true, 100);
    }
  };

  const renderContent = () => {
    if (!isCheckable) {
      return (
        <div
          css={css({
            height: "32px",
            width: "100%",
          })}
        />
      );
    }

    return isTableHeadActive ? (
      <MobileTableHeadActive setIsTableheadActive={setIsTableheadActive} />
    ) : (
      <div
        id="inactive_mobile_head"
        onClick={handelSelectRowsClick}
        css={css(mobileTableHeadContentStyle(colors))}
      >
        <CheckboxDefaultCheckedIcon />

        <Typography variant="body2">{t("Button##select rows")}</Typography>
      </div>
    );
  };

  return (
    <div
      css={css([
        {
          background: colors.blue100,
          borderTop: `1px solid ${colors.blue200}`,
          borderBottom: `1px solid ${colors.blue200}`,
        },
        mobileTableHead,
      ])}
    >
      {renderContent()}
    </div>
  );
};

export default MobileTableHead;
