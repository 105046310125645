import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    bannerMessage: {
      display: "flex",
      alignItems: "center",
      padding: "8px",
      borderRadius: "8px",
      "& > svg": {
        fontSize: "16px",
        alignSelf: "flex-start",
      },

      "& > svg:first-of-type": {
        marginRight: "8px",
      },
    },
    bannerMessageContent: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
    },
    clseBannerIcon: {
      alignSelf: "flex-start",
      fontSize: "16px",
      cursor: "pointer",
      marginLeft: "8px",
    },
  } as CSSObject);
