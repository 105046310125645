/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { deleteActionsList, removeSchedulesList } from "./config";

import DialogWithConfirmation from "../dialogs/DialogWithConfirmation";
import DeleteTableItemsDialogContent from "../dialogs/DeleteTableItemsDialogContent";
import RemoveSchedulesDialogContent from "../dialogs/RemoveSchedulesDialogContent";
import RemoveSchedulesConfirmDialog from "../dialogs/RemoveSchedulesConfirmDialog";
import {
  getActionForSelectionDialogTitle,
  getActionForSelectionDialogDescription,
  getActionForSelectionConfirmationButtonText,
} from "../functions";

import useActionsForSelection from "../../hooks/useActionsForSelection";

import { Actions } from "../../../PageHeader/actions/types/types";
import { selectedTableRows, tableName } from "../../../../atoms/atoms";
import {
  COMPANIES_ACTIONS,
  DRIVERS_ACTIONS,
  maxDialogWidth,
  ROUTES,
  ScheduleOption,
  TABLE_NAMES,
  tableNameParams,
  useMediaQueries,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
} from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";

interface IActionsForSelectionDialogProps {
  isOpen: boolean;
  close: (action: Actions) => void;
  resourceAction: Actions;
  onDefaultView: () => void;
}

const ActionsForSelectionDialog = ({
  isOpen,
  close,
  resourceAction,
  onDefaultView,
}: IActionsForSelectionDialogProps) => {
  const {
    deleteSingleItem,
    deleteBulkOfItems,
    removeBulkOfItems,
  } = useActionsForSelection();

  const { t } = useTranslation();
  const { toMd, fromMd } = useMediaQueries();
  const location = useLocation();

  const selectedRows = useRecoilValue<string[]>(selectedTableRows);
  const currentTableName = useRecoilValue<TABLE_NAMES | undefined>(tableName);

  const isDeleteResourceInResourcesActions = deleteActionsList.includes(
    resourceAction as any
  );

  const isRemoveSchedules = removeSchedulesList.includes(resourceAction as any);

  const [readyToSubmit, setReadyToSubmit] = useState(
    isDeleteResourceInResourcesActions
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [payload, setPayload] = useState<any | null>(null);
  const [schedules, setSchedules] = useState<ScheduleOption[]>([]);

  useEffect(() => {
    setReadyToSubmit(!!payload);
  }, [payload]);

  const handleCloseDialog = () => {
    isRemoveSchedules && setSchedules([]);

    close(resourceAction);
  };

  const isSingle = selectedRows.length === 1;
  const resourceCount = selectedRows?.length;
  const resourceParams = tableNameParams[currentTableName!] || {};
  const companiesPage = location.pathname === ROUTES.Companies;

  const deleteSelectedItems = async () => {
    if (isSingle && !companiesPage) {
      await deleteSingleItem(location.pathname as TABLE_NAMES, setIsLoading);
      handleCloseDialog();
    } else {
      await deleteBulkOfItems(
        location.pathname as TABLE_NAMES,
        payload,
        setIsLoading,
        companiesPage ? selectedRows.length : undefined
      );
      handleCloseDialog();
    }
    toMd && onDefaultView();
  };

  const removeSelectedSchedules = async (removeAction: Actions) => {
    setIsConfirmationOpen(false);
    await removeBulkOfItems(
      resourceParams?.apiResource,
      payload,
      removeAction,
      setIsLoading,
      handleCloseDialog
    );
  };

  const submitAction = async () => {
    switch (resourceAction) {
      case VEHICLES_ACTIONS.DeleteVehicle:
      case DRIVERS_ACTIONS.DeleteDriver:
      case COMPANIES_ACTIONS.DeleteCompany:
      case USERS_ACTIONS.DeleteUser:
        return deleteSelectedItems();

      case VEHICLES_ACTIONS.RemoveSchedules:
      case DRIVERS_ACTIONS.RemoveSchedules:
        return setIsConfirmationOpen(true);

      default:
        console.log(resourceAction);
        break;
    }
  };

  const renderDialogContent = () => {
    switch (resourceAction) {
      case VEHICLES_ACTIONS.DeleteVehicle:
      case DRIVERS_ACTIONS.DeleteDriver:
      case USERS_ACTIONS.DeleteUser:
      case COMPANIES_ACTIONS.DeleteCompany:
        return (
          <DeleteTableItemsDialogContent
            selectedRows={selectedRows}
            setPayload={setPayload}
            resourceName={currentTableName}
          />
        );

      case VEHICLES_ACTIONS.RemoveSchedules:
      case DRIVERS_ACTIONS.RemoveSchedules:
        return (
          <RemoveSchedulesDialogContent
            selectedRows={selectedRows}
            resourceParams={resourceParams}
            schedules={schedules}
            setPayload={setPayload}
            setSchedules={setSchedules}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <DialogWithConfirmation
        data-testid="actions-for-selection-dialog"
        title={getActionForSelectionDialogTitle(resourceAction, t)}
        isActionsSeparator
        isTitleSeparator
        open={isOpen}
        count={resourceCount}
        confirmationDescription={getActionForSelectionDialogDescription(
          resourceAction,
          t
        )}
        submit={submitAction}
        close={handleCloseDialog}
        css={css(fromMd && maxDialogWidth)}
        extendToMaxWidth={toMd}
        actions={
          <>
            <DialogActions
              onClose={handleCloseDialog}
              isLoading={isLoading}
              isreadyToConfirm={readyToSubmit}
              confirmationButtonText={getActionForSelectionConfirmationButtonText(
                resourceAction,
                t
              )}
              cancelButtonTestId={`cancel-${resourceAction}`}
              confirmationButtonTestId={`accept-${resourceAction}`}
              cancelButtonText={t("Button##cancel")}
            />
          </>
        }
      >
        {renderDialogContent()}
      </DialogWithConfirmation>

      {isRemoveSchedules && (
        <RemoveSchedulesConfirmDialog
          schedules={schedules}
          resourceParams={resourceParams}
          isOpen={isConfirmationOpen}
          toMd={toMd}
          isDialogLoading={isLoading}
          setDialogOpen={setIsConfirmationOpen}
          removeSelectedSchedules={removeSelectedSchedules}
        />
      )}
    </>
  );
};

export default ActionsForSelectionDialog;
