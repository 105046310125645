const TachographDrivingCircleIcon = (props: any) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 2.00024C6.47988 2.00024 1.99988 6.48024 1.99988 12.0002C1.99988 17.5202 6.47988 22.0002 11.9999 22.0002C17.5199 22.0002 21.9999 17.5202 21.9999 12.0002C21.9999 6.48024 17.5199 2.00024 11.9999 2.00024ZM11.9999 20.0002C7.58988 20.0002 3.99988 16.4102 3.99988 12.0002C3.99988 7.59024 7.58988 4.00024 11.9999 4.00024C16.4099 4.00024 19.9999 7.59024 19.9999 12.0002C19.9999 16.4102 16.4099 20.0002 11.9999 20.0002Z"
        fill="currentColor"
      />
      <circle cx="12" cy="12" r="3" fill="currentColor" />
    </svg>
  );
};

export default TachographDrivingCircleIcon;
