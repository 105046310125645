import RadioDefaultIcon from "../../assets/customIcons/radio/DefaultIcon";
import RadioDefaultCheckedIcon from "../../assets/customIcons/radio/DefaultCheckedIcon";
import RadioDisabledIcon from "../../assets/customIcons/radio/DisabledIcon";
import RadioDisabledCheckedIcon from "../../assets/customIcons/radio/DisabledCheckedIcon";
import RadioErrorIcon from "../../assets/customIcons/radio/ErrorIcon";
import RadioErrorCheckedIcon from "../../assets/customIcons/radio/ErrorCheckedIcon";

const useRadioIcons = () => {
  const getIcons = (props: any, isError?: boolean) => {
    if (props.disabled) {
      return {
        icon: <RadioDisabledIcon />,
        checkedIcon: <RadioDisabledCheckedIcon />,
      };
    } else if (isError) {
      return {
        icon: <RadioErrorIcon />,
        checkedIcon: <RadioErrorCheckedIcon />,
      };
    } else {
      return {
        icon: <RadioDefaultIcon />,
        checkedIcon: <RadioDefaultCheckedIcon />,
      };
    }
  };

  return { getIcons };
};

export default useRadioIcons;
