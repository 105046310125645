import { useState, useEffect, ReactNode } from "react";
import { useRecoilState } from "recoil";
import { useLocation } from "react-router-dom";

import { STEP_GROUP } from "../../../TutorialMode/types";
import DialogCustom from "../../../DialogCustom/DialogCustom";
import useTutorialMode from "../../../TutorialMode/hooks/useTutorialMode";
import {
  pageGlobalStateObjectResult,
  getPageGlobalStateObjectValue,
} from "../../../Table/functions";
import {
  tableDetails,
  tableDetailsSectionState,
} from "../../../../atoms/atoms";
import { TableDetailsDataStateType } from "../../../../atoms/types";

interface MobileDetailsProps {
  children: ReactNode;
}

const MobileDetails = ({ children }: MobileDetailsProps) => {
  const {
    stepGroup,
    stepIndex,
    checkIfCorrectTutorialStep,
    setTutorialDetails,
  } = useTutorialMode();
  const location = useLocation();

  const [tableDetailsData, setTableDetails] = useRecoilState(tableDetails);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);

  const [isOpen, setOpen] = useState(
    Boolean(pageGlobalStateObjectResult(location.pathname, tableDetailsData))
  );

  useEffect(() => {
    pageGlobalStateObjectResult(location.pathname, tableDetailsData)
      ? setOpen(true)
      : setOpen(false);
  }, [location.pathname, tableDetailsData]);

  useEffect(() => {
    if (isOpen) {
      checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 3) &&
        setTutorialDetails({
          isTutorialOpen: true,
          stepIndex: stepIndex,
          stepGroup: stepGroup,
          isTutorialActive: false,
        });
    }

    // eslint-disable-next-line
  }, [isOpen, stepIndex]);

  const handleCloseDialog = () => {
    setTableDetails((prev: TableDetailsDataStateType | null) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    setOpen(false);
    setIsDetailsOpen(false);
  };

  return pageGlobalStateObjectResult(location.pathname, tableDetailsData) ? (
    <DialogCustom
      data-testid="mobile-details-dialog"
      fullWidth
      open={isOpen}
      close={handleCloseDialog}
      transitionDuration={0}
      noPadding
    >
      {children}
    </DialogCustom>
  ) : null;
};

export default MobileDetails;
