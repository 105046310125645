/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import { useStyles } from "../styles/styles";
import TableIsEmptyIcon from "../../../../../../../assets/customIcons/emptyTable/TableIsEmptyIcon";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";

const NoApiCredentials = () => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { noCredentialsCSS } = useStyles();

  return (
    <div css={css(noCredentialsCSS)}>
      <TableIsEmptyIcon
        css={css({ fontSize: "120px" })}
        data-testid="no-results-icon"
      />

      <Typography variant="body1" sx={{ color: colors.textDark }}>
        {t("Dialog##api management##no credentials header")}
      </Typography>
      <Typography variant="body2Regular" sx={{ color: colors.textSecondary }}>
        {t("Dialog##api management##no credentials description")}
      </Typography>
    </div>
  );
};

export default NoApiCredentials;
