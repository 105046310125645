import { Outlet } from "react-router-dom";

import CookiesBanner from "../components/Privacy/Cookies/CookiesBanner";
import TutorialMode from "../components/TutorialMode/TutorialMode";
import { useGoogleAnalytics } from "../shared/hooks/useGoogleAnalytics";

interface RoutesContentProps {
  user: any;
}

const RoutesContent = ({ user }: RoutesContentProps) => {
  useGoogleAnalytics(user);

  return (
    <>
      <TutorialMode />
      <Outlet />
      <CookiesBanner />
    </>
  );
};

export default RoutesContent;
