/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";

import { useStyles } from "../styles/styles";
import Badge from "../../../../../../Badge/Badge";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import { useDateTime } from "../../../../../../../shared";

interface CompanyCardAuthAccordionProps {
  expandedPanel: string;
  entry: any;
  index: number;
  isLast: boolean;
  rootPadding: string;
  onCollapse: (value: string, expanded: boolean) => void;
  actionButton?: React.JSX.Element;
}

const CompanyCardAuthAccordion = ({
  index,
  entry,
  expandedPanel,
  isLast,
  rootPadding,
  onCollapse,
  actionButton,
}: CompanyCardAuthAccordionProps) => {
  const {
    colors: { textDark, textSecondary },
  } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { currentDateFormat } = useDateTime();

  const isExpanded = expandedPanel === `panel${index}`;

  const {
    mainAccordionCSS,
    headerMainCSS,
    versionRowCSS,
    versionBadgeCSS,
    actionButtonCSS,
    releaseNotesCSS,
  } = useStyles(isExpanded, isLast, rootPadding);

  const { releaseDate, releaseNote, version, fileSize } = entry;

  const formattedDate = moment(releaseDate, "MM/DD/YYYY").format(
    currentDateFormat
  ); // temporary solution until BE returns ISO formatted date

  const titleSubtext = `${formattedDate} - ${fileSize}`;

  return (
    <Accordion
      elevation={0}
      data-testid="news-accordion"
      expanded={isExpanded}
      onChange={(_, expanded) => onCollapse(`panel${index}`, expanded)}
      disableGutters
      css={css(mainAccordionCSS)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        id="accordionHeader"
        key={index}
      >
        <div css={css(headerMainCSS)}>
          <div css={css(versionRowCSS)}>
            <Typography variant="body1">{version}</Typography>

            <div css={css(versionBadgeCSS)}>
              {index === 0 && (
                <Badge color="success">{t("General##latest version")}</Badge>
              )}
            </div>
          </div>

          {releaseDate && (
            <Typography variant="body2" sx={{ color: textSecondary }}>
              {titleSubtext}
            </Typography>
          )}
        </div>
        {actionButton && <div css={css(actionButtonCSS)}>{actionButton}</div>}
      </AccordionSummary>

      <AccordionDetails css={css(releaseNotesCSS)}>
        <Typography component="span" variant="body2" color={textSecondary}>
          {t("Dialog##release notes")}:
        </Typography>

        <ul>
          <li key={releaseNote}>
            <Typography component="span" variant="body2" color={textDark}>
              {releaseNote}
            </Typography>
          </li>
        </ul>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyCardAuthAccordion;
