import { useTranslation } from "react-i18next";
import { BANNER_MESSAGE_STATUSES } from "../../BannerMessage/types";
import { FILE_ROW_LIMIT } from "../constants";
import { Message } from "../../../context/dropzone/dropzoneReducer";

const useSingleFileFunctions = () => {
  const { t } = useTranslation();

  const getErrors = (uniqueErrorCodes: string[]) => {
    return uniqueErrorCodes.map((errorCode) => {
      if (errorCode === "file-invalid-type") {
        return {
          status: BANNER_MESSAGE_STATUSES.Critical,
          title: t("Dropzone##file invalid type"),
        };
      } else {
        return {
          status: BANNER_MESSAGE_STATUSES.Critical,
          title: t("Dropzone##only one file can be attached"),
        };
      }
    });
  };

  const checkIfCorrectNameLength = (
    maxFileNameLength: number | undefined,
    acceptedFile: any
  ) =>
    maxFileNameLength ? acceptedFile.name.length <= maxFileNameLength : true;

  const checkifExceedsRowLimit = (
    rowCount: number,
    setMessage: (
      message: Message | Message[],
      override?: boolean | undefined
    ) => void
  ) => {
    const exceedsLimit = rowCount > 100;
    if (exceedsLimit) {
      setMessage(
        {
          status: BANNER_MESSAGE_STATUSES.Critical,
          title: t("Dropzone##row limit exceeded", {
            rowLimit: FILE_ROW_LIMIT,
          }),
        },
        true
      );
    }
    return exceedsLimit;
  };

  return { checkIfCorrectNameLength, checkifExceedsRowLimit, getErrors };
};

export default useSingleFileFunctions;
