import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { FilterParamsType } from "../types/types";
import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../Table/functions";
import { filterQueryParams } from "../../../atoms/atoms";
import { Filter } from "../../../shared";

const useFilteredValues = () => {
  const location = useLocation();

  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);

  const [filteredValues, setFilteredValues] = useState<Filter[]>([]);

  useEffect(() => {
    const filterObjectFromLocalStorage = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );

    const getfilteredValues = () => {
      const filteredValues: Filter[] = [];
      if (filterObjectFromLocalStorage) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_, val] of Object.entries(filterObjectFromLocalStorage)) {
          const filterValues: Filter[] = val as Filter[];
          filterValues.forEach((v: Filter) => {
            filteredValues.push(v);
          });
        }
      }
      return filteredValues;
    };

    setFilteredValues(getfilteredValues());
  }, [filterParams, location.pathname]);

  const mapThroughFiltersAndRemoveValue = (
    currentFilters: {},
    filterItem: Filter
  ) => {
    let allFilters: {} = currentFilters;
    for (const [key, val] of Object.entries(currentFilters)) {
      let values: any = val;
      if (values.includes(filterItem)) {
        const filteredValuesWithoutCurrentItem = values.filter(
          (val: Filter) => val !== filterItem
        );

        allFilters = {
          ...currentFilters,
          [key]: filteredValuesWithoutCurrentItem,
        };
      }
    }
    // the line below actually removes filter item from local storage
    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, allFilters)
    );
  };

  return { filteredValues, mapThroughFiltersAndRemoveValue };
};

export default useFilteredValues;
