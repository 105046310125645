import { useTranslation } from "react-i18next";

import useTogglePswVisibility from "./useTogglePswVisibility";
import { PASSWORD_TYPE } from "../types";

const usePasswordsFormInputs = () => {
  const { t } = useTranslation();

  const createAccountFormInputs = [
    {
      name: PASSWORD_TYPE.NewPsw,
      title: t("Auth##password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.NewPsw),
    },
    {
      name: PASSWORD_TYPE.Repeat,
      title: t("Auth##repeat password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.Repeat),
    },
  ];

  const resetPaswordFormInputs = [
    {
      name: PASSWORD_TYPE.NewPsw,
      title: t("Auth##new password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.NewPsw),
    },
    {
      name: PASSWORD_TYPE.Repeat,
      title: t("Auth##repeat password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.Repeat),
    },
  ];

  return { createAccountFormInputs, resetPaswordFormInputs };
};

export default usePasswordsFormInputs;
