import { useEffect, useMemo, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useTranslation } from "react-i18next";

import { DownloadLiveStatusDataType } from "./types";
import DownloadStatusDialogContent from "./DownloadStatusDialogContent/DownloadStatusDialogContent";

import { DetailsDataType } from "../../../../types";

import Dialog from "../../../../../Dialog/Dialog";

import { useMediaQueries } from "../../../../../../shared";
import { ApiResources } from "../../../../../../api/resources";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";

interface DownloadStatusDialogProps {
  open: boolean;
  onClose: () => void;
  detailsData: DetailsDataType;
}

const DownloadStatusDialog = ({
  open,
  onClose,
  detailsData,
}: DownloadStatusDialogProps) => {
  const { fromMd, toMd } = useMediaQueries();
  const { postData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const { t } = useTranslation();

  const [socketUrl, setSocketUrl] = useState<string | null>(null);

  const options = useMemo(
    () => ({
      shouldReconnect: () => true,
      retryOnError: true,
    }),
    []
  );

  const { lastJsonMessage, getWebSocket } = useWebSocket(
    socketUrl,
    options,
    Boolean(socketUrl)
  );

  const dialogCustomWidth = fromMd ? 704 : undefined;

  useEffect(() => {
    const getWebsocketToken = async () => {
      try {
        const response = await postData(ApiResources.CreateWebsocketToken);
        setSocketUrl(
          `${process.env.REACT_APP_BASE_URL}${ApiResources.VehiclesLiveStatus}?accessToken=${response.data.token}&VehicleId=${detailsData?.id}`
        );
      } catch (error) {
        handleCommonError(error, t);
      }
    };
    if (open) {
      getWebsocketToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // should be here to prevent infinite loop and to be sure that lastJsonMessage is updated
  // commented out temporarily untill fully tested
  // useEffect(() => {}, [lastJsonMessage, socketUrl]);

  const handleCloseDialog = () => {
    onClose();
    setSocketUrl(null);
    getWebSocket()?.close();
  };

  return (
    <>
      {detailsData && (
        <Dialog
          title={t(`Dialog##download status dialog title`, {
            vehicleNumber: detailsData.number,
          })}
          description={t(`Dialog##download status dialog description`)}
          open={open}
          close={handleCloseDialog}
          extendToMaxWidth={toMd || undefined}
          isActionsSeparator
          actions={
            <DialogActions
              onClose={handleCloseDialog}
              isLoading={false}
              hideAcceptButton
              isreadyToConfirm={null}
              cancelButtonText={t("Button##close")}
            />
          }
          isTitleSeparator
          width={dialogCustomWidth}
        >
          <DownloadStatusDialogContent
            liveStatusData={lastJsonMessage as DownloadLiveStatusDataType}
          />
        </Dialog>
      )}
    </>
  );
};

export default DownloadStatusDialog;
