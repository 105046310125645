import { SetterOrUpdater } from "recoil";

import TableHeadToolbar from "../../TableHeadToolbar/TableHeadToolbar";
import TableHeadActions from "../../../actions/TableHeadActions/TableHeadActions";

interface IMobileTableHeadActiveProps {
  setIsTableheadActive: SetterOrUpdater<boolean>;
}

const MobileTableHeadActive = ({
  setIsTableheadActive,
}: IMobileTableHeadActiveProps) => {
  const toggleActiveHead = (value: boolean) => setIsTableheadActive(value);

  return (
    <>
      <TableHeadToolbar>
        <TableHeadActions toggleActiveHead={toggleActiveHead} />
      </TableHeadToolbar>
    </>
  );
};

export default MobileTableHeadActive;
