import { useReducer, ReactNode, useMemo, useCallback } from "react";

import DropzoneContext from "./dropzoneContext";
import DropzoneReducer, { Message } from "./dropzoneReducer";
import {
  SET_FILE,
  SET_FILES,
  SET_MESSAGE,
  SET_FILE_UPLOAD_STATUS,
  REMOVE_FILE,
  REMOVE_ALL_FILES,
} from "./dropzoneActions";

import { FILE_UPLOAD_STATUS } from "../../shared";

interface DropzoneStateProps {
  children: ReactNode;
}

const DropzoneState = ({ children }: DropzoneStateProps) => {
  const initialState = {
    file: null,
    lineCount: 0,
    columnData: [],
    multipleFiles: [],
    messages: [],
  };

  const getMessageWithResponse = useCallback((error: any) => {
    if (error.response.data.errors) {
      return error.response.data.errors[0].errors[0];
    } else {
      return error.response.data.message
        ? error.response.data.message
        : error.response.statusText;
    }
  }, []);

  const getMessageWithoutResponse = useCallback((error: any) => {
    if (error.request && error.request.statusText) {
      return error.request.statusText;
    }
    return error.message;
  }, []);

  const getCriticalMessage = useCallback(
    (errorResponse: any) => {
      const error = errorResponse;

      const message = error.response
        ? getMessageWithResponse(error)
        : getMessageWithoutResponse(error);

      return { message };
    },
    [getMessageWithResponse, getMessageWithoutResponse]
  );

  const [state, dispatch] = useReducer(DropzoneReducer, initialState);

  const setFile = (file: File | null, fileData?: number[][]) =>
    dispatch({
      type: SET_FILE,
      payload: {
        file,
        fileData: fileData || [],
      },
    });

  const setFiles = (files: File[]) =>
    dispatch({
      type: SET_FILES,
      payload: files,
    });

  const setMessage = (
    message: Message | Message[],
    override: boolean = false
  ) =>
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message: Array.isArray(message) ? message : [message],
        override,
      },
    });

  const setStatus = useCallback(
    (fileUploadStatus: FILE_UPLOAD_STATUS, id: string, rawError?: any) => {
      const error = rawError ? getCriticalMessage(rawError) : null;

      dispatch({
        type: SET_FILE_UPLOAD_STATUS,
        payload: { fileUploadStatus, id, errorMessage: error?.message },
      });
    },
    [getCriticalMessage]
  );

  const removeFile = (id: string) =>
    dispatch({ type: REMOVE_FILE, payload: id });

  const removeAllFiles = () => dispatch({ type: REMOVE_ALL_FILES });

  const value = useMemo(
    () => ({
      file: state.file,
      lineCount: state.lineCount,
      multipleFiles: state.multipleFiles,
      messages: state.messages,
      columnData: state.columnData,
      setFile,
      setFiles,
      setMessage,
      setStatus,
      removeFile,
      removeAllFiles,
    }),
    [
      state.file,
      state.lineCount,
      state.multipleFiles,
      state.messages,
      state.columnData,
      setStatus,
    ]
  );

  return (
    <DropzoneContext.Provider value={value}>
      {children}
    </DropzoneContext.Provider>
  );
};

export default DropzoneState;
