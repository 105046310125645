import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const GeneralSettingsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.85 12L5.55 10.5C5.35 10.4167 5.1625 10.3292 4.9875 10.2375C4.8125 10.1458 4.63333 10.0333 4.45 9.9L3 10.35L2 8.65L3.15 7.65C3.11667 7.43333 3.1 7.21667 3.1 7C3.1 6.78333 3.11667 6.56667 3.15 6.35L2 5.35L3 3.65L4.45 4.1C4.63333 3.96667 4.8125 3.85417 4.9875 3.7625C5.1625 3.67083 5.35 3.58333 5.55 3.5L5.85 2H7.85L8.15 3.5C8.35 3.58333 8.5375 3.67083 8.7125 3.7625C8.8875 3.85417 9.06667 3.96667 9.25 4.1L10.7 3.65L11.7 5.35L10.55 6.35C10.5833 6.56667 10.6 6.78333 10.6 7C10.6 7.21667 10.5833 7.43333 10.55 7.65L11.7 8.65L10.7 10.35L9.25 9.9C9.06667 10.0333 8.8875 10.1458 8.7125 10.2375C8.5375 10.3292 8.35 10.4167 8.15 10.5L7.85 12H5.85ZM6.85 9C7.4 9 7.87083 8.80417 8.2625 8.4125C8.65417 8.02083 8.85 7.55 8.85 7C8.85 6.45 8.65417 5.97917 8.2625 5.5875C7.87083 5.19583 7.4 5 6.85 5C6.3 5 5.82917 5.19583 5.4375 5.5875C5.04583 5.97917 4.85 6.45 4.85 7C4.85 7.55 5.04583 8.02083 5.4375 8.4125C5.82917 8.80417 6.3 9 6.85 9ZM14.8 23L14.35 20.9C14.0667 20.8 13.8042 20.6792 13.5625 20.5375C13.3208 20.3958 13.0833 20.2333 12.85 20.05L10.85 20.7L9.45 18.3L11.05 16.9C11.0167 16.6 11 16.3 11 16C11 15.7 11.0167 15.4 11.05 15.1L9.45 13.7L10.85 11.3L12.85 11.95C13.0833 11.7667 13.3208 11.6042 13.5625 11.4625C13.8042 11.3208 14.0667 11.2 14.35 11.1L14.8 9H17.6L18.05 11.1C18.3333 11.2 18.5958 11.3208 18.8375 11.4625C19.0792 11.6042 19.3167 11.7667 19.55 11.95L21.55 11.3L22.95 13.7L21.35 15.1C21.3833 15.4 21.4 15.7 21.4 16C21.4 16.3 21.3833 16.6 21.35 16.9L22.95 18.3L21.55 20.7L19.55 20.05C19.3167 20.2333 19.0792 20.3958 18.8375 20.5375C18.5958 20.6792 18.3333 20.8 18.05 20.9L17.6 23H14.8ZM16.2 19C17.0333 19 17.7417 18.7083 18.325 18.125C18.9083 17.5417 19.2 16.8333 19.2 16C19.2 15.1667 18.9083 14.4583 18.325 13.875C17.7417 13.2917 17.0333 13 16.2 13C15.3667 13 14.6583 13.2917 14.075 13.875C13.4917 14.4583 13.2 15.1667 13.2 16C13.2 16.8333 13.4917 17.5417 14.075 18.125C14.6583 18.7083 15.3667 19 16.2 19Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default GeneralSettingsIcon;
