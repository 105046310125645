/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";

import { COMPANIES_ACTIONS, NEWS_ACTIONS, VEHICLES_ACTIONS } from "../../..";

import ListChoice from "../../../../components/ListChoice/ListChoice";

interface MoreMenuChoiceProps {
  action: NEWS_ACTIONS | COMPANIES_ACTIONS | VEHICLES_ACTIONS;
  onButtonClick: () => void;
  testId?: string;
  dialog?: React.ReactNode;
}

const MoreMenuChoice = ({
  action,
  onButtonClick,
  testId,
  dialog,
}: MoreMenuChoiceProps) => {
  const { t } = useTranslation();

  const iconCustomStyle = {
    alignSelf: "center",
    width: "16px",
    height: "16px",
  };

  const renderTitle = () => {
    switch (action) {
      case NEWS_ACTIONS.EditNews:
        return t("News##edit");
      case NEWS_ACTIONS.Publish:
        return t("News##publish");
      case NEWS_ACTIONS.Delete:
        return t("News##delete");
      case COMPANIES_ACTIONS.EditCompanyCard:
        return t("Details##edit");
      case VEHICLES_ACTIONS.DownloadStatus:
        return t("Details##download status");
      default:
        return "";
    }
  };

  const renderIcon = () => {
    switch (action) {
      case NEWS_ACTIONS.EditNews:
      case COMPANIES_ACTIONS.EditCompanyCard:
        return <EditRoundedIcon css={css(iconCustomStyle)} />;
      case NEWS_ACTIONS.Publish:
        return <SendIcon css={css(iconCustomStyle)} />;
      case NEWS_ACTIONS.Delete:
        return <DeleteRoundedIcon css={css(iconCustomStyle)} />;
      case VEHICLES_ACTIONS.DownloadStatus:
        return <DownloadingOutlinedIcon />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <ListChoice
        data-testid={testId || "more-menu-choice"}
        title={renderTitle()}
        iconLeft={renderIcon()}
        onClick={onButtonClick}
        alignIconCenter
      />
      {dialog}
    </>
  );
};

export default MoreMenuChoice;
