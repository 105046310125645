/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import ValidationRuleItem from "./ValidationRuleItem";
import { PasswordRuleMetType } from "../../types/types";
import { useStyles } from "../../styles";
import { BUTTON_TYPE } from "../../types";
import { INPUT_LENGTH } from "../../../../shared";

interface IPasswordValidationProps {
  name: BUTTON_TYPE;
  currentPsw: string;
  newPsw: string;
  repeatPsw: string;
  onRulesMet: (value: boolean) => void;
}

const PasswordValidation = ({
  name,
  currentPsw,
  newPsw,
  repeatPsw,
  onRulesMet,
}: IPasswordValidationProps) => {
  const { t } = useTranslation();

  const [isRuleMet, setRuleMet] = useState<PasswordRuleMetType>({
    minLength: false,
    lowercaseUsed: false,
    uppercaseUsed: false,
    pswMatch: false,
    digitUsed: false,
    specCharUsed: false,
  });

  const { passwordValidationBlock } = useStyles();

  const minPswLength = newPsw.length >= INPUT_LENGTH.MinPassword;
  const maxPswLength = newPsw.length <= INPUT_LENGTH.MaxPassword;
  const lowercaseUsed = /[a-z]/.test(newPsw);
  const uppercaseUsed = /[A-Z]/.test(newPsw);
  const passwordsMatch = newPsw.length > 0 && newPsw === repeatPsw;
  const digitIncluded = /\d/.test(newPsw);
  const specialCharIncluded = /\W|_/g.test(newPsw);

  useEffect(() => {
    setRuleMet({
      minLength: minPswLength,
      lowercaseUsed: lowercaseUsed,
      uppercaseUsed: uppercaseUsed,
      pswMatch: passwordsMatch,
      digitUsed: digitIncluded,
      specCharUsed: specialCharIncluded,
    });

    switch (name) {
      case BUTTON_TYPE.SignUp:
      case BUTTON_TYPE.ResetPsw:
        onRulesMet(
          minPswLength &&
            maxPswLength &&
            lowercaseUsed &&
            uppercaseUsed &&
            passwordsMatch &&
            digitIncluded &&
            specialCharIncluded
        );
        break;

      default:
        onRulesMet(
          minPswLength &&
            maxPswLength &&
            lowercaseUsed &&
            uppercaseUsed &&
            passwordsMatch &&
            digitIncluded &&
            specialCharIncluded &&
            currentPsw.length > 0
        );
        break;
    }

    // eslint-disable-next-line
  }, [currentPsw, newPsw, repeatPsw]);

  const displayPasswordMatch =
    name === BUTTON_TYPE.ResetPsw ||
    name === BUTTON_TYPE.ChangePsw ||
    name === BUTTON_TYPE.SignUp;

  return (
    <div
      css={css(passwordValidationBlock)}
      data-testid="password-validation-rules"
    >
      <ValidationRuleItem
        isRuleMet={isRuleMet.minLength}
        ruleText={t("Password##minimum characters", {
          maxNumber: INPUT_LENGTH.MinPassword,
        })}
      />

      <ValidationRuleItem
        isRuleMet={isRuleMet.digitUsed}
        ruleText={t("Password##minimum digits")}
      />

      <ValidationRuleItem
        isRuleMet={isRuleMet.lowercaseUsed}
        ruleText={t("Password##lowercase letter")}
      />
      <ValidationRuleItem
        isRuleMet={isRuleMet.uppercaseUsed}
        ruleText={t("Password##uppercase letter")}
      />

      <ValidationRuleItem
        isRuleMet={isRuleMet.specCharUsed}
        ruleText={t("Password##special character")}
      />

      {displayPasswordMatch && (
        <ValidationRuleItem
          isRuleMet={isRuleMet.pswMatch}
          ruleText={t("Password##passwords must match")}
        />
      )}
    </div>
  );
};

export default PasswordValidation;
