/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../styles";
import { checkIfPositiveNumber } from "../../functions";

import { CreditsDto, CreditsStatuses } from "../../../types";

import PaperBox from "../../../../../../components/PaperBox/PaperBox";
import { COLORS, ThemeColors } from "../../../../../../shared";
import Separator from "../../../../../../components/Separator/Separator";

interface CreditsGeneralInfoProps {
  creditsData: CreditsDto;
  colors: ThemeColors;
  renderFormattedNumber: (number: number) => string;
  formattedNumberColor: (positiveNumber: boolean, incoming?: boolean) => COLORS;
}

const CreditsGeneralInfo = ({
  creditsData,
  colors,
  renderFormattedNumber,
  formattedNumberColor,
}: CreditsGeneralInfoProps) => {
  const { t } = useTranslation();

  const {
    creditsHistoryPaperTitle,
    creditsDiv,
    reservedDiv,
    creditsGeneralInfoPaper,
  } = useStyles({
    colors,
    marginBetweenSections:
      creditsData?.history && creditsData?.history.length > 0,
  });

  const renderCreditsCount = (credits: number) => (
    <Typography
      variant="body1"
      sx={{
        color: formattedNumberColor(
          checkIfPositiveNumber(renderFormattedNumber(credits))
        ),
      }}
    >
      {renderFormattedNumber(credits)}
    </Typography>
  );

  return (
    <PaperBox
      customStyling={creditsGeneralInfoPaper}
      testid="credits-general-info"
    >
      <div css={css([creditsHistoryPaperTitle, creditsDiv])}>
        <Typography variant="body1">
          {t(`Dashboard##${CreditsStatuses.Credits.toLowerCase()}`)}:
        </Typography>
        {renderCreditsCount(creditsData.total)}
      </div>

      <Separator color={colors.gray200} />

      <div css={css([creditsHistoryPaperTitle, reservedDiv])}>
        <Typography variant="body2">
          {t(`Dashboard##${CreditsStatuses.Reserved}`)}:
        </Typography>
        {renderCreditsCount(creditsData.reserved)}
      </div>
    </PaperBox>
  );
};

export default CreditsGeneralInfo;
