import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    dialogBox: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      overflowY: "auto",
    },
  } as CSSObject);
