import { useState } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

import { useReleaseFeatureToggle } from "../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../shared/featureToggles/types";
import {
  USERS_INVITATIONS_DIALOGS,
  UsersInvitationsDataDto,
} from "../../../../../Pages/Users/UsersInvitations/types";
import { ApiResources } from "../../../../../api/resources";

const useUsersInvitationsActions = () => {
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const initialActionDialogOpenState = {
    [USERS_INVITATIONS_DIALOGS.CancelInvitation]: false,
    [USERS_INVITATIONS_DIALOGS.ResendInvitation]: false,
  };

  const [userEmail, setUserEmail] = useState("");
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(
    initialActionDialogOpenState
  );

  const userInvitationActions = [
    {
      title: "resend-invitation",
      open: isActionDialogOpen[USERS_INVITATIONS_DIALOGS.ResendInvitation],
      dialogAction: USERS_INVITATIONS_DIALOGS.ResendInvitation,
      resource: ApiResources.UsersInvitationsResend,
    },
    {
      title: "cancel-invitation",
      open: isActionDialogOpen[USERS_INVITATIONS_DIALOGS.CancelInvitation],
      dialogAction: USERS_INVITATIONS_DIALOGS.CancelInvitation,
      resource: ApiResources.UsersInvitationsCancel,
    },
  ];

  const handleOpenDialog = (
    data: UsersInvitationsDataDto,
    dialogName: USERS_INVITATIONS_DIALOGS
  ) => {
    setUserEmail(data.email);

    setIsActionDialogOpen({
      ...isActionDialogOpen,
      [dialogName]: true,
    });
  };

  const handleCloseDialog = () => {
    setIsActionDialogOpen(initialActionDialogOpenState);
  };

  const inlineActions = [
    {
      title: USERS_INVITATIONS_DIALOGS.CancelInvitation,
      icon: <CloseIcon />,
      clickAction: (data: UsersInvitationsDataDto) =>
        handleOpenDialog(data, USERS_INVITATIONS_DIALOGS.CancelInvitation),
      renderConditions: () =>
        isReleaseFeatureEnabled(FEATURE_NAMES.CancelInvitation),
    },
    {
      title: USERS_INVITATIONS_DIALOGS.ResendInvitation,
      icon: <ReplayRoundedIcon />,
      clickAction: (data: UsersInvitationsDataDto) =>
        handleOpenDialog(data, USERS_INVITATIONS_DIALOGS.ResendInvitation),
      renderConditions: () =>
        isReleaseFeatureEnabled(FEATURE_NAMES.ResendInvitation),
    },
  ];

  return {
    userInvitationActions,
    userEmail,
    inlineActions,
    handleCloseDialog,
  };
};

export default useUsersInvitationsActions;
