/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TableCell } from "@mui/material";
import React, { ReactNode, SyntheticEvent, useContext, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useStyles } from "../../../styles/styles";
import { deselectSingleRow, selectSingleRow } from "../../../functions";
import useCompaniesCheckboxFuntions from "../../../hooks/useCompaniesCheckboxFuntions";
import Checkbox from "../../../../CheckBox/Checkbox";
import useTutorialMode from "../../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../../TutorialMode/types";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import { ROUTES, TableRowData } from "../../../../../shared";
import { selectedTableRows } from "../../../../../atoms/atoms";
import { removeSlashFromPath } from "../../../../../shared/functions/functions";

interface ITableCheckboxProps {
  fromTableRow?: boolean;
  isRowChecked?: any;
  row?: TableRowData | any;
  onTableHeadCheckboxChange?: any;
  isChecked?: boolean;
  fromMobileTableItem?: boolean;
  disabled?: boolean;
  index?: number;
  companyLevelsList?: number[];
}

const TableCheckbox = ({
  fromTableRow,
  isRowChecked,
  row,
  onTableHeadCheckboxChange,
  isChecked,
  fromMobileTableItem,
  disabled,
  index,
  companyLevelsList,
}: ITableCheckboxProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const location = useLocation();

  const [selectedRows, setSelectedRows] = useRecoilState<any>(
    selectedTableRows
  );
  const checkedItem = selectedRows.includes(row?.id);

  const {
    generateEmptyCellCountOfIndexNumber,
    generateRowCheckbox,
    generateIndentedCheckboxDivWithCompanyName,
    mapThroughCompaniesLevels,
  } = useCompaniesCheckboxFuntions(
    row,
    companyLevelsList as number[],
    checkedItem,
    index
  );
  const {
    stepIndex,
    stepGroup,
    checkIfCorrectTutorialStep,
    setTutorialDetails,
  } = useTutorialMode();

  const { tableCheckboxCell, tableCheckboxDiv } = useStyles();

  useEffect(() => {
    if (isRowChecked) {
      selectSingleRow(setSelectedRows, row?.id);
    } else if (selectedRows.includes(row?.id)) {
      return;
    } else {
      deselectSingleRow(setSelectedRows, row?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRowChecked, setSelectedRows, companyLevelsList]);

  const handleRowCheckboxChange = () => {
    if (fromTableRow) {
      if (selectedRows.includes(row?.id)) {
        deselectSingleRow(setSelectedRows, row?.id);
      } else {
        selectSingleRow(setSelectedRows, row?.id);
      }
    }
  };

  const companiesPage = location.pathname === ROUTES.Companies;

  const boxShadowBottom = `inset 0px -1px 0px ${colors.gray200}`;
  const checkedLeftBorder = `inset 2px 0px 0px ${colors.blue700}`;
  const checkboxCellStyle = { zIndex: "4", position: "sticky", left: "0" };

  const arrayOfRowLevels = [...Array(row?.level).fill(row?.level)];

  const renderOptionalTableCell = (children: ReactNode) => {
    if (!fromMobileTableItem) {
      if (companiesPage) {
        if (row?.level > 0) {
          return (
            <TableCell
              css={css({
                padding: "0 !important",
                display: "flex",
                boxShadow: boxShadowBottom,
              })}
            >
              {companyLevelsList &&
                arrayOfRowLevels.map((_, index) => {
                  if (index !== 0) {
                    // generate empty cells in front of checkbox to make space for hierarchy lines drawing.
                    // empty cells count depends on company level number
                    return generateEmptyCellCountOfIndexNumber(
                      Boolean(
                        mapThroughCompaniesLevels(index, arrayOfRowLevels)
                      ),
                      index,
                      arrayOfRowLevels
                    );
                  }
                  return generateEmptyCellCountOfIndexNumber(
                    false,
                    index,
                    arrayOfRowLevels
                  );
                })}

              {/* display Companies page checkbox together with company name for exact design implementation */}
              {generateIndentedCheckboxDivWithCompanyName(children)}
            </TableCell>
          );
        } else {
          generateRowCheckbox(children);
        }
      }

      return (
        // Table row checkbox
        <TableCell
          css={css([
            {
              boxShadow: checkedItem
                ? `${checkedLeftBorder}, ${boxShadowBottom}`
                : boxShadowBottom,
              zIndex: "3",
              alignItems: "center",
            },
            tableCheckboxCell,
          ])}
        >
          {!companiesPage && <div css={css(tableCheckboxDiv)}>{children}</div>}

          {/* display Companies page checkbox together with company name for exact design implementation */}
          {companiesPage && (
            <span css={css({ display: "flex", alignItems: "center" })}>
              <div css={css(tableCheckboxDiv)}>{children}</div>
              <div css={css({ paddingLeft: "12px" })}>{row.name}</div>
            </span>
          )}
        </TableCell>
      );
    } else {
      return children;
    }
  };

  const handleCheckboxClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (
      (fromMobileTableItem &&
        checkIfCorrectTutorialStep(STEP_GROUP.TutorialApplySchedule, 3)) ||
      checkIfCorrectTutorialStep(STEP_GROUP.TutorialApplySchedule, 1)
    ) {
      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: stepIndex + 1,
        stepGroup: stepGroup,
        isTutorialActive: true,
      });
    }
  };

  return (
    <>
      {fromTableRow ? (
        renderOptionalTableCell(
          <Checkbox
            id={`checkbox_${index}_${removeSlashFromPath(
              location.pathname
            )}_body`}
            checked={selectedRows.includes(row?.id)}
            disabled={disabled}
            onChange={handleRowCheckboxChange}
            onClick={handleCheckboxClick}
          />
        )
      ) : (
        // Table head checkbox
        <TableCell css={css(checkboxCellStyle as any)}>
          <div
            css={css([
              tableCheckboxDiv,
              { display: "flex", alignItems: "center" },
            ])}
          >
            <Checkbox
              id={`table_head_${row?.id}`}
              checked={isChecked}
              onChange={onTableHeadCheckboxChange}
              disabled={disabled}
            />

            {/* Display Table header checkbox together with Companies page column name for exact design implementation */}
            {companiesPage && (
              <div css={css({ paddingLeft: "12px" })}>
                {t(`Table##company`)}
              </div>
            )}
          </div>
        </TableCell>
      )}
    </>
  );
};

export default React.memo(TableCheckbox);
