import { useState } from "react";

import LanguageSettings from "./LanguageSettings";
import SettingsInitialContent from "./SettingsInitialContent";
import Tutorials from "./Tutorials";
import { DIALOG_NAMES, SETTINGS_CONTENT_NAMES } from "../../../../types/enums";

interface ISettingsContent {
  isMobile: boolean;
  closeMenu: () => void;
  openDialog: (name: DIALOG_NAMES) => void;
}

const SettingsContent = ({
  isMobile,
  closeMenu,
  openDialog,
}: ISettingsContent) => {
  const [settingsContent, setSettingsContent] = useState<
    SETTINGS_CONTENT_NAMES
  >(SETTINGS_CONTENT_NAMES.Initial);

  const handleSetSettingsContent = (newContent: SETTINGS_CONTENT_NAMES) => {
    setSettingsContent(newContent);
  };

  const renderSettingsContent = () => {
    // settings list with inner menu, but without dialog
    if (settingsContent === SETTINGS_CONTENT_NAMES.Language) {
      return <LanguageSettings setSettingsContent={handleSetSettingsContent} />;
    }

    if (settingsContent === SETTINGS_CONTENT_NAMES.Tutorials) {
      return (
        <Tutorials
          isMobile={isMobile}
          closeMenu={closeMenu}
          setSettingsContent={handleSetSettingsContent}
        />
      );
    }

    // All the settings content
    return (
      <SettingsInitialContent
        isMobile={isMobile}
        setSettingsContent={handleSetSettingsContent}
        closeMenu={closeMenu}
        openDialog={openDialog}
      />
    );
  };

  return renderSettingsContent();
};

export default SettingsContent;
