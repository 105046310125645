/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Lottie from "lottie-react";
import { Trans, useTranslation } from "react-i18next";
import { Step } from "react-joyride";

import { useStyles } from "./styles/styles";
import companyCardAnimation from "../../assets/animations/company_card_tutorial.json";

const useTutorialStepGroups = (fromMd: boolean) => {
  const { t } = useTranslation();

  const { companyCardAnimationCSS } = useStyles();

  const addNewCompanyCardStepsDesktop: Step[] = [
    {
      target: "#companyCardAuthButton",
      title: t("Tutorials##add company card##desktop##first step##title"),
      content: (
        <>
          <Lottie
            animationData={companyCardAnimation}
            loop
            css={css(companyCardAnimationCSS)}
          />
          <Trans i18nKey="Tutorials##add company card##desktop##first step##content" />
        </>
      ),
      placement: "left",
      spotlightPadding: -5,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: false,
    },
    {
      target: "#Companies",
      title: t("Tutorials##add company card##desktop##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##desktop##second step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 0,
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
    },
    {
      target: "#row_0",
      title: t("Tutorials##add company card##desktop##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##desktop##third step##content" />
      ),
      placement: "bottom",
      spotlightPadding: -1,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#desktop-details-aside",
      title: t("Tutorials##add company card##desktop##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##desktop##fourth step##content" />
      ),
      placement: "left",
      spotlightPadding: -6,
      disableScrolling: true,
      disableBeacon: true,
    },

    {
      target: "#add-new-company-card-component",
      title: t("Tutorials##add company card##desktop##fifth step##title"),

      content: (
        <Trans i18nKey="Tutorials##add company card##desktop##fifth step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 2,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#acordion-details-card",
      title: t("Tutorials##add company card##desktop##final step##title"),

      content: (
        <Trans i18nKey="Tutorials##add company card##desktop##final step##content" />
      ),
      placement: "left",
      spotlightPadding: 5,
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  const addNewCompanyCardStepsMobile: Step[] = [
    {
      target: "#mobileDrawerBtn",
      title: t("Tutorials##add company card##mobile##first step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##first step##content" />
      ),
      placement: "left",
      spotlightPadding: 0,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#Companies",
      title: t("Tutorials##add company card##mobile##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##second step##content" />
      ),
      placement: "top",
      spotlightPadding: 1,
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
    },
    {
      target: "#mobile_details_0_item",
      title: t("Tutorials##add company card##mobile##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##third step##content" />
      ),

      placement: "bottom",
      spotlightPadding: 2,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "body",
      title: t("Tutorials##add company card##mobile##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##fourth step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },

    {
      target: "#add-new-company-card-component",
      title: t("Tutorials##add company card##mobile##fifth step##title"),

      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##fifth step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 2,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#acordion-details-card",
      title: t("Tutorials##add company card##mobile##final step##title"),

      content: (
        <Trans i18nKey="Tutorials##add company card##mobile##final step##content" />
      ),
      placement: "top",
      spotlightPadding: 5,
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  const addVehicleStep: Step[] = [
    {
      target: "#add_resource_button",
      title: t("Tutorials##add vehicle##first step##title"),
      content: <Trans i18nKey="Tutorials##add vehicle##first step##content" />,
      placement: fromMd ? "left" : "bottom",
      spotlightPadding: 1,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
  ];

  const applyScheduleStepsDesktop: Step[] = [
    {
      target: "#Vehicles",
      title: t("Tutorials##apply schedule##desktop##first step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##first step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 1,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#checkbox_0_vehicles_body",
      title: t("Tutorials##apply schedule##desktop##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##second step##content" />
      ),
      placement: "right",
      spotlightPadding: -2,
      disableScrollParentFix: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#schedulesDialogBtn",
      title: t("Tutorials##apply schedule##desktop##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##third step##content" />
      ),
      spotlightPadding: 1,
      placement: "right",
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#main_scheduleCards",
      title: t("Tutorials##apply schedule##desktop##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##fourth step##content" />
      ),
      spotlightPadding: 4,
      placement: "top",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#schedules-main-dialog-apply-button",
      title: t("Tutorials##apply schedule##desktop##fifth step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##fifth step##content" />
      ),
      spotlightPadding: 2,
      placement: "top",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#acordion-details-card",
      title: t("Tutorials##apply schedule##desktop##final step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##desktop##final step##content" />
      ),
      placement: "left",
      spotlightPadding: 5,
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  const applyScheduleStepsMobile: Step[] = [
    {
      target: "#mobileDrawerBtn",
      title: t("Tutorials##apply schedule##mobile##first step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##first step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 1,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#Vehicles",
      title: t("Tutorials##apply schedule##mobile##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##second step##content" />
      ),
      placement: "bottom",
      spotlightPadding: -2,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#inactive_mobile_head",
      title: t("Tutorials##apply schedule##mobile##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##third step##content" />
      ),
      spotlightPadding: 1,
      placement: "bottom",
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#checkbox_0_vehicles_body",
      title: t("Tutorials##apply schedule##mobile##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##fourth step##content" />
      ),
      spotlightPadding: 0,
      placement: "top",
      spotlightClicks: true,
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#schedulesDialogBtn",
      title: t("Tutorials##apply schedule##mobile##fifth step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##fifth step##content" />
      ),
      spotlightPadding: 2,
      placement: "bottom",
      spotlightClicks: true,
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "body",
      title: t("Tutorials##apply schedule##mobile##sixth step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##sixth step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#schedules-main-dialog-apply-button",
      title: t("Tutorials##apply schedule##mobile##seventh step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##seventh step##content" />
      ),
      placement: "top",
      spotlightPadding: 2,
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#mobile_details_0_item",
      title: t("Tutorials##apply schedule##mobile##final step##title"),
      content: (
        <Trans i18nKey="Tutorials##apply schedule##mobile##final step##content" />
      ),
      placement: "top",
      spotlightPadding: 0,
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  const createScheduleStepsDesktop: Step[] = [
    {
      target: "body",
      title: t("Tutorials##create schedule##desktop##first step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##first step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#Vehicles",
      title: t("Tutorials##create schedule##desktop##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##second step##content" />
      ),

      spotlightPadding: 0,
      placement: "bottom-start",
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#schedulesDialogBtn",
      title: t("Tutorials##create schedule##desktop##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##third step##content" />
      ),
      spotlightPadding: 1,
      placement: "bottom",
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: ".MuiDialog-paper",
      title: t("Tutorials##create schedule##desktop##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##fourth step##content" />
      ),
      spotlightPadding: -1,
      placement: "top",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#schedules-main-dialog-apply-button",
      title: t("Tutorials##create schedule##desktop##fifth step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##fifth step##content" />
      ),
      spotlightPadding: 2,
      placement: "top",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "body",
      title: t("Tutorials##create schedule##desktop##final step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##desktop##final step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  const createScheduleStepsMobile: Step[] = [
    {
      target: "body",
      title: t("Tutorials##create schedule##mobile##first step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##first step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#mobileDrawerBtn",
      title: t("Tutorials##create schedule##mobile##second step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##second step##content" />
      ),
      placement: "bottom",
      spotlightPadding: 0,
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "#Vehicles",
      title: t("Tutorials##create schedule##mobile##third step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##third step##content" />
      ),
      placement: "top",
      spotlightPadding: 1,
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
    },
    {
      target: "#schedulesDialogBtn",
      title: t("Tutorials##create schedule##mobile##fourth step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##fourth step##content" />
      ),
      spotlightPadding: 2,
      placement: "bottom",
      disableScrolling: true,
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      target: "body",
      title: t("Tutorials##create schedule##mobile##fifth step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##fifth step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "#schedules-main-dialog-apply-button",
      title: t("Tutorials##create schedule##mobile##sixth step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##sixth step##content" />
      ),
      spotlightPadding: 2,
      placement: "bottom",
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: "body",
      title: t("Tutorials##create schedule##mobile##final step##title"),
      content: (
        <Trans i18nKey="Tutorials##create schedule##mobile##final step##content" />
      ),
      placement: "center",
      disableScrolling: true,
      disableBeacon: true,
    },
  ];

  return {
    addNewCompanyCardStepsDesktop,
    addNewCompanyCardStepsMobile,
    addVehicleStep,
    applyScheduleStepsDesktop,
    applyScheduleStepsMobile,
    createScheduleStepsDesktop,
    createScheduleStepsMobile,
  };
};

export default useTutorialStepGroups;
