import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CompanyCardAuthContent from "./components/CompanyCardAuthContent";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import Dialog from "../../../../../Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { useMediaQueries } from "../../../../../../shared";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ApiResources } from "../../../../../../api/resources";

interface CompanyCardAuthProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const CompanyCardAuth = ({ isOpen, closeDialog }: CompanyCardAuthProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const [releases, setReleases] = useState([]);
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      const getCardReaderVersions = async () => {
        try {
          setIsContentLoading(true);
          const { data } = await getData(
            ApiResources.CompanyCardReaderFileVersions
          );

          setReleases(data);
        } catch (error) {
          handleCommonError(error, t);
        }
        setIsContentLoading(false);
      };

      getCardReaderVersions();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Dialog
      data-testid="company-card-auth-dialog"
      title={t("Settings##company card authenticator")}
      close={closeDialog}
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      extendToMaxWidth={toMd}
      width={!toMd ? DialogWidth.MaxWidth : undefined}
      customContentOverflow="scroll"
      actions={
        <DialogActions
          onClose={closeDialog}
          isLoading={false}
          isreadyToConfirm
          hideAcceptButton
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="company-card-auth-close-button"
        />
      }
    >
      <CompanyCardAuthContent
        releases={releases}
        isContentLoading={isContentLoading}
      />
    </Dialog>
  );
};

export default CompanyCardAuth;
