import EditEmailRecipient from "./EditEmailRecipient/EditEmailRecipient";
import RemoveEmailRecipient from "./RemoveEmailRecipient/RemoveEmailRecipient";
import { EmailTransferProps } from "../../../types";

const EmailTransferActions = ({
  card,
  companyId,
  onActionsMenu,
}: EmailTransferProps) => {
  return (
    <>
      <EditEmailRecipient
        card={card}
        companyId={companyId}
        onActionsMenu={onActionsMenu}
      />
      <RemoveEmailRecipient
        card={card}
        companyId={companyId}
        onActionsMenu={onActionsMenu}
      />
    </>
  );
};

export default EmailTransferActions;
