import { useContext } from "react";
import MuiAvatar from "@mui/material/Avatar";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface IAvatarProps {
  textToCut: string;
  customStyle?: any;
}

const Avatar = ({ textToCut, customStyle }: IAvatarProps) => {
  const { colors } = useContext(ThemeContext);

  const avatarLetter = textToCut && textToCut.charAt(0).toUpperCase();

  return (
    <MuiAvatar
      data-testid="avatar"
      sx={[
        {
          color: colors.blue700,
          background: colors.blue100,
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.1px",
          width: "24px",
          height: "24px",
        },
        customStyle,
      ]}
    >
      {avatarLetter}
    </MuiAvatar>
  );
};

export default Avatar;
