export const indexOfNextSameValueInALevelsArray = (
  levelListWithoutCurrentLevel: number[],
  levelNumber: number
) => levelListWithoutCurrentLevel.findIndex((x) => x === levelNumber);

export const arrayWithOnlyOneSameValue = (
  levelListWithoutCurrentLevel: number[],
  nextSameValueIndex: number
) => levelListWithoutCurrentLevel.slice(0, nextSameValueIndex + 1);

export const checkIfNextLevelHasLowerNumber = (
  levelListWithoutCurrentLevel: number[],
  nextSameValueIndex: number,
  levelNumber: number
) => {
  return levelListWithoutCurrentLevel[nextSameValueIndex - 1] <= levelNumber;
};
