import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { VEHICLES_TABLE_COLUMNS } from "./config";
import PageWrapperWithDetails from "../wrappers/PageWrapperWithDetails";
import VehiclesHeaderActions from "../../components/PageHeader/actions/pageHeaderActions/VehiclesHeaderActions/VehiclesHeaderActions";
import Table from "../../components/Table/Table";
import { ROUTES, TABLE_NAMES } from "../../shared";
import LayoutWrapper from "../../components/Layout/LayoutWrapper";
import useTableData from "../../components/Table/hooks/useTableData";
import useVehicleInlineActions from "../../components/Table/actions/inlineActions/hooks/useVehicleInlineActions";
import EditVehicleDialog from "../../components/Table/actions/dialogs/Vehicles/EditVehicleDialog/EditVehicleDialog";
import { isTableCheckable } from "../../atoms/atoms";
import { useAuth } from "../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../roles/types/enums";

const Vehicles = () => {
  const { getAccessStatus } = useAuth();

  useTableData();
  const {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  } = useVehicleInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  const allowedToManageVehicles = getAccessStatus(
    USER_PERMISSIONS.ManageVehicles
  );

  useEffect(() => {
    setIsCheckable(allowedToManageVehicles);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWrapperWithDetails
        pathname={ROUTES.Vehicles}
        currTableColumns={VEHICLES_TABLE_COLUMNS}
        currTableName={TABLE_NAMES.Vehicles}
        inlineActions={allowedToManageVehicles ? inlineActions : []}
      >
        <LayoutWrapper showDetailsSection>
          {allowedToManageVehicles && <VehiclesHeaderActions />}
        </LayoutWrapper>
        <Table hasDetails hideFilters={false} hasTableView />
      </PageWrapperWithDetails>

      {/* inline action dialog */}
      {rowData && (
        <EditVehicleDialog
          isOpen={isOpen}
          onClose={closeDialog}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default Vehicles;
