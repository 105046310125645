import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

export const useStyles = (colors: ThemeColors, textStyles: any) =>
  ({
    datePickerOverride: {
      "& .MuiDateCalendar-root": {
        width: "324px",
        height: "324px",
      },

      "& .MuiPickersCalendarHeader-root": {
        margin: "16px 0",
        padding: "0 24px",
        minHeight: "unset",
      },

      "& .MuiPickersCalendarHeader-label": {
        color: colors.textDark,
        marginRight: "4px",
        textTransform: " capitalize",
        ...textStyles,
      },

      // Fixes library bug when changing month
      "& .MuiPickersCalendarHeader-label:nth-of-type(2)": {
        position: "absolute",
        top: "0",
        left: "0",
      },

      "& .MuiPickersCalendarHeader-switchViewButton": {
        padding: "4px",
        svg: {
          fontSize: "16px",
          color: colors.textDark,
        },
      },

      "& .MuiDayCalendar-header": {
        marginBottom: "4px",
      },

      "& .MuiPickersYear-yearButton, .MuiPickersMonth-monthButton": {
        color: colors.gray700,
        height: "32px",
        margin: "4px 0",
        width: "calc(100% - 8px)",
        ...textStyles,

        "&.Mui-selected": {
          color: colors.white,
          background: colors.blue700,
        },
        "&.Mui-selected:focus": {
          background: colors.blue700,
        },
      },

      "& .MuiYearCalendar-root, .MuiMonthCalendar-root": {
        width: "unset",
        marginTop: "-4px",
        padding: "0 8px 12px 8px",
      },

      "& .MuiYearCalendar-root": {
        maxHeight: "252px",
      },

      "& .MuiDayCalendar-weekDayLabel": {
        color: colors.gray700,
        fontSize: "16px",
        lineHeight: "20px",
        width: "44px",
        height: "unset",
        margin: "0 0 4px 0",
      },

      "& .MuiDayCalendar-weekContainer": {
        margin: "4px 0",
      },

      "& .MuiPickersDay-root": {
        color: colors.textDark,
        fontSize: "16px",
        width: "32px",
        height: "32px",
        margin: "0 6px",
        ...textStyles,

        "&.Mui-selected": {
          background: colors.blue700,
          color: colors.white,
          fontWeight: "600",
        },

        "&.Mui-selected:focus": {
          background: colors.blue700,
        },

        "&.Mui-disabled:not(.Mui-selected)": {
          color: colors.textDarkDisabled,
          textDecoration: "line-through",
        },
      },

      "& .MuiPickersDay-root:focus": {
        background: colors.white,
      },

      "& .MuiPickersDay-root:not(.Mui-selected)": {
        borderColor: colors.gray300,
      },
    },
  } as CSSObject);
