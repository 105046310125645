/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useStyles } from "../../../styles";
import Tooltip from "../../../../Tooltip/Tooltip";
import DoubleLeftArrowIcon from "../../../../../assets/customIcons/actions/DoubleLeftArrowIcon";
import DoubleRightArrowIcon from "../../../../../assets/customIcons/actions/DoubleRightArrowIcon";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

interface IToggledrawerButtonProps {
  isdrawerOpen: boolean;
  onOpendrawer: () => void;
  onDrawerClose: () => void;
}

const ToggleDrawerButton = ({
  isdrawerOpen,
  onOpendrawer,
  onDrawerClose,
}: IToggledrawerButtonProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const { toggleDrawerIcon, navigationItem } = useStyles();

  const renderIconWithTooltip = (children: any) => {
    if (!isdrawerOpen) {
      return (
        <Tooltip
          title={t("Navigation##expand menu")}
          placement="right"
          size="medium"
        >
          <div css={css(toggleDrawerIcon)} data-testid="open-navigation-button">
            {children}
          </div>
        </Tooltip>
      );
    } else {
      return children;
    }
  };

  return (
    <div
      data-testid="toggle-navigation-button"
      onClick={isdrawerOpen ? onDrawerClose : onOpendrawer}
      css={css([
        {
          cursor: "pointer",
          "& > svg": {
            color: colors.gray700,
          },

          "&:hover": {
            backgroundColor: colors.gray100,
          },
        },
        navigationItem,
      ])}
    >
      {isdrawerOpen ? (
        <DoubleLeftArrowIcon />
      ) : (
        renderIconWithTooltip(<DoubleRightArrowIcon />)
      )}
      <Typography
        variant="body2"
        css={css({
          marginLeft: "8px",
          whiteSpace: "nowrap",
        })}
      >
        {t("Navigation##collapse menu")}
      </Typography>
    </div>
  );
};

export default ToggleDrawerButton;
