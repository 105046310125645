import PageHeaderActions from "../PageHeaderActions";
import AddVehiclesDialog from "../../dialogs/vehicles/AddVehicleDialog/AddVehiclesDialog";
import { useDialog } from "../../hooks/useDialog";
import ImportResourceDialog from "../../dialogs/reusableDialogs/ImportResourceDialog/ImportResourceDialog";
import {
  MORE_ACTIONS_KEYS,
  ROUTES,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
} from "../../../../../shared";

const VehiclesHeaderActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <PageHeaderActions
      route={ROUTES.Vehicles}
      openDialog={openDialog}
      addItem
      moreActions={[MORE_ACTIONS_KEYS.Import]}
    >
      <AddVehiclesDialog
        isOpen={isDialogOpen[VEHICLES_ACTIONS.AddVehicle]}
        onClose={closeDialog}
      />

      <ImportResourceDialog
        tableName={TABLE_NAMES.Vehicles}
        isOpen={isDialogOpen[VEHICLES_ACTIONS.ImportVehicle]}
        onClose={closeDialog}
      />
    </PageHeaderActions>
  );
};

export default VehiclesHeaderActions;
