import { Dispatch } from "react";

import { emptyDoughnutStyles } from "../../config";
import { ThemeColors } from "../../../../shared";

export const cutout = 50;
export const diameter = "142";

export const options: any = (
  colors: ThemeColors,
  _data: any,
  setHoveredElement: Dispatch<any>
) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    onHover: function(event: any, activeElements: any) {
      if (activeElements.length > 0) {
        setHoveredElement(activeElements[0].index);
      }
    },
    plugins: {
      tooltip: {
        // disable default tooltip
        enabled: false,
      },
      legend: {
        display: false,
      },
      emptyDoughnut: emptyDoughnutStyles(colors, cutout),
    },
    cutout: cutout,
  };
};
