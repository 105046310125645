/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { useTranslation } from "react-i18next";

import { lineChartAreaBorder, lineChartOptions } from "../../config";
import { useStyles } from "../../styles";

import { CreditsDto, CreditsMonthlyBalanceObjectDto } from "../../../types";

import { useMediaQueries } from "../../../../../../shared";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";

Chart.register(...registerables);

interface CreditsLineChartProps {
  creditsData: CreditsDto | null;
}

const CreditsLineChart = ({ creditsData }: CreditsLineChartProps) => {
  const { colors } = useContext(ThemeContext);
  const { toLg, from1920, fromLg, toSm } = useMediaQueries();
  const { t } = useTranslation();

  const { creditsLineChartAbsoluteDiv } = useStyles({});

  const marginLeftToLg = toLg && "-8px";
  const marginLeftFrom1920 = from1920 && "112px";

  const monthsArray = creditsData?.monthlyBalance.map(
    (monthObject: CreditsMonthlyBalanceObjectDto) =>
      // slice(0, 10) to get only date without time so that it would not show converted current time zone
      toSm
        ? new Date(monthObject.date.slice(0, 10))
            .toLocaleString("default", {
              month: "short",
            })
            .slice(0, 1)
        : new Date(monthObject.date.slice(0, 10)).toLocaleString("default", {
            month: "short",
          })
  );

  const monthlyData = creditsData?.monthlyBalance.map(
    (monthObject: CreditsMonthlyBalanceObjectDto) => monthObject.total
  );

  const labelsObjectArr = creditsData?.monthlyBalance.map(
    (balanceObject: CreditsMonthlyBalanceObjectDto, index: number) => {
      if (index === creditsData?.monthlyBalance.length - 2) {
        // get curr month object and add reserved data to it, so that it would be displayed in the tooltip
        return {
          date: balanceObject.date,
          total: balanceObject.total,
          deducted: balanceObject.deducted,
          added: balanceObject.added,
          reserved: creditsData.reserved,
        };
      } else {
        // all the rest months will not display reserved data
        return balanceObject;
      }
    }
  );

  const data = {
    labels: monthsArray,
    datasets: [
      {
        data: monthlyData,
        // pass all object to form tooltip content
        labels: labelsObjectArr,
        fill: false,
        segment: {
          borderColor: (ctx: any) => {
            return monthsArray && ctx.p1DataIndex < monthsArray?.length - 1
              ? colors.blue600
              : colors.blue500;
          },
          borderDash: (ctx: any) =>
            monthsArray &&
            (ctx.p1DataIndex > monthsArray?.length - 2 ? [10, 5] : [0, 0]),
        },
        hoverBorderWidth: 3,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: colors.white,
        pointStyle: "circle",
        pointRadius: 3.5,
        pointBorderColor: (ctx: any) => {
          return monthsArray && ctx.dataIndex === monthsArray?.length - 1
            ? colors.blue500
            : colors.blue600;
        },
        borderCapStyle: "round",
        spanGaps: true,
      },
    ],
  };

  return (
    <div
      id="credits-line-chart"
      data-testid="credits-line-chart"
      css={css({
        position: "relative",
        width: fromLg ? "100%" : undefined,
        height: toLg ? "168px" : "174px",
        marginLeft: marginLeftToLg || marginLeftFrom1920 || "80px",
        paddingTop: toLg ? "32px" : "unset",
      })}
    >
      <div css={css(creditsLineChartAbsoluteDiv)}>
        <Line
          data={data as any}
          options={lineChartOptions(colors, t, monthsArray, monthlyData) as any}
          plugins={[lineChartAreaBorder]}
        />
      </div>
    </div>
  );
};

export default CreditsLineChart;
