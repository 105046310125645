export enum COLORS {
  Black = "#000000",
  Blue100 = "#EFF5FD",
  Blue200 = "#DDE9FB",
  Blue300 = "#C3D9FA",
  Blue400 = "#A6C8FF",
  Blue500 = "#78A9FF",
  Blue600 = "#0061FC",
  Blue700 = "#003FB4",
  Blue800 = "#001A77",
  Blue900 = "#06113B",
  BlueSideNavigation = "#0B265A",
  Cyan300 = "#67E8F9",
  Cyan600 = "#0891B2",
  Gray50 = "#F7F9FB",
  Gray100 = "#F1F4F8",
  Gray200 = "#E2E7EE",
  Gray300 = "#CCD4DF",
  Gray400 = "#96A2B5",
  Gray500 = "#677488",
  Gray600 = "#4A5567",
  Gray700 = "#334155",
  Gray800 = "#20293A",
  Green50 = "#EDFDF3",
  Green100 = "#C9F8DA",
  Green200 = "#A5F3C2",
  Green300 = "#6FEB9D",
  Green400 = "#4ADE80",
  Green500 = "#22C55E",
  Green600 = "#16A34A",
  Green700 = "#15803D",
  Green800 = "#166534",
  Green900 = "#14532D",
  Grey80 = "#263238",
  InkNormal = "#252A31",
  Orange50 = "#FFF7ED",
  Orange100 = "#FFEDD5",
  Orange200 = "#FED7AA",
  Orange300 = "#FDBA74",
  Orange400 = "#FB923C",
  Orange500 = "#F97316",
  Orange600 = "#EA580C",
  Orange700 = "#C2410C",
  Purple50 = "#F4F0FA",
  Purple100 = "#DDD1F0",
  Purple300 = "#A483D8",
  Purple500 = "#8D64CE",
  Purple700 = "#5A319B",
  Purple900 = "#2D184E",
  Red50 = "#FEF2F2",
  Red100 = "#FEE2E2",
  Red200 = "#FECACA",
  Red300 = "#FCA5A5",
  Red600 = "#DC2626",
  Red700 = "#B91C1C",
  Red800 = "#991B1B",
  Red900 = "#7F1D1D",
  TextDark = "#111729",
  TextDarkDisabled = "#A6B6C8",
  TextSecondary = "#334155",
  TextIconDark = "#162D3D",
  TextPlaceholder = "#697D95",
  TextWarning = "#7C2D12",
  White = "#FFFFFF",
}
