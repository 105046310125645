import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../../../../shared";

interface UseStylesProps {
  colors?: ThemeColors;
  noFlex?: boolean;
}

export const useStyles = ({ colors, noFlex }: UseStylesProps) =>
  ({
    contentBoxTitleBlock: {
      display: "flex",
      marginBottom: "8px",
      alignItems: "center",
    },
    contentBoxPaper: {
      padding: "16px 24px",
      borderRadius: "8px",
      display: noFlex ? "inital" : "flex",
      border: `1px solid ${colors?.gray300}`,
    },
    contentBoxInnerDiv: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    settingPlaceholder: {
      display: "flex",
      alignItems: "center",
    },
    twoFaIconCustomStyle: {
      color: colors?.textDark,
      marginRight: "4px",
    },
    authenticatorLinksContainer: {
      display: "inline-flex",
      flexDirection: "column",

      "& > a:first-of-type": {
        marginBottom: "4px",
      },
    },
    authenticatorQrBlock: { display: "flex", flexDirection: "column" },
    changePswContentBox: {
      marginBottom: "32px",
    },
  } as CSSObject);
