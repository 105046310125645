export const XSM_PAGE_HEADER_HEIGHT = "100px";
export const PAGE_HEADER_HEIGHT = "68px";
export const NAVBAR_HEIGHT = "56px";
export const OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING = "204px";
export const CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING = "36px";
export const OPEN_DESKTOP_DRAWER_WIDTH_WITH_PADDING = "236px";
export const CLOSE_DESKTOP_DRAWER_WIDTH_WITH_PADDING = "68px";
export const FOOTER_HEIGHT_TO_LG = "104px";
export const DESKTOP_TABLE_HEIGHT = `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`;
export const MOBILE_TABLE_HEIGHT = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT})`;
export const MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT_TO_LG})`;
export const COMPANIES_SX_MOBILE_TABLE_HEIGHT = `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT_TO_LG} - 20px)`;
export const TAB_FILTERS_HEIGHT = "52px";
export const INITIAL_DETAILS_TITLE_AREA_HEIGHT_WITH_SHOW_MORE = 101;
export const DETAILS_TITLE_AREA_FIXED_HEIGHT = 103;
export const EMPTY_TABLE_HEADER_HEIGHT = "42px";
export const TIME_INPUT_WIDTH = "108px";
export const TOOLBAR_WITH_TABLE_VIEW = "104px";
export const DEFAULT_TOOLBAR_HEIGHT = 60;
export const MOBILE_TOOLBAR_HEIGHT = 68;
export const dashboardContainerBottomMargin = "12px";
export const DashboardContainerHeight = `calc(100vh - ${PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${dashboardContainerBottomMargin})`;

export const TABLE_TOOLBAR_HEIGHT = (
  toMd: boolean,
  hasSearch: boolean,
  hasTableView?: boolean,
  filtersHeightValue?: number
) => {
  const heightInMd = hasTableView
    ? TOOLBAR_WITH_TABLE_VIEW
    : `${DEFAULT_TOOLBAR_HEIGHT}px`;

  if (hasSearch) {
    const heightWithSearch = toMd ? heightInMd : `${MOBILE_TOOLBAR_HEIGHT}px`;
    return `calc(${heightWithSearch} + ${filtersHeightValue || 0}px)`;
  }
  return "42px";
};

export const TABLE_TOOLBAR_PADDINGS = (
  toMd: boolean,
  hasTableView: boolean | undefined
) => {
  const paddingBottom = hasTableView ? 0 : 8;
  return toMd ? `16px 24px ${paddingBottom}px 24px` : "16px 24px";
};

export const DESKTOP_TABLE_FOOTER_HEIGHT = (toLg: boolean) =>
  toLg ? "104px" : "52px";

export const MOBILE_TABLE_TOOLBAR_AND_SEARCH_HEIGHT = (toMd: boolean) => {
  return toMd ? "112px" : "120px";
};
