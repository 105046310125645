export enum ROUTES {
  Home = "/",
  Dashboard = "/dashboard",
  Vehicles = "/vehicles",
  Drivers = "/drivers",
  VehicleFiles = "/vehicleFiles",
  DriverFiles = "/driversFiles",
  Companies = "/companies",
  Users = "/users",
  UserInvitation = "/UserInvitation",
  UsersInvitations = "/users/invitations",
  FileSynchronization = "/file_synchronization",
  RequestReset = "/requestResetPassword",
  ResetPassword = "/ResetPassword",
  NotFound = "*",
}

export enum ALL_ROUTES {
  AllRoutes = "all",
}

export enum NAVIGATION_ITEMS {
  Vehicles = "Vehicles",
  Dashboard = "Dashboard",
  Drivers = "Drivers",
  Files = "Files",
  Users = "Users",
  Companies = "Companies",
  FileSynchronization = "File synchronization",
}

export enum LOCAL_STORAGE_KEYS {
  ActiveTableColumns = "activeTableColumns",
  JwtKey = "accessToken",
  lng = "i18nextLng",
  DesktopDrawer = "desktopDrawer",
  DesktopDetailsSection = "desktopDetailsSection",
  Tokens = "tokens",
  VehiclesPageParams = "vehiclesPageParams",
  DriversPageParams = "driversPageParams",
  SchedulesPageParams = "schedulesPageParams",
  VehicleFilesPageParams = "vehicleFilesPageParams",
  DriverFilesPageParams = "driverFilesPageParams",
  UsersPageParams = "usersPageParams",
  UsersInvitationsPageParams = "usersInvitationsPageParams",
  CompaniesPageParams = "companiesPageParams",
  Contains = "contains",
  Page = "page",
  PageNumber = "pageNumber",
  SelectedTableItem = "selectedTableItem",
  ShowPerPage = "showPerPage",
  Filters = "filters",
}

export enum LANGUAGES {
  English = "en-US",
  Lithuanian = "lt-LT",
}

export enum COLUMN_NAMES {
  ActivityPeriod = "activity period",
  CardName = "card name",
  CardNumber = "card number",
  Comment = "comment",
  Company = "company",
  CompanyId = "company id",
  ConnectionStatus = "connection status",
  CreatedAt = "created at",
  CreatedBy = "created by",
  dataTypes = "data types",
  Deleted = "deleted",
  DeviceStatus = "device status",
  Driver = "driver",
  DriverFileName = "File name",
  DriverName = "Driver name",
  DriversName = "drivers name",
  DownloadTime = "downloaded at",
  Email = "email",
  Enabled = "enalbled",
  Id = "id",
  InvitedAt = "invited at",
  InvitedBy = "invited by",
  Imei = "imei",
  InvitationStatus = "invitation status",
  LastActivity = "last activity",
  LastConnected = "last connected",
  LastConnection = "last connection",
  LastDownload = "last download",
  Name = "name",
  NextDownload = "next download",
  Number = "number",
  ParentCompany = "parent company",
  PlateNumber = "plate number",
  Role = "role",
  Schedules = "schedules",
  SeenAt = "seen at",
  Status = "status",
  UpdatedAt = "updated at",
  UpdatedBy = "updated by",
  Vehicle = "vehicle",
  VehicleName = "vehicle name",
  VehicleNumber = "vehicle number",
  VehicleFileName = "file name",
}

export enum COLUMN_RESOURCES {
  ActivitiesFrom = "activitiesFrom",
  ActivitiesTo = "activitiesTo",
  CardName = "cardName",
  CardNumber = "cardNumber",
  Comment = "comment",
  Company = "company",
  CompanyId = "companyId",
  ConnectionStatus = "connectionStatus",
  CreatedAt = "createdAt",
  CreatedBy = "createdBy",
  Deleted = "deleted",
  DeviceStatus = "deviceStatus",
  Driver = "driver",
  DriverName = "driverName",
  DriversName = "driversName",
  DownloadTime = "downloadTime",
  Email = "email",
  Enabled = "enabled",
  FileName = "fileName",
  FileTypes = "fileTypes",
  Id = "id",
  InvitedAt = "invitedAt",
  InvitedBy = "invitedBy",
  InvitationStatus = "invitationStatus",
  Imei = "imei",
  LastActivity = "lastActivity",
  LastConnected = "lastConnected",
  LastDownload = "lastDownloadAttempt",
  Name = "name",
  NextDownload = "nextDownload",
  Number = "number",
  ParentCompanyName = "parentCompanyName",
  PlateNumber = "plateNumber",
  Role = "role",
  Schedule = "schedule",
  Schedules = "schedulesCount",
  SeenAt = "seenAt",
  Status = "status",
  UpdatedAt = "updatedAt",
  UpdatedBy = "updatedBy",
  Vehicle = "vehicle",
  VehicleName = "vehicleName",
  VehicleNumber = "vehicleNumber",
  VehicleFileName = "filename",
}

export enum ACTIVITY_STATUS {
  Inactive = "Inactive",
  Offline = "Offline",
  Online = "Online",
  Deactivated = "Deactivated",
  Disabled = "Disabled",
}

export enum DOWNLOAD_ACTIVITY_STATUS {
  Awaiting = "Awaiting connection",
  Successful = "Successful",
  Failed = "Failed",
}

export enum COMPANY_CARD_STATUS {
  Connected = "Connected",
  Disconnected = "Disconnected",
  Expired = "Expired",
  Inactive = "Inactive",
}

export enum INVITATION_STATUS {
  Expired = "Expired",
  Pending = "Pending",
}

export enum RELEASE_VERSION_STATUS {
  Beta = "Beta",
}

export enum TABLE_NAMES {
  Dashboard = "dashboard",
  Vehicles = "vehicles",
  Drivers = "drivers",
  Companies = "companies",
  Schedules = "schedules",
  Files = "files",
  Users = "users",
}

export enum SINGULAR_ITEM_NAMES {
  Company = "Company",
}

export enum ID_TYPE {
  Id = "id",
  Imei = "imei",
  Repeat = "repeat",
}

export type Resource = COLUMN_RESOURCES | COLUMN_RESOURCES[];

export enum LOADER_SIZES {
  Medium = "medium",
  Small = "small",
  Tiny = "tiny",
}

export enum INPUT_LENGTH {
  CardHolderName = 50,
  MinCardHolderName = 3,
  Name = 255,
  Description = 255,
  Imei = 15,
  MaxPassword = 64,
  MinPassword = 12,
  VehicleName = 30,
  CompanyName = 50,
  LzCodeMinimum = 6,
  LzCodeMaximum = 8,
  MinCompanyName = 3,
  MinCompanyCardNameLength = 3,
  MaxCompanyCardNameLength = 50,
  MinCompanyCardIdLength = 12,
  MaxCompanyCardIdLength = 25,
  MinNewsTitleLength = 1,
  MaxNewsTitleLength = 80,
  MaxNewsDescriptionLength = 2000,
}

export enum PASSWORD_VALIDATION_REQUIREMENTS {
  countOfDigit = 1,
}

export enum INPUT_VALUES_LENGTH {
  Imei = 15,
  DriverId = 16,
}

export enum DRIVER_INPUT_VALIDATION_STRINGS {
  DriverIdInvalid = "Dialog##driver id not valid",
  DriverIdEmpty = "Dialog##driver id should not be empty",
}

export enum MORE_ACTIONS_KEYS {
  Import = "import",
}

export enum VEHICLES_ACTIONS {
  AddSchedule = "add to schedule",
  AddVehicle = "add vehicle",
  CreateSchedule = "create vehicle schedule",
  DeleteSchedule = "delete vehicle schedule",
  DeleteVehicle = "delete vehicle",
  DownloadStatus = "download status",
  EditSchedule = "edit vehicle schedule",
  EditVehicle = "edit vehicle",
  ExportVehicle = "export vehicle",
  ImportVehicle = "import vehicle",
  NoTableActions = "no table actions",
  QuickScheduleDownload = "vehicle schedule quick download",
  RemoveSchedules = "remove vehicle schedules",
  Schedules = "vehicle schedules",
}

export enum DRIVERS_ACTIONS {
  AddDriver = "add driver",
  AddSchedule = "add to schedule",
  CreateSchedule = "create driver schedule",
  DeleteSchedule = "delete driver schedule",
  DeleteDriver = "delete driver",
  ImportDriver = "import driver",
  EditSchedule = "edit driver schedule",
  EditDriver = "edit driver",
  QuickScheduleDownload = "driver schedule quick download",
  RemoveSchedules = "remove driver schedules",
  Schedules = "driver schedules",
}

export enum VEHICLE_FILES_ACTIONS {
  Download = "download file vehicle",
  FileSettings = "file settings vehicle",
}

export enum DRIVER_FILES_ACTIONS {
  Download = "download file driver",
  FileSettings = "file settings driver",
}

export enum MAX_ALLOWED_COUNTS {
  DownloadFiles = 100,
}

export enum USERS_ACTIONS {
  EditUser = "edit user",
  DeleteUser = "delete user",
  InviteUser = "invite user",
}

export enum COMPANIES_ACTIONS {
  AddNewEmailRecipient = "add new email recipient",
  CreateCompany = "create company",
  CreateNewCompanyCard = "create new company card",
  DeleteCompany = "delete company",
  EditCompany = "edit company",
  EditCompanyCard = "edit company card",
  EditEmailRecipient = "edit email recipient",
  RemoveCompanyCard = "remove company card",
  RemoveEmailRecipient = "remove email recipient",
}

export enum DATE_FORMATS {
  DateHoursMinutes = "YYYY-MM-DD HH:mm",
  Day = "DD",
  EnUsString = "DD MMMM, yyyy",
  HoursMinutes = "HH:mm",
  HoursMinutesSeconds = "HH:mm:ss",
  ISO_8601 = "YYYY-MM-DDTHH:mm:ssZ",
  Month = "MM",
  Year = "YYYY",
  DayMonthYear = "DD-MM-YYYY",
  MonthDayYear = "MM-DD-YYYY",
  YearMonthDay = "YYYY-MM-DD",
}

export enum VehicleDataTypes {
  Overview = "Overview",
  EventsAndFaults = "EventsAndFaults",
  DetailedSpeed = "DetailedSpeed",
  TechnicalData = "TechnicalData",
  Activities = "Activities",
}

export enum DriverDataTypes {
  DriverCard = "DriverCard",
}

export enum FILE_UPLOAD_STATUS {
  Success = "success",
  Failed = "failed",
  Ready = "ready",
}

export enum FILE_COUNT {
  File = "file",
  Files = "files",
}

export enum FORM_DATA_KEYS {
  DriversFiles = "driversFile",
  VehiclesFiles = "vehiclesFile",
}

export enum RESOURCE_ACTIONS {
  Deleted = "deleted",
  RemovedSchedules = "removed schedules",
}

export enum NEWS_ACTIONS {
  Delete = "delete",
  EditNews = "edit news",
  OpenNew = "open new",
  Publish = "publish",
}

export enum COOKIE_NAMES {
  All = "all",
  Analytics = "analytics",
}
