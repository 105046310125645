/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const Separator = ({ ...props }: any) => {
  const { colors } = useContext(ThemeContext);
  return (
    <div
      {...props}
      data-testid="separator"
      css={css({ height: "1px", background: props.color || colors.gray300 })}
    />
  );
};

export default Separator;
