export enum NEWS_SECTION {
  maxCardTitleLength = 48,
  listImageHeight = 100,
  listImageWidth = 200,
}

export enum NEWS_DIALOG_SIZES {
  height = "640px",
  width = "684px",
  dialogImageHeight = 315,
  dialogImageWidth = 636,
  dialogContentLoaderHeight = 106,
  dialogTextContentLoaderHeightToMd = "40vh",
}
