import { CSSObject } from "@emotion/react";
import { COLORS } from "../../../../../../../shared";

export const useStyles = (
  isExpanded: boolean,
  isLast: boolean,
  rootPadding: string
) =>
  ({
    mainAccordionCSS: {
      borderBottom: isLast ? "0" : `1px  ${COLORS.Gray200} solid`,
      backgroundColor: isExpanded ? COLORS.Gray50 : "transparent",
      "&.MuiAccordion-root": {
        borderRadius: isExpanded ? "8px" : "initial",
      },

      "&.MuiAccordion-root:before": {
        backgroundColor: "transparent",
      },

      "& .MuiAccordionSummary-root": {
        padding: rootPadding,
      },

      "& .MuiAccordionSummary-content": {
        margin: "0",
      },
    },
    headerMainCSS: {
      margin: "0",
      flexDirection: "column",
      alignItems: "flex-start",
      rowGap: "4px",
    },
    versionRowCSS: {
      display: "flex",
      alignItems: "center",
    },
    versionBadgeCSS: {
      paddingLeft: "8px",
      alignItems: "center",
    },
    actionButtonCSS: {
      alignContent: "center",
      margin: "0 16px 0 auto",
    },
    releaseNotesCSS: {
      ul: {
        paddingLeft: "20px",
      },

      li: {
        listStyle: "disc",
      },
    },
  } as CSSObject);
