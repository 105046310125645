/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

import Tooltip from "../../../Tooltip/Tooltip";
import { ThemeColors } from "../../../../shared";

interface GlobalScheduleIconProps {
  specificGlobalSchedule: string | null;
  colors: ThemeColors;
}

const GlobalScheduleIcon = ({
  specificGlobalSchedule,
  colors,
}: GlobalScheduleIconProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={t(`Schedules##global schedule##global icon info`, {
        specificGlobalSchedule: specificGlobalSchedule,
      })}
      size="medium"
      placement="top"
      hasArrow
    >
      <PublicOutlinedIcon
        css={css({
          color: colors.textDark,
          fontSize: "16px",
          display: "flex",
          marginRight: "4px",
        })}
        data-testid="schedules-info-icon"
      />
    </Tooltip>
  );
};

export default GlobalScheduleIcon;
