/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { useStyles } from "../styles";

interface TwoIconsButtonProps {
  title: string;
  icon: ReactNode[];
}

const TwoIconsButton = ({ title, icon }: TwoIconsButtonProps) => {
  const { iconLeftButton, iconRightButton } = useStyles();

  return (
    <>
      <span css={css(iconLeftButton)}>{icon[0]}</span>
      {title}
      <span css={css(iconRightButton)}>{icon[1]}</span>
    </>
  );
};

export default TwoIconsButton;
