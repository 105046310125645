/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

import { useStyles } from "../../../styles";
import { SCHEDULES_NAMES } from "../../../types";

import Tooltip from "../../../../Tooltip/Tooltip";

import { useOverflow } from "../../../../../shared/hooks/useOverflow";
import { TABLE_NAMES } from "../../../../../shared";

interface ScheduleCardTitleProps {
  titleWidth: string;
  resourceName: TABLE_NAMES;
  scheduleName: string;
}

const ScheduleCardTitle = ({
  titleWidth,
  resourceName,
  scheduleName,
}: ScheduleCardTitleProps) => {
  const { t } = useTranslation();
  const { measuredRef, isOverflowing } = useOverflow([]);

  const {
    scheduleTitle,
    globalScheduleInfo,
    scheduleIconAndTitle,
  } = useStyles();

  return (
    <div css={css(scheduleIconAndTitle, { maxWidth: titleWidth })}>
      {scheduleName === SCHEDULES_NAMES.GlobalSchedule && (
        <Tooltip
          title={t(`Schedules##global schedule##info##edit schedule`, {
            variableName: resourceName,
          })}
          size="medium"
          placement="top"
          hasArrow
        >
          <span>
            <PublicOutlinedIcon
              css={css(globalScheduleInfo)}
              data-testid="schedules-info-icon"
            />
          </span>
        </Tooltip>
      )}

      <Tooltip
        title={scheduleName}
        disableHoverListener={!isOverflowing}
        size="medium"
        placement="top"
      >
        <Typography
          variant="body2"
          css={css([scheduleTitle])}
          ref={measuredRef}
        >
          {scheduleName}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default ScheduleCardTitle;
