import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyCardDto, DetailsDataType } from "../../../../types";
import useTableItemDetails from "../../../../hooks/useTableItemdetails";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import { COMPANIES_ACTIONS, useMediaQueries } from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useResponse from "../../../../../../shared/hooks/useResponse";

interface RemoveCompanyCardDialogProps {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  cardData: CompanyCardDto;
  companyData: DetailsDataType;
}

const RemoveCompanyCardDialog = ({
  isDialogOpen,
  onCloseDialog,
  cardData,
  companyData,
}: RemoveCompanyCardDialogProps) => {
  const { toMd } = useMediaQueries();
  const { deleteData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveCompnayCardSubmit = async () => {
    if (companyData) {
      try {
        setIsLoading(true);

        await deleteData(ApiResources.CompanyCards, cardData.id);

        // refresh table details
        getTableItemDetails({
          itemId: companyData.id,
          successMessage: t("Alert##remove company card success"),
          leaveTableDetailsNotUpdated: false,
          valueInSearchField: undefined, // companies page does not have search field,
        });
        onCloseDialog();
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          const responseData: string = err.response?.data
            ? t(`Alert##${err.response?.data.message}`)
            : t("Alert##action failed");
          handleResponse(ALERT_STATUS.Critical, responseData);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      data-testid="remove-company-card-dialog"
      title={t(`Company cards##${COMPANIES_ACTIONS.RemoveCompanyCard}`)}
      description={t(`Company cards##remove company card description`, {
        cardName: cardData?.name,
      })}
      open={isDialogOpen}
      close={onCloseDialog}
      isTitleSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      extendToMaxWidth={toMd}
      submit={handleRemoveCompnayCardSubmit}
      actions={
        <DialogActions
          onClose={onCloseDialog}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##accept")}
          confirmationButtonTestId="remove-company-card-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="remove-company-card-cancel-button"
        />
      }
    />
  );
};

export default RemoveCompanyCardDialog;
