import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import ListChoice from "../../../../../../../ListChoice/ListChoice";
import { SMALL_ICON_SIZE } from "../../../../../../../../shared";

interface RemoveCompanyCardButtonProps {
  onOptionClick: () => void;
}

const RemoveCompanyCardButton = ({
  onOptionClick,
}: RemoveCompanyCardButtonProps) => {
  const { t } = useTranslation();

  return (
    <ListChoice
      data-testid="remove-company-card-option"
      title={t("Details##remove")}
      iconLeft={<DeleteOutlineRoundedIcon sx={{ fontSize: SMALL_ICON_SIZE }} />}
      onClick={onOptionClick}
      alignIconCenter
    />
  );
};

export default RemoveCompanyCardButton;
