/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { useStyles } from "../styles";

interface IconLeftButtonProps {
  title: string;
  icon: ReactNode;
}

const IconLeftButton = ({ title, icon }: IconLeftButtonProps) => {
  const { iconLeftButton } = useStyles();

  return (
    <>
      <span css={css(iconLeftButton)}>{icon}</span>

      {title}
    </>
  );
};

export default IconLeftButton;
