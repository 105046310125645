import { CompaniesHierarchyDataDto } from "../../../shared";

export const mapCompaniesTableRowData = (data: CompaniesHierarchyDataDto) => {
  data.level = 0;
  const dataToFlatten = { ...data, level: 0 };

  return flattenCompanyChildren(dataToFlatten);
};

export const flattenCompanyChildren = (
  companyWithChildren: CompaniesHierarchyDataDto
) => {
  let resultArray: CompaniesHierarchyDataDto[] = [companyWithChildren];
  if (companyWithChildren.childCompanies?.length > 0) {
    let childLevel =
      companyWithChildren.level !== undefined
        ? companyWithChildren.level + 1
        : 0;

    companyWithChildren.childCompanies.forEach(
      (childCompany: CompaniesHierarchyDataDto) => {
        childCompany = { ...childCompany, level: childLevel };

        // recursion:
        flattenCompanyChildren(childCompany).forEach(
          (item: CompaniesHierarchyDataDto) => {
            resultArray.push(item);
          }
        );
      }
    );
  }
  return resultArray;
};
