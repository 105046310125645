/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Credits from "../Credits/Credits";

import Separator from "../../../../Separator/Separator";

const AdditionalButtons = () => {
  return (
    <>
      <Credits />
      <Separator />
      <div css={css({ paddingBottom: "8px" })}></div>
    </>
  );
};

export default AdditionalButtons;
