import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    paragrapghSpacement: {
      marginBottom: "16px",
    },
    paragraphListItem: {
      listStyleType: "inherit",
      marginLeft: "24px",
    },
    textContainer: { textAlign: "justify" },
  } as CSSObject);
