import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { USERS_INVITATIONS_TABLE_COLUMNS, USERS_PAGE_TABS } from "./config";
import {
  MappedUsersInvitationsDataDto,
  UsersInvitationsDataDto,
} from "./types";

import PageWrapperNoDetails from "../../wrappers/PageWrapperNoDetails";

import LayoutWrapper from "../../../components/Layout/LayoutWrapper";
import UsersHeaderActions from "../../../components/PageHeader/actions/pageHeaderActions/UsersHeaderActions/UsersHeaderActions";
import useTableData from "../../../components/Table/hooks/useTableData";
import Table from "../../../components/Table/Table";
import { ROUTES, TABLE_NAMES } from "../../../shared";

import TabFilters from "../../../components/TabFilters/TabFilters";
import useUsersInvitationsActions from "../../../components/Table/actions/inlineActions/hooks/useUsersInvitationsActions";
import UsersInvitationsActionDialog from "../../../components/Table/actions/dialogs/Users/UsersInvitations/UsersInvitationsActionDialog/UsersInvitationsActionDialog";
import { isTableCheckable } from "../../../atoms/atoms";

export const mapUsersInvitationsTableRowData = (
  data: UsersInvitationsDataDto[]
): MappedUsersInvitationsDataDto[] =>
  data.map(
    ({
      id,
      email,
      role,
      invitedBy,
      invitedAt,
      updatedAt,
      company,
      invitationStatus,
    }) => ({
      id,
      email,
      name: company.name,
      role,
      invitedBy,
      invitedAt,
      updatedAt,
      company,
      invitationStatus,
    })
  );

const UsersInvitations = () => {
  useTableData(mapUsersInvitationsTableRowData);
  const {
    userInvitationActions,
    inlineActions,
    userEmail,
    handleCloseDialog,
  } = useUsersInvitationsActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  useEffect(() => {
    setIsCheckable(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageWrapperNoDetails
        currTableName={TABLE_NAMES.Users}
        currTableColumns={USERS_INVITATIONS_TABLE_COLUMNS}
        inlineActions={inlineActions}
      >
        <LayoutWrapper showDetailsSection={false}>
          <UsersHeaderActions currentRoute={ROUTES.UsersInvitations} />
        </LayoutWrapper>

        <Table
          hasDetails={false}
          hideFilters
          hasTabFilters
          tabFilters={<TabFilters tabs={USERS_PAGE_TABS} />}
        />
      </PageWrapperNoDetails>

      {/* inline action dialog */}
      {userInvitationActions.map(({ title, open, dialogAction, resource }) => (
        <UsersInvitationsActionDialog
          key={title}
          isOpen={open}
          userEmail={userEmail}
          onClose={handleCloseDialog}
          dialogAction={dialogAction}
          apiResource={resource}
          testId={title}
        />
      ))}
    </>
  );
};

export default UsersInvitations;
