import { SetStateAction, useState } from "react";
import { useRecoilValue } from "recoil";
import { MenuAnchorEl, useMediaQueries } from "../../../shared";
import {
  desktopDrawerState,
  tableDetailsSectionState,
} from "../../../atoms/atoms";

export const useFilters = () => {
  const { fromLg, toLg } = useMediaQueries();

  const isDesktopDetailsSectionOpen = useRecoilValue(tableDetailsSectionState);
  const isDesktopDrawerOpen = useRecoilValue(desktopDrawerState);

  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

  const handleOpenMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const isPartOfFilterMenu = () => {
    return (
      toLg || (fromLg && isDesktopDetailsSectionOpen && isDesktopDrawerOpen)
    );
  };

  return { anchorEl, handleOpenMenu, handleCloseMenu, isPartOfFilterMenu };
};
