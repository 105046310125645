/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { noop } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import SettingsControlsContentWrapper from "./SettingsControlsContentWrapper";
import { SETTINGS_CONTENT_NAMES } from "../../../../types/enums";
import ListChoice from "../../../../../ListChoice/ListChoice";
import LanguageContext from "../../../../../../context/language/LanguageContext";

interface ILanguageSettings {
  setSettingsContent: (newContent: SETTINGS_CONTENT_NAMES) => void;
}

const LanguageSettings = ({ setSettingsContent }: ILanguageSettings) => {
  const { languages, language, setNewLanguage } = useContext(LanguageContext);

  const { t } = useTranslation();

  return (
    <SettingsControlsContentWrapper onGoBackButton={setSettingsContent}>
      {languages.map(({ id, text, flagSrc }) => {
        const isSelected = id === language;

        return (
          <ListChoice
            key={id}
            title={t(`Languages##${text}`)}
            iconLeft={<img src={flagSrc} alt="" />}
            selected={isSelected}
            css={css({ pointerEvents: isSelected ? "none" : "auto" })}
            onClick={() => (isSelected ? noop() : setNewLanguage(id))}
          />
        );
      })}
    </SettingsControlsContentWrapper>
  );
};

export default LanguageSettings;
