/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../styles";
import Menu from "../../Menu/Menu";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { MenuAnchorEl, useMediaQueries } from "../../../shared";

interface FilterMenuProps {
  isOpen: boolean;
  anchorEl: MenuAnchorEl;
  children: React.ReactNode;
  onClose: () => void;
  hasMoreFilters?: boolean;
}

const FilterMenu = ({
  isOpen,
  anchorEl,
  children,
  onClose,
  hasMoreFilters,
}: FilterMenuProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { fromLg } = useMediaQueries();

  const { filterMenu, filterMenuInnerDiv } = useStyles({});

  const renderMenuTitle = () =>
    hasMoreFilters && fromLg
      ? t("Filter##more filters")
      : t("Filter##table filters");

  return (
    <Menu
      data-testid="filter-menu"
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      MenuListProps={{ variant: "menu" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      css={css(filterMenu)}
    >
      <div css={css(filterMenuInnerDiv)}>
        <Typography
          variant="body2"
          sx={{
            color: colors.gray700,
            marginBottom: "16px",
          }}
        >
          {renderMenuTitle()}
        </Typography>

        {children}
      </div>
    </Menu>
  );
};

export default FilterMenu;
