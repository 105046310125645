/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

interface NewsImageProps {
  setAdaptiveSize?: boolean;
  height?: number | string;
  width?: number | string;
  image: string;
  style?: {};
  testId?: string;
}

const NewsImage = ({
  setAdaptiveSize,
  height,
  width,
  image,
  style,
  testId,
}: NewsImageProps) => {
  const fullWidth = "100%";
  const imageHeight = setAdaptiveSize ? fullWidth : height;
  const imageWidth = setAdaptiveSize ? fullWidth : width;
  const customStyle = setAdaptiveSize && {
    minWidth: fullWidth,
    objectFit: "cover",
  };

  return (
    <img
      data-testid={testId || "news-image"}
      src={`../img/${image}`}
      alt={`${image}`}
      height={imageHeight}
      width={imageWidth}
      css={css([customStyle as {}, style])}
    />
  );
};

export default NewsImage;
