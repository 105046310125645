import { Typography } from "@mui/material";
import DialogCustom from "../../../../DialogCustom/DialogCustom";

interface NotificationsMobileProps {
  isOpen: boolean;
  close: () => void;
}

const NotificationsMobile = ({ isOpen, close }: NotificationsMobileProps) => {
  return (
    <DialogCustom noPadding open={isOpen} close={close}>
      <Typography variant="body2">Notifications content</Typography>
    </DialogCustom>
  );
};

export default NotificationsMobile;
