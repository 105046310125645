/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import { useStyles } from "../styles";
import { NewsDto } from "../types";

import { ThemeColors, useDateTime } from "../../../../../shared";

interface NewsDialogTextContentProps {
  colors: ThemeColors;
  newsData: NewsDto | null;
  toMd: boolean;
}

const NewsDialogTextContent = ({
  colors,
  newsData,
  toMd,
}: NewsDialogTextContentProps) => {
  const { formatDateToEnString } = useDateTime();
  const { newsDialogTextContent } = useStyles({ colors, toMd });

  return (
    <div
      css={css(newsDialogTextContent)}
      data-testid="news-dialog-text-content"
    >
      <Typography variant="body3semiBold" color={colors.textPlaceholder}>
        {formatDateToEnString(newsData?.createdAt)}
      </Typography>
      <Typography
        variant="body2"
        color={colors.textDark}
        sx={{ paddingTop: "8px", whiteSpace: "pre-wrap" }} // whiteSpace: "pre-wrap" is added to keep the line breaks in the text
      >
        {newsData?.content}
      </Typography>
      {/* the div below is added to make some space at the bottom of the long text */}
      <div css={css({ height: "16px" })}></div>
    </div>
  );
};

export default NewsDialogTextContent;
