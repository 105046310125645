import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AddDriversIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.75 5.75C16.75 6.85 16.4 7.75 15.7 8.45C15 9.15 14.1 9.5 13 9.5C11.9 9.5 11 9.15 10.3 8.45C9.6 7.75 9.25 6.85 9.25 5.75C9.25 4.65 9.6 3.75 10.3 3.05C11 2.35 11.9 2 13 2C14.1 2 15 2.35 15.7 3.05C16.4 3.75 16.75 4.65 16.75 5.75Z"
        fill="currentColor"
      />
      <path
        d="M21 15.18V16.03C21 16.44 20.85 16.8 20.56 17.09C20.27 17.38 19.92 17.53 19.5 17.53H18.41C18.33 14.61 15.94 12.27 13 12.27C10.06 12.27 7.67 14.61 7.59 17.53H6.5C6.08 17.53 5.73 17.38 5.44 17.09C5.15 16.8 5 16.44 5 16.03V15.18C5 14.54 5.16 14 5.47 13.55C5.79 13.1 6.2 12.76 6.7 12.53C7.82 12.03 8.89 11.65 9.91 11.4C10.94 11.15 11.97 11.03 13 11.03C14.03 11.03 15.06 11.15 16.07 11.41C17.09 11.67 18.16 12.04 19.27 12.53C19.79 12.76 20.21 13.1 20.52 13.55C20.84 14 21 14.54 21 15.18Z"
        fill="currentColor"
      />
      <path
        d="M17.9101 17.53C17.8301 14.89 15.6601 12.77 13.0001 12.77C10.3401 12.77 8.17008 14.89 8.09008 17.53C8.08008 17.58 8.08008 17.64 8.08008 17.69C8.08008 20.41 10.2901 22.62 13.0001 22.62C15.7101 22.62 17.9201 20.41 17.9201 17.69C17.9201 17.64 17.9201 17.58 17.9101 17.53ZM12.0201 20.91C11.9101 21 11.7701 21.05 11.6301 21.05C11.5301 21.05 11.4301 21.02 11.3301 20.97C10.3801 20.49 9.68008 19.63 9.42008 18.61C9.36008 18.37 9.43008 18.13 9.60008 17.97C9.76008 17.82 10.0001 17.78 10.2101 17.85C11.1601 18.21 11.9101 19.09 12.2301 20.23C12.3001 20.49 12.2201 20.75 12.0201 20.91ZM10.8401 16.46C10.5401 16.46 10.2701 16.32 10.1201 16.08C9.97008 15.84 9.99008 15.56 10.1801 15.33C10.8801 14.48 11.9101 14 13.0001 14C14.0901 14 15.1201 14.48 15.8201 15.33C16.0101 15.55 16.0301 15.83 15.8801 16.08C15.7401 16.32 15.4601 16.46 15.1601 16.46H10.8401ZM14.6701 20.97C14.5701 21.02 14.4701 21.05 14.3701 21.05C14.2301 21.05 14.0901 21 13.9801 20.91C13.7801 20.75 13.7001 20.49 13.7701 20.23C14.0901 19.09 14.8401 18.21 15.7901 17.85C16.0001 17.78 16.2301 17.82 16.4001 17.97C16.5701 18.13 16.6401 18.36 16.5801 18.61C16.3101 19.63 15.6201 20.49 14.6701 20.97Z"
        fill="currentColor"
      />
      <path
        d="M1.58582 9.33928H3.4141V11.1676C3.4141 11.4302 3.50501 11.6423 3.67673 11.8241C3.84845 12.0059 4.07067 12.0868 4.3333 12.0868C4.59592 12.0868 4.80804 11.9958 4.98986 11.8241C5.17168 11.6524 5.25249 11.4302 5.25249 11.1676V9.33928H7.08077C7.3434 9.33928 7.55552 9.24837 7.73734 9.07665C7.90906 8.90494 7.99997 8.68271 7.99997 8.42009C7.99997 8.15746 7.90906 7.94534 7.73734 7.76352C7.56562 7.5918 7.3434 7.5009 7.08077 7.5009H5.25249V5.67261C5.25249 5.40998 5.16158 5.19786 4.98986 5.01604C4.81814 4.83423 4.59592 4.75342 4.3333 4.75342C4.07067 4.75342 3.85855 4.84433 3.67673 5.01604C3.49491 5.18776 3.4141 5.40998 3.4141 5.67261V7.5009H1.58582C1.32319 7.5009 1.11107 7.5918 0.929252 7.76352C0.757535 7.93524 0.666626 8.15746 0.666626 8.42009C0.666626 8.68271 0.757535 8.89484 0.929252 9.07665C1.10097 9.24837 1.32319 9.33928 1.58582 9.33928Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AddDriversIcon;
