import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DoubleLeftArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.7099 17.7062C11.0999 17.3162 11.0999 16.6862 10.7099 16.2962L6.82994 12.4162L10.7099 8.53624C11.0999 8.14624 11.0999 7.51624 10.7099 7.12624C10.3199 6.73624 9.68994 6.73624 9.29994 7.12624L4.70994 11.7162C4.31994 12.1062 4.31994 12.7362 4.70994 13.1262L9.29994 17.7162C9.67994 18.0962 10.3199 18.0962 10.7099 17.7062Z"
        fill="currentColor"
      />
      <path
        d="M17.7075 17.7062C18.0975 17.3162 18.0975 16.6862 17.7075 16.2962L13.8275 12.4162L17.7075 8.53624C18.0975 8.14624 18.0975 7.51624 17.7075 7.12624C17.3175 6.73624 16.6875 6.73624 16.2975 7.12624L11.7075 11.7162C11.3175 12.1062 11.3175 12.7362 11.7075 13.1262L16.2975 17.7162C16.6775 18.0962 17.3175 18.0962 17.7075 17.7062Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DoubleLeftArrowIcon;
