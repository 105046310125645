import React, { ReactNode, useEffect, useMemo, useState } from "react";

import { rolesFeatures, usersFeatures } from "../../shared";
import { releaseFeatures } from "../../shared/featureToggles/releaseFeatures";
import { getEnvironment } from "../../shared/functions/functions";

export const FeatureToggles: any = React.createContext({});

interface FeaturetogglesProps {
  children: ReactNode;
}

export const FeatureTogglesProvider = ({ children }: FeaturetogglesProps) => {
  const [features, setFeatures] = useState({});

  const { isProductionEnv } = getEnvironment();

  useEffect(() => {
    setFeatures({
      usersFeatures,
      releaseFeatures,
      rolesFeatures,
    });
  }, [isProductionEnv]);

  const contextValue = useMemo(
    () => ({
      features,
    }),
    [features]
  );

  return (
    <FeatureToggles.Provider value={contextValue}>
      {children}
    </FeatureToggles.Provider>
  );
};
