import { COLORS } from "../../../../../shared";

export enum DeviceStatus {
  Online = "Online",
  Offline = "Offline",
  Inactive = "Inactive",
  Deactivated = "Deactivated",
}

export enum deviceStatusColors {
  Online = COLORS.Green600,
  Offline = COLORS.Gray200,
  Inactive = COLORS.White,
  Deactivated = COLORS.Red700,
}

export enum deviceStatusBorderColors {
  Online = COLORS.Green600,
  Offline = COLORS.Gray400,
  Inactive = COLORS.Gray400,
  Deactivated = COLORS.Red700,
}
