/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useContext } from "react";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import TextOnlyButton from "./buttonVariants/TextOnlyButton";
import IconLeftButton from "./buttonVariants/IconLeftButton";
import IconRightButton from "./buttonVariants/IconRightButton";
import IconOnlyButton from "./buttonVariants/IconOnlyButton";
import TwoIconsButton from "./buttonVariants/TwoIconsButton";
import { useStyles } from "./styles";
import { styleOptions } from "./constants";
import { ButtonColors, ButtonSizes, ButtonVariants, Ref } from "./types";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import RectangleLoader from "../../shared/components/loaders/RectangleLoader";

export interface ButtonProps
  extends Omit<MuiButtonProps, "children" | "variant" | "color" | "size"> {
  children?: any;
  variant: ButtonVariants;
  size: ButtonSizes;
  color: ButtonColors;
  icon?: any;
  isLoading?: boolean;
  focus?: boolean;
  hasRectangleLoader?: boolean;
  isInitialLoading?: boolean;
  loaderWidth?: number | string;
  highlightAction?: any;
  disableRipple?: boolean;
}

const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      children,
      variant,
      size,
      color,
      icon,
      isLoading,
      focus,
      hasRectangleLoader,
      isInitialLoading,
      loaderWidth,
      highlightAction,
      disableRipple,
      ...props
    },
    ref
  ) => {
    const { colors } = useContext(ThemeContext);

    const getDisabledBgColor = () => {
      if (color === "transparent") {
        return "rgba(255, 255, 255, 0.05)";
      }

      return color === "white" ? colors.white : colors.gray100;
    };

    const renderButtonVariant = () => {
      switch (variant) {
        case "iconLeft":
          return <IconLeftButton title={children} icon={icon} />;
        case "iconRight":
          return <IconRightButton title={children} icon={icon} />;
        case "twoIcons":
          return <TwoIconsButton title={children} icon={icon} />;
        case "iconOnly":
          return <IconOnlyButton icon={icon} />;
        default:
          return <TextOnlyButton title={children} />;
      }
    };

    const bgColorRest = styleOptions.colors[color].background.rest;
    const bgColorHover = styleOptions.colors[color].background.hover;
    const bgColorActive = styleOptions.colors[color].background.active;
    const bgColorDisabled = getDisabledBgColor();

    const textColor = styleOptions.colors[color].text;
    const textColorDisabled =
      color === "transparent"
        ? "rgba(255, 255, 255, 0.3)"
        : colors.textDarkDisabled;

    const padding = styleOptions.paddings[variant][size];
    const fontSize = styleOptions.typography[size].fontSize;
    const lineHeight = styleOptions.typography[size].lineHeight;
    const letterSpacing = styleOptions.typography[size].letterSpacing;

    const circularProgressColor = styleOptions.colors[color].loader;
    const circularProgressSize = size === "tiny" ? 12 : 20;

    const {
      buttonVariant,
      defaultButtonBackgrounds,
      highlightedButton,
    }: any = useStyles(
      colors,
      highlightAction,
      bgColorHover,
      bgColorRest,
      bgColorActive
    );

    return (
      <>
        {hasRectangleLoader && isInitialLoading && (
          <RectangleLoader
            height={36}
            backgroundColor={colors.gray200}
            width={loaderWidth}
          />
        )}
        {!isInitialLoading && (
          <MuiButton
            {...props}
            ref={ref}
            autoFocus={focus}
            variant="contained"
            disableRipple={disableRipple || false}
            sx={[
              {
                textTransform: "none",
                fontWeight: 600,
                zIndex: 5,
                boxShadow: "none",
                minWidth: "unset",
                borderRadius: "6px",
                color: textColor,
                whiteSpace: "nowrap",
                fontSize,
                lineHeight,
                letterSpacing,
                padding,
                pointerEvents: isLoading ? "none" : "auto",

                "&.Mui-focusVisible, &:hover, &:active": {
                  boxShadow: "none",
                },

                "&.Mui-disabled": {
                  backgroundColor: bgColorDisabled,
                  color: textColorDisabled,
                },

                "& .MuiSvgIcon-root": {
                  fontSize: size === "tiny" ? "16px" : "24px",
                },
              },
              highlightAction !== undefined
                ? highlightedButton
                : defaultButtonBackgrounds,
            ]}
          >
            <div
              css={css(
                { visibility: isLoading ? "hidden" : "visible" },
                buttonVariant
              )}
            >
              {renderButtonVariant()}
            </div>

            {isLoading && (
              <CircularProgress
                data-testid="circular-progress"
                size={circularProgressSize}
                sx={{
                  color: circularProgressColor,
                  position: "absolute",
                  left: `calc(50% - ${circularProgressSize / 2}px)`,
                }}
              />
            )}
          </MuiButton>
        )}
      </>
    );
  }
);

export { BUTTON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from "./types/";

export default Button;
