/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import { useStyles } from "./styles";
import StatusIcon from "./StatusIcon";

import { UploadedFileMultipleProps } from "../types";

import Tooltip from "../../Tooltip/Tooltip";
import Button from "../../Button/Button";
import DropzoneContext from "../../../context/dropzone/dropzoneContext";
import FileIcon from "../../../assets/customIcons/FileIcon";
import { FILE_UPLOAD_STATUS } from "../../../shared";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { Typography } from "@mui/material";

const UploadedFileMultiple = ({
  name,
  id,
  isLoading,
  fileUploadStatus,
  failedUpload,
  ...props
}: UploadedFileMultipleProps) => {
  const { colors } = useContext(ThemeContext);
  const { removeFile } = useContext(DropzoneContext);

  const { t } = useTranslation();

  const {
    mainDivCSS,
    statusCSS,
    fileIconCSS,
    uploadStatusCSS,
    loaderCSS,
    failedUploadCSS,
  } = useStyles(colors);

  return (
    <div {...props} css={css(mainDivCSS)}>
      <div css={css(statusCSS)}>
        <div>
          <FileIcon css={css(fileIconCSS)} />

          <Typography
            variant="body2"
            sx={{
              color: colors?.gray700,
              wordBreak: "break-word",
            }}
          >
            {name}
          </Typography>
        </div>
        <div css={css(uploadStatusCSS)}>
          {isLoading && (
            <div css={css(loaderCSS)}>
              <CircularProgress size={20} />
            </div>
          )}

          {fileUploadStatus === FILE_UPLOAD_STATUS.Ready ? (
            <Tooltip
              placement="top"
              title={t("Button##delete")}
              disableTouchListener
            >
              <Button
                color="primarySubtle"
                size="small"
                variant="iconOnly"
                icon={<DeleteOutlineRoundedIcon />}
                disabled={isLoading}
                onClick={() => removeFile(id)}
              />
            </Tooltip>
          ) : (
            <StatusIcon uploadStatus={fileUploadStatus!} />
          )}
        </div>
      </div>

      {failedUpload !== null && (
        <div css={css(failedUploadCSS)}>{failedUpload}</div>
      )}
    </div>
  );
};

export default UploadedFileMultiple;
