import { SetterOrUpdater, useRecoilState } from "recoil";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import useResponse from "./useResponse";

import useAxiosPrivate from "../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../api/resources";
import {
  companyCardsState,
  creditsState,
  deviceStatusState,
  initialLoading,
  newsDataState,
  resetDetailsSectionOnPageChange,
  tableLoading,
} from "../../atoms/atoms";
import { AlertContext } from "../../context/alert/AlertContextProvider";
import { ALERT_MESSAGES } from "../../context/alert/types";

const useRefreshDashboardData = () => {
  const { alertMessage } = useContext(AlertContext);

  const { handleCommonError } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [newsData, setNewsData] = useRecoilState(newsDataState);
  const [, setDeviceStatus] = useRecoilState(deviceStatusState);
  const [, setCompanyCards] = useRecoilState(companyCardsState);
  const [, setCreditsData] = useRecoilState(creditsState);
  const [, setIsTableLoading] = useRecoilState(tableLoading);
  const [, setResetDetailsOnPageChange] = useRecoilState(
    resetDetailsSectionOnPageChange
  );
  const [, setIsInitialLoading] = useRecoilState(initialLoading);

  const refreshNewsData = async () => {
    try {
      setIsTableLoading(true);
      const response = await getData(ApiResources.NewsItems);
      setNewsData(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsTableLoading(false);
    }
  };

  const refreshDashboardCardData = async (
    setData: SetterOrUpdater<any>,
    resource: string,
    resetData: boolean = true
  ) => {
    try {
      setIsTableLoading(true);
      // reset value to null to remove flash of old data
      resetData && setData(null);
      const response = await getData(resource);
      setData(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsTableLoading(false);
    }
  };

  const refreshDashboardData = () => {
    refreshDashboardCardData(setNewsData, ApiResources.NewsItems, false);
    refreshDashboardCardData(setDeviceStatus, ApiResources.DashboardDevices);
    refreshDashboardCardData(
      setCompanyCards,
      ApiResources.DashboardCompanyCards
    );
    refreshDashboardCardData(setCreditsData, ApiResources.DashboardCredits);
  };

  const finishLoadingIfAllDataIsFetched = useCallback(() => {
    if (newsData || alertMessage?.title === ALERT_MESSAGES.actionFailed) {
      setIsInitialLoading(false);
      setResetDetailsOnPageChange(false);
    }
  }, [
    newsData,
    setIsInitialLoading,
    setResetDetailsOnPageChange,
    alertMessage,
  ]);

  // the function below is used to reset the data when the page is changed,
  // so that the loading state is shown again
  const resetDashboardDataOnPageChange = useCallback(() => {
    setNewsData(null);
    setDeviceStatus(null);
    setCompanyCards(null);
  }, [setNewsData, setDeviceStatus, setCompanyCards]);

  return {
    refreshDashboardData,
    resetDashboardDataOnPageChange,
    finishLoadingIfAllDataIsFetched,
    refreshNewsData,
  };
};

export default useRefreshDashboardData;
