import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const RemoveSchedulesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M5 20C4.45 20 3.97917 19.8042 3.5875 19.4125C3.19583 19.0208 3 18.55 3 18V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V3C6 2.71667 6.09583 2.47917 6.2875 2.2875C6.47917 2.09583 6.71667 2 7 2C7.28333 2 7.52083 2.09583 7.7125 2.2875C7.90417 2.47917 8 2.71667 8 3V4H14V3C14 2.71667 14.0958 2.47917 14.2875 2.2875C14.4792 2.09583 14.7167 2 15 2C15.2833 2 15.5208 2.09583 15.7125 2.2875C15.9042 2.47917 16 2.71667 16 3V4H17C17.55 4 18.0208 4.19583 18.4125 4.5875C18.8042 4.97917 19 5.45 19 6V12.1C18.6667 12.05 18.3333 12.025 18 12.025C17.6667 12.025 17.3333 12.05 17 12.1V10H5V18H12C12 18.3333 12.025 18.6667 12.075 19C12.125 19.3333 12.2167 19.6667 12.35 20H5ZM5 8H17V6H5V8Z"
        fill="#334155"
      />
      <path
        d="M20 16H16C15.45 16 15 16.45 15 17C15 17.55 15.45 18 16 18H20C20.55 18 21 17.55 21 17C21 16.45 20.55 16 20 16Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RemoveSchedulesIcon;
