import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import {
  COMPANIES_ACTIONS,
  RenderConditionOptions,
} from "../../../../../shared";

const useCompaniesInlineActions = () => {
  const [rowData, setRowData] = useState<any | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setOpen(false);
  };

  const handleEditCompanyClick = (data: any) => {
    openDialog(data);
  };

  const actionTitle = COMPANIES_ACTIONS.EditCompany;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      icon: <EditIcon />,
      clickAction: (data: any) => handleEditCompanyClick(data),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data }: RenderConditionOptions) => {
        return true;
      },
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  };
};

export default useCompaniesInlineActions;
