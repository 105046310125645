import { CSSObject } from "@emotion/react";
import { COLORS } from "../../../../shared";

export const useStyles = (isItemSeparator: boolean) =>
  ({
    singleCookieItemContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    descriptionTextCSS: {
      marginTop: "8px",
      marginBottom: isItemSeparator ? "24px" : "unset",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      color: COLORS.Gray700,
    },
  } as CSSObject);
