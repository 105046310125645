/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import EnableTwoFA from "./EnableTwoFA/EnableTwoFA";
import DisableTwoFA from "./DisableTwoFA/DisableTwoFA";

import ContentBox from "../../../components/ContentBox/ContentBox";

import { useStyles } from "../../styles";

import useTwoFA from "../../../hooks/useTwoFA";

import TextLink from "../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../TextLink/types";

import TwoFactorAuthenticationIcon from "../../../../../../../assets/customIcons/security/TwoFactorAuthenticationIcon";
import { useAuth } from "../../../../../../../context/Auth/AuthProvider";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";
import { useReleaseFeatureToggle } from "../../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../../shared/featureToggles/types";

const TwoFA = () => {
  const { colors } = useContext(ThemeContext);

  const { user } = useAuth();
  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const { enabledStatValues, disabledStatValues } = useTwoFA({});

  const { t } = useTranslation();

  const [isEnableDialogOpen, setEnableDialogOpen] = useState(false);
  const [isDisableDialogOpen, setDisableDialogOpen] = useState(false);

  const isEnabled = user?.twoFactorEnabled;

  const {
    contentBoxInnerDiv,
    settingPlaceholder,
    twoFaIconCustomStyle,
  } = useStyles({ colors });

  const isRoleAllowedToToggle2FA = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.ShowToggle2FA,
    user?.role,
  );

  const valuesByStatus = isEnabled ? enabledStatValues : disabledStatValues;

  const openDialog = () =>
    isEnabled ? setDisableDialogOpen(true) : setEnableDialogOpen(true);

  const closeDialog = () => {
    setEnableDialogOpen(false);
    setDisableDialogOpen(false);
  };

  return (
    <>
      <ContentBox
        title={t("Password##two-factor authentication")}
        description={t("Password##two-factor authentication description")}
      >
        <div css={css(contentBoxInnerDiv)}>
          <div css={css(settingPlaceholder)}>
            <TwoFactorAuthenticationIcon
              css={css(twoFaIconCustomStyle)}
              data-testid="2FA-icon"
            />

            <Typography variant="body2">
              {t("Password##two-factor authentication status")}
            </Typography>

            {/* empty space */}
            <Typography variant="body2">&nbsp;</Typography>

            <Typography
              data-testid="two-factor-status-string"
              variant="body2Bold"
              sx={{ color: valuesByStatus.color }}
            >
              {valuesByStatus.statusString}
            </Typography>
          </div>

          {(isRoleAllowedToToggle2FA ||
            (!isRoleAllowedToToggle2FA && !isEnabled)) && (
            <TextLink
              size={TEXT_LINK_SIZES.Normal}
              onClick={openDialog}
              data-testid="toggle-two-factor-status-link"
            >
              {valuesByStatus.statusLink}
            </TextLink>
          )}
        </div>
      </ContentBox>

      <EnableTwoFA onCloseDialog={closeDialog} isOpen={isEnableDialogOpen} />

      <DisableTwoFA onCloseDialog={closeDialog} isOpen={isDisableDialogOpen} />
    </>
  );
};

export default TwoFA;
