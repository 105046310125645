import { useTranslation } from "react-i18next";

import InputField from "../../../../../InputField/InputField";
import Dropzone from "../../../../../Dropzone/Dropzone";
import SampleTemplate from "../../../../../Dropzone/SampleTemplate/SampleTemplate";
import { FILE_NAME } from "../../../../../Dialog/types/enums";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { INPUT_LENGTH, inputFieldMarginBottom } from "../../../../../../shared";

interface ImportResourceDialogContentProps {
  fileName: FILE_NAME;
  tooltipText: string;
}

const ImportResourceDialogContent = ({
  fileName,
  tooltipText,
}: ImportResourceDialogContentProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <InputField
        id={user?.selectedCompany.name}
        size="medium"
        data-testid={`import-resource-company-name`}
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={user?.selectedCompany.name}
        customStyle={inputFieldMarginBottom}
      />
      <Dropzone
        data-testid="import-resource-dropzone"
        supportedFormats={[".csv"]}
        maxFileNameLength={INPUT_LENGTH.Name}
        countCsv
        tooltipText={tooltipText}
      />
      <SampleTemplate fileName={fileName} />
    </>
  );
};

export default ImportResourceDialogContent;
