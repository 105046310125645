import { useTranslation } from "react-i18next";

import Badge, { BadgeData } from "../Badge/Badge";
import { TooltipSizes } from "../Tooltip/types";

import {
  ACTIVITY_STATUS,
  COLUMN_RESOURCES,
  COMPANY_CARD_STATUS,
  DOWNLOAD_ACTIVITY_STATUS,
  INVITATION_STATUS,
} from "../../shared";

type SharedStatusResourceType =
  | ACTIVITY_STATUS
  | DOWNLOAD_ACTIVITY_STATUS
  | COMPANY_CARD_STATUS
  | INVITATION_STATUS;

interface ISharedStatusBadge {
  status: SharedStatusResourceType;
  badgeText?: string;
  resourceName?: COLUMN_RESOURCES;
  tooltipSize?: TooltipSizes;
  bubbleOnly?: boolean;
  customTooltipText?: string;
  showTooltip?: boolean;
  iconLeft?: React.ReactNode;
}

const SharedStatusBadge = ({
  status,
  badgeText,
  resourceName,
  tooltipSize,
  bubbleOnly,
  customTooltipText,
  showTooltip = true,
  iconLeft,
}: ISharedStatusBadge) => {
  const { t } = useTranslation();

  const getBadgeData = (statusName: SharedStatusResourceType): BadgeData => {
    switch (statusName) {
      case ACTIVITY_STATUS.Online:
      case COMPANY_CARD_STATUS.Connected:
      case DOWNLOAD_ACTIVITY_STATUS.Successful:
        return { color: "success", isInverted: true };
      case ACTIVITY_STATUS.Offline:
      case COMPANY_CARD_STATUS.Disconnected:
        return { color: "neutral", isInverted: false };
      case ACTIVITY_STATUS.Inactive:
      case COMPANY_CARD_STATUS.Inactive:
      case DOWNLOAD_ACTIVITY_STATUS.Awaiting:
      case INVITATION_STATUS.Pending:
        return { color: "light", isInverted: false };
      case COMPANY_CARD_STATUS.Expired:
      case INVITATION_STATUS.Expired:
      case ACTIVITY_STATUS.Disabled:
      case ACTIVITY_STATUS.Deactivated:
        return { color: "warning", isInverted: true };
      case DOWNLOAD_ACTIVITY_STATUS.Failed:
        return { color: "critical", isInverted: true };
      default:
        return { color: "light", isInverted: false };
    }
  };

  const getBubbleData = (statusName: SharedStatusResourceType): BadgeData => {
    switch (statusName) {
      case COMPANY_CARD_STATUS.Connected:
        return { color: "success", isInverted: true };
      case COMPANY_CARD_STATUS.Disconnected:
        return { color: "gray", isInverted: true };
      case COMPANY_CARD_STATUS.Inactive:
        return { color: "gray", isInverted: false };
      case COMPANY_CARD_STATUS.Expired:
        return { color: "warning", isInverted: true };

      default:
        return { color: "light", isInverted: false };
    }
  };

  const getBadgeTooltipText = (
    statusName: SharedStatusResourceType
  ): string => {
    if (customTooltipText || customTooltipText === "") {
      return customTooltipText;
    }

    return (
      statusName &&
      t(`General##badge##${statusName.toLowerCase()} badge tooltip text`)
    );
  };

  const resourceFunction = bubbleOnly ? getBubbleData : getBadgeData;

  const color = resourceFunction(status).color;
  const isInverted = resourceFunction(status).isInverted;

  const name = bubbleOnly ? "" : t(`General##${status}`);

  return (
    <Badge
      color={color}
      inverted={isInverted}
      tooltipText={
        resourceName !== COLUMN_RESOURCES.LastDownload &&
        !bubbleOnly &&
        showTooltip
          ? getBadgeTooltipText(status)
          : undefined
      }
      tooltipSize={tooltipSize ?? undefined}
      bubbleOnly={bubbleOnly}
      iconLeft={iconLeft}
    >
      {badgeText ?? name}
    </Badge>
  );
};

export default SharedStatusBadge;
