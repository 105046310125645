/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, MouseEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../styles";
import { PASSWORD_TYPE } from "../types";
import Tooltip from "../../../components/Tooltip/Tooltip";

const useTogglePswVisibility = (
  pswType: string,
  setResetValues?: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation();
  const { togglePasswordVisibilitySpan } = useStyles();

  const [showPassword, setShowPassword] = useState({
    current: false,
    newPsw: false,
    repeat: false,
  });

  const togglePswVisibility = (
    e: MouseEvent<HTMLSpanElement>,
    name: PASSWORD_TYPE
  ) => {
    e.preventDefault();
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
    // reset visibility to hidden on exit function call
    setResetValues && setResetValues(false);
  };

  const displayPasswordVisibility = () => {
    if (showPassword.current || showPassword.newPsw || showPassword.repeat) {
      return <VisibilityOff />;
    } else {
      return <Visibility />;
    }
  };

  const PasswordVisibility = () => {
    return (
      <Tooltip
        size="medium"
        placement="top-end"
        title={
          showPassword[pswType as PASSWORD_TYPE]
            ? t("Auth##hide password")
            : t("Auth##show password")
        }
        sx={{
          "& .MuiTooltip-tooltip": {
            marginRight: "-6px",
          },
        }}
      >
        <span
          onMouseDown={(e) => togglePswVisibility(e, pswType as PASSWORD_TYPE)}
          className="change-psw-visibility"
          css={css(togglePasswordVisibilitySpan)}
        >
          {displayPasswordVisibility()}
        </span>
      </Tooltip>
    );
  };

  return { PasswordVisibility, showPassword };
};

export default useTogglePswVisibility;
