import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

export const USERS_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Email,
    isSticky: true,
    resource: COLUMN_RESOURCES.Email,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Company,
    isSticky: false,
    // name the property with the original name from response, no matter which level it is
    // in this case - "name" is at the second level of data response
    resource: COLUMN_RESOURCES.Name,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Role,
    isSticky: false,
    resource: COLUMN_RESOURCES.Role,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.InvitedBy,
    isSticky: false,
    resource: COLUMN_RESOURCES.InvitedBy,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.InvitedAt,
    isSticky: false,
    resource: COLUMN_RESOURCES.InvitedAt,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.UpdatedAt,
    isSticky: false,
    resource: COLUMN_RESOURCES.UpdatedAt,
    isDefaultActive: true,
  },
];
