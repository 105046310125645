import Button from "../Button";
import Tooltip from "../../Tooltip/Tooltip";
import { useMediaQueries } from "../../../shared";

interface IInlineActionIconButtonProps {
  title: string;
  icon: React.JSX.Element;
  onButtonClick: () => any;
  isLoading?: boolean;
  customId?: string;
}

const InlineActionIconButton = ({
  title,
  icon,
  onButtonClick,
  isLoading,
  customId,
}: IInlineActionIconButtonProps) => {
  const { toMd } = useMediaQueries();

  return (
    <Tooltip disableInteractive size="medium" placement="top" title={title}>
      <Button
        id={customId}
        variant={toMd ? "textOnly" : "iconOnly"}
        fullWidth={toMd}
        size="small"
        color="primarySubtle"
        icon={icon}
        onClick={onButtonClick}
        isLoading={isLoading}
      >
        {toMd && icon}
      </Button>
    </Tooltip>
  );
};

export default InlineActionIconButton;
