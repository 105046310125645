/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { noop } from "lodash";
import { useRecoilState } from "recoil";

import { useStyles } from "./styles";
import useColumnsToShowFunctions from "../../../hooks/useColumnsToShowFunctions";
import ListChoice from "../../../../ListChoice/ListChoice";
import { Column } from "../../../../../shared";
import { tableColumns } from "../../../../../atoms/atoms";

const ColumnsToShow = () => {
  const { t } = useTranslation();

  const { columnsToShowOption, columnsToShowTitleCSS } = useStyles();

  const [currTableColumns, setCurrTableColumns] = useRecoilState<Column[]>(
    tableColumns
  );

  const {
    areDefaultColumns,
    restoreActiveColumns,
    toggleActiveColumn,
  } = useColumnsToShowFunctions(currTableColumns, setCurrTableColumns);

  const [isResetDisabled, setIsResetDisabled] = useState(areDefaultColumns);

  useEffect(() => {
    setIsResetDisabled(areDefaultColumns);
  }, [currTableColumns, areDefaultColumns]);

  return (
    <Fragment>
      <Typography variant="body2" sx={columnsToShowTitleCSS as any}>
        {t("Table##select columns to show")}
      </Typography>

      {currTableColumns.map(({ name, isActive, isDisabled }: Column) => {
        return (
          <ListChoice
            key={`${name}-${isActive}`}
            title={t(`Table##${name}`)}
            selected={isActive}
            isCheckbox
            disabled={isDisabled}
            onClick={() => toggleActiveColumn(name)}
          />
        );
      })}

      <ListChoice
        title={t("Button##reset to default")}
        onClick={isResetDisabled ? noop : restoreActiveColumns}
        disabled={isResetDisabled}
        css={css(columnsToShowOption)}
      />
    </Fragment>
  );
};

export default ColumnsToShow;
