/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { TableRow as MuiTableRow } from "@mui/material";
import { useCallback, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import { noop } from "lodash";

import TableCheckbox from "./TableCheckbox";
import TableColumn from "./TableColumn";
import { TableRowStyle } from "../../../styles/styleFunctions";
import { pageGlobalStateObjectResult } from "../../../functions";
import TableInlineActions from "../../../actions/inlineActions/TableInlineActions/TableInlineActions";
import useTableItemDetails from "../../../../DetailsSection/hooks/useTableItemdetails";
import {
  tableInlineActions,
  resetDetailsSectionOnPageChange,
  tableColumns,
  tableDetails,
  isTableCheckable,
} from "../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import {
  ACTIVITY_STATUS,
  Column,
  INVITATION_STATUS,
  ROUTES,
  TableRowData,
} from "../../../../../shared";
import useTutorialMode from "../../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../../TutorialMode/types";

interface ITableRowPros {
  row: TableRowData;
  scrollHorizontal?: boolean;
  hasDetails: boolean;
  disabled: boolean;
  index?: number;
  companyLevelsList?: any;
}

const TableRow = ({
  row,
  scrollHorizontal,
  hasDetails,
  disabled,
  index,
  companyLevelsList,
}: ITableRowPros) => {
  const { colors } = useContext(ThemeContext);

  const { checkIfCorrectTutorialStep, setTutorialDetails } = useTutorialMode();
  const { getTableItemDetails } = useTableItemDetails();
  const location = useLocation();

  const columns = useRecoilValue<Column[]>(tableColumns);
  const detailsSectionData = useRecoilValue(tableDetails);
  const inlineActions = useRecoilValue(tableInlineActions);
  const isCheckable = useRecoilValue(isTableCheckable);

  const [, setIsDetailsResetNeeded] = useRecoilState(
    resetDetailsSectionOnPageChange
  );

  const detailsData = pageGlobalStateObjectResult(
    location.pathname,
    detailsSectionData
  );

  const isInitiallyChecked = false;

  const handleDetailsClick = useCallback(
    (row: any) => {
      getTableItemDetails({
        itemId: row.id,
        fromTableRowClick: true,
        rowData: row,
      });

      const isCompaniesPage = location.pathname === ROUTES.Companies;

      if (
        checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 2) &&
        isCompaniesPage
      ) {
        setTutorialDetails({
          isTutorialOpen: false,
          stepIndex: 3,
          stepGroup: STEP_GROUP.TutorialNewCompanyCard,
          isTutorialActive: true,
        });
      }

      setIsDetailsResetNeeded(false);
    },
    // eslint-disable-next-line
    [getTableItemDetails, setIsDetailsResetNeeded]
  );
  const checkIfStatusIsDisabled = () =>
    row.status === ACTIVITY_STATUS.Deactivated ||
    row?.invitationStatus === INVITATION_STATUS.Expired;

  return (
    <MuiTableRow
      data-testid="table-row"
      id={`row_${index}`}
      onClick={hasDetails ? () => handleDetailsClick(row) : noop}
      css={css([
        TableRowStyle(isInitiallyChecked, colors, checkIfStatusIsDisabled()),
        { cursor: hasDetails ? "pointer" : "default" },
      ])}
      selected={row.id === detailsData?.id}
    >
      {isCheckable && (
        <TableCheckbox
          fromTableRow
          isRowChecked={isInitiallyChecked}
          row={row}
          disabled={disabled}
          index={index}
          companyLevelsList={companyLevelsList}
        />
      )}

      {columns.map(
        ({ name, resource, isSticky, secondCellValue, isActive }: Column) => {
          return (
            isActive && (
              <TableColumn
                key={name}
                data={row}
                name={name}
                isCheckable={isCheckable}
                isSticky={isSticky}
                resource={resource}
                secondCellValue={secondCellValue}
                scrollHorizontal={scrollHorizontal}
              />
            )
          );
        }
      )}
      {inlineActions.length > 0 && (
        <TableInlineActions
          scrollHorizontal={scrollHorizontal}
          inlineActions={inlineActions}
          row={row}
        />
      )}
      {/* Inline actions dialogs appears in page component (Vehicles.tsx, Drivers.tsx, etc.) */}
    </MuiTableRow>
  );
};

export default TableRow;
