import { useEffect, useState } from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FilterOptionList from "./components/FilterOptionList";
import { useStyles } from "../styles";
import useFilterDropdown from "../hooks/useFilterDropdown";
import { FILTER_NAMES } from "../types";
import { pageGlobalStateObjectResult } from "../../Table/functions";
import Autocomplete from "../../Autocomplete/Autocomplete";
import RectangleLoader from "../../../shared/components/loaders/RectangleLoader";
import useInitialLoading from "../../../shared/hooks/useInitialLoading";
import { Filter } from "../../../shared";
import { filterQueryParams, tableLoading } from "../../../atoms/atoms";

export interface FilterDropdownProps {
  filterName: FILTER_NAMES;
  placeholder: string;
  translateString?: string;
}

const FilterDropdown = ({
  filterName,
  placeholder,
  translateString,
  ...props
}: FilterDropdownProps &
  Omit<
    MuiAutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "size" | "options"
  >) => {
  const { t } = useTranslation();

  const { isInitialLoading } = useInitialLoading();
  const {
    isOpen,
    isLoading,
    options,
    setOpen,
    getFilterOptions,
    handleFilterChangeValue,
  } = useFilterDropdown(filterName);
  const location = useLocation();

  const isTableLoading = useRecoilValue(tableLoading);
  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);

  const [value, setValue] = useState<Filter[]>([]);
  const [selectedItem, setSelectedItem] = useState<Filter | null>(null);

  const { filterDropdownCustomWidth } = useStyles({});

  const filterObjectFromLocalStorage = pageGlobalStateObjectResult(
    location.pathname,
    filterParams
  );

  useEffect(() => {
    !filterObjectFromLocalStorage && setValue([]);
  }, [filterObjectFromLocalStorage, selectedItem]);

  useEffect(() => {
    const checkFilterNameAndSetValue = (filterObject: {
      [key: string]: Filter[];
    }) => {
      const filterValue = filterObject?.[filterName];
      if (filterValue !== undefined) {
        setValue(filterValue);
      }
    };

    checkFilterNameAndSetValue(filterObjectFromLocalStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, filterParams, location.pathname, filterName]);

  const handleChange = (event: any, newValue: Filter[]) => {
    event.preventDefault();

    handleFilterChangeValue({
      isTableLoading,
      filterObjectFromLocalStorage,
      newValue,
      filterName,
      setSelectedItem,
      setFilterParams,
      setValue,
    });
  };

  const getOptionLabel = (option: Filter) => {
    const name = String(option.name);

    if (translateString) {
      return t(`${translateString}##${name}`);
    }

    return name;
  };

  const handleOpen = () => {
    setOpen(true);
    getFilterOptions();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return isInitialLoading ? (
    <RectangleLoader
      customStyle={filterDropdownCustomWidth}
      height={36}
      testId={`${filterName}-filter-loader`}
    />
  ) : (
    <Autocomplete
      {...props}
      data-testid={`${filterName}-filter-dropdown`}
      fullWidth
      multiple
      size="small"
      includeInputInList
      disableClearable
      disableCloseOnSelect
      open={isOpen}
      value={value}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      loading={isLoading}
      onChange={(e, newFilterValue) => handleChange(e, newFilterValue)}
      renderOption={(props, option, { selected }) => (
        <FilterOptionList
          key={option.id}
          optionName={getOptionLabel(option)}
          props={props}
          option={option}
          selected={selected}
          isTableLoading={isTableLoading}
          selectedItem={selectedItem}
        />
      )}
      renderTags={() => null}
      textFieldParams={{
        placeholder,
      }}
      getOptionLabel={(option: Filter) => getOptionLabel(option)}
    />
  );
};

export default FilterDropdown;
