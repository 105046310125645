/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useStyles } from "./styles";
import Badge from "../../../../../Badge/Badge";
import BannerMessage from "../../../../../BannerMessage/BannerMessage";
import { BANNER_MESSAGE_STATUSES } from "../../../../../BannerMessage/types";
import {
  MAX_SCHEDULES_COUNT,
  ResourceParams,
  ScheduleDataDto,
} from "../../../../../Schedules/types";
import ScheduleCards from "../../../../../Schedules/components/ScheduleCards/ScheduleCards";
import useTutorialMode from "../../../../../TutorialMode/hooks/useTutorialMode";
import { useFormattedNumber } from "../../../../../../shared";
import { tablePageData } from "../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";

interface SchedulesMainDialogContentProps {
  resourceParams: ResourceParams;
  resourceCount: number;
  canManageSchedules: boolean;
  deselectSchedule: () => void;
}

const SchedulesMainDialogContent = ({
  resourceParams,
  resourceCount,
  canManageSchedules,
  deselectSchedule,
}: SchedulesMainDialogContentProps) => {
  const { t } = useTranslation();
  const { renderFormattedNumber } = useFormattedNumber();
  const {
    isTutorialActive,
    stepIndex,
    stepGroup,
    callTutorialWithTimeout,
  } = useTutorialMode();
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const pageData = useRecoilValue(tablePageData);

  const [isLoading, setIsLoading] = useState(true);
  const [schedulesData, setSchedulesData] = useState<ScheduleDataDto[] | null>(
    null
  );
  const [globalScheduleExists, setGlobalScheduleExists] = useState(true);

  const { dialogContent } = useStyles();

  const initialized = useRef(false);

  const { apiResource, resourceName } = resourceParams;

  useEffect(() => {
    retrieveDataIfNotInitialized();

    // eslint-disable-next-line
  }, [resourceCount]);

  const getSchedulesData = async () => {
    try {
      setIsLoading(true);

      const { data } = await getData(apiResource);

      const isGlobalTrue = data.some(
        (item: ScheduleDataDto) => item.global === true
      );

      deselectSchedule();
      setGlobalScheduleExists(isGlobalTrue);
      setSchedulesData(data);
      setIsLoading(false);

      if (isTutorialActive) {
        callTutorialWithTimeout(true, stepIndex, stepGroup, true, 200);
      }
    } catch (error) {
      handleCommonError(error, t);
    }
  };

  const retrieveDataIfNotInitialized = async () => {
    if (!initialized.current) {
      initialized.current = true;

      getSchedulesData();
    }
  };

  const scheduleCardLimitReached =
    (schedulesData &&
      (schedulesData.length >= MAX_SCHEDULES_COUNT.GlobalConfigured ||
        (!globalScheduleExists &&
          schedulesData?.length >= MAX_SCHEDULES_COUNT.NoGlobalConfigured))) ??
    false;

  return (
    <>
      {canManageSchedules && (
        <>
          {scheduleCardLimitReached && !isLoading && (
            <BannerMessage
              data-testid="max-number-reached-schedules-banner"
              status={BANNER_MESSAGE_STATUSES.Info}
              title={t("Schedules##max number reached")}
              css={css({ marginBottom: "16px" })}
            />
          )}
          <div
            css={css(dialogContent)}
            data-testid={`selected-${resourceName}-schedules-badge`}
          >
            <Typography variant="body1">
              {t(`Dialog##selected resource`, {
                resource: t(`General##${resourceName}`).toLocaleLowerCase(),
              })}
              :
            </Typography>
            <Badge color="info">
              {`${renderFormattedNumber(resourceCount)} / ${
                pageData?.totalCount
              }`}
            </Badge>
          </div>
        </>
      )}
      <ScheduleCards
        schedulesData={schedulesData}
        isScheduleDataLoading={isLoading}
        globalScheduleExists={globalScheduleExists}
        limitReached={scheduleCardLimitReached}
        resourceParams={resourceParams}
        canManageSchedules={canManageSchedules}
        getSchedulesData={getSchedulesData}
      />
    </>
  );
};

export default SchedulesMainDialogContent;
