/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { DashboardCardNames } from "../types";
import { useStyles as dashboardStyles } from "../styles";

import { useMediaQueries } from "../../../shared";
import NoInitialResults from "../../../components/Table/components/TableEmpty/NoInitialResults";

const NoResultsCard = () => {
  const { toSm, toLg, toMd, to1099 } = useMediaQueries();
  const { emptyCardBox } = dashboardStyles({ toSm, toLg, toMd, to1099 });

  return (
    <div css={css(emptyCardBox)} data-testid="company-cards-no-results">
      <NoInitialResults dashboardCardName={DashboardCardNames.CompanyCards} />
    </div>
  );
};

export default NoResultsCard;
