import { useTranslation } from "react-i18next";
import { SetStateAction } from "react";
import { CSSObject } from "@emotion/react";

import NewsDraftActions from "./NewsDraftActions";

import { useStyles } from "../styles";

import Badge from "../../../../../components/Badge/Badge";
import { MenuAnchorEl, NEWS_ACTIONS } from "../../../../../shared";

interface DraftAdditionsProps {
  onOpenActionsMenu: (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
    stopPropagation: () => void;
  }) => void;
  onCloseActionsMenu: () => void;
  isActionsOpen: MenuAnchorEl;
  openDialog: (action: NEWS_ACTIONS) => void;
}

const DraftAdditions = ({
  onOpenActionsMenu,
  onCloseActionsMenu,
  isActionsOpen,
  openDialog,
}: DraftAdditionsProps) => {
  const { t } = useTranslation();

  const { newsBadge } = useStyles({});

  return (
    <>
      <Badge color="warning" customStyle={newsBadge as CSSObject}>
        {t("News##unpublished")}
      </Badge>
      <NewsDraftActions
        handleOpenActionsMenu={onOpenActionsMenu}
        handleCloseActionsMenu={onCloseActionsMenu}
        isActionsOpen={isActionsOpen}
        onOpenEditDialog={() => openDialog(NEWS_ACTIONS.EditNews)}
        onOpenPublishDialog={() => openDialog(NEWS_ACTIONS.Publish)}
        onOpenDeleteDialog={() => openDialog(NEWS_ACTIONS.Delete)}
      />
    </>
  );
};

export default DraftAdditions;
