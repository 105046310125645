/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";

import { useStyles } from "./styles";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const PaginationLoader = ({ ...props }) => {
  const { colors } = useContext(ThemeContext);
  const { paginationLoader } = useStyles();

  const paginationLoadersWidth = [
    { width: 36, id: 1 },
    { width: 36, id: 2 },
    { width: 20, id: 3 },
    { width: 20, id: 4 },
    { width: 20, id: 5 },
    { width: 36, id: 6 },
    { width: 36, id: 7 },
  ];

  return (
    <div
      data-testid="pagination-loader"
      {...props}
      css={css([
        {
          span: {
            background: colors.gray200,
          },
        },
        paginationLoader,
      ])}
    >
      {paginationLoadersWidth.map((loaderwidth: any) => (
        <Skeleton
          variant="rectangular"
          width={loaderwidth.width}
          key={loaderwidth.id}
        />
      ))}
    </div>
  );
};

export default PaginationLoader;
