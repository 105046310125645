import { useContext } from "react";
import { Trans } from "react-i18next";
import { Typography } from "@mui/material";

import TextLink from "../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../TextLink/types";
import { FILE_NAME } from "../../Dialog/types/enums";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

export interface SampleTemplateProps {
  fileName: FILE_NAME;
}

const SampleTemplate = ({ fileName }: SampleTemplateProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <Typography
      variant="body2"
      sx={{ color: colors.gray700, margin: "16px 0" }}
    >
      <Trans i18nKey="Dialog##download sample file message">
        Download a
        <TextLink
          data-testid="file-sample-template-link"
          size={TEXT_LINK_SIZES.Normal}
          href={`/static/${fileName}.csv`}
          download
        >
          sample CSV template
        </TextLink>
        to see an example of the format required.
      </Trans>
    </Typography>
  );
};

export default SampleTemplate;
