import {
  generateCreditsItemBorderColor,
  generateCreditsItemColor,
} from "../functions";

import { creditsObjectType } from "../../types";

import DoughnutChartListItem from "../../../components/DoughnutChartListItem/DoughnutChartListItem";

const useCreditsFunctions = () => {
  const generateCreditList = (
    creditsObjectArray: creditsObjectType[] | null,
    hoveredElement: number | null,
    hovered: boolean
  ) =>
    creditsObjectArray &&
    creditsObjectArray.map((i: creditsObjectType, index: number) => {
      return (
        <DoughnutChartListItem
          key={i.name}
          item={i}
          hoveredElement={hoveredElement}
          hovered={index === hoveredElement && hovered}
          backgroundColor={generateCreditsItemColor}
          borderColor={generateCreditsItemBorderColor}
          customStyle={{
            marginBottom: index === creditsObjectArray.length - 1 ? 0 : "12px",
          }}
        />
      );
    });

  return { generateCreditList };
};

export default useCreditsFunctions;
