import { AxiosError } from "axios";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import useTableItemDetails from "../../../../DetailsSection/hooks/useTableItemdetails";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ALERT_STATUS } from "../../../../../context/alert/types";
import { refreshTableAndUpdateDetails } from "../../../../../shared/functions/functions";
import useResponse from "../../../../../shared/hooks/useResponse";

const useEditItem = () => {
  const { putData } = useAxiosPrivate();
  const { t } = useTranslation();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();

  const editItem = async (
    setIsLoading: (value: SetStateAction<boolean>) => void,
    resource: string,
    payload: any,
    valueInSearchField: string | null,
    editableItemHasOpenedDetails: boolean | null | undefined,
    resourceString: string | undefined,
    closeDialog: () => void
  ) => {
    const successMessage = t(`Alert##successfully edited`, { resourceString });
    try {
      setIsLoading(true);
      await putData(resource, payload);
      // refresh table and set updated details if details section is opened
      refreshTableAndUpdateDetails({
        valueInSearchField,
        resourceId: payload.id,
        successMessage,
        itemHasOpenedDetails: editableItemHasOpenedDetails,
        getTableItemDetails,
        handleResponse,
      });
      closeDialog();
    } catch (error) {
      const err = error as AxiosError<any>;
      if (err) {
        const responseDataMessage = err.response?.data?.message;
        handleResponse(
          ALERT_STATUS.Critical,
          t(`Alert##${responseDataMessage}`)
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { editItem };
};

export default useEditItem;
