/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useTranslation } from "react-i18next";

import AddNewEmailRecipientDialog from "./AddNewEmailRecipientDialog";
import Tooltip from "../../../../../../../Tooltip/Tooltip";
import Button from "../../../../../../../Button/Button";
import { useDialog } from "../../../../../../../PageHeader/actions/hooks/useDialog";

import { COMPANY_CARDS_LIMITS } from "../../../../../../../../Pages/Companies/types";
import { COMPANIES_ACTIONS } from "../../../../../../../../shared";

interface AddNewEmailRecipientProps {
  companyId: string;
  emailCount: number;
}

const AddNewEmailRecipient = ({
  companyId,
  emailCount,
}: AddNewEmailRecipientProps) => {
  const { t } = useTranslation();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const isDisabled = emailCount >= COMPANY_CARDS_LIMITS.MaxEmailTransferCount;

  const handleAddEmailClick = () => {
    openDialog(COMPANIES_ACTIONS.AddNewEmailRecipient);
  };

  const handleCloseDialog = () => {
    closeDialog(COMPANIES_ACTIONS.AddNewEmailRecipient);
  };

  return (
    <>
      <Tooltip
        title={t(`Details##email limit reached`, {
          currCount: emailCount,
          maxCount: COMPANY_CARDS_LIMITS.MaxEmailTransferCount,
        })}
        placement="top-end"
        hasArrow
        size="medium"
        disableHoverListener={!isDisabled}
      >
        <span>
          <Button
            data-testid="add-new-email-button"
            color="secondary"
            onClick={handleAddEmailClick}
            size="tiny"
            variant="iconLeft"
            disabled={isDisabled}
            icon={<AddOutlinedIcon css={css({ marginRight: "-2px" })} />}
          >
            {t("Company cards##add new")}
          </Button>
        </span>
      </Tooltip>

      <AddNewEmailRecipientDialog
        companyId={companyId}
        isOpen={isDialogOpen[COMPANIES_ACTIONS.AddNewEmailRecipient]}
        handleClose={handleCloseDialog}
      />
    </>
  );
};

export default AddNewEmailRecipient;
