import { CSSObject } from "@emotion/react";
import { COLORS } from "../../../../../shared";

export const useStyles = () =>
  ({
    tableViewLoaderCSS: {
      marginLeft: "24px",
      "@media(max-width: 599px)": {
        marginLeft: "0",
        marginTop: "8px",
      },

      "@media(max-width: 839px)": {
        margin: "8px 0",
      },
    },
    tableViewButtonCSS: {
      "@media(max-width: 599px)": {
        marginTop: "2px",
        marginLeft: "0",
      },

      "@media(max-width: 839px)": {
        margin: "8px 0",
      },
    },
    columnsToShowMenuCSS: {
      "& .MuiMenu-paper": {
        maxHeight: "384px",
        minWidth: "330px",
      },
    },
    columnsToShowTitleCSS: {
      borderBottom: `1px solid ${COLORS.Gray300}`,
      padding: "12px 16px",
    },
    columnsToShowOption: {
      position: "sticky",
      bottom: "0",
      background: COLORS.White,
      zIndex: "1",
      color: COLORS.Blue700,
      borderTop: `1px solid ${COLORS.Gray300}`,
      paddingTop: "13px",

      "&:hover": {
        "&.Mui-disabled": {
          background: COLORS.White,
        },
      },

      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
  } as CSSObject);
