import { CSSObject } from "@emotion/react";

export const useStyles = (logisticJournalError: null | string) =>
  ({
    lzCodeInputWithButton: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },
    resetVerifyButton: {
      marginTop: "8px",
      marginBottom: logisticJournalError ? "24px" : "0",
      height: "44px",
    },
  } as CSSObject);
