import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    companyCardContainer: {
      height: "100%",
      overflowY: "auto",
      scrollbarGutter: "stable",
      marginRight: "-16px",
    },
    companyCardsListBlock: { paddingRight: "8px" },
    singleCard: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingBottom: "12px",
      alignItems: "center",
    },
  } as CSSObject);
