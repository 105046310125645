import { ThemeColors } from "../../shared";

export const muiAutocompleteStyleOverrides = (
  colors: ThemeColors,
  props: any,
  padding: number | string
) => {
  return {
    "&:hover, .MuiOutlinedInput-root:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray400,
      },

      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.red800,
      },

      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.gray300,
      },
    },

    "& .MuiAutocomplete-inputRoot": {
      borderRadius: "6px",
      padding,
      background:
        props.disabled || props.readOnly ? colors.gray100 : colors.white,
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiAutocomplete-input": {
        color: colors.textDark,
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        padding: "0",
      },

      "& .MuiAutocomplete-endAdornment": {
        right: "12px",

        "& > button": {
          backgroundColor: "unset",
        },

        "& .MuiAutocomplete-popupIndicator": {
          marginRight: "0",
        },
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.gray300,
    },
  };
};

export const muiAutocompleteComponentPropsStyleOverrides = (
  colors: ThemeColors,
  props: any
) => {
  return {
    border: `1px solid ${colors.gray200}`,
    borderRadius: "6px",
    boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)",

    "& .MuiAutocomplete-listbox": {
      padding: "0",

      "& .MuiAutocomplete-option": {
        color: colors.textDark,
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        padding: "14px 16px",
        wordBreak: "break-word",
      },

      "& .MuiAutocomplete-option.Mui-focused": {
        background: colors.gray100,
      },

      '& .MuiAutocomplete-option[aria-selected="true"], .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        background: props.multiple ? colors.white : colors.blue200,

        "&:hover": {
          background: props.multiple ? colors.gray100 : colors.blue200,
        },
      },
    },

    "& .MuiAutocomplete-loading, .MuiAutocomplete-noOptions": {
      color: colors.textPlaceholder,
      fontSize: "14px",
      lineHeight: "20px",
    },
  };
};

export const largeTooltipTextFieldStyleOverrides = (colors: ThemeColors) => {
  return {
    "& .MuiTouchRipple-root": {
      display: "none",
    },

    "& .MuiFormHelperText-root": {
      margin: "6px 0 0",
      display: "flex",
      alignItems: "center",
      color: colors.gray700,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.1px",

      "&.Mui-error": {
        color: colors.gray700,
      },
    },
  };
};
