/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useStyles } from "../styles";
import { ThemeColors } from "../../../../../../../shared";

interface IParentWithMultipleChildsLineProps {
  colors: ThemeColors;
}

const ParentWithMultipleChildsLine = ({
  colors,
}: IParentWithMultipleChildsLineProps) => {
  const {
    boxWithRightBorder,
    boxWithoutBorders,
    boxWithLeftAndBottomBorders,
  } = useStyles(colors);

  return (
    <>
      <div css={{ display: "flex", width: "100%" }}>
        <div css={css(boxWithoutBorders)}></div>
        <div css={css(boxWithLeftAndBottomBorders)}></div>
      </div>
      <div css={{ display: "flex", width: "100%" }}>
        <div css={css(boxWithRightBorder)}></div>
        <div css={css(boxWithoutBorders)}></div>
      </div>
    </>
  );
};
export default ParentWithMultipleChildsLine;
