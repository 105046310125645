import { TFunction } from "i18next";

import { USER_ROLES_RAW_DATA } from "./types/enums";

export const restrictedRole = USER_ROLES_RAW_DATA.TeltonikaSupport;

export const userRolesDescirpions: any = (
  t: TFunction<"translation", undefined>
) => ({
  User: t("General##user role description"),
  Administrator: t("General##administrator role description"),
  TeltonikaSupport: t("General##teltonika support role description"),
});
