import { useRecoilValue } from "recoil";

import {
  initialLoading,
  resetDetailsSectionOnPageChange,
} from "../../atoms/atoms";

const useInitialLoading = () => {
  const isLoading = useRecoilValue(initialLoading);
  const resetDetailsonPageChange = useRecoilValue(
    resetDetailsSectionOnPageChange
  );

  const isInitialLoading = isLoading || resetDetailsonPageChange;

  return { isInitialLoading };
};

export default useInitialLoading;
