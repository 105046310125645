import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ErrorCheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="11"
                    fill="#0054A6"
                    stroke="#FEF2F2"
                    strokeWidth="2"
                />
                <circle cx="12" cy="12" r="4" fill="white" />
                <rect
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    stroke="#B91C1C"
                />
            </g>
        </SvgIcon>
    );
};

export default ErrorCheckedIcon;
