import DownloadStatusDialog from "../DownloadStatusDialog/DownloadStatusDialog";

import { DetailsDataType } from "../../../../types";

import { useDialog } from "../../../../../PageHeader/actions/hooks/useDialog";

import {
  getSelectedTableItemFromLocalStorage,
  ROUTES,
  useMediaQueries,
  VEHICLES_ACTIONS,
} from "../../../../../../shared";
import MoreMenuChoice from "../../../../../../shared/components/sharedListChoices/MoreMenuChoice/MoreMenuChoice";

interface IDownloadStatusButtonProps {
  onActionsClose: () => void;
  detailsData: DetailsDataType;
}

const DownloadStatusButton = ({
  onActionsClose,
  detailsData,
}: IDownloadStatusButtonProps) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { fromMd } = useMediaQueries();

  const handleDownloadStatusClick = () => {
    openDialog(VEHICLES_ACTIONS.DownloadStatus);
  };

  const handleCloseDownloadStatusDialog = () => {
    closeDialog(VEHICLES_ACTIONS.DownloadStatus);
    onActionsClose();
  };

  const checkIfCorrectVehicle = fromMd
    ? detailsData?.id === getSelectedTableItemFromLocalStorage(ROUTES.Vehicles)
    : Boolean(detailsData?.id);

  return (
    <>
      <MoreMenuChoice
        action={VEHICLES_ACTIONS.DownloadStatus}
        onButtonClick={handleDownloadStatusClick}
      />

      {checkIfCorrectVehicle && (
        <DownloadStatusDialog
          open={isDialogOpen[VEHICLES_ACTIONS.DownloadStatus]}
          onClose={handleCloseDownloadStatusDialog}
          detailsData={detailsData}
        />
      )}
    </>
  );
};

export default DownloadStatusButton;
