/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReactNode } from "react";
import { useStyles } from "../../../../styles";
import DetailsCardItemText from "../DetailsCardItemText";
import DetailsCardItemTitle from "../DetailsCardItemTitle";

interface IDetailsCardSingleItemProps {
  children?: ReactNode;
  title: string;
  alignUnset?: boolean;
  textToRender?: string;
}

const DetailsCardSingleItem = ({
  children,
  title,
  alignUnset,
  textToRender,
}: IDetailsCardSingleItemProps) => {
  const { paperCardItem, paperCardItemAlignUnset } = useStyles();

  return (
    <div
      css={css(
        alignUnset ? [paperCardItem, paperCardItemAlignUnset] : paperCardItem
      )}
    >
      <DetailsCardItemTitle title={title} />
      {textToRender ? (
        <DetailsCardItemText textToRender={textToRender} />
      ) : (
        children
      )}
    </div>
  );
};

export default DetailsCardSingleItem;
