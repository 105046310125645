import PageHeaderActions from "../PageHeaderActions";
import { useDialog } from "../../hooks/useDialog";
import InviteUserDialog from "../../dialogs/users/InviteUserDialog/InviteUserDialog";
import { ROUTES, TABLE_NAMES, USERS_ACTIONS } from "../../../../../shared";
import { useAuth } from "../../../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../../../roles/types/enums";

interface UsersHeaderActionsProps {
  currentRoute: ROUTES;
}

const UsersHeaderActions = ({ currentRoute }: UsersHeaderActionsProps) => {
  const { getAccessStatus } = useAuth();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const tableName = TABLE_NAMES.Users;

  const allowedToManageInvites = getAccessStatus(USER_PERMISSIONS.ManageUsers);

  return (
    <>
      <PageHeaderActions
        route={currentRoute}
        openDialog={openDialog}
        inviteUser={allowedToManageInvites}
      >
        <InviteUserDialog
          isOpen={isDialogOpen[USERS_ACTIONS.InviteUser]}
          onClose={closeDialog}
          tableName={tableName}
        />
      </PageHeaderActions>
    </>
  );
};

export default UsersHeaderActions;
