import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { mapCompaniesTableRowData } from "./functions";
import {
  COMPANIES_DESKTOP_TABLE_COLUMNS,
  COMPANIES_MOBILE_TABLE_COLUMNS,
} from "./config";
import PageWrapperWithDetails from "../wrappers/PageWrapperWithDetails";
import { currPage, isTableCheckable } from "../../atoms/atoms";
import LayoutWrapper from "../../components/Layout/LayoutWrapper";
import CompaniesHeaderActions from "../../components/PageHeader/actions/pageHeaderActions/CompanieHeaderActions/CompaniesHeaderActions";
import useCompaniesInlineActions from "../../components/Table/actions/inlineActions/hooks/useCompaniesInlineActions";
import { getPageGlobalStateObjectValue } from "../../components/Table/functions";
import useTableData from "../../components/Table/hooks/useTableData";
import Table from "../../components/Table/Table";
import {
  getPageNumberFromLocalStorage,
  ROUTES,
  TABLE_NAMES,
  useMediaQueries,
} from "../../shared";
import EditCompanyDialog from "../../components/Table/actions/dialogs/Companies/EditCompanyDialog/EditCompanyDialog";

const Companies = () => {
  useTableData(mapCompaniesTableRowData);
  const {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  } = useCompaniesInlineActions();
  const { toMd } = useMediaQueries();

  const [, setPageToShow] = useRecoilState(currPage);
  const [isCheckable, setIsCheckable] = useRecoilState(isTableCheckable);

  useEffect(() => {
    !isCheckable && setIsCheckable(true);
    // Since tableToolbar is not displayed, pageNumber is taken from local storage on every page render
    const pageToShowOnFirstRender = getPageNumberFromLocalStorage(
      ROUTES.Companies
    );
    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(
        ROUTES.Companies,
        prev,
        pageToShowOnFirstRender
      )
    );
    // eslint-disable-next-line
  }, [setPageToShow]);

  return (
    <>
      <PageWrapperWithDetails
        pathname={ROUTES.Companies}
        currTableColumns={
          toMd
            ? COMPANIES_MOBILE_TABLE_COLUMNS
            : COMPANIES_DESKTOP_TABLE_COLUMNS
        }
        currTableName={TABLE_NAMES.Companies}
        inlineActions={inlineActions}
      >
        <LayoutWrapper showDetailsSection>
          <CompaniesHeaderActions />
        </LayoutWrapper>
        <Table hasDetails hasFooter={false} hasSearch={false} hideFilters />
      </PageWrapperWithDetails>
      {/* inline action dialog */}
      {rowData && (
        <EditCompanyDialog
          isOpen={isOpen}
          onClose={closeDialog}
          rowData={rowData}
        />
      )}
    </>
  );
};

export default Companies;
