/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CircularProgress } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import React, { ReactNode, useEffect } from "react";

import { useStyles } from "./styles/styles";
import TableFooter from "./components/TableFooter/TableFooter";
import TableToolbar from "./components/TableToolbar/TableToolbar";
import DesktopTable from "./components/DesktopTable/DesktopTable";
import MobileTable from "./components/MobileTable/MobileTable";
import TableEmpty from "./components/TableEmpty/TableEmpty";
import { getSelectableRows } from "./functions";
import TableLoader from "../Loaders/TableLoader";

import {
  COMPANIES_SX_MOBILE_TABLE_HEIGHT,
  DESKTOP_TABLE_HEIGHT,
  MOBILE_TABLE_HEIGHT,
  MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER,
  TABLE_NAMES,
  useMediaQueries,
} from "../../shared";
import {
  filtersState,
  resetDetailsSectionOnPageChange,
  rowsEnabledToSelect,
  selectedTableRows,
  tableData,
  tableLoading,
  tableName,
} from "../../atoms/atoms";
import { useAuth } from "../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../shared/featureToggles/hooks";
import { getEnvironment } from "../../shared/functions/functions";

interface ITableProps {
  hasDetails: boolean;
  tabFilters?: ReactNode;
  hasTabFilters?: boolean;
  hasTableView?: boolean;
  hasFooter?: boolean;
  hasSearch?: boolean;
  hideFilters?: boolean;
}

const Table = ({
  hasDetails,
  tabFilters,
  hasTabFilters,
  hasTableView,
  hasFooter = true,
  hasSearch = true,
  hideFilters,
}: ITableProps) => {
  const { user } = useAuth();
  const { toMd, toLg, toSm } = useMediaQueries();
  const { isProductionEnv } = getEnvironment();
  const { isReleaseFeatureEnabledForUser } = useReleaseFeatureToggle();

  const tableRowData = useRecoilValue(tableData);
  const isLoading = useRecoilValue(tableLoading);
  const isDetailsResetNeeded = useRecoilValue(resetDetailsSectionOnPageChange);
  const currentTableName = useRecoilValue(tableName);
  const [, setSelectedRows] = useRecoilState<any>(selectedTableRows);
  const [, setSelectableRows] = useRecoilState(rowsEnabledToSelect);
  const [, setHideFilters] = useRecoilState(filtersState);

  const { tableCircularProgressDiv } = useStyles();

  useEffect(() => {
    setHideFilters(hideFilters || false);
    setSelectableRows(
      getSelectableRows(
        currentTableName as TABLE_NAMES,
        tableRowData,
        user,
        isProductionEnv,
        isReleaseFeatureEnabledForUser
      )
    );
    setSelectedRows([]);
    // eslint-disable-next-line
  }, [
    currentTableName,
    hideFilters,
    setHideFilters,
    setSelectableRows,
    setSelectedRows,
    tableRowData,
    user,
  ]);

  const isTableEmpty = tableRowData?.length === 0;
  const companiesPage = currentTableName === TABLE_NAMES.Companies;
  const companiesMediumScreen = companiesPage && toMd;
  const companiesMobileTableHeight =
    companiesMediumScreen && MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER;

  const renderTable = () => {
    if (isDetailsResetNeeded && isLoading) {
      return <TableLoader hasDetails={hasDetails} />;
    }
    if (isTableEmpty) {
      // isDetailsResetNeeded check is needed to display loader instead of double empty table render on page change (Dashboard page)
      return isLoading || isDetailsResetNeeded ? (
        <TableLoader hasDetails={hasDetails} />
      ) : (
        <TableEmpty hasSearch={hasSearch} />
      );
    }

    return (
      <div>
        {isLoading && (
          <div css={css(tableCircularProgressDiv)}>
            <CircularProgress data-testid="table-circular-progress" />
          </div>
        )}
        {toMd ? (
          <MobileTable
            hasDetails={hasDetails}
            toMd={toMd}
            toLg={toLg}
            hasTableView={hasTableView}
            hasTabFilters={hasTabFilters}
            hasSearch={hasSearch}
          />
        ) : (
          <DesktopTable
            hasTabFilters={hasTabFilters}
            hasDetails={hasDetails}
            hasSearch={hasSearch}
          />
        )}
      </div>
    );
  };

  const calculateMobileTableHeight = () => {
    const tableHeight = companiesPage
      ? COMPANIES_SX_MOBILE_TABLE_HEIGHT
      : MOBILE_TABLE_HEIGHT_WITHOUT_FOOTER;

    return hasSearch ? MOBILE_TABLE_HEIGHT : tableHeight;
  };

  return (
    <div
      data-testid="table"
      style={{
        position: "relative",
        height: toSm
          ? calculateMobileTableHeight()
          : companiesMobileTableHeight || DESKTOP_TABLE_HEIGHT,
        width: "100%",
      }}
    >
      {tabFilters}
      <TableToolbar
        toMd={toMd}
        hasSearch={hasSearch}
        hasTableView={hasTableView}
      />
      {renderTable()}

      {hasFooter && <TableFooter />}
    </div>
  );
};

export default React.memo(Table);
