import { Location } from "react-router";

import { pageGlobalStateObjectResult } from "../functions";
import { Filter, TableRowData } from "../../../shared";
import { FilterParamsType } from "../../Filters/types/types";
import { FILTER_KEYS, FILTER_NAMES } from "../../Filters/types/enums";

const useTableDataFunctions = () => {
  const routeWithoutSearch = (companiesRoute: boolean, location: Location) =>
    companiesRoute && location.pathname;

  const setTableRowData = (
    response: any,
    companiesRoute: boolean,
    tableRowDataMap: any,
    setTableData: (
      valOrUpdater:
        | TableRowData[]
        | ((currVal: TableRowData[]) => TableRowData[])
    ) => void
  ) => {
    // make a copy of companies page repsonse object to avoid error: `cannot add property 'X', object is not extensible`
    const companiesPageApiResponse = companiesRoute && { ...response?.data };
    let itemsFromResponse = companiesPageApiResponse || response?.data.items;

    tableRowDataMap && setTableData(tableRowDataMap(itemsFromResponse));

    !tableRowDataMap && setTableData(itemsFromResponse);
  };

  const setPageDataState = (
    response: any,
    companiesRoute: boolean,
    setPageData: (valOrUpdater: any) => void,
    tableRowDataMap: any
  ) => {
    if (companiesRoute && tableRowDataMap) {
      setPageData(tableRowDataMap({ ...response?.data }));
    }

    !companiesRoute && setPageData(response?.data);
  };

  const generateFilterQueryParamsKeyName = (
    key: FILTER_NAMES | string,
    value: any
  ) => {
    // set query key to NoSchedulesAssigned if schedules option is "No schedules"
    // BE implementation requires different key for this case, since it's id is of type boolean instead of Guid
    if (key === FILTER_NAMES.Schedules && value.id === "true") {
      return FILTER_KEYS.NoSchedulesAssigned;
    } else {
      switch (key) {
        case FILTER_NAMES.Schedules:
          return FILTER_KEYS.ScheduleIds;

        case FILTER_NAMES.VehicleNumber:
          return FILTER_KEYS.VehicleIds;

        case FILTER_NAMES.DriverName:
          return FILTER_KEYS.DriverIds;

        default:
          return key;
      }
    }
  };

  const generateIdValue = (value: Filter) =>
    value.id === "null" ? "" : value.id;

  const generateQueryParamsKeyValue = (
    key: FILTER_NAMES | string,
    value: Filter
  ) => {
    const filtersWithIdValue =
      key === FILTER_NAMES.Schedules ||
      key === FILTER_NAMES.LastDownloadAttempt ||
      key === FILTER_NAMES.DriverName ||
      key === FILTER_NAMES.VehicleNumber;

    return filtersWithIdValue ? generateIdValue(value) : value.name;
  };

  const getFiltersAndFormFilterQueryParams = (
    location: Location,
    filterParams: FilterParamsType
  ) => {
    const currentFilters = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );

    let queryString = "";
    if (currentFilters) {
      for (const [key, val] of Object.entries(currentFilters)) {
        let filterQueryParamsString = "";

        const filterValue: Filter[] = val as Filter[];

        filterValue.forEach((v: Filter) => {
          const keyAndValueString = `&${generateFilterQueryParamsKeyName(
            key,
            v
          )}=${generateQueryParamsKeyValue(key, v)}`;

          filterQueryParamsString = filterQueryParamsString + keyAndValueString;
        });

        queryString = queryString + filterQueryParamsString;
      }
    }
    return queryString;
  };

  const generateRefreshTableDataQueryParams = ({
    location,
    pageToShow,
    rowsCount,
    searchParams,
    MIN_CHAR_LENGTH_IN_SEARCH,
    filterParams,
    sortedColumn,
  }: any) => {
    const pageNumberFromLocalStorage = pageGlobalStateObjectResult(
      location.pathname,
      pageToShow
    );

    const sortingInfo = pageGlobalStateObjectResult(
      location.pathname,
      sortedColumn
    );

    const searchQueryString =
      searchParams && searchParams.length >= MIN_CHAR_LENGTH_IN_SEARCH
        ? `&Contains=${searchParams}`
        : "";

    const sortingString = !sortingInfo?.orderBy
      ? ""
      : `&OrderBy=${sortingInfo?.orderBy}&Descending=${sortingInfo?.descending}`;

    const queryString = `${
      location.pathname
    }?PageNumber=${pageNumberFromLocalStorage}&PageSize=${rowsCount?.toString()}${searchQueryString}${sortingString}`;

    const apiQueryParams =
      queryString + getFiltersAndFormFilterQueryParams(location, filterParams);

    return apiQueryParams;
  };

  return {
    setTableRowData,
    routeWithoutSearch,
    setPageDataState,
    getFiltersAndFormFilterQueryParams,
    generateRefreshTableDataQueryParams,
  };
};

export default useTableDataFunctions;
