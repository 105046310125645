/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../../../styles";

import TextLink from "../../../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../../../TextLink/types";

import { ThemeContext } from "../../../../../../../../../context/theme/ThemeContextProvider";
import { OtpInputField } from "../../../../../../../../../shared/auth/OtpInputField";
import { useAuth } from "../../../../../../../../../context/Auth/AuthProvider";

export interface SendOtpCodeProps {
  secretKey: string;
  error: string | null;
  isLoading: boolean;
  changeCode: (newValue: string) => void;
}

export const SendOtpCode = ({
  secretKey,
  error,
  isLoading,
  changeCode,
}: SendOtpCodeProps) => {
  const {
    colors: { textDark },
  } = useContext(ThemeContext);

  const { user } = useAuth();
  const { t } = useTranslation();

  const [isQr, setIsQr] = useState(true);

  const { authenticatorQrBlock } = useStyles({});

  const toggleIsQr = () => setIsQr(!isQr);

  return (
    <div
      css={css({
        color: textDark,
      })}
    >
      <Typography variant="body2" sx={{ marginBottom: "16px" }}>
        {isQr ? t("Password##scan QR code") : t("Password##type text code")}:
      </Typography>

      <div css={css(authenticatorQrBlock)}>
        {isQr ? (
          <QRCodeSVG
            size={144}
            fgColor={textDark}
            value={`otpauth://totp/${process.env.REACT_APP_2FA_NAME}:${user?.email}?secret=${secretKey}&issuer=${process.env.REACT_APP_2FA_NAME}`}
          />
        ) : (
          <Typography variant="body1Bold">{secretKey}</Typography>
        )}

        <TextLink
          size={TEXT_LINK_SIZES.Normal}
          onClick={toggleIsQr}
          css={css({
            margin: "16px 0 24px 0",
          })}
        >
          {isQr
            ? t("Password##click to type code")
            : t("Password##click to scan code")}
          .
        </TextLink>
      </div>

      <OtpInputField
        isLoading={isLoading}
        error={error}
        onChange={changeCode}
        isRequired
      />
    </div>
  );
};
