import { SCREEN_NAME } from "../types";
import ApiCredentialsCreated from "./ApiCredentialsCreated";
import ApiCredentialsMain from "./ApiCredentialsMain";
import NoApiCredentials from "./NoApiCredentials";

interface ApiManagementContentProps {
  apiCredentialData: any;
  renderName: SCREEN_NAME;
  isLoading: boolean;
  handleOpenDelete: () => void;
}

const ApiManagementContent = ({
  apiCredentialData,
  renderName,
  isLoading,
  handleOpenDelete,
}: ApiManagementContentProps) => {
  const currentContent = (() => {
    switch (renderName) {
      case SCREEN_NAME.Empty:
        return <NoApiCredentials />;

      case SCREEN_NAME.Main:
        return (
          <ApiCredentialsMain
            apiCredentialData={apiCredentialData}
            isLoading={isLoading}
            handleOpenDelete={handleOpenDelete}
          />
        );

      case SCREEN_NAME.CredentialsCreated:
        return <ApiCredentialsCreated apiCredentialData={apiCredentialData} />;

      default:
        return <></>;
    }
  })();

  return currentContent;
};

export default ApiManagementContent;
