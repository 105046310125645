import { useTranslation } from "react-i18next";
import { useState } from "react";

import Dialog from "../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../Dialog/types/enums";
import { useMediaQueries } from "../../../../../../../shared";
import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../../shared/hooks/useResponse";
import useRefreshTableData from "../../../../../../../shared/hooks/useRefreshTableData";
import DialogActions from "../../../../../../../shared/components/buttons/DialogActions";
import { ALERT_STATUS } from "../../../../../../../context/alert/types";
import { ApiResources } from "../../../../../../../api/resources";
import { USERS_INVITATIONS_DIALOGS } from "../../../../../../../Pages/Users/UsersInvitations/types";

interface InvitationActionDialogProps {
  isOpen: boolean;
  userEmail: string;
  dialogAction: USERS_INVITATIONS_DIALOGS;
  apiResource: ApiResources;
  testId: string;
  onClose: (action: USERS_INVITATIONS_DIALOGS) => void;
}

const InvitationActionDialog = ({
  isOpen,
  userEmail,
  dialogAction,
  apiResource,
  testId,
  onClose,
}: InvitationActionDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { postData } = useAxiosPrivate();
  const { handleCommonError, handleResponse } = useResponse();
  const { refreshTableData } = useRefreshTableData();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseInvitationActionDialog = () => {
    onClose(dialogAction);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (userEmail) {
      try {
        await postData(apiResource, {
          email: userEmail,
        });

        handleResponse(
          ALERT_STATUS.Success,
          t(`Alert##${dialogAction} success`)
        );

        handleCloseInvitationActionDialog();
        refreshTableData();
      } catch (error) {
        handleCommonError(error, t);
      }
    }
    setIsLoading(false);
  };

  const dialogTitle = t(`Dialog##${dialogAction}`);

  return (
    <Dialog
      data-testid={`${testId}-dialog`}
      title={`${dialogTitle}?`}
      description={t(`Dialog##${dialogAction} description`, {
        userEmail: `"${userEmail}"`,
      })}
      extendToMaxWidth={toMd}
      isTitleSeparator
      open={isOpen}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={handleCloseInvitationActionDialog}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={handleCloseInvitationActionDialog}
          isreadyToConfirm
          isLoading={isLoading}
          confirmationButtonText={t("Button##yes")}
          confirmationButtonTestId={`${testId}-confirm-button`}
          cancelButtonText={t("Button##close")}
          cancelButtonTestId={`${testId}-close-button`}
        />
      }
    />
  );
};

export default InvitationActionDialog;
