import { useState } from "react";
import { useTranslation } from "react-i18next";

const useInputValidation = (
  input: any,
  requiredLength: number,
  errorText: string,
  isNumericValue: boolean,
  fieldRequired: boolean,
  emptyErrorText: string
) => {
  const { t } = useTranslation();

  const [inputError, setInputError] = useState<string | null>(null);

  const inputIsValid = (): boolean => {
    return /^[a-zA-Z0-9]{16}$/.test(input) && input.length === requiredLength;
  };

  const validateInput = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue && !inputIsValid()) {
      setInputError(
        t(errorText, {
          maxNumber: requiredLength,
        })
      );
    }
    if (fieldRequired && inputValue.length === 0) {
      setInputError(t(emptyErrorText));
    }
  };

  const clearInputError = () => setInputError(null);

  return {
    inputIsValid,
    inputError,
    setInputError,
    clearInputError,
    validateInput,
  };
};

export default useInputValidation;
