import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = (
  bgColor?: COLORS,
  borderColor?: COLORS,
  isDisabled?: boolean,
  bgColorHover?: COLORS,
  borderColorHover?: COLORS
) =>
  ({
    frameContentCSS: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "128px",
      background: bgColor,
      border: `2px dashed ${borderColor}`,
      borderRadius: "8px",
      padding: "22px 30px",
      textAlign: "center",
      boxSizing: "border-box",
      cursor: isDisabled ? "auto" : "pointer",
      transition: "all 0.3s ease-in-out",

      "&:hover": {
        background: bgColorHover,
        border: `2px dashed ${borderColorHover}`,
      },

      ".on-drag-enter-enter, .on-drag-leave-enter": {
        opacity: "0",
      },

      ".on-drag-enter-enter-active, .on-drag-leave-enter-active": {
        opacity: "1",
        transition: "opacity 300ms ease-in-out",
      },

      ".on-drag-enter-exit, .on-drag-leave-exit": {
        opacity: "1",
      },

      ".on-drag-enter-exit-active, .on-drag-leave-exit-active": {
        opacity: "0",
      },
    },
  } as CSSObject);
