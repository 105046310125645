import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { NAVIGATION_ITEMS, ROUTES } from "../shared";
import { isItemVisible } from "../shared/layout";
import { useAuth } from "../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../roles/types/enums";

interface RestrictedRouteProps {
  children: ReactNode;
  itemName: NAVIGATION_ITEMS;
}

const RestrictedRoute = ({ children, itemName }: RestrictedRouteProps) => {
  const { getAccessStatus } = useAuth();

  const hiddenRoutesForUsers: { [key: string]: USER_PERMISSIONS } = {
    [NAVIGATION_ITEMS.Users]: USER_PERMISSIONS.ManageUsers,
    [NAVIGATION_ITEMS.Companies]: USER_PERMISSIONS.ManageCompanies,
  };

  const checkIfUserCanAccessRoute = () => {
    if (itemName in hiddenRoutesForUsers) {
      return getAccessStatus(hiddenRoutesForUsers[itemName]);
    }
    return true;
  };

  const isAllowed = () => {
    if (isItemVisible(itemName) && checkIfUserCanAccessRoute()) {
      return children;
    }
    return isItemVisible(NAVIGATION_ITEMS.Dashboard) ? (
      <Navigate to={ROUTES.Dashboard} replace />
    ) : (
      <Navigate to={ROUTES.Vehicles} replace />
    );
  };

  return <>{isAllowed()}</>;
};

export default RestrictedRoute;
