import { AxiosResponse } from "axios";
import { TFunction } from "i18next";

import { REPEAT_NAME_VALUES } from "../Schedules/types";
import { replaceCamelCaseStringToSeparateWords } from "../../shared/functions/functions";
import { restrictedRole, userRolesDescirpions } from "../../roles/constants";
import { capitalizeStringFirstLetter } from "./../../shared/functions/functions";
import { ApiResources } from "./../../api/resources";

export const setOptionsArrayfromQueryResponse = (
  response: AxiosResponse<any, any>,
  resource: string,
  getRepeatString: (repeat: REPEAT_NAME_VALUES, customRepeat: number) => string,
  t: TFunction<"translation", undefined>,
  // Change to false when decided to show all options:
  isAllowedToInviteAllRoles: boolean
) => {
  const createQueryOptionsFromStringArray = (
    optionArray: any[],
    descriptions?: any
  ) => {
    return optionArray.map((option: string) => {
      const readableOptionString = replaceCamelCaseStringToSeparateWords(
        option
      ).toLocaleLowerCase();
      // convert user roles string array into object array,
      // so that it would meet the QueryDropdown option render requirements
      const readableName = capitalizeStringFirstLetter(readableOptionString);

      const optionDescription = descriptions && descriptions(t)[option];

      // id and name matches intentionally
      return {
        id: readableName,
        name: t ? t(`General##${readableName}`) : readableName,
        description: optionDescription,
      };
    });
  };

  switch (resource) {
    case ApiResources.CompanyList:
      return response.data.companies;

    case ApiResources.UsersRoles: {
      if (isAllowedToInviteAllRoles) {
        return createQueryOptionsFromStringArray(
          response.data,
          userRolesDescirpions
        );
      }

      // DISPLAY ONLY ONE ALLOWED ROLE IF oneAllowedRole IS TRUE
      const allowedRolesFromResponse = response.data.filter(
        (value: string) => value !== restrictedRole
      );

      return createQueryOptionsFromStringArray(
        allowedRolesFromResponse,
        userRolesDescirpions
      );
    }

    case ApiResources.Repeat:
      return response.data.map(({ days, repeat }: any) => ({
        id: `${repeat}_schedule_period`,
        name: t ? t(`Schedules##repeat period##${repeat}`) : repeat,
        value: repeat,
        days,
      }));

    case ApiResources.VehicleSchedules:
    case ApiResources.DriverSchedules:
      return response.data.map(({ name, repeat, customRepeat, id }: any) => ({
        id,
        name,
        value: id,
        description: t(getRepeatString(repeat, customRepeat)),
      }));

    case ApiResources.UsersSettingsFileFormats:
      return response.data.map((fileFormat: any) => ({
        id: `${fileFormat}_settings_dropdown`,
        name: t(`Settings##file formats##${fileFormat}`),
        value: fileFormat,
      }));

    default:
      return [];
  }
};
