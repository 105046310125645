import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import EditVehicleDialogContent from "./EditVehicleDialogContent";
import { EditVehiclePayloadType } from "../../../../types";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import DialogTitleWithTooltip from "../../../../../Dialog/components/DialogTitleWithTooltip/DialogTitleWithTooltip";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import {
  searchQueryParams,
  tableDetails,
  tableDetailsResource,
} from "../../../../../../atoms/atoms";
import { ALERT_STATUS } from "../../../../../../context/alert/types";

import {
  GeneralDialogProps,
  useMediaQueries,
  VEHICLES_ACTIONS,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { VehicleDataDto } from "../../../../../../Pages/Vehicles/interfaces";
import {
  capitalizeResourceFirstLetter,
  refreshTableAndUpdateDetails,
} from "../../../../../../shared/functions/functions";

interface IEditVehicleDialogProps {
  rowData: VehicleDataDto | null;
}

const EditVehicleDialog = ({
  isOpen,
  onClose,
  rowData,
}: IEditVehicleDialogProps & GeneralDialogProps) => {
  const { toMd } = useMediaQueries();
  const { putData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();

  const { t } = useTranslation();

  const resource = useRecoilValue(tableDetailsResource);
  const currentTableDetails = useRecoilValue(tableDetails);
  const searchParams = useRecoilValue(searchQueryParams);

  const [payload, setPayload] = useState<EditVehiclePayloadType>({
    id: null,
    imei: null,
    name: null,
    comment: null,
    disabled: null,
  });
  const [isReadyToConfirm, setIsReadyToConfirm] = useState<boolean | "" | null>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isReadyToCallApi = isReadyToConfirm && resource;

  const editableItemHasOpenedDetails =
    currentTableDetails?.vehiclesPage &&
    currentTableDetails?.vehiclesPage.id === payload.id;

  const resourceString =
    resource && capitalizeResourceFirstLetter(resource).slice(0, -1);

  const valueInSearchField = searchParams.vehiclesPage;
  const successMessage = t(`Alert##successfully edited`, { resourceString });

  const handleCloseEditVehiclesDialog = () => {
    onClose(VEHICLES_ACTIONS.EditVehicle);
  };

  const handleSubmit = async () => {
    if (isReadyToCallApi) {
      const editVehicle = async () => {
        try {
          setIsLoading(true);
          await putData(resource, payload);

          // refresh table and set updated details if details section is opened
          refreshTableAndUpdateDetails({
            valueInSearchField,
            resourceId: payload.id,
            successMessage,
            itemHasOpenedDetails: editableItemHasOpenedDetails,
            getTableItemDetails,
            handleResponse,
          });

          handleCloseEditVehiclesDialog();
        } catch (error) {
          const err = error as AxiosError<any>;
          if (err) {
            const responseData: string = err.response?.data
              ? err.response?.data.message
              : t("Alert##action failed");

            handleResponse(ALERT_STATUS.Critical, t(`Alert##${responseData}`));
          }
        } finally {
          setIsLoading(false);
        }
      };
      await editVehicle();
    }
  };

  return (
    <Dialog
      data-testid="edit-vehicle-dialog"
      title={
        rowData &&
        ((
          <DialogTitleWithTooltip
            title={t("Dialog##edit vehicle", {
              name: rowData.number,
            })}
            tooltipText={rowData.number}
          />
        ) as any)
      }
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseEditVehiclesDialog}
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={handleCloseEditVehiclesDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-vehicle-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-edit-vehicle"
        />
      }
    >
      {rowData && (
        <EditVehicleDialogContent
          rowData={rowData}
          setPayload={setPayload}
          setIsReadyToConfirm={setIsReadyToConfirm}
        />
      )}
    </Dialog>
  );
};

export default EditVehicleDialog;
