import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilState } from "recoil";

import EditNewsDialogContent from "./EditNewsDialogContent";

import { NewsDto, NewsPayloadDto } from "../..";

import Dialog from "../../../../../../components/Dialog/Dialog";
import { DialogHeight } from "../../../../../../components/Dialog/types/enums";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import useRefreshDashboardData from "../../../../../../shared/hooks/useRefreshDashboardData";
import { ApiResources } from "../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import { newsScrollOffset } from "../../../../../../atoms/atoms";

interface EditNewsDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
  newsData: NewsDto | null;
  isLoading: boolean;
}

const EditNewsDialog = ({
  isDialogOpen,
  onClose,
  newsData,
  isLoading,
}: EditNewsDialogProps) => {
  const { putData } = useAxiosPrivate();
  const { handleResponse, handleCommonError } = useResponse();
  const { refreshNewsData } = useRefreshDashboardData();
  const { t } = useTranslation();

  const [scrollOffsetNumber, setScrollOffsetNumber] = useRecoilState(
    newsScrollOffset
  );

  const [isPayloadReady, setIsPayloadReady] = useState<boolean>(false);
  const [payload, setPayload] = useState<NewsPayloadDto>({
    id: null,
    title: null,
    content: null,
    imageName: null,
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitLoading(true);
      await putData(ApiResources.NewsItems, payload).then(() => {
        refreshNewsData();
      });

      // scroll to the last position after new is edited to see the edited new in a client view
      setScrollOffsetNumber(scrollOffsetNumber);
      handleResponse(ALERT_STATUS.Success, t("Alert##news edited"));
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsSubmitLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      data-testid="edit-news-dialog"
      title={t("Dialog##edit news")}
      extendToMaxWidth
      maxHeight={DialogHeight.MaxHeight}
      isTitleSeparator
      isActionsSeparator
      open={isDialogOpen}
      close={onClose}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isSubmitLoading}
          isreadyToConfirm={isPayloadReady}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-news-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-edit-news"
        />
      }
    >
      <EditNewsDialogContent
        isLoading={isLoading}
        newsData={newsData}
        setIsPayloadReady={setIsPayloadReady}
        setPayload={setPayload}
      />
    </Dialog>
  );
};

export default EditNewsDialog;
