import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const CompanyHierarchyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.2 18.2999V17.3999H13.4C12.905 17.3999 12.4813 17.2237 12.1288 16.8712C11.7762 16.5187 11.6 16.0949 11.6 15.5999V8.3999H9.8V9.2999C9.8 9.7949 9.62375 10.2187 9.27125 10.5712C8.91875 10.9237 8.495 11.0999 8 11.0999H5.3C4.805 11.0999 4.38125 10.9237 4.02875 10.5712C3.67625 10.2187 3.5 9.7949 3.5 9.2999V5.6999C3.5 5.2049 3.67625 4.78115 4.02875 4.42865C4.38125 4.07615 4.805 3.8999 5.3 3.8999H8C8.495 3.8999 8.91875 4.07615 9.27125 4.42865C9.62375 4.78115 9.8 5.2049 9.8 5.6999V6.5999H15.2V5.6999C15.2 5.2049 15.3763 4.78115 15.7288 4.42865C16.0813 4.07615 16.505 3.8999 17 3.8999H19.7C20.195 3.8999 20.6188 4.07615 20.9713 4.42865C21.3238 4.78115 21.5 5.2049 21.5 5.6999V9.2999C21.5 9.7949 21.3238 10.2187 20.9713 10.5712C20.6188 10.9237 20.195 11.0999 19.7 11.0999H17C16.505 11.0999 16.0813 10.9237 15.7288 10.5712C15.3763 10.2187 15.2 9.7949 15.2 9.2999V8.3999H13.4V15.5999H15.2V14.6999C15.2 14.2049 15.3763 13.7812 15.7288 13.4287C16.0813 13.0762 16.505 12.8999 17 12.8999H19.7C20.195 12.8999 20.6188 13.0762 20.9713 13.4287C21.3238 13.7812 21.5 14.2049 21.5 14.6999V18.2999C21.5 18.7949 21.3238 19.2187 20.9713 19.5712C20.6188 19.9237 20.195 20.0999 19.7 20.0999H17C16.505 20.0999 16.0813 19.9237 15.7288 19.5712C15.3763 19.2187 15.2 18.7949 15.2 18.2999ZM17 9.2999H19.7V5.6999H17V9.2999ZM17 18.2999H19.7V14.6999H17V18.2999ZM5.3 9.2999H8V5.6999H5.3V9.2999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CompanyHierarchyIcon;
