/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import CloseIcon from "@mui/icons-material/CloseRounded";

import { useStyles } from "./styles";
import Button from "../../../components/Button/Button";

interface IClosebuttonProps {
  onClose: () => void;
  customStyle?: any;
}

const CloseButton = ({ onClose, customStyle }: IClosebuttonProps) => {
  const { closeButton } = useStyles();

  return (
    <Button
      data-testid="close-button"
      id="close-button"
      variant="iconOnly"
      size="small"
      color="white"
      icon={<CloseIcon css={css(closeButton)} />}
      onClick={onClose}
      style={customStyle}
    />
  );
};

export default CloseButton;
