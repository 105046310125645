import { ROUTES, TABLE_NAMES } from "../../shared";

export const specificGlobalSchedule = (pathname: string) => {
  switch (pathname) {
    case ROUTES.Vehicles:
      return TABLE_NAMES.Vehicles;

    case ROUTES.Drivers:
      return TABLE_NAMES.Drivers;

    default:
      return null;
  }
};
