/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneRounded";
import { SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import NotificationsDesktop from "./components/NotificationsDesktop";
import NotificationsMobile from "./components/NotificationsMobile";

import { useStyles } from "../../styles";
import Button from "../../../Button/Button";
import { dummyNotificationsCount } from "../../../../mocks/dummyData/data";
import { MenuAnchorEl, useMediaQueries } from "../../../../shared";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { mobileDrawerState } from "../../../../atoms/atoms";

const Notifications = () => {
  const { colors } = useContext(ThemeContext);

  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const [, setIsMobileDrawerOpen] = useRecoilState(mobileDrawerState);

  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);
  const [isMobileNotificationsOpen, setIsMobileNotificationsOpen] = useState(
    false
  );

  const { notificationsButton } = useStyles();

  const openNotificationsMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => setAnchorEl(e.currentTarget);

  const closeNotificationsMenu = () => setAnchorEl(null);

  const openMobileNotifications = () => setIsMobileNotificationsOpen(true);

  const closeMobileNotifications = () => {
    setIsMobileDrawerOpen(false);
    setIsMobileNotificationsOpen(false);
  };

  return (
    <>
      <Button
        disableRipple={toMd}
        fullWidth={toMd}
        variant="iconLeft"
        size="small"
        color="white"
        icon={
          <Badge
            badgeContent={dummyNotificationsCount}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: colors.red600,
                color: colors.white,
                fontSize: "12px",
                fontWeight: "700",
                letterSpacing: "0.4px",
                padding: "0 5px",
              },
            }}
          >
            <NotificationsIcon />
          </Badge>
        }
        onClick={toMd ? openMobileNotifications : openNotificationsMenu}
        css={css(notificationsButton)}
      >
        {t("Notifications##notifications")}
      </Button>

      {toMd ? (
        <NotificationsMobile
          isOpen={isMobileNotificationsOpen}
          close={closeMobileNotifications}
        />
      ) : (
        <NotificationsDesktop
          anchorEl={anchorEl}
          close={closeNotificationsMenu}
        />
      )}
    </>
  );
};

export default Notifications;
