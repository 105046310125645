/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { DIRECTIONS } from "./types";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../Button/Button";

interface TabButtonProps {
  direction: DIRECTIONS;
}

const SegmentedToggleButtons = (
  scrollButtonLeftMargin: any,
  scrollButtonRightMargin: any,
  segmentedToggleButtons: any,
  props: TabButtonProps
) => {
  const isLeftButton = props.direction === DIRECTIONS.Left;

  return (
    <Button
      {...props}
      color={BUTTON_COLORS.White}
      size={BUTTON_SIZES.Small}
      variant={BUTTON_VARIANTS.IconOnly}
      icon={
        isLeftButton ? <ChevronLeftRoundedIcon /> : <ChevronRightRoundedIcon />
      }
      css={css([
        segmentedToggleButtons,
        isLeftButton ? scrollButtonLeftMargin : scrollButtonRightMargin,
      ])}
    />
  );
};

export default SegmentedToggleButtons;
