/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useStyles } from "../styles";
import { ThemeColors } from "../../../../../../../shared";

interface IParenAndFirstChildLineProps {
  colors: ThemeColors;
}

const ParentAndFirstChildLine = ({ colors }: IParenAndFirstChildLineProps) => {
  const { boxWithoutBorders, boxWithLeftAndBottomBorders } = useStyles(colors);

  return (
    <>
      <div css={{ display: "flex", width: "100%" }}>
        <div css={css(boxWithoutBorders)}></div>
        <div css={css(boxWithLeftAndBottomBorders)}></div>
      </div>
      <div css={{ display: "flex", width: "100%" }}>
        <div css={css(boxWithoutBorders)}></div>
        <div css={css(boxWithoutBorders)}></div>
      </div>
    </>
  );
};

export default ParentAndFirstChildLine;
