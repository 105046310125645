import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import useCompaniesCardsFormInputs from "../../../../hooks/useCompaniesCardsFormInputs";
import { DetailsDataType } from "../../../../../types";
import InputField from "../../../../../../InputField/InputField";
import { AddNewCompanyCardPayloadType } from "../../../../../../../Pages/Companies/types";
import { inputFieldMarginBottom } from "../../../../../../../shared";
import InfoIconWithTooltip from "../../../../../../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";

interface AddCompanyCardDialogContentProps {
  detailsData: DetailsDataType;
  setPayload: Dispatch<SetStateAction<AddNewCompanyCardPayloadType>>;
  setIsReadyToConfirm: Dispatch<SetStateAction<boolean>>;
}

const AddCompanyCardDialogContent = ({
  detailsData,
  setPayload,
  setIsReadyToConfirm,
}: AddCompanyCardDialogContentProps) => {
  const { t } = useTranslation();

  const [cardName, setCardName] = useState<string>("");
  const [cardId, setCardId] = useState<string | null>(null);
  const [cardNameInputError, setCardNameInputError] = useState<string | null>(
    null
  );
  const [cardIdInputError, setCardIdInputError] = useState<string | null>(null);

  const {
    isCardNameValid,
    validateCardNameLength,
    handleCardNameChangeValue,
    isCardIdValid,
    validateCardId,
    handleCardIdChangeValue,
  } = useCompaniesCardsFormInputs({
    setCardNameInputError,
    setCardName,
    setCardIdInputError,
    setCardId,
  });

  const isPayloadReadyToConfirm =
    Boolean(detailsData?.id) &&
    Boolean(cardName) &&
    Boolean(cardId) &&
    Boolean(isCardIdValid(cardId)) &&
    Boolean(isCardNameValid(cardName));

  useEffect(() => {
    detailsData &&
      setPayload({
        companyId: detailsData.id,
        name: cardName.trim(),
        companyCardId: cardId,
      });

    setIsReadyToConfirm(isPayloadReadyToConfirm);
  }, [
    cardId,
    cardName,
    detailsData,
    isPayloadReadyToConfirm,
    setIsReadyToConfirm,
    setPayload,
  ]);

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={detailsData?.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="card-name-input-field"
        size="medium"
        fullWidth
        required
        errorText={isCardNameValid(cardName) ? "" : cardNameInputError}
        onBlur={validateCardNameLength}
        onChange={handleCardNameChangeValue}
        labelLeft={t("Dialog##card name")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="card-id-input-field"
        size="medium"
        fullWidth
        required
        errorText={isCardIdValid(cardId) ? "" : cardIdInputError}
        onBlur={validateCardId}
        onChange={handleCardIdChangeValue}
        labelLeft={t("Dialog##company card id")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        labelAddition={
          <InfoIconWithTooltip title={t(`Dialog##enter company card id`)} />
        }
      />
    </>
  );
};

export default AddCompanyCardDialogContent;
