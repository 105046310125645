/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { MouseEventHandler, useContext } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { useStyles } from "./styles";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import InfoIcon from "../../assets/customIcons/status/InfoIcon";
import CriticalIcon from "../../assets/customIcons/status/CriticalIcon";
import WarningIcon from "../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../assets/customIcons/status/SuccessIcon";
import { ALERT_STATUS } from "../../context/alert/types";

export interface IAlertProps {
  status: ALERT_STATUS;
  title: string;
  description?: string;
  close?: MouseEventHandler<SVGSVGElement>;
}

const Alert = ({ status, title, description, close }: IAlertProps) => {
  const {
    colors: { blue700, green600, orange600, red700 },
  } = useContext(ThemeContext);

  const {
    alertBox,
    alertContent,
    alertTitle,
    alertDescription,
    closeAlert,
    infoIcon,
  } = useStyles();

  const styleOptions = {
    info: {
      border: blue700,
      icon: <InfoIcon css={css(infoIcon)} data-testid="alert-info-icon" />,
    },
    critical: {
      border: red700,
      icon: <CriticalIcon data-testid="alert-critical-icon" />,
    },
    warning: {
      border: orange600,
      icon: <WarningIcon data-testid="alert-warning-icon" />,
    },
    success: {
      border: green600,
      icon: <SuccessIcon data-testid="alert-success-icon" />,
    },
  };

  const borderColor = styleOptions[status].border;
  const iconImage = styleOptions[status].icon;

  return (
    <div
      data-testid="alert"
      css={css([{ borderLeft: `4px solid ${borderColor}` }, alertBox])}
    >
      {iconImage}

      <div css={css(alertContent)}>
        <div css={css(alertTitle)}>{title}</div>

        {description && <div css={css(alertDescription)}>{description}</div>}
      </div>

      {close && <CloseIcon onClick={close} css={css(closeAlert)} />}
    </div>
  );
};

export default Alert;
