import { CSSObject } from "@emotion/react";
import { ThemeColors } from "./../../types/types";

export const useStyles = (colors: ThemeColors) =>
  ({
    headerWithLogo: {
      padding: "16px 24px 15px 24px",
      borderBottom: `1px solid ${colors.gray200}`,
      display: "flex",
      alignItems: "center",
    },
    animationCSS: {
      position: "absolute",
      bottom: "120px",
      height: "180px",
      margin: "0 auto",

      "@media (max-width: 839px)": {
        bottom: "78px",
        height: "125px",
      },

      "@media (max-width: 599px)": {
        bottom: "60px",
        height: "110px",
      },
    },
    errorMainContainer: {
      justifyContent: "center",
      alignItems: "center",
      padding: "100px 0",
    },
    errorAnimatedBlock: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "625px",
      height: "416px",
      boxSizing: "border-box",
      border: `1px solid ${colors.gray200}`,
      borderRadius: "8px",

      "@media (max-width: 839px)": {
        width: "410px",
        height: "273px",
      },

      "@media (max-width: 599px)": {
        width: "328px",
        height: "218px",
      },
    },
    errorMainTitle: {
      color: colors.textIconDark,
      fontSize: "140px",
      fontWeight: "600",
      textTransform: "uppercase",

      "@media (max-width: 839px)": {
        fontSize: "92px",
      },

      "@media (max-width: 599px)": {
        fontSize: "74px",
      },
    },
    errorDescriptionArea: {
      marginTop: "32px",
      textAlign: "center",
      padding: "0 16px",

      h1: {
        color: colors.textDark,
        fontSize: "32px",
        fontWeight: "600",
        lineHeight: "40px",
        letterSpacing: "0.25px",

        "@media(max-width: 599px)": {
          fontSize: "24px",
          lineHeight: "32px",
          letterSpacing: "unset",
        },
      },

      p: {
        color: colors.gray700,
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: "0.15px",
      },
    },
    errorBoxDots: {
      position: "absolute",
      top: "24px",
      left: "16px",
      display: "flex",
      columnGap: "8px",

      span: {
        display: "inline-block",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
      },

      "span:nth-of-type(1)": {
        background: colors.red600,
      },

      "span:nth-of-type(2)": {
        background: colors.orange400,
      },

      "span:nth-of-type(3)": {
        background: colors.green500,
      },

      "@media (max-width: 839px)": {
        top: "16px",
        left: "10px",
        columnGap: "5px",

        span: {
          width: "10px",
          height: "10px",
        },
      },

      "@media (max-width: 599px)": {
        top: "12px",
        left: "8px",
        columnGap: "4px",

        span: {
          width: "8px",
          height: "8px",
        },
      },
    },
  } as CSSObject);
