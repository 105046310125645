/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

import DialogParagraph from "./DialogParagraph";
import ListParagraph from "./ListParagraph";

import { useStyles } from "../styles";
import { betaVersionCheckboxLabel, betaVersionParagraphs } from "../config";

import Checkbox from "../../../../CheckBox/Checkbox";

import closeBetaImage from "../../../../../assets/images/ClosedBETA-image_compressed.jpg";
import { useMediaQueries } from "../../../../../shared";

interface BetaVersionContentProps {
  onCheckboxChange: () => void;
}

const BetaVersionContent = ({ onCheckboxChange }: BetaVersionContentProps) => {
  const { toMd } = useMediaQueries();

  const { textContainer } = useStyles();

  const imageWidthFromMd = "636px";
  const imageHeightFromMd = "318px";

  const formattedText = betaVersionParagraphs.map((paragraph) => (
    <div key={paragraph.title} css={css(textContainer)}>
      <Typography variant="body2Bold">{paragraph.title}</Typography>
      {paragraph.description && (
        <DialogParagraph text={paragraph.description} />
      )}
      {paragraph.list && <ListParagraph listItems={paragraph.list} />}
      {paragraph.afterParagraph && (
        <DialogParagraph text={paragraph.afterParagraph} />
      )}
    </div>
  ));

  return (
    <>
      <img
        src={closeBetaImage}
        alt="Closed Beta"
        width={toMd ? "100%" : imageWidthFromMd}
        height={toMd ? "auto" : imageHeightFromMd}
      />
      <div>{formattedText}</div>
      <Checkbox
        label={
          <span
            css={css({ span: { fontWeight: 700 } })}
            dangerouslySetInnerHTML={{ __html: betaVersionCheckboxLabel }} // this type of code allows us to escape HTML tags.
          />
        }
        onChange={onCheckboxChange}
      />
    </>
  );
};

export default BetaVersionContent;
