import useTutorialMode from "./useTutorialMode";
import { STEP_GROUP, TOOLTIP_ACTIONS } from "../types";

const useTutorialTooltipButtons = (toMd: boolean, fromMd: boolean) => {
  const { stepGroup, stepIndex } = useTutorialMode();

  const isFirstStep = stepIndex === 0;

  const getProceedButtonText = (isLastStep: boolean) => {
    if (isLastStep) {
      return TOOLTIP_ACTIONS.Finish;
    }

    if (isFirstStep) {
      return TOOLTIP_ACTIONS.GetStarted;
    }

    return TOOLTIP_ACTIONS.Next;
  };

  const showPreviousButton = () => {
    if (!isFirstStep) {
      if (stepGroup === STEP_GROUP.TutorialNewCompanyCard && toMd) {
        return stepIndex !== 2;
      }
      if (stepGroup === STEP_GROUP.TutorialApplySchedule) {
        return (
          (fromMd && stepIndex !== 1) ||
          (toMd && stepIndex !== 1 && stepIndex !== 2)
        );
      }
      return true;
    }
    return false;
  };

  const showNextButton = () => {
    const isCorrectStep = (steps: number[]) => steps.includes(stepIndex);

    switch (stepGroup) {
      case STEP_GROUP.TutorialNewCompanyCard:
        return fromMd ? !isCorrectStep([1, 2]) : isCorrectStep([3, 4, 5]);

      case STEP_GROUP.TutorialApplySchedule:
        return fromMd ? !isCorrectStep([0, 1, 2]) : isCorrectStep([5, 6, 7]);

      case STEP_GROUP.TutorialCreateSchedule:
        return fromMd ? !isCorrectStep([1, 2]) : !isCorrectStep([1, 2, 3]);

      default:
        return false;
    }
  };

  return { getProceedButtonText, showNextButton, showPreviousButton };
};

export default useTutorialTooltipButtons;
