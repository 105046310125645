/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "../styles";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

const ColoredCircles = () => {
  const { colors } = useContext(ThemeContext);

  const { errorBoxDots } = useStyles(colors);

  return (
    <div css={css(errorBoxDots)} data-testid="colored-circles">
      <span />
      <span />
      <span />
    </div>
  );
};

export default ColoredCircles;
