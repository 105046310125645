import { Skeleton } from "@mui/material";
import { useContext } from "react";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface IRectangleLoaderProps {
  width?: string | any;
  height?: string | any;
  customStyle?: any;
  backgroundColor?: string;
  borderRadius?: string;
  testId?: string;
}

const RectangleLoader = ({
  width,
  height,
  customStyle,
  backgroundColor,
  borderRadius,
  testId,
}: IRectangleLoaderProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <Skeleton
      data-testid={testId ?? "rectangle-loader"}
      variant="rectangular"
      width={width}
      height={height}
      sx={[
        {
          background: backgroundColor ?? colors.gray100,
          borderRadius: borderRadius ?? "6px",
        },
        customStyle,
      ]}
    />
  );
};

export default RectangleLoader;
