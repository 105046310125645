/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

import ListChoice from "../../../components/ListChoice/ListChoice";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface IGoBackButtonClick {
  onButtonClick: any;
}

const GoBackButton = ({ onButtonClick }: IGoBackButtonClick) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <ListChoice
      title={t("Button##go back")}
      iconLeft={<ChevronLeftRoundedIcon />}
      onClick={onButtonClick}
      css={css({ background: colors.white, color: colors.blue700 })}
    />
  );
};

export default GoBackButton;
