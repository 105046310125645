import { useTranslation } from "react-i18next";
import { useContext } from "react";

import SingleEmailTransferItem from "./components/SingleEmailTransferItem/SingleEmailTransferItem";
import AddNewEmailRecipient from "./components/AddNewEmailRecipient/AddNewEmailRecipient";

import AccordionDetailsCard from "../AccordionDetailsCard/AccordionDetailsCard";

import { EmailTransferObjectDto } from "../../../../types";

import { COMPANY_CARDS_LIMITS } from "../../../../../../Pages/Companies/types";
import InfoIconWithTooltip from "../../../../../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import Separator from "../../../../../Separator/Separator";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../BannerMessage/BannerMessage";

interface EmailTransferDetailsProps {
  emailTransferDetails: EmailTransferObjectDto[];
  companyId: string;
}

const EmailTransferDetails = ({
  emailTransferDetails,
  companyId,
}: EmailTransferDetailsProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  const hasEmails = emailTransferDetails && emailTransferDetails.length > 0;

  const noEmailTransferContent = () => (
    <>
      <Separator color={colors.gray200} />
      <BannerMessage
        data-testid="no-email-transfer-banner"
        status={BANNER_MESSAGE_STATUSES.Warning}
        title={t("Details##no recipients added")}
        externalCustomStyle={{ margin: "16px" }}
      />
    </>
  );

  const renderListOfCards = () => (
    <AccordionDetailsCard
      cardTitle={t(`Details##email transfer`)}
      maxCount={COMPANY_CARDS_LIMITS.MaxEmailTransferCount}
      currentCount={emailTransferDetails.length}
      quickTitleAction={
        <AddNewEmailRecipient
          companyId={companyId}
          emailCount={emailTransferDetails.length}
        />
      }
      titleInlineIcon={
        <InfoIconWithTooltip
          title={t("Details##email transfer info tooltip", {
            maxCount: COMPANY_CARDS_LIMITS.MaxEmailTransferCount,
          })}
          tooltipMinWidth="310px"
        />
      }
    >
      {hasEmails &&
        emailTransferDetails.map(
          (card: EmailTransferObjectDto, index: number) => (
            <SingleEmailTransferItem
              key={card.id}
              companyId={companyId}
              card={card}
              totalCount={emailTransferDetails.length}
              index={index}
            />
          )
        )}
      {!hasEmails && noEmailTransferContent()}
    </AccordionDetailsCard>
  );

  return <>{renderListOfCards()}</>;
};

export default EmailTransferDetails;
