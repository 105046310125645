import { useState } from "react";
import { useTranslation } from "react-i18next";

import BetaVersionContent from "./components/BetaVersionContent";

import Dialog from "../../../Dialog/Dialog";
import { DialogHeight, DialogWidth } from "../../../Dialog/types/enums";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../Button/Button";

import { useMediaQueries } from "../../../../shared";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import useAxiosPrivate from "../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../api/resources";
import useResponse from "../../../../shared/hooks/useResponse";

const WelcomeDialog = () => {
  const { user } = useAuth();
  const { putData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const hasUserAcceptedBetaTerms = user?.acceptedBetaTerms;
  const showBetaWellcomeDialog = !hasUserAcceptedBetaTerms;

  const [isOpen, setOpen] = useState(showBetaWellcomeDialog);
  const [isChecked, setIsChecked] = useState(hasUserAcceptedBetaTerms);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseDialog = async () => {
    if (isChecked) {
      try {
        setIsLoading(true);

        await putData(ApiResources.AcceptBetaVersionTerms);

        setOpen(false);
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      {showBetaWellcomeDialog && (
        <Dialog
          title={t("Dialog##welcome to beta version title")}
          isTitleSeparator
          isActionsSeparator
          close={undefined} // the dialog can be closed only after checking the checkbox on actions button click
          open={isOpen}
          extendToMaxWidth={toMd}
          width={DialogWidth.MaxWidth}
          showXButton={false}
          maxHeight={DialogHeight.MaxHeight}
          actions={
            <Button
              fullWidth
              color={BUTTON_COLORS.Primary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              onClick={handleCloseDialog}
              disabled={!isChecked}
              isLoading={isLoading}
            >
              {t("Button##continue to TachoSync")}
            </Button>
          }
        >
          <BetaVersionContent onCheckboxChange={toggleCheckbox} />
        </Dialog>
      )}
    </>
  );
};

export default WelcomeDialog;
