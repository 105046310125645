import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = (isDisabled?: boolean, showSeparator?: boolean) =>
  ({
    toggleItemContainer: {
      display: "flex",
      alignItems: "center",
      padding: showSeparator ? "0 0 16px 0" : "16px 0",
      justifyContent: "space-between",
    },
    toggleItemTitleBox: {
      display: "flex",
      alignItems: "center",
      color: isDisabled ? COLORS.TextDarkDisabled : COLORS.TextSecondary,
    },
  } as CSSObject);
