import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import TableIsEmptyIconStopFragment from "./TableIsEmptyIconStopFragment";

const TableIsEmptyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 120 120" {...props} data-testid="empty-table-icon">
      <g clipPath="url(#clip0_8097_358293)" fill="none">
        <g clipPath="url(#clip1_8097_358293)">
          <path
            d="M54.3516 48.1143C54.7356 47.9643 55.1016 47.8083 55.4616 47.6523"
            stroke="url(#paint0_linear_8097_358293)"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.7582 45.9782C74.7962 36.5522 58.6742 22.5302 52.7222 28.6922C46.3622 35.2682 71.7962 40.4402 79.0262 29.4962C86.5382 18.1262 68.4782 9.46215 66.0122 12.9602C63.4502 16.5902 78.7922 22.3502 86.0042 14.4962"
            stroke="url(#paint1_linear_8097_358293)"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M87.1406 13.038C87.3566 12.708 87.5606 12.366 87.7526 12"
            stroke="url(#paint2_linear_8097_358293)"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M71.3203 51.0479H106.072V105.594C106.072 106.92 104.992 108 103.666 108H71.3203V51.0479Z"
            fill="url(#paint3_linear_8097_358293)"
          />
          <path
            d="M112.317 32.6045H84.1589C83.4629 32.6045 82.8209 33.0005 82.5089 33.6245L73.7969 51.0545H106.077L113.967 35.2745C114.579 34.0505 113.691 32.6045 112.317 32.6045Z"
            fill="url(#paint4_linear_8097_358293)"
          />
          <path
            d="M10.0735 32.6045H35.8315C36.5275 32.6045 37.1695 33.0005 37.4815 33.6245L46.1935 51.0545H16.3135L8.42348 35.2745C7.81148 34.0505 8.69948 32.6045 10.0735 32.6045Z"
            fill="url(#paint5_linear_8097_358293)"
          />
          <path
            d="M16.1953 51.0479H73.7953V105.642C73.7953 106.944 72.7393 108 71.4373 108H18.5533C17.2513 108 16.1953 106.944 16.1953 105.642V51.0479Z"
            fill="#C5E2FF"
          />
          <path
            d="M99.6632 58.3438H82.7672C81.4417 58.3438 80.3672 59.4183 80.3672 60.7438V73.5598C80.3672 74.8852 81.4417 75.9598 82.7672 75.9598H99.6632C100.989 75.9598 102.063 74.8852 102.063 73.5598V60.7438C102.063 59.4183 100.989 58.3438 99.6632 58.3438Z"
            fill="#C5E2FF"
          />
          <path
            d="M83.5547 62.5918H99.4367"
            stroke="#70B8FF"
            strokeWidth="1.806"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.5547 66.7441H90.4727"
            stroke="#70B8FF"
            strokeWidth="1.806"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.82812 107.4H114.164"
            stroke="#70B8FF"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            opacity="0.5"
            d="M41.7013 92.6875H23.8633C22.9421 92.6875 22.1953 93.4343 22.1953 94.3555V100.332C22.1953 101.253 22.9421 102 23.8633 102H41.7013C42.6225 102 43.3693 101.253 43.3693 100.332V94.3555C43.3693 93.4343 42.6225 92.6875 41.7013 92.6875Z"
            fill="#70B8FF"
          />
          <path
            d="M73.795 51.0479H16.243L12.439 62.4538C12.043 63.6478 12.931 64.8838 14.191 64.8838H67.855C68.647 64.8838 69.355 64.3738 69.607 63.6238L73.801 51.0479H73.795Z"
            fill="#EAF5FF"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8097_358293"
          x1="54.2196"
          y1="49.0744"
          x2="79.1077"
          y2="5.96416"
          gradientUnits="userSpaceOnUse"
        >
          <TableIsEmptyIconStopFragment />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8097_358293"
          x1="54.2582"
          y1="49.0982"
          x2="79.1462"
          y2="5.99415"
          gradientUnits="userSpaceOnUse"
        >
          <TableIsEmptyIconStopFragment />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8097_358293"
          x1="63.3149"
          y1="54.33"
          x2="88.203"
          y2="11.2202"
          gradientUnits="userSpaceOnUse"
        >
          <TableIsEmptyIconStopFragment />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8097_358293"
          x1="71.3203"
          y1="79.5239"
          x2="106.072"
          y2="79.5239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70B8FF" />
          <stop offset="1" stopColor="#C5E2FF" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8097_358293"
          x1="73.7969"
          y1="41.8265"
          x2="114.165"
          y2="41.8265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70B8FF" />
          <stop offset="1" stopColor="#C5E2FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8097_358293"
          x1="27.2095"
          y1="51.0485"
          x2="27.2095"
          y2="32.6045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70B8FF" />
          <stop offset="1" stopColor="#C5E2FF" />
        </linearGradient>
        <clipPath id="clip0_8097_358293">
          <rect width="120" height="120" fill="white" />
        </clipPath>
        <clipPath id="clip1_8097_358293">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default TableIsEmptyIcon;
