import { Dispatch, SetStateAction, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";

import { getPayloadObject } from "../functions";

import useCompaniesResources from "../../hooks/useCompaniesResources";

import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../BannerMessage/BannerMessage";

import { TABLE_NAMES } from "../../../../shared";
import SelectedRowsTag from "../../../../shared/components/tags/SelectedRowsTag";
import ResourceTag from "../../../../shared/components/tags/ResourceTag";

interface IDeleteTableItemsDialogContentProps {
  selectedRows: string[];
  setPayload: Dispatch<SetStateAction<object | null>>;
  resourceName: TABLE_NAMES | undefined;
}

const DeleteTableItemsDialogContent = ({
  selectedRows,
  setPayload,
  resourceName,
}: IDeleteTableItemsDialogContentProps) => {
  const { companyRelatedResources, isLoading } = useCompaniesResources(
    resourceName,
    selectedRows
  );
  const { t } = useTranslation();

  const resourceCount = selectedRows?.length;

  useEffect(() => {
    setPayload(getPayloadObject(resourceName, selectedRows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, setPayload]);

  const generateCompaniesRelatedResourcesTags = () => {
    if (companyRelatedResources) {
      return Object.entries(companyRelatedResources).map(([key, val]) => (
        <ResourceTag
          key={uuidv4()}
          resource={key}
          isLoading={isLoading}
          count={val}
        />
      ));
    }
  };

  return (
    <>
      {resourceName === TABLE_NAMES.Companies && (
        <BannerMessage
          data-testid="delete-companies-dialog-banner"
          status={BANNER_MESSAGE_STATUSES.Warning}
          title={t("Dialog##delete companies warning")}
          externalCustomStyle={{ marginBottom: "16px" }}
        />
      )}
      <SelectedRowsTag
        resourceName={resourceName}
        resourceCount={resourceCount}
      />
      {generateCompaniesRelatedResourcesTags()}
    </>
  );
};

export default DeleteTableItemsDialogContent;
