/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import { useStyles } from "../styles";
import LeftSide from "../LeftSide/LeftSide";
import RightSide from "../RightSide/RightSide";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const ContentContainer = () => {
  const { colors } = useContext(ThemeContext);
  const { contentContainer } = useStyles({
    colors,
  });

  return (
    <div css={css(contentContainer)} className=".scrollable">
      <LeftSide />
      <RightSide />
    </div>
  );
};

export default ContentContainer;
