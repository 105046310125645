import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { useState } from "react";

import { NewsDto } from "../..";

import Dialog from "../../../../../../components/Dialog/Dialog";
import { DialogWidth } from "../../../../../../components/Dialog/types/enums";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import useRefreshDashboardData from "../../../../../../shared/hooks/useRefreshDashboardData";
import { ApiResources } from "../../../../../../api/resources";
import { newsScrollOffset } from "../../../../../../atoms/atoms";
import { ALERT_STATUS } from "../../../../../../context/alert/types";

interface DeleteNewsDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
  newsData: NewsDto | null;
}

const DeleteNewsDialog = ({
  isDialogOpen,
  onClose,
  newsData,
}: DeleteNewsDialogProps) => {
  const { deleteData } = useAxiosPrivate();
  const { handleResponse, handleCommonError } = useResponse();
  const { refreshNewsData } = useRefreshDashboardData();
  const { t } = useTranslation();

  const [, setScrollOffsetNumber] = useRecoilState(newsScrollOffset);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (newsData) {
      try {
        setIsLoading(true);
        await deleteData(ApiResources.NewsItems, newsData.id).then(() => {
          refreshNewsData();
        });
        setScrollOffsetNumber(0);
        handleResponse(ALERT_STATUS.Success, t("Alert##news deleted"));
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        setIsLoading(false);
        onClose();
      }
    }
  };

  return (
    <Dialog
      data-testid="delete-news-dialog"
      title={t("Dialog##delete news")}
      description={t("Dialog##delete news description")}
      width={DialogWidth.MinWidth}
      extendToMaxWidth
      isActionsSeparator
      open={isDialogOpen}
      close={onClose}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isLoading}
          isreadyToConfirm={true}
          confirmationButtonText={t("Button##accept")}
          confirmationButtonTestId="delete-news-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-delete-news"
        />
      }
    />
  );
};

export default DeleteNewsDialog;
