import { useContext } from "react";

import CreditsGeneralInfo from "../CreditsGeneralInfo/CreditsGeneralInfo";
import CreditsHistoryInfo from "../CreditsHistoryInfo/CreditsHistoryInfo";

import { CreditsDto } from "../../../types";

import { useFormattedNumber, useMediaQueries } from "../../../../../../shared";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import DialogContentWrapper from "../../../../../../components/Dialog/components/DialogContentWrapper/DialogContentWrapper";

interface HistoryDialogContentProps {
  creditsData: CreditsDto;
}

const HistoryDialogContent = ({ creditsData }: HistoryDialogContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { renderFormattedNumber } = useFormattedNumber();
  const { fromMd } = useMediaQueries();

  const formattedNumberColor = (
    positiveNumber: boolean,
    incoming?: boolean
  ) => {
    const positiveIncomingNumber = positiveNumber && incoming;
    const incomingNumberColor = positiveIncomingNumber && colors.green900;
    const positiveNumberColor = positiveNumber && colors.textDark;
    const negativeNumberColor = colors.red800;

    return incomingNumberColor || positiveNumberColor || negativeNumberColor;
  };

  return (
    <DialogContentWrapper
      maxHeight={fromMd ? "528px" : undefined} // with such content will be 754px max height of the dialog
      testId="history-dialog-content"
    >
      <CreditsGeneralInfo
        colors={colors}
        creditsData={creditsData}
        renderFormattedNumber={renderFormattedNumber}
        formattedNumberColor={formattedNumberColor}
      />
      {creditsData?.history && creditsData?.history.length > 0 && (
        <CreditsHistoryInfo
          colors={colors}
          creditsData={creditsData}
          renderFormattedNumber={renderFormattedNumber}
          formattedNumberColor={formattedNumberColor}
        />
      )}
    </DialogContentWrapper>
  );
};

export default HistoryDialogContent;
