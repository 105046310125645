import { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import moment from "moment";

import { TimeZoneContext } from "../../../../../../../../context/TimeZone/TimeZoneProvider";
import { useDateTime } from "../../../../../../../../shared";

export interface TimeZoneWithOffset {
  name: string;
  offset: string;
}

const useTimeSettings = () => {
  const { currentTimeZone } = useContext(TimeZoneContext);
  const { currentDateFormat } = useDateTime();

  const formTimeZoneWithOffset = (timeZoneName: string) => {
    const offset = moment()
      .tz(timeZoneName)
      .format("Z");

    return {
      name: timeZoneName,
      offset,
    };
  };

  const [timeZoneValue, setTimeZoneValue] = useState(
    formTimeZoneWithOffset(currentTimeZone)
  );
  const [timeZones, setTimeZones] = useState<TimeZoneWithOffset[]>([]);

  const [dateFormatValue, setDateFormatValue] = useState(currentDateFormat);

  useEffect(() => {
    const countryCodes = moment.tz.countries();

    const names = countryCodes.reduce(
      (list: string[], code) => [...list, ...moment.tz.zonesForCountry(code)],
      []
    );

    const uniqueNames = lodash.uniq(names);

    const namesWithOffset = uniqueNames.map((name) =>
      formTimeZoneWithOffset(name)
    );

    const compareByName = (a: TimeZoneWithOffset, b: TimeZoneWithOffset) => {
      if (a.name < b.name && -1) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    };

    namesWithOffset.sort(compareByName);

    namesWithOffset.sort((a, b) => {
      const [ahh, amm] = a.offset.split(":");
      const [bhh, bmm] = b.offset.split(":");

      return Number(ahh) * 60 + Number(amm) - (Number(bhh) * 60 + Number(bmm));
    });

    setTimeZones(namesWithOffset);
  }, []);

  return {
    dateFormatValue,
    timeZones,
    timeZoneValue,
    formTimeZoneWithOffset,
    setDateFormatValue,
    setTimeZoneValue,
  };
};

export default useTimeSettings;
