import { useLocation } from "react-router-dom";
import { deselectAllRows } from "./../functions";
import { AxiosError } from "axios";
import { useRecoilState } from "recoil";

import { DownloadFileProps } from "../types";
import useResponse from "../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import { ALERT_STATUS } from "../../../context/alert/types";
import { selectedTableRows } from "../../../atoms/atoms";
import { capitalizeLocationFirstLetter } from "../../../shared/functions/functions";

export const useFileDownload = (passUrl?: string) => {
  const { getData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const location = useLocation();

  const [, setSelectedRows] = useRecoilState<any>(selectedTableRows);

  const downloadCCAFile = async ({ setIsLoading }: DownloadFileProps) => {
    if (passUrl) {
      try {
        setIsLoading && setIsLoading(true);
        const response = await getData(passUrl, {
          responseType: "blob",
        });

        window.location.href = response.headers.location;
      } catch (error) {
        const err = error as AxiosError;
        if (err) {
          const response: any = err.response;
          handleResponse(
            ALERT_STATUS.Critical,
            response?.data?.message || err.message
          );
        }
      } finally {
        setIsLoading && setIsLoading(false);
      }
    }
  };

  const downloadFile = async ({
    queryString,
    setIsLoading,
    onCloseDialog,
    successMessage,
  }: DownloadFileProps) => {
    const resource =
      passUrl ||
      `${process.env.REACT_APP_BASE_URL}${capitalizeLocationFirstLetter(
        location.pathname
      )}/Download?Ids=${queryString}`;

    try {
      setIsLoading && setIsLoading(true);
      const response = await getData(resource, {
        responseType: "blob",
      });

      const blob = new Blob([response.data]);

      const fileName = response.headers.filename;

      const link = document.createElement("a");

      link.download = fileName;
      link.href = window.URL.createObjectURL(blob);

      document.body.appendChild(link);
      link.click();

      successMessage && handleResponse(ALERT_STATUS.Success, successMessage);

      document.body.removeChild(link);
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        const response: any = err.response;
        handleResponse(
          ALERT_STATUS.Critical,
          response?.data?.message || err.message
        );
      }
    } finally {
      setIsLoading && setIsLoading(false);
      deselectAllRows(setSelectedRows);
      onCloseDialog && onCloseDialog();
    }
  };

  return { downloadFile, downloadCCAFile };
};
