import { Typography } from "@mui/material";

interface ScheduleCardRowProps {
  resourceName: string;
  resourceValue: string | null;
  firstColumnCSS: any;
}

const ScheduleCardRow = ({
  resourceName,
  resourceValue,
  firstColumnCSS,
}: ScheduleCardRowProps) => {
  return (
    <div data-testid="schedule-card-row">
      <Typography variant="body2" sx={firstColumnCSS}>
        {resourceName}:
      </Typography>

      <Typography variant="body2">{resourceValue ?? "-"}</Typography>
    </div>
  );
};

export default ScheduleCardRow;
