/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Link from "@mui/material/Link";

import { IconLinkProps } from "../types";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import Tooltip from "../../../components/Tooltip/Tooltip";

const IconLink = ({ link, tooltip, icon: Icon, dataTestId }: IconLinkProps) => {
  const { t } = useTranslation();

  const { colors } = useContext(ThemeContext);

  return (
    <Tooltip disableTouchListener title={t(`Auth##${tooltip}`)} size="medium">
      <Link
        data-testid={dataTestId}
        target="_blank"
        rel="noreferrer"
        href={link}
        underline="hover"
        css={css({
          display: "inline-flex",
          color: colors.gray700,

          "&:hover": {
            color: colors.blue700,
          },
        })}
      >
        <Icon />
      </Link>
    </Tooltip>
  );
};

export default IconLink;
