import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

interface CircleStepperStyleProps {
  colors: ThemeColors;
  showLeftHorizontalLine: boolean | undefined;
  fromMd: boolean;
}

export const useStyles = ({
  colors,
  showLeftHorizontalLine,
  fromMd,
}: CircleStepperStyleProps) => {
  const stickLength = fromMd ? "60px" : "41px";
  const stickThickness = "2px";
  return {
    circleStepperContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "fit-content",
      alignItems: "center",
      padding: fromMd ? "16px 0" : undefined,
    },
    circleStepIconStyle: {
      width: "32px",
      height: "32px",
    },
    pulsatingCircle: {
      height: "28px",
      width: "28px",
      position: "relative",
      borderRadius: "50%",
      border: `2px solid ${colors.blue700}`,
    },
    pulsatingDot: {
      height: "10px",
      width: "10px",
      position: "absolute",
      borderRadius: "50%",
      left: "9px",
      top: "9px",
      backgroundColor: `${colors.blue700}`,
    },
    stagesFromMdContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: !showLeftHorizontalLine ? stickLength : undefined,
    },
    stagesToMdContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: showLeftHorizontalLine ? "center" : "undefined",
    },
    stageIconContainerToMd: {
      display: "flex",
      flexDirection: "column",
      width: "fit-content",
      justifyContent: "center",
      alignItems: "center",
    },
    horizontalStickStyle: {
      width: stickLength,
      height: stickThickness,
    },
    verticalStickStyle: {
      width: stickThickness,
      height: stickLength,
    },
    downloadStepTextToMd: {
      marginLeft: "16px",
      marginTop: showLeftHorizontalLine ? "0" : "8px",
    },
  } as CSSObject;
};
