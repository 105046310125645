import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    checkboxLabel: {
      display: "inline-flex",
      alignItems: "center",
    },
    checkboxContent: {
      "& > span": {
        display: "block",
      },
    },
  } as CSSObject);
