import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  COLUMN_RESOURCES,
  DATE_FORMATS,
  DOWNLOAD_ACTIVITY_STATUS,
  useDateTime,
} from "../..";
import DateTime from "../../components/dateTime/DateTime";
import {
  getNumberFromString,
  getStringWithoutNumber,
} from "../../functions/functions";
import Tooltip from "../../../components/Tooltip/Tooltip";
import DetailsCardItemText from "../../../components/DetailsSection/components/DetailsContent/components/DetailsCardItemText";
import SharedStatusBadge from "../../../components/SharedStatusBadge/SharedStatusBadge";

const useCustomResources = () => {
  const { t } = useTranslation();

  const {
    currentTimeFormatHoursMinutes,
    currentTimeZone,
    convertToCurrentTimezone,
  } = useDateTime();

  const renderLastDownloadAttemptBadge = (
    wasLastAttemptSuccessful: boolean | null
  ) => {
    if (wasLastAttemptSuccessful === null) {
      return <DetailsCardItemText textToRender={"-"} />;
    }
    if (wasLastAttemptSuccessful === true) {
      return (
        <SharedStatusBadge
          status={DOWNLOAD_ACTIVITY_STATUS.Successful}
          resourceName={COLUMN_RESOURCES.LastDownload}
        />
      );
    } else {
      return (
        <SharedStatusBadge
          status={DOWNLOAD_ACTIVITY_STATUS.Failed}
          resourceName={COLUMN_RESOURCES.LastDownload}
        />
      );
    }
  };

  const renderLastDownloadAttemptBadgeWithTooltip = (data: any) => {
    return data.lastDownloadAttemptDate ? (
      <Tooltip
        data-testid="last-download-attempt-tooltip"
        title={<DateTime date={String(data.lastDownloadAttemptDate)} />}
        placement="top"
        size="medium"
        hasArrow
      >
        <span>{renderLastDownloadAttemptBadge(data.lastDownloadAttempt)}</span>
      </Tooltip>
    ) : (
      <>{renderLastDownloadAttemptBadge(data.lastDownloadAttempt)}</>
    );
  };

  const renderNextDownloadAttemptText = (
    nextDownloadAttempt: string | null,
    fromTable?: boolean
  ) => {
    if (nextDownloadAttempt !== null) {
      const nextDownloadAttemptTextDetails = getNumberFromString(
        nextDownloadAttempt
      )
        ? // display translation with dynamicaly fetched number
          t(`Details##${getStringWithoutNumber(nextDownloadAttempt)}`, {
            count: +getNumberFromString(nextDownloadAttempt),
          })
        : // else translate string
          t(`Details##${nextDownloadAttempt}`);

      return fromTable ? (
        convertStringToDate(nextDownloadAttempt)
      ) : (
        <DetailsCardItemText textToRender={nextDownloadAttemptTextDetails} />
      );
    } else {
      return <DetailsCardItemText textToRender={"-"} />;
    }
  };

  const convertStringToDate = (nextDownloadAttempt: string | null) => {
    if (moment(nextDownloadAttempt, DATE_FORMATS.ISO_8601).isValid()) {
      const convertedDate = convertToCurrentTimezone(nextDownloadAttempt);

      const currentTime = moment().tz(currentTimeZone);
      return convertedDate.isBefore(currentTime)
        ? t("Details##Upon device connection")
        : convertedDate.format(currentTimeFormatHoursMinutes);
    } else {
      return nextDownloadAttempt;
    }
  };

  return {
    renderLastDownloadAttemptBadge,
    renderNextDownloadAttemptText,
    renderLastDownloadAttemptBadgeWithTooltip,
  };
};

export default useCustomResources;
