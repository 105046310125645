import { ThemeColors } from "../../shared";

export const DoughnutTopAlignment = "48";
export const DoughnutCenterItemsLineHeight = "16";

export const emptyDoughnut = {
  id: "emptyDoughnut",
  afterDraw(chart: any, _args: any, options: any) {
    const { datasets } = chart.data;

    const { color, width, radiusDecrease } = options;
    let hasData = false;

    let total: number = 0;
    datasets.forEach((dataset: any) =>
      dataset.data.map((item: number) => {
        total = total + item;
        return total;
      })
    );

    if (total > 0) {
      hasData = true;
    }

    if (!hasData) {
      const {
        chartArea: { left, top, right, bottom },
        ctx,
      } = chart;
      // wait for the chart to be drawn to avoid flash of empty doughnut
      chart &&
        setTimeout(() => {
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;
          ctx?.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
          ctx?.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
          ctx?.stroke();
        }, 100);
    }
  },
};

export const emptyDoughnutStyles = (colors: ThemeColors, cutout: number) => {
  return {
    color: colors.gray300,
    width: 20,
    radiusDecrease: 10,
    cutout: cutout,
  };
};
