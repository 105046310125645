/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, SyntheticEvent } from "react";
import MuiDialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Typography } from "@mui/material";

import { CombineDialogNewFeatureProps } from "./types";
import { useStyles } from "./styles";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../Button/Button";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../shared";
import useObserveMutation from "../../shared/hooks/useObserveMutation";

const DialogNewFeature = ({
  title,
  description,
  image,
  actions,
  close,
  submit,
  ...props
}: CombineDialogNewFeatureProps) => {
  const {
    colors: { textDark, gray700 },
  } = useContext(ThemeContext);

  const { toMd } = useMediaQueries();
  useObserveMutation({
    parentElementName: document.body,
    querySelector: ".MuiDialog-root",
  });

  const {
    dialogPaper,
    closeButton,
    contentContainer,
    dialogNewFeatureActions,
  } = useStyles({ toMd });

  const handleSubmit = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    submit?.();
  };

  return (
    <MuiDialog
      data-testid="dialog-new-feature"
      {...props}
      onClose={close}
      fullScreen={toMd}
      sx={{
        "& .MuiDialog-paper": dialogPaper as {},
      }}
    >
      {close && (
        <Button
          data-testid="dialog-new-feature-close-button"
          color={BUTTON_COLORS.White}
          size={BUTTON_SIZES.Small}
          variant={BUTTON_VARIANTS.IconOnly}
          icon={<CloseIcon />}
          css={css(closeButton)}
          onClick={close}
        />
      )}

      <div css={css(contentContainer)}>
        <div>
          {image}

          <Typography
            variant="h6"
            sx={{ color: textDark, margin: "16px 0 8px 0" }}
          >
            {title}
          </Typography>

          <Typography variant="body2Regular" sx={{ color: gray700 }}>
            {description}
          </Typography>
        </div>

        <form onSubmit={handleSubmit}>
          <div css={css(dialogNewFeatureActions)}>{actions}</div>
        </form>
      </div>
    </MuiDialog>
  );
};

export default DialogNewFeature;
