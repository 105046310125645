import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

export const COMPANIES_DESKTOP_TABLE_COLUMNS: Column[] = [
  // Companies page rows content is rendered together with table checkbox in TableCheckbox.tsx
  {
    name: COLUMN_NAMES.Company,
    isSticky: true,
    resource: COLUMN_RESOURCES.Name,
    isDefaultActive: true,
  },
];

export const COMPANIES_MOBILE_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Company,
    isSticky: true,
    resource: COLUMN_RESOURCES.Name,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.ParentCompany,
    isSticky: true,
    resource: COLUMN_RESOURCES.ParentCompanyName,
    isDefaultActive: true,
  },
];
