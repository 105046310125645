import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRecoilState } from "recoil";

import { NewsDto } from "../..";

import Dialog from "../../../../../../components/Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { DialogWidth } from "../../../../../../components/Dialog/types/enums";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import useRefreshDashboardData from "../../../../../../shared/hooks/useRefreshDashboardData";
import { ApiResources } from "../../../../../../api/resources";
import { newsScrollOffset } from "../../../../../../atoms/atoms";
import { ALERT_STATUS } from "../../../../../../context/alert/types";

interface PublishNewsDialogProps {
  isDialogOpen: boolean;
  onClose: () => void;
  newsData: NewsDto | null;
}

const PublishNewsDialog = ({
  isDialogOpen,
  onClose,
  newsData,
}: PublishNewsDialogProps) => {
  const { patchData } = useAxiosPrivate();
  const { handleResponse, handleCommonError } = useResponse();
  const { refreshNewsData } = useRefreshDashboardData();
  const { t } = useTranslation();

  const [, setScrollOffsetNumber] = useRecoilState(newsScrollOffset);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await patchData(`${ApiResources.NewsItems}/${newsData?.id}/Publish`).then(
        () => {
          refreshNewsData();
        }
      );
      setScrollOffsetNumber(0);
      handleResponse(ALERT_STATUS.Success, t("Alert##news published"));
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      data-testid="publish-news-dialog"
      title={t("Dialog##publish news")}
      description={t("Dialog##publish news description")}
      width={DialogWidth.MinWidth}
      extendToMaxWidth
      isActionsSeparator
      open={isDialogOpen}
      close={onClose}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isLoading}
          isreadyToConfirm={true}
          confirmationButtonText={t("Button##accept")}
          confirmationButtonTestId="publish-news-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-publish-news"
        />
      }
    ></Dialog>
  );
};

export default PublishNewsDialog;
