import { noop } from "lodash";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import {
  GetSecretKey,
  SendOtpCode,
} from "../Dialogs/SecuritySettings/TwoFA/EnableTwoFA/content";
import { SendPassword } from "../Dialogs/SecuritySettings/TwoFA/DisableTwoFA/content";
import {
  DISABLE_AUTHENTICATION_STEPS,
  ENABLE_AUTHENTICATION_STEPS,
  StatusValuesProps,
} from "../types";

import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../api/resources";
import useResponse from "../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../context/alert/types";
import { useAuth } from "../../../../../context/Auth/AuthProvider";

interface UseTwoFAProps {
  onCloseDialog?: () => void;
}

const useTwoFA = ({ onCloseDialog }: UseTwoFAProps) => {
  const { colors } = useContext(ThemeContext);

  const { toggleTwoFaEnable } = useAuth();
  const { t } = useTranslation();
  const { postData } = useAxiosPrivate();
  const { handleResponse } = useResponse();

  const [step, setStep] = useState(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
  const [disableStep, setDisableStep] = useState(
    DISABLE_AUTHENTICATION_STEPS.Confirmation
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [secretKey, setSecretKey] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState("");

  const changeStep = (newStep: ENABLE_AUTHENTICATION_STEPS) => setStep(newStep);

  const changeDisableStep = (newStep: DISABLE_AUTHENTICATION_STEPS) =>
    setDisableStep(newStep);

  const changeOtpCode = (newValue: string) => {
    setError(null);
    setOtpCode(newValue);
  };

  const enterPassword = (newValue: string) => {
    setError(null);
    setPassword(newValue);
  };

  const handleTwoFaError = (
    error: AxiosError,
    setErr?: Dispatch<SetStateAction<string | null>>
  ) => {
    const err = error;
    const errorString = t("Password##incorrect code");

    if (err.response?.status === 400) {
      setErr ? setErr(errorString) : setError(errorString);
    } else {
      handleResponse(ALERT_STATUS.Critical, error as any);
    }
  };

  const getSecretKeySubmit = async () => {
    try {
      setIsLoading(true);

      const response = await postData(ApiResources.GenerateTwoFactorKey);

      setSecretKey(response?.data.key);
      changeStep(ENABLE_AUTHENTICATION_STEPS.SendOtpCode);
    } catch (error) {
      setSecretKey("");
      const err = error as AxiosError<any>;

      if (err) {
        const responseData: string = err.response?.data
          ? t(`Alert##${err.response?.data.message}`)
          : t("Alert##action failed");
        handleResponse(ALERT_STATUS.Critical, responseData);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sendOtpCodeSubmit = async () => {
    try {
      setIsLoading(true);

      await postData(ApiResources.EnableTwofactor, {
        otpCode,
      });

      toggleTwoFaEnable(true);

      changeStep(ENABLE_AUTHENTICATION_STEPS.SuccessMessage);
    } catch (error) {
      handleTwoFaError(error as AxiosError<any>);
    }

    setIsLoading(false);
  };

  const sendPasswordSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await postData(ApiResources.DisableTwofactor, {
        password,
      });

      toggleTwoFaEnable(false);

      onCloseDialog && onCloseDialog();
      handleResponse(
        ALERT_STATUS.Success,
        response?.data.message || t("Password##disabled two FA")
      );
    } catch (error) {
      const err = error as AxiosError<any>;

      if (err.response?.status === 400) {
        setError(t("Password##incorrect password"));
      } else {
        if (err) {
          const responseData: string = err.response?.data
            ? t(`Alert##${err.response?.data.message}`)
            : t("Alert##action failed");
          handleResponse(ALERT_STATUS.Critical, responseData);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const twoFAsteps = {
    [ENABLE_AUTHENTICATION_STEPS.GetSecretKey]: {
      hasSeparators: true,
      description: t("Password##generate key description"),
      cancelBtn: t("Button##cancel"),
      submitBtn: t("Button##continue"),
      isSubmitBtnDisabled: false,
      content: <GetSecretKey />,
      onSubmit: getSecretKeySubmit,
    },
    [ENABLE_AUTHENTICATION_STEPS.SendOtpCode]: {
      hasSeparators: true,
      description: "",
      cancelBtn: t("Button##cancel"),
      submitBtn: t("Button##verify"),
      isSubmitBtnDisabled: otpCode.length < 6,
      content: (
        <SendOtpCode
          secretKey={secretKey}
          error={error}
          isLoading={isLoading}
          changeCode={changeOtpCode}
        />
      ),
      onSubmit: sendOtpCodeSubmit,
    },
    [ENABLE_AUTHENTICATION_STEPS.SuccessMessage]: {
      hasSeparators: false,
      description: t("Password##success description"),
      cancelBtn: t("Button##close"),
      submitBtn: null,
      isSubmitBtnDisabled: false,
      content: null,
      onSubmit: noop,
    },
  };

  const disableTwoFASteps = {
    [DISABLE_AUTHENTICATION_STEPS.Confirmation]: {
      hasActionSeparator: false,
      title: t("Password##are you sure"),
      description: t("Password##disable description confirmation"),
      submitBtn: t("Button##yes"),
      isSubmitBtnDisabled: false,
      content: null,
      onSubmit: () =>
        changeDisableStep(DISABLE_AUTHENTICATION_STEPS.SendPassword),
    },
    [DISABLE_AUTHENTICATION_STEPS.SendPassword]: {
      hasActionSeparator: true,
      title: t("Password##two-factor authentication"),
      description: t("Password##disable description send password"),
      submitBtn: t("Button##continue"),
      isSubmitBtnDisabled: password.length === 0,
      content: (
        <SendPassword
          error={error}
          isLoading={isLoading}
          onChangePassword={enterPassword}
        />
      ),
      onSubmit: sendPasswordSubmit,
    },
  };

  const enabledStatValues: StatusValuesProps = {
    color: colors.green700,
    statusString: t("Password##enabled"),
    statusLink: t("Button##disable"),
  };

  const disabledStatValues: StatusValuesProps = {
    color: colors.red800,
    statusString: t("Password##disabled"),
    statusLink: t("Button##enable"),
  };

  return {
    twoFAsteps,
    step,
    isLoading,
    setStep,
    setSecretKey,
    setOtpCode,
    setError,
    enabledStatValues,
    disabledStatValues,
    disableTwoFASteps,
    setPassword,
    disableStep,
    setDisableStep,
    handleTwoFaError,
  };
};

export default useTwoFA;
