import { createContext } from "react";
import { Language, LANGUAGES } from "../../shared";

export interface LanguageInitialState {
  languages: Language[];
  language: LANGUAGES;
  userBrowserDateFormat: string;
  currentDateFormat: string;
}

interface LanguageContextState extends LanguageInitialState {
  setNewLanguage: (language: LANGUAGES) => void;
}

const LanguageContext = createContext({} as LanguageContextState);

export default LanguageContext;
