/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { DetailsDataType } from "../../../../../../types";
import AddCompanyCardDialog from "../../../../../../actions/dialogs/companies/AddCompanyCardDialog/AddCompanyCardDialog";
import { useDialog } from "../../../../../../../PageHeader/actions/hooks/useDialog";
import Tooltip from "../../../../../../../Tooltip/Tooltip";
import Button from "../../../../../../../Button/Button";
import { COMPANY_CARDS_LIMITS } from "../../../../../../../../Pages/Companies/types";
import { COMPANIES_ACTIONS } from "../../../../../../../../shared";
import useTutorialMode from "../../../../../../../TutorialMode/hooks/useTutorialMode";

interface AddcompanyCardLinkProps {
  detailsData: DetailsDataType;
  cardsCount: number;
}

const AddCompanyCardButton = ({
  detailsData,
  cardsCount,
}: AddcompanyCardLinkProps) => {
  const { isTutorialActive } = useTutorialMode();
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const isDisabled = cardsCount >= COMPANY_CARDS_LIMITS.MaxCompanyCardsCount;

  const handleLinkClick = () => {
    if (!isDisabled && !isTutorialActive)
      openDialog(COMPANIES_ACTIONS.CreateNewCompanyCard);
  };

  const handleCloseDialog = () => {
    closeDialog(COMPANIES_ACTIONS.CreateNewCompanyCard);
  };

  return (
    <>
      <Tooltip
        title={t(`Details##company cards limit reached`, {
          currCount: cardsCount,
          maxCount: COMPANY_CARDS_LIMITS.MaxCompanyCardsCount,
        })}
        placement="top-end"
        hasArrow
        size="medium"
        disableHoverListener={!isDisabled}
      >
        <span>
          <Button
            id="add-new-company-card-component"
            data-testid="add-new-card-button"
            color="secondary"
            onClick={handleLinkClick}
            size="tiny"
            variant="iconLeft"
            disabled={isDisabled}
            css={css(isTutorialActive && { cursor: "auto" })}
            icon={<AddOutlinedIcon css={css({ marginRight: "-2px" })} />}
          >
            {t("Company cards##add new")}
          </Button>
        </span>
      </Tooltip>

      <AddCompanyCardDialog
        open={isDialogOpen[COMPANIES_ACTIONS.CreateNewCompanyCard]}
        onClose={handleCloseDialog}
        detailsData={detailsData}
      />
    </>
  );
};

export default AddCompanyCardButton;
