/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import { useStyles } from "../../../styles/styles";
import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../../functions";
import Button from "../../../../Button/Button";
import { FilterParamsType } from "../../../../Filters/types";
import NoResultsFoundIcon from "../../../../../assets/customIcons/emptyTable/NoResultsFoundIcon";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import {
  currPage,
  filterQueryParams,
  noResultClear,
  searchQueryParams,
  tableName,
} from "../../../../../atoms/atoms";
import {
  LOCAL_STORAGE_KEYS,
  TABLE_NAMES,
  updateQueryParamsInLocalStorage,
} from "../../../../../shared";
import { SearchQueryParamsStateType } from "../../../../../atoms/types";

const NoResultsFound = () => {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useRecoilState(searchQueryParams);
  const [, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setPageToShow] = useRecoilState(currPage);
  const [, setNoResultClearCall] = useRecoilState(noResultClear);
  const currentTableName = useRecoilValue(tableName);

  const { noResultsIcon, emptyTableTitle, emptyTableDescription } = useStyles();

  const showResetButton = () => {
    switch (currentTableName) {
      case TABLE_NAMES.Users:
        return false;
      case TABLE_NAMES.Files:
        return false;
      default:
        return true;
    }
  };

  const handleResetView = () => {
    // reset search calls refreshTable api, while reset filters - calls useTableData and two api calls are executed on one click
    // to avoid this, set noResultClear state to true if search field is not emtpy.

    pageGlobalStateObjectResult(location.pathname, searchParams) &&
      setNoResultClearCall(true);

    // Then only useTableData table api is called and refreshTableData is not called inside of runSearch() in Search component:
    setSearchParams((prev: SearchQueryParamsStateType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    updateQueryParamsInLocalStorage(
      location.pathname,
      LOCAL_STORAGE_KEYS.Contains,
      null
    );

    // otherwise useTableData is executed if only filters needs to be reseted
    // clear all filters
    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );

    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(location.pathname, prev, "1")
    );
  };

  return (
    <>
      <div>
        <NoResultsFoundIcon
          css={css(noResultsIcon)}
          data-testid="no-results-icon"
        />
      </div>
      <Typography
        variant="body1"
        css={css(emptyTableTitle)}
        sx={{ color: colors.textDark }}
      >
        {t("Table##empty table##no results found##title")}
      </Typography>
      <Typography
        variant="body2"
        css={css(emptyTableDescription)}
        sx={{ color: colors.textSecondary }}
      >
        {t("Table##empty table##no results found##description")}
      </Typography>
      {showResetButton() && (
        <Button
          variant="textOnly"
          color="primary"
          size="small"
          onClick={handleResetView}
        >
          {t("Table##empty table##button##reset view")}
        </Button>
      )}
    </>
  );
};

export default NoResultsFound;
