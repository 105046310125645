import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
  currPage,
  selectedTableRows,
  tableDetails,
  tableDetailsSectionState,
  tablePageData,
} from "../../../atoms/atoms";
import { TableDetailsDataStateType } from "../../../atoms/types";
import { getPageGlobalStateObjectValue } from "../../../components/Table/functions";
import { AlertContext } from "../../../context/alert/AlertContextProvider";
import { useAuth } from "../../../context/Auth/AuthProvider";
import { deleteStoredPageParams } from "../../../shared";

const useUsersValues = () => {
  const { removeAlert } = useContext(AlertContext);
  const { setErrorMessageFromServer } = useAuth();
  const location: any = useLocation();

  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setPageToShow] = useRecoilState(currPage);
  const [, setSelectedRows] = useRecoilState(selectedTableRows);
  const [, setPageData] = useRecoilState(tablePageData);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);

  const resetAllPreviousValues = () => {
    deleteStoredPageParams();
    setTableDetails((prev: TableDetailsDataStateType | null) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    setPageToShow({
      vehiclesPage: null,
      driversPage: null,
    });
    setSelectedRows([]);
    setPageData(null);
    setIsDetailsOpen(false);
    setErrorMessageFromServer(false);
    // should remove unauthorized message if user was not authenticated previously
    removeAlert();
  };
  return { resetAllPreviousValues };
};

export default useUsersValues;
