import PageHeaderActions from "../PageHeaderActions";
import { useDialog } from "../../hooks/useDialog";
import AddDriverDialog from "../../dialogs/drivers/AddDriverDialog/AddDriverDialog";
import ImportResourceDialog from "../../dialogs/reusableDialogs/ImportResourceDialog/ImportResourceDialog";
import {
  DRIVERS_ACTIONS,
  MORE_ACTIONS_KEYS,
  ROUTES,
  TABLE_NAMES,
} from "../../../../../shared";

const DriversHeaderActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <PageHeaderActions
      route={ROUTES.Drivers}
      openDialog={openDialog}
      addItem
      moreActions={[MORE_ACTIONS_KEYS.Import]}
    >
      <>
        <AddDriverDialog
          isOpen={isDialogOpen[DRIVERS_ACTIONS.AddDriver]}
          onClose={closeDialog}
        />
        <ImportResourceDialog
          tableName={TABLE_NAMES.Drivers}
          isOpen={isDialogOpen[DRIVERS_ACTIONS.ImportDriver]}
          onClose={closeDialog}
        />
      </>
    </PageHeaderActions>
  );
};

export default DriversHeaderActions;
