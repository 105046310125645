import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import RemoveEmailRecipientDialog from "./RemoveEmailRecipientDialog";
import { EmailTransferProps } from "../../../../types";
import ListChoice from "../../../../../../../../../ListChoice/ListChoice";
import {
  COMPANIES_ACTIONS,
  SMALL_ICON_SIZE,
} from "../../../../../../../../../../shared";
import { useDialog } from "../../../../../../../../../PageHeader/actions/hooks/useDialog";

const RemoveEmailRecipient = ({
  card,
  companyId,
  onActionsMenu,
}: EmailTransferProps) => {
  const { t } = useTranslation();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleOpenDialog = () => {
    openDialog(COMPANIES_ACTIONS.RemoveEmailRecipient);
  };

  const handleCloseDialog = () => {
    onActionsMenu(null);
    closeDialog(COMPANIES_ACTIONS.RemoveEmailRecipient);
  };

  return (
    <>
      <ListChoice
        data-testid="remove-email-recipient-menu-choice"
        title={t("Details##remove")}
        onClick={handleOpenDialog}
        iconLeft={
          <DeleteOutlineRoundedIcon sx={{ fontSize: SMALL_ICON_SIZE }} />
        }
        alignIconCenter
      />
      <RemoveEmailRecipientDialog
        companyId={companyId}
        open={isDialogOpen[COMPANIES_ACTIONS.RemoveEmailRecipient]}
        onClose={handleCloseDialog}
        cardData={card}
      />
    </>
  );
};

export default RemoveEmailRecipient;
