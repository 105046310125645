import { createContext, useMemo, useState } from "react";
import { AlertInitialState, ALERT_STATUS } from "./types";

export const AlertContext = createContext<any>(null);
interface IAlertContextProviderProps {
  children: React.ReactNode;
}
const AlertContextProvider = ({ children }: IAlertContextProviderProps) => {
  const [alertMessage, setAlertMessage] = useState<AlertInitialState>({
    status: null,
    title: null,
    description: null,
  });

  const setAlert = (
    status: ALERT_STATUS,
    title: string,
    description?: string
  ) =>
    setAlertMessage({
      status: status,
      title: title,
      description: description || null,
    });

  const removeAlert = () => {
    setAlertMessage({
      status: null,
      title: null,
      description: null,
    });
  };

  const alertContextProviderValue = useMemo(
    () => ({
      setAlert,
      removeAlert,
      alertMessage,
      status: alertMessage.status,
      title: alertMessage.title,
      description: alertMessage.description
    }),
    [alertMessage]
  );

  return (
    <AlertContext.Provider value={alertContextProviderValue}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
