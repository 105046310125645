/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import useCredits from "./hooks/useCredits";
import CreditsDoughnutChart from "./components/CreditsDoughnutChart/CreditsDoughnutChart";
import useCreditsFunctions from "./hooks/useCreditsFunctions";
import ViewHistoryLink from "./components/ViewHistoryLink/ViewHistoryLink";
import CreditsLineChart from "./components/CreditsLineChart/CreditsLineChart";
import { useStyles } from "./styles";

import { creditsObjectType, CreditsStatuses } from "../types";

import CardWrapper from "../../CardWrapper/CardWrapper";
import { useStyles as dashboardStyles } from "../../styles";

import { creditsState, tableLoading } from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../shared";

const Credits = () => {
  const { isLoading } = useCredits();
  const { toSm, toLg, from1099 } = useMediaQueries();
  const { generateCreditList } = useCreditsFunctions();
  const { t } = useTranslation();

  const isTableLoading = useRecoilValue(tableLoading);
  const creditsData = useRecoilValue(creditsState);

  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);

  const { doughnutChartListBox, doughnutChartContainer } = dashboardStyles({
    toSm,
  });
  const { creditsContainer, mainCreditsInfoBlock } = useStyles({
    toLg,
    from1099,
  });

  const loading = isTableLoading || isLoading;

  useEffect(() => {
    !hovered && setHoveredElement(null);
  }, [hovered, hoveredElement]);

  const creditsObjectArray: creditsObjectType[] | null = creditsData && [
    {
      name: CreditsStatuses.Reserved,
      count: creditsData.reserved,
    },
    {
      name: CreditsStatuses.Available,
      count: creditsData.available,
    },
  ];

  return (
    <CardWrapper
      isTopSection
      cardTitle={t("Dashboard##credits")}
      isCreditsCard
      isLoading={loading}
    >
      {loading ? (
        <RectangleLoader
          width={"100%"}
          height={"100%"}
          testId="credits-card-loader"
        />
      ) : (
        creditsData && (
          <div css={css(creditsContainer)}>
            <div css={css(mainCreditsInfoBlock)}>
              <div css={css(doughnutChartContainer)}>
                <CreditsDoughnutChart
                  creditsData={creditsData}
                  setHovered={setHovered}
                  setHoveredElement={setHoveredElement}
                  creditsObjectArray={creditsObjectArray}
                />
                <div
                  css={css([
                    doughnutChartListBox,
                    {
                      justifyContent: "center",
                    },
                  ])}
                >
                  {generateCreditList(
                    creditsObjectArray,
                    hoveredElement,
                    hovered
                  )}
                </div>
              </div>
              <ViewHistoryLink creditsData={creditsData} />
            </div>
            <CreditsLineChart creditsData={creditsData} />
          </div>
        )
      )}
    </CardWrapper>
  );
};

export default Credits;
