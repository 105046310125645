/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import DetailsCardItemText from "../../components/DetailsContent/components/DetailsCardItemText";
import { useStyles as useReusableDetailsStyles } from "../../styles";
import { ExpandArrowProps } from "../../types";
import Tooltip from "../../../Tooltip/Tooltip";
import {
  getNumberFromString,
  getStringWithoutNumber,
} from "../../../../shared/functions/functions";

const useDetailsFunctions = () => {
  const { t } = useTranslation();
  const { collapseArrow } = useReusableDetailsStyles();

  const transitionTiming = "0.2s";
  const arrowRotationAngle = (isOpen: boolean) => (isOpen ? 0 : "-0.5turn");

  const renderDetailsSectionTitle = (
    index: number,
    isLoading: boolean,
    title: string,
    titleInlineAddition?: string
  ) =>
    index === 0 &&
    !isLoading && (
      <DetailsCardItemText
        textToRender={title}
        customStyle={{ marginLeft: "40px" }}
        titleInlineAddition={titleInlineAddition}
      />
    );

  const renderInlineSubtitleText = (periodString: string) => {
    // if is number in a string
    return Boolean(getNumberFromString(periodString))
      ? // display translation with dynamicaly fetched number
        t(`Details##${getStringWithoutNumber(periodString)}`, {
          count: +getNumberFromString(periodString),
        })
      : // else translate string
        t(`Schedules##repeat period##${periodString}`);
  };

  const renderExpandIcon = ({
    handleToggleCollapse,
    isOpen,
    customStyle,
    hasTooltip,
  }: ExpandArrowProps) => {
    return (
      <div
        data-testid="toggle-expand-arrow"
        css={css([collapseArrow, customStyle])}
        onClick={handleToggleCollapse}
      >
        {hasTooltip ? (
          <Tooltip
            title={t(isOpen ? "Actions##collapse" : "Actions##expand")}
            placement="top"
            size="medium"
          >
            <ExpandLessIcon
              data-testid="expand-less-icon"
              css={css({
                transition: `all ${transitionTiming} ease`,
                transform: `rotate(${arrowRotationAngle(isOpen)})`,
              })}
            />
          </Tooltip>
        ) : (
          <ExpandLessIcon
            data-testid="expand-less-icon"
            css={css({
              transition: `all ${transitionTiming} ease`,
              transform: `rotate(${arrowRotationAngle(isOpen)})`,
            })}
          />
        )}
      </div>
    );
  };

  return {
    renderInlineSubtitleText,
    renderDetailsSectionTitle,
    renderExpandIcon,
  };
};

export default useDetailsFunctions;
