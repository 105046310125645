import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = (fromMd?: boolean) =>
  ({
    header: {
      position: "sticky",
      top: "0",
      zIndex: 1,
      background: COLORS.White,
    },
    headerContent: {
      padding: "10px 24px 9px 24px",
      borderBottom: `1px solid ${COLORS.Gray200}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    navbarItemsBlock: {
      display: "flex",
      alignItems: "center",
    },
    menuButton: {
      marginLeft: "8px",
    },
    navbarControls: {
      display: "flex",
      alignItems: "center",
      marginLeft: "4px",
      "@media (max-width: 839px)": {
        marginLeft: "0",
        display: "block",
      },

      "button + button": {
        marginLeft: "4px",
        "@media (max-width: 839px)": {
          marginLeft: "0",
        },
      },
    },
    settingsDesktopMenu: {
      "& .MuiMenu-paper": {
        width: "315px",
      },
    },
    notificationsButton: {
      "&:hover": {
        background: COLORS.Gray200,
        "@media (max-width: 839px)": {
          background: COLORS.Gray100,
        },
      },

      "&:active": {
        background: COLORS.Gray300,
        "@media (max-width: 839px)": {
          background: COLORS.Gray200,
        },
      },
    },
    notificationsDesktopMenu: {
      "& .MuiMenu-paper": {
        width: "564px",
      },
    },
    settingsContentTop: {
      display: "flex",
      alignItems: "center",
      padding: "12px 16px",
    },
    settingsContentTopData: {
      display: "flex",
      flexDirection: "column",
      color: COLORS.TextDark,

      letterSpacing: "0.4px",
      wordBreak: "break-all",
    },
    companySelectorTitle: {
      maxWidth: "142px",
      textOverflow: "ellipsis",
      overflowX: "hidden",
    },
    searchCompanyDiv: {
      padding: "12px 16px",
    },
    rootCompanyDiv: {
      padding: "12px 16px 4px 16px",
    },
    companySelectorTopLogo: {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 0 4px 24px",
    },
    companySelectorTopControls: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",

      "& > button": {
        position: "absolute",
        top: "4px",
        right: "4px",
      },
    },
    childCompaniesCount: {
      color: COLORS.TextPlaceholder,
      padding: "12px 16px 4px 16px",
    },
    selectCompanyRadioGroupCSS: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      marginBottom: "14px",
      maxHeight: fromMd ? "445px" : "unset",
      overflow: "auto",

      "& > label + label": {
        marginTop: "6px",
      },
    },
    noCompaniesFoundCSS: {
      lineHeight: "24px",
      color: COLORS.TextPlaceholder,
      padding: "12px 16px",
      fontWeight: 400,
    },
  } as CSSObject);
