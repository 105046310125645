import { useTranslation } from "react-i18next";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../Button";
import Tooltip from "../../Tooltip/Tooltip";
import { useMediaQueries } from "../../../shared";

interface IInlineActionIconButtonProps {
  title: string;
  icon: React.JSX.Element;
  onButtonClick: () => any;
  isLoading?: boolean;
}

const InlineActionIconButton = ({
  title,
  icon,
  onButtonClick,
  isLoading,
}: IInlineActionIconButtonProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  return (
    <Tooltip
      disableInteractive
      disableTouchListener
      placement="top"
      size="medium"
      title={t(`Button##inline actions##${title.toLowerCase()}`)}
    >
      <Button
        data-testid={`${title.toLowerCase()}-button`}
        fullWidth={toMd}
        color={BUTTON_COLORS.PrimarySubtle}
        size={BUTTON_SIZES.Small}
        variant={toMd ? BUTTON_VARIANTS.TextOnly : BUTTON_VARIANTS.IconOnly}
        icon={icon}
        onClick={onButtonClick}
        isLoading={isLoading}
      >
        {toMd && icon}
      </Button>
    </Tooltip>
  );
};

export default InlineActionIconButton;
