import { useTranslation } from "react-i18next";

import HistoryDialogContent from "../HistoryDialogContent/HistoryDialogContent";

import { CreditsDto } from "../../../types";

import Dialog from "../../../../../../components/Dialog/Dialog";
import { useMediaQueries } from "../../../../../../shared";
import { DialogWidth } from "../../../../../../components/Dialog/types/enums";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";

interface HistoryDialogProps {
  open: boolean;
  onClose: () => void;
  creditsData: CreditsDto;
}

const HistoryDialog = ({ open, onClose, creditsData }: HistoryDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();

  return (
    <Dialog
      open={open}
      close={onClose}
      title={t("Dashboard##credits history")}
      description={t("Dialog##credits history description")}
      isTitleSeparator
      actions={
        <DialogActions
          onClose={onClose}
          cancelButtonText={t("Button##close")}
          isLoading={false}
          isreadyToConfirm
          hideAcceptButton
        />
      }
      extendToMaxWidth={toMd}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      isActionsSeparator
    >
      <HistoryDialogContent creditsData={creditsData} />
    </Dialog>
  );
};

export default HistoryDialog;
