import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../shared";

export const useStyles = (colors: ThemeColors) =>
  ({
    mainDivCSS: {
      border: `1px solid ${colors.gray200}`,
      borderRadius: "8px",
      padding: "16px",
    },
    upperRowCSS: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    fileNameAreaCSS: {
      display: "flex",
      alignItems: "center",
    },
    fileIconCSS: {
      alignSelf: "flex-start",
      color: colors.textPlaceholder,
      marginRight: "8px",
    },
  } as CSSObject);
