/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import ChangePasswordDialog from "./ChangePasswordDialog/ChangePasswordDialog";

import { useStyles } from "../../styles";

import ContentBox from "../../../components/ContentBox/ContentBox";

import TextLink from "../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../TextLink/types";

import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";

const ChangePassword = () => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenChangePasswordDialog = () => {
    setIsDialogOpen(true);
  };

  const {
    contentBoxInnerDiv,
    settingPlaceholder,
    changePswContentBox,
  } = useStyles({ colors });
  return (
    <div css={css(changePswContentBox)}>
      <ContentBox title={t("Password##password")}>
        <div css={css(contentBoxInnerDiv)}>
          <div css={css(settingPlaceholder)}>
            <Typography variant="body2">
              {t("Password##account password")}
            </Typography>
          </div>
          <TextLink
            size={TEXT_LINK_SIZES.Normal}
            onClick={handleOpenChangePasswordDialog}
            data-testid="change-password-text-link"
          >
            {t("Password##change")}
          </TextLink>
        </div>
      </ContentBox>

      <ChangePasswordDialog
        isOpen={isDialogOpen}
        closeDialog={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

export default ChangePassword;
