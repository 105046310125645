/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { SetStateAction } from "react";

import { useStyles } from "../styles";

import MoreActionsButton from "../../../../../shared/components/buttons/MoreActionsButton";
import { MenuAnchorEl, NEWS_ACTIONS } from "../../../../../shared";
import { MORE_ACTIONS_MENU_SIZES } from "../../../../../shared/components/buttons/types";
import MoreMenuChoice from "../../../../../shared/components/sharedListChoices/MoreMenuChoice/MoreMenuChoice";

interface NewsDraftActionsProps {
  handleOpenActionsMenu: (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
    stopPropagation: () => void;
  }) => void;
  handleCloseActionsMenu: () => void;
  isActionsOpen: MenuAnchorEl;
  onOpenEditDialog: () => void;
  onOpenPublishDialog: () => void;
  onOpenDeleteDialog: () => void;
}

const NewsDraftActions = ({
  handleOpenActionsMenu,
  handleCloseActionsMenu,
  isActionsOpen,
  onOpenEditDialog,
  onOpenPublishDialog,
  onOpenDeleteDialog,
}: NewsDraftActionsProps) => {
  const { newsDraftActions } = useStyles({});

  const newsActionsLIst = [
    {
      action: NEWS_ACTIONS.Publish,
      testId: "publish-news-choice",
      onButtonClick: onOpenPublishDialog,
    },
    {
      action: NEWS_ACTIONS.EditNews,
      testId: "edit-news-choice",
      onButtonClick: onOpenEditDialog,
    },
    {
      action: NEWS_ACTIONS.Delete,
      testId: "delete-news-choice",
      onButtonClick: onOpenDeleteDialog,
    },
  ];

  const generateListOfChoices = () =>
    newsActionsLIst.map((action) => (
      <MoreMenuChoice
        key={action.action}
        action={action.action}
        testId={action.testId}
        onButtonClick={action.onButtonClick}
      />
    ));

  return (
    <>
      <MoreActionsButton
        onMenuOpen={handleOpenActionsMenu}
        onCloseMenu={handleCloseActionsMenu}
        anchorEl={isActionsOpen || null}
        menuWidth={MORE_ACTIONS_MENU_SIZES.Small}
        customStyle={css(newsDraftActions)}
      >
        {generateListOfChoices()}
      </MoreActionsButton>
    </>
  );
};

export default NewsDraftActions;
