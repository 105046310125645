import { useCallback, useState } from "react";
import { Navigate } from "react-router-dom";

import AuthContainer from "../AuthContainer";
import PasswordsForm from "../PasswordsForm/PasswordsForm";
import { BUTTON_TYPE } from "../../types";
import { PasswordInputType, ROUTES } from "../../../../shared";

interface IPasswordsContainerProps {
  inputs: PasswordInputType[];
  button: BUTTON_TYPE;
  name: string;
}

const PasswordsContainer = ({
  inputs,
  button,
  name,
}: IPasswordsContainerProps) => {
  const [navigateHome, setNavigateHome] = useState<boolean>(false);

  const handleRedirection = useCallback(() => setNavigateHome(true), []);

  return navigateHome ? (
    <Navigate to={ROUTES.Home} replace />
  ) : (
    <AuthContainer formName={name}>
      <PasswordsForm
        inputs={inputs}
        button={button}
        showPswRequirements
        redirectHome={handleRedirection}
      />
    </AuthContainer>
  );
};

export default PasswordsContainer;
