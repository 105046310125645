import { useRecoilValue } from "recoil";

import VehicleFilters from "./PageFilters/VehicleFilters/VehicleFilters";
import DriverFilters from "./PageFilters/DriverFilters/DriverFilters";
import FilesFilters from "./PageFilters/FilesFilters/FilesFilters";
import { tableName } from "../../atoms/atoms";
import { TABLE_NAMES } from "../../shared";

const Filters = () => {
  const currentTableName = useRecoilValue(tableName);

  const renderFilters = () => {
    switch (currentTableName) {
      case TABLE_NAMES.Vehicles:
        return <VehicleFilters />;

      case TABLE_NAMES.Drivers:
        return <DriverFilters />;

      case TABLE_NAMES.Files:
        return <FilesFilters />;

      default:
        return null;
    }
  };

  return renderFilters();
};

export default Filters;
