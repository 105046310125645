export enum COMPANY_WINDOWS {
  InitialSupport,
  ClientCodeVerification,
  CreateChild,
  CreateNewHierarchy,
}

export enum PAYLOAD_ID_KEYS {
  LogisticsJournalId = "logisticsJournalId",
  ParentCompanyId = "parentCompanyId",
}

export enum COMPANY_TYPE {
  NewHierarchy = "new hierarchy",
  Regular = "company",
}
