import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    resourceTagDiv: {
      display: "flex",
      flexDirection: "column",
      width: "fit-content",
      marginTop: "6px",
    },
  } as CSSObject);
