import { useState } from "react";

import { Actions, DialogState } from "../types/types";
import {
  DRIVERS_ACTIONS,
  DRIVER_FILES_ACTIONS,
  VEHICLES_ACTIONS,
  VEHICLE_FILES_ACTIONS,
} from "../../../../shared";
import {
  COMPANIES_ACTIONS,
  USERS_ACTIONS,
} from "./../../../../shared/types/enums";

export const useDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<DialogState>({
    [VEHICLES_ACTIONS.AddVehicle]: false,
    [VEHICLES_ACTIONS.ImportVehicle]: false,
    [VEHICLES_ACTIONS.DeleteVehicle]: false,
    [VEHICLES_ACTIONS.AddSchedule]: false,
    [VEHICLES_ACTIONS.CreateSchedule]: false,
    [VEHICLES_ACTIONS.DeleteSchedule]: false,
    [VEHICLES_ACTIONS.EditSchedule]: false,
    [VEHICLES_ACTIONS.QuickScheduleDownload]: false,
    [VEHICLES_ACTIONS.Schedules]: false,
    [VEHICLES_ACTIONS.RemoveSchedules]: false,
    [VEHICLES_ACTIONS.DownloadStatus]: false,
    [DRIVERS_ACTIONS.AddDriver]: false,
    [DRIVERS_ACTIONS.ImportDriver]: false,
    [DRIVERS_ACTIONS.DeleteDriver]: false,
    [DRIVERS_ACTIONS.CreateSchedule]: false,
    [DRIVERS_ACTIONS.DeleteSchedule]: false,
    [DRIVERS_ACTIONS.EditSchedule]: false,
    [DRIVERS_ACTIONS.Schedules]: false,
    [DRIVERS_ACTIONS.RemoveSchedules]: false,
    [VEHICLE_FILES_ACTIONS.Download]: false,
    [VEHICLE_FILES_ACTIONS.FileSettings]: false,
    [DRIVER_FILES_ACTIONS.Download]: false,
    [DRIVER_FILES_ACTIONS.FileSettings]: false,
    [USERS_ACTIONS.InviteUser]: false,
    [USERS_ACTIONS.EditUser]: false,
    [USERS_ACTIONS.DeleteUser]: false,
    [COMPANIES_ACTIONS.AddNewEmailRecipient]: false,
    [COMPANIES_ACTIONS.EditEmailRecipient]: false,
    [COMPANIES_ACTIONS.RemoveEmailRecipient]: false,
    [COMPANIES_ACTIONS.CreateCompany]: false,
    [COMPANIES_ACTIONS.CreateNewCompanyCard]: false,
    [COMPANIES_ACTIONS.DeleteCompany]: false,
  });

  const openDialog = (action: Actions) => {
    setIsDialogOpen({ ...isDialogOpen, [action]: true });
  };

  const closeDialog = (action: Actions) =>
    setIsDialogOpen({ ...isDialogOpen, [action]: false });

  return { isDialogOpen, openDialog, closeDialog };
};
