/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import useResizeObserver from "use-resize-observer";

import FilteredValueTag from "./FilteredValueTag";
import ClearAllButton from "./ClearAllButton";
import FiltersTitle from "./FiltersTitle";
import useFilteredValues from "../hooks/useFilteredValues";
import { useStyles } from "../styles";
import { FilterParamsType } from "../types/";

import {
  getPageGlobalStateObjectValue,
  pageGlobalStateObjectResult,
} from "../../Table/functions";

import {
  currPage,
  filterQueryParams,
  filtersHeight,
} from "../../../atoms/atoms";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { Filter, useMediaQueries } from "../../../shared";
import useInitialLoading from "../../../shared/hooks/useInitialLoading";

const FilteredValues = () => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { fromMd } = useMediaQueries();

  const {
    filteredValues,
    mapThroughFiltersAndRemoveValue,
  } = useFilteredValues();

  const location = useLocation();

  const { isInitialLoading } = useInitialLoading();
  const { ref, height = 1 } = useResizeObserver();

  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setFiltersHeightValue] = useRecoilState(filtersHeight);
  const [, setPageToShow] = useRecoilState(currPage);

  const { filteredValuesDiv, filtersTabsDiv } = useStyles({
    colors,
    isLoading: isInitialLoading,
    fromMd: fromMd,
  });

  const hasFilteredValues = filteredValues.length > 0;

  useEffect(() => {
    hasFilteredValues
      ? setFiltersHeightValue(height + 16)
      : setFiltersHeightValue(0);
  }, [height, setFiltersHeightValue, hasFilteredValues]);

  const resetCurrentPage = () =>
    setPageToShow((prev: any) =>
      getPageGlobalStateObjectValue(location.pathname, prev, "1")
    );

  const handleDeleteFilterValue = (filterItem: Filter) => {
    const currentFilters = pageGlobalStateObjectResult(
      location.pathname,
      filterParams
    );

    mapThroughFiltersAndRemoveValue(currentFilters, filterItem);

    resetCurrentPage();
  };

  const handleClearAll = () => {
    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );

    resetCurrentPage();
  };

  return (
    <>
      {hasFilteredValues && (
        <div
          css={css(filteredValuesDiv)}
          data-testid="filtered-values-block"
          ref={ref}
        >
          <FiltersTitle isInitialLoading={isInitialLoading} />

          <div css={css(filtersTabsDiv)}>
            {filteredValues.map((item: any) => (
              <FilteredValueTag
                key={item.id}
                isInitialLoading={isInitialLoading}
                item={item}
                onDelete={handleDeleteFilterValue}
              />
            ))}

            <ClearAllButton
              onButtonClick={handleClearAll}
              buttonText={t("Button##clear all")}
              isInitialLoading={isInitialLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FilteredValues;
