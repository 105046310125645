import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";

import { UsersDataDto } from "../../../../../Pages/Users/types/types";
import { RenderConditionOptions, USERS_ACTIONS } from "../../../../../shared";

import {
  getEnvironment,
  replaceCamelCaseStringToSeparateWords,
} from "../../../../../shared/functions/functions";
import { USER_ROLES_RAW_DATA } from "../../../../../roles/types/enums";

const useUsersInlineActions = (isAllowedFullControlOfUsers: boolean) => {
  const { isProductionEnv } = getEnvironment();

  const [rowData, setRowData] = useState<UsersDataDto | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setIsOpen(false);
  };

  const actionTitle = USERS_ACTIONS.EditUser;

  const handleEditUser = (data: UsersDataDto) => {
    openDialog(data);
  };

  const isUserHimself = (id: number, loggedUserId: number) =>
    id === loggedUserId;

  const inlineRenderConditions = ({ data, user }: RenderConditionOptions) => {
    const supportRole = replaceCamelCaseStringToSeparateWords(
      USER_ROLES_RAW_DATA.TeltonikaSupport
    );

    const isNotSelf = !isUserHimself(data?.id, user?.id);

    if (isProductionEnv) {
      const isNotSupportUser = data?.role !== supportRole;

      return isAllowedFullControlOfUsers
        ? isNotSelf
        : isNotSelf && isNotSupportUser;
    }

    return isNotSelf;
  };

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      icon: <EditIcon />,
      clickAction: (data: UsersDataDto) => handleEditUser(data),
      renderConditions: ({ data, user }: RenderConditionOptions) =>
        inlineRenderConditions({ data, user }),
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useUsersInlineActions;
