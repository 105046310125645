import { useState } from "react";

import CompanyCardActions from "./CompanyCardActions/CompanyCardActions";
import CompanyCardContent from "./CompanyCardContent/CompanyCardContent";
import SingleDetailsAcordionItem from "../../../AccordionDetailsCard/components/SingleDetailsAcordionItem";
import { CompanyCardDto, DetailsDataType } from "../../../../../../types";
import {
  COMPANY_CARD_STATUS,
  MenuAnchorEl,
} from "../../../../../../../../shared";
import { MORE_ACTIONS_MENU_SIZES } from "../../../../../../../../shared/components/buttons/types";

interface SingleCompanyCardProps {
  totalCount: number;
  cardData: CompanyCardDto;
  index: number;
  companyData: DetailsDataType;
}

const SingleCompanyCard = ({
  totalCount,
  cardData,
  index,
  companyData,
}: SingleCompanyCardProps) => {
  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);

  return (
    <SingleDetailsAcordionItem
      title={cardData.name}
      totalCount={totalCount}
      index={index}
      collapsable
      companyCardStatus={cardData.status as COMPANY_CARD_STATUS}
      anchorEl={isActionsOpen}
      setIsActionsOpen={setIsActionsOpen}
      titleInlineActions={
        <CompanyCardActions
          cardData={cardData}
          companyData={companyData}
          onActionsMenu={setIsActionsOpen}
        />
      }
      menuWidth={MORE_ACTIONS_MENU_SIZES.Small}
    >
      <CompanyCardContent cardData={cardData} />
    </SingleDetailsAcordionItem>
  );
};

export default SingleCompanyCard;
