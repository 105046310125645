/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReactNode } from "react";

import PageHeader from "../PageHeader/PageHeader";
import { useStyles } from "../../shared";

interface ILayoutNodetailsProps {
  headerTitle: string;
  children: ReactNode;
}

const LayoutNoDetails = ({ headerTitle, children }: ILayoutNodetailsProps) => {
  const { pageLayout } = useStyles();

  return (
    <div data-testid="layout-no-details" css={css(pageLayout)}>
      <PageHeader headerTitle={headerTitle} isPageWithDetails={false}>
        {children}
      </PageHeader>
    </div>
  );
};

export default LayoutNoDetails;
