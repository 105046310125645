import { CSSObject } from "@emotion/react";

import { SEGMENTED_TOGGLE_TAB_SIZES } from "./types";

import { COLORS } from "../../shared";

export const useStyles = (
  size: SEGMENTED_TOGGLE_TAB_SIZES,
  tabWidth?: string,
  fullWidth?: boolean
) => {
  const styleOptions = {
    [SEGMENTED_TOGGLE_TAB_SIZES.Tiny]: {
      padding: "3px 4px",
      tabPadding: "2px 12px 2px 8px",
      scrollButtonLeftMargin: "-4px 4px -4px -4px",
      scrollButtonRightMargin: "-4px -4px -4px 4px",
      columnGap: "4px",
      borderRadius: "8px",
    },
    [SEGMENTED_TOGGLE_TAB_SIZES.Small]: {
      padding: "3px 4px",
      tabPadding: "2px 12px 2px 8px",
      scrollButtonLeftMargin: "-4px 4px -4px -4px",
      scrollButtonRightMargin: "-4px -4px -4px 4px",
      columnGap: "4px",
      borderRadius: "9px",
    },
    [SEGMENTED_TOGGLE_TAB_SIZES.Normal]: {
      padding: "7px 8px",
      tabPadding: "6px 12px 6px 8px",
      scrollButtonLeftMargin: "0 4px 0 0",
      scrollButtonRightMargin: "0 0 0 4px",
      columnGap: "8px",
      borderRadius: "10px",
    },
    [SEGMENTED_TOGGLE_TAB_SIZES.Large]: {
      padding: "7px 8px",
      tabPadding: "6px 12px 6px 8px",
      scrollButtonLeftMargin: "0 4px 0 0",
      scrollButtonRightMargin: "0 0 0 4px",
      columnGap: "8px",
      borderRadius: "12px",
    },
  };

  const padding = styleOptions[size].padding;
  const tabPadding = styleOptions[size].tabPadding;
  const columnGap = styleOptions[size].columnGap;
  const borderRadius = styleOptions[size].borderRadius;

  return {
    childrenCSS: {
      display: "flex",
      flexDirection: "row",
      color: COLORS.TextDark,
      background: COLORS.Gray100,
      textTransform: "none",
      fontWeight: "600",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      padding: tabPadding,
      minHeight: "unset",
      borderRadius: "6px",
      whiteSpace: "nowrap",
      maxWidth: "unset",
      minWidth: "unet",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

      "& .MuiTab-iconWrapper": {
        margin: "0 8px 0 0",
      },

      "&.Mui-selected": {
        color: COLORS.Gray700,
        background: COLORS.White,
      },

      "&:hover": {
        background: COLORS.Gray200,
      },

      "&:active": {
        background: COLORS.Gray300,
      },
    },
    mainTabsCSS: {
      display: fullWidth ? "flex" : "inline-flex",
      minHeight: "unset",
      maxWidth: "100%",
      border: `1px solid ${COLORS.Gray200}`,
      background: COLORS.Gray100,
      padding: padding,
      borderRadius: borderRadius,

      "& .MuiTabs-scroller": {
        display: "flex",
        flex: fullWidth ? "1" : "unset",
      },

      "& .MuiTabs-flexContainer": {
        display: fullWidth ? "grid" : "inline-grid",
        gridAutoFlow: "column",
        gridAutoColumns: fullWidth ? "1fr" : tabWidth || "auto",
        gridColumnGap: columnGap,
        flex: fullWidth ? "1" : "unset",
      },

      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    scrollButtonLeftMargin: {
      margin: styleOptions[size].scrollButtonLeftMargin,
    },
    scrollButtonRightMargin: {
      margin: styleOptions[size].scrollButtonRightMargin,
    },
    segmentedToggleButtons: {
      alignSelf: "center",
      boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)",
    },
  } as CSSObject;
};
