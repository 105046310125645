import { useTranslation } from "react-i18next";
import { INPUT_LENGTH } from "..";

const useDriversFormInputs = ({ ...props }: any) => {
  const { t } = useTranslation();

  const {
    setCardHolderInputError,
    setDescriptionInputError,
    setCardHolderName,
    setDriverId,
    validateInput,
    clearInputError,
    setDescriptionValue,
  } = props;

  const validateCardHolderNameLength = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length > INPUT_LENGTH.CardHolderName) {
      setCardHolderInputError(
        t("Dialog##card holder name not valid", {
          maxNumber: INPUT_LENGTH.CardHolderName,
        })
      );
    }

    if (inputValue.length < INPUT_LENGTH.MinCardHolderName) {
      setCardHolderInputError(
        t("Dialog##card holder name too short", {
          minNumber: INPUT_LENGTH.MinCardHolderName,
        })
      );
    }

    if (inputValue.length === 0) {
      setCardHolderInputError(
        t("Dialog##card holder name should not be empty")
      );
    }
  };

  const validateDescriptionLength = (e: any) => {
    const inputValue = e.target.value;
    if (inputValue.length > INPUT_LENGTH.Description) {
      setDescriptionInputError(
        t("Dialog##description not valid", {
          maxNumber: INPUT_LENGTH.Description,
        })
      );
    }
  };

  const handleCardHolderNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setCardHolderName(e.target.value);
    validateCardHolderNameLength(e);
    setCardHolderInputError(null);
  };

  const handleChangeDriverIdValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setDriverId(e.target.value);
    validateInput(e);
    clearInputError();
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setDescriptionValue(e.target.value);
    validateDescriptionLength(e);
    setDescriptionInputError(null);
  };

  return {
    validateCardHolderNameLength,
    validateDescriptionLength,
    handleCardHolderNameChange,
    handleChangeDriverIdValue,
    handleChangeDescription,
  };
};

export default useDriversFormInputs;
