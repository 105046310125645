import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useUsersValues from "./useUsersValues";
import { AUTH_ERRORS, BUTTON_TYPE, PasswordFormPayloadType } from "../types";
import axios from "../../../api/axios";
import { ApiResources } from "../../../api/resources";
import { ALERT_STATUS } from "../../../context/alert/types";
import { useAuth } from "../../../context/Auth/AuthProvider";
import LanguageContext from "../../../context/language/LanguageContext";
import { LANGUAGES, ROUTES } from "../../../shared";
import { setTokensToLocalStorage } from "../../../shared/functions/auth";
import { setStoredLanguage } from "../../../shared/functions/functions";
import useResponse from "../../../shared/hooks/useResponse";

const usePasswordsForm = () => {
  const { language } = useContext(LanguageContext);
  const { setIsAuthLoading, setTokenInfo } = useAuth();
  const { resetAllPreviousValues } = useUsersValues();
  const { handleResponse } = useResponse();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const from = ROUTES.Dashboard;

  const submitPasswordsForm = async (
    setSubmitLoading: Dispatch<SetStateAction<boolean>>,
    button: BUTTON_TYPE,
    payload: PasswordFormPayloadType,
    redirectHome: () => void,
    setAuthError?: Dispatch<SetStateAction<string>>
  ) => {
    try {
      setSubmitLoading(true);
      // RESET PASSWORD
      if (button === BUTTON_TYPE.ResetPsw) {
        await axios.post(ApiResources.ResetPassword, payload);
        handleResponse(
          ALERT_STATUS.Success,
          t("Password##reset password success")
        );
        redirectHome();
      }

      // CREATE ACCOUNT
      if (button === BUTTON_TYPE.SignUp) {
        //Checks if user invite exists
        await axios.post(ApiResources.UsersInvitationsValidate, {
          token: payload.token,
          email: payload.email,
        });

        setIsAuthLoading(true);
        const response = await axios.post(
          ApiResources.UsersCreateInvited,
          payload
        );

        setTokenInfo(response.data);
        setTokensToLocalStorage(response.data);

        i18n.changeLanguage(language);
        setStoredLanguage(language || LANGUAGES.English);

        resetAllPreviousValues();

        navigate(from, { replace: true });
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        const response: any = err.response;
        const responseDataMessage = response?.data?.message;

        if (responseDataMessage === AUTH_ERRORS.InvitationExpired) {
          setAuthError?.(responseDataMessage);
        } else {
          handleResponse(
            ALERT_STATUS.Critical,
            responseDataMessage || err.message
          );
        }
      }
    } finally {
      setSubmitLoading(false);
      if (button === BUTTON_TYPE.SignUp) {
        setIsAuthLoading(false);
      }
    }
  };
  return { submitPasswordsForm };
};

export default usePasswordsForm;
