import { useContext } from "react";

import { IReleaseFeatures, ReleaseFeaturesNames } from "../types";

import { getEnvironment } from "../../functions/functions";

import { FeatureToggles } from "../../../context/FeatureToggles/FeatureTogglesProvider";
import { useAuth } from "../../../context/Auth/AuthProvider";

export const useReleaseFeatureToggle = () => {
  const {
    features: { releaseFeatures, usersFeatures, rolesFeatures },
  } = useContext<any>(FeatureToggles);

  const { user } = useAuth();

  const { isProductionEnv, isDevelopmentEnv } = getEnvironment();

  const isReleaseFeatureEnabled = (featureName: ReleaseFeaturesNames) => {
    return releaseFeatures({ isProductionEnv, isDevelopmentEnv }).some(
      (feature: IReleaseFeatures) => {
        if (feature.name === featureName) return feature.enabled;

        return false;
      }
    );
  };

  const isReleaseFeatureEnabledForUser = (
    featureName: ReleaseFeaturesNames,
    userEmail: string
  ) => {
    return releaseFeatures({ isProductionEnv, isDevelopmentEnv }).some(
      (feature: IReleaseFeatures) => {
        if (feature.name === featureName && feature.enabled) {
          if (usersFeatures[featureName].includes(userEmail)) {
            return true;
          }
        }

        return false;
      }
    );
  };

  const isReleaseFeatureEnabledForRole = (
    featureName: ReleaseFeaturesNames,
    userRole: string
  ) => {
    return releaseFeatures({ isProductionEnv, isDevelopmentEnv }).some(
      (feature: IReleaseFeatures) => {
        if (feature.name === featureName && feature.enabled) {
          if (rolesFeatures[featureName]?.includes(userRole)) {
            return true;
          }
        }

        return false;
      }
    );
  };

  const isReleaseFeatureEnabledFromBE = (featureName: ReleaseFeaturesNames) => {
    return user ? user[featureName] : false;
  };

  return {
    isReleaseFeatureEnabled,
    isReleaseFeatureEnabledForUser,
    isReleaseFeatureEnabledForRole,
    isReleaseFeatureEnabledFromBE,
  };
};
