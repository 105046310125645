const TachographAvailableIcon = (props: any) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.00024"
        y="4"
        width="16"
        height="16"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M19.5 4.50049L4.5 19.5005" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};

export default TachographAvailableIcon;
