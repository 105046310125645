/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  MoreVertRounded,
  PauseRounded,
  PlayArrowRounded,
} from "@mui/icons-material";

import ConfirmDeleteDialog from "../../ConfirmDeleteDialog/ConfirmDeleteDialog";
import EditScheduleDialog from "../../EditScheduleDialog/EditScheduleDialog";
import { useStyles } from "../../../styles";
import { SCHEDULES_NAMES, ScheduleDataDto } from "../../../types";

import Tooltip from "../../../../Tooltip/Tooltip";
import Button from "../../../../Button/Button";
import useTableItemDetails from "../../../../DetailsSection/hooks/useTableItemdetails";
import { useDialog } from "../../../../PageHeader/actions/hooks/useDialog";
import { MIN_CHAR_LENGTH_IN_SEARCH } from "../../../../Search/constants";
import { pageGlobalStateObjectResult } from "../../../../Table/functions";
import Menu from "../../../../Menu/Menu";
import ListChoice from "../../../../ListChoice/ListChoice";

import { tableDetails } from "../../../../../atoms/atoms";
import {
  DRIVERS_ACTIONS,
  MenuAnchorEl,
  SMALL_ICON_SIZE,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
  getSearchQueryParamsFromLocalStorage,
  tableNameParams,
} from "../../../../../shared";
import { refreshTableAndUpdateDetails } from "../../../../../shared/functions/functions";
import useResponse from "../../../../../shared/hooks/useResponse";

interface ScheduleCardActionsProps {
  resourceName: TABLE_NAMES;
  scheduleName: string;
  scheduleId: string | null;
  scheduleData: ScheduleDataDto | null;
  getSchedulesData: () => Promise<void>;
}

const ScheduleCardActions = ({
  resourceName,
  scheduleName,
  scheduleId,
  scheduleData,
  getSchedulesData,
}: ScheduleCardActionsProps) => {
  const { t } = useTranslation();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const location = useLocation();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();

  const detailsSectionData = useRecoilValue(tableDetails);

  const [anchorEl, setAnchorEl] = useState<MenuAnchorEl>(null);

  const { scheduleCardButtons } = useStyles();

  const resourceParams = tableNameParams[resourceName];

  const { actionDelete, actionEdit } = resourceParams;

  const isGlobalSchedule = scheduleName === SCHEDULES_NAMES.GlobalSchedule;

  const handleActionButton = (
    buttonName: VEHICLES_ACTIONS | DRIVERS_ACTIONS
  ) => {
    openDialog?.(buttonName);
    handleCloseMenu();
  };

  const handleContentRefresh = async (
    dialogName: VEHICLES_ACTIONS | DRIVERS_ACTIONS,
    successMessage: string
  ) => {
    const detailsData = pageGlobalStateObjectResult(
      location.pathname,
      detailsSectionData
    );

    const valueInSearchField =
      getSearchQueryParamsFromLocalStorage(location.pathname)?.length >=
      MIN_CHAR_LENGTH_IN_SEARCH
        ? getSearchQueryParamsFromLocalStorage(location.pathname)
        : null;

    const currentTableDetailsSchedules = detailsData?.schedules.map(
      (schedule: any) => schedule.scheduleId
    );

    const itemHasOpenedDetails = currentTableDetailsSchedules?.includes(
      scheduleId
    );

    await refreshTableAndUpdateDetails({
      valueInSearchField,
      resourceId: detailsData?.id,
      successMessage,
      itemHasOpenedDetails,
      getTableItemDetails,
      handleResponse,
    });

    await getSchedulesData();

    closeDialog(dialogName);
  };

  const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <>
      <div css={css(scheduleCardButtons)}>
        {isGlobalSchedule && !scheduleId && (
          <Tooltip
            title={t("Button##edit")}
            size="medium"
            placement="top"
            hasArrow
          >
            <Button
              data-testid="edit-schedule-button"
              variant="iconOnly"
              size="tiny"
              color="white"
              onClick={() => handleActionButton(actionEdit)}
              icon={<EditRoundedIcon />}
            />
          </Tooltip>
        )}

        {scheduleId && (
          <Tooltip
            title={t("Button##actions")}
            size="medium"
            placement="top"
            hasArrow
          >
            <Button
              data-testid="schedule-actions-button"
              variant="iconOnly"
              size="tiny"
              color="white"
              onClick={handleOpenMenu}
              icon={<MoreVertRounded />}
            />
          </Tooltip>
        )}

        <Menu
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          MenuListProps={{ disableListWrap: true }}
          css={css({
            "& .MuiMenu-paper": {
              width: "179px",
            },
          })}
        >
          {scheduleData?.paused ? (
            <ListChoice
              data-testid="resume-schedule-option"
              title={t("Button##resume")}
              disabled
              onClick={() => console.log("resume button clicked")}
              iconLeft={<PlayArrowRounded sx={{ fontSize: SMALL_ICON_SIZE }} />}
              alignIconCenter
            />
          ) : (
            <ListChoice
              data-testid="pause-schedule-option"
              disabled
              title={t("Button##pause")}
              onClick={() => console.log("pause button clicked")}
              iconLeft={<PauseRounded sx={{ fontSize: SMALL_ICON_SIZE }} />}
              alignIconCenter
            />
          )}

          <ListChoice
            data-testid="edit-schedule-option"
            title={t("Button##edit")}
            onClick={() => handleActionButton(actionEdit)}
            iconLeft={<EditRoundedIcon sx={{ fontSize: SMALL_ICON_SIZE }} />}
            alignIconCenter
          />
          {!isGlobalSchedule && (
            <ListChoice
              data-testid="delete-schedule-option"
              title={t("Button##delete")}
              onClick={() => handleActionButton(actionDelete)}
              iconLeft={
                <DeleteRoundedIcon sx={{ fontSize: SMALL_ICON_SIZE }} />
              }
              alignIconCenter
            />
          )}
        </Menu>
      </div>
      {scheduleId && (
        <ConfirmDeleteDialog
          resourceParams={resourceParams}
          scheduleId={scheduleId}
          scheduleName={scheduleName}
          isOpen={isDialogOpen[actionDelete]}
          onClose={closeDialog}
          handleContentRefresh={handleContentRefresh}
        />
      )}

      {isDialogOpen[actionEdit] && (
        <EditScheduleDialog
          resourceParams={resourceParams}
          isOpen={isDialogOpen[actionEdit]}
          isGlobalSchedule={isGlobalSchedule}
          scheduleData={scheduleData}
          onClose={closeDialog}
          handleContentRefresh={handleContentRefresh}
        />
      )}
    </>
  );
};

export default ScheduleCardActions;
