import { useContext } from "react";
import moment, { Moment, unitOfTime } from "moment-timezone";

import { DATE_FORMATS, DateType, LANGUAGES } from "../types";
import { TimeZoneContext } from "../../context/TimeZone/TimeZoneProvider";
import LanguageContext from "../../context/language/LanguageContext";

export const useDateTime = () => {
  const { currentDateFormat, userBrowserDateFormat } = useContext(
    LanguageContext
  );

  const {
    systemTimeZone,
    currentTimeZone,
    revertToSystemTimeZone,
  } = useContext(TimeZoneContext);

  const currentTimeFormatHoursMinutes = `${currentDateFormat} ${DATE_FORMATS.HoursMinutes}`;
  const defaultDetailsTimeStampFormat = `${currentDateFormat} ${DATE_FORMATS.HoursMinutesSeconds}`;

  const formattedDate = (
    date: DateType,
    format: string = defaultDetailsTimeStampFormat
  ) => {
    if (date !== "undefined") {
      if (date === null || date === "-") {
        return "-";
      }

      return moment
        .utc(date)
        .tz(currentTimeZone)
        .format(format);
    }
  };

  const formatDateToEnString = (date?: string) => {
    return (
      date &&
      moment(date)
        .locale(LANGUAGES.English)
        .format(DATE_FORMATS.EnUsString)
    );
  };

  const startOfTime = (period: string) =>
    moment().startOf(period as unitOfTime.StartOf);

  const isValidDateFormat = (input: string, format: DATE_FORMATS) =>
    moment(input, format, true).isValid();

  const convertDateToUTC = (input: string | Moment | undefined) =>
    moment(input, DATE_FORMATS.ISO_8601)
      .utc()
      .format();

  const convertToCurrentTimezone = (input: string | null) =>
    moment.utc(input, DATE_FORMATS.ISO_8601).tz(currentTimeZone);

  const subtractDaysFromDate = (
    days: number,
    date: DateType,
    format?: string
  ) => {
    const dateObj = moment
      .utc(date)
      .tz(currentTimeZone)
      .subtract(days, "days");

    return format ? dateObj.format(format) : dateObj;
  };

  const getUTCDate = (date?: string) => moment.utc(date);

  const convertToCurrentDateFormat = (dateInput: string | Moment) => {
    return moment(dateInput).format(currentDateFormat);
  };

  return {
    currentTimeZone,
    currentDateFormat,
    userBrowserDateFormat,
    currentTimeFormatHoursMinutes,
    systemTimeZone,
    convertDateToUTC,
    getUTCDate,
    convertToCurrentTimezone,
    convertToCurrentDateFormat,
    formattedDate,
    startOfTime,
    isValidDateFormat,
    revertToSystemTimeZone,
    subtractDaysFromDate,
    formatDateToEnString,
  };
};
