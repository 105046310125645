/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SharedFilters from "../SharedFilters";

import { useStyles } from "../../styles";
import { TABLE_NAMES } from "../../../../shared";

const VehicleFilters = () => {
  const { filtersMainBlock } = useStyles({});

  return (
    <div css={css(filtersMainBlock)} data-testid="vehicle-filters">
      <SharedFilters tableName={TABLE_NAMES.Vehicles} />
    </div>
  );
};

export default VehicleFilters;
