export enum detailsAndDrawerLayoutState {
  BothOpen = "bothOpen",
  DetailsOnly = "detailsOnly",
  DrawerOnly = "drawerOnly",
  BothClosed = "bothClosed",
}

export enum lastActivityState {
  BreakOrRest = "BreakOrRest",
  Available = "Available",
  Work = "Work",
  Drive = "Drive",
  Unknown4 = "Unknown4",
  Unknown5 = "Unknown5",
  NotAvailable = "NotAvailable",
}
