/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, Fragment } from "react";
import { SystemStyleObject, Theme } from "@mui/system";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/ErrorRounded";
import { Typography } from "@mui/material";

import { useStyles } from "./styles";
import {
  inputPropsStyleOverrides,
  textFieldStyleOverrides,
} from "./styleConstants";
import { InputFieldProps } from "./types";

import OptionalTooltip from "../Tooltip/OptionalTooltip";
import Label from "../Label/Label";
import { muiOutlinedInputDefaultStylesOverrides } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const InputField = ({
  size,
  labelLeft,
  labelIcon,
  labelRight,
  iconLeft,
  iconRight,
  readOnly,
  errorText,
  customStyle,
  customInputStyle,
  textRef,
  testId,
  tooltipText,
  labelAddition,
  ...props
}: InputFieldProps & Omit<TextFieldProps, "size">) => {
  const { colors } = useContext(ThemeContext);

  const { errorIcon } = useStyles();

  const paddings = {
    tiny: "2px 12px",
    small: "8px 12px",
    medium: "12px",
  };

  const padding = paddings[size];

  const repeatedStyles = muiOutlinedInputDefaultStylesOverrides(colors);
  const customInputStyleExists = customInputStyle
    ? { input: customInputStyle }
    : {};

  return (
    <div
      css={css({
        display: "inline-flex",
        flexDirection: "column",
        width: props.fullWidth ? "100%" : "unset",
        ...customStyle,
      })}
    >
      {(labelLeft || labelRight) && (
        <div css={css({ display: "flex" })}>
          <Label
            titleLeft={labelLeft}
            titleRight={labelRight}
            titleTooltip={labelIcon}
            isRequired={props.required}
          />
          {/* empty space: */}
          {labelAddition && <Typography variant="body2">&nbsp;</Typography>}
          {labelAddition || null}
        </div>
      )}

      <OptionalTooltip
        tooltipPlacement="top"
        tooltipTitle={tooltipText ?? ""}
        isDisabled={!tooltipText}
      >
        <TextField
          data-testid={testId}
          inputRef={textRef}
          {...props}
          error={Boolean(errorText)}
          autoComplete="off"
          helperText={
            errorText ? (
              <Fragment>
                <ErrorIcon data-testid="error-icon" css={css(errorIcon)} />
                {errorText}
              </Fragment>
            ) : (
              ""
            )
          }
          InputProps={{
            ...props.InputProps,
            spellCheck: "false",
            readOnly,
            ...(iconLeft && {
              startAdornment: (
                <InputAdornment position="start">{iconLeft}</InputAdornment>
              ),
            }),
            ...(iconRight && {
              endAdornment: (
                <InputAdornment position="end">{iconRight}</InputAdornment>
              ),
            }),
            sx: [
              {
                background:
                  props.disabled || readOnly ? colors.gray100 : colors.white,
                padding,
              },
              inputPropsStyleOverrides,
              repeatedStyles,
              customInputStyleExists as SystemStyleObject<Theme>,
            ],
          }}
          sx={textFieldStyleOverrides}
        />
      </OptionalTooltip>
    </div>
  );
};

export default InputField;
