import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import EmailRecipientDialogContent from "../EmailRecipientDialogContent";
import { NewRecipientPayload } from "../../types";
import useCompaniesTabDetails from "../../../../hooks/useCompaniesTabDetails";
import Dialog from "../../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../../Dialog/types/enums";
import DialogActions from "../../../../../../../../shared/components/buttons/DialogActions";
import { useMediaQueries } from "../../../../../../../../shared";
import useAxiosPrivate from "../../../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../../../shared/hooks/useResponse";
import { ApiResources } from "../../../../../../../../api/resources";
import { tableDetailsLoading } from "../../../../../../../../atoms/atoms";
import { ALERT_STATUS } from "../../../../../../../../context/alert/types";

interface AddNewEmailRecipientDialogProps {
  companyId: string;
  isOpen: boolean;
  handleClose: () => void;
}

const AddNewEmailRecipientDialog = ({
  companyId,
  isOpen,
  handleClose,
}: AddNewEmailRecipientDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { postData } = useAxiosPrivate();
  const { handleErrorWithEntries, handleResponse } = useResponse();
  const { getTabDetailsData } = useCompaniesTabDetails();

  const [, setIsDetailsLoading] = useRecoilState(tableDetailsLoading);

  const initialPayloadState = {
    companyId,
    email: "",
    fileFormat: null,
  };

  const [payload, setPayload] = useState<NewRecipientPayload>(
    initialPayloadState
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);

        await postData(ApiResources.EmailRecipients, payload);
        handleResponse(
          ALERT_STATUS.Success,
          t("Alert##email transfer new recipient success")
        );

        setIsDetailsLoading(true);
        await getTabDetailsData(companyId, ApiResources.EmailRecipients);
        setIsDetailsLoading(false);
      } catch (error) {
        handleErrorWithEntries(error, t);
      }
    }

    setIsLoading(false);
  };

  return (
    <Dialog
      data-testid="add-new-recipient-dialog"
      title={t("Details##add new email recipient##title")}
      open={isOpen}
      isTitleSeparator
      isActionsSeparator
      extendToMaxWidth={toMd}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={handleClose}
      submit={handleSubmit}
      TransitionProps={{
        onExited: () => setPayload(initialPayloadState),
      }}
      actions={
        <DialogActions
          onClose={handleClose}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##add")}
          confirmationButtonTestId="add-new-recipient-dialog-add-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="add-new-recipient-dialog-close-button"
        />
      }
    >
      <EmailRecipientDialogContent
        isLoading={isLoading}
        payload={payload}
        setIsReady={setIsReady}
        setPayload={setPayload}
      />
    </Dialog>
  );
};

export default AddNewEmailRecipientDialog;
