import { useRecoilValue } from "recoil";
import { tableName } from "../../../atoms/atoms";
import {
  COLUMN_NAMES,
  Column,
  LOCAL_STORAGE_KEYS,
  TABLE_NAMES,
} from "../../../shared";
import { setItemToLocalStorage } from "../../../shared/functions/functions";

const useColumnsToShowFunctions = (
  currTableColumns: Column[],
  setCurrTableColumns: (
    valOrUpdater: Column[] | ((currVal: Column[]) => Column[])
  ) => void
) => {
  const currentTableName = useRecoilValue<TABLE_NAMES | undefined>(tableName);

  const areDefaultColumns = currTableColumns.every(
    (column: any) => Boolean(column.isDefaultActive) === column.isActive
  );

  const toggleActiveColumn = (name: COLUMN_NAMES) => {
    const currentColumns = [...currTableColumns];

    const updatedColumns = currentColumns.map((column: Column) =>
      column.name === name ? { ...column, isActive: !column.isActive } : column
    );

    const setToLocalStorage = () => {
      type StorageColumns = {
        [key: string]: boolean;
      };
      const storageColumns: StorageColumns = {};

      updatedColumns.forEach(
        (column) => (storageColumns[column.name] = Boolean(column.isActive))
      );

      setItemToLocalStorage(
        `${LOCAL_STORAGE_KEYS.ActiveTableColumns}-${currentTableName}`,
        JSON.stringify(storageColumns)
      );
    };

    setToLocalStorage();
    setCurrTableColumns(updatedColumns);
  };

  const restoreActiveColumns = () => {
    const updatedColumns = [...currTableColumns].map((column) => ({
      ...column,
      isActive: Boolean(column.isDefaultActive),
    }));

    localStorage.removeItem(
      `${LOCAL_STORAGE_KEYS.ActiveTableColumns}-${currentTableName}`
    );

    setCurrTableColumns(updatedColumns);
  };

  return { areDefaultColumns, toggleActiveColumn, restoreActiveColumns };
};

export default useColumnsToShowFunctions;
