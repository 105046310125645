import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = () =>
  ({
    authGrid: {
      flex: 1,
    },
    itemGrid: {
      "@media (max-width:839px)": {
        flex: "1",
      },
    },
    singleItemGrid: {
      "@media (max-width: 1439px)": {
        flex: "1",
      },
    },
    logo: {
      display: "flex",
      padding: "14px 24px",

      "& > svg": {
        height: "24px",
      },

      "@media (max-width:599px)": {
        padding: "14px 16px",
      },
    },
    loginContentContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "@media (max-width:1439px)": {
        padding: "64px 0",
      },

      "@media (max-width:839px)": {
        padding: "144px 0",
      },

      "@media (max-width:599px)": {
        padding: "64px 16px",
      },
    },
    innerLoginContentContainer: {
      width: "62%",

      "@media (max-width:839px)": {
        width: "68%",
      },

      "@media (max-width:599px)": {
        width: "100%",
      },
    },
    loginContentHeaders: {
      marginBottom: "32px",
      color: COLORS.TextDark,
    },
    togglePasswordVisibilitySpan: {
      display: "flex",
      cursor: "pointer",
      color: COLORS.Gray500,
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

      "&:hover": {
        color: COLORS.Gray600,
      },
    },
    contactsOuterDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      columnGap: "16px",
      marginTop: "16px",
    },
    privacyPolicyDiv: {
      color: COLORS.TextDark,
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "12px",
      letterSpacing: "0.4px",
      maxWidth: "208px",
    },
    contactIconsDiv: {
      display: "flex",
      alignItems: "center",

      "& > a + a": {
        marginLeft: "16px",
      },
    },
    blockWithBottomSpacing: {
      marginBottom: "16px",
    },
    passwordValidationBlock: {
      "& > div": {
        display: "flex",
        alignItems: "center",
      },

      "& > div + div": {
        marginTop: "4px",
      },
    },
    termsOfServiceCSS: {
      paddingBottom: "16px",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
  } as CSSObject);
