import CompanySelectorContent from "../CompanySelectorContent/CompanySelectorContent";
import DialogCustom from "../../../../../DialogCustom/DialogCustom";
import { CompaniesDto } from "../../../../../../shared";

interface IMobileCompanySelectorProps {
  isOpen: boolean;
  onClose: () => void;
  companies: CompaniesDto[];
}

const MobileCompanySelector = ({
  isOpen,
  onClose,
  companies,
}: IMobileCompanySelectorProps) => {
  return (
    <DialogCustom
      noPadding
      open={isOpen}
      close={onClose}
      data-testid="mobile-company-selector"
    >
      <CompanySelectorContent close={onClose} companies={companies} />
    </DialogCustom>
  );
};

export default MobileCompanySelector;
