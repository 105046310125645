/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React, { useContext } from "react";
import { useRecoilValue } from "recoil";

import MobileTableHead from "./components/MobileTableHead";
import MobileTableItem from "./components/MobileTableItem";
import { useStyles } from "../../styles/styles";
import {
  filtersHeight,
  tableData,
  tableHeadActivity,
  tableLoading,
} from "../../../../atoms/atoms";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  DESKTOP_TABLE_FOOTER_HEIGHT,
  TABLE_TOOLBAR_HEIGHT,
  MOBILE_TABLE_TOOLBAR_AND_SEARCH_HEIGHT,
  TableRowData,
} from "../../../../shared";

interface IMobileTableProps {
  hasDetails: boolean;
  toMd: boolean;
  toLg: boolean;
  hasTableView?: boolean;
  hasTabFilters?: boolean;
  hasSearch?: boolean;
}

const MobileTable = ({
  hasDetails,
  toMd,
  toLg,
  hasTableView,
  hasTabFilters,
  hasSearch = true,
}: IMobileTableProps) => {
  const { colors } = useContext(ThemeContext);

  const isLoading = useRecoilValue(tableLoading);
  const isTableHeadActive = useRecoilValue(tableHeadActivity);
  const tableRows = useRecoilValue<TableRowData[]>(tableData);
  const filtersHeightValue = useRecoilValue(filtersHeight);

  const { mobileTableItemBox } = useStyles();

  return (
    <div
      data-testid="mobile-table"
      css={css([
        {
          position: "absolute",
          top: hasTabFilters
            ? `${MOBILE_TABLE_TOOLBAR_AND_SEARCH_HEIGHT(toMd)}`
            : `${TABLE_TOOLBAR_HEIGHT(
                toMd,
                hasSearch,
                hasTableView,
                filtersHeightValue
              )}`,
          left: "0",
          right: "0",
          bottom: DESKTOP_TABLE_FOOTER_HEIGHT(toLg),
          overflow: "auto",
        },
      ])}
    >
      <MobileTableHead />
      <div
        css={css([
          {
            background: colors.gray100,
            opacity: isLoading ? "0.4" : "1",
            transition: "opacity 300ms ease-out",
          },
          mobileTableItemBox,
        ])}
      >
        {tableRows &&
          tableRows.map((row: TableRowData, i: number) => (
            <MobileTableItem
              key={row.id}
              row={row}
              isTableHeadActive={isTableHeadActive}
              index={i}
              isFirstItem={i === 0}
              isLastItem={i === tableRows.length - 1}
              hasDetails={hasDetails}
            />
          ))}
      </div>
    </div>
  );
};

export default React.memo(MobileTable);
