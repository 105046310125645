import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../shared";

interface UseStylesProps {
  colors: ThemeColors;
}

export const useStyles = ({ colors }: UseStylesProps) =>
  ({
    sharedToolbarActionMenu: {
      "& .MuiMenu-paper": {
        maxHeight: "337px",
        minWidth: "211px",
      },
      ".action-disabled": {
        color: colors.textDarkDisabled,
        "&:hover": {
          background: "unset",
        },
      },
    },
    sharedToolbarActionListChoice: {
      background: colors.white,
      color: colors.blue700,
    },
  } as CSSObject);
