import { createContext, useMemo } from "react";

import { ThemeColors } from "../../shared";
import { ALL_COLORS } from "../../shared/theme/constants";

interface IThemeContextProviderProps {
  children: React.ReactNode;
}

export interface ThemeContextState {
  colors: ThemeColors;
}

export const ThemeContext = createContext({} as ThemeContextState);

const ThemeContextProvider = ({ children }: IThemeContextProviderProps) => {
  const colors = ALL_COLORS;

  const themeContextProviderValue = useMemo(
    () => ({
      colors: colors,
    }),
    [colors]
  );

  return (
    <ThemeContext.Provider value={themeContextProviderValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
