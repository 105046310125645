import PageHeaderActions from "../PageHeaderActions";
import CreateCompanyDialog from "../../dialogs/companies/CreateCompanyDialog/CreateCompanyDialog";
import { useDialog } from "../../hooks/useDialog";
import { COMPANIES_ACTIONS, ROUTES } from "../../../../../shared";

const CompaniesHeaderActions = () => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  return (
    <PageHeaderActions route={ROUTES.Companies} openDialog={openDialog} addItem>
      <CreateCompanyDialog
        isOpen={isDialogOpen[COMPANIES_ACTIONS.CreateCompany]}
        onClose={closeDialog}
      />
    </PageHeaderActions>
  );
};

export default CompaniesHeaderActions;
