import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../types";

export const useStyles = (colors?: ThemeColors, ...props: any) =>
  ({
    body2: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    body2bold: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    body3regular: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",

      "& .MuiTypography-root": {
        letterSpacing: "0.4px",
      },
    },
    body3semiBold: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "0.4px",
      "& .MuiTypography-root": {
        letterSpacing: "0.4px",
      },
    },
    body4semibold: {
      fontSize: "10px",
      fontWeight: 600,
      lineHeight: "12px",
      letterSpacing: "1px",
    },
    logo: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    pageLayout: {
      display: "flex",
      width: `100%`,
    },
    flexbox: {
      display: "flex",
      alignItems: "center",
    },
    verticalSeparator: {
      alignSelf: "stretch",
      flexShrink: "0",
      width: "1px",
      background: colors?.gray100,
      margin: props[0]?.separatorMargin || "0 16px",
    },
    customTab: {
      textTransform: "none",
      minHeight: "44px",
      minWidth: "164px",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
      color: colors?.textPlaceholder,
      fontWeight: 600,

      "@media(max-width: 839px)": {
        flex: "1",
      },
      "@media(max-width: 599px)": {
        minWidth: "164px",
      },

      "&.Mui-selected": {
        color: colors?.textDark,
      },
      "&:hover": {
        background: colors?.gray50,
        boxShadow: `inset 0px -2px 0px ${colors?.gray300}`,
      },
    },
    tabsHolder: { minHeight: "44px" },
  } as CSSObject);
