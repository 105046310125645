import { COLORS } from "../..";

export const useStyles = () => ({
  closeButton: {
    color: COLORS.TextDark,
  },
  listChoice: {
    background: COLORS.White,
    color: COLORS.Blue700,
  },
  settingsButton: {
    "&:hover": {
      background: COLORS.Gray100,
    },
    "&:active": {
      backGround: COLORS.Gray200,
    },
  },
});
