import { useTranslation } from "react-i18next";
import { useContext } from "react";

import useTwoFA from "../../../../hooks/useTwoFA";
import { ENABLE_AUTHENTICATION_STEPS } from "../../../../types";

import Dialog from "../../../../../../../Dialog/Dialog";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../../../../Button/Button";
import { DialogWidth } from "../../../../../../../Dialog/types/enums";

import { useAuth } from "../../../../../../../../context/Auth/AuthProvider";
import { useReleaseFeatureToggle } from "../../../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../../../shared/featureToggles/types";
import { AlertContext } from "../../../../../../../../context/alert/AlertContextProvider";
import { ALERT_STATUS } from "../../../../../../../../context/alert/types";

interface EnableTwoFAProps {
  onCloseDialog: () => void;
  isOpen: boolean;
}

const EnableTwoFA = ({ onCloseDialog, isOpen }: EnableTwoFAProps) => {
  const { setAlert } = useContext(AlertContext);
  const { user } = useAuth();
  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();
  const {
    twoFAsteps,
    step,
    isLoading,
    setStep,
    setSecretKey,
    setOtpCode,
    setError,
  } = useTwoFA({});

  const { t } = useTranslation();

  const isRoleAllowedToToggle2FA = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.ShowToggle2FA,
    user?.role
  );

  const isSuccessMessageStep =
    step === ENABLE_AUTHENTICATION_STEPS.SuccessMessage;

  const isAllowedToClose =
    isRoleAllowedToToggle2FA ||
    (!isRoleAllowedToToggle2FA && isSuccessMessageStep);

  const hasSeparators = twoFAsteps[step].hasSeparators;
  const description = twoFAsteps[step].description;
  const cancelBtnName = twoFAsteps[step].cancelBtn;
  const submitBtnName = twoFAsteps[step].submitBtn;
  const isDisabled = twoFAsteps[step].isSubmitBtnDisabled;
  const content = twoFAsteps[step].content;
  const onSubmit = twoFAsteps[step].onSubmit;

  const handleClose = () => {
    onCloseDialog();
    isSuccessMessageStep &&
      setAlert(
        ALERT_STATUS.Success,
        t("Password##two-factor authentication enabled")
      );
  };

  return (
    <Dialog
      data-testid={"enable-two-fa-dialog"}
      title={t("Password##two-factor authentication")}
      description={description}
      isTitleSeparator={hasSeparators}
      isActionsSeparator={hasSeparators}
      extendToMaxWidth
      submit={onSubmit}
      width={DialogWidth.MinWidth}
      TransitionProps={{
        onExited: () => {
          setStep(ENABLE_AUTHENTICATION_STEPS.GetSecretKey);
          setSecretKey("");
          setOtpCode("");
          setError(null);
        },
      }}
      actions={
        <>
          {isAllowedToClose && (
            <Button
              fullWidth
              color={BUTTON_COLORS.Secondary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              onClick={handleClose}
            >
              {cancelBtnName}
            </Button>
          )}
          {submitBtnName && (
            <Button
              fullWidth
              color={BUTTON_COLORS.Primary}
              size={BUTTON_SIZES.Normal}
              variant={BUTTON_VARIANTS.TextOnly}
              type="submit"
              disabled={isDisabled}
              isLoading={isLoading}
            >
              {submitBtnName}
            </Button>
          )}
        </>
      }
      close={isAllowedToClose ? handleClose : undefined}
      open={isOpen}
    >
      {content}
    </Dialog>
  );
};

export default EnableTwoFA;
