/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Tab } from "@mui/material";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Tabs from "../Tabs/Tabs";
import { TabsType } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import useTabFilters from "../../shared/hooks/useTabFilters";

interface ITabFiltersProps {
  tabs: TabsType[];
}

const TabFilters = ({ tabs }: ITabFiltersProps) => {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();

  const { t } = useTranslation();
  const { tabIndex } = useTabFilters(location.pathname);
  const navigate = useNavigate();

  const renderTabs = () =>
    tabs.map((tab: TabsType) => (
      <Tab
        key={tab.label}
        label={t(tab.label)}
        onClick={() => {
          navigate(tab.route);
        }}
      />
    ));

  return (
    <div
      css={css({
        padding: "8px 24px 0 24px",
        boxShadow: `inset 0px -1px 0px ${colors.gray200}`,
      })}
    >
      <Tabs value={tabIndex}>{renderTabs()}</Tabs>
    </div>
  );
};

export default TabFilters;
