import { CSSObject } from "@emotion/react";

interface UseStylesProps {
  fromMd?: boolean;
}

export const useStyles = ({ fromMd }: UseStylesProps) =>
  ({
    downloadStatContentContainer: {
      display: "flex",
      flexDirection: fromMd ? "row" : "column",
      width: "100%",
      justifyContent: "center",
      paddingTop: !fromMd ? "16px" : undefined,
    },
  } as CSSObject);
