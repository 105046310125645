/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Typography from "@mui/material/Typography";
import { ReactNode, useContext } from "react";

import { useStyles } from "./styles";
import { ThemeContext } from "../../../../../../../../context/theme/ThemeContextProvider";

interface ScheduleDetailsSingleRowProps {
  title: string;
  content?: string;
  dataTypes?: ReactNode;
}

const ScheduleDetailsSingleRow = ({
  title,
  content,
  dataTypes,
}: ScheduleDetailsSingleRowProps) => {
  const { colors } = useContext(ThemeContext);

  const { singleRowCSS } = useStyles();

  return (
    <div css={css(singleRowCSS)}>
      <Typography variant="body2Bold" sx={{ color: colors.textPlaceholder }}>
        {title}:
      </Typography>

      {content && (
        <Typography
          variant="body2Bold"
          sx={{ color: colors.textSecondary, wordBreak: "break-word" }}
        >
          {content}
        </Typography>
      )}

      {dataTypes}
    </div>
  );
};

export default ScheduleDetailsSingleRow;
