/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import HistoryDialog from "../HistoryDialog/HistoryDialog";

import { CreditsDto } from "../../../types";

import TextLink from "../../../../../../components/TextLink/TextLink";
import {
  TEXT_LINK_COLORS,
  TEXT_LINK_SIZES,
} from "../../../../../../components/TextLink/types";

interface ViewHistoryLinkProps {
  creditsData: CreditsDto;
}

const ViewHistoryLink = ({ creditsData }: ViewHistoryLinkProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleCloseHitoryDialog = () => {
    setOpen(false);
  };

  const handleOpenHisoryDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <TextLink
        size={TEXT_LINK_SIZES.Small}
        onClick={handleOpenHisoryDialog}
        underline="none"
        data-testid="view-history-link"
        color={TEXT_LINK_COLORS.Primary}
        css={css({ marginTop: "16px" })}
      >
        {t("Dashboard##view history")}
      </TextLink>

      <HistoryDialog
        open={open}
        onClose={handleCloseHitoryDialog}
        creditsData={creditsData}
      />
    </>
  );
};

export default ViewHistoryLink;
