/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import { NewsDto } from "..";
import { useStyles } from "../styles";
import { NEWS_SECTION } from "../types/enums";

import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import { useDateTime } from "../../../../../shared";
import Tooltip from "../../../../../components/Tooltip/Tooltip";

interface ListedNewContentProps {
  newsData: NewsDto;
  isHeightOverflowing: boolean;
  titleRef: any;
}

const ListedNewContent = ({
  newsData,
  isHeightOverflowing,
  titleRef,
}: ListedNewContentProps) => {
  const { colors } = useContext(ThemeContext);
  const { formatDateToEnString } = useDateTime();

  const { textBox } = useStyles({ colors });

  return (
    <div css={css(textBox)} data-testid="news-content">
      <Typography
        variant="body4semiBold"
        sx={{ color: colors.textPlaceholder, paddingBottom: "4px" }}
      >
        {formatDateToEnString(newsData.createdAt)}
      </Typography>

      <Tooltip
        title={newsData.title}
        disableHoverListener={
          newsData.title.length < NEWS_SECTION.maxCardTitleLength
        }
        size="medium"
        disableInteractive
      >
        <Typography
          variant="body3semiBold"
          sx={{
            color: colors.textDark,
            height: "32px",
            overflow: "hidden",
          }}
          ref={titleRef}
        >
          {isHeightOverflowing ||
          newsData.title.length > NEWS_SECTION.maxCardTitleLength
            ? `${newsData.title.slice(0, NEWS_SECTION.maxCardTitleLength)} ...`
            : newsData.title}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default ListedNewContent;
