export enum USER_ROLES_RAW_DATA {
  User = "User",
  Administrator = "Administrator",
  TeltonikaSupport = "TeltonikaSupport",
  // remove Support from the list when TeltonikaSupport is fixed everywhere in BE
  Support = "Teltonika Support",
}

//All possible permissions list from GET Users/Me "permissions" object
export enum USER_PERMISSIONS {
  ManageSchedules = "CanManageSchedules",
  ManageVehicles = "CanManageVehicles",
  ManageDrivers = "CanManageDrivers",
  DownloadFiles = "CanDownloadFiles",
  ManageCompanies = "CanManageCompanies",
  ManageUsers = "CanManageUsers",
  ManageCompanyCards = "CanManageCompanyCards",
  DistributeTokens = "CanDistributeTokens",
  ReceiveNotifications = "CanReceiveNotifications",
  ManageFtpSynchronization = "CanManageFtpSynchronization",
  DisableVehicles = "CanDisableVehicles",
  SeeCompanyHierarchy = "CanSeeCompanyHierarchy",
  EnableSampleCredits = "CanEnableSampleCredits",
  ManageApiCredentials = "CanManageApiCredentials",
  SeeCreditsDashboard = "CanSeeCreditsDashboard",
  ManageNews = "CanManageNews",
  PublishNews = "CanPublishNews",
}
