/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { Paper, Typography } from "@mui/material";
import { ReactNode, useContext } from "react";

import { useStyles } from "../../Dialogs/styles";

import InfoIconWithTooltip from "../../../../../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";

interface ContentBoxProps {
  children: ReactNode;
  title: string;
  noFlex?: boolean;
  description?: string;
  css?: SerializedStyles;
}

const ContentBox = ({
  children,
  title,
  noFlex,
  description,
  ...props
}: ContentBoxProps) => {
  const { colors } = useContext(ThemeContext);

  const { contentBoxTitleBlock, contentBoxPaper } = useStyles({
    colors,
    noFlex,
  });

  return (
    <div {...props}>
      <div css={css(contentBoxTitleBlock)}>
        <Typography variant="body1" sx={{ color: colors.textDark }}>
          {title}
        </Typography>

        {description && (
          <InfoIconWithTooltip
            title={description}
            customIconStyle={{ marginLeft: "4px" }}
          />
        )}
      </div>

      <Paper elevation={0} variant="outlined" sx={contentBoxPaper as {}}>
        {children}
      </Paper>
    </div>
  );
};

export default ContentBox;
