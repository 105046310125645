/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import { useMediaQueries } from "../../../../../../shared";
import GoBackButton from "../../../../../../shared/components/buttons/GoBackButton";
import { SETTINGS_CONTENT_NAMES } from "../../../../types/enums";

interface ISettingsControlsContentWrapperProps {
  onGoBackButton: (newContent: SETTINGS_CONTENT_NAMES) => void | any;
  children: ReactNode;
}

const SettingsControlsContentWrapper = ({
  onGoBackButton,
  children,
}: ISettingsControlsContentWrapperProps) => {
  const { toMd } = useMediaQueries();

  return (
    <div css={css({ marginTop: toMd ? "-48px" : "0" })}>
      <GoBackButton onButtonClick={onGoBackButton} />
      {children}
    </div>
  );
};

export default SettingsControlsContentWrapper;
