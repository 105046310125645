import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import EnquiryText from "../EnquiryText/EnquiryText";
import AuthContainer from "../../AuthContainer";
import Button from "../../../../../components/Button/Button";
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../../components/Button/types/enums";
import { ROUTES } from "../../../../../shared";

interface IResetSentProps {
  redirect: () => void;
}

const ResetSent = ({ redirect }: IResetSentProps) => {
  const { t } = useTranslation();

  const [toLogin, setToLogin] = useState(false);

  const handleClick = useCallback(() => setToLogin(true), []);

  return toLogin ? (
    <Navigate to={ROUTES.Home} replace />
  ) : (
    <AuthContainer
      formName={t("Auth##link sent")}
      secondaryText={t("Auth##check email")}
    >
      <Button
        data-testid="resend-psw-signIn-button"
        fullWidth
        color={BUTTON_COLORS.Primary}
        size={BUTTON_SIZES.Normal}
        variant={BUTTON_VARIANTS.TextOnly}
        type="submit"
        onClick={handleClick}
      >
        {t("Auth##sign in")}
      </Button>

      <EnquiryText
        primaryText={t("Auth##did not receive")}
        linkText={t("Auth##resend")}
        onClick={redirect}
      />
    </AuthContainer>
  );
};

export default ResetSent;
