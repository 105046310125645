/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { useStyles } from "../../../../styles";

import TextLink from "../../../../../../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../../../../TextLink/types";

export const GetSecretKey = () => {
  const { t } = useTranslation();

  const { authenticatorLinksContainer } = useStyles({});

  return (
    <div css={css(authenticatorLinksContainer)}>
      <TextLink
        size={TEXT_LINK_SIZES.Normal}
        href=" https://www.microsoft.com/en-us/security/mobile-authenticator-app"
        target="_blank"
        rel="noreferrer"
      >
        {t("Auth##microsoft authenticator")}
      </TextLink>

      <TextLink
        size={TEXT_LINK_SIZES.Normal}
        href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid"
        target="_blank"
        rel="noreferrer"
      >
        {t("Auth##google authenticator")}
      </TextLink>
    </div>
  );
};
