import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import CompanySelectorTopControls from "./components/CompanySelectorTopControls/CompanySelectorTopControls";
import SearchCompany from "./components/SearchCompany/SearchCompany";
import CompanyList from "./components/CompanyList/CompanyList";
import RootCompany from "./components/RootCompany/RootCompany";

import { CompaniesDto } from "../../../../../../shared";
import { useReleaseFeatureToggle } from "../../../../../../shared/featureToggles/hooks";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { FEATURE_NAMES } from "../../../../../../shared/featureToggles/types";

interface ICompanySelectorContentProps {
  companies: CompaniesDto[];
  close: () => void;
}

const CompanySelectorContent = ({
  companies,
  close,
}: ICompanySelectorContentProps) => {
  const { user } = useAuth();
  const { isReleaseFeatureEnabledForRole } = useReleaseFeatureToggle();

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (e: {
    target: { value: SetStateAction<string> };
  }) => setSearchValue(e.target.value);

  const clearSearchValue = () => setSearchValue("");

  const isRoleAllowedToChangeRootCompany = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.AllowedToChangeRootCompany,
    user?.role
  );

  return (
    <>
      <CompanySelectorTopControls
        close={close}
        boxPadding="16px 16px 12px 16px"
        itemName={t("Selector##company selector")}
      />
      {isRoleAllowedToChangeRootCompany && (
        <RootCompany userRootCompany={user?.selectedRootCompany} />
      )}
      <SearchCompany
        isRootSearch={isRoleAllowedToChangeRootCompany}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        clearSearchValue={clearSearchValue}
      />

      <CompanyList searchValue={searchValue} companies={companies} />
    </>
  );
};

export default CompanySelectorContent;
