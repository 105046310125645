import { useTranslation } from "react-i18next";
import { MutableRefObject, SetStateAction, useCallback } from "react";
import { useRecoilState } from "recoil";

import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../api/resources";
import useResponse from "../../../shared/hooks/useResponse";
import { initialLoading } from "../../../atoms/atoms";

export type useGetInitialDataProps = {
  setIsLoading: (value: SetStateAction<boolean>) => void;
  setDataState: any;
  resource: ApiResources | string;
  initialized: MutableRefObject<boolean>;
};

const useGetInitialData = () => {
  const { getData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();

  const { t } = useTranslation();

  const [, setIsInitialLoading] = useRecoilState(initialLoading);

  const getInitialData = useCallback(
    async ({
      setIsLoading,
      setDataState,
      resource,
      initialized,
    }: useGetInitialDataProps) => {
      try {
        setIsLoading(true);
        const response = await getData(resource);
        setDataState(response.data);
      } catch (error) {
        handleCommonError(error, t);
        setIsInitialLoading(false);
      } finally {
        initialized.current = true;
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getData, handleCommonError]
  );
  return { getInitialData };
};

export default useGetInitialData;
