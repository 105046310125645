import { DESKTOP_TABLE_FOOTER_HEIGHT, ThemeColors } from "../../../shared";

export const generateTableToolbarStyle = (colors: ThemeColors) => {
  return {
    display: "inline-flex",
    borderRadius: "8px",
    marginRight: "16px",

    "& > div": {
      display: "flex",
      alignItems: "center",
      color: colors.textDark,
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.1px",
      border: `1px solid ${colors.white}`,
      background: colors.blue300,
      marginRight: "-1px",
      padding: "3px 15px",
      cursor: "pointer",
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    "& > div:first-of-type": {
      borderRadius: "8px 0 0 8px",
      padding: "3px 15px 3px 7px",
    },

    "& > div:last-of-type": {
      borderRadius: "0 8px 8px 0",
    },

    "& > div:hover": {
      background: colors.blue400,
      zIndex: "1",
    },

    "& > div:active": {
      background: colors.blue500,
      zIndex: "1",
    },
    ".action-disabled": {
      color: colors.textDarkDisabled,
      borderRadius: "0px 8px 8px 0px",
      border: `1px solid ${colors.white}`,
      background: colors.blue200,
      "&:hover": {
        background: colors.blue200,
      },
    },
  };
};

export const tableHeadToolbarDivStyle = (toMd: boolean) => {
  return {
    div: {
      marginLeft: toMd ? "6px" : "18px",
    },
  };
};

export const TableRowStyle = (
  isInitiallyChecked: boolean,
  colors: ThemeColors,
  isDisabled: boolean
) => {
  return {
    cursor: "pointer",

    td: {
      background: isInitiallyChecked ? colors.blue100 : colors.white,
      color: isDisabled ? colors.textDarkDisabled : colors.textDark,
    },

    "&:hover td": {
      background: isInitiallyChecked ? colors.blue200 : colors.gray50,
    },
  };
};

export const mobileTableHeadContentStyle = (colors: ThemeColors) => {
  return {
    display: "inline-flex",
    alignItems: "center",
    color: colors.textDark,
    border: `1px solid ${colors.blue200}`,
    borderRadius: "8px",
    padding: "3px 15px 3px 7px",
    cursor: "pointer",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

    svg: {
      color: colors.textPlaceholder,
      marginRight: "6px",
    },

    "&:hover": {
      background: colors.blue200,
      borderColor: colors.blue200,
    },

    "&:active": {
      background: colors.blue300,
      borderColor: colors.blue300,
    },
  };
};

export const mobileTableItemStyle = (
  colors: ThemeColors,
  isFirstItem: boolean,
  isLastItem: boolean
) => {
  return {
    background: colors.white,
    borderTop: isFirstItem ? "none" : `1px solid ${colors.gray200}`,
    borderBottom: isLastItem ? "none" : `1px solid ${colors.gray200}`,
    display: "flex",
    // For edit row copy animation from FOTA MobileCard file
  };
};

export const tableFooterStyle = (
  toLg: boolean,
  fromLg: boolean,
  colors: ThemeColors
) => {
  return {
    height: DESKTOP_TABLE_FOOTER_HEIGHT(toLg),
    backgroundColor: colors.gray100,
    boxShadow: `0px 1px 0px 0px ${colors.gray200} inset`,
    justifyContent: fromLg ? "space-between" : "center",
    flexDirection: fromLg ? "row" : "column",
    zIndex: 5,
    "& > div": {
      padding: fromLg ? "0 24px" : "unset",
    },
    "& > div:first-of-type": {
      margin: toLg ? "8px 0" : "unset",
    },
  };
};
