import {
  generateDataTypeIconBackground,
  generateDataTypeIconColor,
} from "./styleFunctions";

const EventsAndFaultsDataTypeIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={generateDataTypeIconBackground(props)}
      />
      <path
        d="M4.77047 19.0303C4.62757 19.0303 4.49766 18.9946 4.38074 18.9232C4.26382 18.8517 4.17288 18.7575 4.10793 18.6406C4.04297 18.5237 4.00725 18.397 4.00075 18.2606C3.99425 18.1242 4.02998 17.9911 4.10793 17.8612L11.318 5.38973C11.3959 5.25982 11.4966 5.16239 11.62 5.09743C11.7434 5.03248 11.8701 5 12 5C12.1299 5 12.2566 5.03248 12.38 5.09743C12.5034 5.16239 12.6041 5.25982 12.682 5.38973L19.8921 17.8612C19.97 17.9911 20.0057 18.1242 19.9992 18.2606C19.9928 18.397 19.957 18.5237 19.8921 18.6406C19.8271 18.7575 19.7362 18.8517 19.6193 18.9232C19.5023 18.9946 19.3724 19.0303 19.2295 19.0303H4.77047ZM6.11505 17.4714H17.8849L12 7.33839L6.11505 17.4714ZM12 16.692C12.2208 16.692 12.406 16.6173 12.5554 16.4679C12.7048 16.3185 12.7795 16.1333 12.7795 15.9125C12.7795 15.6916 12.7048 15.5065 12.5554 15.3571C12.406 15.2077 12.2208 15.133 12 15.133C11.7791 15.133 11.594 15.2077 11.4446 15.3571C11.2952 15.5065 11.2205 15.6916 11.2205 15.9125C11.2205 16.1333 11.2952 16.3185 11.4446 16.4679C11.594 16.6173 11.7791 16.692 12 16.692ZM12 14.3536C12.2208 14.3536 12.406 14.2789 12.5554 14.1295C12.7048 13.9801 12.7795 13.795 12.7795 13.5741V11.2357C12.7795 11.0149 12.7048 10.8297 12.5554 10.6803C12.406 10.5309 12.2208 10.4562 12 10.4562C11.7791 10.4562 11.594 10.5309 11.4446 10.6803C11.2952 10.8297 11.2205 11.0149 11.2205 11.2357V13.5741C11.2205 13.795 11.2952 13.9801 11.4446 14.1295C11.594 14.2789 11.7791 14.3536 12 14.3536Z"
        fill={generateDataTypeIconColor(props)}
      />
    </svg>
  );
};

export default EventsAndFaultsDataTypeIcon;
