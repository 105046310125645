/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { CircularProgress, Typography } from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

import { useStyles } from "../styles/styles";
import Button from "../../../../../../Button/Button";
import { useDateTime } from "../../../../../../../shared";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";

interface ApiCredentialsMainProps {
  apiCredentialData: any;
  isLoading: boolean;
  handleOpenDelete: () => void;
}

const ApiCredentialsMain = ({
  apiCredentialData,
  isLoading,
  handleOpenDelete,
}: ApiCredentialsMainProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { currentTimeFormatHoursMinutes, formattedDate } = useDateTime();

  const {
    credentialsMainContent,
    credentialsTextAndButtonCSS,
    credentialsTextCSS,
    mainLoaderCSS,
  } = useStyles();

  return isLoading ? (
    <div css={css(mainLoaderCSS)}>
      <CircularProgress />
    </div>
  ) : (
    <div css={css(credentialsMainContent)}>
      <div css={css(credentialsTextAndButtonCSS)}>
        <Typography variant="body1" sx={{ color: colors.textDark }}>
          {t("Dialog##api management##api credentials")}
        </Typography>

        <div css={css(credentialsTextCSS)}>
          <Typography variant="body2" sx={{ color: colors.textSecondary }}>
            {t("Dialog##api management##created at")}:
          </Typography>
          <Typography variant="body2" sx={{ color: colors.textDark }}>
            {formattedDate(
              apiCredentialData?.createdAt,
              currentTimeFormatHoursMinutes
            )}
          </Typography>
        </div>
      </div>

      <Button
        color="secondary"
        size="tiny"
        variant="iconOnly"
        icon={<DeleteRoundedIcon />}
        onClick={handleOpenDelete}
      />
    </div>
  );
};

export default ApiCredentialsMain;
