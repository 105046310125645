/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { useRecoilValue } from "recoil";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ImportIcon from "@mui/icons-material/FileDownloadOutlined";

import { Actions } from "../types/types";

import Menu from "../../../Menu/Menu";
import ListChoice from "../../../ListChoice/ListChoice";
import { useMenu } from "../../../Table/hooks/useMenu";
import Button from "../../../Button/Button";
import useTutorialMode from "../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../TutorialMode/types";
import {
  COMPANIES_ACTIONS,
  DRIVERS_ACTIONS,
  DRIVER_FILES_ACTIONS,
  ROUTES,
  useMediaQueries,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
  VEHICLE_FILES_ACTIONS,
  MORE_ACTIONS_KEYS,
} from "../../../../shared";
import {
  selectedTableRows,
  tableData,
  tableDetailsSectionState,
} from "../../../../atoms/atoms";
import useInitialLoading from "../../../../shared/hooks/useInitialLoading";

import AddVehiclesIcon from "../../../../assets/customIcons/AddVehiclesIcon";
import AddDriversIcon from "../../../../assets/customIcons/AddDriversIcon";

interface IPageHeaderActionsProps {
  route: ROUTES;
  children: ReactNode;
  moreActions?: MORE_ACTIONS_KEYS[];
  addItem?: boolean;
  importItem?: boolean;
  download?: boolean;
  fileSettings?: boolean;
  inviteUser?: boolean;
  openDialog: (action: Actions) => void;
}

const PageHeaderActions = ({
  route,
  children,
  moreActions,
  addItem,
  importItem,
  download,
  fileSettings,
  inviteUser,
  openDialog,
}: IPageHeaderActionsProps) => {
  const { toSm, fromLg } = useMediaQueries();
  const { t } = useTranslation();
  const { isInitialLoading } = useInitialLoading();
  const {
    checkIfCorrectTutorialStep,
    setTutorialDetails,
    stepGroup,
  } = useTutorialMode();
  const { anchorEl, handleOpenMenu, handleCloseMenu } = useMenu();

  const isDesktopDetailsOpen = useRecoilValue(tableDetailsSectionState);

  const tableRowData = useRecoilValue(tableData);
  const selectedRows = useRecoilValue(selectedTableRows);

  const actionButtonLoaderWidthToSm = "100%";

  const handleAddVehicle = () => {
    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialAddVehicle, 0)) {
      setTutorialDetails({
        isTutorialOpen: false,
        stepIndex: 0,
        stepGroup,
        isTutorialActive: true,
      });
    }

    openDialog(VEHICLES_ACTIONS.AddVehicle);
  };

  const handleAddItemClick = () => {
    switch (route) {
      case ROUTES.Vehicles:
        return handleAddVehicle();
      case ROUTES.Drivers:
        return openDialog(DRIVERS_ACTIONS.AddDriver);
      case ROUTES.Companies:
        return openDialog(COMPANIES_ACTIONS.CreateCompany);

      default:
        console.log("add item");
    }
  };

  const handleImportItemClick = () => {
    handleCloseMenu();
    switch (route) {
      case ROUTES.Vehicles:
        return openDialog(VEHICLES_ACTIONS.ImportVehicle);
      case ROUTES.Drivers:
        return openDialog(DRIVERS_ACTIONS.ImportDriver);
      default:
        return () => console.log("import item");
    }
  };

  const generateAddItemButtonText = () => {
    switch (route) {
      case ROUTES.Vehicles:
        return t("Button##add vehicle");
      case ROUTES.Drivers:
        return t("Button##add driver");
      case ROUTES.Companies:
        return t("Button##create");
      default:
        return "add item";
    }
  };

  const generateImportItemButtonText = () => {
    if (route === ROUTES.Vehicles) {
      return t("Button##import vehicles");
    }

    if (route === ROUTES.Drivers) {
      return t("Button##import drivers");
    }

    return "";
  };

  const getAddItemIcon = () => {
    if (route === ROUTES.Vehicles) {
      return <AddVehiclesIcon />;
    }

    if (route === ROUTES.Drivers) {
      return <AddDriversIcon />;
    }
  };

  const handleDownloadButtonClick = () => {
    switch (route) {
      case ROUTES.VehicleFiles:
        return openDialog(VEHICLE_FILES_ACTIONS.Download);
      case ROUTES.DriverFiles:
        return openDialog(DRIVER_FILES_ACTIONS.Download);
      default:
        return () => console.log("download");
    }
  };

  const handleFileSettingsButtonClick = () => {
    switch (route) {
      case ROUTES.VehicleFiles:
        return openDialog(VEHICLE_FILES_ACTIONS.FileSettings);
      case ROUTES.DriverFiles:
        return openDialog(DRIVER_FILES_ACTIONS.FileSettings);
      default:
        return () => console.log("File settings button");
    }
  };

  const handleInviteUserButtonClick = () => {
    openDialog(USERS_ACTIONS.InviteUser);
  };

  const showAddButtonInMenu = isDesktopDetailsOpen && moreActions && !fromLg;

  return (
    <>
      <>
        {addItem && !showAddButtonInMenu && (
          <Button
            fullWidth={toSm}
            id="add_resource_button"
            // loader appears only on the specified screen
            hasRectangleLoader={toSm}
            variant="textOnly"
            size="small"
            color="primary"
            onClick={handleAddItemClick}
            isInitialLoading={isInitialLoading}
            loaderWidth={actionButtonLoaderWidthToSm}
          >
            {generateAddItemButtonText()}
          </Button>
        )}
        {moreActions && (
          <Button
            fullWidth={toSm}
            hasRectangleLoader={toSm}
            variant="iconRight"
            size="small"
            color="white"
            isInitialLoading={isInitialLoading}
            icon={<ExpandMoreRoundedIcon />}
            onClick={handleOpenMenu}
          >
            {t("Button##more actions")}
          </Button>
        )}

        <Menu
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          css={css({
            marginTop: "3px",
            "& .MuiMenu-paper": {
              minWidth: "211px",
            },
          })}
        >
          {addItem && showAddButtonInMenu && (
            <ListChoice
              title={generateAddItemButtonText()}
              iconLeft={getAddItemIcon()}
              onClick={() => {
                handleAddItemClick();
              }}
            />
          )}

          {moreActions?.includes(MORE_ACTIONS_KEYS.Import) && (
            <ListChoice
              title={generateImportItemButtonText()}
              iconLeft={<ImportIcon />}
              onClick={handleImportItemClick}
            />
          )}
        </Menu>

        {importItem && (
          <Button
            variant="textOnly"
            fullWidth={toSm}
            hasRectangleLoader={toSm}
            size="small"
            color="white"
            onClick={handleImportItemClick}
            isInitialLoading={isInitialLoading}
            loaderWidth={actionButtonLoaderWidthToSm}
          >
            {t("Button##import")}
          </Button>
        )}
        {download && (
          <Button
            data-testid="header-download-button"
            fullWidth={toSm}
            hasRectangleLoader={toSm}
            variant="textOnly"
            size="small"
            color="primary"
            disabled={tableRowData?.length === 0 || selectedRows?.length === 0}
            onClick={handleDownloadButtonClick}
            isInitialLoading={isInitialLoading}
            loaderWidth={actionButtonLoaderWidthToSm}
          >
            {t("Button##download")}
          </Button>
        )}
        {fileSettings && (
          <Button
            data-testid="header-file-settings-button"
            fullWidth={toSm}
            hasRectangleLoader={toSm}
            variant="textOnly"
            size="small"
            color="white"
            disabled={tableRowData?.length === 0}
            onClick={handleFileSettingsButtonClick}
            isInitialLoading={isInitialLoading}
            loaderWidth={actionButtonLoaderWidthToSm}
          >
            {t("Button##file settings")}
          </Button>
        )}
        {inviteUser && (
          <Button
            data-testid="header-invite-user-button"
            fullWidth={toSm}
            hasRectangleLoader={toSm}
            variant="textOnly"
            size="small"
            color="primary"
            onClick={handleInviteUserButtonClick}
            isInitialLoading={isInitialLoading}
            loaderWidth={actionButtonLoaderWidthToSm}
          >
            {t("Button##invite user")}
          </Button>
        )}
      </>
      {/* Dialogs below as children*/}
      {children}
    </>
  );
};

export default PageHeaderActions;
