import { createContext } from "react";

import { DropzoneInitialState, Message } from "./dropzoneReducer";

import { FILE_UPLOAD_STATUS } from "../../shared";

interface DropzoneContextState extends DropzoneInitialState {
  setFile: (file: File | null, fileData?: number[][]) => void;
  setFiles: (files: File[]) => void;
  setMessage: (message: Message | Message[], override?: boolean) => void;
  setStatus: (
    fileUploadStatus: FILE_UPLOAD_STATUS,
    id: string,
    rawError?: any
  ) => void;
  removeFile: (id: string) => void;
  removeAllFiles: () => void;
}

const DropzoneContext = createContext({} as DropzoneContextState);

export default DropzoneContext;
