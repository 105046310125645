import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import lt from "./locales/lt.json";

import { LANGUAGES } from "../shared";

export const resources = {
  [LANGUAGES.English]: { translation: en },
  [LANGUAGES.Lithuanian]: { translation: lt },
} as const;

i18n.use(initReactI18next).init({
  lng: LANGUAGES.English,
  fallbackLng: LANGUAGES.English,
  resources,
  keySeparator: "##",
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: false,
  react: {
    useSuspense: true,
  },
});
