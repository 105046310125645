/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import NewsImage from "./NewsImage";

import { selectableImageType } from "..";
import { useStyles } from "../styles";

import { ThemeColors, useMediaQueries } from "../../../../../shared";

interface SelectableImageProps {
  image: selectableImageType;
  colors: ThemeColors;
  selectedImage: selectableImageType | null;
  onImageClick: (image: selectableImageType) => void;
}

const SelectableImage = ({
  image,
  colors,
  selectedImage,
  onImageClick,
}: SelectableImageProps) => {
  const { toSm, toMd, toLg } = useMediaQueries();
  const { selectableImageDiv } = useStyles({
    colors,
    toMd,
    toSm,
    toLg,
  });

  return (
    <div
      data-testid="selectable-image"
      css={css([
        selectableImageDiv,
        {
          outline:
            selectedImage?.id === image.id
              ? `2px solid ${colors.blue600}`
              : "unset",
        },
      ])}
      onClick={() => onImageClick(image)}
    >
      <NewsImage
        width={"100%"}
        height={"100%"}
        image={image.imageName}
        testId={image.imageName}
      />
    </div>
  );
};

export default SelectableImage;
