import { useEffect } from "react";

interface UseObserveMutationProps {
  parentElementName: HTMLElement;
  querySelector: string;
}

const useObserveMutation = ({
  parentElementName,
  querySelector,
}: UseObserveMutationProps) => {
  useEffect(() => {
    const parentElement = parentElementName;

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          // Check each removed node to see if it's the Dialog or contains the Dialog
          mutation.removedNodes.forEach(() => {
            const dialogNode = document.querySelector(querySelector);
            if (!dialogNode) {
              window.location.reload();
            }
          });
        }
      }
    });

    // Start observing
    observer.observe(parentElement, {
      childList: true, // Listen for direct children being added/removed
      subtree: true, // Also listen for deep changes in all descendants
    });

    // Cleanup
    return () => observer.disconnect();
  }, [parentElementName, querySelector]);

  return <></>;
};

export default useObserveMutation;
