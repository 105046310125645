import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import RectangleLoader from "../../../shared/components/loaders/RectangleLoader";

interface IFiltersTitleProps {
  isInitialLoading: boolean;
}

const FiltersTitle = ({ isInitialLoading }: IFiltersTitleProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  return isInitialLoading ? (
    <RectangleLoader
      testId="filtered-by-rectangle-loader"
      width={77}
      height={21}
      customStyle={{ marginRight: "16px" }}
    />
  ) : (
    <Typography
      variant="body2"
      sx={{
        color: colors.textDark,
        lineHeight: "36px",
        alignSelf: "flex-start",
        whiteSpace: "nowrap",
        marginRight: "16px",
      }}
    >{`${t("Filter##filtered by")}:`}</Typography>
  );
};

export default FiltersTitle;
