/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useState, useEffect, SyntheticEvent } from "react";
import MuiSnackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import Alert from "../Alert/Alert";
import { AlertContext } from "../../context/alert/AlertContextProvider";
import { AlertInitialState } from "../../context/alert/types";

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionLeft = (props: TransitionProps) => (
  <Slide {...props} direction="left" />
);

const Snackbar = () => {
  const { status, title, description, removeAlert } = useContext(AlertContext);

  const [open, setOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<AlertInitialState[]>([]);
  const [alertData, setAlertData] = useState<any>({
    status: null,
    title: null,
    description: null,
  });

  useEffect(() => {
    status
      ? setSnackPack([...snackPack, { status, title, description }])
      : setOpen(false);
    // eslint-disable-next-line
  }, [status, title, description]);

  useEffect(() => {
    if (snackPack.length > 0) {
      if (!alertData.status) {
        setAlertData(snackPack.shift());
        setOpen(true);
      } else if (alertData.status && open) {
        setOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [snackPack, alertData, open]);

  const handleClose = (
    _e: SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    removeAlert();
  };

  const handleExited = () =>
    setAlertData({
      status: null,
      title: null,
      description: null,
    });

  return alertData.status ? (
    <MuiSnackbar
      data-testid="snackbar"
      open={open}
      disableWindowBlurListener
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={TransitionLeft}
    >
      <div css={css({ width: "448px" })}>
        <Alert
          status={alertData.status}
          title={alertData.title}
          description={alertData.description}
          close={handleClose}
        />
      </div>
    </MuiSnackbar>
  ) : null;
};

export default Snackbar;
