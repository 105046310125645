/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import MenuIcon from "@mui/icons-material/MenuRounded";
import { useContext } from "react";
import { useRecoilState } from "recoil";

import { STEP_GROUP } from "../TutorialMode/types";
import useTutorialMode from "../TutorialMode/hooks/useTutorialMode";
import Button from "../Button/Button";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { mobileDrawerState } from "../../atoms/atoms";

const MobileMenuButton = () => {
  const { colors } = useContext(ThemeContext);

  const { checkIfCorrectTutorialStep, setTutorialDetails } = useTutorialMode();

  const [, setIsMobileDrawerOpen] = useRecoilState(mobileDrawerState);

  const handleOpenDrawer = () => {
    setIsMobileDrawerOpen(true);

    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 0)) {
      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: 1,
        stepGroup: STEP_GROUP.TutorialNewCompanyCard,
        isTutorialActive: true,
      });
    }

    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialCreateSchedule, 1)) {
      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: 2,
        stepGroup: STEP_GROUP.TutorialCreateSchedule,
        isTutorialActive: true,
      });
    }

    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialApplySchedule, 0)) {
      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: 1,
        stepGroup: STEP_GROUP.TutorialApplySchedule,
        isTutorialActive: true,
      });
    }
  };

  return (
    <Button
      data-testid="mobile-drawer-button"
      id="mobileDrawerBtn"
      variant="iconOnly"
      size="small"
      color="white"
      icon={<MenuIcon css={css({ color: colors.textDark })} />}
      onClick={handleOpenDrawer}
      css={css({ marginLeft: "8px" })}
    />
  );
};

export default MobileMenuButton;
