import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import EditDriverDialogContent from "./EditDriverDialogContent";
import { EditDriverPayloadType } from "../../../../types";
import Dialog from "../../../../../Dialog/Dialog";
import DialogTitleWithTooltip from "../../../../../Dialog/components/DialogTitleWithTooltip/DialogTitleWithTooltip";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import { DriverDataDto } from "../../../../../../Pages/Drivers/interfaces";
import {
  DRIVERS_ACTIONS,
  GeneralDialogProps,
  useMediaQueries,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import {
  searchQueryParams,
  tableDetails,
  tableDetailsResource,
} from "../../../../../../atoms/atoms";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../shared/hooks/useResponse";
import {
  capitalizeResourceFirstLetter,
  refreshTableAndUpdateDetails,
} from "../../../../../../shared/functions/functions";

interface IEditDriverDialogProps {
  rowData: DriverDataDto | null;
}

const EditDriverDialog = ({
  isOpen,
  onClose,
  rowData,
}: IEditDriverDialogProps & GeneralDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { putData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleCommonError, handleResponse } = useResponse();

  const resource = useRecoilValue(tableDetailsResource);
  const currentTableDetails = useRecoilValue(tableDetails);
  const searchParams = useRecoilValue(searchQueryParams);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReadyToConfirm, setIsReadyToConfirm] = useState<boolean | "" | null>(
    false
  );
  const [payload, setPayload] = useState<EditDriverPayloadType>({
    id: null,
    cardNumber: null,
    cardName: null,
    comment: null,
  });

  const isReadyToCallApi = isReadyToConfirm && resource;

  const editableItemHasOpenedDetails =
    currentTableDetails?.driversPage &&
    currentTableDetails?.driversPage.id === payload.id;

  const resourceString =
    resource && capitalizeResourceFirstLetter(resource).slice(0, -1);

  const valueInSearchField = searchParams.driversPage;
  const successMessage = t(`Alert##successfully edited`, { resourceString });

  const handleCloseEditDriverDialog = () => {
    onClose(DRIVERS_ACTIONS.EditDriver);
  };

  const handleSubmit = () => {
    if (isReadyToCallApi) {
      const editDriver = async () => {
        try {
          setIsLoading(true);
          await putData(resource, payload);

          // refresh table and set updated details if details section is opened

          refreshTableAndUpdateDetails({
            valueInSearchField,
            resourceId: payload.id,
            successMessage,
            itemHasOpenedDetails: editableItemHasOpenedDetails,
            getTableItemDetails,
            handleResponse,
          });
        } catch (error) {
          handleCommonError(error, t);
        } finally {
          setIsLoading(false);
        }
      };

      editDriver();
      handleCloseEditDriverDialog();
    }
  };

  return (
    <Dialog
      data-testid="edit-driver-dialog"
      title={
        rowData &&
        ((
          <DialogTitleWithTooltip
            title={t("Dialog##edit driver", {
              name: rowData.cardName,
            })}
            tooltipText={rowData.cardName}
          />
        ) as any)
      }
      isTitleSeparator
      open={isOpen}
      close={handleCloseEditDriverDialog}
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={handleCloseEditDriverDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-driver-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-edit-driver"
        />
      }
    >
      {rowData && (
        <EditDriverDialogContent
          rowData={rowData}
          setPayload={setPayload}
          setIsReadyToConfirm={setIsReadyToConfirm}
        />
      )}
    </Dialog>
  );
};

export default React.memo(EditDriverDialog);
