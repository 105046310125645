import { useEffect, useState } from "react";
import { ROUTES } from "..";

const useTabFilters = (pathname: string) => {
  const initialTabIndex = (() => {
    switch (pathname) {
      case ROUTES.VehicleFiles:
      case ROUTES.Users:
        return 0;

      case ROUTES.DriverFiles:
      case ROUTES.UsersInvitations:
        return 1;

      default:
        return 0;
    }
  })();

  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  useEffect(() => {
    setTabIndex(initialTabIndex);
    // eslint-disable-next-line
  }, [pathname]);

  return { tabIndex };
};

export default useTabFilters;
