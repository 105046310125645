import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";

import RemoveScheduleDialog from "./RemoveScheduleDialog";

import { DriverScheduleDto, ScheduleDto } from "../../../../../types";

import ListChoice from "../../../../../../ListChoice/ListChoice";

import {
  MenuAnchorEl,
  TABLE_NAMES,
  tableNameParams,
} from "../../../../../../../shared";
import { tableDetails, tableName } from "../../../../../../../atoms/atoms";

interface IRemoveScheduleButtonProps {
  schedule: ScheduleDto | DriverScheduleDto;
  onActionsMenu?: Dispatch<SetStateAction<MenuAnchorEl>>;
}

const RemoveScheduleButton = ({
  schedule,
  onActionsMenu,
}: IRemoveScheduleButtonProps) => {
  const { t } = useTranslation();

  const detailsData = useRecoilValue(tableDetails);
  const resourceName = useRecoilValue(tableName);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenRemoveScheduleDialog = () => {
    setIsOpen(true);
  };

  const handleCloseRemoveScheduleDialog = () => {
    setIsOpen(false);
    onActionsMenu && onActionsMenu(null);
  };
  const resourceParams = tableNameParams[resourceName!] || {};

  const detailsDataFromPage =
    resourceName === TABLE_NAMES.Vehicles
      ? detailsData?.vehiclesPage
      : detailsData?.driversPage;

  return (
    <>
      <ListChoice
        title={t("Details##remove")}
        iconLeft={<DoDisturbOnOutlinedIcon />}
        onClick={handleOpenRemoveScheduleDialog}
        alignIconCenter
      />

      <RemoveScheduleDialog
        schedule={schedule}
        resourceParams={resourceParams}
        resourceId={detailsDataFromPage?.id}
        isOpen={isOpen}
        handleClose={handleCloseRemoveScheduleDialog}
      />
    </>
  );
};

export default RemoveScheduleButton;
