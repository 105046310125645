/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useState, Fragment, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { useMediaQueries } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { tableColumns } from "../../atoms/atoms";

interface ITableLoaderProps {
  hasDetails: boolean;
}

const TableLoader = ({ hasDetails }: ITableLoaderProps) => {
  const {
    colors: { blue100, blue200, gray100, gray200, white },
  } = useContext(ThemeContext);

  const { toMd } = useMediaQueries();

  const columns = useRecoilValue<any>(tableColumns);

  const [rowsCount, setRowsCount] = useState(0);

  const desktopTableRowHeight = 44;
  const mobileTableRowHeight = 56;
  const PAGE_HEADER_HEIGHT = 68;
  const NAVBAR_HEIGHT = 56;
  const mobileItemRowsCount = hasDetails ? columns.length + 1 : columns.length;

  useEffect(() => {
    const desktopRows = Math.floor(
      (window.innerHeight - PAGE_HEADER_HEIGHT - NAVBAR_HEIGHT) /
        desktopTableRowHeight
    );
    const mobileRows = Math.floor(
      (window.innerHeight - PAGE_HEADER_HEIGHT - NAVBAR_HEIGHT) /
        mobileTableRowHeight
    );
    setRowsCount(toMd ? mobileRows : desktopRows);
  }, [toMd, columns]);

  return (
    <div
      data-testid="table-loader"
      css={css({
        overflow: "hidden",
      })}
    >
      <div>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={42}
          css={css({
            position: "sticky",
            top: "0",
            zIndex: "1",
            boxSizing: "border-box",
            background: blue100,
            borderTop: `1px solid ${blue200}`,
            borderBottom: `1px solid ${blue200}`,
          })}
        />
        <div
          css={css({
            flex: "1",
          })}
        >
          {Array(rowsCount)
            .fill(null)
            .map((_, i) => {
              const everyFullItem = (i + 1) % mobileItemRowsCount === 0;

              return (
                <Fragment key={uuidv4()}>
                  <Skeleton
                    variant="rectangular"
                    height={toMd ? 55 : 43}
                    css={css({
                      background: white,
                      borderBottom: `1px solid ${gray200}`,
                    })}
                  />

                  {toMd && everyFullItem && (
                    <div
                      css={css({
                        height: "8px",
                        background: gray100,
                      })}
                    />
                  )}
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TableLoader;
