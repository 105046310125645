export enum BUTTON_COLORS {
    Critical = "critical",
    CriticalSubtle = "criticalSubtle",
    Primary = "primary",
    PrimarySubtle = "primarySubtle",
    Secondary = "secondary",
    Transparent = "transparent",
    White = "white"
}

export enum BUTTON_SIZES {
    Large = "large",
    Normal = "normal",
    Small = "small",
    Tiny = "tiny"
}

export enum BUTTON_VARIANTS {
    TextOnly = "textOnly",
    IconLeft = "iconLeft",
    IconRight = "iconRight",
    TwoIcons = "twoIcons",
    IconOnly = "iconOnly"
}