import {
  generateDataTypeIconBackground,
  generateDataTypeIconColor,
} from "./styleFunctions";

const OverviewDataTypeIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={generateDataTypeIconBackground(props)}
      />
      <path
        d="M18.9159 17.5361H4.88902C4.39512 17.5361 4 17.0738 4 16.3668V9.63216C4 8.93422 4.39512 8.46289 4.88902 8.46289H18.8171C19.4098 8.46289 19.8049 8.92516 19.8049 9.63216V16.3668C19.8049 17.0647 19.4098 17.5361 18.9159 17.5361Z"
        stroke={generateDataTypeIconColor(props)}
        strokeWidth="1.26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.80249 14.834H11.0025"
        stroke={generateDataTypeIconColor(props)}
        strokeWidth="1.26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8025 14.834H18.0025"
        stroke={generateDataTypeIconColor(props)}
        strokeWidth="1.26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0925 10.4062H5.71249C5.41425 10.4062 5.17249 10.6797 5.17249 11.0171V12.1369C5.17249 12.4742 5.41425 12.7477 5.71249 12.7477H11.0925C11.3907 12.7477 11.6325 12.4742 11.6325 12.1369V11.0171C11.6325 10.6797 11.3907 10.4062 11.0925 10.4062Z"
        fill={generateDataTypeIconColor(props)}
      />
      <path
        d="M5.17078 7.58537C5.17078 7.26208 5.43285 7 5.75614 7H8.68297C9.00626 7 9.26834 7.26208 9.26834 7.58537V8.17073H5.17078V7.58537Z"
        fill={generateDataTypeIconColor(props)}
      />
    </svg>
  );
};

export default OverviewDataTypeIcon;
