import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Dialog from "../../../Dialog/Dialog";
import { DialogWidth } from "../../../Dialog/types/enums";
import { ResourceParams } from "../../../Schedules/types";
import { Actions } from "../../../PageHeader/actions/types";

import { convertStringBasedOnLength, ScheduleOption } from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";

interface RemoveSchedulesConfirmDialogProps {
  schedules: ScheduleOption[];
  resourceParams: ResourceParams;
  isOpen: boolean;
  toMd: boolean;
  isDialogLoading: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  removeSelectedSchedules: (removeAction: Actions) => Promise<void>;
}

const RemoveSchedulesConfirmDialog = ({
  schedules,
  resourceParams,
  isOpen,
  toMd,
  isDialogLoading,
  setDialogOpen,
  removeSelectedSchedules,
}: RemoveSchedulesConfirmDialogProps) => {
  const { t } = useTranslation();

  const { resourceName, actionRemove } = resourceParams;

  const getSchedulesInfo = () => {
    const schedulesLength = schedules.length;
    const isMultipleSelected = schedulesLength > 1;

    return schedulesLength
      ? {
          resourceValue: isMultipleSelected
            ? schedulesLength
            : `"${schedules[0].name}"`,
          descriptionString: convertStringBasedOnLength(
            schedulesLength,
            resourceName
          ),
        }
      : {};
  };

  const { resourceValue, descriptionString } = getSchedulesInfo();

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = () => {
    removeSelectedSchedules(actionRemove);
  };

  return (
    <Dialog
      data-testid="remove-schedules-confirmation-dialog"
      title={`${t("Dialog##remove schedules")}?`}
      description={t(
        `Dialog##remove schedules confirmation description##${descriptionString}`,
        {
          resourceValue: resourceValue,
        }
      )}
      open={isOpen}
      close={handleClose}
      extendToMaxWidth={toMd}
      isActionsSeparator
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={handleClose}
          isLoading={isDialogLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##remove")}
          confirmationButtonTestId="remove-schedule-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="cancel-remove-schedule-confirmation-button"
        />
      }
    />
  );
};

export default RemoveSchedulesConfirmDialog;
