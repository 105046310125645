/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReactNode, useContext } from "react";

import { useStyles } from "./styles";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import SortAscIcon from "../../assets/customIcons/actions/SortAscIcon";
import SortDescIcon from "../../assets/customIcons/actions/SortDescIcon";

export interface TableSortLabelProps {
  isActive: boolean;
  children: ReactNode;
  isDescending: boolean | "";
  onSortClick: () => void;
}

const TableSortLabel = ({
  children,
  isActive,
  isDescending,
  onSortClick,
}: TableSortLabelProps) => {
  const {
    colors: { textDark, textDarkDisabled },
  } = useContext(ThemeContext);

  const { mainSpan, iconCSS } = useStyles();

  const getColor = (direction: boolean) =>
    isActive && direction === isDescending ? textDark : textDarkDisabled;

  return (
    <span onClick={onSortClick} css={css(mainSpan)}>
      {children}

      <span css={css(iconCSS)}>
        <SortAscIcon
          css={css({
            color: getColor(false),
          })}
        />

        <SortDescIcon
          css={css({
            color: getColor(true),
          })}
        />
      </span>
    </span>
  );
};

export default TableSortLabel;
