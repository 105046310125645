import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import useTutorialMode from "./useTutorialMode";
import { STEP_GROUP, TUTORIAL_BUTTON_TYPE } from "../types";

import { ROUTES } from "../../../shared";
import {
  selectedTableRows,
  tableDetailsSectionState,
} from "../../../atoms/atoms";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../api/resources";
import { useAuth } from "../../../context/Auth/AuthProvider";
import useResponse from "../../../shared/hooks/useResponse";

const useTutorialTooltip = () => {
  const {
    stepGroup,
    stepIndex,
    clearTableHeadIfApplySchedule,
    handleClearTableHead,
    handleTutorialStepIndexChange,
    handleCloseDetailsCompletely,
    setIsMobileDrawerOpen,
    setIsSettingsOpen,
    setTutorialDetails,
  } = useTutorialMode();
  const { putData } = useAxiosPrivate();
  const location = useLocation();
  const { getUserData } = useAuth();
  const { t } = useTranslation();
  const { handleCommonError } = useResponse();

  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setSelectedRows] = useRecoilState(selectedTableRows);

  const isCompaniesPage = location.pathname === ROUTES.Companies;
  const isVehiclesPage = location.pathname === ROUTES.Vehicles;

  const handleTutorialCompletion = async (stepGroup: STEP_GROUP) => {
    try {
      await putData(ApiResources.UsersTutorials, {
        tutorials: [stepGroup],
      });

      setTutorialDetails({
        isTutorialOpen: false,
        stepIndex: 0,
        stepGroup: STEP_GROUP.Default,
        isTutorialActive: false,
      });

      clearTableHeadIfApplySchedule();
      handleCloseDetailsCompletely(location.pathname as ROUTES);
      await getUserData();
    } catch (error) {
      handleCommonError(error, t);
    }
  };

  const newCompanyCardDesktopButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 1:
        case 2:
          if (stepIndex === 1 || isCompaniesPage) {
            setTutorialDetails({
              isTutorialOpen: false,
              stepIndex: 0,
              stepGroup: STEP_GROUP.TutorialNewCompanyCard,
              isTutorialActive: true,
            });

            setTimeout(() => {
              document.getElementById("desktopSettingsButton")?.click();
            }, 200);
          } else {
            handleTutorialStepIndexChange(stepIndex - 1);
          }
          break;

        case 3:
          handleCloseDetailsCompletely(ROUTES.Companies);
          handleTutorialStepIndexChange(stepIndex - 1);
          break;

        default:
          handleTutorialStepIndexChange(stepIndex - 1);
          break;
      }
    } else {
      const indexNumber = isCompaniesPage ? 2 : 1;
      switch (stepIndex) {
        case 0:
          setIsSettingsOpen(false);
          isCompaniesPage && handleCloseDetailsCompletely(ROUTES.Companies);

          setTutorialDetails({
            isTutorialOpen: true,
            stepIndex: indexNumber,
            stepGroup: stepGroup,
            isTutorialActive: true,
          });

          break;

        case 5:
          handleTutorialCompletion(STEP_GROUP.TutorialNewCompanyCard);
          break;

        default:
          handleTutorialStepIndexChange(stepIndex + 1);
          break;
      }
    }
  };

  const newCompanyCardMobileButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 1:
          setIsMobileDrawerOpen(false);
          handleTutorialStepIndexChange(stepIndex - 1);
          break;

        case 2:
          isCompaniesPage && setIsDetailsOpen(false);
          break;

        case 3:
          document.getElementById("close-button")?.click();

          setTutorialDetails({
            isTutorialOpen: true,
            stepIndex: stepIndex - 1,
            stepGroup: stepGroup,
            isTutorialActive: true,
          });
          break;

        default:
          handleTutorialStepIndexChange(stepIndex - 1);
          break;
      }
    } else {
      return stepIndex === 5
        ? handleTutorialCompletion(STEP_GROUP.TutorialNewCompanyCard)
        : handleTutorialStepIndexChange(stepIndex + 1);
    }
  };

  const applyScheduleDesktopButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 2:
          handleClearTableHead();
          return handleTutorialStepIndexChange(stepIndex - 1);

        case 3:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          return handleTutorialStepIndexChange(stepIndex - 1);

        case 5:
          handleCloseDetailsCompletely(ROUTES.Vehicles);
          document.getElementById("schedulesDialogBtn")?.click();
          handleTutorialStepIndexChange(stepIndex - 1);

          break;
        default:
          handleTutorialStepIndexChange(stepIndex - 1);
          break;
      }
    } else {
      const nextStepIndex = isVehiclesPage ? 2 : 1;
      switch (stepIndex) {
        case 0:
          return handleTutorialStepIndexChange(stepIndex + nextStepIndex);

        case 4:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          document.getElementById("row_0")?.click();
          setTutorialDetails({
            isTutorialOpen: false,
            stepIndex: stepIndex + 1,
            stepGroup: stepGroup,
            isTutorialActive: true,
          });

          break;

        case 5:
          handleTutorialCompletion(STEP_GROUP.TutorialApplySchedule);
          break;

        default:
          handleTutorialStepIndexChange(stepIndex + 1);
          break;
      }
    }
  };

  const applyScheduleMobileButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 1:
          setIsMobileDrawerOpen(false);
          break;

        case 3:
          handleClearTableHead();
          break;

        case 4:
          setSelectedRows([]);
          break;

        case 5:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          break;

        case 7:
          document.getElementById("schedulesDialogBtn")?.click();
          break;

        default:
          break;
      }
      handleTutorialStepIndexChange(stepIndex - 1);
    } else {
      switch (stepIndex) {
        case 6:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();

          handleTutorialStepIndexChange(stepIndex + 1);
          break;

        case 7:
          handleTutorialCompletion(STEP_GROUP.TutorialApplySchedule);

          break;

        default:
          handleTutorialStepIndexChange(stepIndex + 1);
          break;
      }
    }
  };

  const createScheduleDesktopButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 2:
          return isVehiclesPage && handleTutorialStepIndexChange(stepIndex - 2);

        case 3:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          return handleTutorialStepIndexChange(stepIndex - 1);

        case 5:
          document.getElementById("schedulesDialogBtn")?.click();
          return handleTutorialStepIndexChange(stepIndex - 1);

        default:
          handleTutorialStepIndexChange(stepIndex - 1);
          break;
      }
    } else {
      const nextStepIndex = isVehiclesPage ? 2 : 1;
      switch (stepIndex) {
        case 0:
          return handleTutorialStepIndexChange(stepIndex + nextStepIndex);

        case 4:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          return handleTutorialStepIndexChange(stepIndex + 1);

        case 5:
          handleTutorialCompletion(STEP_GROUP.TutorialCreateSchedule);
          break;

        default:
          handleTutorialStepIndexChange(stepIndex + 1);
          break;
      }
    }
  };

  const createScheduleMobileButtonActions = (
    buttonType: TUTORIAL_BUTTON_TYPE
  ) => {
    if (buttonType === TUTORIAL_BUTTON_TYPE.Previous) {
      switch (stepIndex) {
        case 2:
          setIsMobileDrawerOpen(false);
          return handleTutorialStepIndexChange(stepIndex - 1);

        case 3:
          return handleTutorialStepIndexChange(stepIndex - 3);

        case 4:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          return handleTutorialStepIndexChange(stepIndex - 1);

        case 6:
          document.getElementById("schedulesDialogBtn")?.click();
          return handleTutorialStepIndexChange(stepIndex - 1);

        default:
          handleTutorialStepIndexChange(stepIndex - 1);
          break;
      }
    } else {
      const nextStepIndex = isVehiclesPage ? 3 : 1;

      switch (stepIndex) {
        case 0:
          return handleTutorialStepIndexChange(stepIndex + nextStepIndex);

        case 5:
          document
            .getElementById("schedules-main-dialog-close-button")
            ?.click();
          return handleTutorialStepIndexChange(stepIndex + 1);

        case 6:
          return handleTutorialCompletion(STEP_GROUP.TutorialCreateSchedule);

        default:
          handleTutorialStepIndexChange(stepIndex + 1);
          break;
      }
    }
  };

  return {
    applyScheduleDesktopButtonActions,
    applyScheduleMobileButtonActions,
    createScheduleDesktopButtonActions,
    createScheduleMobileButtonActions,
    newCompanyCardDesktopButtonActions,
    newCompanyCardMobileButtonActions,
  };
};

export default useTutorialTooltip;
