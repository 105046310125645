/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import {
  TOGGLE_SWITCH_LABEL_PLACEMENT,
  TOGGLE_SWITCH_SIZES,
  ToggleSwitch,
} from "../../../ToggleSwitch";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { useStyles } from "./styles";

interface ISingleCookieItemProps {
  isItemSeparator: boolean;
  cookieDescription: string;
  cookieTitle: string;
  toggleDisable: boolean;
  onToggleChange: any;
  toggleChecked?: boolean;
  switcherLabel?: string;
  switcherLabelPlacement?: TOGGLE_SWITCH_LABEL_PLACEMENT;
  customStyle?: any;
}

export const SingleCookieItem = ({
  isItemSeparator,
  cookieDescription,
  cookieTitle,
  toggleDisable,
  onToggleChange,
  toggleChecked,
  switcherLabel,
  switcherLabelPlacement,
  customStyle,
}: ISingleCookieItemProps) => {
  const {
    colors: { gray300, gray700 },
  } = useContext(ThemeContext);

  const { descriptionTextCSS, singleCookieItemContainer } = useStyles(
    isItemSeparator
  );

  return (
    <>
      <div css={css(singleCookieItemContainer, customStyle)}>
        <Typography variant="body1" sx={{ color: gray700, maxWidth: "484px" }}>
          {cookieTitle}
        </Typography>

        <ToggleSwitch
          size={TOGGLE_SWITCH_SIZES.Medium}
          defaultChecked={toggleChecked}
          disabled={toggleDisable}
          label={switcherLabel}
          labelPlacement={switcherLabelPlacement}
          onChange={onToggleChange}
        />
      </div>
      <div
        css={css({
          borderBottom: isItemSeparator ? `1px solid ${gray300}` : "unset",
        })}
      >
        <Typography
          component="p"
          variant="body2Regular"
          css={css(descriptionTextCSS)}
        >
          {cookieDescription}
        </Typography>
      </div>
    </>
  );
};
