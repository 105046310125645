/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { SettingsPayloadDto } from "../types";
import ContentBox from "../../../components/ContentBox/ContentBox";
import QueryDropdown from "../../../../../../QueryDropdown/QueryDropdown";
import { ApiResources } from "../../../../../../../api/resources";
import {
  ID_TYPE,
  inputFieldMarginBottom,
  SelectedOptionObjectType,
} from "../../../../../../../shared";

interface FileFormatSettingsProps {
  userFileFormat: string;
  settingsPayload: SettingsPayloadDto;
  isSubmitLoading: boolean;
  isGeneralSettingsLoading: boolean;
  setSettingsPayload: Dispatch<SetStateAction<SettingsPayloadDto>>;
  setIsTriggered: Dispatch<SetStateAction<boolean>>;
}

const FileFormatSettings = ({
  userFileFormat,
  settingsPayload,
  isSubmitLoading,
  isGeneralSettingsLoading,
  setSettingsPayload,
  setIsTriggered,
}: FileFormatSettingsProps) => {
  const { t } = useTranslation();

  const initialFileFormatValue = {
    id: `${userFileFormat}_settings_dropdown`,
    name: t(`Settings##file formats##${userFileFormat}`),
    value: userFileFormat,
  };

  const [
    selectedFileFormat,
    setSelectedFileFormat,
  ] = useState<SelectedOptionObjectType | null>(null);

  useEffect(() => {
    userFileFormat.length > 0 && setSelectedFileFormat(initialFileFormatValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedFileFormat, userFileFormat]);

  const handleChange = (fileFormat: any) => {
    setIsTriggered(true);
    setSelectedFileFormat(fileFormat);
    setSettingsPayload({ ...settingsPayload, fileFormat: fileFormat.value });
  };

  return (
    <div css={css({ width: "100%" })}>
      <ContentBox
        title={t("Settings##file format settings##title")}
        description={t("Settings##file format settings##description")}
      >
        <QueryDropdown
          testId="user-file-format-dropdown"
          disableClearable
          fullWidth
          resource={ApiResources.UsersSettingsFileFormats}
          idType={ID_TYPE.Id}
          size="medium"
          readOnly={isSubmitLoading}
          value={!isGeneralSettingsLoading ? selectedFileFormat : null}
          change={(_, val) => handleChange(val)}
          labelLeft={t("Dialog##file format")}
          customStyle={inputFieldMarginBottom}
          textFieldParams={{
            placeholder: isGeneralSettingsLoading
              ? t("Dialog##loading")
              : undefined,
          }}
        />
      </ContentBox>
    </div>
  );
};

export default FileFormatSettings;
