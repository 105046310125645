import { StyleOptions } from "./types";
import { COLORS } from "../../shared";

export const styleOptions: StyleOptions = {
  colors: {
    primary: {
      text: COLORS.White,
      background: {
        rest: COLORS.Blue700,
        hover: COLORS.Blue800,
        active: COLORS.Blue900,
      },
      loader: COLORS.White,
    },
    primarySubtle: {
      text: COLORS.Blue700,
      background: {
        rest: COLORS.Blue100,
        hover: COLORS.Blue200,
        active: COLORS.Blue400,
      },
      loader: COLORS.Blue700,
    },
    secondary: {
      text: COLORS.TextDark,
      background: {
        rest: COLORS.Gray100,
        hover: COLORS.Gray200,
        active: COLORS.Gray300,
      },
      loader: COLORS.TextDark,
    },
    white: {
      text: COLORS.Gray700,
      background: {
        rest: COLORS.White,
        hover: COLORS.Gray200,
        active: COLORS.Gray300,
      },
      loader: COLORS.Gray700,
    },
    transparent: {
      text: COLORS.White,
      background: {
        rest: "rgba(255, 255, 255, 0.05)",
        hover: "rgba(255, 255, 255, 0.1)",
        active: "rgba(255, 255, 255, 0.12)",
      },
      loader: COLORS.White,
    },
    critical: {
      text: COLORS.White,
      background: {
        rest: COLORS.Red700,
        hover: COLORS.Red800,
        active: COLORS.Red900,
      },
      loader: COLORS.White,
    },
    criticalSubtle: {
      text: COLORS.Red800,
      background: {
        rest: COLORS.Red100,
        hover: COLORS.Red200,
        active: COLORS.Red300,
      },
      loader: COLORS.Red800,
    },
  },
  paddings: {
    textOnly: {
      tiny: "4px 8px",
      small: "8px 12px",
      normal: "12px 16px",
      large: "15px 24px",
    },
    iconLeft: {
      tiny: "4px 8px 4px 4px",
      small: "6px 12px 6px 8px",
      normal: "10px 16px 10px 12px",
      large: "14px 24px 14px 16px",
    },
    iconRight: {
      tiny: "4px 4px 4px 8px",
      small: "6px 8px 6px 12px",
      normal: "10px 12px 10px 16px",
      large: "14px 16px 14px 24px",
    },
    twoIcons: {
      tiny: "4px",
      small: "6px 8px",
      normal: "10px 12px",
      large: "14px 16px",
    },
    iconOnly: {
      tiny: "4px",
      small: "6px",
      normal: "10px",
      large: "14px",
    },
  },
  typography: {
    tiny: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
    small: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    normal: {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    large: {
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.15px",
    },
  },
};
