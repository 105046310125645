import { useEffect, useRef, useState } from "react";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { DeleteConfirmationDto } from "../types";
import { getPayloadObject } from "../actions";

import { TABLE_NAMES } from "../../../shared";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../shared/hooks/useResponse";
import { ApiResources } from "../../../api/resources";

const useCompaniesResources = (
  resourceName: TABLE_NAMES | undefined,
  selectedRows: string[]
) => {
  const { postData } = useAxiosPrivate();
  const { handleCommonError } = useResponse();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const companiesPage = resourceName === TABLE_NAMES.Companies;
  const relatedResourcesInitialValues = companiesPage && {
    vehicles: null,
    drivers: null,
    files: null,
    users: null,
    companyCards: null,
  };

  const [companyRelatedResources, setCompanyRelatedResources] = useState<
    DeleteConfirmationDto | {} | null
  >(
    // the following values are used to display the required number of loaders in delete companies dialog
    relatedResourcesInitialValues || null
  );

  const initialized = useRef(false);

  useEffect(() => {
    if (companiesPage && !initialized.current) {
      const getRelatedResourceList = async () => {
        try {
          setIsLoading(true);
          const response = await postData(
            ApiResources.CompaniesDeleteConfirmation,
            getPayloadObject(resourceName, selectedRows)
          );

          setCompanyRelatedResources(response?.data);
        } catch (error) {
          const err = error as AxiosError<any>;
          handleError(err);
        } finally {
          setIsLoading(false);
        }
      };
      getRelatedResourceList();
      initialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceName, selectedRows]);

  const handleError = (error: AxiosError<any>) => {
    if (error) {
      handleCommonError(error, t);
    }
  };

  return {
    companyRelatedResources,
    isLoading,
  };
};

export default useCompaniesResources;
