import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AddVehiclesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.91 16.5502H14.73C14.73 17.3102 15 17.9502 15.53 18.4802C16.06 19.0102 16.7 19.2802 17.46 19.2802C18.22 19.2802 18.86 19.0102 19.39 18.4802C19.92 17.9502 20.19 17.3102 20.19 16.5502H21.1C21.36 16.5502 21.57 16.4602 21.75 16.2902C21.92 16.1202 22.01 15.9002 22.01 15.6402V12.3002C22.01 12.1902 21.99 12.1002 21.96 12.0002C21.93 11.9002 21.88 11.8302 21.82 11.7502L19.82 9.09023C19.65 8.86023 19.44 8.68023 19.18 8.56024C18.92 8.43023 18.65 8.37023 18.36 8.37023H16.54V6.55023C16.54 6.05023 16.36 5.62023 16.01 5.27023C15.65 4.91023 15.23 4.74023 14.73 4.74023H3.82001C3.32001 4.74023 2.89001 4.92023 2.54001 5.27023C2.18001 5.63023 2.01001 6.05023 2.01001 6.55023V9.10023C2.01001 9.60023 2.42001 10.0102 2.92001 10.0102C3.42001 10.0102 3.83001 9.60023 3.83001 9.10023V6.55023H14.74V14.7302H11.92C11.42 14.7302 11.01 15.1402 11.01 15.6402C11.01 16.1402 11.42 16.5502 11.92 16.5502H11.91ZM18.1 17.1902C17.93 17.3602 17.71 17.4502 17.45 17.4502C17.19 17.4502 16.98 17.3602 16.8 17.1902C16.63 17.0202 16.54 16.8002 16.54 16.5402C16.54 16.2802 16.63 16.0702 16.8 15.8902C16.97 15.7202 17.19 15.6302 17.45 15.6302C17.71 15.6302 17.92 15.7202 18.1 15.8902C18.27 16.0602 18.36 16.2802 18.36 16.5402C18.36 16.8002 18.27 17.0102 18.1 17.1902ZM20.41 12.9102H16.55V10.1802H18.37L20.42 12.9102H20.41Z"
        fill="currentColor"
      />
      <path
        d="M2.28 16.54H4.09V18.35C4.09 18.61 4.18 18.82 4.35 19C4.52 19.18 4.74 19.26 5 19.26C5.26 19.26 5.47 19.17 5.65 19C5.83 18.83 5.91 18.61 5.91 18.35V16.54H7.72C7.97999 16.54 8.19 16.45 8.37 16.28C8.54 16.11 8.63 15.89 8.63 15.63C8.63 15.37 8.54 15.16 8.37 14.98C8.2 14.81 7.97999 14.72 7.72 14.72H5.91V12.91C5.91 12.65 5.82 12.44 5.65 12.26C5.48 12.08 5.26 12 5 12C4.74 12 4.53 12.09 4.35 12.26C4.17 12.43 4.09 12.65 4.09 12.91V14.72H2.28C2.02 14.72 1.81 14.81 1.63 14.98C1.46 15.15 1.37 15.37 1.37 15.63C1.37 15.89 1.46 16.1 1.63 16.28C1.8 16.45 2.02 16.54 2.28 16.54Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AddVehiclesIcon;
