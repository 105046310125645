import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../context/Auth/AuthProvider";
import { ROUTES } from "../shared";

const ProtectedRoute = () => {
  const { user, tokenInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenInfo) {
      navigate(ROUTES.Home, { state: { from: location }, replace: true });
    }
  }, [location, navigate, user, tokenInfo]);

  // If authenticated, render the child routes
  return <Outlet />;
};

export default ProtectedRoute;
