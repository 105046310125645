import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  EditRecipientPayload,
  NEW_RECIPIENT_FIELD_NAMES,
  NewRecipientPayload,
} from "../types";
import QueryDropdown from "../../../../../../QueryDropdown/QueryDropdown";
import InputField from "../../../../../../InputField/InputField";
import { ApiResources } from "../../../../../../../api/resources";
import {
  EXAMPLE_EMAIL,
  ID_TYPE,
  inputFieldMarginBottom,
  useEmailValidation,
} from "../../../../../../../shared";

interface EmailRecipientDialogContentProps {
  isLoading: boolean;
  payload: NewRecipientPayload | EditRecipientPayload;
  setIsReady: Dispatch<SetStateAction<boolean>>;
  setPayload:
    | Dispatch<SetStateAction<NewRecipientPayload>>
    | Dispatch<SetStateAction<EditRecipientPayload>>;
}

const EmailRecipientDialogContent = ({
  isLoading,
  payload,
  setIsReady,
  setPayload,
}: EmailRecipientDialogContentProps) => {
  const { t } = useTranslation();

  const { fileFormat, email } = payload || {};

  const {
    emailIsValid,
    emailError,
    validateEmail,
    clearEmailError,
  } = useEmailValidation(email);

  const fileFormatValue = fileFormat
    ? {
        id: `${fileFormat}_settings_dropdown`,
        name: t(`Settings##file formats##${fileFormat}`),
        value: fileFormat,
      }
    : null;

  const getIsPayloadReady = () =>
    Object.values(payload).every((value) => value !== null && value !== "");

  const isAddReady = getIsPayloadReady() && emailIsValid;

  useEffect(() => {
    setIsReady(isAddReady);
    // eslint-disable-next-line
  }, [emailError, fileFormatValue, email]);

  const handlePayloadChange = (
    field: NEW_RECIPIENT_FIELD_NAMES,
    value: string | null
  ) => {
    setPayload((prevPayload: any) => ({
      ...prevPayload,
      [field]: value,
    }));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    handlePayloadChange(NEW_RECIPIENT_FIELD_NAMES.Email, value);

    validateEmail(e);
    clearEmailError();
  };

  const handleFileFormatChange = (fileFormat: any) => {
    handlePayloadChange(NEW_RECIPIENT_FIELD_NAMES.FileFormat, fileFormat.value);
  };

  return (
    <>
      <InputField
        data-testid="new-recipient-email-field"
        size="medium"
        fullWidth
        required
        labelLeft={t("Dialog##email")}
        onBlur={validateEmail}
        errorText={emailIsValid ? "" : emailError}
        readOnly={isLoading}
        onChange={handleEmailChange}
        placeholder={`${t("Auth##example")} ${EXAMPLE_EMAIL}`}
        customStyle={inputFieldMarginBottom}
        value={payload.email}
      />

      <QueryDropdown
        testId="email-file-format-dropdown"
        disableClearable
        fullWidth
        isRequired
        resource={ApiResources.UsersSettingsFileFormats}
        idType={ID_TYPE.Id}
        size="medium"
        readOnly={isLoading}
        value={fileFormatValue}
        change={(_, val) => handleFileFormatChange(val)}
        labelLeft={t("Dialog##file format")}
        customStyle={inputFieldMarginBottom}
        textFieldParams={{
          placeholder: t("Dialog##select"),
        }}
      />
    </>
  );
};

export default EmailRecipientDialogContent;
