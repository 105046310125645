import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const CancelToken = axios.CancelToken;
export const cancelSource = CancelToken.source();
export const isCanceled = axios.isCancel;

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  cancelToken: cancelSource.token,
  headers: {
    "Content-Type": "application/json",
    accept: "*/*",
  },
  withCredentials: true,
});
