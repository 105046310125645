import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import VehicleDataTypesList from "../../VehicleDataTypesList/VehicleDataTypesList";
import { ScheduleDataDto } from "../../../types";

interface ScheduleCardFileTypesRowProps {
  scheduleData: ScheduleDataDto;
  firstColumnCSS: any;
}

const ScheduleCardFileTypesRow = ({
  scheduleData,
  firstColumnCSS,
}: ScheduleCardFileTypesRowProps) => {
  const { t } = useTranslation();

  return (
    <div data-testid="schedule-card-file-types-row">
      <Typography variant="body2" sx={firstColumnCSS}>
        {t("Schedules##data types")}:
      </Typography>

      <VehicleDataTypesList schedule={scheduleData as any} size="medium" />
    </div>
  );
};

export default ScheduleCardFileTypesRow;
