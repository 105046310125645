import { HTMLAttributes } from "react";

import Tooltip from "./Tooltip";
import { TOOOLTIP_WIDTHS } from "./types";

interface IAutocompleteDisableOptionTooltip {
  props: HTMLAttributes<HTMLLIElement>;
  option: any;
  tooltipTitle: string;
}

const AutocompleteDisbledOptionTooltip = ({
  props,
  option,
  tooltipTitle,
}: IAutocompleteDisableOptionTooltip) => {
  return (
    <Tooltip
      size="large"
      hasArrow={false}
      maxWidth={TOOOLTIP_WIDTHS.MaxTooltipWodthForAutocomplete}
      fromAutocomplete
      placement="top-end"
      title={props["aria-disabled"] ? tooltipTitle : ""}
    >
      <li>
        <span {...props} key={option.id}>{option}</span>
      </li>
    </Tooltip>
  );
};

export default AutocompleteDisbledOptionTooltip;
