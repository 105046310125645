/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import DetailsContent from "../DetailsContent/DetailsContent";
import { useStyles } from "../../styles";
import {
  SECTION_OPEN_DELAY_NOT_RESET,
  SECTION_OPEN_DELAY_ON_RESET,
} from "../../constants";
import { getPageGlobalStateObjectValue } from "../../../Table/functions";
import { STEP_GROUP } from "../../../TutorialMode/types";
import useTutorialMode from "../../../TutorialMode/hooks/useTutorialMode";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import {
  desktopDetailsSectionWidth,
  LOCAL_STORAGE_KEYS,
  updatePageParamsStateInLocalStorage,
  useMediaQueries,
} from "../../../../shared";
import {
  resetDetailsSectionOnPageChange,
  tableData,
  tableDetails,
  tableDetailsSectionState,
} from "../../../../atoms/atoms";
import { TableDetailsDataStateType } from "../../../../atoms/types";

const DesktopDetails = () => {
  const { colors } = useContext(ThemeContext);

  const { toLg } = useMediaQueries();
  const location = useLocation();
  const {
    stepGroup,
    stepIndex,
    callTutorialWithTimeout,
    checkIfCorrectTutorialStep,
  } = useTutorialMode();

  const tableRowData = useRecoilValue(tableData);
  const [, setTableDetails] = useRecoilState(tableDetails);
  const [isDetailsResetNeeded, setIsDetailsResetNeeded] = useRecoilState(
    resetDetailsSectionOnPageChange
  );
  const [isDetailsOpen, setIsDetailsOpen] = useRecoilState(
    tableDetailsSectionState
  );

  const { desktopDetailsAside, detailsContentContainer } = useStyles();

  useEffect(() => {
    isDetailsOpen && tableRowData.length === 0 && handleCloseDesktopDetails();
    // eslint-disable-next-line
  }, [tableRowData]);

  useEffect(() => {
    if (isDetailsOpen) {
      if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 3)) {
        callTutorialWithTimeout(true, stepIndex, stepGroup, true, 300);
      }

      if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialApplySchedule, 5)) {
        callTutorialWithTimeout(true, stepIndex, stepGroup, true, 300);
      }
    }

    // eslint-disable-next-line
  }, [isDetailsOpen, stepIndex]);

  const delay =
    isDetailsResetNeeded && isDetailsOpen
      ? SECTION_OPEN_DELAY_ON_RESET
      : SECTION_OPEN_DELAY_NOT_RESET;

  const handleCloseDesktopDetails = () => {
    setIsDetailsOpen(false);

    setTableDetails((prev: TableDetailsDataStateType | null) =>
      getPageGlobalStateObjectValue(location.pathname, prev, null)
    );
    setIsDetailsResetNeeded(false);
    updatePageParamsStateInLocalStorage(
      location.pathname,
      LOCAL_STORAGE_KEYS.SelectedTableItem,
      null
    );
    updatePageParamsStateInLocalStorage(
      location.pathname,
      LOCAL_STORAGE_KEYS.DesktopDetailsSection,
      false
    );
  };

  return (
    <aside
      data-testid="desktop-details-aside"
      id="desktop-details-aside"
      css={css([
        {
          width:
            isDetailsOpen && tableRowData.length > 0
              ? desktopDetailsSectionWidth(toLg)
              : "0",
          transition: `width 0.3s cubic-bezier(0.4, 0, 0.2, 1) ${delay}`,
          borderLeft: `1px solid var(--Gray-Gray200, ${colors.gray200})`,
        },
        desktopDetailsAside,
      ])}
    >
      <div
        css={css([
          {
            height: `100%`,
          },
          detailsContentContainer,
        ])}
      >
        {isDetailsOpen && tableRowData.length > 0 && (
          <DetailsContent onClose={handleCloseDesktopDetails} />
        )}
      </div>
    </aside>
  );
};

export default DesktopDetails;
