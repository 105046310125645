import { CSSObject } from "@emotion/react";

export const useStyles = () => {
  return {
    dialogContent: {
      alignItems: "center",
      display: "flex",
      columnGap: "4px",
      padding: "0 16px 16px 16px",
    },
  } as CSSObject;
};
