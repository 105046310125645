/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";

import { DownloadLiveStatusDataType, DownloadStatusesTypes } from "../types";
import { useStyles } from "../styles";

import useDownloadStatus from "../../../hooks/useDownloadStatus";
import useDownloadStatusFunctions from "../../../hooks/useDownloadStatusFunctions";

import { useMediaQueries } from "../../../../../../../shared";

interface DownloadStatusDialogContentProps {
  liveStatusData: DownloadLiveStatusDataType;
}

const DownloadStatusDialogContent = ({
  liveStatusData,
}: DownloadStatusDialogContentProps) => {
  const { fromMd } = useMediaQueries();
  const { downloadLiveStatuses, isLoading } = useDownloadStatus();
  const {
    successStep,
    currentStep,
    notCompletedStep,
    renderStepsLoader,
    renderErrorBanner,
  } = useDownloadStatusFunctions({
    downloadLiveStatuses,
  });

  const [currentValueIndex, setCurrentValueIndex] = useState<number | null>(
    null
  );

  const { downloadStatContentContainer } = useStyles({ fromMd });

  const showErrorBanner =
    liveStatusData?.Status === DownloadStatusesTypes.InsufficientCredits ||
    liveStatusData?.Status === DownloadStatusesTypes.VehicleDeactivated;

  useEffect(() => {
    downloadLiveStatuses &&
      setCurrentValueIndex(
        downloadLiveStatuses?.stages.findIndex(
          (stage: any) => stage === liveStatusData?.Stage
        )
      );
  }, [liveStatusData, downloadLiveStatuses]);

  const showCurrentStageInAList = () =>
    downloadLiveStatuses &&
    liveStatusData &&
    downloadLiveStatuses.stages.map((stage: any, index: number) => {
      const isCurrentStage = index === currentValueIndex;
      const passedSuccessStage = currentValueIndex && index < currentValueIndex;

      return (
        <Fragment key={stage}>
          {showErrorBanner
            ? notCompletedStep(index, stage)
            : currentStep(
                isCurrentStage,
                index,
                stage,
                liveStatusData.Finished
              ) ||
              successStep(Boolean(passedSuccessStage), index, stage) ||
              notCompletedStep(index, stage)}
        </Fragment>
      );
    });

  return (
    <div data-testid="download-status-dialog-content">
      {!liveStatusData || isLoading ? (
        renderStepsLoader()
      ) : (
        <>
          {showErrorBanner && renderErrorBanner(liveStatusData)}
          <div css={css(downloadStatContentContainer)}>
            {showCurrentStageInAList()}
          </div>
        </>
      )}
    </div>
  );
};

export default DownloadStatusDialogContent;
