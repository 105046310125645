/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import useCompanies from "../../../../hooks/useCompanies";
import { useStyles } from "../../../../../../styles";
import Autocomplete from "../../../../../../../Autocomplete/Autocomplete";
import { CompaniesDto } from "../../../../../../../../shared";

interface RootCompanyProps {
  userRootCompany: CompaniesDto;
}

const RootCompany = ({ userRootCompany }: RootCompanyProps) => {
  const { t } = useTranslation();
  const { rootCompanyList, getQueryData } = useCompanies();

  const { rootCompanyDiv } = useStyles();

  const [selectedRootCompany, setSelectedRootCompany] = useState(
    userRootCompany
  );

  const handleRootCompanyChange = (value: CompaniesDto) => {
    setSelectedRootCompany(value);
    getQueryData(undefined, value.id);
  };

  return (
    <div css={css(rootCompanyDiv)}>
      <Autocomplete
        size="medium"
        labelLeft={t("Selector##client company")}
        fullWidth
        disableClearable
        value={selectedRootCompany}
        options={rootCompanyList}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_: SyntheticEvent, value: CompaniesDto) =>
          handleRootCompanyChange(value)
        }
      />
    </div>
  );
};

export default RootCompany;
