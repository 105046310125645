import { useTranslation } from "react-i18next";

import PasswordsContainer from "../../PasswordsContainer/PasswordsContainer";
import { BUTTON_TYPE } from "../../../types";
import usePasswordsFormInputs from "../../../hooks/usePasswordsFormInputs";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { resetPaswordFormInputs } = usePasswordsFormInputs();

  return (
    <PasswordsContainer
      inputs={resetPaswordFormInputs}
      button={BUTTON_TYPE.ResetPsw}
      name={t("Auth##reset password")}
    />
  );
};

export default ResetPassword;
