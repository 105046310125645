import { useTranslation } from "react-i18next";

import { CompanyCardObjectDto } from "../types";

import { COMPANY_CARD_STATUS, useDateTime } from "../../../../../shared";
import WarningIcon from "../../../../../assets/customIcons/status/WarningIcon";
import InfoIcon from "../../../../../assets/customIcons/status/InfoIcon";

interface UseCardStatusFunctionsProps {
  card: CompanyCardObjectDto;
}

const useCardStatusFunctions = ({ card }: UseCardStatusFunctionsProps) => {
  const { convertToCurrentDateFormat, formattedDate } = useDateTime();
  const { t } = useTranslation();

  const currDate = new Date();

  const treeMonthsPlusFromNow = convertToCurrentDateFormat(
    new Date(currDate.setMonth(currDate.getMonth() + 3)).toISOString()
  );

  const today = convertToCurrentDateFormat(new Date().toISOString());
  const expirationDate =
    card.validUntil && convertToCurrentDateFormat(card.validUntil);

  const checkIfExpirationDayIsInThreeMonths = () => {
    if (expirationDate) {
      return expirationDate > today && expirationDate <= treeMonthsPlusFromNow;
    }
  };

  const checkIfExpired = () => {
    if (expirationDate) return expirationDate < today;
  };

  const disconnectedStatusText = checkIfExpirationDayIsInThreeMonths()
    ? t("Dashboard##tooltip##disconnected card expire soon", {
        lastSeen: formattedDate(card.disconnectedAt),
        expirationDate:
          card.validUntil && convertToCurrentDateFormat(card.validUntil),
      })
    : t("Dashboard##tooltip##disconnected card", {
        lastSeen: formattedDate(card.disconnectedAt),
      });

  const connectedStatusInExpireTooltipText = t(
    "Dashboard##tooltip##connected card expire soon",
    {
      expirationDate:
        card.validUntil && convertToCurrentDateFormat(card.validUntil),
    }
  );

  const warningBadgeIcon = (status: COMPANY_CARD_STATUS) =>
    checkIfExpirationDayIsInThreeMonths() ? (
      <WarningIcon
        fillColor={
          status === COMPANY_CARD_STATUS.Connected ? "white" : undefined
        }
      />
    ) : (
      undefined
    );

  const expiredBadgeIcon = <InfoIcon />;

  const expiredStatusText = t("Dashboard##tooltip##expired card", {
    expirationDate:
      card.validUntil && convertToCurrentDateFormat(card.validUntil),
  });

  return {
    disconnectedStatusText,
    checkIfExpirationDayIsInThreeMonths,
    warningBadgeIcon,
    checkIfExpired,
    expiredBadgeIcon,
    expiredStatusText,
    connectedStatusInExpireTooltipText,
  };
};

export default useCardStatusFunctions;
