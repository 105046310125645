/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import DeleteCircleIcon from "../../../assets/customIcons/actions/DeleteCircleIcon";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface ClearInputIconProps {
  value: string;
  clearValue: () => void;
}

export const ClearInputIcon = ({ value, clearValue }: ClearInputIconProps) => {
  const { colors } = useContext(ThemeContext);

  return value.length > 0 ? (
    <DeleteCircleIcon
      onMouseDown={clearValue}
      css={css({
        color: colors.textPlaceholder,
        cursor: "pointer",
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

        "&:hover": {
          color: colors.gray500,
        },
      })}
    />
  ) : null;
};
