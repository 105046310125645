import { useTranslation } from "react-i18next";

import { SCHEDULE_STATUS, ScheduleDataDto } from "../../types";
import Dialog from "../../../Dialog/Dialog";
import { DialogWidth } from "../../../Dialog/types/enums";

import { TABLE_NAMES, useMediaQueries } from "../../../../shared";
import DialogActions from "../../../../shared/components/buttons/DialogActions";

interface ConfirmCloseDialogProps {
  resourceName: TABLE_NAMES;
  isOpen: boolean;
  isLoading: boolean;
  selectedSchedule: ScheduleDataDto | null;
  closeWithMainDialog: (
    closeSchedules: boolean,
    closeConfirmOnly: boolean
  ) => void;
  handleSubmit: () => Promise<void>;
}

const ConfirmCloseDialog = ({
  resourceName,
  isOpen,
  isLoading,
  selectedSchedule,
  closeWithMainDialog,
  handleSubmit,
}: ConfirmCloseDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const isScheduleSelected = selectedSchedule !== null;

  const dialogText = {
    title: t(
      `Schedules##confirm close##title##${
        isScheduleSelected
          ? SCHEDULE_STATUS.Selected
          : SCHEDULE_STATUS.NotSelected
      }`
    ),
    description: t(
      `Schedules##confirm close##description##${resourceName}##${
        isScheduleSelected
          ? SCHEDULE_STATUS.Selected
          : SCHEDULE_STATUS.NotSelected
      }`
    ),
    cancelButton: isScheduleSelected
      ? t("Button##don't apply")
      : t("Button##cancel"),
    confirmationButton: isScheduleSelected
      ? t("Button##apply")
      : t("Button##yes"),
  };

  const handleCancelButton = (closeConfirmOnly: boolean) => {
    closeWithMainDialog(Boolean(selectedSchedule), closeConfirmOnly);
  };

  const handleApply = () => {
    isScheduleSelected ? handleSubmit() : closeWithMainDialog(true, false);
  };

  return (
    <Dialog
      data-testid="confirm-close-schedules-dialog"
      title={dialogText.title}
      description={dialogText.description}
      open={isOpen}
      isTitleSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      close={() => handleCancelButton(true)}
      submit={handleApply}
      actions={
        <DialogActions
          onClose={() => handleCancelButton(false)}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={dialogText.confirmationButton}
          confirmationButtonTestId="confirm-close-schedules-confirmation-button"
          cancelButtonText={dialogText.cancelButton}
          cancelButtonTestId="confirm-close-schedules-cancel-button"
        />
      }
    />
  );
};

export default ConfirmCloseDialog;
