import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import ChangePasswordDialogContent from "./components/ChangePasswordDialogContent";
import { useAuth } from "../../../../../../../../context/Auth/AuthProvider";
import useAxiosPrivate from "../../../../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../../../../shared/hooks/useResponse";
import {
  BUTTON_TYPE,
  PASSWORD_TYPE,
  PasswordInputsType,
} from "../../../../../../../../Pages/Auth/types";
import useTogglePswVisibility from "../../../../../../../../Pages/Auth/hooks/useTogglePswVisibility";
import { ApiResources } from "../../../../../../../../api/resources";
import { updateTokens } from "../../../../../../../../shared/functions/auth";
import { ALERT_STATUS } from "../../../../../../../../context/alert/types";
import Dialog from "../../../../../../../Dialog/Dialog";
import DialogActions from "../../../../../../../../shared/components/buttons/DialogActions";
import { DialogWidth } from "../../../../../../../Dialog/types/enums";

interface IChangePasswordDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

const ChangePasswordDialog = ({
  isOpen,
  closeDialog,
}: IChangePasswordDialogProps) => {
  const { setTokenInfo } = useAuth();
  const { postData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [resetValues, setResetValues] = useState<boolean>(false);
  const [payload, setPayload] = useState<{} | null>(null);

  const inputs: PasswordInputsType[] = [
    {
      name: PASSWORD_TYPE.Current,
      title: t("Auth##enter current password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.Current, setResetValues),
    },
    {
      name: PASSWORD_TYPE.NewPsw,
      title: t("Auth##enter new password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.NewPsw, setResetValues),
    },
    {
      name: PASSWORD_TYPE.Repeat,
      title: t("Auth##repeat new password"),
      TooltipPsw: useTogglePswVisibility(PASSWORD_TYPE.Repeat, setResetValues),
    },
  ];

  const handleSubmit = async () => {
    if (ready) {
      try {
        setIsLoading(true);
        const response = await postData(ApiResources.ChangePassword, payload);

        if (response) {
          updateTokens(setTokenInfo, response);
          setResetValues(true);
          closeDialog();
          handleResponse(ALERT_STATUS.Success, t("Auth##password changed"));
        }
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          const incorrectPasswordMessage =
            err.response?.data && err.response?.data?.entries[0].message;

          handleResponse(
            ALERT_STATUS.Critical,
            incorrectPasswordMessage ||
              err.response?.data.message ||
              err.message
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      data-testid="change-password-dialog"
      noValidate
      title={t("Password##change password")}
      open={isOpen}
      close={closeDialog}
      isTitleSeparator
      isActionsSeparator
      submit={handleSubmit}
      width={DialogWidth.MinWidth}
      TransitionProps={{
        onExited: () => setResetValues(true),
      }}
      actions={
        <DialogActions
          onClose={closeDialog}
          isLoading={isLoading}
          isreadyToConfirm={ready}
          confirmationButtonText={t("Button##change")}
          cancelButtonTestId={`cancel-change-password`}
          confirmationButtonTestId={`accept-change-password`}
          cancelButtonText={t("Button##cancel")}
        />
      }
    >
      <ChangePasswordDialogContent
        inputs={inputs}
        button={BUTTON_TYPE.ChangePsw}
        setReady={setReady}
        resetValues={resetValues}
        setPayload={setPayload}
      />
    </Dialog>
  );
};

export default ChangePasswordDialog;
