/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import InputField from "../../InputField/InputField";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const TextField = ({ timeZoneExplanatory, ...restProps }: any) => {
  const { error, helperText, InputProps, placeholder, value } = restProps;

  const {
    colors: { textPlaceholder },
  } = useContext(ThemeContext);

  const isTimeZoneExplanatory =
    timeZoneExplanatory &&
    value.length !== 0 &&
    value !== placeholder &&
    !error;

  return (
    <div
      css={css({
        position: "relative",
      })}
    >
      <InputField
        {...restProps}
        fullWidth
        size="medium"
        readOnly={InputProps.readOnly}
        errorText={helperText}
      />

      {isTimeZoneExplanatory && (
        <span
          css={css({
            position: "absolute",
            top: "0",
            left: "90px",
            display: "flex",
            alignItems: "center",
            color: textPlaceholder,
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            padding: "12px 0",
          })}
        >
          {timeZoneExplanatory}
        </span>
      )}
    </div>
  );
};

export default TextField;
