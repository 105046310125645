/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { QuickDownloadPayloadDto, SCHEDULE_DATE_TYPE } from "../types";

import DatePicker from "../../../../../../DatePicker/DatePicker";

import DownloadDataTypesCheckboxes from "../../../../../../Schedules/components/DownloadDataTypesCheckboxes";
import { VehicleDataTypes, useDateTime } from "../../../../../../../shared";
import { ThemeContext } from "../../../../../../../context/theme/ThemeContextProvider";

interface VehicleQuickDownloadScheduleDialogContentProps {
  quickDownloadPayload: QuickDownloadPayloadDto;
  inputError: {
    startDate: string;
    endDate: string;
  };
  setInputError: any;
  anyErrorsExist: boolean;
  setQuickDownloadPayload: Dispatch<SetStateAction<QuickDownloadPayloadDto>>;
}

const VehicleQuickDownloadScheduleDialogContent = ({
  quickDownloadPayload,
  inputError,
  anyErrorsExist,
  setInputError,
  setQuickDownloadPayload,
}: VehicleQuickDownloadScheduleDialogContentProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();
  const { convertDateToUTC, getUTCDate } = useDateTime();

  const { datePickersCSS } = useStyles(anyErrorsExist);

  const { fileTypes, activities } = quickDownloadPayload;

  const defaultDateValuesWithActivities = {
    startDate: getUTCDate()
      .subtract(1, "week")
      .format(),
    endDate: getUTCDate().format(),
  };

  const isActivitiesChecked = fileTypes.includes(VehicleDataTypes.Activities);

  useEffect(() => {
    setQuickDownloadPayload((prevPayload) => ({
      ...prevPayload,
      activities: isActivitiesChecked ? defaultDateValuesWithActivities : null,
    }));

    setInputError({
      startDate: "",
      endDate: "",
    });
    // eslint-disable-next-line
  }, [isActivitiesChecked]);

  const validateStartDate = (
    error: string | null | SetStateAction<string | null>,
    name: SCHEDULE_DATE_TYPE
  ) => {
    const errorMessage = error ? String(error) : "";

    setInputError({
      ...inputError,
      [name]: errorMessage,
    });
  };

  const handleDataTypes = (value: VehicleDataTypes) => {
    const isAlreadySelected = fileTypes.includes(value)
      ? fileTypes.filter((val) => val !== value)
      : [...fileTypes, value];

    setQuickDownloadPayload({
      ...quickDownloadPayload,
      fileTypes: isAlreadySelected,
    });
  };

  const handleDateChange = (value: string | null, name: SCHEDULE_DATE_TYPE) => {
    const newDate = value ?? "";

    const convertedDate = convertDateToUTC(newDate);

    setQuickDownloadPayload((prevPayload) => ({
      ...prevPayload,
      activities: {
        ...(prevPayload.activities ?? { startDate: "", endDate: "" }),
        [name]: convertedDate,
      },
    }));
  };

  return (
    <>
      <DownloadDataTypesCheckboxes
        selectedDataTypes={fileTypes}
        handleChange={(_, value) => handleDataTypes(value)}
      />

      {fileTypes.includes(VehicleDataTypes.Activities) && (
        <>
          <Typography
            variant="body2"
            sx={{ color: colors.textSecondary, margin: "16px 0 4px 0" }}
          >
            {t("Dialog##download activities")}
          </Typography>

          <div css={css(datePickersCSS)}>
            <DatePicker
              label={t("Dialog##from")}
              disableFuture
              initialDate={defaultDateValuesWithActivities.startDate}
              setDate={(value) =>
                handleDateChange(value, SCHEDULE_DATE_TYPE.StartDate)
              }
              maxDate={getUTCDate(activities?.endDate) ?? undefined}
              externalErrorHandle={(error) =>
                validateStartDate(error, SCHEDULE_DATE_TYPE.StartDate)
              }
            />

            <DatePicker
              label={t("Dialog##to")}
              disableFuture
              initialDate={defaultDateValuesWithActivities.endDate}
              setDate={(value) =>
                handleDateChange(value, SCHEDULE_DATE_TYPE.EndDate)
              }
              externalErrorHandle={(error) =>
                validateStartDate(error, SCHEDULE_DATE_TYPE.EndDate)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default VehicleQuickDownloadScheduleDialogContent;
