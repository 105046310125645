/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SettingsContent from "./SettingsContent/SettingsContent";
import { useStyles } from "../../../styles";
import Menu from "../../../../Menu/Menu";
import { MenuAnchorEl } from "../../../../../shared";
import { DIALOG_NAMES } from "../../../types/enums";

interface SettingsMenuDesktopProps {
  isMobile: boolean;
  anchorEl: MenuAnchorEl;
  close: () => void;
  openDialog: (name: DIALOG_NAMES) => void
}

const SettingsMenuDesktop = ({
  isMobile,
  anchorEl,
  close,
  openDialog
}: SettingsMenuDesktopProps) => {
  const { settingsDesktopMenu } = useStyles();

  return (
    <Menu
      data-testid="settings-desktop-menu"
      open={Boolean(anchorEl)}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{ disableListWrap: true }}
      css={css(settingsDesktopMenu)}
    >
      <SettingsContent isMobile={isMobile} closeMenu={close} openDialog={openDialog}/>
    </Menu>
  );
};

export default SettingsMenuDesktop;
