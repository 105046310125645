import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = () =>
  ({
    errorIcon: {
      color: COLORS.Red700,
      fontSize: "16px",
      margin: "2px 4px 0 0",
      alignSelf: "flex-start",
    },
  } as CSSObject);
