/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useState } from "react";

import { useStyles } from "../../styles";
import {
  checkIfPositiveNumber,
  generateNumberStringWithPlusOrMinus,
} from "../../functions";

import { CreditsDto, CreditsHistoryTransactionObjectDto } from "../../../types";

import {
  COLORS,
  MenuAnchorEl,
  ThemeColors,
  useDateTime,
} from "../../../../../../shared";
import AccordionDetailsCard from "../../../../../../components/DetailsSection/components/DetailsContent/components/AccordionDetailsCard/AccordionDetailsCard";
import SingleDetailsAcordionItem from "../../../../../../components/DetailsSection/components/DetailsContent/components/AccordionDetailsCard/components/SingleDetailsAcordionItem";
import DetailsCardItemTitle from "../../../../../../components/DetailsSection/components/DetailsContent/components/DetailsCardItemTitle";
import DetailsCardItemText from "../../../../../../components/DetailsSection/components/DetailsContent/components/DetailsCardItemText";
import Separator from "../../../../../../components/Separator/Separator";

interface CreditsHistoryInfoProps {
  creditsData: CreditsDto;
  colors: ThemeColors;
  renderFormattedNumber: (number: number) => string;
  formattedNumberColor: (positiveNumber: boolean, incoming?: boolean) => COLORS;
}

const CreditsHistoryInfo = ({
  colors,
  creditsData,
  renderFormattedNumber,
  formattedNumberColor,
}: CreditsHistoryInfoProps) => {
  const { convertToCurrentDateFormat } = useDateTime();
  const { t } = useTranslation();

  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);

  const {
    dialogContentSectionTitle,
    singleAcordionItemBlock,
    itemSeparator,
  } = useStyles({});

  const renderSingleYearAccordionBlock = (
    transaction: CreditsHistoryTransactionObjectDto
  ) => (
    <div css={css(singleAcordionItemBlock)}>
      <DetailsCardItemTitle
        title={convertToCurrentDateFormat(transaction.date) || ""}
        showColon={false}
      />
      <DetailsCardItemText
        // render number with + or - sign
        textToRender={generateNumberStringWithPlusOrMinus(
          renderFormattedNumber(transaction.amount)
        )}
        customStyle={{
          color: formattedNumberColor(
            checkIfPositiveNumber(transaction.amount),
            true
          ),
        }}
      />
    </div>
  );

  return (
    <>
      <div css={css(dialogContentSectionTitle)}>
        <Typography variant="body2" sx={{ color: colors.textDark }}>
          {t("General##history")}
        </Typography>
      </div>

      <AccordionDetailsCard
        showTitleArea={false}
        customStyle={{ margin: 0 }}
        testid="credits-history-accordion-details"
      >
        {creditsData.history.map((item, index) => (
          <SingleDetailsAcordionItem
            key={item.year}
            expandFirstItem={false}
            title={t("General##year") + `: ${item.year}`}
            totalCount={creditsData.history.length}
            index={index}
            collapsable
            anchorEl={isActionsOpen}
            setIsActionsOpen={setIsActionsOpen}
            showFirstTopSeparator={index !== 0}
            fromDialog
          >
            {item.transactions.length > 0 &&
              item.transactions.map((transaction, index) => (
                // single year block
                <div key={transaction.date}>
                  {renderSingleYearAccordionBlock(transaction)}

                  {/* item separator */}
                  {index < item.transactions.length - 1 && (
                    <Separator color={colors.gray200} style={itemSeparator} />
                  )}
                </div>
              ))}
          </SingleDetailsAcordionItem>
        ))}
      </AccordionDetailsCard>
    </>
  );
};

export default CreditsHistoryInfo;
