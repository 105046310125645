/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import {
  CircularProgress,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  Typography,
} from "@mui/material";
import { useRecoilValue } from "recoil";

import { useStyles } from "./styles";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import useRadioIcons from "../../shared/hooks/useRadioIcons";
import { tableLoading } from "../../atoms/atoms";
import { CompaniesDto } from "../../shared";

export interface IRadioListItemProps {
  label?: string;
  info?: string;
  isError?: boolean;
  selectedValue?: string | CompaniesDto;
}

const RadioListItem = ({
  label,
  info,
  isError,
  selectedValue,
  ...props
}: IRadioListItemProps & MuiRadioProps) => {
  const {
    colors: { blue100, blue200, blue700, blue800, gray300, gray400, textDark },
  } = useContext(ThemeContext);
  const { getIcons } = useRadioIcons();
  const isTableLoading = useRecoilValue(tableLoading);

  const {
    radioListItemLabel,
    radioListItemContentHolder,
    radioLoaderDiv,
  } = useStyles();

  const icon = getIcons(props, isError).icon;
  const checkedIcon = getIcons(props, isError).checkedIcon;

  const isDefault = !props.disabled && !isError;

  const isSelectedItem = selectedValue === props.value;

  return (
    <label
      css={css([
        {
          color: textDark,

          "&:hover": {
            backgroundColor: blue100,
          },

          "&:active": {
            backgroundColor: blue200,
          },
        },
        radioListItemLabel,
      ])}
    >
      <div css={css(radioListItemContentHolder)}>
        <Typography
          variant="body2"
          css={css({
            color: isTableLoading && !isSelectedItem ? gray300 : "inherit",
          })}
        >
          {label}
        </Typography>

        {isTableLoading && isSelectedItem ? (
          <div css={css(radioLoaderDiv)}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <MuiRadio
            data-testid="radio"
            {...props}
            size="small"
            icon={icon}
            checkedIcon={checkedIcon}
            disabled={isTableLoading}
            sx={{
              padding: "0",
              marginRight: label || info ? "8px" : "0",
              color: gray300,

              "& > svg": {
                fontSize: "24px",
              },

              "&:hover": {
                color: isDefault ? gray400 : "unset",
              },
              "&.Mui-checked": {
                color: blue700,
              },
              "&:hover.Mui-checked": {
                color: isDefault ? blue800 : "unset",
              },
            }}
          />
        )}
      </div>
    </label>
  );
};

export default RadioListItem;
