import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

export const useStyles = (
  colors?: ThemeColors,
  highlightAction?: any,
  bgColorHover?: string,
  bgColorRest?: string,
  bgColorActive?: string
) =>
  ({
    buttonVariant: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconLeftButton: {
      display: "flex",
      alignItems: "center",
      marginRight: "8px",
    },
    iconRightButton: {
      display: "flex",
      alignItems: "center",
      marginLeft: "8px",
    },
    defaultButtonBackgrounds: {
      backgroundColor: bgColorRest,

      "&:hover": {
        backgroundColor: bgColorHover,
      },

      "&:active": {
        backgroundColor: bgColorActive,
      },
    },
    highlightedButton: {
      zIndex: 1,
      backgroundColor: "transparent",

      "&:after, &:before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "100%",
        borderRadius: "6px",
        transition: "all 450ms",
      },
      "&:after": {
        width: "100%",
        backgroundColor: bgColorRest,
        zIndex: -2,
      },
      "&:before": {
        width: highlightAction ? "100%" : "0%",
        backgroundColor: colors?.blue700,
        zIndex: -1,
        animation: highlightAction ? "highlightPulse 750ms 1 250ms" : "unset",
      },
      "&:hover": {
        "&:after, &:before": {
          backgroundColor: bgColorHover,
        },
        backgroundColor: bgColorHover,
      },

      "&:active": {
        "&:after, &:before": {
          backgroundColor: bgColorActive,
        },
      },

      "@keyframes highlightPulse": {
        "50%": {
          outline: `3px solid ${colors?.blue600}`,
        },
      },
    },
  } as CSSObject);
