import { TableBody as MuiTableBody } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

import TableRow from "../DesktopTable/components/TableRow";
import { isRowDisabled, getPageGlobalStateObjectValue } from "../../functions";
import useTableItemDetails from "../../../DetailsSection/hooks/useTableItemdetails";
import {
  getSelectedTableItemFromLocalStorage,
  TableRowData,
} from "../../../../shared";
import {
  rowsEnabledToSelect,
  tableData,
  tableDetails,
  tableName,
} from "../../../../atoms/atoms";

interface ITableBodyProps {
  scrollHorizontal: boolean;
  hasDetails: boolean;
}

const TableBody = ({ scrollHorizontal, hasDetails }: ITableBodyProps) => {
  const tableRows = useRecoilValue<any>(tableData);
  const location = useLocation();

  const { getTableItemDetails } = useTableItemDetails();

  const [, setTableDetails] = useRecoilState(tableDetails);
  const currentTableName = useRecoilValue(tableName);
  const selectableRows = useRecoilValue(rowsEnabledToSelect);

  const [companyLevelsList, setCompanyLevelsList] = useState(undefined);

  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      if (tableRows) {
        if (!getSelectedTableItemFromLocalStorage(location.pathname)) {
          setTableDetails((prev: any) =>
            getPageGlobalStateObjectValue(location.pathname, prev, null)
          );
        } else {
          if (
            currentTableName &&
            location.pathname.includes(currentTableName)
          ) {
            getTableItemDetails({
              itemId: getSelectedTableItemFromLocalStorage(location.pathname),
            });
          }
        }
      } else {
        setTableDetails((prev: any) =>
          getPageGlobalStateObjectValue(location.pathname, prev, null)
        );
      }
    }
  }, [
    currentTableName,
    getTableItemDetails,
    location.pathname,
    setTableDetails,
    tableRows,
  ]);

  useEffect(() => {
    setCompanyLevelsList(
      tableRows?.map((row: any) => {
        return row.level;
      })
    );
  }, [tableRows]);

  return (
    tableRows && (
      <MuiTableBody data-testid="table-body">
        {tableRows?.map((row: TableRowData, index: number) => (
          <TableRow
            key={row.id}
            row={row}
            scrollHorizontal={scrollHorizontal}
            hasDetails={hasDetails}
            disabled={isRowDisabled(selectableRows, row)}
            index={index}
            companyLevelsList={companyLevelsList}
          />
        ))}
      </MuiTableBody>
    )
  );
};

export default TableBody;
