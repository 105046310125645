/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useStyles } from "../../styles";

interface DialogContentWrapperProps {
  children: React.ReactNode;
  maxHeight?: string;
  testId?: string;
}

const DialogContentWrapper = ({
  children,
  maxHeight,
  testId,
}: DialogContentWrapperProps) => {
  const { dialogContentWrapper } = useStyles({ maxHeight });

  return (
    <div
      css={css(dialogContentWrapper)}
      data-testid={testId || "dialog-content-wrapper"}
    >
      {children}
    </div>
  );
};

export default DialogContentWrapper;
