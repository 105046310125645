import { useTranslation } from "react-i18next";
import { INPUT_LENGTH, numbersOnly } from "../../../../shared";

const useCompaniesCardsFormInputs = ({ ...props }: any) => {
  const { t } = useTranslation();

  const {
    setCardNameInputError,
    setCardName,
    setCardIdInputError,
    setCardId,
  } = props;

  const isCardNameValid = (name: string) => {
    const cardName = name.trim();

    return (
      name &&
      cardName.length <= INPUT_LENGTH.MaxCompanyCardNameLength &&
      cardName.length >= INPUT_LENGTH.MinCompanyCardNameLength
    );
  };

  const isCardIdValid = (id: string | null) => {
    const trimmedId = id?.trim();
    const notTooLongId =
      trimmedId && trimmedId.length <= INPUT_LENGTH.MaxCompanyCardIdLength;
    const notTooShortId =
      trimmedId && trimmedId.length >= INPUT_LENGTH.MinCompanyCardIdLength;

    return id && notTooLongId && notTooShortId && numbersOnly.test(trimmedId);
  };

  const validateCardNameLength = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length < INPUT_LENGTH.MinCompanyCardNameLength) {
      setCardNameInputError(
        t("Dialog##card name too short", {
          minNumber: INPUT_LENGTH.MinCompanyCardNameLength,
        })
      );
    }
    if (inputValue.length > INPUT_LENGTH.MaxCompanyCardNameLength) {
      setCardNameInputError(
        t("Dialog##card name not valid", {
          maxNumber: INPUT_LENGTH.MaxCompanyCardNameLength,
        })
      );
    }
    if (inputValue.length === 0) {
      setCardNameInputError(t("Dialog##card name should not be empty"));
    }
  };

  const validateCardId = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length < INPUT_LENGTH.MinCompanyCardIdLength) {
      setCardIdInputError(
        t("Dialog##card id too short", {
          minNumber: INPUT_LENGTH.MinCompanyCardIdLength,
        })
      );
    }
    if (inputValue.length > INPUT_LENGTH.MaxCompanyCardIdLength) {
      setCardIdInputError(
        t("Dialog##card id not valid", {
          maxNumber: INPUT_LENGTH.MaxCompanyCardIdLength,
        })
      );
    }
    if (inputValue.length === 0) {
      setCardIdInputError(t("Dialog##card id should not be empty"));
    }
    if (!numbersOnly.test(inputValue)) {
      setCardIdInputError(t("Dialog##card id should contain only numbers"));
    }
  };

  const handleCardNameChangeValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setCardName(e.target.value);
    validateCardNameLength(e);
    setCardNameInputError(null);
  };

  const handleCardIdChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setCardId(e.target.value);
    validateCardId(e);
    setCardIdInputError(null);
  };

  return {
    isCardNameValid,
    validateCardNameLength,
    handleCardNameChangeValue,
    isCardIdValid,
    validateCardId,
    handleCardIdChangeValue,
  };
};

export default useCompaniesCardsFormInputs;
