/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import RectangleLoader from "./RectangleLoader";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface HeaderTitleLoaderProps {
  dashboardPage?: boolean;
}

const HeaderTitleLoader = ({ dashboardPage }: HeaderTitleLoaderProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <div css={css({ display: "flex" })} data-testid="header-title-loader">
      <RectangleLoader
        width={216}
        borderRadius={"46px"}
        backgroundColor={colors.gray200}
        height={24}
        customStyle={{ marginRight: "22px" }}
      />
      {!dashboardPage && (
        <RectangleLoader
          width={38}
          borderRadius={"46px"}
          backgroundColor={colors.gray200}
          height={24}
        />
      )}
    </div>
  );
};

export default HeaderTitleLoader;
