import { Children, cloneElement } from "react";

import Dialog, { CombinedDialogProps } from "../../../Dialog/Dialog";

interface DialogWithConfirmationProps {
  count: number;
  confirmationDescription: string;
}

const DialogWithConfirmation = ({
  count,
  confirmationDescription,

  ...props
}: DialogWithConfirmationProps & CombinedDialogProps) => {
  return (
    <Dialog
      {...props}
      isTitleSeparator
      isActionsSeparator
      title={props.title}
      description={confirmationDescription}
      submit={props.submit}
      TransitionProps={{
        onExited: (node) => {
          props.TransitionProps?.onExited &&
            props.TransitionProps?.onExited(node);
        },
      }}
      actions={Children.map(props.actions.props.children, (child) => {
        const isSubmitBtn = child.props.type === "submit";

        return cloneElement(child, {
          css: [
            child.props.css,
            {
              transition: "unset",
              "&:hover": {
                transition:
                  "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              },
            },
          ],
          ...(isSubmitBtn && {}),
        });
      })}
    >
      {props.children}
    </Dialog>
  );
};

export default DialogWithConfirmation;
