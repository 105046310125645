import { useTranslation } from "react-i18next";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { getButtonText } from "../functions";

import ListChoice from "../../../ListChoice/ListChoice";
import { Actions } from "../../../PageHeader/actions/types";
import Tooltip from "../../../Tooltip/Tooltip";

interface ActionListChoiceProps {
  action: Actions;
  disabledAction: boolean;
  tooltipTitle: string;
  onActionClick: ((value?: any) => void) | undefined;
  getIconLeft: (action: Actions) => EmotionJSX.Element;
}

const ActionListChoice = ({
  action,
  disabledAction,
  tooltipTitle,
  onActionClick,
  getIconLeft,
}: ActionListChoiceProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      key={action}
      title={disabledAction ? tooltipTitle : ""}
      placement="top"
      hasArrow
    >
      <span>
        <ListChoice
          key={action}
          title={getButtonText(action, t)}
          iconLeft={getIconLeft(action)}
          onClick={onActionClick}
          className={disabledAction ? "action-disabled" : ""}
        />
      </span>
    </Tooltip>
  );
};

export default ActionListChoice;
