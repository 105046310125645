/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import LogoIcon from "../../../assets/customIcons/contacts/LogoIcon";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const AuthLoader = () => {
  const {
    colors: { blue800 },
  } = useContext(ThemeContext);

  return (
    <div
      css={css({
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <LogoIcon
        color="primary"
        css={css({
          color: blue800,
          animationName: "pulse",
          animationDuration: "1.5s",
          animationTimingFunction: "linear",
          animationIterationCount: "infinite",
          animationDirection: "alternate",

          "@keyframes pulse": {
            from: {
              transform: "scale(1)",
            },
            to: {
              transform: "scale(1.3)",
            },
          },
        })}
      />
    </div>
  );
};

export default AuthLoader;
