import { Dispatch, SetStateAction, useState } from "react";

import RemoveCompanyCardButton from "../../RemoveCompanyCardButton/RemoveCompanyCardButton";
import { CompanyCardDto, DetailsDataType } from "../../../../../../../types";
import EditCompanyCardDialog from "../../../../../../../actions/dialogs/companies/EditCompanyCardDialog/EditCompanyCardDialog";
import RemoveCompanyCardDialog from "../../../../../../../actions/dialogs/companies/RemoveCompanyCardDialog/RemoveCompanyCardDialog";
import {
  COMPANIES_ACTIONS,
  MenuAnchorEl,
} from "../../../../../../../../../shared";
import EditChoice from "../../../../../../../../../shared/components/sharedListChoices/EditChoice/EditChoice";

interface CompanyCardActionsProps {
  cardData: CompanyCardDto;
  companyData: DetailsDataType;
  onActionsMenu: Dispatch<SetStateAction<MenuAnchorEl>>;
}

const CompanyCardActions = ({
  cardData,
  companyData,
  onActionsMenu,
}: CompanyCardActionsProps) => {
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = (action: COMPANIES_ACTIONS) => {
    action === COMPANIES_ACTIONS.RemoveCompanyCard
      ? setIsRemoveDialogOpen(true)
      : setIsEditDialogOpen(true);
  };

  const handleCloseDialog = (action: COMPANIES_ACTIONS) => {
    action === COMPANIES_ACTIONS.RemoveCompanyCard
      ? setIsRemoveDialogOpen(false)
      : setIsEditDialogOpen(false);
    onActionsMenu && onActionsMenu(null);
  };

  return (
    <>
      <EditChoice
        testId="edit-company-card-option"
        onButtonClick={() =>
          handleOpenDialog(COMPANIES_ACTIONS.EditCompanyCard)
        }
      />
      <RemoveCompanyCardButton
        onOptionClick={() =>
          handleOpenDialog(COMPANIES_ACTIONS.RemoveCompanyCard)
        }
      />

      {/* Company card actions dialogs */}
      <EditCompanyCardDialog
        isDialogOpen={isEditDialogOpen}
        onCloseDialog={() =>
          handleCloseDialog(COMPANIES_ACTIONS.EditCompanyCard)
        }
        cardData={cardData}
        companyData={companyData}
      />

      <RemoveCompanyCardDialog
        isDialogOpen={isRemoveDialogOpen}
        onCloseDialog={() =>
          handleCloseDialog(COMPANIES_ACTIONS.RemoveCompanyCard)
        }
        cardData={cardData}
        companyData={companyData}
      />
    </>
  );
};

export default CompanyCardActions;
