import { useTranslation } from "react-i18next";

import { TRANSLATABLE_FILTER_VALUES } from "../types/enums";
import { TAG_COLORS, TAG_SIZES } from "../../Tag/enums";
import Tag from "../../Tag/Tag";
import { Filter } from "../../../shared";
import RectangleLoader from "../../../shared/components/loaders/RectangleLoader";

interface IFilteredValueTag {
  isInitialLoading: boolean;
  item: Filter;
  onDelete: (filterItem: Filter) => void;
}

const FilteredValueTag = ({
  isInitialLoading,
  item,
  onDelete,
}: IFilteredValueTag) => {
  const { t } = useTranslation();

  const generateTagText = () => {
    const translatableTextArray: string[] = Object.values(
      TRANSLATABLE_FILTER_VALUES
    );

    return translatableTextArray.includes(item.name)
      ? t(`General##${item.name}`)
      : item.name;
  };

  return isInitialLoading ? (
    <RectangleLoader
      testId={`${item.name}-rectangle-loader`}
      key={item.name}
      width={"77px"}
      height={20}
      customStyle={{ padding: "7px 24px 8px 24px" }}
    />
  ) : (
    <Tag
      data-testid={`${item.name}-filter-tag`}
      key={item.name}
      size={TAG_SIZES.Medium}
      color={TAG_COLORS.Primary}
      title={generateTagText()}
      onDelete={() => onDelete(item)}
    />
  );
};

export default FilteredValueTag;
