/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import LinkItem from "./components/LinkItem";
import AdditionalButtons from "./components/AdditionalButtons/AdditionalButtons";

import { useStyles } from "../styles";

import { isItemVisible, LayoutType } from "../../../shared/layout";
import { useReleaseFeatureToggle } from "../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../shared/featureToggles/types";
import { NAVIGATION_ITEMS, ROUTES } from "../../../shared";
import { useAuth } from "../../../context/Auth/AuthProvider";
import { USER_PERMISSIONS } from "../../../roles/types/enums";

const Navigation = () => {
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const { getAccessStatus } = useAuth();

  const { navigationItems } = useStyles();

  const LAYOUT: LayoutType[] = [
    {
      key: NAVIGATION_ITEMS.Dashboard,
      title: NAVIGATION_ITEMS.Dashboard,
      pathname: ROUTES.Dashboard,
      shouldBeVisible: isItemVisible(NAVIGATION_ITEMS.Dashboard),
    },
    {
      key: NAVIGATION_ITEMS.Vehicles,
      title: NAVIGATION_ITEMS.Vehicles,
      pathname: ROUTES.Vehicles,
      shouldBeVisible: isItemVisible(NAVIGATION_ITEMS.Vehicles),
    },
    {
      key: NAVIGATION_ITEMS.Drivers,
      title: NAVIGATION_ITEMS.Drivers,
      pathname: ROUTES.Drivers,
      shouldBeVisible: isItemVisible(NAVIGATION_ITEMS.Drivers),
    },
    {
      key: NAVIGATION_ITEMS.Files,
      title: NAVIGATION_ITEMS.Files,
      pathname: ROUTES.VehicleFiles,
      shouldBeVisible: isItemVisible(NAVIGATION_ITEMS.Files),
    },
    {
      key: NAVIGATION_ITEMS.Companies,
      title: NAVIGATION_ITEMS.Companies,
      pathname: ROUTES.Companies,
      shouldBeVisible:
        isItemVisible(NAVIGATION_ITEMS.Companies) &&
        getAccessStatus(USER_PERMISSIONS.ManageCompanies),
    },
    {
      key: NAVIGATION_ITEMS.Users,
      title: NAVIGATION_ITEMS.Users,
      pathname: ROUTES.Users,
      shouldBeVisible:
        isItemVisible(NAVIGATION_ITEMS.Users) &&
        getAccessStatus(USER_PERMISSIONS.ManageUsers),
    },
    {
      key: NAVIGATION_ITEMS.FileSynchronization,
      title: NAVIGATION_ITEMS.FileSynchronization,
      pathname: ROUTES.FileSynchronization,
      shouldBeVisible: isItemVisible(NAVIGATION_ITEMS.FileSynchronization),
    },
  ];

  const linkItems = LAYOUT.map(
    (layoutItem: LayoutType) =>
      layoutItem.shouldBeVisible && (
        <LinkItem
          key={layoutItem.key}
          name={layoutItem.title}
          link={layoutItem.pathname}
        />
      )
  );

  return (
    <nav data-testid="navigation">
      {/* display additional buttons to open credits dialog and etc. */}
      {isReleaseFeatureEnabled(
        FEATURE_NAMES.ShowAdditionalNavigationButtons
      ) && <AdditionalButtons />}

      <ul css={css(navigationItems)}>{linkItems}</ul>
    </nav>
  );
};

export default Navigation;
