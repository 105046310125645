import { useRecoilState, useRecoilValue } from "recoil";

import useTutorialMode from "./useTutorialMode";
import { STEP_GROUP } from "../types";
import { SETTINGS_CONTENT_NAMES } from "../../Navbar/types/enums";
import { ROUTES } from "../../../shared";
import { mobileDrawerState, tableData } from "../../../atoms/atoms";

const useTutorialStartActions = (
  isMobile: boolean,
  pageLocation: ROUTES,
  setSettingsContent: (newContent: SETTINGS_CONTENT_NAMES) => void,
  closeMenu: () => void
) => {
  const isVehiclesPage = pageLocation === ROUTES.Vehicles;

  const {
    isSettingsOpen,
    clearTableHeadIfApplySchedule,
    handleClearTableHead,
    handleCloseDetailsCompletely,
    setIsSettingsOpen,
    setTutorialDetails,
  } = useTutorialMode();

  const [, setIsMobileDrawerOpen] = useRecoilState(mobileDrawerState);
  const tableRowData = useRecoilValue(tableData);

  const startCompanyCardTutorial = () => {
    if (!isMobile) {
      setSettingsContent(SETTINGS_CONTENT_NAMES.Initial);
      !isSettingsOpen && setIsSettingsOpen(true);
      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: 0,
        stepGroup: STEP_GROUP.TutorialNewCompanyCard,
        isTutorialActive: true,
      });
    } else {
      const startingStep = pageLocation === ROUTES.Companies ? 2 : 0;

      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: startingStep,
        stepGroup: STEP_GROUP.TutorialNewCompanyCard,
        isTutorialActive: true,
      });

      setIsMobileDrawerOpen(false);
    }
  };

  const startCreateScheduleTutorial = () => {
    !isMobile ? closeMenu() : setIsMobileDrawerOpen(false);

    isVehiclesPage && handleCloseDetailsCompletely(ROUTES.Vehicles);

    setTutorialDetails({
      isTutorialOpen: true,
      stepIndex: 0,
      stepGroup: STEP_GROUP.TutorialCreateSchedule,
      isTutorialActive: true,
    });
  };

  const startApplyScheduleTutorial = () => {
    const startingGroupInVehicles =
      tableRowData.length === 0
        ? STEP_GROUP.TutorialAddVehicle
        : STEP_GROUP.TutorialApplySchedule;

    const startingGroup = isVehiclesPage
      ? startingGroupInVehicles
      : STEP_GROUP.TutorialApplySchedule;

    isVehiclesPage && clearTableHeadIfApplySchedule();

    if (!isMobile) {
      closeMenu();

      if (isVehiclesPage) {
        handleClearTableHead();
        handleCloseDetailsCompletely(ROUTES.Vehicles);
      }

      const startingStepInVehicles = tableRowData.length > 0 ? 1 : 0;
      const startingStep = isVehiclesPage ? startingStepInVehicles : 0;

      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: startingStep,
        stepGroup: startingGroup,
        isTutorialActive: true,
      });
    } else {
      setIsMobileDrawerOpen(false);

      const startingStepMobile =
        isVehiclesPage && tableRowData.length > 0 ? 2 : 0;

      setTutorialDetails({
        isTutorialOpen: true,
        stepIndex: startingStepMobile,
        stepGroup: startingGroup,
        isTutorialActive: true,
      });
    }
  };

  return {
    startApplyScheduleTutorial,
    startCompanyCardTutorial,
    startCreateScheduleTutorial,
  };
};

export default useTutorialStartActions;
