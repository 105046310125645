/** @jsxImportSource @emotion/react */
import { css, CSSObject } from "@emotion/react";
import { Fragment, SyntheticEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import TermsOfServiceCheckbox from "./TermsOfServiceCheckbox";
import PasswordValidation from "../PasswordValidation/PasswordValidation";
import { BUTTON_TYPE, PASSWORD_TYPE, TooltipPswProps } from "../../types";
import usePasswordValidation from "../../hooks/usePasswordValidation";
import { useStyles } from "../../styles";
import usePasswordsForm from "../../hooks/usePasswordsForm";

import Button from "../../../../components/Button/Button";
import {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../../components/Button/types/enums";
import InputField from "../../../../components/InputField/InputField";
import { useAuth } from "../../../../context/Auth/AuthProvider";
import { passwordLengthIsValid } from "../../../../shared/functions/functions";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../components/BannerMessage/BannerMessage";
import { inputFieldMarginBottom } from "../../../../shared";

interface IPAsswordsFormProps {
  inputs: Array<any>;
  button: BUTTON_TYPE;
  showPswRequirements: boolean;
  redirectHome: () => void;
}

const PasswordsForm = ({
  inputs,
  button,
  showPswRequirements,
  redirectHome,
}: IPAsswordsFormProps) => {
  const { isLoading } = useAuth();

  const { token, email } = useQueryParams();
  const { validatePasswordLength } = usePasswordValidation();
  const { t } = useTranslation();
  const { submitPasswordsForm } = usePasswordsForm();

  const [authError, setAuthError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [passwordError, setPasswordError] = useState<string>("");
  const [ready, setReady] = useState(false);
  const [password, setPassword] = useState({
    current: "",
    repeat: "",
    newPsw: "",
  });
  const [acceptedTOS, setAcceptedTOS] = useState(false);

  const { blockWithBottomSpacing, termsOfServiceCSS } = useStyles();

  const { current, repeat, newPsw } = password;

  const payload = {
    password: password.newPsw,
    passwordConfirm: password.repeat,
    token,
    email,
  };

  const toggleDisable = useCallback((value: boolean) => {
    setReady(value);
  }, []);

  const handleChange = useCallback(
    (e: { target: { name: string; value: string } }) => {
      const inputName = e.target.name;
      const inputValue = e.target.value;

      setPassword({
        ...password,
        [inputName]: inputValue,
      });
    },
    [password]
  );

  const handleSubmit = (event: SyntheticEvent) => {
    setAuthError("");
    event.preventDefault();
    if (ready) {
      submitPasswordsForm(
        setSubmitLoading,
        button,
        payload,
        redirectHome,
        setAuthError
      );
    }
  };

  const generateNewPasswordError = (inputName: PASSWORD_TYPE) => {
    if (inputName === PASSWORD_TYPE.NewPsw) {
      return passwordLengthIsValid(newPsw) ? "" : passwordError;
    }
    return null;
  };

  const renderInputs = () =>
    inputs.map(
      (input: {
        TooltipPsw: TooltipPswProps;
        name: PASSWORD_TYPE;
        title: string;
      }) => {
        const { name, title } = input;

        const { PasswordVisibility, showPassword } = input.TooltipPsw;

        return (
          <Fragment key={name}>
            <InputField
              fullWidth
              size="medium"
              type={
                showPassword[name as keyof typeof showPassword]
                  ? "text"
                  : "password"
              }
              labelLeft={title}
              name={name}
              placeholder={t("Auth##enter here")}
              onChange={handleChange}
              iconRight={<PasswordVisibility />}
              errorText={generateNewPasswordError(name)}
              onBlur={(event) =>
                validatePasswordLength(event, setPasswordError)
              }
              css={css(blockWithBottomSpacing)}
            />
          </Fragment>
        );
      }
    );

  const isSignUpPage = button === BUTTON_TYPE.SignUp;
  const isDisabled = isSignUpPage ? !acceptedTOS || !ready : !ready;

  return (
    <form noValidate onSubmit={handleSubmit} data-testid="passwords-form">
      {authError && (
        <BannerMessage
          status={BANNER_MESSAGE_STATUSES.Critical}
          title={t(`Auth##${authError}`)}
          externalCustomStyle={inputFieldMarginBottom}
        />
      )}
      {renderInputs()}
      {showPswRequirements && (
        <div css={css(blockWithBottomSpacing)}>
          {/* validation rules: */}
          <PasswordValidation
            currentPsw={current}
            newPsw={newPsw}
            repeatPsw={repeat}
            onRulesMet={toggleDisable}
            name={button}
          />
        </div>
      )}

      {button === BUTTON_TYPE.SignUp && (
        <TermsOfServiceCheckbox
          termsOfServiceCSS={termsOfServiceCSS as CSSObject}
          acceptedTOS={acceptedTOS}
          setAcceptedTOS={setAcceptedTOS}
        />
      )}

      <Button
        data-testid="confirm-button"
        fullWidth
        color={BUTTON_COLORS.Primary}
        size={BUTTON_SIZES.Normal}
        variant={BUTTON_VARIANTS.TextOnly}
        type="submit"
        disabled={isDisabled}
        isLoading={submitLoading || isLoading}
      >
        {t(`Auth##${button}`)}
      </Button>
    </form>
  );
};

export default PasswordsForm;
