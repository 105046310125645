import {
  Column,
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  ROUTES,
  TabsType,
} from "../../../shared";

export const USERS_INVITATIONS_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Email,
    isSticky: true,
    resource: COLUMN_RESOURCES.Email,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Company,
    isSticky: false,
    resource: COLUMN_RESOURCES.Name,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.InvitationStatus,
    isSticky: false,
    resource: COLUMN_RESOURCES.InvitationStatus,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.Role,
    isSticky: false,
    resource: COLUMN_RESOURCES.Role,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.InvitedBy,
    isSticky: false,
    resource: COLUMN_RESOURCES.InvitedBy,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.InvitedAt,
    isSticky: false,
    resource: COLUMN_RESOURCES.InvitedAt,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.UpdatedAt,
    isSticky: false,
    resource: COLUMN_RESOURCES.UpdatedAt,
    isDefaultActive: false,
  },
];

export const USERS_PAGE_TABS: TabsType[] = [
  {
    label: "Tabs##users",
    route: ROUTES.Users,
  },
  {
    label: "Tabs##invitations",
    route: ROUTES.UsersInvitations,
  },
];
