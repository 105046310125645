import { useLocation } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const token = query
    .get("token")
    ?.split(" ")
    .join("+");

  const email = query
    .get("email")
    ?.split(" ")
    .join("+");

  return { token, email, query };
};

export default useQueryParams;
