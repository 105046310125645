import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

import { DriversFileDataDto } from "../../../../../Pages/Files";
import {
  DRIVER_FILES_ACTIONS,
  RenderConditionOptions,
} from "../../../../../shared";

const useDriversFilesInlineActions = () => {
  const [rowData, setRowData] = useState<DriversFileDataDto | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setOpen(false);
  };

  const handleDownloadDriverFileClick = (data: DriversFileDataDto) => {
    // potential implementation of download file written below (commented out until link is done in back-end):
    // window.location.href = `${process.env.REACT_APP_BASE_URL}driversFiles/download/${data.id}`;
    console.log("download file", data);
  };

  const actionTitle = DRIVER_FILES_ACTIONS.Download;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      icon: <DownloadIcon />,
      clickAction: (data: any) => handleDownloadDriverFileClick(data),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) => true,
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useDriversFilesInlineActions;
