import EditEmailRecipientDialog from "./EditEmailRecipientDialog";
import { EmailTransferProps } from "../../../../types";
import EditChoice from "../../../../../../../../../../shared/components/sharedListChoices/EditChoice/EditChoice";
import { useDialog } from "../../../../../../../../../PageHeader/actions/hooks/useDialog";
import { COMPANIES_ACTIONS } from "../../../../../../../../../../shared";

const EditEmailRecipient = ({
  companyId,
  card,
  onActionsMenu,
}: EmailTransferProps) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const handleOpenDialog = () => {
    openDialog(COMPANIES_ACTIONS.EditEmailRecipient);
  };

  const handleCloseDialog = () => {
    onActionsMenu(null);
    closeDialog(COMPANIES_ACTIONS.EditEmailRecipient);
  };

  return (
    <EditChoice
      testId="edit-email-transfer-option"
      onButtonClick={handleOpenDialog}
      dialog={
        <EditEmailRecipientDialog
          companyId={companyId}
          open={isDialogOpen[COMPANIES_ACTIONS.EditEmailRecipient]}
          onClose={handleCloseDialog}
          cardData={card}
        />
      }
    />
  );
};

export default EditEmailRecipient;
