/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useContext } from "react";

import CardStatusBadge from "../CardStatusBadge/CardStatusBadge";

import { CompanyCardObjectDto } from "../../types";
import { useStyles } from "../../styles";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { COMPANY_CARD_STATUS } from "../../../../../../shared";

interface SingleCardProps {
  card: CompanyCardObjectDto;
}

const SingleCard = ({ card }: SingleCardProps) => {
  const { colors } = useContext(ThemeContext);

  const { singleCard } = useStyles();

  return (
    <div css={css(singleCard)} data-testid="single-company-card-item">
      <Typography
        key={card.name}
        variant="body2"
        sx={{
          color:
            card.status === COMPANY_CARD_STATUS.Expired
              ? colors.textDarkDisabled
              : colors.textDark,
        }}
      >
        {card.name}
      </Typography>
      <CardStatusBadge card={card} />
    </div>
  );
};

export default SingleCard;
