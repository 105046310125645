/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { DetailsDataType } from "../../../../types";
import Button from "../../../../../Button/Button";
import { FilterParamsType } from "../../../../../Filters/types";
import { getPageGlobalStateObjectValue } from "../../../../../Table/functions";
import Tooltip from "../../../../../Tooltip/Tooltip";

import {
  filterQueryParams,
  filterRefreshTable,
} from "../../../../../../atoms/atoms";
import {
  LOCAL_STORAGE_KEYS,
  TABLE_NAMES,
  getDetailsDataValues,
  isStoredPageParams,
  setPageParamsToLocalStorage,
  updateQueryParamsInLocalStorage,
} from "../../../../../../shared";
import ListChoice from "../../../../../ListChoice/ListChoice";

interface OpenFilesButtonProps {
  detailsData: DetailsDataType | undefined;
  resourceName: TABLE_NAMES | undefined;
  menuChoice?: boolean;
}

const OpenFilesButton = ({
  detailsData,
  resourceName,
  menuChoice,
}: OpenFilesButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setRefreshFromFilter] = useRecoilState(filterRefreshTable);

  const { hasFiles, number: vehicleNumber, id, cardName } = detailsData ?? {};
  const [isDisabled, setIsDisabled] = useState(!hasFiles);

  const detailsDataFromTableName = () => {
    if (resourceName === TABLE_NAMES.Vehicles) {
      return getDetailsDataValues(TABLE_NAMES.Vehicles, id, vehicleNumber);
    }

    return getDetailsDataValues(TABLE_NAMES.Drivers, id, cardName);
  };

  const handleOpen = async () => {
    const {
      filterName,
      filterValue,
      pageLocation,
    } = detailsDataFromTableName();

    const newFilterParams = {
      [filterName]: [filterValue],
    };

    if (!isStoredPageParams(pageLocation)) {
      setPageParamsToLocalStorage({
        pathname: pageLocation,
        currentPage: "1",
        rowsPerPage: "25",
        selectedTableItem: null,
        searchQueryParams: null,
        desktopDetailsSection: undefined,
        filters: newFilterParams,
      });
    } else {
      updateQueryParamsInLocalStorage(
        pageLocation,
        LOCAL_STORAGE_KEYS.Filters,
        newFilterParams
      );
    }

    setIsDisabled(true);

    setRefreshFromFilter(false);

    setFilterParams((prev: FilterParamsType) =>
      getPageGlobalStateObjectValue(pageLocation, prev, newFilterParams)
    );
    navigate(pageLocation);
  };

  const buttonIcon = (
    <InsertDriveFileOutlinedIcon css={css({ marginRight: "-2px" })} />
  );

  return resourceName === TABLE_NAMES.Drivers ||
    resourceName === TABLE_NAMES.Vehicles ? (
    <Tooltip
      placement="top-end"
      size="medium"
      disableHoverListener={hasFiles}
      title={t("Details##open files disabled tooltip")}
    >
      <div>
        {menuChoice ? (
          <ListChoice
            title={t("Details##open files")}
            iconLeft={buttonIcon}
            onClick={handleOpen}
            alignIconCenter
            disabled={isDisabled}
          />
        ) : (
          <Button
            data-testid="open-files-button"
            color="secondary"
            onClick={handleOpen}
            size="tiny"
            variant="iconLeft"
            disabled={isDisabled}
            icon={buttonIcon}
          >
            {t("Details##open files")}
          </Button>
        )}
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};

export default OpenFilesButton;
