/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";

import useTutorialTooltip from "./hooks/useTutorialTooltip";
import useTutorialTooltipButtons from "./hooks/useTutorialTooltipButtons";
import { useStyles } from "./styles/styles";
import useTutorialMode from "./hooks/useTutorialMode";
import {
  STEP_GROUP,
  TOOLTIP_ACTIONS,
  TUTORIAL_BUTTON_TYPE,
  TutorialTooltipProps,
} from "./types";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../Button/Button";
import { useMediaQueries } from "../../shared";

export const TutorialTooltip = ({
  step,
  tooltipProps,
  isLastStep,
  size,
}: TutorialTooltipProps) => {
  const { t } = useTranslation();
  const { toMd, fromMd } = useMediaQueries();

  const {
    applyScheduleDesktopButtonActions,
    applyScheduleMobileButtonActions,
    createScheduleDesktopButtonActions,
    createScheduleMobileButtonActions,
    newCompanyCardDesktopButtonActions,
    newCompanyCardMobileButtonActions,
  } = useTutorialTooltip();
  const { stepGroup, stepIndex, handleTutorialExit } = useTutorialMode();
  const {
    getProceedButtonText,
    showPreviousButton,
    showNextButton,
  } = useTutorialTooltipButtons(toMd, fromMd);

  const hideSteps = stepGroup === STEP_GROUP.TutorialAddVehicle;

  const {
    buttonSpacing,
    closeIconCSS,
    nextBtnCSS,
    previousBtnCSS,
    tooltipContent,
    tooltipText,
    tutorialTooltipCSS,
  } = useStyles(hideSteps);

  const handleClick = (buttonType: TUTORIAL_BUTTON_TYPE) => {
    switch (stepGroup) {
      case STEP_GROUP.TutorialApplySchedule:
        return fromMd
          ? applyScheduleDesktopButtonActions(buttonType)
          : applyScheduleMobileButtonActions(buttonType);

      case STEP_GROUP.TutorialCreateSchedule:
        return fromMd
          ? createScheduleDesktopButtonActions(buttonType)
          : createScheduleMobileButtonActions(buttonType);

      case STEP_GROUP.TutorialNewCompanyCard:
        return fromMd
          ? newCompanyCardDesktopButtonActions(buttonType)
          : newCompanyCardMobileButtonActions(buttonType);

      default:
        break;
    }
  };

  return (
    <div css={css(tutorialTooltipCSS)} {...tooltipProps}>
      <div css={css(tooltipText)}>
        {!hideSteps && (
          <span>
            {stepIndex + 1}/{size}
          </span>
        )}
        <span>{step.title}</span>
        <div>
          <CloseIcon
            onClick={() => handleTutorialExit()}
            css={css(closeIconCSS)}
          />
        </div>
      </div>

      <div css={css(tooltipContent)}>{step.content}</div>

      <div css={css(buttonSpacing)}>
        {showPreviousButton() && (
          <Button
            color={BUTTON_COLORS.Transparent}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.TextOnly}
            fullWidth
            onClick={() => handleClick(TUTORIAL_BUTTON_TYPE.Previous)}
            css={css(previousBtnCSS)}
          >
            {t(`Button##${TOOLTIP_ACTIONS.Previous}`)}
          </Button>
        )}

        {showNextButton() && (
          <Button
            color={BUTTON_COLORS.Secondary}
            size={BUTTON_SIZES.Small}
            variant={BUTTON_VARIANTS.TextOnly}
            fullWidth
            onClick={() => handleClick(TUTORIAL_BUTTON_TYPE.Proceed)}
            css={css(nextBtnCSS)}
          >
            {t(`Button##${getProceedButtonText(isLastStep)}`)}
          </Button>
        )}
      </div>
    </div>
  );
};
