import { useTranslation } from "react-i18next";
import { useState } from "react";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";

import InviteUserDialogContent from "./InviteUserDialogContent";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import {
  GeneralDialogProps,
  ROUTES,
  useMediaQueries,
  USERS_ACTIONS,
} from "../../../../../../shared";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useRefreshTableData from "../../../../../../shared/hooks/useRefreshTableData";

interface IInviteUserDialogProps extends GeneralDialogProps {
  tableName: string;
}

const InviteUserDialog = ({
  isOpen,
  onClose,
  tableName,
}: IInviteUserDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const location = useLocation();
  const { postData } = useAxiosPrivate();
  const { handleResponse } = useResponse();
  const { refreshTableData } = useRefreshTableData();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [payload, setPayload] = useState<any>(null);

  const handleCloseInviteUserDialog = () => {
    onClose(USERS_ACTIONS.InviteUser);
  };

  const resetAllValues = () => {
    setIsReady(false);
    setPayload(null);
  };

  const usersInvitationRoute = location.pathname === ROUTES.UsersInvitations;

  const handleSubmit = async () => {
    if (isReady) {
      try {
        setIsLoading(true);
        await postData(ApiResources.InviteUser, payload);

        handleResponse(ALERT_STATUS.Success, t("Dialog##invite user sent"));
        usersInvitationRoute && refreshTableData();

        handleCloseInviteUserDialog();
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          handleResponse(
            ALERT_STATUS.Critical,
            err.response?.data.message || err.message
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      data-testid={`${tableName.toLowerCase()}-invite-user-dialog`}
      title={t("Dialog##invite user")}
      description={t("Dialog##invite user description")}
      extendToMaxWidth={toMd}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseInviteUserDialog}
      submit={handleSubmit}
      TransitionProps={{
        onExited: () => resetAllValues(),
      }}
      actions={
        <DialogActions
          onClose={handleCloseInviteUserDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReady}
          confirmationButtonText={t("Button##invite")}
          confirmationButtonTestId="invite-user-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-invite-user-button"
        />
      }
    >
      <InviteUserDialogContent
        setPayload={setPayload}
        setIsReady={setIsReady}
      />
    </Dialog>
  );
};

export default InviteUserDialog;
