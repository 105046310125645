import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useStyles } from "./styles";
import { MenuAnchorEl, useMediaQueries } from "../..";
import Button from "../../../components/Button/Button";

interface ISettingsButtonProps {
  openMenu?: (e: { currentTarget: SetStateAction<MenuAnchorEl> }) => void;
  handleInitialView?: (value: boolean) => void;
}

const SettingsButton = ({
  openMenu,
  handleInitialView,
}: ISettingsButtonProps) => {
  const { fromMd } = useMediaQueries();
  const { t } = useTranslation();

  const classes = useStyles();

  const buttonText = t("Button##settings");

  return (
    <>
      {fromMd && (
        <Button
          id="desktopSettingsButton"
          variant="iconLeft"
          size="small"
          color="white"
          icon={<SettingsIcon />}
          onClick={openMenu}
        >
          {buttonText}
        </Button>
      )}
      {handleInitialView && (
        <Button
          disableRipple
          fullWidth
          variant="iconLeft"
          size="small"
          color="white"
          icon={<SettingsIcon />}
          onClick={() => handleInitialView(false)}
          sx={classes.settingsButton}
        >
          {buttonText}
        </Button>
      )}
    </>
  );
};

export default SettingsButton;
