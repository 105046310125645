import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

interface StyleProps {
  colors: ThemeColors;
}

export const useStyles = ({ colors }: StyleProps) =>
  ({
    infoIcon: {
      color: colors.blue700,
      fontSize: "16px",
      display: "flex",
      cursor: "pointer",
    },
  } as CSSObject);
