import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../shared";

interface UseStylesProps {
  colors?: ThemeColors;
  downloadProgress?: number | string | null;
  alignIconCenter?: boolean;
}

export const useStyles = ({
  colors,
  downloadProgress,
  alignIconCenter,
}: UseStylesProps) =>
  ({
    iconLeftCSS: {
      display: "flex",
      alignItems: "center",
      alignSelf: alignIconCenter ? "center" : "flex-start",
      marginRight: "8px",
      color: downloadProgress ? colors?.textDarkDisabled : "unset",
    },
    checkboxDiv: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",

      "& > svg, & > label": {
        marginLeft: "8px",
      },
    },
    listChoiceContent: {
      flex: "1",
      color: downloadProgress ? colors?.textDarkDisabled : "unset",

      "& > span": {
        display: "block",
      },
    },
  } as CSSObject);
