import { ThemeColors } from "../../shared/types/types";
import { generateColor } from "../../shared/functions/functions";

export const styleVariants = (
  inverted: boolean | undefined,
  colors: ThemeColors
) => {
  return {
    light: {
      text: generateColor(inverted, colors.white, colors.textDark),
      border: generateColor(inverted, colors.textDark, colors.gray200),
      background: generateColor(inverted, colors.textDark, colors.white),
    },
    neutral: {
      text: colors.textDark,
      border: colors.gray200,
      background: colors.gray100,
    },
    gray: {
      text: generateColor(inverted, colors.white, colors.textDark),
      border: colors.gray400,
      background: generateColor(inverted, colors.gray400, colors.white),
    },
    info: {
      text: generateColor(inverted, colors.white, colors.blue700),
      border: generateColor(inverted, colors.blue700, colors.blue200),
      background: generateColor(inverted, colors.blue700, colors.blue100),
    },
    success: {
      text: generateColor(inverted, colors.white, colors.green900),
      border: generateColor(inverted, colors.green700, colors.green100),
      background: generateColor(inverted, colors.green700, colors.green50),
    },
    warning: {
      text: generateColor(inverted, colors.white, colors.textWarning),
      border: generateColor(inverted, colors.orange700, colors.orange100),
      background: generateColor(inverted, colors.orange700, colors.orange50),
    },
    critical: {
      text: generateColor(inverted, colors.white, colors.red800),
      border: generateColor(inverted, colors.red700, colors.red100),
      background: generateColor(inverted, colors.red700, colors.red50),
    },
    premium: {
      text: generateColor(inverted, colors.white, colors.purple900),
      border: generateColor(inverted, colors.purple700, colors.purple100),
      background: generateColor(inverted, colors.purple700, colors.purple50),
    },
  };
};
