import { useLocation } from "react-router-dom";

import { ROUTES } from "../../../../../shared";
import useRefreshDashboardData from "../../../../../shared/hooks/useRefreshDashboardData";

const useChangeCompanyFunctions = () => {
  const { refreshDashboardData } = useRefreshDashboardData();
  const location = useLocation();

  const dashboardPage = location.pathname === ROUTES.Dashboard;

  const refreshPageData = (refreshTable: () => void) => {
    dashboardPage ? refreshDashboardData() : refreshTable();
  };

  return { refreshPageData };
};

export default useChangeCompanyFunctions;
