/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CompanyCardAuthAccordion from "./CompanyCardAuthAccordion";
import CompanyCardAuthLoader from "./CompanyCardAuthLoader";
import { useFileDownload } from "../../../../../../Table/hooks";
import Button from "../../../../../../Button/Button";

interface CompanyCardAuthContentProps {
  releases: any[];
  isContentLoading: boolean;
}

const CompanyCardAuthContent = ({
  releases,
  isContentLoading,
}: CompanyCardAuthContentProps) => {
  const { t } = useTranslation();

  const downloadLink = `${process.env.REACT_APP_BASE_URL}CompanyCardReaderFile/Download`;
  const { downloadCCAFile } = useFileDownload(downloadLink);

  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<string>("panel0");

  const handleCollapse = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : "");
  };

  const handleSubmitDownload = async () => {
    !downloadLoading &&
      (await downloadCCAFile({
        setIsLoading: setDownloadLoading,
        successMessage: t("Alert##CCA file download success"),
      }));
  };

  return (
    <div
      css={css({
        height: isContentLoading ? "336px" : "352px",
      })}
    >
      {isContentLoading ? (
        <CompanyCardAuthLoader />
      ) : (
        <>
          {releases.map((entry: any, index: number) => {
            return (
              <CompanyCardAuthAccordion
                rootPadding="16px"
                key={`${entry.version}_CCA_Version`}
                entry={entry}
                index={index}
                onCollapse={handleCollapse}
                expandedPanel={expanded}
                isLast={releases.length - 1 === index}
                actionButton={
                  index === 0 ? (
                    <Button
                      variant="textOnly"
                      size="small"
                      color="primary"
                      onClick={handleSubmitDownload}
                      isLoading={downloadLoading}
                    >
                      {t("Button##download")}
                    </Button>
                  ) : (
                    undefined
                  )
                }
              />
            );
          })}
        </>
      )}
    </div>
  );
};

export default CompanyCardAuthContent;
