import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import useResponse from "../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../api/resources";
import { deviceStatusState } from "../../../../../atoms/atoms";

const useDeviceStatus = () => {
  const { handleCommonError } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [deviceStatus, setDeviceStatus] = useRecoilState(deviceStatusState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialized = useRef(false);

  const getDeviceStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getData(ApiResources.DashboardDevices);
      setDeviceStatus(response.data);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      initialized.current = true;
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, handleCommonError, setDeviceStatus]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getDeviceStatus();
    }
  }, [getDeviceStatus]);

  return { getDeviceStatus, isLoading, deviceStatus };
};

export default useDeviceStatus;
