/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useStyles } from "../../../styles";
import Menu from "../../../../Menu/Menu";
import { MenuAnchorEl } from "../../../../../shared";

interface NotificationsDesktopProps {
  anchorEl: MenuAnchorEl;
  close: () => void;
}

const NotificationsDesktop = ({
  anchorEl,
  close,
}: NotificationsDesktopProps) => {
  const { notificationsDesktopMenu } = useStyles();

  return (
    <Menu
      open={Boolean(anchorEl)}
      onClose={close}
      anchorEl={anchorEl}
      css={css(notificationsDesktopMenu)}
    >
      <Typography variant="body2">Notifications content</Typography>
    </Menu>
  );
};

export default NotificationsDesktop;
