export enum ENABLE_AUTHENTICATION_STEPS {
  GetSecretKey,
  SendOtpCode,
  SuccessMessage,
}

export enum DISABLE_AUTHENTICATION_STEPS {
  Confirmation,
  SendPassword
}
