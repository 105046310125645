import {
  COMPANIES_ACTIONS,
  DRIVERS_ACTIONS,
  USERS_ACTIONS,
  VEHICLES_ACTIONS,
} from "../../../../shared";

export const deleteActionsList = [
  VEHICLES_ACTIONS.DeleteVehicle,
  DRIVERS_ACTIONS.DeleteDriver,
  USERS_ACTIONS.DeleteUser,
  COMPANIES_ACTIONS.DeleteCompany,
];

export const removeSchedulesList = [
  VEHICLES_ACTIONS.RemoveSchedules,
  DRIVERS_ACTIONS.RemoveSchedules,
];
