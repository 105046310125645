/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";
import { useRecoilValue } from "recoil";

import NoResultsFound from "./NoResultsFound/NoResultsFound";
import NoInitialResults from "./NoInitialResults";
import { useStyles } from "../../styles/styles";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { filtersHeight, noSearchResults } from "../../../../atoms/atoms";
import {
  DESKTOP_TABLE_FOOTER_HEIGHT,
  EMPTY_TABLE_HEADER_HEIGHT,
  NAVBAR_HEIGHT,
  TABLE_TOOLBAR_HEIGHT,
  useMediaQueries,
  XSM_PAGE_HEADER_HEIGHT,
} from "../../../../shared";

interface ITableEmptyProps {
  hasSearch: boolean;
}

const TableEmpty = ({ hasSearch }: ITableEmptyProps) => {
  const { colors } = useContext(ThemeContext);
  const { toMd, toLg, toSm } = useMediaQueries();

  const noResults = useRecoilValue(noSearchResults);
  const filtersHeightValue = useRecoilValue(filtersHeight);

  const { emptyTable, emptyTableInnerDiv } = useStyles();

  const calculateMobileViewHeight = () => {
    return `calc(100vh - ${XSM_PAGE_HEADER_HEIGHT} - ${NAVBAR_HEIGHT} - ${TABLE_TOOLBAR_HEIGHT(
      toMd,
      hasSearch,
      undefined,
      filtersHeightValue
    )} - ${EMPTY_TABLE_HEADER_HEIGHT} - ${DESKTOP_TABLE_FOOTER_HEIGHT(toLg)})`;
  };

  return (
    <div className="remaining-height" data-testid="empty-table">
      <div css={css(emptyTable)}>
        <div
          css={css({
            height: EMPTY_TABLE_HEADER_HEIGHT,
            boxSizing: "border-box",
            background: colors.blue100,
            borderTop: `1px solid ${colors.blue200}`,
            borderBottom: `1px solid ${colors.blue200}`,
          })}
        />

        <div
          css={css([
            emptyTableInnerDiv,
            {
              height: toSm ? calculateMobileViewHeight() : "unset",
              padding: toSm ? "0" : "unset",
            },
          ])}
        >
          {/* error case should be handled in the future */}
          {noResults ? <NoResultsFound /> : <NoInitialResults />}
        </div>
      </div>
    </div>
  );
};

export default TableEmpty;
