/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import { ScheduleDto } from "../../../DetailsSection/types";
import { useStyles } from "../../../DetailsSection/styles";
import Tooltip from "../../../Tooltip/Tooltip";
import { TooltipSizes } from "../../../Tooltip/types";

import ActivitiesDataTypeIcon from "../../../../assets/customIcons/dataTypes/ActivitiesDataTypeIcon";
import DetailedSpeedDataTypeIcon from "../../../../assets/customIcons/dataTypes/DetailedSpeedDataTypeIcon";
import EventsAndFaultsDataTypeIcon from "../../../../assets/customIcons/dataTypes/EventsAndFaultsDataTypeIcon";
import OverviewDataTypeIcon from "../../../../assets/customIcons/dataTypes/OverviewDataTypeIcon";
import TechnicalDataTypeIcon from "../../../../assets/customIcons/dataTypes/TechnicalDataTypeIcon";
import { checkIfDataTypeIsDisabled } from "../../../../shared/functions/functions";
import { VehicleDataTypes } from "../../../../shared";

interface IVehicledataTypesProps {
  schedule: ScheduleDto;
  size?: TooltipSizes;
}

const VehicleDataTypesList = ({
  schedule,
  size = "small",
}: IVehicledataTypesProps) => {
  const { t } = useTranslation();
  const { dataTypeIconsBlock } = useStyles();

  return (
    <div css={css(dataTypeIconsBlock)}>
      <Tooltip
        title={t("Details##overview")}
        size={size}
        placement="top"
        hasArrow
      >
        <span>
          <OverviewDataTypeIcon
            disabled={checkIfDataTypeIsDisabled(
              VehicleDataTypes.Overview,
              schedule
            )}
          />
        </span>
      </Tooltip>

      <Tooltip
        title={t("Details##events and faults")}
        size={size}
        placement="top"
        hasArrow
      >
        <span>
          <EventsAndFaultsDataTypeIcon
            disabled={checkIfDataTypeIsDisabled(
              VehicleDataTypes.EventsAndFaults,
              schedule
            )}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={t("Details##detailed speed")}
        size={size}
        placement="top"
        hasArrow
      >
        <span>
          <DetailedSpeedDataTypeIcon
            disabled={checkIfDataTypeIsDisabled(
              VehicleDataTypes.DetailedSpeed,
              schedule
            )}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={t("Details##technical data")}
        size={size}
        placement="top"
        hasArrow
      >
        <span>
          <TechnicalDataTypeIcon
            disabled={checkIfDataTypeIsDisabled(
              VehicleDataTypes.TechnicalData,
              schedule
            )}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={t("Details##activities")}
        size={size}
        placement="top"
        hasArrow
      >
        <span>
          <ActivitiesDataTypeIcon
            disabled={checkIfDataTypeIsDisabled(
              VehicleDataTypes.Activities,
              schedule
            )}
          />
        </span>
      </Tooltip>
    </div>
  );
};

export default VehicleDataTypesList;
