import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Typography } from "@mui/material";

import Dialog from "../../../../../Dialog/Dialog";
import {
  DRIVER_FILES_ACTIONS,
  GeneralDialogProps,
  MAX_ALLOWED_COUNTS,
  TABLE_NAMES,
  useMediaQueries,
  VEHICLE_FILES_ACTIONS,
} from "../../../../../../shared";
import { selectedTableRows, tableName } from "../../../../../../atoms/atoms";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import SelectedRowsTag from "../../../../../../shared/components/tags/SelectedRowsTag";
import { useFileDownload } from "../../../../../Table/hooks/useFileDownload";
import BannerMessage from "../../../../../BannerMessage/BannerMessage";
import { BANNER_MESSAGE_STATUSES } from "../../../../../BannerMessage/types";

interface IDownloadDialogProps extends GeneralDialogProps {
  filesTableName: string;
}

const DownloadDialog = ({
  isOpen,
  onClose,
  filesTableName,
}: IDownloadDialogProps) => {
  const { t } = useTranslation();
  const { downloadFile } = useFileDownload();
  const { toMd } = useMediaQueries();

  const currentTableName = useRecoilValue<TABLE_NAMES | undefined>(tableName);
  const selectedRows = useRecoilValue<string[]>(selectedTableRows);

  const [isLoading, setIsLoading] = useState(false);

  const isreadyToDownload =
    selectedRows.length <= MAX_ALLOWED_COUNTS.DownloadFiles;
  const bannerStatus = isreadyToDownload
    ? BANNER_MESSAGE_STATUSES.Info
    : BANNER_MESSAGE_STATUSES.Warning;

  const action = () =>
    filesTableName === TABLE_NAMES.Vehicles
      ? VEHICLE_FILES_ACTIONS.Download
      : DRIVER_FILES_ACTIONS.Download;
  const resourceCount = selectedRows?.length;

  const handleCloseDownloadVehicleFilesDialog = () => {
    onClose(action());
  };

  const generateDownloadUrlQueryParams = () => {
    let firstId: string = "";
    let remainingString: string = "";
    selectedRows.forEach((row: string, index: number) => {
      if (index === 0) {
        firstId = row;
      }
      if (index !== 0) {
        remainingString = remainingString + `&Ids=${row}`;
      }
    });
    return firstId + remainingString;
  };

  const handleSubmitDownload = () => {
    isreadyToDownload &&
      downloadFile({
        queryString: generateDownloadUrlQueryParams(),
        setIsLoading,
        onCloseDialog: handleCloseDownloadVehicleFilesDialog,
      });
  };

  return (
    <Dialog
      data-testid={`download-${filesTableName.toLowerCase()}-files-dialog`}
      title={t(`Dialog##download ${filesTableName.toLowerCase()} files`)}
      isTitleSeparator
      open={isOpen}
      close={handleCloseDownloadVehicleFilesDialog}
      submit={handleSubmitDownload}
      extendToMaxWidth={toMd}
      actions={
        <DialogActions
          onClose={handleCloseDownloadVehicleFilesDialog}
          isLoading={isLoading}
          isreadyToConfirm={isreadyToDownload}
          confirmationButtonText={t("Button##download")}
          cancelButtonTestId={`cancel-${action()}`}
          confirmationButtonTestId={`accept-${action()}`}
          cancelButtonText={t("Button##cancel")}
          hasTooltip={!isreadyToDownload}
          tooltipText={t("Button##max download files", {
            maxNumber: MAX_ALLOWED_COUNTS.DownloadFiles,
          })}
        />
      }
    >
      <BannerMessage
        status={bannerStatus}
        title={t("Dialog##max download files", {
          maxNumber: MAX_ALLOWED_COUNTS.DownloadFiles,
        })}
        externalCustomStyle={{ margin: "8px 0 16px 0" }}
      />
      <Typography variant="body2" sx={{ marginBottom: "16px" }}>
        {t("Dialog##download only selection")}
      </Typography>
      <SelectedRowsTag
        resourceName={currentTableName}
        resourceCount={resourceCount}
      />
    </Dialog>
  );
};

export default DownloadDialog;
