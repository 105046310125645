import { useContext } from "react";
import LanguageContext from "../../context/language/LanguageContext";

export const useFormattedNumber = (props?: number) => {
  const { language } = useContext(LanguageContext);

  const renderFormattedNumber = (number: number) =>
    number
      ? number.toLocaleString((language as unknown) as string)
      : String(number);

  const formattedNumber = props ? props.toLocaleString(language) : String(props);

  return { renderFormattedNumber, formattedNumber };
};
