import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";

import "./i18next/config";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import LanguageProvider from "./context/language/LanguageProvider";
import ThemeContextProvider from "./context/theme/ThemeContextProvider";
import { muiThemeOverride as theme } from "./shared";
import AlertContextProvider from "./context/alert/AlertContextProvider";
import TimeZoneProvider from "./context/TimeZone/TimeZoneProvider";
import AuthProvider from "./context/Auth/AuthProvider";
import DropzoneState from "./context/dropzone/DropzoneState";
import { FeatureTogglesProvider } from "./context/FeatureToggles/FeatureTogglesProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <ThemeContextProvider>
          <AlertContextProvider>
            <TimeZoneProvider>
              <FeatureTogglesProvider>
                <AuthProvider>
                  <LanguageProvider>
                    <DropzoneState>
                      <App />
                    </DropzoneState>
                  </LanguageProvider>
                </AuthProvider>
              </FeatureTogglesProvider>
            </TimeZoneProvider>
          </AlertContextProvider>
        </ThemeContextProvider>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
