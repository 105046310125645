/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { v4 as uuidv4 } from "uuid";

import { useRecoilState } from "recoil";

import SingleScheduleCard from "./SingleScheduleCard/SingleScheduleCard";
import CreateScheduleDialog from "../CreateScheduleDialog/CreateScheduleDialog";

import { useStyles } from "../../styles";
import { ResourceParams, ScheduleDataDto } from "../../types";

import { useDialog } from "../../../PageHeader/actions/hooks/useDialog";

import { selectedVehicleSchedule } from "../../../../atoms/atoms";
import { tableNameParams } from "../../../../shared";

interface ScheduleCardsProps {
  schedulesData: ScheduleDataDto[] | null;
  isScheduleDataLoading: boolean;
  globalScheduleExists: boolean;
  limitReached: boolean;
  resourceParams: ResourceParams;
  canManageSchedules: boolean;
  getSchedulesData: () => Promise<void>;
}

const ScheduleCards = ({
  schedulesData,
  isScheduleDataLoading,
  globalScheduleExists,
  limitReached,
  resourceParams,
  canManageSchedules,
  getSchedulesData,
}: ScheduleCardsProps) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const [selectedSchedule, setSelectedSchedule] = useRecoilState(
    selectedVehicleSchedule
  );

  const { scheduleCards } = useStyles();

  const selectedScheduleId = selectedSchedule?.id;

  const { resourceName, actionCreate } = resourceParams;

  const handleCardClick = (
    isCreateNewSchedule: boolean | undefined,
    scheduleData: ScheduleDataDto | null
  ) => {
    const isAlreadySelected = selectedScheduleId === scheduleData?.id;

    setSelectedSchedule(
      isCreateNewSchedule || isAlreadySelected ? null : scheduleData
    );

    if (isCreateNewSchedule) {
      openDialog?.(actionCreate);
    }
  };

  return (
    <>
      <div css={css(scheduleCards)} id="main_scheduleCards">
        {isScheduleDataLoading ? (
          Array(3)
            .fill(null)
            .map((_, index) => {
              return (
                <SingleScheduleCard
                  key={uuidv4()}
                  scheduleData={null}
                  resourceName={resourceName}
                  index={index}
                  isScheduleDataLoading
                  globalScheduleExists={globalScheduleExists}
                  getSchedulesData={getSchedulesData}
                />
              );
            })
        ) : (
          <>
            {!globalScheduleExists && (
              <SingleScheduleCard
                scheduleData={null}
                globalScheduleExists={false}
                isScheduleDataLoading={isScheduleDataLoading}
                resourceName={resourceName}
                getSchedulesData={getSchedulesData}
              />
            )}

            {schedulesData?.map((scheduleData) => (
              <SingleScheduleCard
                key={scheduleData.id}
                scheduleData={scheduleData}
                globalScheduleExists={true}
                resourceName={resourceName}
                isSelected={selectedScheduleId === scheduleData.id}
                getSchedulesData={getSchedulesData}
                handleCardClick={handleCardClick}
              />
            ))}

            {!limitReached && canManageSchedules && (
              <SingleScheduleCard
                scheduleData={null}
                globalScheduleExists={true}
                resourceName={resourceName}
                isCreateNewSchedule
                isSelected={!selectedScheduleId}
                getSchedulesData={getSchedulesData}
                handleCardClick={handleCardClick}
              />
            )}
          </>
        )}
      </div>
      <CreateScheduleDialog
        resourceParams={tableNameParams[resourceName]}
        isOpen={isDialogOpen[actionCreate]}
        onClose={closeDialog}
        getSchedulesData={getSchedulesData}
      />
    </>
  );
};

export default ScheduleCards;
