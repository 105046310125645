/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import useTableFunctions from "../../../hooks/useTableFunctions";
import { useStyles } from "../../../styles/styles";
import {
  ACTIVITY_STATUS,
  Column,
  INVITATION_STATUS,
  TableRowData,
  ThemeColors,
} from "../../../../../shared";

interface IMobileTableItemColumnProps {
  colors: ThemeColors;
  columnData: Column;
  row: TableRowData;
}

const MobileTableItemColumn = ({
  colors,
  columnData,
  row,
}: IMobileTableItemColumnProps) => {
  const { renderValue } = useTableFunctions();
  const { t } = useTranslation();

  const { mobileTableColumn, mobileTableColumnValue } = useStyles();

  const { name, isBadge, resource, secondCellValue } = columnData;

  return (
    <div css={css(mobileTableColumn)}>
      <Typography variant="body4semiBold" sx={{ color: colors.gray700 }}>
        {t(`Table##${name.toLowerCase()}`)}
      </Typography>
      <div
        css={css([
          {
            color:
              row.status === ACTIVITY_STATUS.Deactivated ||
              row?.invitationStatus === INVITATION_STATUS.Expired
                ? colors.textDarkDisabled
                : colors.textDark,
            marginTop: isBadge ? "2px" : "0",
          },
          mobileTableColumnValue,
        ])}
      >
        {renderValue(name, resource, row, secondCellValue)}
      </div>
    </div>
  );
};

export default MobileTableItemColumn;
