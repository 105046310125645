import { useTranslation } from "react-i18next";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

import { SingleCookieItem } from "./SingleCookieItem";
import { CookieItem } from "../../types";
import { TOGGLE_SWITCH_LABEL_PLACEMENT } from "../../../ToggleSwitch";

interface CookiesModalContentProps {
  setIsAnalyticsCookies: Dispatch<SetStateAction<boolean>>;
  isAnalyticsCookies: boolean | undefined;
}

export const CookiesModalContent = ({
  setIsAnalyticsCookies,
  isAnalyticsCookies,
}: CookiesModalContentProps) => {
  const { t } = useTranslation();

  const handleAnalyticsToggleChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setIsAnalyticsCookies(event.target.checked);
  };

  const COOKIE_ITEMS_LIST: CookieItem[] = [
    {
      haveItemSeparator: false,
      title: t("Cookies##essential cookies"),
      description: t("Cookies##essential cookies description"),
      isToggleDisabled: true,
      isToggleChecked: true,
      labelPlacement: TOGGLE_SWITCH_LABEL_PLACEMENT.Start,
      label: t("Cookies##always active"),
      onToggleChange: null,
    },

    {
      haveItemSeparator: false,
      title: t("Cookies##analytics cookies"),
      description: t("Cookies##analytics cookies description"),
      label: undefined,
      labelPlacement: undefined,
      isToggleDisabled: false,
      isToggleChecked: isAnalyticsCookies,
      onToggleChange: handleAnalyticsToggleChange,
      customStyle: { paddingTop: "24px" },
    },
  ];

  return (
    <>
      {COOKIE_ITEMS_LIST.map(
        ({
          title,
          haveItemSeparator,
          description,
          label,
          labelPlacement,
          onToggleChange,
          isToggleDisabled,
          isToggleChecked,
          customStyle,
        }: CookieItem) => (
          <SingleCookieItem
            key={title}
            cookieTitle={title}
            cookieDescription={description}
            isItemSeparator={haveItemSeparator}
            switcherLabel={label}
            switcherLabelPlacement={labelPlacement}
            onToggleChange={onToggleChange}
            toggleDisable={isToggleDisabled}
            toggleChecked={isToggleChecked}
            customStyle={customStyle}
          />
        )
      )}
    </>
  );
};
