import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const ErrorIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    fill="white"
                    stroke="#B91C1C"
                />
                <rect
                    x="1"
                    y="1"
                    width="22"
                    height="22"
                    rx="11"
                    stroke="#FEF2F2"
                    strokeWidth="2"
                />
            </g>
        </SvgIcon>
    );
};

export default ErrorIcon;
