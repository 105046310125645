import { CSSObject } from "@emotion/react";
import { COLORS } from "../../shared";

export const useStyles = () =>
  ({
    alertBox: {
      display: "flex",
      alignItems: "center",
      padding: "12px 12px 12px 8px",
      backgroundColor: COLORS.White,
      borderRadius: "4px",
      wordBreak: "break-word",
      boxShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 3px rgba(0, 0, 0, 0.1)",

      "& > svg:first-of-type": {
        alignSelf: "flex-start",
        marginRight: "8px",
      },
    },
    alertContent: {
      flex: "1",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
    },
    alertTitle: {
      color: COLORS.TextDark,
      fontWeight: 600,
    },
    alertDescription: {
      color: COLORS.Gray700,
      marginTop: "4px",
    },
    closeAlert: {
      alignSelf: "flex-start",
      fontSize: "16px",
      cursor: "pointer",
      margin: "-4px -4px 0 8px",
    },
    infoIcon: {
      color: COLORS.Blue700,
    },
  } as CSSObject);
