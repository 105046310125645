import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import useCompaniesTabDetails from "../../../../../../hooks/useCompaniesTabDetails";
import { EmailTransferObjectDto } from "../../../../../../../../types";
import Dialog from "../../../../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../../../../Dialog/types/enums";
import DialogActions from "../../../../../../../../../../shared/components/buttons/DialogActions";
import { tableDetailsLoading } from "../../../../../../../../../../atoms/atoms";
import useResponse from "../../../../../../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../../../../../../api/hooks/useAxiosPrivate";
import { useMediaQueries } from "../../../../../../../../../../shared";
import { ApiResources } from "../../../../../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../../../../../context/alert/types";

interface RemoveEmailRecipientDialogProps {
  companyId: string;
  cardData: EmailTransferObjectDto;
  open: boolean;
  onClose: () => void;
}

const RemoveEmailRecipientDialog = ({
  companyId,
  cardData,
  open,
  onClose,
}: RemoveEmailRecipientDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { deleteData } = useAxiosPrivate();
  const { handleErrorWithEntries, handleResponse } = useResponse();
  const { getTabDetailsData } = useCompaniesTabDetails();

  const [, setIsDetailsLoading] = useRecoilState(tableDetailsLoading);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitEdit = async () => {
    try {
      setIsLoading(true);

      await deleteData(ApiResources.EmailRecipients, cardData.id);
      handleResponse(
        ALERT_STATUS.Success,
        t("Alert##email transfer remove recipient success")
      );

      setIsDetailsLoading(true);
      await getTabDetailsData(companyId, ApiResources.EmailRecipients);
      setIsDetailsLoading(false);
    } catch (error) {
      handleErrorWithEntries(error, t);
    }

    setIsLoading(false);
  };

  return (
    <Dialog
      title={t("Details##remove email recipient##title", {
        userEmail: `"${cardData.email}"`,
      })}
      wordBreakTitle="break-all"
      description={t("Details##remove email recipient##description")}
      open={open}
      close={onClose}
      extendToMaxWidth={toMd}
      isTitleSeparator
      submit={handleSubmitEdit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={onClose}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##accept")}
          confirmationButtonTestId="remove-recipient-dialog-accept-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="remove-recipient-dialog-close-button"
        />
      }
    />
  );
};

export default RemoveEmailRecipientDialog;
