import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useContext } from "react";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

interface ISircularLoaderWithBackdropProps {
  isLoading: boolean;
}

const CircularLoaderWithBackdrop = ({
  isLoading,
}: ISircularLoaderWithBackdropProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <Backdrop
      open={isLoading}
      sx={{
        zIndex: "9999",
      }}
    >
      <CircularProgress
        sx={{
          color: colors.white,
        }}
      />
    </Backdrop>
  );
};

export default CircularLoaderWithBackdrop;
