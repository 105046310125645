import { useState } from "react";
import { useTranslation } from "react-i18next";

import EditCardDialogContent from "./EditCardDialogContent";
import useTableItemDetails from "../../../../hooks/useTableItemdetails";
import { CompanyCardDto, DetailsDataType } from "../../../../types";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { COMPANIES_ACTIONS, useMediaQueries } from "../../../../../../shared";
import { EditCompanyCardPayloadType } from "../../../../../../Pages/Companies/types";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";

interface EditCompanyCardDialogProps {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  cardData: CompanyCardDto;
  companyData: DetailsDataType;
}

const EditCompanyCardDialog = ({
  isDialogOpen,
  onCloseDialog,
  cardData,
  companyData,
}: EditCompanyCardDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();
  const { putData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleCommonError } = useResponse();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [payload, setPayload] = useState<EditCompanyCardPayloadType | null>(
    null
  );
  const [isReadyToConfirm, setIsReadyToConfirm] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (isReadyToConfirm && companyData) {
      try {
        setIsLoading(true);

        await putData(ApiResources.CompanyCards, payload);

        // refresh table details
        getTableItemDetails({
          itemId: companyData.id,
          successMessage: t("Alert##edit company card success"),
          leaveTableDetailsNotUpdated: false,
          valueInSearchField: undefined, // companies page does not have search field,
        });

        onCloseDialog();
      } catch (error) {
        handleCommonError(error, t);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Dialog
      title={t(`Company cards##${COMPANIES_ACTIONS.EditCompanyCard}`)}
      open={isDialogOpen}
      close={onCloseDialog}
      submit={handleSubmit}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      actions={
        <DialogActions
          onClose={onCloseDialog}
          isLoading={isLoading}
          isreadyToConfirm={isReadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="edit-company-card-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="edit-company-card-cancel-button"
        />
      }
    >
      <EditCardDialogContent
        cardData={cardData}
        companyData={companyData}
        setPayload={setPayload}
        setIsReadyToConfirm={setIsReadyToConfirm}
      />
    </Dialog>
  );
};

export default EditCompanyCardDialog;
