import { useEffect, useRef } from "react";

const usePreviousLocation = (location: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = location;
  });

  return ref.current;
};

export default usePreviousLocation;
