import { useTranslation } from "react-i18next";
import CardWrapper from "../../CardWrapper/CardWrapper";

const VehicleData = () => {
  const { t } = useTranslation();

  return (
    <CardWrapper
      isTopSection
      cardTitle={t("Dashboard##vehicle data")}
      isDataCard
      isInnerCard
    >
      <></>
    </CardWrapper>
  );
};

export default VehicleData;
