/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { Typography } from "@mui/material";
import { MouseEventHandler, ReactNode, useContext } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";

import { useStyles } from "./styles";
import { BANNER_MESSAGE_STATUSES } from "./types";
import InfoIcon from "../../assets/customIcons/status/InfoIcon";
import CriticalIcon from "../../assets/customIcons/status/CriticalIcon";
import WarningIcon from "../../assets/customIcons/status/WarningIcon";
import SuccessIcon from "../../assets/customIcons/status/SuccessIcon";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

export interface BannerMessageProps {
  status: BANNER_MESSAGE_STATUSES;
  title?: string | ReactNode;
  description?: string | ReactNode;
  close?: MouseEventHandler<SVGSVGElement>;
  css?: SerializedStyles;
  textWidth?: string;
  externalCustomStyle?: any;
  testId?: string;
}

const BannerMessage = ({
  status,
  title,
  description,
  close,
  textWidth,
  externalCustomStyle,
  testId,
  ...props
}: BannerMessageProps) => {
  const {
    colors: {
      blue100,
      blue500,
      blue700,
      red50,
      red300,
      orange50,
      orange300,
      green50,
      green300,
      textDark,
    },
  } = useContext(ThemeContext);

  const { bannerMessage, bannerMessageContent, closeBannerIcon } = useStyles();

  const styleOptions = {
    info: {
      background: blue100,
      border: blue500,
      icon: (
        <InfoIcon
          css={css({
            color: blue700,
          })}
        />
      ),
    },
    critical: {
      background: red50,
      border: red300,
      icon: <CriticalIcon />,
    },
    warning: {
      background: orange50,
      border: orange300,
      icon: <WarningIcon />,
    },
    success: {
      background: green50,
      border: green300,
      icon: <SuccessIcon />,
    },
  };

  const bgColor = styleOptions[status].background;
  const borderColor = styleOptions[status].border;
  const iconImage = styleOptions[status].icon;

  return (
    <div
      data-testid={testId || "banner-message"}
      {...props}
      css={css([
        {
          background: bgColor,
          border: `1px solid ${borderColor}`,
        },
        externalCustomStyle,
        bannerMessage,
      ])}
    >
      {iconImage}

      <div
        css={css([
          {
            width: textWidth,
            color: textDark,
          },
          bannerMessageContent,
        ])}
      >
        {title && <Typography variant="subtitle1semiBold">{title}</Typography>}

        {description && (
          <Typography
            variant="subtitle1"
            sx={{ marginTop: title ? "4px" : "0" }}
          >
            {description}
          </Typography>
        )}
      </div>

      {close && <CloseIcon css={css(closeBannerIcon)} onClick={close} />}
    </div>
  );
};

export { BANNER_MESSAGE_STATUSES } from "./types/enums";

export default BannerMessage;
