// Text for the WelcomeDialog component will not be translated as it is only for the BETA version of the application.
export const betaVersionParagraphs = [
  {
    title: null,
    description:
      "We’re excited to have you here! As part of our BETA testing phase, you’re getting early access to try out our product and help shape its future. Here’s what you need to know:",
    list: null,
    afterParagraph: null,
  },
  {
    title: "What is BETA Testing?",
    description:
      "BETA testing is an early release version of the product that allows us to gather valuable feedback from users like you. Please note:",
    list: [
      {
        bold: "Closed BETA",
        text:
          "(from 2024-11-18 to 2024-12-20): Limited access for selected customers only.",
      },
      {
        bold: "Open BETA",
        text:
          "(from 2025-01-20 to 2025-03-14): Available to all customers who want to try out the new product.",
      },
    ],
    afterParagraph:
      "During this time, certain features may be incomplete, missing, or not fully functional. Bugs are also expected as we work to improve the system.",
  },
  {
    title: "Important Info:",
    description: null,
    list: [
      {
        bold: "Your Feedback Matters —",
        text:
          'Your input will help shape the final product. Provide feedback <a href="https://forms.office.com/e/BSBQQYRHXj" target="blank" class="link">here</a>.',
      },
      {
        bold: "Expect Some Glitches —",
        text:
          "Things may not always work perfectly, as this is a BETA version. If you encounter any issues, please reach out to our support team.",
      },
      {
        bold: "Data Purge —",
        text:
          "Please note that all data collected during both the Closed and Open BETA stages will be deleted before the official release of TachoSync.",
      },
      {
        bold: "Tutorials Available —",
        text:
          "Tutorials to help you set up the system for successful data downloads are available in the <strong>Settings</strong>. These guides cover essential steps to get you up and running smoothly.",
      },
    ],
    afterParagraph:
      "Thank you for being part of our journey to build a better product!",
  },
];

export const betaVersionCheckboxLabel =
  "<span>I understand </span>this is only a BETA version and that all created data <span>will be deleted</span> at the end of Open BETA period.";
