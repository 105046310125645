import { Typography } from "@mui/material";
import { ReactNode, useContext } from "react";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

interface IMultiValuesCellItemProps {
  title: string;
  value: string | number | ReactNode;
}

const MultiValuesCellItem = ({ title, value }: IMultiValuesCellItemProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <div>
      <Typography
        variant="subtitle1semiBold"
        sx={{ color: colors.textPlaceholder }}
      >
        {`${title}: `}
      </Typography>
      <Typography variant="subtitle1semiBold" sx={{ color: colors.textDark }}>
        {value}
      </Typography>
    </div>
  );
};

export default MultiValuesCellItem;
