import { useTranslation } from "react-i18next";
import {
  CUSTOM_REPEAT_INPUT_REQUIREMENTS,
  REPEAT_NAME_VALUES,
} from "../../types";

const useScheduleRepeatValues = () => {
  const { t } = useTranslation();

  const validateCustomRepeatInput = (value: string | null) => {
    if (value === "") return value;

    const number = Number(value);
    if (number > CUSTOM_REPEAT_INPUT_REQUIREMENTS.Max)
      return CUSTOM_REPEAT_INPUT_REQUIREMENTS.Max;
    if (number <= 0) return CUSTOM_REPEAT_INPUT_REQUIREMENTS.Min;

    return value;
  };

  const getRepeatString = (
    repeat: REPEAT_NAME_VALUES,
    customRepeat: number
  ) => {
    if (repeat !== REPEAT_NAME_VALUES.Custom) {
      return t(`Schedules##repeat period##${repeat}`);
    }

    return customRepeat === 1
      ? t(`Schedules##repeat period##${REPEAT_NAME_VALUES.Daily}`)
      : t(`Schedules##repeat period##EveryDays`, { count: customRepeat });
  };

  const checkIfRepeatValueIsValid = (
    value: string | number | null | undefined
  ) => {
    return !!value && !isNaN(Number(value));
  };

  return {
    checkIfRepeatValueIsValid,
    getRepeatString,
    validateCustomRepeatInput,
  };
};

export default useScheduleRepeatValues;
