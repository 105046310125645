import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import { ApiResources } from "../resources";
import axios from "../axios";
import {
  tokenRefreshState,
  tokensAtom,
  twoFactorVerificationState,
  userAtom,
} from "../../atoms/atoms";
import {
  deleteStoredTokens,
  getTokensFromLocalStorage,
  setTokensToLocalStorage,
} from "../../shared/functions/auth";
import useResponse from "../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../context/alert/types";

const useRefreshToken = () => {
  const { handleResponse } = useResponse();
  const { t } = useTranslation();

  const [, setTokenInfo] = useRecoilState(tokensAtom);
  const [, setUser] = useRecoilState(userAtom);
  const [, setIsTokenRefreshLoading] = useRecoilState(tokenRefreshState);
  const [, setIs2FaVerification] = useRecoilState(twoFactorVerificationState);

  const refreshToken = async () => {
    try {
      setIsTokenRefreshLoading(true);
      const resp = await axios.post(ApiResources.RefreshToken, {
        accessToken: getTokensFromLocalStorage()?.token,
        refreshToken: getTokensFromLocalStorage()?.refreshToken,
      });
      resp && setTokenInfo(resp.data);
      resp && setTokensToLocalStorage(resp.data);

      return resp?.data;
    } catch (error) {
      const err = error as any;

      setTokenInfo(null);
      setUser(null);
      deleteStoredTokens();
      setIs2FaVerification(false);

      handleResponse(
        ALERT_STATUS.Critical,
        // changed line for debugging purposes
        err.response?.data.message
          ? err?.response?.data?.message
          : t("General##unauthorized")
      );
    } finally {
      setIsTokenRefreshLoading(false);
    }
  };

  return refreshToken;
};

export default useRefreshToken;
