import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

import { useFileDownload } from "../../../hooks/useFileDownload";
import { VehicleFilesDataDto } from "../../../../../Pages/Files";
import {
  RenderConditionOptions,
  VEHICLE_FILES_ACTIONS,
} from "../../../../../shared";

const useVehicleFilesInlineActions = () => {
  const [rowData, setRowData] = useState<VehicleFilesDataDto | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { downloadFile } = useFileDownload();

  const openDialog = (data: any) => {
    setRowData(data);
    setIsOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setIsOpen(false);
  };

  const actionTitle = VEHICLE_FILES_ACTIONS.Download;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      hasLoader: true,
      icon: <DownloadIcon />,
      clickAction: (data: VehicleFilesDataDto, setIsButtonDownload?: any) =>
        downloadFile({
          queryString: data.id,
          setIsLoading: setIsButtonDownload,
        }),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) => true,
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useVehicleFilesInlineActions;
