/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { ReactNode } from "react";

import DetailsSection from "../DetailsSection/DetailsSection";
import PageHeader from "../PageHeader/PageHeader";
import { useStyles } from "../../shared/styles/sharedStyles";

interface ILayoutWithDetailsProps {
  headerTitle: string;
  children: ReactNode;
}

const LayoutWithDetails = ({
  headerTitle,
  children,
}: ILayoutWithDetailsProps) => {
  const { pageLayout } = useStyles();

  return (
    <div data-testid="layout-with-details" css={css(pageLayout)}>
      <PageHeader headerTitle={headerTitle} isPageWithDetails>
        {children}
      </PageHeader>
      <DetailsSection />
    </div>
  );
};

export default LayoutWithDetails;
