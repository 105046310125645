import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DriverScheduleDto, ScheduleDto } from "../../../../../types";
import useTableItemDetails from "../../../../../hooks/useTableItemdetails";
import Dialog from "../../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../../Dialog/types/enums";
import { ResourceParams } from "../../../../../../Schedules/types";

import DialogActions from "../../../../../../../shared/components/buttons/DialogActions";

import useAxiosPrivate from "../../../../../../../api/hooks/useAxiosPrivate";
import {
  getValueInSearchField,
  useMediaQueries,
} from "../../../../../../../shared";
import useResponse from "../../../../../../../shared/hooks/useResponse";
import { refreshTableAndUpdateDetails } from "../../../../../../../shared/functions/functions";

interface RemoveScheduleDialogProps {
  schedule: ScheduleDto | DriverScheduleDto;
  resourceParams: ResourceParams;
  resourceId: string | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

const RemoveScheduleDialog = ({
  schedule,
  resourceParams,
  resourceId,
  isOpen,
  handleClose,
}: RemoveScheduleDialogProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const { deleteData } = useAxiosPrivate();
  const { handleCommonError, handleResponse } = useResponse();

  const { getTableItemDetails } = useTableItemDetails();

  const [isLoading, setIsLoading] = useState(false);

  const { column, apiResource } = resourceParams || {};

  const valueInSearchField = getValueInSearchField(resourceParams);

  const handleDelete = async () => {
    try {
      setIsLoading(true);

      await deleteData(
        apiResource,
        `${column}/${resourceId}/Schedule/${schedule.scheduleId}/Remove`
      );

      handleClose();
      handleContentRefresh();
    } catch (error) {
      handleCommonError(error, t);
    }
    setIsLoading(false);
  };

  const handleContentRefresh = () => {
    refreshTableAndUpdateDetails({
      valueInSearchField: valueInSearchField,
      resourceId: resourceId,
      successMessage: t("Alert##successfully removed", {
        resourceString: t("General##schedule"),
      }),
      itemHasOpenedDetails: true,
      getTableItemDetails,
      handleResponse,
    });
  };

  return (
    <Dialog
      data-testid="details-remove-schedule-dialog"
      title={t(`Schedules##details remove schedule##title`)}
      description={t(`Schedules##details remove schedule##description`, {
        scheduleName: schedule.name,
      })}
      open={isOpen}
      isTitleSeparator
      width={!toMd ? DialogWidth.MinWidth : undefined}
      extendToMaxWidth={toMd}
      close={handleClose}
      submit={handleDelete}
      actions={
        <DialogActions
          onClose={handleClose}
          isLoading={isLoading}
          isreadyToConfirm
          confirmationButtonText={t("Button##remove")}
          confirmationButtonTestId="details-remove-schedule-confirmation-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="details-remove-schedule-cancel-button"
        />
      }
    />
  );
};

export default RemoveScheduleDialog;
