import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  currPage,
  filterQueryParams,
  searchQueryParams,
  tableColumns,
  tableData,
  tableDetails,
  tableDetailsResource,
  tableDetailsSectionState,
  tableInlineActions,
  tableName,
  tableRowsPerPage,
  tableSortedColumn,
} from "../../atoms/atoms";
import { SearchQueryParamsStateType } from "../../atoms/types";
import {
  pageGlobalStateObjectResult,
  getPageGlobalStateObjectValue,
} from "../../components/Table/functions";
import {
  getDetailsSectionStateFromLocalStorage,
  getSearchQueryParamsFromLocalStorage,
  isStoredPageParams,
  ROUTES,
  setPageParamsToLocalStorage,
  TableRowData,
  TABLE_NAMES,
  useMediaQueries,
  renderColumnsWithIsActive,
  getFiltersFromLocalStorage,
  getSortedColumnFromLocalStorage,
} from "../../shared";
import useRefreshDashboardData from "../../shared/hooks/useRefreshDashboardData";

interface IPageWrapperWithDetailsProps {
  children: ReactNode;
  pathname: ROUTES;
  currTableColumns: any;
  currTableName: TABLE_NAMES;
  inlineActions?: {
    title: string;
    icon: any;
    clickAction: (data: any) => void;
  }[];
}

const PageWrapperWithDetails = ({
  children,
  pathname,
  currTableColumns,
  currTableName,
  inlineActions,
}: IPageWrapperWithDetailsProps) => {
  const { resetDashboardDataOnPageChange } = useRefreshDashboardData();
  const { toMd } = useMediaQueries();
  const location = useLocation();

  const detailsSectionData = useRecoilValue(tableDetails);
  const [, setTableColumns] = useRecoilState(tableColumns);
  const [, setTableData] = useRecoilState<TableRowData[]>(tableData);
  const [currentTableName, setCurrentTableName] = useRecoilState(tableName);
  const [pageToShow, setPageToShow] = useRecoilState(currPage);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(tableRowsPerPage);
  const [, setResource] = useRecoilState(tableDetailsResource);
  const [isDetailsOpen, setIsDetailsOpen] = useRecoilState(
    tableDetailsSectionState
  );
  const [searchParams, setSearchParams] = useRecoilState(searchQueryParams);
  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setInlineActions] = useRecoilState(tableInlineActions);

  const [sortedColumn, setSortedColumn] = useRecoilState<any>(
    tableSortedColumn
  );

  const detailsData = pageGlobalStateObjectResult(
    location.pathname,
    detailsSectionData
  );

  useEffect(() => {
    resetDashboardDataOnPageChange();
  }, [resetDashboardDataOnPageChange]);

  useEffect(() => {
    inlineActions && setInlineActions(inlineActions);
    setIsDetailsOpen(
      toMd ? false : getDetailsSectionStateFromLocalStorage(pathname)
    );
    setFilterParams((prev: any) =>
      getPageGlobalStateObjectValue(
        pathname,
        prev,
        getFiltersFromLocalStorage(pathname)
      )
    );

    setSortedColumn((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,
        getSortedColumnFromLocalStorage(location.pathname)
      )
    );

    if (getSearchQueryParamsFromLocalStorage(pathname)?.length >= 3) {
      setSearchParams((prev: SearchQueryParamsStateType) =>
        getPageGlobalStateObjectValue(
          pathname,
          prev,
          getSearchQueryParamsFromLocalStorage(pathname)
        )
      );
    } else {
      setSearchParams((prev: SearchQueryParamsStateType) =>
        getPageGlobalStateObjectValue(pathname, prev, null)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchParams, setIsDetailsOpen, toMd, pathname, setInlineActions]);

  useEffect(() => {
    setResource(pathname);
    if (!isStoredPageParams(pathname)) {
      setPageParamsToLocalStorage({
        pathname,
        currentPage: "1",
        rowsPerPage: "25",
        selectedTableItem: null,
        searchQueryParams: null,
        desktopDetailsSection: false,
        filters: null,
        sortedColumn: "",
      });
      setRowsPerPage((prev: any) =>
        getPageGlobalStateObjectValue(location.pathname, prev, "25")
      );

      setPageToShow((prev: any) =>
        getPageGlobalStateObjectValue(pathname, prev, "1")
      );
    }
    if (pageGlobalStateObjectResult(pathname, pageToShow)) {
      if (!isDetailsOpen) {
        // the setPageParamsToLocalStorage function is called on any of the global state change
        // (pathname, pageToShow, rowsPerPage, selected item, search, detailssection )
        setPageParamsToLocalStorage({
          pathname,
          currentPage: pageGlobalStateObjectResult(pathname, pageToShow),
          rowsPerPage: pageGlobalStateObjectResult(pathname, rowsPerPage),
          selectedTableItem: null,
          searchQueryParams: pageGlobalStateObjectResult(
            pathname,
            searchParams
          ),
          desktopDetailsSection: toMd
            ? false
            : getDetailsSectionStateFromLocalStorage(pathname),
          filters: pageGlobalStateObjectResult(pathname, filterParams),
          sortedColumn: pageGlobalStateObjectResult(pathname, sortedColumn),
        });
      } else if (detailsData && isDetailsOpen) {
        // the setPageParamsToLocalStorage function is called on any of the global state change
        // (pathname, pageToShow, rowsPerPage, selected item, search, detailssection )
        setPageParamsToLocalStorage({
          pathname,
          currentPage: pageGlobalStateObjectResult(pathname, pageToShow),
          rowsPerPage: pageGlobalStateObjectResult(pathname, rowsPerPage),
          selectedTableItem: detailsData.id,
          searchQueryParams: pageGlobalStateObjectResult(
            pathname,
            searchParams
          ),
          desktopDetailsSection: toMd
            ? false
            : getDetailsSectionStateFromLocalStorage(pathname),
          filters: pageGlobalStateObjectResult(pathname, filterParams),
          sortedColumn: pageGlobalStateObjectResult(pathname, sortedColumn),
        });
      }
    }

    const columnsWithActive = renderColumnsWithIsActive(
      currTableName,
      currTableColumns
    );

    setTableColumns(columnsWithActive);
    setCurrentTableName(currTableName);
  }, [
    currentTableName,
    detailsData,
    rowsPerPage,
    sortedColumn,
    setResource,
    setRowsPerPage,
    setTableColumns,
    setTableData,
    setCurrentTableName,
    toMd,
    searchParams,
    isDetailsOpen,
    location.pathname,
    setPageToShow,
    pageToShow,
    pathname,
    currTableName,
    currTableColumns,
    filterParams,
  ]);

  return <>{children}</>;
};

export default PageWrapperWithDetails;
