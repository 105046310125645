/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import SharedFilters from "../SharedFilters";

import { useStyles } from "../../styles";
import { TABLE_NAMES } from "../../../../shared";

const DriverFilters = () => {
  const { filtersMainBlock } = useStyles({});

  return (
    <div css={css(filtersMainBlock)} data-testid="driver-filters">
      <SharedFilters
        tableName={TABLE_NAMES.Drivers}
        translateStatus="Drivers##last activity"
      />
    </div>
  );
};

export default DriverFilters;
