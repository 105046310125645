/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";

import TextLink from "../../../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../../../components/TextLink/types";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import { ROUTES } from "../../../../../shared";

interface IEnquiryTextProps {
  primaryText: string;
  linkText: string;
  component?: any;
  to?: ROUTES;
  onClick?: () => void;
}

const EnquiryText = ({
  primaryText,
  linkText,
  ...props
}: IEnquiryTextProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <div
      css={css({
        marginTop: "16px",
        display: "flex",
      })}
    >
      <Typography variant="body2" sx={{ color: colors.textDark }}>
        {primaryText}
      </Typography>
      {/* empty space: */}
      <Typography variant="body2">&nbsp;</Typography>

      <TextLink {...props} size={TEXT_LINK_SIZES.Normal} underline="none">
        {linkText}
      </TextLink>
    </div>
  );
};

export default EnquiryText;
