import { ThemeColors } from "..";
export const muiOutlinedInputDefaultStylesOverrides = (colors: ThemeColors) => {
  return {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.blue700,
        borderWidth: "1px !important",
        boxShadow: `-2px -2px 0px ${colors.blue200}, -2px 2px 0px ${colors.blue200}, 2px -2px 0px ${colors.blue200}, 2px 2px 0px ${colors.blue200}`,
      },

      "& .Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.red700,
        boxShadow: `-2px -2px 0px ${colors.red100}, -2px 2px 0px ${colors.red100}, 2px -2px 0px ${colors.red100}, 2px 2px 0px ${colors.red100}`,
      },
    },

    "& .Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.red700,
    },

    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.gray300,
    },

    "& .MuiOutlinedInput-input": {
      height: "unset",
      padding: "0",

      "&.Mui-disabled": {
        color: colors.textDarkDisabled,
        WebkitTextFillColor: colors.textDarkDisabled,
      },

      "&::placeholder": {
        color: colors.textPlaceholder,
        lineHeight: "20px",
        opacity: "1",
      },
    },
  };
};

export const desktopDetailsSectionWidth = (toLg: boolean) =>
  toLg ? "361px" : "472px";

export const PAGE_HEADER_SIDE_PADDING_SUM = "48px";

export const remainingPageHeaderWidth = (fromMd: boolean, toLg: boolean) =>
  fromMd
    ? `calc(100% - ${desktopDetailsSectionWidth(
        toLg
      )} - ${PAGE_HEADER_SIDE_PADDING_SUM})`
    : "100%";

export const generateDesktopTableStyle = (
  colors: ThemeColors,
  companiesPage: boolean,
  isTableLoading: boolean
) => {
  return {
    tbody: {
      opacity: isTableLoading ? "0.4" : "1",
      transition: "opacity 300ms ease-out",
    },

    "th, td": {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      whiteSpace: "nowrap",
    },

    th: {
      background: colors.blue100,
      padding: "8px 12px",
      borderTop: `1px solid ${colors.blue200}`,
      borderBottom: `1px solid ${colors.blue200}`,
    },

    "th:first-of-type": {
      padding: "8px 6px 8px 24px",
    },

    "th:last-of-type": {
      padding: "8px 24px 8px 12px",
    },

    td: {
      background: "white",
      padding: "10px 12px",
      maxWidth: companiesPage ? "100%" : "200px",
      overflowX: "hidden",
      textOverflow: "ellipsis",
      borderBottom: "none",
    },

    "td:first-of-type": {
      padding: "10px 12px 10px 24px",
    },

    "td:last-of-type": {
      padding: "10px 24px 10px 12px",
    },

    "tbody tr": {
      "&.Mui-selected td": {
        background: colors.blue100,
      },

      "&:hover": {
        "&.Mui-selected td": {
          background: colors.blue200,
        },
      },
    },
  };
};

export const tableColumnRightDropShadow = (
  scrollHorizontal: boolean | undefined
) => {
  return {
    filter: scrollHorizontal
      ? `drop-shadow(6px 0px 7px rgba(15, 23, 42, 0.08))`
      : "unset",
    transition: `filter 0.5s ease-in`,
  };
};

export const tableColumnDropShadow = (
  scrollHorizontal: boolean | undefined
) => {
  return {
    filter: scrollHorizontal
      ? `drop-shadow(-6px 0px 7px rgba(15, 23, 42, 0.08))`
      : "unset",
    transition: `filter 0.5s ease-in`,
  };
};

export const maxDialogWidth = {
  "& .MuiDialog-paper": {
    maxWidth: "448px",
  },
};

export const filterDropdownCustomWidth = {
  width: "246px",
};

export const inputFieldMarginBottom = {
  marginBottom: "16px",
};
