/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from "@emotion/react";
import { useContext, useState, SyntheticEvent } from "react";
import { ChipProps } from "@mui/material/Chip";

import { TAG_COLORS, TAG_SIZES } from "./enums";
import ClearIcon from "../../assets/customIcons/actions/ClearIcon";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface TagProps {
  size: TAG_SIZES;
  color: TAG_COLORS;
  title: string | number;
  css?: SerializedStyles;
  onClick?: () => void;
  onDelete?: (event: any) => void;
}

const Tag = ({
  size,
  color,
  title,
  onClick,
  onDelete,
  ...props
}: TagProps & Omit<ChipProps, "size" | "color">) => {
  const {
    colors: {
      blue100,
      blue200,
      blue300,
      blue400,
      blue700,
      blue800,
      blue900,
      gray100,
      gray200,
      gray700,
      textDark,
      white,
    },
  } = useContext(ThemeContext);

  const [isSelected, setSelected] = useState(false);

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    if (onClick) {
      setSelected(!isSelected);
      onClick();
    }
  };

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    onDelete && onDelete(e);
  };

  const styleVariants = {
    colors: {
      primary: {
        backgroundRest: isSelected ? blue700 : blue200,
        backgroundHover: isSelected ? blue800 : blue300,
        backgroundActive: isSelected ? blue900 : blue400,
        textRest: isSelected ? white : blue700,
        textActive: isSelected ? white : blue700,
        border: isSelected ? blue700 : blue100,
        icon: isSelected ? white : blue700,
        iconHover: blue800,
        iconActive: blue900,
      },
      white: {
        backgroundRest: white,
        backgroundHover: gray100,
        backgroundActive: gray200,
        textRest: gray700,
        textActive: textDark,
        border: gray200,
        icon: gray700,
        iconHover: gray700,
        iconActive: gray700,
      },
    },
    paddings: {
      medium: "5px 8px",
      small: "1px 8px",
      tiny: onDelete ? "1px 4px 1px 8px" : "1px 8px",
    },
  };

  const bgColorRest = styleVariants.colors[color].backgroundRest;
  const bgColorHover = styleVariants.colors[color].backgroundHover;
  const bgColorActive = styleVariants.colors[color].backgroundActive;
  const textColorRest = styleVariants.colors[color].textRest;
  const textColorActive = styleVariants.colors[color].textActive;
  const borderColor = styleVariants.colors[color].border;
  const iconColor = styleVariants.colors[color].icon;
  const iconColorHover = styleVariants.colors[color].iconHover;
  const iconColorActive = styleVariants.colors[color].iconActive;

  const padding = styleVariants.paddings[size];

  const fontSize = size === "tiny" ? "12px" : "14px";
  const lineHeight = size === "tiny" ? "16px" : "20px";
  const letterSpacing = size === "tiny" ? "0.4px" : "0.1px";

  return (
    <div
      {...props}
      onClick={handleClick}
      css={css({
        display: "inline-flex",
        alignItems: "center",
        border: `1px solid ${borderColor}`,
        borderRadius: "4px",
        cursor: onClick ? "pointer" : "auto",
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: bgColorRest,
        padding,

        "&:hover": {
          backgroundColor: onClick ? bgColorHover : bgColorRest,
        },

        "&:active": {
          backgroundColor: onClick ? bgColorActive : bgColorRest,
        },

        "& > svg": {
          color: iconColor,
          fontSize: "16px",
          marginLeft: "8px",
          cursor: "pointer",
          transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&:hover": {
            color: iconColorHover,
          },

          "&:active": {
            color: iconColorActive,
          },
        },
      })}
    >
      <span
        css={css({
          fontWeight: "600",
          color: textColorRest,
          fontSize,
          lineHeight,
          letterSpacing,

          "&:active": {
            color: textColorActive,
          },
        })}
      >
        {title}
      </span>

      {onDelete && (
        <ClearIcon onClick={handleDelete} data-testid={`${title}-clear-icon`} />
      )}
    </div>
  );
};

export default Tag;
