/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import ApiRoundedIcon from "@mui/icons-material/ApiRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { useRecoilState } from "recoil";

import { useStyles } from "../../../../styles";
import { ISettingsListItem } from "../../../../types/interfaces";
import { DIALOG_NAMES, SETTINGS_CONTENT_NAMES } from "../../../../types/enums";

import Separator from "../../../../../Separator/Separator";
import ListChoice from "../../../../../ListChoice/ListChoice";

import LogOutIcon from "../../../../../../assets/customIcons/actions/LogOutIcon";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { deleteStoredTokens } from "../../../../../../shared/functions/auth";
import Avatar from "../../../../../../shared/components/avatars/Avatar";
import { deleteStoredPageParams } from "../../../../../../shared";

import {
  tableData,
  twoFactorVerificationState,
} from "../../../../../../atoms/atoms";
import { useReleaseFeatureToggle } from "../../../../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../../../../shared/featureToggles/types";
import GeneralSettingsIcon from "../../../../../../assets/customIcons/GeneralSettingsIcon";
import TutorialIcon from "../../../../../../assets/customIcons/actions/TutorialIcon";
import { USER_PERMISSIONS } from "../../../../../../roles/types/enums";

interface ISettingsInitialContentProps {
  isMobile: boolean;
  setSettingsContent: (newContent: SETTINGS_CONTENT_NAMES) => void;
  closeMenu?: () => void;
  openDialog: (name: DIALOG_NAMES) => void;
}

const SettingsInitialContent = ({
  isMobile,
  setSettingsContent,
  closeMenu,
  openDialog,
}: ISettingsInitialContentProps) => {
  const {
    isReleaseFeatureEnabled,
    isReleaseFeatureEnabledForRole,
  } = useReleaseFeatureToggle();
  const {
    user,
    setIsLoading,
    setUser,
    setTokenInfo,
    getAccessStatus,
  } = useAuth();
  const { t } = useTranslation();

  const [, setIs2FaVerification] = useRecoilState(twoFactorVerificationState);
  const [, setTableRowData] = useRecoilState(tableData);

  const { settingsContentTop, settingsContentTopData } = useStyles();

  const handleItemClick = (settingsContent: SETTINGS_CONTENT_NAMES) => {
    setSettingsContent(settingsContent);
  };

  const handleLogOut = () => {
    closeMenu && closeMenu();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setTokenInfo(null);
      setUser(null);
      deleteStoredTokens();
      deleteStoredPageParams();
      setIs2FaVerification(false);
      setTableRowData([]);
    }, 500);
  };

  const settingsItemList: ISettingsListItem[] = [
    {
      title: t("Settings##general settings"),
      icon: <GeneralSettingsIcon />,
      action: () => openDialog(DIALOG_NAMES.GeneralSettings),
      enabled: true,
    },
    {
      title: t("Languages##language"),
      icon: <LanguageIcon />,
      action: () => handleItemClick(SETTINGS_CONTENT_NAMES.Language),
      enabled: isReleaseFeatureEnabled(FEATURE_NAMES.ChangeLanguage),
    },
    {
      title: t("Settings##api management"),
      icon: <ApiRoundedIcon />,
      action: () => openDialog(DIALOG_NAMES.ApiManagement),
      enabled: getAccessStatus(USER_PERMISSIONS.ManageApiCredentials),
    },
    {
      title: t("Settings##security"),
      icon: <LockOutlinedIcon />,
      action: () => openDialog(DIALOG_NAMES.Security),
      enabled: isReleaseFeatureEnabled(FEATURE_NAMES.ShowSecuritySettings),
    },
    {
      title: t("Settings##tutorials"),
      icon: <TutorialIcon />,
      action: () => handleItemClick(SETTINGS_CONTENT_NAMES.Tutorials),
      enabled: isReleaseFeatureEnabledForRole(
        FEATURE_NAMES.ShowTutorials,
        user?.role
      ),
    },
    {
      title: t("Settings##company card authenticator"),
      icon: <FileDownloadOutlinedIcon />,
      action: () => openDialog(DIALOG_NAMES.CompanyCardAuth),
      id: "companyCardAuthButton",

      enabled: isReleaseFeatureEnabled(FEATURE_NAMES.CcaFileDownload),
      actionDisabled: !isReleaseFeatureEnabledForRole(
        FEATURE_NAMES.CcaFileDownload,
        user?.role
      ),
    },
  ];

  return (
    <>
      {!isMobile && user && (
        <>
          <div css={css(settingsContentTop)}>
            <Avatar
              textToCut={user?.email}
              customStyle={{ marginRight: "8px" }}
            />
            <div css={css(settingsContentTopData)}>
              <Typography variant="body3semiBold">{user?.email}</Typography>
              <Typography variant="body3regular">
                {user?.company.name}
              </Typography>
            </div>
          </div>
          <Separator />
        </>
      )}
      {settingsItemList.map(
        (item: ISettingsListItem) =>
          item.enabled && (
            <ListChoice
              id={item?.id}
              key={item.title}
              title={item.title}
              description={item.description}
              iconLeft={item.icon}
              onClick={item.action}
              isLoading={item.isLoading}
              disabled={item.actionDisabled}
            />
          )
      )}
      <Separator />
      <ListChoice
        title={t("Auth##log out")}
        iconLeft={<LogOutIcon />}
        onClick={handleLogOut}
      />
    </>
  );
};

export default SettingsInitialContent;
