/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import NewsImage from "./NewsImage";

import { useStyles } from "../styles";
import { NEWS_DIALOG_SIZES } from "../types/enums";

import { ThemeColors } from "../../../../../shared";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";

interface NewsImageContainerProps {
  image: string | undefined;
  colors: ThemeColors;
  height: number | string;
  width: number | string;
  listItem?: boolean;
  isLoading?: boolean;
  toMd?: boolean;
  dropzoneItem?: boolean;
}

const NewsImageContainer = ({
  image,
  colors,
  height,
  width,
  listItem,
  isLoading,
  toMd,
  dropzoneItem
}: NewsImageContainerProps) => {
  const { newsImageBox } = useStyles({
    colors,
    listItem,
  });

  const shouldHaveLoader = isLoading && !listItem;

  const setAdaptiveSize = toMd && !listItem && !dropzoneItem;
  const mobileLoaderWidth = setAdaptiveSize && "100%";

  return (
    <div data-testid="news-image-container">
      {shouldHaveLoader && (
        <RectangleLoader
          testId="news-image-loader"
          width={mobileLoaderWidth || NEWS_DIALOG_SIZES.dialogImageWidth}
          height={NEWS_DIALOG_SIZES.dialogImageHeight}
        />
      )}
      {!shouldHaveLoader && (
        <div css={css(newsImageBox)} data-testid="news-image-block">
          {image ? (
            <NewsImage
              setAdaptiveSize={setAdaptiveSize}
              height={height}
              width={width}
              image={image}
            />
          ) : (
            <NewsImage
              setAdaptiveSize={setAdaptiveSize}
              height={height}
              width={width}
              image={`../img/no_image.svg`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NewsImageContainer;
