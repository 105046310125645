import { TFunction } from "i18next";

import { Actions } from "../../PageHeader/actions/types/types";
import {
  DRIVERS_ACTIONS,
  PossibleResourceActions,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
} from "../../../shared";
import {
  COMPANIES_ACTIONS,
  USERS_ACTIONS,
} from "./../../../shared/types/enums";

export const getActionForSelectionDialogTitle = (
  resourceAction: PossibleResourceActions,
  t: TFunction<"translation", undefined>
) => {
  switch (resourceAction) {
    case VEHICLES_ACTIONS.RemoveSchedules:
    case DRIVERS_ACTIONS.RemoveSchedules:
      return t("Dialog##remove schedules");

    case VEHICLES_ACTIONS.DeleteVehicle:
      return t("Dialog##delete vehicles");
    case DRIVERS_ACTIONS.DeleteDriver:
      return t("Dialog##delete drivers");
    case COMPANIES_ACTIONS.DeleteCompany:
      return t("Dialog##delete companies");
    case USERS_ACTIONS.DeleteUser:
      return t("Dialog##delete users");
    default:
      return "";
  }
};

export const getActionForSelectionDialogDescription = (
  resourceAction: PossibleResourceActions,
  t: TFunction<"translation", undefined>
) => {
  switch (resourceAction) {
    case VEHICLES_ACTIONS.DeleteVehicle:
      return t("Dialog##confirm delete vehicles");
    case DRIVERS_ACTIONS.DeleteDriver:
      return t("Dialog##confirm delete drivers");
    case COMPANIES_ACTIONS.DeleteCompany:
      return t("Dialog##confirm delete companies");
    case USERS_ACTIONS.DeleteUser:
      return t("Dialog##confirm delete users");

    case VEHICLES_ACTIONS.AddSchedule:
      return t("Dialog##confirm add schedule");

    case VEHICLES_ACTIONS.RemoveSchedules:
      return t("Dialog##remove schedules description##vehicles");
    case DRIVERS_ACTIONS.RemoveSchedules:
      return t("Dialog##remove schedules description##drivers");
    default:
      return "";
  }
};

export const getActionForSelectionConfirmationButtonText = (
  resourceAction: PossibleResourceActions,
  t: TFunction<"translation", undefined>
) => {
  switch (resourceAction) {
    case VEHICLES_ACTIONS.DeleteVehicle:
    case DRIVERS_ACTIONS.DeleteDriver:
    case USERS_ACTIONS.DeleteUser:
    case COMPANIES_ACTIONS.DeleteCompany:
      return t("Dialog##delete");

    case VEHICLES_ACTIONS.AddSchedule:
      return t("Dialog##add");
    case VEHICLES_ACTIONS.CreateSchedule:
      return t("Dialog##create");

    case VEHICLES_ACTIONS.RemoveSchedules:
    case DRIVERS_ACTIONS.RemoveSchedules:
      return t("Dialog##remove");
    default:
      return "";
  }
};

export const getPayloadObject = (
  resourceName: TABLE_NAMES | undefined,
  selectedRows: string[]
) => {
  switch (resourceName) {
    case TABLE_NAMES.Vehicles:
      return { vehicleIds: selectedRows };
    case TABLE_NAMES.Drivers:
      return { driverIds: selectedRows };
    case TABLE_NAMES.Users:
      return { userIds: selectedRows };
    case TABLE_NAMES.Companies:
      return { companyIds: selectedRows };
    default:
      return {};
  }
};

export const getButtonText = (
  action: Actions,
  t: TFunction<"translation", undefined>
) => {
  switch (action) {
    case DRIVERS_ACTIONS.AddSchedule:
    case VEHICLES_ACTIONS.AddSchedule:
      return t("Button##add to schedule");

    case VEHICLES_ACTIONS.CreateSchedule:
    case DRIVERS_ACTIONS.CreateSchedule:
      return t("Button##create schedule");

    case VEHICLES_ACTIONS.RemoveSchedules:
    case DRIVERS_ACTIONS.RemoveSchedules:
      return t("Button##remove schedules");

    case VEHICLES_ACTIONS.DeleteVehicle:
    case DRIVERS_ACTIONS.DeleteDriver:
    case USERS_ACTIONS.DeleteUser:
    case COMPANIES_ACTIONS.DeleteCompany:
      return t("Button##delete");
    default:
      return "";
  }
};
