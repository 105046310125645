import { useTranslation } from "react-i18next";

import PasswordsContainer from "../../PasswordsContainer/PasswordsContainer";
import { BUTTON_TYPE } from "../../../types";
import usePasswordsFormInputs from "../../../hooks/usePasswordsFormInputs";

const CreateAccount = () => {
  const { t } = useTranslation();
  const { createAccountFormInputs } = usePasswordsFormInputs();

  return (
    <PasswordsContainer
      inputs={createAccountFormInputs}
      button={BUTTON_TYPE.SignUp}
      name={t("Auth##create password")}
    />
  );
};

export default CreateAccount;
