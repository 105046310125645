import { useTranslation } from "react-i18next";

import {
  DownloadLiveStatusDataType,
  DownloadStatusesTypes,
  DownloadStatusLiveStatusesType,
} from "../components/DownloadStatusDialog/types";

import CircleStepper from "../../../../CirclesStepper/CircleStepper";
import { BANNER_MESSAGE_STATUSES } from "../../../../BannerMessage/types";
import BannerMessage from "../../../../BannerMessage/BannerMessage";

import { useMediaQueries } from "../../../../../shared";
import RectangleLoader from "../../../../../shared/components/loaders/RectangleLoader";
import { replaceCamelCaseStringToSeparateWords } from "../../../../../shared/functions/functions";
import { CircleStepperStatusTypes } from "../../../../CirclesStepper/types";

interface UseDownloadStatusFunctionsProps {
  downloadLiveStatuses: DownloadStatusLiveStatusesType | null;
}

const useDownloadStatusFunctions = ({
  downloadLiveStatuses,
}: UseDownloadStatusFunctionsProps) => {
  const { toMd } = useMediaQueries();
  const { t } = useTranslation();

  const loaderHeight = toMd ? "390px" : "108px";

  const isLastElement = (
    index: number,
    downloadLiveStatuses: DownloadStatusLiveStatusesType
  ) => index === downloadLiveStatuses.stages.length - 1;

  const successStep = (
    passedSuccessStage: boolean,
    index: number,
    stage: string
  ) =>
    passedSuccessStage && (
      <CircleStepper
        showLeftHorizontalLine={index !== 0}
        stepTitle={replaceCamelCaseStringToSeparateWords(stage)}
        status={CircleStepperStatusTypes.success}
        lastElement={isLastElement(
          index,
          downloadLiveStatuses as DownloadStatusLiveStatusesType
        )}
      />
    );

  const currentStep = (
    isCurrentStage: boolean,
    index: number,
    stage: string,
    finished: boolean
  ) =>
    isCurrentStage && (
      <CircleStepper
        showLeftHorizontalLine={index !== 0}
        stepTitle={replaceCamelCaseStringToSeparateWords(stage)}
        status={CircleStepperStatusTypes.current}
        lastElement={isLastElement(
          index,
          downloadLiveStatuses as DownloadStatusLiveStatusesType
        )}
        pulseEffect={!finished}
      />
    );

  const notCompletedStep = (index: number, stage: string) => (
    <CircleStepper
      showLeftHorizontalLine={index !== 0}
      stepTitle={replaceCamelCaseStringToSeparateWords(stage)}
      status={CircleStepperStatusTypes.notCompleted}
      lastElement={isLastElement(
        index,
        downloadLiveStatuses as DownloadStatusLiveStatusesType
      )}
    />
  );

  const renderStepsLoader = () => (
    <RectangleLoader width={"100%"} height={loaderHeight} />
  );

  const errorBanners = [
    {
      status: DownloadStatusesTypes.InsufficientCredits,
      bannerStatus: BANNER_MESSAGE_STATUSES.Critical,
      title: DownloadStatusesTypes.InsufficientCredits,
    },
    {
      status: DownloadStatusesTypes.VehicleDeactivated,
      bannerStatus: BANNER_MESSAGE_STATUSES.Warning,
      title: DownloadStatusesTypes.VehicleDeactivated,
    },
  ];

  const generateBannerMessage = (
    liveStatusData: DownloadLiveStatusDataType
  ) => {
    const errorBanner = errorBanners.find(
      (banner) => banner.status === liveStatusData.Status
    );

    return (
      errorBanner && {
        bannerStatus: errorBanner.bannerStatus,
        title: errorBanner.title,
      }
    );
  };

  const renderErrorBanner = (liveStatusData: DownloadLiveStatusDataType) => (
    <BannerMessage
      data-testid="download-status-error-banner"
      status={
        generateBannerMessage(liveStatusData)
          ?.bannerStatus as BANNER_MESSAGE_STATUSES
      }
      title={t(
        `Dialog##download status banners##${
          generateBannerMessage(liveStatusData)?.title
        }`
      )}
      externalCustomStyle={{ margin: "16px 0" }}
    />
  );

  return {
    isLastElement,
    successStep,
    currentStep,
    notCompletedStep,
    renderStepsLoader,
    generateBannerMessage,
    renderErrorBanner,
  };
};

export default useDownloadStatusFunctions;
