/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import TextLink from "../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../components/TextLink/types";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <span css={css({ marginRight: "5px" })}>
        {t("Auth##do not have account")}
      </span>

      <TextLink
        size={TEXT_LINK_SIZES.Large}
        href="https://teltonika-gps.com/about-us/contacts"
        target="_blank"
        rel="noreferrer"
      >
        {t("Auth##contact us")}
      </TextLink>
    </Fragment>
  );
};

export default ContactUs;
