/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, useContext, ReactNode, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { useRecoilValue } from "recoil";

import OptionalTooltip from "../Tooltip/OptionalTooltip";
import { BUTTON_ACTIONS } from "./types/enums";

import Button from "../Button/Button";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { useFormattedNumber } from "../../shared";
import { noSearchResults, tableData } from "../../atoms/atoms";

interface StepperProps {
  value: number;
  iconLeft: ReactNode;
  iconRight: ReactNode;
  iconFirst: ReactNode;
  iconLast: ReactNode;
  minValue?: number;
  maxValue?: number;
  tooltipLeft?: string;
  tooltipRight?: string;
  tooltipFirst?: string;
  tooltipLast?: string;
  buttonClick: (buttonAction: BUTTON_ACTIONS) => void;
  customValue: (data: number) => void;
}

const Stepper = ({
  value,
  iconLeft,
  iconRight,
  iconFirst,
  iconLast,
  minValue,
  maxValue,
  tooltipLeft,
  tooltipRight,
  tooltipFirst,
  tooltipLast,
  buttonClick,
  customValue,
  ...props
}: StepperProps) => {
  const {
    colors: { black, gray200, textDark },
  } = useContext(ThemeContext);

  const { renderFormattedNumber } = useFormattedNumber();
  const { t } = useTranslation();

  const noResults = useRecoilValue(noSearchResults);
  const tableRowData = useRecoilValue(tableData);
  const [currentPage, setCurrentPage] = useState(value);

  useEffect(() => {
    setCurrentPage(value);
  }, [value]);

  const isLeftBtnDisabled = minValue !== undefined && value <= minValue;
  const isRightBtnDisabled = maxValue !== undefined && value >= maxValue;

  const isTableEmpty = tableRowData?.length === 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const userInput = +e.target.value.replace(/\D/g, "");

    maxValue && userInput > maxValue
      ? setCurrentPage(maxValue)
      : setCurrentPage(userInput);
  };

  return (
    <div
      data-testid="stepper"
      {...props}
      css={css({
        display: "flex",
        alignItems: "center",

        button: {
          color: black,
        },
      })}
    >
      <OptionalTooltip
        tooltipPlacement="top"
        tooltipTitle={tooltipFirst ?? ""}
        isDisabled={isLeftBtnDisabled}
      >
        <span>
          <Button
            data-testid="iconFirst-button"
            disabled={isLeftBtnDisabled}
            variant="iconOnly"
            color="white"
            size="small"
            icon={iconFirst}
            onClick={() => buttonClick(BUTTON_ACTIONS.FirstPage)}
            css={css({
              marginRight: "8px",
            })}
          />
        </span>
      </OptionalTooltip>

      <OptionalTooltip
        tooltipPlacement="top"
        tooltipTitle={tooltipLeft ?? ""}
        isDisabled={isLeftBtnDisabled}
      >
        <span>
          <Button
            data-testid="iconLeft-button"
            disabled={isLeftBtnDisabled}
            variant="iconOnly"
            color="white"
            size="small"
            icon={iconLeft}
            onClick={() => buttonClick(BUTTON_ACTIONS.PreviousPage)}
          />
        </span>
      </OptionalTooltip>

      <div
        css={css({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "36px",
          minWidth: "36px",
          padding: "0 8px",
          boxSizing: "border-box",
        })}
      >
        <TextField
          variant="filled"
          size="small"
          onChange={handleChange}
          value={noResults || isTableEmpty ? 0 : currentPage}
          onBlur={() => {
            customValue(currentPage);
          }}
          onKeyDown={(ev) => ev.key === "Enter" && customValue(currentPage)}
          css={css({
            width: `${currentPage?.toString().length + 1}ch `,

            transition:
              "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            "&:hover": {
              backgroundColor: gray200,
              borderRadius: "6px 6px 0 0",
            },

            "& .MuiInputBase-input": {
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "20px",
              letterspacing: "0.1px",
              textAlign: "center",
            },
            "& .MuiFilledInput-input": {
              padding: "8px 4px",
            },

            "& .MuiFilledInput-root::after": {
              borderBottom: "unset",
            },

            "& .MuiFilledInput-root, &.Mui-focused": {
              backgroundColor: "transparent",
            },
          })}
        />

        <Typography
          variant="body2"
          sx={{ color: textDark, marginLeft: "4px", whiteSpace: "nowrap" }}
        >
          {maxValue !== undefined &&
            t("Button##pages of", {
              total: renderFormattedNumber(maxValue),
            })}
        </Typography>
      </div>

      <OptionalTooltip
        tooltipPlacement="top"
        tooltipTitle={tooltipRight ?? ""}
        isDisabled={isRightBtnDisabled}
      >
        <span>
          <Button
            data-testid="iconRight-button"
            disabled={isRightBtnDisabled}
            variant="iconOnly"
            color="white"
            size="small"
            icon={iconRight}
            onClick={() => buttonClick(BUTTON_ACTIONS.NextPage)}
            css={css({
              marginRight: "8px",
            })}
          />
        </span>
      </OptionalTooltip>

      <OptionalTooltip
        tooltipPlacement="top"
        tooltipTitle={tooltipLast ?? ""}
        isDisabled={isRightBtnDisabled}
      >
        <span>
          <Button
            data-testid="iconLast-button"
            disabled={isRightBtnDisabled}
            variant="iconOnly"
            color="white"
            size="small"
            icon={iconLast}
            onClick={() => buttonClick(BUTTON_ACTIONS.LastPage)}
          />
        </span>
      </OptionalTooltip>
    </div>
  );
};

export default Stepper;
