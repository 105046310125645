/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import Notifications from "./Notifications/Notifications";
import Settings from "./Settings/Settings";

import { useStyles } from "../styles";

import { useReleaseFeatureToggle } from "../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../shared/featureToggles/types";

interface ControlsProps {
  handleDrawer?: (value: boolean) => void;
}

const Controls = ({ handleDrawer }: ControlsProps) => {
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();

  const { navbarControls } = useStyles();

  return (
    <>
      <div css={css(navbarControls)} data-testid="settings-controls">
        {isReleaseFeatureEnabled(FEATURE_NAMES.ShowNotifications) && (
          <Notifications />
        )}

        <Settings handleDrawer={handleDrawer} />
      </div>
    </>
  );
};

export default Controls;
