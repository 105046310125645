import { useState } from "react";
import { useTranslation } from "react-i18next";
import { INPUT_LENGTH, INPUT_VALUES_LENGTH } from "..";
import { isNumeric } from "../../components/PageHeader/actions/functions";

const useImeiValidation = (imei: any) => {
  const { t } = useTranslation();
  const [imeiError, setImeiError] = useState<string | null>(null);

  const isCorrectLength = (imeiValueLength: number) =>
    imeiValueLength === INPUT_VALUES_LENGTH.Imei;

  const imeiIsValid = isNumeric(imei) && isCorrectLength(imei.length);

  const validateImei = (e: any) => {
    const inputValue = e.target.value;
    if (
      (inputValue && !imeiIsValid) ||
      (inputValue.length > INPUT_VALUES_LENGTH.Imei &&
        !isCorrectLength(inputValue.length))
    ) {
      setImeiError(
        t("Dialog##imei not valid", {
          maxNumber: INPUT_LENGTH.Imei,
        })
      );
    }
    if (inputValue.length === 0) {
      setImeiError(t("Dialog##imei should not be empty"));
    }
  };

  const clearImeiError = () => setImeiError(null);

  return {
    imeiIsValid,
    imeiError,
    setImeiError,
    clearImeiError,
    validateImei,
  };
};

export default useImeiValidation;
