import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    searchInputSpan: {
      width: "246px",

      "@media(max-width: 839px)": {
        width: "unset",
      },
    },
    inputField: {
      width: "246px",

      "@media(max-width: 1439px)": {
        width: "100%",
      },
    },
  } as CSSObject);
