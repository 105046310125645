/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Skeleton } from "@mui/material";
import { useContext } from "react";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

const ScheduleCardLoader = () => {
  const {
    colors: { gray100 },
  } = useContext(ThemeContext);

  return (
    <div data-testid="schedule-card-loader">
      <Skeleton
        variant="rectangular"
        height="16px"
        width="216px"
        css={css({
          background: gray100,
          borderRadius: "46px",
          marginBottom: "8px",
        })}
      />
      <Skeleton
        variant="rectangular"
        height="16px"
        width="129px"
        css={css({
          background: gray100,
          borderRadius: "46px",
        })}
      />
    </div>
  );
};

export default ScheduleCardLoader;
