/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { ReactNode, useContext } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

import { useDialog } from "./actions/hooks/useDialog";
import { useStyles } from "./styles";
import SchedulesMainDialog from "./actions/dialogs/reusableDialogs/SchedulesMainDialog/SchedulesMainDialog";

import Button from "../Button/Button";
import { STEP_GROUP } from "../TutorialMode/types";
import useTutorialMode from "../TutorialMode/hooks/useTutorialMode";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import {
  DRIVERS_ACTIONS,
  TABLE_NAMES,
  VEHICLES_ACTIONS,
  tableNameParams,
  useFormattedNumber,
  useMediaQueries,
} from "../../shared";
import {
  selectedTableRows,
  tableDetailsSectionState,
  tableName,
  tablePageData,
} from "../../atoms/atoms";
import HeaderTitleLoader from "../../shared/components/loaders/HeaderTitleLoader";
import useInitialLoading from "../../shared/hooks/useInitialLoading";

interface IPageHeaderProps {
  children: ReactNode;
  headerTitle: string;
  tableItemsCount?: number;
  isPageWithDetails: boolean;
}

const PageHeader = ({ children, headerTitle }: IPageHeaderProps) => {
  const { colors } = useContext(ThemeContext);

  const { toMd, toSm, fromMd, toLg } = useMediaQueries();
  const { t } = useTranslation();
  const { renderFormattedNumber } = useFormattedNumber();
  const { isInitialLoading } = useInitialLoading();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const {
    stepIndex,
    stepGroup,
    checkIfCorrectTutorialStep,
    setTutorialDetails,
  } = useTutorialMode();

  const isDesktopDetailsOpen = useRecoilValue(tableDetailsSectionState);
  const resourceName = useRecoilValue(tableName);
  const pageData: any = useRecoilValue(tablePageData);
  const selectedRows = useRecoilValue(selectedTableRows);

  const dashboardPage = headerTitle === t(`General##${TABLE_NAMES.Dashboard}`);

  const { pageHeader, pageHeaderTitle, pageHeaderActions } = useStyles(
    colors,
    !dashboardPage,
    dashboardPage
  );

  const resourceParams = tableNameParams[resourceName!] || {};

  const countOfTableItems = pageData?.totalCount ?? pageData?.length;

  const showIconOnly = toMd || (fromMd && toLg && isDesktopDetailsOpen);

  const handleVehicleSchedulesOpen = () => {
    const createScheduleStepIndex = toMd ? 3 : 2;
    const applyScheduleStepIndex = toMd ? 4 : 2;

    const createScheduleTutorialStep = checkIfCorrectTutorialStep(
      STEP_GROUP.TutorialCreateSchedule,
      createScheduleStepIndex
    );

    const applycheduleTutorialStep = checkIfCorrectTutorialStep(
      STEP_GROUP.TutorialApplySchedule,
      applyScheduleStepIndex
    );

    if (createScheduleTutorialStep || applycheduleTutorialStep) {
      setTutorialDetails({
        isTutorialOpen: false,
        stepIndex: stepIndex + 1,
        stepGroup,
        isTutorialActive: true,
      });
    }

    openDialog(VEHICLES_ACTIONS.Schedules);
  };

  const handleSchedulesButtonClick = () => {
    switch (headerTitle) {
      case t(`General##${TABLE_NAMES.Vehicles}`):
        return handleVehicleSchedulesOpen();

      case t(`General##${TABLE_NAMES.Drivers}`):
        return openDialog(DRIVERS_ACTIONS.Schedules);

      default:
        return;
    }
  };

  const schedulesButtonExists =
    headerTitle === t(`General##${TABLE_NAMES.Vehicles}`) ||
    headerTitle === t(`General##${TABLE_NAMES.Drivers}`);

  const schedulesMainAction = resourceParams?.actionMain;

  const shouldHaveLoader = isInitialLoading;

  return (
    <div css={css([pageHeader])}>
      <div css={css(pageHeaderTitle)}>
        {shouldHaveLoader && (
          <HeaderTitleLoader dashboardPage={dashboardPage} />
        )}
        {!shouldHaveLoader && (
          <>
            <Typography
              variant="h6"
              sx={{ color: colors.textDark, marginRight: "5px" }}
            >
              {headerTitle}
            </Typography>
            {!dashboardPage && (
              <Typography variant="h6" sx={{ color: colors.textPlaceholder }}>
                {pageData
                  ? `(${renderFormattedNumber(countOfTableItems)})`
                  : `(${0})`}
              </Typography>
            )}

            {schedulesButtonExists && (
              <Button
                id="schedulesDialogBtn"
                fullWidth={toSm}
                // loader appears only on the specified screen
                hasRectangleLoader={toSm}
                variant={showIconOnly ? "iconOnly" : "iconLeft"}
                size="small"
                color={selectedRows.length > 0 ? "primary" : "white"}
                icon={<CalendarMonthRoundedIcon sx={{ fontSize: 20 }} />}
                onClick={handleSchedulesButtonClick}
                isInitialLoading={isInitialLoading}
                loaderWidth="100%"
                highlightAction={selectedRows.length > 0}
                css={css([
                  {
                    marginLeft: toSm ? "auto" : "16px",
                  },
                  showIconOnly && { width: "36px" },
                ])}
              >
                {t("Button##schedules")}
              </Button>
            )}
          </>
        )}
      </div>
      {/* Page header actions loader appears in <Button /> component */}
      <div css={css(pageHeaderActions)}>{children}</div>

      <SchedulesMainDialog
        isOpen={schedulesMainAction ? isDialogOpen[schedulesMainAction] : false}
        resourceParams={resourceParams}
        onClose={closeDialog}
      />
    </div>
  );
};

export default PageHeader;
