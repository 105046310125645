const BreakOrRestIcon = (props: any) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.25024C4.41421 5.25024 4.75 5.58603 4.75 6.00024L4.75 20.0002C4.75 20.4145 4.41421 20.7502 4 20.7502C3.58579 20.7502 3.25 20.4145 3.25 20.0002L3.25 6.00024C3.25 5.58603 3.58579 5.25024 4 5.25024Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 11.9999C21.4141 11.9999 21.7499 12.3357 21.7499 12.7499L21.7499 19.9999C21.7499 20.4141 21.4141 20.7499 20.9999 20.7499C20.5857 20.7499 20.2499 20.4141 20.2499 19.9999L20.2499 12.7499C20.2499 12.3357 20.5857 11.9999 20.9999 11.9999Z"
        fill="currentColor"
      />
      <path
        d="M4 12.0006L4.37589 11.7897L4.75178 11.5787C4.75178 11.8117 4.94066 12.0006 5.17366 12.0006H21V16.0006H5.181C4.94395 16.0006 4.75178 16.1928 4.75178 16.4298L4 16.0006V12.0006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BreakOrRestIcon;
