/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import React, { Dispatch, SetStateAction, useEffect } from "react";

import { selectableImageType } from "../..";
import { useStyles } from "../../styles";
import { imageList } from "../../config";
import SelectableImage from "../../components/SelectableImage";

import Dialog from "../../../../../../components/Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import {
  DialogHeight,
  DialogWidth,
} from "../../../../../../components/Dialog/types/enums";
import { ThemeColors, useMediaQueries } from "../../../../../../shared";

interface SelectImageDialogProps {
  isDialogOpen: boolean;
  onDialogClose: () => void;
  colors: ThemeColors;
  setSelectedImage: Dispatch<SetStateAction<selectableImageType | null>>;
  selectedImage: selectableImageType | null;
  setIsInteracted?: Dispatch<SetStateAction<boolean>>;
}

const SelectImageDialog = ({
  isDialogOpen,
  onDialogClose,
  colors,
  setSelectedImage,
  selectedImage,
  setIsInteracted,
}: SelectImageDialogProps) => {
  const { toSm, toMd, fromLg, fromMd } = useMediaQueries();
  const { t } = useTranslation();

  const [
    chosenImage,
    setChosenImage,
  ] = React.useState<selectableImageType | null>(null);

  const { selectImageContainer } = useStyles({
    colors,
    toSm,
  });

  const isreadyToConfirm = Boolean(chosenImage);
  const dialogHeightfromLg = fromLg && DialogHeight.selectImageHeight;
  const dialogHeightfromMd = fromMd && DialogHeight.selectImgaeHeigthFromMd;

  useEffect(() => {
    setChosenImage(selectedImage);
  }, [selectedImage, setChosenImage, isDialogOpen]);

  const handleSubmitSelectImage = () => {
    setSelectedImage(chosenImage);
    setIsInteracted && setIsInteracted(true);
    onDialogClose();
  };

  const handleCloseDialog = () => {
    setChosenImage(null);
    onDialogClose();
  };

  const handleImageClick = (image: selectableImageType) => {
    if (chosenImage?.id === image.id) {
      setChosenImage(null);
      return;
    }
    setChosenImage(image);
  };

  const generateSelectableImageList = () => {
    return imageList.map((image: { id: number; imageName: string }) => (
      <SelectableImage
        key={image.id}
        image={image}
        colors={colors}
        selectedImage={chosenImage}
        onImageClick={handleImageClick}
      />
    ));
  };

  return (
    <Dialog
      data-testid="select-image-dialog"
      title={t("Dialog##select image")}
      description={t("Dialog##select image description")}
      width={toMd ? "100%" : DialogWidth.Dialog920px}
      maxHeight={dialogHeightfromLg || dialogHeightfromMd || undefined}
      isTitleSeparator
      isActionsSeparator
      open={isDialogOpen}
      close={handleCloseDialog}
      submit={handleSubmitSelectImage}
      actions={
        <DialogActions
          onClose={handleCloseDialog}
          isLoading={false}
          isreadyToConfirm={isreadyToConfirm}
          confirmationButtonText={t("Button##save")}
          confirmationButtonTestId="select-image-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-select-image"
        />
      }
    >
      <div css={css(selectImageContainer)}>{generateSelectableImageList()}</div>
    </Dialog>
  );
};

export default SelectImageDialog;
