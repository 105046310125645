import { useContext } from "react";
import { AxiosError } from "axios";
import { TFunction } from "i18next";

import { ALERT_STATUS } from "../../context/alert/types";
import { AlertContext } from "../../context/alert/AlertContextProvider";
import { RESPONSE_MESSAGES } from "../../api/types";

const useResponse = () => {
  const { setAlert } = useContext(AlertContext);

  const handleResponse = (
    status: ALERT_STATUS,
    message: string,
    description?: string
  ) => {
    if (message === RESPONSE_MESSAGES.CanNotReadToken) {
      return;
    }
    // don't display alert snackbar on error 500
    if (message === RESPONSE_MESSAGES.Status500) {
      return;
    }
    return setAlert(status, message, description);
  };

  const handleCommonError = (
    error: any,
    t: TFunction<"translation", undefined>
  ) => {
    const err = error as AxiosError<any>;
    if (err) {
      const responseData: string = err.response?.data
        ? t(`Alert##${err.response?.data.message}`)
        : t("Alert##action failed");

      handleResponse(ALERT_STATUS.Critical, responseData);
    }
  };

  const handleErrorWithEntries = (
    error: any,
    t: TFunction<"translation", undefined>
  ) => {
    const err = error as AxiosError<any>;
    if (err) {
      const responseMessage = err.response?.data.message;

      const translatedMessage =
        responseMessage && t(`Alert##${responseMessage}`);

      const responseEntries = err.response?.data?.entries;
      const responseEntriesMessage =
        err.response?.data !== "" &&
        responseEntries.length > 0 &&
        t(`Alert##${err.response?.data?.entries[0]?.message}`);
      const errorMessage =
        responseEntriesMessage || translatedMessage || err.message;
      handleResponse(ALERT_STATUS.Critical, errorMessage);
    }
  };

  return { handleCommonError, handleErrorWithEntries, handleResponse };
};

export default useResponse;
