/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";

import DetailsCard from "../DetailsCard";
import DetailsCardItemText from "../DetailsCardItemText";
import DetailsCardItemTitle from "../DetailsCardItemTitle";
import { useStyles } from "../../../../styles";
import DriverLastActivityState from "../../../../../Table/components/DriverLastActivityState/DriverLastActivityState";
import { IGeneralDetailsProps } from "../../../../../../shared";

const DriverGeneralDetails = ({ detailsData }: IGeneralDetailsProps) => {
  const { t } = useTranslation();
  const {
    paperCardItem,
    paperCardItemAlignUnset,
    paperCardItemFixedHeight,
  } = useStyles();

  return (
    <DetailsCard cardTitle={t("Details##general")} detailsData={detailsData}>
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##company")} />
        <DetailsCardItemText
          textToRender={detailsData?.company.name as string}
        />
      </div>

      <div css={css([paperCardItem, paperCardItemAlignUnset])}>
        <DetailsCardItemTitle title={t("Details##card holder")} />
        <DetailsCardItemText textToRender={detailsData?.cardName as string} />
      </div>

      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##driver id")} />
        <DetailsCardItemText
          textToRender={detailsData?.cardNumber as string}
          hasCopyButton
          copiedValue={detailsData?.cardNumber as string}
        />
      </div>
      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##last activity")} />
        <DriverLastActivityState
          lastActivity={detailsData?.lastActivity as string}
        />
      </div>
    </DetailsCard>
  );
};

export default DriverGeneralDetails;
