/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import useTimeSettings, { TimeZoneWithOffset } from "./hooks/useTimeSettings";
import { SettingsPayloadDto, TIME_SETTINGS_KEYS } from "../types";
import ContentBox from "../../../components/ContentBox/ContentBox";
import Autocomplete from "../../../../../../Autocomplete/Autocomplete";
import ToggleItemWithTooltip from "../../../../../../ToggleItem/ToggleItemWithTooltip";
import { DATE_FORMATS } from "../../../../../../../shared";

interface TimeSettingsProps {
  isChecked: boolean;
  isGeneralSettingsLoading: boolean;
  isSubmitLoading: boolean;
  systemTimeZone: any;
  userBrowserDateFormat: string;
  setSettingsPayload: Dispatch<SetStateAction<SettingsPayloadDto>>;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
  setIsTriggered: Dispatch<SetStateAction<boolean>>;
}

const TimeSettings = ({
  isChecked,
  isGeneralSettingsLoading,
  isSubmitLoading,
  systemTimeZone,
  userBrowserDateFormat,
  setSettingsPayload,
  setIsChecked,
  setIsTriggered,
}: TimeSettingsProps) => {
  const { t } = useTranslation();

  const {
    dateFormatValue,
    timeZones,
    timeZoneValue,
    formTimeZoneWithOffset,
    setDateFormatValue,
    setTimeZoneValue,
  } = useTimeSettings();

  const handleToggle = () => {
    if (isChecked) {
      setSettingsPayload((prevPayload) => ({
        ...prevPayload,
        timeSettings: {
          timeZone: timeZoneValue.name,
          dateFormat: dateFormatValue,
        },
      }));
    } else {
      setTimeZoneValue(formTimeZoneWithOffset(systemTimeZone));
      setDateFormatValue(userBrowserDateFormat);
    }

    setIsTriggered(true);
    setIsChecked(!isChecked);
  };

  const updatePayload = (key: TIME_SETTINGS_KEYS, value: string) => {
    setIsTriggered(true);

    setSettingsPayload((prevPayload) => ({
      ...prevPayload,
      timeSettings: {
        ...prevPayload.timeSettings,
        [key]: value,
      },
    }));
  };

  const handleTimeZoneChange = (timeZone: TimeZoneWithOffset) => {
    setTimeZoneValue(timeZone);
    updatePayload(TIME_SETTINGS_KEYS.TimeZone, timeZone.name);
  };

  const handleDateFormatChange = (dateFormat: string) => {
    setDateFormatValue(dateFormat);
    updatePayload(TIME_SETTINGS_KEYS.DateFormat, dateFormat);
  };

  const dateFormatOptions = [
    DATE_FORMATS.DayMonthYear,
    DATE_FORMATS.MonthDayYear,
    DATE_FORMATS.YearMonthDay,
  ];

  return (
    <div css={css({ width: "100%", marginTop: "32px" })}>
      <ContentBox
        title={t("Settings##time settings##title")}
        description={t("Settings##time settings##description")}
        noFlex
      >
        <ToggleItemWithTooltip
          showSeparator
          isDisabled={isGeneralSettingsLoading}
          title={t("Settings##time settings##time and date format toggle")}
          onToggle={handleToggle}
          isChecked={isChecked}
        />

        <Autocomplete
          data-testid="time-zone-dropdown"
          labelLeft={t("Dialog##time zone")}
          size="medium"
          options={timeZones}
          value={!isGeneralSettingsLoading ? timeZoneValue : null}
          disableClearable
          fullWidth
          readOnly={isChecked || isSubmitLoading}
          groupBy={(option) => option.offset}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={(_: SyntheticEvent, timeZone: TimeZoneWithOffset) =>
            handleTimeZoneChange(timeZone)
          }
          textFieldParams={{
            placeholder: isGeneralSettingsLoading
              ? t("Dialog##loading")
              : undefined,
          }}
          customStyle={{ margin: "16px 0 16px 0" }}
        />
        <Autocomplete
          data-testid="date-display-format-dropdown"
          labelLeft={t("Dialog##date display format")}
          size="medium"
          options={dateFormatOptions}
          disableClearable
          value={!isGeneralSettingsLoading ? dateFormatValue : null}
          readOnly={isChecked || isSubmitLoading}
          onChange={(_: SyntheticEvent, value: string) =>
            handleDateFormatChange(value)
          }
          textFieldParams={{
            placeholder: isGeneralSettingsLoading
              ? t("Dialog##loading")
              : undefined,
          }}
          labelIcon={t("Settings##time settings##date display format tooltip")}
          fullWidth
        />
      </ContentBox>
    </div>
  );
};

export default TimeSettings;
