import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

import { DriverDataDto } from "../../../../../Pages/Drivers/interfaces";
import { DRIVERS_ACTIONS, RenderConditionOptions } from "../../../../../shared";

const useDriverInlineActions = () => {
  const [rowData, setRowData] = useState<DriverDataDto | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const openDialog = (data: any) => {
    setRowData(data);
    setOpen(true);
  };

  const closeDialog = () => {
    setRowData(null);
    setOpen(false);
  };

  const handleEditDriverClick = (data: DriverDataDto) => {
    openDialog(data);
  };

  const actionTitle = DRIVERS_ACTIONS.EditDriver;

  const inlineActions = [
    {
      title: actionTitle.charAt(0).toUpperCase() + actionTitle.slice(1),
      icon: <EditIcon />,
      clickAction: (data: any) => handleEditDriverClick(data),
      // if renderConditions property is equal to true
      // then table will return inline action button
      renderConditions: ({ data, user }: RenderConditionOptions) =>
        true
    },
  ];

  return {
    inlineActions,
    rowData,
    isOpen,
    closeDialog,
  };
};

export default useDriverInlineActions;
