/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";

import { useStyles } from "../styles";

interface IconRightButtonProps {
  title: string;
  icon: ReactNode;
}

const IconRightButton = ({ title, icon }: IconRightButtonProps) => {
  const { iconRightButton } = useStyles();

  return (
    <>
      {title}
      <span css={css(iconRightButton)}>{icon}</span>
    </>
  );
};

export default IconRightButton;
