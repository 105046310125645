import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { FILES_PAGE_TABS, DRIVERS_FILES_TABLE_COLUMNS } from "../config";
import PageWrapperNoDetails from "../../wrappers/PageWrapperNoDetails";
import LayoutWrapper from "../../../components/Layout/LayoutWrapper";
import TabFilters from "../../../components/TabFilters/TabFilters";
import useTableData from "../../../components/Table/hooks/useTableData";
import Table from "../../../components/Table/Table";
import { TABLE_NAMES } from "../../../shared";
import useDriversFilesInlineActions from "../../../components/Table/actions/inlineActions/hooks/useDriversFilesInlineActions";
import DriversFilesPageHeaderActions from "../../../components/PageHeader/actions/pageHeaderActions/DriversFilesPageHeaderActions/DriversFilesPageHeaderActions";
import { isTableCheckable } from "../../../atoms/atoms";

const DriversFiles = () => {
  useTableData();
  const { inlineActions } = useDriversFilesInlineActions();

  const [, setIsCheckable] = useRecoilState(isTableCheckable);

  useEffect(() => {
    setIsCheckable(true);
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapperNoDetails
      currTableName={TABLE_NAMES.Files}
      currTableColumns={DRIVERS_FILES_TABLE_COLUMNS}
      inlineActions={inlineActions}
    >
      <LayoutWrapper showDetailsSection={false}>
        <DriversFilesPageHeaderActions />
      </LayoutWrapper>
      <Table
        hasDetails={false}
        tabFilters={<TabFilters tabs={FILES_PAGE_TABS} />}
        hasTabFilters
      />
    </PageWrapperNoDetails>
  );
};

export default DriversFiles;
