/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useContext, useState } from "react";

import VehicleQuickDownloadScheduleDialog from "./VehicleQuickDownloadScheduleDialog/VehicleQuickDownloadScheduleDialog";
import { QUICK_DOWNLOAD_STATUS } from "./types";

import { DriverDetailsDto, VehicleDetailsDto } from "../../../../types";
import useTableItemDetails from "../../../../hooks/useTableItemdetails";

import { Actions } from "../../../../../PageHeader/actions/types";
import Button from "../../../../../Button/Button";
import Tooltip from "../../../../../Tooltip/Tooltip";
import { useDialog } from "../../../../../PageHeader/actions/hooks/useDialog";
import {
  VEHICLES_ACTIONS,
  getValueInSearchField,
} from "../../../../../../shared";
import DownloadFileIcon from "../../../../../../assets/customIcons/actions/DownloadFileIcon";
import { refreshTableAndUpdateDetails } from "../../../../../../shared/functions/functions";
import useResponse from "../../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";

interface ScheduleQuickDownloadProps {
  resourceAction: Actions;
  detailsData: VehicleDetailsDto | DriverDetailsDto;
  hasQuickDownload: boolean | undefined;
}

const ScheduleQuickDownload = ({
  resourceAction,
  detailsData,
  hasQuickDownload,
}: ScheduleQuickDownloadProps) => {
  const { colors } = useContext(ThemeContext);

  const { t } = useTranslation();

  const { postData } = useAxiosPrivate();
  const { handleCommonError, handleResponse } = useResponse();
  const location = useLocation();

  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { getTableItemDetails } = useTableItemDetails();
  const valueInSearchField = getValueInSearchField(location);

  const [isLoading, setIsLoading] = useState(false);

  const { id, disabled } = detailsData;

  const handleLinkClick = async () => {
    if (resourceAction === VEHICLES_ACTIONS.QuickScheduleDownload) {
      openDialog(VEHICLES_ACTIONS.QuickScheduleDownload);
    } else {
      try {
        setIsLoading(true);
        await postData(ApiResources.DriverSchedulesQuickDownload, {
          driverId: id,
        });

        handleContentRefresh();

        handleResponse(
          ALERT_STATUS.Info,
          t("Alert##quick download created##title"),
          t("Alert##quick download created##description##drivers")
        );
      } catch (error) {
        handleCommonError(error, t);
      }
      setIsLoading(false);
    }
  };

  const handleContentRefresh = () => {
    refreshTableAndUpdateDetails({
      valueInSearchField: valueInSearchField,
      resourceId: id,
      itemHasOpenedDetails: true,
      getTableItemDetails,
      handleResponse,
    });
  };

  const handleCloseDialog = () => {
    closeDialog(VEHICLES_ACTIONS.QuickScheduleDownload);
  };

  const getQuickDownloadStatus = () => {
    if (disabled) {
      return QUICK_DOWNLOAD_STATUS.Deactivated;
    }

    return hasQuickDownload
      ? QUICK_DOWNLOAD_STATUS.Initiated
      : QUICK_DOWNLOAD_STATUS.New;
  };

  const getButtonIcon = () =>
    isLoading ? (
      <CircularProgress
        size={12}
        sx={{
          color: colors.textDarkDisabled,
        }}
      />
    ) : (
      <DownloadFileIcon css={css({ marginRight: "-2px" })} />
    );

  return (
    <>
      <Tooltip
        placement="top-end"
        size="medium"
        title={t(
          `Schedules##quick download##${resourceAction} ${getQuickDownloadStatus()}`
        )}
      >
        <div>
          <Button
            data-testid="schedule-quick-download-button"
            color="secondary"
            onClick={handleLinkClick}
            size="tiny"
            variant="iconLeft"
            disabled={hasQuickDownload || disabled || isLoading}
            icon={getButtonIcon()}
          >
            {t("Schedules##quick download##title")}
          </Button>
        </div>
      </Tooltip>

      <VehicleQuickDownloadScheduleDialog
        vehicleId={id}
        isOpen={isDialogOpen[VEHICLES_ACTIONS.QuickScheduleDownload]}
        handleClose={handleCloseDialog}
      />
    </>
  );
};

export default ScheduleQuickDownload;
