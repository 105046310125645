import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { setOptionsArrayfromQueryResponse } from "../functions";
import useScheduleRepeatValues from "../../Schedules/hooks/useScheduleRepeatValues/useScheduleRepeatValues";
import { ALERT_STATUS } from "../../../context/alert/types";
import useResponse from "../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import { useReleaseFeatureToggle } from "../../../shared/featureToggles/hooks";
import { useAuth } from "../../../context/Auth/AuthProvider";
import { FEATURE_NAMES } from "../../../shared/featureToggles/types";
import { getEnvironment } from "../../../shared/functions/functions";

const useQueryDropdown = (resource: string, showSupportRole?: boolean) => {
  const { user } = useAuth();

  const { isProductionEnv } = getEnvironment();
  const { handleResponse } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();
  const { getRepeatString } = useScheduleRepeatValues();
  const {
    isReleaseFeatureEnabledForRole,
    isReleaseFeatureEnabledForUser,
  } = useReleaseFeatureToggle();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  const initialized = useRef(false);

  const isAllowedToInviteAllRoles = isReleaseFeatureEnabledForRole(
    FEATURE_NAMES.AllowedToInviteAllRoles,
    user?.role
  );

  const isAllowedFullControlOfUsers =
    isReleaseFeatureEnabledForUser(
      FEATURE_NAMES.AllowedFullControlOfUsers,
      user?.email
    ) && isProductionEnv;

  const isAllowedToChooseSupport = isAllowedToInviteAllRoles && showSupportRole;

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setIsLoading(true);
      const getQueryData = async () => {
        try {
          const response = await getData(resource);
          setOptions(
            setOptionsArrayfromQueryResponse(
              response,
              resource,
              getRepeatString,
              t,
              isAllowedFullControlOfUsers || isAllowedToChooseSupport
            )
          );
        } catch (error) {
          const err = error as AxiosError;
          if (err) {
            handleResponse(ALERT_STATUS.Critical, err.message);
          }
        } finally {
          setIsLoading(false);
        }
      };

      getQueryData();
    }
  }, [
    getData,
    getRepeatString,
    handleResponse,
    isAllowedFullControlOfUsers,
    isAllowedToChooseSupport,
    resource,
    t,
  ]);

  return { isLoading, options };
};

export default useQueryDropdown;
