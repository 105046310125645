import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

import { INPUT_LENGTH } from "../types";

import { selectableImageType } from "../../Pages/Dashboard/LeftSide/News";

interface UseNewsFormInputsProps {
  setSelectedImage: Dispatch<SetStateAction<selectableImageType | null>>;
  titleValue?: string;
  descriptionValue?: string;
  setIsInteracted?: Dispatch<SetStateAction<boolean>>;
}

const useNewsFormInputs = ({
  setSelectedImage,
  titleValue,
  descriptionValue,
  setIsInteracted,
}: UseNewsFormInputsProps) => {
  const { t } = useTranslation();
  const [titleInputError, setTitleInputError] = useState<string | null>(null);
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);
  const [title, setTitle] = useState<string | null>(titleValue || "");
  const [description, setDescription] = useState<string | null>(
    descriptionValue || ""
  );

  const validateTitleLength = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length > INPUT_LENGTH.MaxNewsTitleLength) {
      setTitleInputError(
        t("Dialog##title not valid", {
          maxNumber: INPUT_LENGTH.MaxNewsTitleLength,
        })
      );
    }

    if (inputValue.length === 0) {
      setTitleInputError(t("Dialog##title should not be empty"));
    }
  };

  const validateDescriptionLength = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue.length > INPUT_LENGTH.MaxNewsDescriptionLength) {
      setDescriptionInputError(
        t("Dialog##description not valid", {
          maxNumber: INPUT_LENGTH.MaxNewsDescriptionLength,
        })
      );
    }

    if (inputValue.length === 0) {
      setDescriptionInputError(t("Dialog##description should not be empty"));
    }
  };

  const handleChangeTitleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsInteracted && setIsInteracted(true);
    setTitle(e.target.value);
    setTitleInputError(null);
    validateTitleLength(e);
  };

  const handleChangeDescritpionValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setIsInteracted && setIsInteracted(true);
    setDescription(e.target.value);
    setDescriptionInputError(null);
    validateDescriptionLength(e);
  };

  const resetAllValues = () => {
    setTitle(null);
    setTitleInputError(null);
    setDescription(null);
    setDescriptionInputError(null);
    setSelectedImage(null);
  };

  const isTitleValid = title && title.length < INPUT_LENGTH.MaxNewsTitleLength;
  const isDescriptionValid =
    description && description.length < INPUT_LENGTH.MaxNewsDescriptionLength;

  return {
    validateTitleLength,
    titleInputError,
    handleChangeTitleValue,
    title,
    resetAllValues,
    isTitleValid,
    validateDescriptionLength,
    handleChangeDescritpionValue,
    isDescriptionValid,
    descriptionInputError,
    description,
    setTitle,
    setDescription,
  };
};

export default useNewsFormInputs;
