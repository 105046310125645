import { useTranslation } from "react-i18next";

import { TABLE_NAMES, useFormattedNumber } from "../..";
import { TAG_COLORS, TAG_SIZES } from "../../../components/Tag/enums";
import Tag from "../../../components/Tag/Tag";

interface ISelectedRowsTagProps {
  resourceName: TABLE_NAMES | string | undefined;
  resourceCount: number;
  tagTitle?: string;
}

const SelectedRowsTag = ({
  resourceName,
  resourceCount,
  tagTitle,
}: ISelectedRowsTagProps) => {
  const { t } = useTranslation();
  const { renderFormattedNumber } = useFormattedNumber();

  const translationString = t(`Dialog##selected resource`, {
    resource: t(`General##${resourceName}`).toLocaleLowerCase(),
  });

  return (
    <Tag
      data-testid="number-of-selected-items-for-selection"
      size={TAG_SIZES.Medium}
      color={TAG_COLORS.Primary}
      title={
        tagTitle
          ? `${tagTitle}: ${renderFormattedNumber(resourceCount)}`
          : translationString + ": " + renderFormattedNumber(resourceCount)
      }
    />
  );
};

export default SelectedRowsTag;
