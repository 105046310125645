export enum STEP_GROUP {
  Default = "Default",
  TutorialAddVehicle = "AddVehicle",
  TutorialApplySchedule = "ApplySchedule",
  TutorialNewCompanyCard = "CompanyCards",
  TutorialCreateSchedule = "CreateSchedule",
  FinishTutorial = "finishTutorial",
}

export enum TUTORIAL_NAMES_FROM_USERS {
  ApplySchedule = "applyScheduleCompleted",
  CompanyCards = "companyCardsCompleted",
  CreateSchedule = "createScheduleCompleted",
}

export enum TOOLTIP_ACTIONS {
  Finish = "finish",
  GetStarted = "get started",
  Next = "next",
  Previous = "previous",
}

export enum TUTORIAL_BUTTON_TYPE {
  Proceed = "Proceed",
  Previous = "Previous",
}
