import { useTranslation } from "react-i18next";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

import { DriverScheduleDto, ScheduleDto } from "../../../../types";

import ListChoice from "../../../../../ListChoice/ListChoice";

import { ThemeColors } from "../../../../../../shared";

interface IScheduleHistoryButtonProps {
  schedule: ScheduleDto | DriverScheduleDto;
  colors: ThemeColors;
}

const ScheduleHistoryButton = ({
  schedule,
  colors,
}: IScheduleHistoryButtonProps) => {
  const { t } = useTranslation();

  return (
    <ListChoice
      title={t("Details##schedule history")}
      iconLeft={<OpenInNewOutlinedIcon sx={{ width: "16px" }} />}
      onClick={() => console.log("click driver schedule history", schedule)}
      alignIconCenter
    />
  );
};

export default ScheduleHistoryButton;
