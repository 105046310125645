/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFilters } from "../../hooks";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import FilterButton from "../../components/FilterButton";
import FilterMenu from "../../components/FilterMenu";
import { FILTER_NAMES } from "../../types";
import { useStyles } from "../../styles";
import { checkIfFilesPage, ROUTES } from "../../../../shared";

interface FilterValue {
  filterName: FILTER_NAMES;
  placeholder: string;
}

const FilesFilters = () => {
  const location = useLocation();

  const {
    anchorEl,
    handleOpenMenu,
    handleCloseMenu,
    isPartOfFilterMenu,
  } = useFilters();

  const { t } = useTranslation();

  const {
    filtersMainBlock,
    filtersListBlock,
    separateFilterDropdown,
  } = useStyles({});

  const filterValues: { [key: string]: FilterValue } = {
    [ROUTES.VehicleFiles]: {
      filterName: FILTER_NAMES.VehicleNumber,
      placeholder: t("Filter##vehicle number"),
    },
    [ROUTES.DriverFiles]: {
      filterName: FILTER_NAMES.DriverName,
      placeholder: t("Filter##driver"),
    },
  };

  const currentLocation = location.pathname;
  const filterValueFromLocation = filterValues[currentLocation];

  return (
    <div css={css(filtersMainBlock)} data-testid="files-filters">
      {checkIfFilesPage(currentLocation) && (
        <>
          {!isPartOfFilterMenu() && (
            <FilterDropdown
              filterName={filterValueFromLocation.filterName}
              placeholder={filterValueFromLocation.placeholder}
              css={css(separateFilterDropdown)}
            />
          )}

          {isPartOfFilterMenu() && (
            <>
              <FilterButton onOpen={handleOpenMenu} hasLoadingCustomStyle />

              <FilterMenu
                isOpen={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorEl={anchorEl}
              >
                <div css={css(filtersListBlock)}>
                  <FilterDropdown
                    filterName={filterValueFromLocation.filterName}
                    placeholder={filterValueFromLocation.placeholder}
                  />
                </div>
              </FilterMenu>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FilesFilters;
