/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useStyles } from "./styles";

import RectangleLoader from "../loaders/RectangleLoader";

import { capitalizeStringFirstLetter } from "../../functions/functions";
import { useFormattedNumber } from "../../hooks";

import Tag from "../../../components/Tag/Tag";
import { TAG_COLORS, TAG_SIZES } from "../../../components/Tag/enums";

interface ResourceTagProps {
  resource: any;
  isLoading: boolean;
  count: number | null;
}

const ResourceTag = ({ resource, isLoading, count }: ResourceTagProps) => {
  const { renderFormattedNumber } = useFormattedNumber();
  const { resourceTagDiv } = useStyles();

  return (
    <div key={`${resource.id}-tag`} css={css([resourceTagDiv])}>
      {isLoading ? (
        <RectangleLoader testId="resource-tag-loader" height={32} width={172} />
      ) : (
        <Tag
          data-testid="resource-tag"
          size={TAG_SIZES.Medium}
          color={TAG_COLORS.Primary}
          title={`${capitalizeStringFirstLetter(
            resource
          )}: ${renderFormattedNumber(count || 0)}`}
        />
      )}
    </div>
  );
};

export default ResourceTag;
