const TachographUnknownIcon = (props: any) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5892 8.075C14.5892 7.35833 14.3517 6.78333 13.8767 6.35C13.4017 5.91667 12.7725 5.7 11.9892 5.7C11.5059 5.7 11.0684 5.80417 10.6767 6.0125C10.285 6.22083 9.94753 6.525 9.66419 6.925C9.39753 7.30833 9.03503 7.52917 8.57669 7.5875C8.11836 7.64583 7.72253 7.525 7.38919 7.225C7.15586 7.00833 7.02669 6.74167 7.00169 6.425C6.97669 6.10833 7.05586 5.80833 7.23919 5.525C7.77253 4.725 8.45169 4.10417 9.27669 3.6625C10.1017 3.22083 11.0059 3 11.9892 3C13.6059 3 14.9184 3.45833 15.9267 4.375C16.935 5.29167 17.4392 6.49167 17.4392 7.975C17.4392 8.725 17.2809 9.4 16.9642 10C16.6475 10.6 16.0642 11.3083 15.2142 12.125C14.5975 12.7083 14.1809 13.1625 13.9642 13.4875C13.7475 13.8125 13.6059 14.1833 13.5392 14.6C13.4725 15 13.3017 15.3333 13.0267 15.6C12.7517 15.8667 12.4225 16 12.0392 16C11.6559 16 11.3267 15.8708 11.0517 15.6125C10.7767 15.3542 10.6392 15.0333 10.6392 14.65C10.6392 14 10.7809 13.4042 11.0642 12.8625C11.3475 12.3208 11.8225 11.75 12.4892 11.15C13.3392 10.4 13.9017 9.82083 14.1767 9.4125C14.4517 9.00417 14.5892 8.55833 14.5892 8.075ZM11.9892 22C11.4392 22 10.9684 21.8042 10.5767 21.4125C10.185 21.0208 9.98919 20.55 9.98919 20C9.98919 19.45 10.185 18.9792 10.5767 18.5875C10.9684 18.1958 11.4392 18 11.9892 18C12.5392 18 13.01 18.1958 13.4017 18.5875C13.7934 18.9792 13.9892 19.45 13.9892 20C13.9892 20.55 13.7934 21.0208 13.4017 21.4125C13.01 21.8042 12.5392 22 11.9892 22Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TachographUnknownIcon;
