import { AxiosError } from "axios";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { getPageGlobalStateObjectValue } from "../../Table/functions";
import useAxiosPrivate from "../../../api/hooks/useAxiosPrivate";
import {
  tableDetails,
  tableDetailsLoading,
  tableDetailsSectionState,
} from "../../../atoms/atoms";
import { ALERT_STATUS } from "../../../context/alert/types";
import useRefreshTableData from "../../../shared/hooks/useRefreshTableData";
import useResponse from "../../../shared/hooks/useResponse";
import {
  GetTableitemDetailsProps,
  LOCAL_STORAGE_KEYS,
  updatePageParamsStateInLocalStorage,
} from "../../../shared";

const useTableItemDetails = () => {
  const { handleResponse } = useResponse();
  const { getData } = useAxiosPrivate();

  const [, setTableDetails] = useRecoilState(tableDetails);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const location = useLocation();

  const [, setIsLoading] = useRecoilState(tableDetailsLoading);

  const { refreshTableData } = useRefreshTableData();

  const getTableItemDetails = useCallback(
    async ({
      itemId,
      successMessage,
      leaveTableDetailsNotUpdated,
      fromTableRowClick,
      fromEditItem,
      valueInSearchField,
      rowData,
    }: GetTableitemDetailsProps) => {
      try {
        setIsLoading(true);

        (leaveTableDetailsNotUpdated || fromEditItem) &&
          // refresh table and pass search params if they exist
          refreshTableData(undefined, valueInSearchField);
        successMessage && handleResponse(ALERT_STATUS.Success, successMessage);

        // calling item details api is not necessary when adding new item,
        // since it does not impact details section content display
        itemId &&
          !leaveTableDetailsNotUpdated &&
          (await getData(`${location.pathname}/${itemId}`).then((resp) => {
            setTableDetails((prev: any) =>
              getPageGlobalStateObjectValue(location.pathname, prev, resp.data)
            );
            fromTableRowClick && setIsDetailsOpen(true);

            // transfered from TableRow DetailsItemClick method to be sure that detailsare not opened when 404 response returns:
            fromTableRowClick &&
              updatePageParamsStateInLocalStorage(
                location.pathname,
                LOCAL_STORAGE_KEYS.DesktopDetailsSection,
                true
              );

            successMessage &&
              handleResponse(ALERT_STATUS.Success, successMessage);
          }));
      } catch (error) {
        const err = error as AxiosError;
        if (err) {
          const errorMessage = err.message;
          handleResponse(ALERT_STATUS.Critical, errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      getData,
      handleResponse,
      location.pathname,
      refreshTableData,
      setTableDetails,
      setIsDetailsOpen,
      setIsLoading,
    ]
  );

  return { getTableItemDetails };
};

export default useTableItemDetails;
