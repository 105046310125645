/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import CompanySelectorContent from "../CompanySelectorContent/CompanySelectorContent";
import Menu from "../../../../../Menu/Menu";
import { CompaniesDto, MenuAnchorEl } from "../../../../../../shared";

interface IDesktopCompanySelectorProps {
  onClose: () => void;
  anchorEl: MenuAnchorEl;
  companies: CompaniesDto[];
}

const DesktopCompanySelector = ({
  onClose,
  anchorEl,
  companies,
}: IDesktopCompanySelectorProps) => {
  return (
    <Menu
      data-testid="desktop-company-selector"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      slotProps={{
        paper: {
          id: "companySelectorMenu",
        },
      }}
      css={css({
        "& .MuiPaper-root": {
          width: "566px",
        },
      })}
    >
      <CompanySelectorContent close={onClose} companies={companies} />
    </Menu>
  );
};

export default DesktopCompanySelector;
