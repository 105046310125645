import { CSSObject } from "@emotion/react";

interface StylesInterface {
  maxHeight?: string;
}

export const useStyles = ({ maxHeight }: StylesInterface) =>
  ({
    dialogTitleWithTooltip: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    dialogContentWrapper: {
      maxHeight: maxHeight,
      overflowY: "scroll",
      marginRight: "-16px",
      scrollbarGutter: "stable",
      paddingRight: "6px",
    },
  } as CSSObject);
