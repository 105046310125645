/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useStyles } from "../../styles";
import Tooltip from "../../../Tooltip/Tooltip";
import { STEP_GROUP } from "../../../TutorialMode/types";
import useTutorialMode from "../../../TutorialMode/hooks/useTutorialMode";
import DriversIcon from "../../../../assets/customIcons/navigation/DriversIcon";
import FilesIcon from "../../../../assets/customIcons/navigation/FilesIcon";
import UsersIcon from "../../../../assets/customIcons/navigation/UsersIcon";
import VehiclesIcon from "../../../../assets/customIcons/navigation/VehiclesIcon";
import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";
import { NAVIGATION_ITEMS, ROUTES, useMediaQueries } from "../../../../shared";
import { desktopDrawerState, mobileDrawerState } from "../../../../atoms/atoms";
import FileSynchronizationIcon from "../../../../assets/customIcons/navigation/FileSynchronizationIcon";
import CompaniesIcon from "../../../../assets/customIcons/navigation/CompaniesIcon";
import DashboardIcon from "../../../../assets/customIcons/navigation/DashboardIcon";

interface ILinkItemProps {
  name: NAVIGATION_ITEMS;
  link: ROUTES;
}

const LinkItem = ({ name, link }: ILinkItemProps) => {
  const { colors } = useContext(ThemeContext);

  const { isTutorialOpen, stepGroup, setTutorialDetails } = useTutorialMode();
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();

  const isMobiledrawerOpen = useRecoilValue(mobileDrawerState);
  const isDesktopdrawerOpen = useRecoilValue(desktopDrawerState);

  const { navigationItem } = useStyles();

  const resource = window.location.pathname;
  const vehiclesPage = resource === ROUTES.Home && link === ROUTES.Vehicles;
  const urlLInk =
    resource === ROUTES.DriverFiles && name === NAVIGATION_ITEMS.Files
      ? ROUTES.DriverFiles
      : link;
  const isActive = resource === urlLInk || vehiclesPage;

  const iconToRender = (item: NAVIGATION_ITEMS) => {
    switch (item) {
      case NAVIGATION_ITEMS.Dashboard:
        return <DashboardIcon />;
      case NAVIGATION_ITEMS.Vehicles:
        return <VehiclesIcon />;
      case NAVIGATION_ITEMS.Drivers:
        return <DriversIcon />;
      case NAVIGATION_ITEMS.Files:
        return <FilesIcon />;
      case NAVIGATION_ITEMS.Companies:
        return <CompaniesIcon />;
      case NAVIGATION_ITEMS.Users:
        return <UsersIcon />;
      case NAVIGATION_ITEMS.FileSynchronization:
        return <FileSynchronizationIcon />;
      default:
        return null;
    }
  };

  const renderLinkWithConditionalTooltip = (children: any) => {
    if (!isMobiledrawerOpen && !isDesktopdrawerOpen) {
      return (
        <Tooltip
          title={t(`General##${name.toLowerCase()}`)}
          placement="right"
          size="medium"
        >
          {children}
        </Tooltip>
      );
    } else {
      return children;
    }
  };

  const handleClick = () => {
    const isCompaniesPage = resource === ROUTES.Companies;
    const isVehiclesPage = resource === ROUTES.Vehicles;

    if (link === ROUTES.Companies && isTutorialOpen) {
      setTutorialDetails({
        isTutorialOpen: isCompaniesPage,
        stepIndex: 2,
        stepGroup: stepGroup,
        isTutorialActive: true,
      });
    }

    if (
      link === ROUTES.Vehicles &&
      isTutorialOpen &&
      stepGroup === STEP_GROUP.TutorialCreateSchedule
    ) {
      const nextStepIndex = toMd ? 3 : 2;

      setTutorialDetails({
        isTutorialOpen: isVehiclesPage,
        stepIndex: nextStepIndex,
        stepGroup: stepGroup,
        isTutorialActive: true,
      });
    }

    if (
      link === ROUTES.Vehicles &&
      isTutorialOpen &&
      stepGroup === STEP_GROUP.TutorialApplySchedule
    ) {
      setTutorialDetails({
        isTutorialOpen: isVehiclesPage,
        stepIndex: toMd ? 2 : 1,
        stepGroup: stepGroup,
        isTutorialActive: true,
      });
    }
  };

  return (
    <li>
      {renderLinkWithConditionalTooltip(
        <Link
          onClick={handleClick}
          data-testid={`${name}-navigation-item`}
          to={link}
          id={name}
          css={css([
            {
              color: colors.gray700,
              backgroundColor: isActive ? colors.gray200 : "transparent",

              "& > svg": {
                color: isActive ? colors.textDark : colors.gray700,
              },

              "&:hover": {
                backgroundColor: isActive ? colors.gray300 : colors.gray100,
              },

              "&:active": {
                backgroundColor: isActive ? colors.gray300 : colors.gray200,
              },
            },
            navigationItem,
          ])}
        >
          {iconToRender(name)}
          <Typography
            variant="body2"
            css={css({
              marginLeft: "8px",
              whiteSpace: "nowrap",
            })}
          >
            {t(`General##${name.toLowerCase()}`)}
          </Typography>
        </Link>
      )}
    </li>
  );
};

export default LinkItem;
