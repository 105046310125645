import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { NewsPayloadDto, selectableImageType } from "../..";
import ImageDropzone from "../../components/ImageDropzone/ImageDropzone";

import Dialog from "../../../../../../components/Dialog/Dialog";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import { DialogHeight } from "../../../../../../components/Dialog/types/enums";
import InputField from "../../../../../../components/InputField/InputField";
import useNewsFormInputs from "../../../../../../shared/hooks/useNewsFormInputs";
import { inputFieldMarginBottom } from "../../../../../../shared";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import { ApiResources } from "../../../../../../api/resources";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useRefreshDashboardData from "../../../../../../shared/hooks/useRefreshDashboardData";
import { newsScrollOffset } from "../../../../../../atoms/atoms";

interface CreateNewsDialogProps {
  isDialogOpen: boolean;
  onClose: (resetAllVal: any) => void;
}

const CreateNewsDialog = ({ isDialogOpen, onClose }: CreateNewsDialogProps) => {
  const { postData } = useAxiosPrivate();
  const { handleResponse, handleCommonError } = useResponse();
  const { refreshNewsData } = useRefreshDashboardData();
  const { t } = useTranslation();

  const [, setScrollOffsetNumber] = useRecoilState(newsScrollOffset);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    selectedImage,
    setSelectedImage,
  ] = useState<selectableImageType | null>(null);

  const [payload, setPayload] = useState<NewsPayloadDto>({
    title: null,
    content: null,
    imageName: null,
  });

  const {
    titleInputError,
    validateTitleLength,
    handleChangeTitleValue,
    title,
    resetAllValues,
    isTitleValid,
    validateDescriptionLength,
    handleChangeDescritpionValue,
    isDescriptionValid,
    descriptionInputError,
    description,
  } = useNewsFormInputs({ setSelectedImage });

  const isPayloadReadyToConfirm =
    title &&
    isTitleValid &&
    description &&
    isDescriptionValid &&
    Boolean(selectedImage);

  useEffect(() => {
    setPayload({
      title: title?.trim() || null,
      content: description?.trim() || null,
      imageName: selectedImage?.imageName || null,
    });
  }, [title, description, selectedImage]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await postData(ApiResources.NewsItems, payload).then(() => {
        refreshNewsData();
      });

      // scroll to the start position after the news is created
      setScrollOffsetNumber(0);
      handleResponse(ALERT_STATUS.Success, t("Alert##news created"));
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsLoading(false);
      onClose(resetAllValues);
    }
  };

  return (
    <Dialog
      data-testid="create-news-dialog"
      title={t("Dialog##create news")}
      description={t("Dialog##create news description")}
      extendToMaxWidth
      maxHeight={DialogHeight.MaxHeight}
      isTitleSeparator
      isActionsSeparator
      open={isDialogOpen}
      close={() => onClose(resetAllValues)}
      submit={handleSubmit}
      actions={
        <DialogActions
          onClose={() => onClose(resetAllValues)}
          isLoading={isLoading}
          isreadyToConfirm={isPayloadReadyToConfirm}
          confirmationButtonText={t("Button##create")}
          confirmationButtonTestId="create-news-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-create-news"
        />
      }
    >
      <ImageDropzone
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        label={t("Dropzone##select image")}
      />
      <InputField
        id={uuidv4()}
        testId="create-news-title-input-field"
        size="medium"
        fullWidth
        required
        errorText={titleInputError}
        onBlur={validateTitleLength}
        onChange={handleChangeTitleValue}
        labelLeft={t("Dialog##title")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="create-news-description-input-field"
        size="medium"
        fullWidth
        required
        errorText={descriptionInputError}
        onBlur={validateDescriptionLength}
        onChange={handleChangeDescritpionValue}
        labelLeft={t("Dialog##description required")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        rows={6}
        multiline
      />
    </Dialog>
  );
};

export default CreateNewsDialog;
