/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateNewsDialog from "../Dialogs/CreateNewsDialog/CreateNewsDialog";

import Button from "../../../../../components/Button/Button";

const CreateNewsButton = () => {
  const { t } = useTranslation();

  const [isDialogOpen, setIsNewDialogOpen] = useState<boolean>(false);

  const handleAddNewsButtonClick = () => {
    setIsNewDialogOpen(true);
  };

  const handleCloseCreateNewsDialog = (resetAllVal: () => void) => {
    setIsNewDialogOpen(false);
    resetAllVal();
  };

  return (
    <>
      <Button
        data-testid="create-news-button"
        size="tiny"
        variant="iconLeft"
        icon={<AddOutlinedIcon />}
        color="secondary"
        onClick={handleAddNewsButtonClick}
        css={css({ backgroundColor: "transparent" })}
      >
        {t("News##create news")}
      </Button>

      <CreateNewsDialog
        isDialogOpen={isDialogOpen}
        onClose={handleCloseCreateNewsDialog}
      />
    </>
  );
};

export default CreateNewsButton;
