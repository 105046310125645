/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";

import useDeviceStatus from "./hooks/useDeviceStatus";
import DoughnutChart from "./components/DoughnutChart/DoughnutChart";
import {
  hoveredElementToRealIndex,
  statusBorderColor,
  statusColor,
} from "./functions";

import CardWrapper from "../../CardWrapper/CardWrapper";
import DoughnutChartListItem from "../../components/DoughnutChartListItem/DoughnutChartListItem";
import { useStyles as dashboardStyles } from "../../styles";

import { deviceStatusState, tableLoading } from "../../../../atoms/atoms";
import RectangleLoader from "../../../../shared/components/loaders/RectangleLoader";
import { useMediaQueries } from "../../../../shared";

const DeviceStatus = () => {
  const { isLoading } = useDeviceStatus();
  const { toSm } = useMediaQueries();

  const { t } = useTranslation();
  const isTableLoading = useRecoilValue(tableLoading);
  const deviceStatus = useRecoilValue(deviceStatusState);

  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredElement, setHoveredElement] = useState<number | null>(null);

  const { doughnutChartListBox, doughnutChartContainer } = dashboardStyles({
    toSm,
    fixedContentHeight: true,
  });

  const loading = isTableLoading || isLoading;

  useEffect(() => {
    !hovered && setHoveredElement(null);
  }, [hovered, hoveredElement]);

  const generateStatusList = (deviceStatus: any) =>
    deviceStatus &&
    deviceStatus.summary.map((i: any, index: number) => (
      <DoughnutChartListItem
        key={i.name}
        item={i}
        hoveredElement={hoveredElement}
        hovered={index === hoveredElementToRealIndex(hoveredElement) && hovered}
        backgroundColor={statusColor}
        borderColor={statusBorderColor}
      />
    ));

  return (
    <CardWrapper
      // height setting on toSm screen is not needed here
      isTopSection={!toSm}
      cardTitle={t("Dashboard##device status")}
      isLoading={loading}
    >
      {loading ? (
        <RectangleLoader
          width={"100%"}
          height={"100%"}
          testId="device-status-loader"
        />
      ) : (
        deviceStatus && (
          <div css={css(doughnutChartContainer)}>
            <DoughnutChart
              deviceStatus={deviceStatus}
              setHovered={setHovered}
              setHoveredElement={setHoveredElement}
            />
            <div
              css={css([
                doughnutChartListBox,
                { justifyContent: "flex-start" },
              ])}
            >
              {generateStatusList(deviceStatus)}
            </div>
          </div>
        )
      )}
    </CardWrapper>
  );
};

export default DeviceStatus;
