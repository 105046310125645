import { Column, COLUMN_NAMES, COLUMN_RESOURCES } from "../../shared";

export const DRIVERS_TABLE_COLUMNS: Column[] = [
  {
    name: COLUMN_NAMES.Driver,
    isSticky: true,
    resource: COLUMN_RESOURCES.CardName,
    sortResource: COLUMN_RESOURCES.CardName,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.CardNumber,
    isSticky: false,
    resource: COLUMN_RESOURCES.CardNumber,
    isDefaultActive: true,
    isDisabled: true,
  },
  {
    name: COLUMN_NAMES.LastActivity,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastActivity,
    sortResource: COLUMN_RESOURCES.LastActivity,
    isDefaultActive: true,
  },
  {
    name: COLUMN_NAMES.LastConnection,
    isSticky: false,
    resource: COLUMN_RESOURCES.LastConnected,
    sortResource: COLUMN_RESOURCES.LastConnected,
    isDefaultActive: true,
  },
  // should be commented out until the whole logic is rethinked
  // {
  //   name: COLUMN_NAMES.LastDownload,
  //   isSticky: false,
  //   resource: COLUMN_RESOURCES.LastDownload,
  //   sortResource: COLUMN_RESOURCES.LastDownload,
  //   isBadge: true,
  //   isDefaultActive: true,
  // },
  // {
  //   name: COLUMN_NAMES.NextDownload,
  //   isSticky: false,
  //   resource: COLUMN_RESOURCES.NextDownload,
  //   isDefaultActive: true,
  // },
  {
    name: COLUMN_NAMES.Schedules,
    resource: COLUMN_RESOURCES.Schedules,
    sortResource: COLUMN_RESOURCES.Schedules,
  },
  {
    name: COLUMN_NAMES.CreatedBy,
    resource: COLUMN_RESOURCES.CreatedBy,
    sortResource: COLUMN_RESOURCES.CreatedBy,
  },
  {
    name: COLUMN_NAMES.CreatedAt,
    resource: COLUMN_RESOURCES.CreatedAt,
    sortResource: COLUMN_RESOURCES.CreatedAt,
  },
  {
    name: COLUMN_NAMES.UpdatedAt,
    resource: COLUMN_RESOURCES.UpdatedAt,
    sortResource: COLUMN_RESOURCES.UpdatedAt, // BE is not implemented yet
  },
];
