import { useRecoilState } from "recoil";
import { useEffect } from "react";

import {
  tableDetailsSectionState,
  tableName,
} from "../../atoms/atoms";
import { TABLE_NAMES } from "../../shared";
import useRefreshDashboardData from "../../shared/hooks/useRefreshDashboardData";

interface DashboardWrapperProps {
  children: React.ReactNode;
}

const DashboardWrapper = ({ children }: DashboardWrapperProps) => {
  const { finishLoadingIfAllDataIsFetched } = useRefreshDashboardData();

  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setCurrentTableName] = useRecoilState(tableName);

  useEffect(() => {
    finishLoadingIfAllDataIsFetched();
    setIsDetailsOpen(false);
    setCurrentTableName(TABLE_NAMES.Dashboard);
  }, [setIsDetailsOpen, setCurrentTableName, finishLoadingIfAllDataIsFetched]);

  return <>{children}</>;
};

export default DashboardWrapper;
