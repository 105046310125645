export enum DownloadStatusesTypes {
    VehicleDeactivated = "VehicleDeactivated",
    InsufficientCredits = "InsufficientCredits",
    WaitingForScheduleStart = "WaitingForScheduleStart",
    WaitingForDeviceConnection = "WaitingForDeviceConnection",
    ImeiReceived = "ImeiReceived",
    CreditReserved = "CreditReserved",
    StatusStateReceived = "StatusStateReceived",
    DriverInfoReceived = "DriverInfoReceived",
    NoDueSchedules = "NoDueSchedules",
    CompanyCardInUse = "CompanyCardInUse",
    CompanyCardAvailable = "CompanyCardAvailable",
    CompanyCardNotAvailable = "CompanyCardNotAvailable",
    AuthenticationStarted = "AuthenticationStarted",
    AuthenticationFinished = "AuthenticationFinished",
    ReceivedDownloadList = "ReceivedDownloadList",
    StartedDownloadingForSchedule = "StartedDownloadingForSchedule",
    TerminatedConnectionToDevice = "TerminatedConnectionToDevice",
    StartedUploadingVehicleFilesForSchedule = "StartedUploadingVehicleFilesForSchedule",
    StartedDownloadingDriverFilesForSchedule = "StartedDownloadingDriverFilesForSchedule",
    Done = "Done",
  }
  
  export enum DownloadStagesTypes {
    DownloadTrigger = "DownloadTrigger",
    Initialization = "Initialization",
    Authentication = "Authentication",
    Downloading = "Downloading",
  }