/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactNode } from "react";
import MuiDialog, { DialogProps as MuiDialogProps } from "@mui/material/Dialog";

import { useStyles } from "./styles";

import { useMediaQueries } from "../../shared";
import CloseButton from "../../shared/components/buttons/CloseButton";

interface DialogCustomProps {
  children: ReactNode;
  noPadding?: boolean;
  close?: () => void;
}

type CombinedDialogCustomProps = DialogCustomProps & MuiDialogProps;

const DialogCustom = ({
  children,
  noPadding,
  close,
  ...props
}: CombinedDialogCustomProps) => {
  const { toMd } = useMediaQueries();

  const { dialogBox } = useStyles();

  const showPadding = () => {
    if (noPadding) {
      return "0";
    } else {
      return "16px";
    }
  };

  return (
    <MuiDialog
      {...props}
      onClose={close}
      fullScreen={toMd}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: toMd ? "unset" : "684px",
          minWidth: toMd ? "unset" : "448px",
          borderRadius: toMd ? "0" : "12px",
        },
      }}
    >
      {close && (
        <CloseButton
          onClose={close}
          customStyle={{ position: "absolute", top: "4px", right: "4px" }}
        />
      )}

      <div
        css={css([
          {
            padding: noPadding ? "0" : "16px 24px",

            "@media (max-width: 599px)": {
              padding: showPadding(),
            },
          },
          dialogBox,
        ])}
      >
        {children}
      </div>
    </MuiDialog>
  );
};

export default DialogCustom;
