import { CSSObject } from "@emotion/react";
import { ThemeColors } from "../../../../shared";

interface StylesInterface {
  colors?: ThemeColors;
  marginBetweenSections?: boolean;
  toLg?: boolean;
  from1099?: boolean;
}

export const useStyles = ({
  colors,
  marginBetweenSections,
  toLg,
  from1099,
}: StylesInterface) =>
  ({
    creditsContainer: {
      display: "flex",
      flexDirection: toLg ? "column" : "row",
    },
    mainCreditsInfoBlock: { height: from1099 && toLg ? "244px" : "unset" },
    creditsHistoryPaperTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    creditsDiv: {
      marginBottom: "16px",
    },
    reservedDiv: {
      marginTop: "16px",
    },
    dialogContentSectionTitle: {
      marginBottom: "8px",
      alignItems: "center",
    },
    singleAcordionItemBlock: {
      display: "flex",
      marginBottom: "8px",
      alignItems: "center",
      wordBreak: "break-word",
      justifyContent: "space-between",
    },
    itemSeparator: { marginBottom: "16px" },
    creditsGeneralInfoPaper: {
      marginBottom: marginBetweenSections ? "24px" : "0",
      border: `1px solid ${colors?.gray200}`,
    },
    creditsLineChartAbsoluteDiv: {
      position: "absolute",
      width: "100%",
      height: "inherit",
    },
  } as CSSObject);
