import { AxiosError } from "axios";
import { TFunction } from "i18next";

export const generateErrorMessageToDisplay = (
  err: AxiosError<any, any>,
  t: TFunction
) => {
  const response = err?.response;
  const messageFromError = response?.data?.message || err?.message;
  const messageString = !response && t("General##unauthorized");
  const messageToDisplay = messageString || messageFromError;

  return messageToDisplay;
};
