/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect } from "react";
import { useRecoilState } from "recoil";

import ToggleDrawerButton from "./components/ToggleDrawerButton/ToggleDrawerButton";
import Navigation from "../Navigation/Navigation";
import { useStyles } from "../styles";
import { desktopDrawerState } from "../../../atoms/atoms";
import {
  CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
  LOCAL_STORAGE_KEYS,
  OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
  useMediaQueries,
} from "../../../shared";
import { setItemToLocalStorage } from "../../../shared/functions/functions";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";

const DesktopDrawer = () => {
  const { colors } = useContext(ThemeContext);

  const { to1099 } = useMediaQueries();

  const { desktopdrawerAside, desktopdrawerContent } = useStyles();

  const [isDesktopDrawerOpen, setIsDesktopDrawerOpen] = useRecoilState(
    desktopDrawerState
  );

  useEffect(() => {
    to1099 && handleCloseDrawer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to1099]);

  const handleOpenDrawer = () => {
    setIsDesktopDrawerOpen(true);
    setItemToLocalStorage(LOCAL_STORAGE_KEYS.DesktopDrawer, true.toString());
  };

  const handleCloseDrawer = () => {
    setIsDesktopDrawerOpen(false);
    setItemToLocalStorage(LOCAL_STORAGE_KEYS.DesktopDrawer, false.toString());
  };

  return (
    <aside
      id="desktopDrawer"
      data-testid="DesktopDrawer"
      css={css([
        {
          background: colors.gray50,
          borderRight: `1px solid ${colors.gray200}`,
          width: isDesktopDrawerOpen
            ? OPEN_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING
            : CLOSE_DESKTOP_DRAWER_WIDTH_WITHOUT_PADDING,
        },
        desktopdrawerAside,
      ])}
    >
      <div css={css(desktopdrawerContent)}>
        <Navigation />
        {!to1099 && (
          <ToggleDrawerButton
            isdrawerOpen={isDesktopDrawerOpen}
            onOpendrawer={handleOpenDrawer}
            onDrawerClose={handleCloseDrawer}
          />
        )}
      </div>
    </aside>
  );
};

export default DesktopDrawer;
