import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { tableData, tableDetailsSectionState } from "../../atoms/atoms";
import { getDetailsSectionStateFromLocalStorage, ROUTES } from "../../shared";

const FileSynchronization = () => {
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [, setTableData] = useRecoilState(tableData);

  useEffect(() => {
    setIsDetailsOpen(
      getDetailsSectionStateFromLocalStorage(ROUTES.FileSynchronization)
    );
    setTableData([]);
  }, [setIsDetailsOpen, setTableData]);

  return <div>FileSynchronization</div>;
};

export default FileSynchronization;
