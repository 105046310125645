/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

import Contacts from "./Contacts";
import { useStyles } from "../styles";
import {
  backgroundGridStyleOverrides,
  formNamestyleOverrides,
  secondaryTextStyleOverrides,
} from "../constants";

import Logo from "../../../components/Logo/Logo";
import { useMediaQueries } from "../../../shared";
import bgImageMobile from "../../../assets/backgrounds/auth-background-mobile.jpg";
import bgImageDesktop from "../../../assets/backgrounds/auth-background-desktop.jpg";

interface IAuthContainerProps {
  formName: string;
  secondaryText?: string | React.ReactNode;
  children: React.ReactNode;
}

const AuthContainer = ({
  children,
  formName,
  secondaryText,
}: IAuthContainerProps) => {
  const { toLg, fromMd, toMd, fromLg } = useMediaQueries();

  const {
    authGrid,
    itemGrid,
    logo,
    loginContentContainer,
    innerLoginContentContainer,
    loginContentHeaders,
  } = useStyles();

  return (
    <section
      className="remaining-height"
      css={css({ height: "100vh" })}
      data-testid="auth-container"
    >
      <Grid
        container
        flexDirection={toLg ? "column" : "row"}
        css={css(authGrid)}
      >
        <Grid
          item
          container
          flexDirection="column"
          xs={0}
          lg={toLg ? 12 : 5}
          css={css(itemGrid)}
        >
          <div css={css(logo)}>
            <Logo />
          </div>
          <div
            css={css([
              { flex: fromLg || toMd ? "1" : "unset" },
              loginContentContainer,
            ])}
          >
            <div css={css(innerLoginContentContainer)}>
              <div css={css(loginContentHeaders)}>
                <Typography variant="h4" sx={formNamestyleOverrides}>
                  {formName}
                </Typography>
                <Typography variant="body1" sx={secondaryTextStyleOverrides}>
                  {secondaryText}
                </Typography>
              </div>
              <div>{children}</div>
              <Contacts />
            </div>
          </div>
        </Grid>
        {fromMd && (
          <Grid
            item
            xs={0}
            lg={toLg ? 12 : 7}
            sx={backgroundGridStyleOverrides(
              toLg,
              bgImageMobile,
              bgImageDesktop
            )}
          />
        )}
      </Grid>
    </section>
  );
};

export default AuthContainer;
