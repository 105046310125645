import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useEmailValidation = (email: string) => {
    const { t } = useTranslation();
    const [emailError, setEmailError] = useState<string | null>(null);

    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{1,10}$/;
    const emailIsValid = regexEmail.test(email);

    const validateEmail = (e: any) => {
        const inputValue = e.target.value;

        if (inputValue && !regexEmail.test(inputValue)) {
            setEmailError(t("Auth##invalid email format"));
        }
    };

    const clearEmailError = () => setEmailError(null);

    return {
        emailIsValid,
        emailError,
        setEmailError,
        clearEmailError,
        validateEmail
    };
};
