import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  currPage,
  filterQueryParams,
  searchQueryParams,
  tableColumns,
  tableData,
  tableDetailsResource,
  tableDetailsSectionState,
  tableInlineActions,
  tableName,
  tableRowsPerPage,
  tableSortedColumn,
} from "../../atoms/atoms";
import { SearchQueryParamsStateType } from "../../atoms/types";
import {
  pageGlobalStateObjectResult,
  getPageGlobalStateObjectValue,
} from "../../components/Table/functions";
import {
  getDetailsSectionStateFromLocalStorage,
  getSearchQueryParamsFromLocalStorage,
  isStoredPageParams,
  setPageParamsToLocalStorage,
  TableRowData,
  TABLE_NAMES,
  useMediaQueries,
  renderColumnsWithIsActive,
  getFiltersFromLocalStorage,
  getSortedColumnFromLocalStorage,
  getDefaultSortedColumn,
} from "../../shared";
import useRefreshDashboardData from "../../shared/hooks/useRefreshDashboardData";

interface IPageWrapperNoDetailsProps {
  children: ReactNode;
  currTableColumns: any;
  currTableName: TABLE_NAMES;
  inlineActions?: {
    title: string;
    icon: any;
    clickAction: (data: any) => void;
  }[];
}

const PageWrapperNoDetails = ({
  children,
  currTableColumns,
  currTableName,
  inlineActions,
}: IPageWrapperNoDetailsProps) => {
  const { resetDashboardDataOnPageChange } = useRefreshDashboardData();
  const { toMd } = useMediaQueries();
  const location = useLocation();

  const [, setTableColumns] = useRecoilState(tableColumns);
  const [, setTableData] = useRecoilState<TableRowData[]>(tableData);
  const [currentTableName, setCurrentTableName] = useRecoilState(tableName);
  const [pageToShow, setPageToShow] = useRecoilState(currPage);
  const [rowsPerPage, setRowsPerPage] = useRecoilState(tableRowsPerPage);
  const [, setResource] = useRecoilState(tableDetailsResource);
  const [, setIsDetailsOpen] = useRecoilState(tableDetailsSectionState);
  const [searchParams, setSearchParams] = useRecoilState(searchQueryParams);
  const [filterParams, setFilterParams] = useRecoilState(filterQueryParams);
  const [, setInlineActions] = useRecoilState(tableInlineActions);
  const [sortedColumn, setSortedColumn] = useRecoilState<any>(
    tableSortedColumn
  );

  useEffect(() => {
    resetDashboardDataOnPageChange();
  }, [resetDashboardDataOnPageChange]);

  useEffect(() => {
    setIsDetailsOpen(
      toMd ? false : getDetailsSectionStateFromLocalStorage(location.pathname)
    );
    inlineActions && setInlineActions(inlineActions);
    setFilterParams((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,
        getFiltersFromLocalStorage(location.pathname)
      )
    );

    setSortedColumn((prev: any) =>
      getPageGlobalStateObjectValue(
        location.pathname,
        prev,

        getDefaultSortedColumn(
          location.pathname,
          getSortedColumnFromLocalStorage(location.pathname)
        )
      )
    );

    if (getSearchQueryParamsFromLocalStorage(location.pathname)?.length >= 3) {
      setSearchParams((prev: SearchQueryParamsStateType) =>
        getPageGlobalStateObjectValue(
          location.pathname,
          prev,
          getSearchQueryParamsFromLocalStorage(location.pathname)
        )
      );
    } else {
      setSearchParams((prev: SearchQueryParamsStateType) =>
        getPageGlobalStateObjectValue(location.pathname, prev, null)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setSearchParams,
    toMd,
    location.pathname,
    setInlineActions,
    setIsDetailsOpen,
  ]);

  useEffect(() => {
    setResource(location.pathname);
    if (!isStoredPageParams(location.pathname)) {
      setPageParamsToLocalStorage({
        pathname: location.pathname,
        currentPage: "1",
        rowsPerPage: "25",
        selectedTableItem: null,
        searchQueryParams: null,
        desktopDetailsSection: undefined,
        filters: null,
        sortedColumn: "",
      });
      setRowsPerPage((prev: any) =>
        getPageGlobalStateObjectValue(location.pathname, prev, "25")
      );

      setPageToShow((prev: any) =>
        getPageGlobalStateObjectValue(location.pathname, prev, "1")
      );
    }

    const columnsWithActive = renderColumnsWithIsActive(
      currTableName,
      currTableColumns
    );

    setTableColumns(columnsWithActive);

    setCurrentTableName(currTableName);
  }, [
    currTableColumns,
    currTableName,
    location.pathname,
    searchParams,
    currentTableName,
    setPageToShow,
    setRowsPerPage,
    setTableColumns,
    setCurrentTableName,
    setTableData,
    setResource,
  ]);

  useEffect(() => {
    if (pageGlobalStateObjectResult(location.pathname, pageToShow)) {
      // the setPageParamsToLocalStorage function is called on any of the global state change
      // (pathname, pageToShow, rowsPerPage, selected item, search, detailssection )
      setPageParamsToLocalStorage({
        pathname: location.pathname,
        currentPage: pageGlobalStateObjectResult(location.pathname, pageToShow),
        rowsPerPage: pageGlobalStateObjectResult(
          location.pathname,
          rowsPerPage
        ),
        selectedTableItem: null,
        searchQueryParams: pageGlobalStateObjectResult(
          location.pathname,
          searchParams
        ),
        desktopDetailsSection: false,
        filters: pageGlobalStateObjectResult(location.pathname, filterParams),
        sortedColumn: getDefaultSortedColumn(
          location.pathname,
          pageGlobalStateObjectResult(location.pathname, sortedColumn)
        ),
      });
    }
  }, [
    filterParams,
    location.pathname,
    pageToShow,
    sortedColumn,
    rowsPerPage,
    searchParams,
  ]);

  return <>{children}</>;
};

export default PageWrapperNoDetails;
