/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";

import { useStyles } from "../styles/styles";
import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../../BannerMessage/BannerMessage";
import InputField from "../../../../../../InputField/InputField";
import CopyButton from "../../../../../../CopyButton/CopyButton";
import { useOverflow } from "../../../../../../../shared/hooks/useOverflow";

interface ApiCredentialsProps {
  apiCredentialData: any;
}

const ApiCredentials = ({ apiCredentialData }: ApiCredentialsProps) => {
  const { t } = useTranslation();
  const { measuredRef, isOverflowing } = useOverflow([]);

  const { fieldAndButtonCSS, copyButtonCSS } = useStyles();

  const { apiKey, apiSecret } = apiCredentialData || {};

  return (
    <div>
      <BannerMessage
        status={BANNER_MESSAGE_STATUSES.Success}
        title={t("Dialog##api management##success banner")}
      />

      <div css={css([fieldAndButtonCSS, { margin: "16px 0" }])}>
        <InputField
          size="small"
          data-testid="api-management-api-key"
          fullWidth
          value={apiKey}
          readOnly
          labelLeft={t("Dialog##api management##api key")}
        />
        <CopyButton
          size="small"
          copiedValue={apiKey}
          buttonCustomStyle={copyButtonCSS as CSSProperties}
        />
      </div>

      <div css={css(fieldAndButtonCSS)}>
        <InputField
          size="small"
          data-testid="api-management-api-secret"
          fullWidth
          value={apiSecret}
          readOnly
          labelLeft={t("Dialog##api management##api secret")}
          textRef={measuredRef}
          customInputStyle={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          tooltipText={isOverflowing ? apiSecret : undefined}
        />

        <CopyButton
          size="small"
          copiedValue={apiSecret}
          buttonCustomStyle={copyButtonCSS as CSSProperties}
        />
      </div>
    </div>
  );
};

export default ApiCredentials;
