import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

import AccordionDetailsCard from "../AccordionDetailsCard/AccordionDetailsCard";

import { DetailsDto, DETAILS_TITLES } from "../../../../types";

import BannerMessage, {
  BANNER_MESSAGE_STATUSES,
} from "../../../../../BannerMessage/BannerMessage";
import Separator from "../../../../../Separator/Separator";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import { SCHEDULES_LIMITS } from "../../../../../../Pages/Vehicles/types";

interface NoCardsProps {
  detailsData: DetailsDto;
  quickAction?: ReactNode;
  status: BANNER_MESSAGE_STATUSES;
  messageText: string;
  detailsTitle?: DETAILS_TITLES;
  maxCount?: number;
  currCount?: number;
  testId?: string;
}

const NoCards = ({
  detailsData,
  quickAction,
  status,
  messageText,
  detailsTitle,
  maxCount,
  currCount,
  testId,
}: NoCardsProps) => {
  const { colors } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <AccordionDetailsCard
      cardTitle={t(`Details##${detailsTitle || DETAILS_TITLES.Schedules}`)}
      maxCount={maxCount || SCHEDULES_LIMITS.MaxScheduleCount}
      currentCount={currCount || detailsData.schedulesCount}
      quickTitleAction={quickAction}
    >
      <Separator color={colors.gray200} />
      <BannerMessage
        data-testid={testId ? testId : "no-cards-component-banner"}
        status={status}
        title={t(`Details##${messageText}`)}
        externalCustomStyle={{ margin: "16px" }}
      />
    </AccordionDetailsCard>
  );
};

export default NoCards;
