import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";

import useGetInitialData from "../../../hooks/useGetInitialData";

import { creditsState } from "../../../../../atoms/atoms";
import { ApiResources } from "../../../../../api/resources";

const useCredits = () => {
  const { getInitialData } = useGetInitialData();

  const [creditsData, setCreditsData] = useRecoilState(creditsState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getInitialData({
        setIsLoading,
        setDataState: setCreditsData,
        resource: ApiResources.DashboardCredits,
        initialized,
      });
    }
  }, [getInitialData, setCreditsData]);

  return { creditsData, isLoading };
};

export default useCredits;
