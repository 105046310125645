import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import useCompaniesCardsFormInputs from "../../../hooks/useCompaniesCardsFormInputs";
import { CompanyCardDto, DetailsDataType } from "../../../../types";
import InputField from "../../../../../InputField/InputField";
import InfoIconWithTooltip from "../../../../../IconsWithTooltips/InfoIconWithTooltip/InfoIconWithTooltip";
import { EditCompanyCardPayloadType } from "../../../../../../Pages/Companies/types";
import { inputFieldMarginBottom } from "../../../../../../shared";

interface EditCardDialogContentProps {
  cardData: CompanyCardDto;
  companyData: DetailsDataType;
  setPayload: Dispatch<SetStateAction<EditCompanyCardPayloadType | null>>;
  setIsReadyToConfirm: Dispatch<SetStateAction<boolean>>;
}

const EditCardDialogContent = ({
  cardData,
  companyData,
  setPayload,
  setIsReadyToConfirm,
}: EditCardDialogContentProps) => {
  const { t } = useTranslation();

  const [cardName, setCardName] = useState<string>(cardData.name);
  const [cardId, setCardId] = useState<string | null>(cardData.companyCardId);
  const [cardNameInputError, setCardNameInputError] = useState<string | null>(
    null
  );
  const [cardIdInputError, setCardIdInputError] = useState<string | null>(null);

  const {
    isCardNameValid,
    validateCardNameLength,
    handleCardNameChangeValue,
    isCardIdValid,
    validateCardId,
    handleCardIdChangeValue,
  } = useCompaniesCardsFormInputs({
    setCardNameInputError,
    setCardName,
    setCardIdInputError,
    setCardId,
  });

  const isPayloadReady =
    Boolean(cardName) &&
    Boolean(cardId) &&
    Boolean(isCardIdValid(cardId)) &&
    Boolean(isCardNameValid(cardName));

  useEffect(() => {
    cardData &&
      cardId &&
      setPayload({
        id: cardData.id,
        name: cardName.trim(),
        companyCardId: cardId.trim(),
      });

    setIsReadyToConfirm(isPayloadReady);
  }, [
    cardData,
    cardId,
    cardName,
    isPayloadReady,
    setIsReadyToConfirm,
    setPayload,
  ]);

  return (
    <>
      <InputField
        id={uuidv4()}
        size="medium"
        data-testid="edit-card-company-name"
        fullWidth
        readOnly
        labelLeft={t("Dialog##company")}
        placeholder={companyData?.name}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="edit-card-name-input-field"
        value={cardName}
        size="medium"
        fullWidth
        required
        errorText={isCardNameValid(cardName) ? "" : cardNameInputError}
        onBlur={validateCardNameLength}
        onChange={handleCardNameChangeValue}
        labelLeft={t("Dialog##card name")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
      />
      <InputField
        id={uuidv4()}
        testId="edit-card-id-input-field"
        value={cardId}
        size="medium"
        fullWidth
        required
        errorText={isCardIdValid(cardId) ? "" : cardIdInputError}
        onBlur={validateCardId}
        onChange={handleCardIdChangeValue}
        labelLeft={t("Dialog##company card id")}
        placeholder={t("Dialog##enter here")}
        customStyle={inputFieldMarginBottom}
        labelAddition={
          <InfoIconWithTooltip title={t(`Dialog##enter company card id`)} />
        }
      />
    </>
  );
};

export default EditCardDialogContent;
