import { useTranslation } from "react-i18next";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import Stepper from "./Stepper";
import { BUTTON_ACTIONS } from "./types/enums";

interface IPaginationProps {
  page: number;
  total: number;
  perPage: number;
  onChange: (newPage: number) => void;
  isInitialLoading?: boolean;
}

const Pagination = ({
  page,
  total,
  perPage,
  onChange,
  isInitialLoading,
}: IPaginationProps) => {
  const { t } = useTranslation();

  const totalPages = Math.ceil(total / perPage) || 0;

  const handleCustomNumber = (input: number) => {
    onChange(input);
  };

  const handleClicks = (buttonName: BUTTON_ACTIONS) => {
    switch (buttonName) {
      case BUTTON_ACTIONS.PreviousPage:
        onChange(page - 1);
        break;

      case BUTTON_ACTIONS.NextPage:
        onChange(page + 1);
        break;

      case BUTTON_ACTIONS.LastPage:
        onChange(totalPages);
        break;

      default:
        onChange(1);
        break;
    }
  };

  return (
    <Stepper
      iconFirst={<FirstPageIcon />}
      iconLast={<LastPageIcon />}
      iconLeft={<ChevronLeftRoundedIcon />}
      iconRight={<ChevronRightRoundedIcon />}
      value={page}
      minValue={1}
      maxValue={totalPages}
      tooltipLeft={t("Button##previous page")}
      tooltipRight={t("Button##next page")}
      tooltipFirst={t("Button##first page")}
      tooltipLast={t("Button##last page")}
      buttonClick={(buttonName) => handleClicks(buttonName)}
      customValue={(data) => handleCustomNumber(data)}
    />
  );
};

export default Pagination;
