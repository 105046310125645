import { useContext, ReactNode } from "react";
import MuiMenu, { MenuProps as MuiMenuProps } from "@mui/material/Menu";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

interface MenuProps {
  children: ReactNode;
}

const Menu = ({ children, ...props }: MenuProps & MuiMenuProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <MuiMenu
      {...props}
      sx={{
        "& .MuiMenu-paper": {
          background: colors.white,
          border: `1px solid ${colors.gray200}`,
          borderRadius: "6px",
          boxShadow: "0px 2px 8px rgba(15, 23, 42, 0.16)",
        },

        "& .MuiMenu-list": {
          padding: "0",
        },
      }}
    >
      {children}
    </MuiMenu>
  );
};

export default Menu;
