/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { Typography } from "@mui/material";

import { FrameProps } from "./types";
import { useStyles } from "./styles";

import BannerMessage from "../BannerMessage/BannerMessage";
import Tooltip from "../Tooltip/Tooltip";
import Button from "../Button/Button";
import DropzoneContext from "../../context/dropzone/dropzoneContext";
import { FILE_COUNT } from "../../shared";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const Frame = ({
  isDragged,
  supportedFormats,
  getRootProps,
  getInputProps,
  isMultiple,
  isDisabled,
  tooltipText,
  isUploaded,
}: FrameProps) => {
  const { messages } = useContext(DropzoneContext);
  const {
    colors: { blue700, blue100, gray50, gray200, gray700, textDarkDisabled },
  } = useContext(ThemeContext);

  const { t } = useTranslation();

  const onDragEnterRef = useRef(null);
  const onDragEnterLeaveRef = useRef(null);

  const bgColor = isDragged ? blue100 : gray50;
  const bgColorHover = isDisabled ? gray50 : blue100;
  const borderColor = isDragged ? blue700 : gray200;
  const borderColorHover = isDisabled ? gray200 : blue700;
  const textColor = isDisabled ? textDarkDisabled : gray700;

  const { frameContentCSS } = useStyles(
    bgColor,
    borderColor,
    isDisabled,
    bgColorHover,
    borderColorHover
  );

  const multipleCheck = isMultiple ? FILE_COUNT.Files : FILE_COUNT.File;

  const getFileSelection = () => {
    if (supportedFormats.length === 0) {
      return <div>{t("Dropzone##select file type")}</div>;
    } else {
      return (
        <Fragment>
          <div>{t(`Dropzone##or drop ${multipleCheck} to upload`)}</div>

          <div>
            {t("Dropzone##supported formats")} {supportedFormats.join(", ")}
          </div>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <Typography variant="body2" sx={{ color: gray700, marginBottom: "4px" }}>
        {!isUploaded ? t("Dropzone##add file") : t("Dialog##selected files")}:
      </Typography>

      {messages?.map(({ status, title, description }) => (
        <BannerMessage
          key={title}
          status={status}
          title={title}
          description={description}
          css={css({
            marginBottom: "4px",
          })}
        />
      ))}

      {!isUploaded ? (
        <Tooltip placement="top" size="medium" title={tooltipText ?? ""}>
          <div {...getRootProps()} css={css(frameContentCSS)}>
            <CSSTransition
              in={isDragged}
              unmountOnExit
              timeout={{ enter: 300 }}
              nodeRef={onDragEnterRef}
              classNames="on-drag-enter"
            >
              <Typography
                variant="body2"
                sx={{ color: blue700 }}
                ref={onDragEnterRef}
              >
                {t(`Dropzone##drop ${multipleCheck} to upload`)}
              </Typography>
            </CSSTransition>

            <CSSTransition
              in={!isDragged}
              unmountOnExit
              timeout={{ enter: 300 }}
              classNames="on-drag-leave"
              nodeRef={onDragEnterLeaveRef}
            >
              <div ref={onDragEnterLeaveRef}>
                <Button
                  data-testid="select-file-button"
                  color="primarySubtle"
                  size="small"
                  variant="textOnly"
                  disabled={isDisabled}
                  css={css({
                    marginBottom: "4px",
                  })}
                >
                  {t(`Dropzone##select ${multipleCheck}`)}
                </Button>

                <Typography
                  component={"div"}
                  variant="body2"
                  sx={{
                    color: textColor,

                    "& > div:last-of-type": {
                      fontWeight: 400,
                    },
                  }}
                >
                  {getFileSelection()}
                </Typography>
              </div>
            </CSSTransition>

            <input {...getInputProps()} />
          </div>
        </Tooltip>
      ) : (
        <div></div>
      )}
    </Fragment>
  );
};

export default Frame;
