import { useContext, Children, cloneElement } from "react";
import MuiTabs from "@mui/material/Tabs";

import { CombinedTabsProps, TAB_SIZES } from "./types";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const Tabs = ({ children, size, ...props }: CombinedTabsProps) => {
  const { colors } = useContext(ThemeContext);

  const isSmall = size === TAB_SIZES.Small;

  return (
    <MuiTabs
      {...props}
      sx={{
        minHeight: "unset",

        "& .MuiTabs-scrollButtons": {
          color: colors.gray700,
        },

        "& .MuiTabs-scrollButtons.Mui-disabled": {
          opacity: "1",
        },
      }}
    >
      {Children.map(children, (child) =>
        cloneElement(child, {
          sx: {
            color: colors.textPlaceholder,
            lineHeight: "20px",
            fontWeight: "600",
            borderRadius: "9px 9px 0 0",
            background: colors.white,
            textTransform: "none",
            minWidth: "unset",
            minHeight: "unset",
            padding: isSmall ? "8px 16px" : "12px 16px",
            fontSize: isSmall ? "14px" : "16px",
            letterSpacing: isSmall ? "0.1px" : "0.15px",
            boxShadow: `inset 0px -2px 0px ${colors.gray100}`,
            transition: `background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,

            "&.Mui-selected": {
              color: colors.textDark,
            },

            "&:hover": {
              background: colors.gray50,
              boxShadow: `inset 0px -2px 0px ${colors.gray300}`,
            },
          },
        })
      )}
    </MuiTabs>
  );
};

export default Tabs;
