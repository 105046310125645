import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Typography } from "@mui/material";
import { useContext } from "react";

import { ThemeContext } from "../../../../context/theme/ThemeContextProvider";

interface IValidationRuleItemProps {
  isRuleMet: boolean;
  ruleText: string;
}

const ValidationRuleItem = ({
  isRuleMet,
  ruleText,
}: IValidationRuleItemProps) => {
  const {
    colors: { green600, textDark, textDarkDisabled },
  } = useContext(ThemeContext);

  return (
    <div>
      <CheckCircleRoundedIcon
        sx={{
          fontSize: "16px",
          color: isRuleMet ? green600 : textDarkDisabled,
        }}
      />
      <Typography variant="body2" sx={{ color: textDark, marginLeft: "8px" }}>
        {ruleText}
      </Typography>
    </div>
  );
};

export default ValidationRuleItem;
