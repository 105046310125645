import { COLORS } from "../../shared";

export const formNamestyleOverrides = {
  lineHeight: "44px",
  fontWeight: 700,
  letterSpacing: "0.25px",
  whiteSpace: "pre-line",
};

export const secondaryTextStyleOverrides = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "8px",
};

export const backgroundGridStyleOverrides = (
  toLg: boolean,
  bgImageMobile: string,
  bgImageDesktop: string
) => {
  return {
    backgroundImage: `url(${toLg ? bgImageMobile : bgImageDesktop})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: toLg ? "50% 55%" : "right",
    backgroundColor: COLORS.Blue100,

    "@media (max-width: 1439px)": {
      flexGrow: "1",
    },
  };
};

export const loginFailedMessage =
  "The email address or password you entered is incorrect.";
