/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState, SetStateAction } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import DetailsCard from "../DetailsCard";
import DetailsCardItemText from "../DetailsCardItemText";
import DetailsCardItemTitle from "../DetailsCardItemTitle";
import OpenFilesButton from "../OpenFilesButton/OpenFilesButton";
import DownloadStatusButton from "../DownloadStatusButton/DownloadStatusButton";

import { useStyles } from "../../../../styles";

import SharedStatusBadge from "../../../../../SharedStatusBadge/SharedStatusBadge";

import {
  ACTIVITY_STATUS,
  IGeneralDetailsProps,
  MenuAnchorEl,
} from "../../../../../../shared";
import MoreActionsButton from "../../../../../../shared/components/buttons/MoreActionsButton";
import { tableName } from "../../../../../../atoms/atoms";
import { getEnvironment } from "../../../../../../shared/functions/functions";

const VehicleGeneralDetails = ({ detailsData }: IGeneralDetailsProps) => {
  const { t } = useTranslation();

  const resourceName = useRecoilValue(tableName);

  const [isActionsOpen, setIsActionsOpen] = useState<MenuAnchorEl>(null);

  const { isProductionEnv } = getEnvironment();

  const { paperCardItem, paperCardItemFixedHeight } = useStyles();

  const handleOpenActionsMenu = (e: {
    currentTarget: SetStateAction<MenuAnchorEl>;
  }) => {
    setIsActionsOpen && setIsActionsOpen(e.currentTarget);
  };

  const handleCloseActionsMenu = () => {
    setIsActionsOpen && setIsActionsOpen(null);
  };

  const renderMoreActions = () => (
    <MoreActionsButton
      onMenuOpen={handleOpenActionsMenu}
      onCloseMenu={handleCloseActionsMenu}
      anchorEl={isActionsOpen}
    >
      <OpenFilesButton
        detailsData={detailsData}
        resourceName={resourceName}
        menuChoice
      />
      <DownloadStatusButton
        onActionsClose={handleCloseActionsMenu}
        detailsData={detailsData}
      />
    </MoreActionsButton>
  );

  return (
    <DetailsCard
      cardTitle={t("Details##general")}
      detailsData={detailsData}
      // Do not apply changes in prod until design issues are resolved
      moreActions={!isProductionEnv && renderMoreActions()}
    >
      <div css={css(paperCardItem)}>
        <DetailsCardItemTitle title={t("Details##company")} />
        <DetailsCardItemText
          textToRender={detailsData?.company.name as string}
        />
      </div>

      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##imei")} />
        <DetailsCardItemText
          textToRender={detailsData?.imei as string}
          hasCopyButton
          copiedValue={detailsData?.imei as string}
        />
      </div>

      <div css={css([paperCardItem, paperCardItemFixedHeight])}>
        <DetailsCardItemTitle title={t("Details##device status")} />
        <SharedStatusBadge status={detailsData?.status as ACTIVITY_STATUS} />
      </div>
    </DetailsCard>
  );
};

export default VehicleGeneralDetails;
