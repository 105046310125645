import { CSSObject } from "@emotion/react";
import { COLORS } from "../../../shared";

const generateGap = (fromMd: boolean, fromLg: boolean): string => {
  if (fromLg) {
    return "24px";
  }
  if (fromMd) {
    return "16px";
  }
  return "8px";
};

const generateMediaQueryStylesFromLg = (
  fromLg: boolean,
  fromLgStyle: string,
  otherStyle: string
) => (fromLg ? fromLgStyle : otherStyle);

export const useStyles = (fromMd: boolean, fromLg: boolean) =>
  ({
    cookiesBannerContainer: {
      display: "flex",
      flexDirection: fromLg ? "row" : "column",
      justifyContent: "center",
      position: "fixed",
      bottom: "0",
      backgroundColor: COLORS.White,
      borderTop: `1px solid ${COLORS.Gray200}`,
      boxShadow: "0px 6px 24px rgba(15, 23, 42, 0.16)",
      zIndex: "1201",
      alignItems: "center",
      left: 0,
      right: 0,
      gap: generateGap(fromMd, fromLg),
      padding: "24px",
    },
    cookiesBannerDescription: {
      justifyContent: "end",

      maxWidth: generateMediaQueryStylesFromLg(fromLg, "566px", "100%"),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
    },
    cookiesBannerButtonsCSS: {
      display: "flex",
      flex: 1,
      flexDirection: fromMd ? "row" : "column-reverse",
      alignItems: "flex-start",
      gap: fromMd ? "16px" : "8px",
      maxWidth: generateMediaQueryStylesFromLg(fromLg, "566px", "unset"),
      width: "100%",
    },
  } as CSSObject);
