import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    mainSpan: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    iconCSS: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "8px",

      "& > svg": {
        fontSize: "8px",
        transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1)",
      },
    },
  } as CSSObject);
