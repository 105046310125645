/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SetterOrUpdater } from "recoil";

import { useStyles } from "../styles";
import Navigation from "../Navigation/Navigation";

import Logo from "../../Logo/Logo";
import Notifications from "../../Navbar/components/Notifications/Notifications";
import Controls from "../../Navbar/components/Controls";
import useTutorialMode from "../../TutorialMode/hooks/useTutorialMode";

import CloseButton from "../../../shared/components/buttons/CloseButton";
import { useStyles as sharedStyles } from "../../../shared/styles/sharedStyles";
import { navigateHome } from "../../../shared/functions/functions";
import GoBackButton from "../../../shared/components/buttons/GoBackButton";
import SettingsButton from "../../../shared/components/buttons/SettingsButton";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useReleaseFeatureToggle } from "../../../shared/featureToggles/hooks";
import { FEATURE_NAMES } from "../../../shared/featureToggles/types";

interface MobileDrawerProps {
  isOpen: boolean;
  setIsMobileDrawerOpen: SetterOrUpdater<boolean>;
}

const MobileDrawer = ({ isOpen, setIsMobileDrawerOpen }: MobileDrawerProps) => {
  const { colors } = useContext(ThemeContext);

  const {
    isTutorialActive,
    isTutorialOpen,
    stepIndex,
    stepGroup,
    setTutorialDetails,
  } = useTutorialMode();
  const { isReleaseFeatureEnabled } = useReleaseFeatureToggle();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { drawerDiv, initialDrawerDiv, navbarControls } = useStyles();
  const { logo } = sharedStyles();

  const [isInitialView, setInitialView] = useState(true);

  useEffect(() => {
    isTutorialOpen && !isOpen && handleDrawerOpenFalse();
    //eslint-disable-next-line
  }, [isTutorialOpen, stepGroup, stepIndex]);

  const handleInitialView = (val: boolean) => setInitialView(val);

  const handleDrawerOpenFalse = () => {
    setIsMobileDrawerOpen(false);
  };

  const handleOnEntered = () =>
    isTutorialActive &&
    setTutorialDetails({
      isTutorialOpen: true,
      stepIndex: stepIndex,
      stepGroup: stepGroup,
      isTutorialActive: true,
    });

  return (
    <Drawer
      data-testid="mobile-drawer"
      anchor="top"
      open={isOpen}
      onClose={handleDrawerOpenFalse}
      SlideProps={{
        onExited: () => handleInitialView(true),
        onEntered: handleOnEntered,
      }}
      sx={{
        "& .MuiDrawer-paper": {
          height: "100%",
        },
      }}
    >
      <div css={css(drawerDiv)}>
        <Logo
          css={css(logo)}
          onClick={() => navigateHome(location, navigate)}
        />
        <CloseButton onClose={handleDrawerOpenFalse} />
      </div>

      {isInitialView ? (
        <div css={css(initialDrawerDiv)}>
          <Typography
            variant="body3semiBold"
            css={css({
              color: colors.textPlaceholder,
              marginBottom: "4px",
              letterSpacing: "0.4px",
            })}
          >
            {t("General##menu")}
          </Typography>
          <Navigation />

          <div css={css(navbarControls)}>
            {isReleaseFeatureEnabled(FEATURE_NAMES.ShowNotifications) && (
              <Notifications />
            )}
            <SettingsButton handleInitialView={handleInitialView} />
          </div>
        </div>
      ) : (
        <>
          <GoBackButton onButtonClick={() => handleInitialView(true)} />
          <Controls handleDrawer={setIsMobileDrawerOpen} />
        </>
      )}
    </Drawer>
  );
};

export default MobileDrawer;
