/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import TextLink from "../../../components/TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../components/TextLink/types";

interface BackToSignInProps {
  handleClick: () => void;
}

const BackToSignIn = ({ handleClick }: BackToSignInProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <span
        css={css({
          marginBottom: "8px",
        })}
      >
        {t("Auth##account verification description")}
      </span>

      <TextLink size={TEXT_LINK_SIZES.Large} onClick={handleClick}>
        {t("Auth##back to sign in screen")}
      </TextLink>
    </Fragment>
  );
};

export default BackToSignIn;
