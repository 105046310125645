/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import TableHeadToolbar from "../../TableHeadToolbar/TableHeadToolbar";
import { useStyles } from "../../../styles/styles";
import TableHeadActions from "../../../actions/TableHeadActions/TableHeadActions";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

const DesktopTableHeadActive = () => {
  const {
    colors: { blue100, blue200 },
  } = useContext(ThemeContext);

  const { desktopTableHeadActive } = useStyles();

  return (
    <div
      data-testid="desktop-table-active-head"
      css={css([
        {
          background: blue100,
          borderTop: `1px solid ${blue200}`,
          borderBottom: `1px solid ${blue200}`,
        },
        desktopTableHeadActive,
      ])}
    >
      <TableHeadToolbar>
        <TableHeadActions />
      </TableHeadToolbar>
    </div>
  );
};

export default DesktopTableHeadActive;
