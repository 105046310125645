import { useTranslation } from "react-i18next";

import { getButtonText } from "../functions";

import { Actions } from "../../../PageHeader/actions/types";
import Tooltip from "../../../Tooltip/Tooltip";

interface ActionButtonProps {
  action: Actions;
  disabledAction: boolean;
  tooltipTitle: string;
  onButtonClick?: (value?: any) => void;
}

const ActionButton = ({
  action,
  disabledAction,
  tooltipTitle,
  onButtonClick,
}: ActionButtonProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      key={action}
      title={disabledAction ? tooltipTitle : ""}
      placement="top"
      hasArrow
      size="medium"
    >
      <div
        onClick={onButtonClick}
        // add classname to change style of button when at least one selected item has child companies
        className={disabledAction ? "action-disabled" : ""}
      >
        {getButtonText(action, t)}
      </div>
    </Tooltip>
  );
};

export default ActionButton;
