/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiTooltip, {
  tooltipClasses,
  TooltipProps as MuiTooltipProps,
} from "@mui/material/Tooltip";

import { TooltipLargeProps } from "./types";
import Button from "../Button/Button";
import { ThemeContext } from "../../context/theme/ThemeContextProvider";

const TooltipLarge = styled(
  ({
    buttonText,
    onButtonClick,
    customMargin,
    className,
    ...props
  }: TooltipLargeProps & MuiTooltipProps) => {
    const content = props.title ? (
      <div>
        <div>{props.title}</div>

        {buttonText && onButtonClick && (
          <Button
            variant="textOnly"
            size="normal"
            color="white"
            fullWidth
            onClick={onButtonClick}
            css={css({
              marginTop: "16px",
            })}
          >
            {buttonText}
          </Button>
        )}
      </div>
    ) : (
      ""
    );

    return (
      <MuiTooltip {...props} title={content} classes={{ popper: className }} />
    );
  }
)(({ customMargin }) => {
  const {
    colors: { gray700, white },
  } = useContext(ThemeContext);

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: gray700,
      color: white,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.1px",
      padding: "16px",
      borderRadius: "12px",
      maxWidth: "311px",
    },
    [`&[data-popper-placement*="top"] .${tooltipClasses.tooltip}, &[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}, &[data-popper-placement*="left"] .${tooltipClasses.tooltip}, &[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
      margin: `${customMargin || "0 0 0 0"} !important`,
    },
  };
});

export default TooltipLarge;
