export enum SETTINGS_CONTENT_NAMES {
  Initial = "initial",
  Language = "language",
  Tutorials = "tutorials",
}

export enum DIALOG_NAMES {
  ApiManagement = "apiManagement",
  CompanyCardAuth = "companyCardAuth",
  GeneralSettings = "generalSettings",
  Password = "password",
  Security = "security",
}
