import { CSSObject } from "@emotion/react";

export const useStyles = (anyErrorsExist: boolean) =>
  ({
    datePickersCSS: {
      display: "flex",
      alignItems: "flex-start",
      gap: "12px",
      height: anyErrorsExist ? "94px" : "78px",
    },
  } as CSSObject);
