/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { TableCell } from "@mui/material";
import { MutableRefObject, ReactNode, useContext, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useRecoilValue } from "recoil";

import CompanyHierarchyTreeLines from "../components/DesktopTable/components/CompanyHierarchyTreeLines/CompanyHierarchyTreeLines";
import { useStyles } from "../styles/styles";
import { detailsAndDrawerLayoutState } from "../types";
import { asDetailsAndDrawerLayoutState } from "../functions";
import { LongTextTooltip } from "../../Tooltip/LongTextTooltip";
import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { CompaniesHierarchyDataDto } from "../../../shared";
import { useIsXOverflow } from "../../../shared/hooks/useIsXOverflow";

import {
  desktopDrawerState,
  tableDetailsSectionState,
} from "../../../atoms/atoms";

const useCompaniesCheckboxFuntions = (
  row: CompaniesHierarchyDataDto,
  companyLevelsList: number[],
  checkedItem: boolean,
  index?: number
) => {
  const { colors } = useContext(ThemeContext);
  const ref: MutableRefObject<null> | null = useRef(null);
  const isOverflowing = useIsXOverflow(ref);

  const isDetailsOpen = useRecoilValue(tableDetailsSectionState);
  const isDesktopDrawerOpen = useRecoilValue(desktopDrawerState);

  const layoutState: detailsAndDrawerLayoutState = asDetailsAndDrawerLayoutState(
    isDetailsOpen,
    isDesktopDrawerOpen
  );

  const { tableCheckboxDiv, companyTitleDivInDesktopCell } = useStyles(
    layoutState
  );

  const boxShadowBottom = `inset 0px -1px 0px ${colors.gray200}`;
  const checkedLeftBorder = `inset 2px 0px 0px ${colors.blue700}`;

  const generateCheckboxBorderLeft = () => {
    return row?.level && row?.level > 0
      ? `1px solid ${colors.gray200} !important`
      : "unset";
  };

  const generateEmptyCellCountOfIndexNumber = (
    boxShadow: boolean,
    indexOfxAxisEmptyDiv: number,
    xAxisEmptyDivsArray: any
  ) => {
    return (
      <div
        key={uuidv4()}
        css={css({
          width: indexOfxAxisEmptyDiv === 0 ? `50px` : `40px`,
          backgroundColor: `${colors.gray50} !important`,
          boxShadow: boxShadow ? boxShadowBottom : "unset",
        })}
      >
        <CompanyHierarchyTreeLines
          indexOfxAxisEmptyDiv={indexOfxAxisEmptyDiv}
          companyLevelsList={companyLevelsList}
          tableRowIndex={index}
          xAxisEmptyDivsArray={xAxisEmptyDivsArray}
        />
      </div>
    );
  };

  const generateRowCheckbox = (children: ReactNode) => (
    <TableCell
      css={css([
        {
          boxShadow: checkedItem
            ? `${checkedLeftBorder}, ${boxShadowBottom}`
            : `${boxShadowBottom}`,
          zIndex: "3",
          position: "sticky",
          borderLeft: generateCheckboxBorderLeft(),
        },
      ])}
    >
      <div css={css(tableCheckboxDiv)}>{children}</div>
    </TableCell>
  );

  const generateIndentedCheckboxDivWithCompanyName = (children: ReactNode) => {
    return (
      <LongTextTooltip title={row?.name} open={isOverflowing}>
        <div
          css={css([
            tableCheckboxDiv,
            {
              padding: "10px 8px",
              boxShadow: checkedItem
                ? `${checkedLeftBorder}, ${boxShadowBottom}`
                : `${boxShadowBottom}`,
              borderLeft: generateCheckboxBorderLeft(),
              alignItems: "center",
              display: "flex",
            },
          ])}
        >
          {children}
          <div
            ref={ref}
            css={css([
              companyTitleDivInDesktopCell,
              {
                paddingLeft: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            ])}
          >
            {row.name}
          </div>
        </div>
      </LongTextTooltip>
    );
  };

  const mapThroughCompaniesLevels = (
    indexOfEmptyXAxisDiv: number,
    arrayOfRowLevels: any
  ) => {
    for (let i = 0; i < companyLevelsList.length; i++) {
      if (i === index) {
        if (companyLevelsList[i] > companyLevelsList[i + 1]) {
          const gapBetweenLevels =
            companyLevelsList[i + 1] - companyLevelsList[i];

          const gapConvertedToPositiveNumber = Math.abs(gapBetweenLevels);

          const lastXAxisEmptyDivWithoutShadow =
            arrayOfRowLevels.length - 1 - gapConvertedToPositiveNumber;

          if (
            (gapBetweenLevels <= -2 &&
              indexOfEmptyXAxisDiv > lastXAxisEmptyDivWithoutShadow) ||
            (gapBetweenLevels === -1 &&
              indexOfEmptyXAxisDiv > lastXAxisEmptyDivWithoutShadow) ||
            (gapBetweenLevels === -1 && companyLevelsList[i + 1] === 1)
          ) {
            return true;
          }
          return false;
        }
        return false;
      }
    }
  };

  return {
    generateEmptyCellCountOfIndexNumber,
    generateRowCheckbox,
    generateIndentedCheckboxDivWithCompanyName,
    mapThroughCompaniesLevels,
  };
};

export default useCompaniesCheckboxFuntions;
