export enum INPUT_FIELD {
  Email = "email",
  Password = "password",
}

export enum PASSWORD_TYPE {
  Current = "current",
  Repeat = "repeat",
  NewPsw = "newPsw",
}

export enum BUTTON_TYPE {
  SignUp = "sign up",
  ResetPsw = "reset password",
  NewPsw = "newPsw",
  ChangePsw = "change password",
}

export enum AUTH_ERRORS {
  InvitationExpired = "Invitation expired",
}
