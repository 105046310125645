const TachographWorkIcon = (props: any) => {
  return (
    <svg
      width={props.width || "24"}
      height={props.height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7427 8.37628C20.8299 8.42405 20.8098 8.55474 20.7122 8.57407L16.6023 9.38819L7.19386 18.7966C6.88209 19.1084 6.19788 18.9296 5.88611 18.6179C5.57434 18.3061 5.38317 17.6094 5.69494 17.2977L15.1033 7.88927L16.0439 3.895C16.0662 3.80001 16.194 3.78268 16.2408 3.86829L17.8383 6.78633L20.7427 8.37628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2512 3.77243C8.31082 3.71031 8.41568 3.741 8.43241 3.82545L9.2638 8.02261L18.6722 17.431C18.984 17.7428 18.8052 18.427 18.4935 18.7388C18.1817 19.0505 17.4851 19.2417 17.1733 18.9299L7.76488 9.52153L3.69085 8.56224C3.60873 8.5429 3.58067 8.44026 3.64154 8.38183L5.99667 6.12134L8.2512 3.77243Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TachographWorkIcon;
