import { DRIVER_INPUT_VALIDATION_STRINGS, INPUT_VALUES_LENGTH } from "..";

import useInputValidation from "./useInputValidation";

const useDriverIdInputValidation = (driverId: string | null) => {
  const {
    inputIsValid,
    inputError,
    clearInputError,
    validateInput,
  } = useInputValidation(
    driverId,
    INPUT_VALUES_LENGTH.DriverId,
    DRIVER_INPUT_VALIDATION_STRINGS.DriverIdInvalid,
    true,
    true,
    DRIVER_INPUT_VALIDATION_STRINGS.DriverIdEmpty
  );

  return { inputIsValid, inputError, clearInputError, validateInput };
};

export default useDriverIdInputValidation;
