import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ApiResources } from "../../../../../api/resources";
import useResponse from "../../../../../shared/hooks/useResponse";
import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import { MenuAnchorEl } from "../../../../../shared";

interface SingleNewProps {
  id: string;
  setIsActionsOpen?: (value: React.SetStateAction<MenuAnchorEl>) => void;
}

const useSingleNew = ({ id, setIsActionsOpen }: SingleNewProps) => {
  const { handleCommonError } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSingleNews = async (setNewsData: any) => {
    try {
      setIsLoading(true);
      const response = await getData(`${ApiResources.NewsItems}/${id}`);

      setNewsData(response.data);
      return;
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseActionsMenu = () => {
    setIsActionsOpen && setIsActionsOpen(null);
  };

  return {
    getSingleNews,
    isLoading,
    handleCloseActionsMenu,
  };
};

export default useSingleNew;
