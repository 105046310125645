import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import useAxiosPrivate from "../../../../../api/hooks/useAxiosPrivate";
import useResponse from "../../../../../shared/hooks/useResponse";
import { ApiResources } from "../../../../../api/resources";
import {
  newsDataState,
  newsScrollOffset,
  tableLoading,
} from "../../../../../atoms/atoms";

const useNews = () => {
  const { handleCommonError } = useResponse();
  const { getData } = useAxiosPrivate();
  const { t } = useTranslation();

  const [, setScrollOffsetNumber] = useRecoilState(newsScrollOffset);
  const [, setNewsData] = useRecoilState(newsDataState);
  const [, setIsTableLoading] = useRecoilState(tableLoading);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const initialized = useRef(false);

  const getNews = useCallback(async () => {
    try {
      const response = await getData(ApiResources.NewsItems);
      setNewsData(response.data);
      setScrollOffsetNumber(0);
    } catch (error) {
      handleCommonError(error, t);
    } finally {
      initialized.current = true;
      setIsLoading(false);
      setIsTableLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData, handleCommonError]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getNews();
    }
  }, [getNews]);

  return { isLoading, getNews, initialized };
};

export default useNews;
