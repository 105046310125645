import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

interface CircleStepperCurrentIconProps extends SvgIconProps {
  strokeColor?: string;
  width?: string;
  height?: string;
  fillColour?: string;
}

const CircleStepperCurrentIcon = ({
  strokeColor,
  width,
  height,
  fillColour,
  ...props
}: CircleStepperCurrentIconProps) => {
  return (
    <SvgIcon
      {...props}
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="white"
    >
      <svg>
        <rect x="1" y="1" width="30" height="30" rx="15" />
        <rect
          x="1"
          y="1"
          width="30"
          height="30"
          rx="15"
          stroke={strokeColor || "#003FB4"}
          strokeWidth="2"
          box-shadow="0 0 0 0 rgba(0,63,108, 0.4)"
        />
        <circle
          id="circle"
          cx="16"
          cy="16"
          r={"5"}
          fill={strokeColor || "#003FB4"}
        />
      </svg>
    </SvgIcon>
  );
};

export default CircleStepperCurrentIcon;
