import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DefaultIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect
                x="2.5"
                y="2.5"
                width="19"
                height="19"
                rx="9.5"
                fill="white"
                stroke="currentColor"
            />
        </SvgIcon>
    );
};

export default DefaultIcon;
