import React from "react";

import MobileDetails from "./components/MobileDetails/MobileDetails";
import DesktopDetails from "./components/DesktopDetails/DesktopDetails";
import DetailsContent from "./components/DetailsContent/DetailsContent";
import { useMediaQueries } from "../../shared";

const DetailsSection = () => {
  const { toMd } = useMediaQueries();

  return toMd ? (
    <MobileDetails>
      <DetailsContent />
    </MobileDetails>
  ) : (
    <DesktopDetails />
  );
};

export default React.memo(DetailsSection);
