import { SetStateAction } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import { useStyles } from "../styles";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../Button/Button";
import useInitialLoading from "../../../shared/hooks/useInitialLoading";
import { MenuAnchorEl, useMediaQueries } from "../../../shared";
import RectangleLoader from "../../../shared/components/loaders/RectangleLoader";
import {
  desktopDrawerState,
  tableDetailsSectionState,
} from "../../../atoms/atoms";

interface FilterButtonProps {
  onOpen: (e: { currentTarget: SetStateAction<MenuAnchorEl> }) => void;
  hasLoadingCustomStyle?: boolean;
  hasMoreFilters?: boolean;
}

const FilterButton = ({
  onOpen,
  hasLoadingCustomStyle,
  hasMoreFilters = false,
}: FilterButtonProps) => {
  const { t } = useTranslation();
  const { isInitialLoading } = useInitialLoading();

  const { toSm, toLg, to1099, fromMd, fromLg } = useMediaQueries();

  const isDesktopDetailsSectionOpen = useRecoilValue(tableDetailsSectionState);
  const isDesktopDrawerOpen = useRecoilValue(desktopDrawerState);

  const { filterButtonLoader } = useStyles({
    toSm,
    toLg,
    hasLoadingCustomStyle,
  });

  const isDetailsAndDrawerOpen = () => {
    return isDesktopDetailsSectionOpen && isDesktopDrawerOpen;
  };

  const buttonWithMoreFilters =
    hasMoreFilters && fromLg && !isDetailsAndDrawerOpen();

  const rectangularWidth = toLg ? 75 : 112;

  return isInitialLoading ? (
    <RectangleLoader
      testId="filter-button-loader"
      width={rectangularWidth}
      height={24}
      customStyle={filterButtonLoader}
    />
  ) : (
    <Button
      data-testid="filter-button"
      color={BUTTON_COLORS.White}
      size={BUTTON_SIZES.Small}
      variant={
        to1099 && fromMd ? BUTTON_VARIANTS.IconOnly : BUTTON_VARIANTS.IconLeft
      }
      icon={<FilterAltOutlinedIcon />}
      onClick={onOpen}
    >
      {buttonWithMoreFilters ? t("Button##more filters") : t("Button##filters")}
    </Button>
  );
};

export default FilterButton;
