import { Children, cloneElement } from "react";
import MuiTabs, { TabsProps as MuiTabsProps } from "@mui/material/Tabs";
import { SxProps, Theme } from "@mui/material";

import SegmentedToggleButtons from "./SegmentedToggleButtons";
import { SEGMENTED_TOGGLE_TAB_SIZES } from "./types";
import { useStyles } from "./styles";

interface SegmentedToggleProps {
  children: React.JSX.Element | React.JSX.Element[];
  size: SEGMENTED_TOGGLE_TAB_SIZES;
  tabWidth?: string;
  fullWidth?: boolean;
}

const SegmentedToggle = ({
  children,
  size,
  tabWidth,
  fullWidth,
  ...props
}: SegmentedToggleProps & Omit<MuiTabsProps, "children">) => {
  const {
    childrenCSS,
    mainTabsCSS,
    scrollButtonLeftMargin,
    scrollButtonRightMargin,
    segmentedToggleButtons,
  } = useStyles(size, tabWidth, fullWidth);

  return (
    <MuiTabs
      {...props}
      variant="scrollable"
      allowScrollButtonsMobile
      ScrollButtonComponent={(buttonProps) =>
        SegmentedToggleButtons(
          scrollButtonLeftMargin,
          scrollButtonRightMargin,
          segmentedToggleButtons,
          buttonProps
        )
      }
      sx={mainTabsCSS as SxProps<Theme> | undefined}
    >
      {Children.map(children, (child) =>
        cloneElement(child, {
          sx: childrenCSS,
        })
      )}
    </MuiTabs>
  );
};

export default SegmentedToggle;
