import { CSSObject } from "@emotion/react";

import { ThemeColors } from "../../shared";

interface UseStylesProps {
  colors?: ThemeColors;
}

export const useStyles = ({ colors }: UseStylesProps) =>
  ({
    paperBox: {
      borderRadius: "8px",
      padding: "16px",
      border: `1px solid ${colors?.gray300}`,
      color: colors?.textSecondary,
    },
    boxTitleArea: {
      marginBottom: "10px",
      color: colors?.textSecondary,
    },
  } as CSSObject);
