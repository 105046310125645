/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useContext } from "react";

import News from "./News/News";
import Credits from "./Credits/Credits";
import VehicleData from "./VehicleData/VehicleData";
import DriverData from "./DriverData/DriverData";

import { useStyles } from "../styles";

import { ThemeContext } from "../../../context/theme/ThemeContextProvider";
import { useMediaQueries } from "../../../shared";
import { USER_PERMISSIONS } from "../../../roles/types/enums";
import { useAuth } from "../../../context/Auth/AuthProvider";
import { getEnvironment } from "../../../shared/functions/functions";

const LeftSide = () => {
  const { colors } = useContext(ThemeContext);
  const { getAccessStatus } = useAuth();
  const { to1099, from1099, fromLg } = useMediaQueries();

  const { isProductionEnv } = getEnvironment();

  const { leftSide, leftSidePapersHolder } = useStyles({
    colors,
    to1099,
    from1099,
    fromLg,
  });

  return (
    <div css={css(leftSide)}>
      <News />
      {getAccessStatus(USER_PERMISSIONS.SeeCreditsDashboard) && <Credits />}
      {!isProductionEnv && (
        <div css={css([leftSidePapersHolder])}>
          <VehicleData />
          <DriverData />
        </div>
      )}
    </div>
  );
};

export default LeftSide;
