import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    paginationLoader: {
      display: "flex",
      alignItems: "center",

      "& > span ": {
        height: "36px",
        borderRadius: "6px",
      },

      "& > span:nth-of-type(3),span:nth-of-type(4),span:nth-of-type(5)": {
        height: "24px",
        borderRadius: "46px",
      },

      "& > span + span": {
        marginLeft: "8px",
      },
    },
  } as CSSObject);
