import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const DisabledCheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <rect x="2" y="2" width="20" height="20" rx="10" fill="#CBD5E1" />
            <circle cx="12" cy="12" r="3.5" fill="#F8FAFC" stroke="#CBD5E1" />
        </SvgIcon>
    );
};

export default DisabledCheckedIcon;
