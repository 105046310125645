import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Tooltip from "../../Tooltip/Tooltip";
import Button from "../../Button/Button";

const RightArrowButton = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="top"
      size="medium"
      title={t("Button##next month")}
      disableTouchListener
    >
      <span>
        <Button
          {...props}
          color="secondary"
          size="tiny"
          variant="iconOnly"
          icon={<ChevronRightRoundedIcon />}
        />
      </span>
    </Tooltip>
  );
};

export default RightArrowButton;
