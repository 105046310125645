import { useTranslation } from "react-i18next";

import InputField from "../../../../../../../../InputField/InputField";
import useTogglePswVisibility from "../../../../../../../../../Pages/Auth/hooks/useTogglePswVisibility";
import {
  INPUT_FIELD,
  PASSWORD_TYPE,
} from "../../../../../../../../../Pages/Auth/types";

export interface SendPasswordProps {
  error: string | null;
  isLoading: boolean;
  onChangePassword: (newValue: string) => void;
}

export const SendPassword = ({
  error,
  isLoading,
  onChangePassword,
}: SendPasswordProps) => {
  const { t } = useTranslation();
  const { PasswordVisibility, showPassword } = useTogglePswVisibility(
    PASSWORD_TYPE.Current
  );

  return (
    <div>
      <InputField
        data-testid="current-password-field"
        fullWidth
        name={INPUT_FIELD.Password}
        labelLeft={t("Password##enter current password")}
        placeholder={t("Password##enter")}
        size="medium"
        type={showPassword.current ? "text" : "password"}
        onChange={(e) => onChangePassword(e.target.value)}
        errorText={error}
        disabled={isLoading}
        iconRight={<PasswordVisibility />}
      />
    </div>
  );
};
