import { Location } from "react-router";

import { DetailsDto } from "./types";
import { ROUTES } from "../../shared";

export const renderTitle = (
  // type of companies details is not known yet
  detailsData: DetailsDto | any,
  location: Location
) => {
  if (detailsData) {
    switch (location.pathname) {
      case ROUTES.Vehicles:
        return detailsData.number;
      case ROUTES.Drivers:
        return detailsData.cardName;
      case ROUTES.Companies:
        return detailsData.name;

      default:
        return "";
    }
  }
};
