import { NewsDto } from "../../types";
import { NEWS_DIALOG_SIZES } from "../../types/enums";
import NewsImageContainer from "../../components/NewsImageContainer";
import NewsDialogTextContent from "../../components/NewsDialogTextContent";
import CloseDialogButton from "../../components/CloseDialogButton";

import Dialog from "../../../../../../components/Dialog/Dialog";
import { ThemeColors, useMediaQueries } from "../../../../../../shared";
import RectangleLoader from "../../../../../../shared/components/loaders/RectangleLoader";

interface SingleNewDialogProps {
  newsData: NewsDto | null;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  colors: ThemeColors;
  title: string;
}

const SingleNewDialog = ({
  newsData,
  isOpen,
  onClose,
  isLoading,
  colors,
  title,
}: SingleNewDialogProps) => {
  const { toMd } = useMediaQueries();

  return (
    <Dialog
      data-testid="single-news-dialog"
      isTitleSeparator
      title={title}
      isActionsSeparator
      actions={<CloseDialogButton onClose={onClose} isLoading={isLoading} />}
      open={isOpen}
      close={onClose}
      width={NEWS_DIALOG_SIZES.width}
      extendToMaxWidth={toMd}
    >
      <NewsImageContainer
        toMd={toMd}
        image={newsData?.imageName || undefined}
        height={318}
        width={636}
        colors={colors}
        isLoading={isLoading}
      />

      {isLoading ? (
        <RectangleLoader
          testId="news-content-loader"
          width={toMd ? "100%" : NEWS_DIALOG_SIZES.dialogImageWidth}
          height={
            toMd
              ? NEWS_DIALOG_SIZES.dialogTextContentLoaderHeightToMd
              : NEWS_DIALOG_SIZES.dialogContentLoaderHeight
          }
          customStyle={{ margin: "25px 0 16px 0" }}
        />
      ) : (
        <NewsDialogTextContent
          colors={colors}
          newsData={newsData}
          toMd={toMd}
        />
      )}
    </Dialog>
  );
};

export default SingleNewDialog;
