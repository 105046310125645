/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { forwardRef, useContext, useState, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import { useStyles } from "./styles";
import { ListChoiceProps, Ref } from "./types";

import Checkbox from "../CheckBox/Checkbox";

import { ThemeContext } from "../../context/theme/ThemeContextProvider";
import { useStyles as sharedStyles } from "../../shared/styles/sharedStyles";

const ListChoice = forwardRef<Ref, ListChoiceProps>(
  (
    {
      title,
      timeStamp,
      description,
      isCheckbox,
      iconLeft,
      iconRight,
      normalWhiteSpace,
      onClick,
      isLoading,
      alignIconCenter,
      ...props
    },
    ref
  ) => {
    const {
      colors: {
        blue200,
        gray100,
        gray200,
        gray700,
        textDark,
        textDarkDisabled,
      },
      colors,
    } = useContext(ThemeContext);

    const [isSelected, setIsSelected] = useState(Boolean(props.selected));

    const { body3regular, body4semibold } = sharedStyles();
    const {
      iconLeftCSS,
      listChoiceContent,
      checkboxDiv,
      downloadProgressArea,
    } = useStyles({
      colors,
      alignIconCenter,
    });

    useEffect(() => {
      setIsSelected(Boolean(props.selected));
    }, [props.selected]);

    const handleSelect = (e?: any) => {
      e?.stopPropagation();
      if (props.selected) {
        setIsSelected(!isSelected);
      }

      onClick && onClick();
    };

    return (
      <MenuItem
        {...props}
        ref={ref}
        selected={props.selected && isSelected}
        onClick={handleSelect}
        disabled={props.disabled || isLoading}
        sx={{
          color: textDark,
          alignItems: "center",
          padding:
            isCheckbox || iconLeft || iconRight ? "12px 16px" : "14px 16px",
          whiteSpace: normalWhiteSpace ? "normal" : "nowrap",
          wordBreak: normalWhiteSpace ? "break-word" : "normal",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",

          "&.Mui-selected": {
            background: isCheckbox ? "unset" : blue200,

            "&:hover": {
              background: isCheckbox ? gray100 : blue200,
            },
          },

          "&.Mui-disabled": {
            color: textDarkDisabled,
            opacity: "1",
          },

          "&:hover": {
            backgroundColor: gray100,
          },

          "&:active": {
            backgroundColor: gray200,
          },
        }}
      >
        {iconLeft && <div css={css(iconLeftCSS)}>{iconLeft}</div>}

        <div css={css(listChoiceContent)}>
          {timeStamp && (
            <Typography
              css={css([
                { color: textDark, marginBottom: "4px" },
                body4semibold,
              ])}
            >
              {timeStamp}
            </Typography>
          )}

          <Typography
            variant="body2"
            css={css({
              color: props.disabled || isLoading ? textDarkDisabled : "inherit",
            })}
          >
            {title}
          </Typography>

          {description && (
            <Typography
              css={css([
                {
                  color:
                    props.disabled || isLoading ? textDarkDisabled : gray700,
                  whiteSpace: "normal",
                },
                body3regular,
              ])}
            >
              {description}
            </Typography>
          )}
        </div>

        {isLoading && (
          <div css={css(downloadProgressArea)}>
            <CircularProgress size={20} />
          </div>
        )}
        <div css={css(checkboxDiv)}>
          {iconRight}

          {isCheckbox && (
            <Checkbox checked={isSelected} disabled={props.disabled} />
          )}
        </div>
      </MenuItem>
    );
  }
);

export default ListChoice;
