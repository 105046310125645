import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M6.545,9.989,12.76,3.58l2.312,2.245L11.124,9.891l.005.005-.008.007,4.062,4.067L12.855,16.3ZM1.057,9.841,7.276,3.435,9.631,5.72,5.49,9.99l.005,0L5.49,10,9.5,14.017,7.368,16.151ZM9.549,0,6.729,2.9l0,.005L.008,9.847,0,9.854l6.835,6.836h0l2.861,2.862,2.671-2.673L15.179,19.7l2.866-2.862L20.814,19.6l2.862-2.866-2.862-2.862L18.488,16.2,12.18,9.891,18.4,3.482l2.357,2.291,2.819-2.907L20.67.049,17.888,2.915,15.036.148,12.448,2.814Z"
        transform="translate(0.324 2.488)"
      />
      <path fill="none" d="M8,6.209,2.043,12.343l5.957,6" />
      <path
        fill="none"
        d="M8.5,6.348,2.581,12.5l6,5.991"
        transform="translate(4.957)"
      />
      <path
        fill="none"
        d="M8.148,6.272,2.213,12.38,8.227,18.4"
        transform="translate(10.957)"
      />
    </SvgIcon>
  );
};

export default LogoIcon;
