import { CSSObject } from "@emotion/react";

export const useStyles = () =>
  ({
    radioListItemLabel: {
      display: "inline-flex",
      alignItems: "space-between",
      padding: "12px 16px",
      whiteSpace: "nowrap",
      wordBreak: "normal",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    },
    radioListItemContentHolder: {
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "space-between",
    },
    radioLoaderDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "24px",
      height: "24px",
      marginRight: "8px",
    },
  } as CSSObject);
