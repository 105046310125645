/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useContext, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import MobileTableItemColumn from "./MobileTableItemColumn";
import TableCheckbox from "../../DesktopTable/components/TableCheckbox";
import { useStyles } from "../../../styles/styles";
import { isRowDisabled } from "../../../functions";
import { mobileTableItemStyle } from "../../../styles/styleFunctions";
import InlineActionIconButton from "../../../../Button/buttonVariants/InlineActionIconButton";
import useTableItemDetails from "../../../../DetailsSection/hooks/useTableItemdetails";
import useTutorialMode from "../../../../TutorialMode/hooks/useTutorialMode";
import { STEP_GROUP } from "../../../../TutorialMode/types";
import {
  rowsEnabledToSelect,
  tableColumns,
  tableInlineActions,
  tableName,
} from "../../../../../atoms/atoms";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";
import { Column, TableRowData } from "../../../../../shared";
import DetailsIcon from "../../../../../assets/customIcons/actions/DetailsIcon";
import { useAuth } from "../../../../../context/Auth/AuthProvider";

interface IMobileTableItemProps {
  row: TableRowData;
  index: number;
  isTableHeadActive: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
  hasDetails: boolean;
}

const MobileTableItem = ({
  row,
  index,
  isTableHeadActive,
  isFirstItem,
  isLastItem,
  hasDetails,
}: IMobileTableItemProps) => {
  const { user } = useAuth();
  const { colors } = useContext(ThemeContext);

  const {
    stepGroup,
    stepIndex,
    checkIfCorrectTutorialStep,
    setTutorialDetails,
  } = useTutorialMode();
  const { t } = useTranslation();
  const { getTableItemDetails } = useTableItemDetails();

  const columns = useRecoilValue<Column[]>(tableColumns);
  const currentTableName = useRecoilValue(tableName);
  const inlineActions = useRecoilValue(tableInlineActions);
  const selectableRows = useRecoilValue(rowsEnabledToSelect);

  const [isInlineBtnLoading, setInlineBtnLoading] = useState(false);

  const {
    activeMobileTableItemCheckbox,
    mobileTableItem,
    mobilTableInlineActionsBlock,
  } = useStyles();

  const handleDetailsClick = useCallback(() => {
    if (checkIfCorrectTutorialStep(STEP_GROUP.TutorialNewCompanyCard, 2)) {
      setTutorialDetails({
        isTutorialOpen: false,
        stepIndex: stepIndex + 1,
        stepGroup: stepGroup,
        isTutorialActive: true,
      });
    }

    getTableItemDetails({ itemId: row.id, rowData: row });
    // eslint-disable-next-line
  }, [getTableItemDetails, row, stepIndex]);

  return (
    <div css={css(mobileTableItemStyle(colors, isFirstItem, isLastItem))}>
      {isTableHeadActive && (
        <div css={css(activeMobileTableItemCheckbox)}>
          <TableCheckbox
            fromTableRow
            fromMobileTableItem
            isRowChecked={false}
            index={index}
            row={row}
            disabled={isRowDisabled(selectableRows, row)}
          />
        </div>
      )}

      <div
        css={css([
          {
            "& > div": {
              borderBottom: `1px solid ${colors.gray200}`,
              padding: "0 24px",
            },
          },
          mobileTableItem,
        ])}
      >
        {columns.map(
          (column: Column) =>
            column.isActive && (
              <MobileTableItemColumn
                key={column.name}
                colors={colors}
                columnData={column}
                row={row}
              />
            )
        )}

        {(inlineActions.length > 0 || hasDetails) && (
          <div css={css(mobilTableInlineActionsBlock)}>
            <div
              css={css({
                display: "flex",
                "button + button": {
                  marginLeft: "16px",
                },
              })}
            >
              {inlineActions.map((action: any) => {
                if (
                  action.renderConditions({ data: row, user }) ||
                  action.renderConditions === undefined
                ) {
                  return (
                    <InlineActionIconButton
                      key={action.title}
                      title={action.title}
                      icon={action.icon}
                      onButtonClick={() =>
                        action.clickAction(row, setInlineBtnLoading)
                      }
                      isLoading={action.hasLoader && isInlineBtnLoading}
                    />
                  );
                }
                return null;
              })}

              {hasDetails && (
                <InlineActionIconButton
                  customId={`mobile_details_${index}_item`}
                  title={t(`Table##show details##${currentTableName}`)}
                  icon={<DetailsIcon />}
                  onButtonClick={handleDetailsClick}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MobileTableItem);
