import { SetterOrUpdater } from "recoil";
import { LOCAL_STORAGE_KEYS, TokenDataDto } from "..";

export const setTokensToLocalStorage = (tokens: TokenDataDto | any) =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.Tokens, JSON.stringify(tokens));

export const getTokensFromLocalStorage = () => {
  const tokens = localStorage.getItem(LOCAL_STORAGE_KEYS.Tokens);
  return JSON.parse(tokens as any);
};

export const isStoredTokens = () =>
  Boolean(localStorage.getItem(LOCAL_STORAGE_KEYS.Tokens));

export const deleteStoredTokens = () =>
  localStorage.removeItem(LOCAL_STORAGE_KEYS.Tokens);

export const updateTokens = (
  setTokenInfo: SetterOrUpdater<any>,
  response: any
) => {
  setTokenInfo({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  });
  setTokensToLocalStorage({
    token: response.data.token,
    refreshToken: response.data.refreshToken,
  });
};
