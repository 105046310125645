import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import {
  InputValuesDto,
  SCHEDULE_PAYLOAD_NAMES,
  ScheduleDataDto,
  TimeValue,
} from "../types";
import { DATE_FORMATS, useDateTime } from "../../../shared";

export const useScheduleDateTimeValues = () => {
  const { formattedDate } = useDateTime();

  const getFormattedTimeInput = (value: string) => {
    if (!value) return null;

    if (value) {
      const [hoursStr, minutesStr] = value.split(":");
      const hours = parseInt(hoursStr) || 0;
      const minutes = parseInt(minutesStr) || 0;

      // Normalize hours and minutes
      const normalizedHours = Math.min(hours, 23);

      const normalizedMinutes =
        value.length <= 4
          ? minutes.toString().padStart(2, "0")
          : Math.min(minutes, 59);

      // Update state only if input length doesn't exceed 5 characters
      if (value.length <= 5) {
        const formattedTime = moment()
          .set({
            hour: normalizedHours,
            minute: Number(normalizedMinutes),
          })
          .format("HH:mm");

        return formattedTime;
      }
    }
  };

  const timeValues: TimeValue[] = (() => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = [0, 30];
    const result: TimeValue[] = [];

    const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);

    hours.forEach((hour) => {
      minutes.forEach((minute) => {
        const label = `${padZero(hour)}:${padZero(minute)}`;
        const value = hour * 60 + minute;
        result.push({ value, label });
      });
    });

    return result;
  })();

  const formatTimeFromInput = (value: string) => {
    const valueWihNumbersOnly = value.replace(/\D/g, "");

    // Format valueWihNumbersOnly to include a colon after the second character if more than two characters are present
    return valueWihNumbersOnly.length > 2
      ? `${valueWihNumbersOnly.slice(0, 2)}:${valueWihNumbersOnly.slice(2)}`
      : valueWihNumbersOnly;
  };

  const getConvertedTimeValue = (
    value: string,
    datePart: string,
    setStartDateTime: Dispatch<SetStateAction<string | null>>,
    handleFormChange: (
      key: SCHEDULE_PAYLOAD_NAMES,
      value: string | null | boolean
    ) => void
  ) => {
    const formattedValue = formatTimeFromInput(value);

    formattedValue.length <= 5 && setStartDateTime(formattedValue);

    if (formattedValue.length === 5 && datePart) {
      const fullDateWithTime = `${datePart} ${formattedValue}`;
      handleFormChange(SCHEDULE_PAYLOAD_NAMES.StartDate, fullDateWithTime);
    }
  };

  const getDateValue = (
    inputValues: InputValuesDto,
    scheduleData: ScheduleDataDto | null | undefined
  ) => {
    if (inputValues[SCHEDULE_PAYLOAD_NAMES.StartDate] !== "") {
      return inputValues[SCHEDULE_PAYLOAD_NAMES.StartDate];
    }

    return scheduleData
      ? formattedDate(
          scheduleData[SCHEDULE_PAYLOAD_NAMES.StartDate],
          DATE_FORMATS.DateHoursMinutes
        )
      : "";
  };

  return {
    timeValues,
    getConvertedTimeValue,
    getDateValue,
    getFormattedTimeInput,
  };
};
