import { useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";

import useGetInitialData from "../../../hooks/useGetInitialData";

import { companyCardsState } from "../../../../../atoms/atoms";
import { ApiResources } from "../../../../../api/resources";

const useCompanyCards = () => {
  const { getInitialData } = useGetInitialData();

  const [companyCards, setCompanyCards] = useRecoilState(companyCardsState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getInitialData({
        setIsLoading,
        setDataState: setCompanyCards,
        resource: ApiResources.DashboardCompanyCards,
        initialized,
      });
    }
  }, [getInitialData, setCompanyCards]);

  return { companyCards, isLoading };
};

export default useCompanyCards;
