export enum SCHEDULE_DATE_TYPE {
  StartDate = "startDate",
  EndDate = "endDate",
}

export enum QUICK_DOWNLOAD_STATUS {
  Deactivated = "deactivated",
  Disabled = "disabled",
  Initiated = "initiated",
  New = "new",
}
