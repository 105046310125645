/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useContext, useRef } from "react";
import TableCell from "@mui/material/TableCell";
import { useLocation } from "react-router-dom";

import useTableFunctions from "../../../hooks/useTableFunctions";
import { LongTextTooltip } from "../../../../Tooltip/LongTextTooltip";
import { ThemeContext } from "../../../../../context/theme/ThemeContextProvider";

import {
  COLUMN_NAMES,
  COLUMN_RESOURCES,
  ROUTES,
  tableColumnRightDropShadow,
} from "../../../../../shared";
import { useIsXOverflow } from "../../../../../shared/hooks/useIsXOverflow";

interface TableColumnProps {
  data: any;
  name: COLUMN_NAMES;
  resource: COLUMN_RESOURCES;
  isCheckable: boolean;
  isSticky?: boolean;
  scrollHorizontal?: boolean;
  secondCellValue?: COLUMN_RESOURCES;
}

const TableColumn = ({
  data,
  name,
  resource,
  isCheckable,
  isSticky,
  scrollHorizontal,
  secondCellValue,
}: TableColumnProps) => {
  const { colors } = useContext(ThemeContext);
  const location = useLocation();

  const { renderValue } = useTableFunctions();

  const ref = useRef(null);
  const isOverflowing = useIsXOverflow(ref);

  const companiesPage = location.pathname === ROUTES.Companies;

  const boxShadowBottom = `inset 0px -1px 0px ${colors.gray200}`;

  const paddingLeft = () => {
    if (isSticky) {
      return "60px";
    }

    return "unset";
  };

  return (
    <>
      <LongTextTooltip
        title={renderValue(name, resource, data)}
        open={isOverflowing}
      >
        <TableCell
          ref={ref}
          css={css([
            {
              position: isSticky ? "sticky" : "unset",
              left: !isCheckable ? "0" : paddingLeft(),
              boxShadow: boxShadowBottom,
              zIndex: isSticky ? 2 : "inherit",
            },
            isSticky && tableColumnRightDropShadow(scrollHorizontal),
          ])}
        >
          {/* companies hierarchy page cells should stay empty since values are rendered together with TableCheckbox*/}

          {!companiesPage
            ? renderValue(name, resource, data, secondCellValue)
            : null}
        </TableCell>
      </LongTextTooltip>
    </>
  );
};

export default TableColumn;
