import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { RadioButtonUnchecked } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import SettingsControlsContentWrapper from "./SettingsControlsContentWrapper";
import { SETTINGS_CONTENT_NAMES } from "../../../../types/enums";
import {
  STEP_GROUP,
  TUTORIAL_NAMES_FROM_USERS,
} from "../../../../../TutorialMode/types";
import useTutorialStartActions from "../../../../../TutorialMode/hooks/useTutorialStartActions";

import { ThemeContext } from "../../../../../../context/theme/ThemeContextProvider";
import SuccessIcon from "../../../../../../assets/customIcons/status/SuccessIcon";
import ListChoice from "../../../../../ListChoice/ListChoice";
import { useAuth } from "../../../../../../context/Auth/AuthProvider";
import { ROUTES } from "../../../../../../shared";

interface TutorialProps {
  isMobile: boolean;
  closeMenu: () => void;
  setSettingsContent: (newContent: SETTINGS_CONTENT_NAMES) => void;
}

const Tutorials = ({
  isMobile,
  closeMenu,
  setSettingsContent,
}: TutorialProps) => {
  const {
    colors: { textDarkDisabled },
  } = useContext(ThemeContext);
  const { user } = useAuth();

  const location = useLocation();
  const { t } = useTranslation();

  const {
    startApplyScheduleTutorial,
    startCompanyCardTutorial,
    startCreateScheduleTutorial,
  } = useTutorialStartActions(
    isMobile,
    location.pathname as ROUTES,
    setSettingsContent,
    closeMenu
  );

  const startTutorial = (stepGroup: STEP_GROUP) => {
    switch (stepGroup) {
      case STEP_GROUP.TutorialNewCompanyCard:
        startCompanyCardTutorial();
        break;

      case STEP_GROUP.TutorialCreateSchedule:
        startCreateScheduleTutorial();
        break;

      case STEP_GROUP.TutorialApplySchedule:
        startApplyScheduleTutorial();
        break;

      default:
        closeMenu();
        break;
    }
  };

  const checkIfTutorialCompleted = (tutorialName: STEP_GROUP) => {
    const convertedNames: { [key: string]: TUTORIAL_NAMES_FROM_USERS } = {
      [STEP_GROUP.TutorialApplySchedule]:
        TUTORIAL_NAMES_FROM_USERS.ApplySchedule,
      [STEP_GROUP.TutorialCreateSchedule]:
        TUTORIAL_NAMES_FROM_USERS.CreateSchedule,
      [STEP_GROUP.TutorialNewCompanyCard]:
        TUTORIAL_NAMES_FROM_USERS.CompanyCards,
    };

    return user?.tutorialStatus[convertedNames[tutorialName]] ? (
      <SuccessIcon />
    ) : (
      <RadioButtonUnchecked sx={{ color: textDarkDisabled }} />
    );
  };

  return (
    <SettingsControlsContentWrapper onGoBackButton={setSettingsContent}>
      <ListChoice
        title={t("Tutorials##add company card##name")}
        iconLeft={checkIfTutorialCompleted(STEP_GROUP.TutorialNewCompanyCard)}
        iconRight={<ChevronRightRoundedIcon />}
        onClick={() => startTutorial(STEP_GROUP.TutorialNewCompanyCard)}
      />
      <ListChoice
        title={t("Tutorials##create schedule##name")}
        iconLeft={checkIfTutorialCompleted(STEP_GROUP.TutorialCreateSchedule)}
        iconRight={<ChevronRightRoundedIcon />}
        onClick={() => startTutorial(STEP_GROUP.TutorialCreateSchedule)}
      />
      <ListChoice
        title={t("Tutorials##apply schedule##name")}
        iconLeft={checkIfTutorialCompleted(STEP_GROUP.TutorialApplySchedule)}
        iconRight={<ChevronRightRoundedIcon />}
        onClick={() => startTutorial(STEP_GROUP.TutorialApplySchedule)}
      />
    </SettingsControlsContentWrapper>
  );
};

export default Tutorials;
