import { Trans } from "react-i18next";
import TextLink from "../../../TextLink/TextLink";
import { TEXT_LINK_SIZES } from "../../../TextLink/types";

export const CookiesMainDescription = () => {
  return (
    <Trans
      data-testid="cookies-main-description"
      i18nKey="Cookies##cookie banner message"
      components={{
        1: (
          <TextLink
            noFlex
            href="https://teltonika-gps.com/about-us/policies-certificates/cookies-policy"
            target="_blank"
            rel="noreferrer"
            underline="none"
            size={TEXT_LINK_SIZES.Normal}
          />
        ),
      }}
    />
  );
};
