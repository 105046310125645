import { CSSObject } from "@emotion/react";
import { COLORS, ThemeColors } from "../../shared";

export const useStyles = (colors?: ThemeColors) =>
  ({
    drawerDiv: {
      padding: "10px 24px 10px 24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    initialDrawerDiv: {
      padding: "10px 24px",
    },
    navbarControls: {
      "& > button": {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "8px",
        padding: "6px",
      },
    },
    listChoice: {
      background: COLORS.White,
      color: COLORS.Blue700,
    },

    navigationItems: {
      "& li + li": {
        marginTop: "8px",
      },
    },
    desktopdrawerAside: {
      position: "fixed",
      top: "56px",
      bottom: "0",
      zIndex: 1200,
      padding: "18px 15px 18px 16px",
      transition: "width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    desktopdrawerContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    navigationItem: {
      display: "flex",
      alignItems: "center",
      padding: "6px",
      borderRadius: "4px",
      overflowX: "hidden",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    toggleDrawerIcon: { height: "24px" },

    additionalNavButton: {
      color: colors?.gray700,
      cursor: "pointer",
      marginBottom: "8px",

      "&:hover": {
        backgroundColor: colors?.gray200,
      },

      "&:active": {
        backgroundColor: colors?.gray200,
      },
    },

    navItemTypography: {
      marginLeft: "8px",
      whiteSpace: "nowrap",
    },
  } as CSSObject);
