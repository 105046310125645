/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Tooltip from "./Tooltip";

interface LongTextTooltipProps {
  children: any;
  title: any;
  open: boolean;
  customStyles?: any;
}

export const LongTextTooltip = ({
  children,
  title,
  open,
  customStyles
}: LongTextTooltipProps) => {
  return (
    <Tooltip
      size="medium"
      placement="top"
      title={title}
      disableHoverListener={!open}
      css={css([{
        "& .MuiTooltip-tooltip": {
          maxWidth: "unset", 
        },
      }, customStyles])}
    >
      {children}
    </Tooltip>
  );
};
