import { useEffect, useRef } from "react";
import Joyride from "react-joyride";
import { useLocation } from "react-router-dom";

import { TutorialTooltip } from "./TutorialTooltip";
import { STEP_GROUP } from "./types";
import { useStyles } from "./styles/styles";
import useTutorialMode from "./hooks/useTutorialMode";
import { ROUTES, useMediaQueries } from "../../shared";

const TutorialMode = () => {
  const {
    currentStepGroup,
    isTutorialOpen,
    stepIndex,
    stepGroup,
    handleTutorialExit,
  } = useTutorialMode();

  const location = useLocation();
  const { fromMd, toSm } = useMediaQueries();
  const { overlayCSS, spotlightCSS } = useStyles();

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    handleTutorialExit(location.pathname as ROUTES);

    //eslint-disable-next-line
  }, [fromMd, toSm]);

  useEffect(() => {
    const closeTutorial = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleTutorialExit();
      }
    };

    if (isTutorialOpen) {
      window.addEventListener("keydown", closeTutorial);
    } //Prevents listener from activating if no tutorial is open

    return () => {
      window.removeEventListener("keydown", closeTutorial);
    };
    //eslint-disable-next-line
  }, [isTutorialOpen]);

  return (
    <Joyride
      floaterProps={{ hideArrow: true }}
      scrollOffset={100}
      styles={{
        tooltip: { mixBlendMode: "normal" },
        options: {
          zIndex: 10000,
        },
        spotlight: spotlightCSS as any,
        overlay: overlayCSS as any,
      }}
      disableScrollParentFix={stepGroup === STEP_GROUP.TutorialApplySchedule}
      disableOverlayClose
      steps={currentStepGroup}
      stepIndex={stepIndex}
      run={isTutorialOpen}
      tooltipComponent={TutorialTooltip}
    />
  );
};

export default TutorialMode;
