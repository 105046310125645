import { Fragment } from "react";

import { DateType, useDateTime } from "../..";

interface DateTimeProps {
  date: DateType;
  format?: string;
}

const DateTime = ({ date, format }: DateTimeProps) => {
  const { formattedDate } = useDateTime();

  return <Fragment>{formattedDate(date, format)}</Fragment>;
};

export default DateTime;
