import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useRecoilValue } from "recoil";

import AddDriverDialogContent from "./AddDriverDialogContent";
import Dialog from "../../../../../Dialog/Dialog";
import { DialogWidth } from "../../../../../Dialog/types/enums";
import useTableItemDetails from "../../../../../DetailsSection/hooks/useTableItemdetails";
import DialogActions from "../../../../../../shared/components/buttons/DialogActions";
import {
  DRIVERS_ACTIONS,
  GeneralDialogProps,
  useMediaQueries,
} from "../../../../../../shared";
import { tableDetailsResource } from "../../../../../../atoms/atoms";
import useResponse from "../../../../../../shared/hooks/useResponse";
import { ALERT_STATUS } from "../../../../../../context/alert/types";
import useAxiosPrivate from "../../../../../../api/hooks/useAxiosPrivate";
import useDriversFormInputs from "../../../../../../shared/hooks/useDriversFormInputs";
import {
  cardHolderNameIsValid,
  descriptionIsValid,
  isDriverPayloadReadyToConfirm,
} from "../../../../../../shared/functions/functions";
import useDriverIdInputValidation from "../../../../../../shared/hooks/useDriverIdInputValidation";

const AddDriverDialog = ({ isOpen, onClose }: GeneralDialogProps) => {
  const { t } = useTranslation();
  const { toMd } = useMediaQueries();
  const { postData } = useAxiosPrivate();
  const { getTableItemDetails } = useTableItemDetails();
  const { handleResponse } = useResponse();

  const resource = useRecoilValue(tableDetailsResource);

  const [cardHolderName, setCardHolderName] = useState<string | null>(null);
  const [cardHolderInputError, setCardHolderInputError] = useState<
    string | null
  >(null);
  const [driverId, setDriverId] = useState<string | null>(null);
  const [descriptionValue, setDescriptionValue] = useState<string | null>("");
  const [descriptionInputError, setDescriptionInputError] = useState<
    string | null
  >(null);

  const {
    inputError,
    inputIsValid,
    clearInputError,
    validateInput,
  } = useDriverIdInputValidation(driverId);

  const {
    validateCardHolderNameLength,
    validateDescriptionLength,
    handleCardHolderNameChange,
    handleChangeDriverIdValue,
    handleChangeDescription,
  } = useDriversFormInputs({
    setCardHolderInputError,
    setDescriptionInputError,
    setCardHolderName,
    setDriverId,
    validateInput,
    clearInputError,
    setDescriptionValue,
  });

  const payload = {
    cardNumber: driverId,
    cardName: cardHolderName?.trim(),
    comment: descriptionValue,
  };

  const resetAllValues = () => {
    setCardHolderName(null);
    setCardHolderInputError(null);
    setDriverId(null);
    clearInputError();
    setDescriptionValue("");
    setDescriptionInputError(null);
  };

  const handleCloseAddDriverDialog = () => {
    onClose(DRIVERS_ACTIONS.AddDriver);
  };

  const handleSubmit = () => {
    const addDriver = async () => {
      try {
        const response = await postData(resource as string, payload);
        const idFromResponseLocation = response?.headers.location;

        handleCloseAddDriverDialog();
        getTableItemDetails({
          itemId: idFromResponseLocation,
          successMessage: t("Alert##add driver success"),
          leaveTableDetailsNotUpdated: true,
        });
      } catch (error) {
        const err = error as AxiosError<any>;
        if (err) {
          const responseData: string = err.response?.data
            ? err.response?.data.message
            : t("Alert##action failed");
          handleResponse(ALERT_STATUS.Critical, responseData);
        }
      }
    };

    addDriver();
  };

  return (
    <Dialog
      data-testid="add-driver-dialog"
      title={t("Dialog##add driver")}
      extendToMaxWidth={toMd}
      isTitleSeparator
      isActionsSeparator
      open={isOpen}
      close={handleCloseAddDriverDialog}
      submit={handleSubmit}
      width={!toMd ? DialogWidth.MinWidth : undefined}
      TransitionProps={{
        onExited: () => {
          resetAllValues();
        },
      }}
      actions={
        <DialogActions
          onClose={handleCloseAddDriverDialog}
          isLoading={false}
          isreadyToConfirm={isDriverPayloadReadyToConfirm(
            cardHolderName,
            cardHolderNameIsValid(cardHolderName),
            driverId,
            inputIsValid,
            descriptionIsValid(descriptionValue)
          )}
          confirmationButtonText={t("Button##add")}
          confirmationButtonTestId="add-driver-button"
          cancelButtonText={t("Button##close")}
          cancelButtonTestId="close-add-driver"
        />
      }
    >
      <AddDriverDialogContent
        onCardHolderNameChange={handleCardHolderNameChange}
        validateCardHolderName={validateCardHolderNameLength}
        cardHolderNameErrorText={
          cardHolderNameIsValid(cardHolderName) ? "" : cardHolderInputError
        }
        validateIdInput={validateInput}
        idInputError={inputError}
        onIdValueChange={handleChangeDriverIdValue}
        onDescriptionChange={handleChangeDescription}
        validateDescription={validateDescriptionLength}
        descriptionErrorText={
          descriptionIsValid(descriptionValue) ? "" : descriptionInputError
        }
      />
    </Dialog>
  );
};

export default AddDriverDialog;
